import * as types from "../types.variables.js";

export function setAppointmentPOI(poi) {
  return {
    type: types.APPOINTMENT_SET_POI,
    payload: poi,
  };
}

export function setAppointmentValues(appointment) {
  return {
    type: types.APPOINTMENT_SET_VALUES,
    payload: appointment,
  };
}

export function setShowCreateAppointment(value) {
  return {
    type: types.APPOINTMENT_SET_SHOW_CREATEAPPOINTMENT,
    payload: value,
  };
}
export function setHideCreateAppointment(value) {
  return {
    type: types.APPOINTMENT_SET_HIDE_CREATEAPPOINTMENT,
    payload: value,
  };
}
