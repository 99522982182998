import { useEffect, useRef } from "react";
import { LOCATION_MANAGER } from "../../../../reduxStore/actions/main.js";
import { setNavigationController } from "../navigation.component.jsx";
import NavigationController from "@catchupapplications/indoor-navigation/dist/models/NavigationController";
import Toast from "../../../../functions/classes/toast.class.js";
import { useIntl } from "react-intl";

/**
 * Dieser Effect checkt die gegebenen Parameter ab, um zu entscheiden ob der Status "navigationMode" auf "sbs" oder "live" gestellt werden soll.
 * Wenn alle notwendigen Infos gegeben sind wird automatisch "live" aktiviert. Dadurch kommt auch ein route snapping zustande!
 * @param isApp
 * @param locationSettings
 * @param locationObject
 * @param navigationMode
 * @param currentRoute
 * @param currentLeg
 * @param {function}setNavigationMode
 * @param addToast
 */
export function useNavigationMode(
  isApp,
  locationSettings,
  locationObject,
  navigationMode,
  currentRoute,
  currentLeg,
  setNavigationMode,
  addToast
) {
  const prevNavigationMode = useRef();
  const intl = useIntl();

  // --- functions ----
  const manageNavigationController = () => {
    if (navigationMode === "live" && currentRoute) {
      setNavigationController(
        // todo: das hier noch dynamic machen
        new NavigationController(currentRoute, "walking", "bluetooth")
      );

      LOCATION_MANAGER.switchViewType("navigation");
    } else {
      setNavigationController(null);
    }
    if (prevNavigationMode === "live" && navigationMode === "sbs") {
      addToast(
        new Toast(
          "nowLiveMode",
          intl.formatMessage({ id: "navigation.nowInSBSMode" }),
          Toast.toastTypes.warning
        )
      );
    }
    return () => {
      setNavigationController(null);
      LOCATION_MANAGER.switchViewType("preview");
    };
  };
  const manageNavigationMode = () => {
    //todo: Erst später wieder live hinzufügen!
    if (
      isApp &&
      locationSettings.userAllowed &&
      locationSettings.permissionAllowed &&
      currentLeg.isLive
    ) {
      setNavigationMode("live");
    } else {
      setNavigationMode("sbs");
    }
    return () => {
      //setNavigationMode("map");
    };
  };

  // --- effects ---
  useEffect(manageNavigationMode, [
    locationSettings.userAllowed,
    locationSettings.permissionAllowed,
    locationObject.zIndex,
    currentLeg,
  ]);
  useEffect(manageNavigationController, [currentRoute, navigationMode]);
  useEffect(() => {
    prevNavigationMode.current = navigationMode;
  }, [navigationMode]);
}
