import React from "react";

/**
 * Standard Card, die z.B. bei Suche, Neuigkeiten, Veranstaltungen etc. verwendet wird!
 * @param onClick
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
const StandardCard = React.forwardRef(
  (
    {
      onClick,
      children,
      role = "",
      className = "",
      headingClassName = "",
      padding = true,
      heading,
      headingType = "h2",
      ariaLabel = "",
    },
    ref
  ) => {
    const getHeading = () => {
      if (!heading) return null;

      let hClass =
        "standardCard__heading d-flex flex-column " + headingClassName;
      switch (headingType) {
        case "h1":
          return <h1 className={hClass}>{heading}</h1>;
        case "h2":
          return <h2 className={hClass}>{heading}</h2>;
        case "h3":
          return <h3 className={hClass}>{heading}</h3>;
        case "h4":
          return <h4 className={hClass}>{heading}</h4>;
        default:
          return <h3 className={hClass}>{heading}</h3>;
      }
    };
    return (
      <div
        ref={ref}
        role={role}
        onClick={onClick}
        className={`${padding ? "p-3" : ""} standardCard ${className}`}
        aria-label={ariaLabel}
      >
        {getHeading()}
        {children}
      </div>
    );
  }
);
export default StandardCard;
