import React from "react";
import PropTypes from "prop-types";

const StandardSubline = (props) => {
  return (
    <div className={props.className != null ? props.className : "mb-3"}>
      <span id={props.id} aria-label={props.ariaLabel}>
        {props.children}
      </span>
    </div>
  );
};

StandardSubline.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  ariaLabel: PropTypes.string,
  id: PropTypes.string,
};

export default StandardSubline;
