import { useEffect } from "react";
/**
 *
 * @param {*} currentStep
 * @param {*} setBannerStepDistance
 * @param {*} oneStepPerLeg
 * @param {Leg} currentLeg
 * @param {*} isTerminal
 */
export default function useSBSNavigation(
  currentStep,
  setBannerStepDistance,
  oneStepPerLeg,
  currentLeg,
  isTerminal
) {
  useEffect(() => {
    if (!currentStep || isTerminal) return;

    setBannerStepDistance(Math.round(currentStep.distance));
  }, [currentStep]);

  useEffect(() => {
    if (!isTerminal || !currentLeg) return;
    // Damit der "weiter" button pro leg funktioniert, muss das Leg immer sofort auf den letzten Step gesetzt werden!
    // daher muss auch beim "weiter" klick nichts weiter verändert werdne
    // beim prevStep Click aber schon! Das ist nur für Terminal Modus.
    if (oneStepPerLeg) {
      currentLeg.setLastStep();
    }

    setBannerStepDistance(Math.round(currentLeg.distance));
  }, [currentLeg]);
}
