import { config } from "react-spring";

/**
 * Das hier sind Konfigurationen, welche für die Animation
 * oder "useSpring" verwendet werden. Sie sind der "config" Parameter.
 */
export const wobbly = {
  immediate: false,
  config: {
    ...config.wobbly,
    friction: 25,
  },
};

export const fade = {
  immediate: false,
  config: {
    friction: 25,
  },
};
