import React from "react";
import EtagenWechslerContainer from "./etagenwechsler.container.jsx";
import ActionBubbles, {
  Languages,
} from "./actionBubbles/actionBubbles.component.jsx";
import PositionRecorder from "../../elements/positionRecorder/positionRecorder.jsx";
import TerminalMapControls from "./TerminalMapControls.jsx";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setLanguage } from "../../../reduxStore/actions/main.js";

/**
 * Das sind die Action bubbles die in der MapUI oben rechts immer zu sehen sind
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function MapControls(props) {
  return (
    <div className={`d-flex flex-column ml-auto pr-2 mt-2 w-100 h-100`}>
      {props.isTerminal ? (
        <div className="d-flex flex-column mt-auto align-items-end mb-3 w-100">
          <div className="d-flex flex-column">
            <Languages
              language={props.language}
              setLanguage={props.setLanguage}
            />
          </div>
          <div className="d-flex flex-column align-items-end">
            <TerminalMapControls />
            <EtagenWechslerContainer />
            {props.children}
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column align-items-end mb-3 w-100">
          <EtagenWechslerContainer />
          <PositionRecorder className={"mb-2"} />
          {props.children}
          <ActionBubbles className={"mb-2"} openingDirection={"down"} />
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => ({
  isTerminal: state.main.isTerminal,
  language: state.main.language,
});
const dispatchToProps = (dispatch) => ({
  setLanguage: (key) => dispatch(setLanguage(key)),
});
export default withRouter(
  connect(mapStateToProps, dispatchToProps)(MapControls)
);
