import ReactDOM from "react-dom/client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { NativeStorageInterface } from "@catchupapplications/utilities";
import LogBoundary from "./components/LogBoundary.component";
import configureStore, { history } from "./reduxStore/store";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import IntlContext from "./IntlContext.jsx";
import NativeAppInterface from "./components/nativeAppInterface/NativeAppInterface";
import * as Comlink from "comlink";
import React, { Suspense } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// @ts-ignore
library.add(fas);

export const nativeStorageInterface = NativeStorageInterface.getInstance({
  deviceType: "iOS",
});

const store = configureStore();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

Comlink.transferHandlers.set("FUNCTION", {
  //@ts-ignore
  canHandle: (obj) => typeof obj === "function",
  serialize(obj) {
    const { port1, port2 } = new MessageChannel();
    Comlink.expose(obj, port1);
    return [port2, [port2]];
  },
  deserialize(port) {
    //@ts-ignore
    port.start();
    //@ts-ignore
    return Comlink.wrap(port);
  },
});

const App = React.lazy(() => import("./app/App"));

root.render(
  <Provider store={store}>
    <IntlContext>
      <ConnectedRouter history={history}>
        <LogBoundary>
          <NativeAppInterface />
          <Suspense
            fallback={
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "gray",
                }}
              >
                <FontAwesomeIcon
                  icon={"map"}
                  className="m-3 loadingSpinner"
                  style={{ fontSize: 5 + "rem" }}
                />
              </div>
            }
          >
            <App />
          </Suspense>
        </LogBoundary>
      </ConnectedRouter>
    </IntlContext>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
