export class RecordedPositions_COLLECTION {
  constructor() {
    this.data = [];
  }

  /**
   * @return {RecordedPositions}
   */
  getCurrent() {
    if (this.data.length > 0) return this.data[this.data.length - 1];
  }
  startNewRecord(start, destination) {
    this.data.push(new RecordedPositions(start, destination));
  }
}
export class RecordedPositions {
  constructor(start, destination) {
    this.startCoordinates = null;
    this.destinationCoordinates = null;
    this.description = "";
    this.system = "";
    this.deviceInfomation = {};
    this.startTimestamp = new Date().getTime();
    this.destinationTimestamp = null;
    this.start = start;
    this.destination = destination;
    this.data = [];
    this.submittedToUpload = false;
  }

  /**
    * @param{[{
        lat,
        lng,
        accuracy,
        course,
        zIndex,
        timestamp,
        speed,
      }] | [{
        UUID,
        major,
        minor,
        rssi,
        timestamp,
        heading,
      }]} entry
    */
  pushEntry(entry) {
    if (this.destinationTimestamp == null) this.data.push(entry);
    else console.warn("This RecordedPositions is already finished!");
  }
  stopRecording() {
    this.destinationTimestamp = new Date().getTime();
  }
  setSubmittedToUpload() {
    this.submittedToUpload = true;
  }
  setNavigationType(type) {
    this.navigationType = type;
  }
  setDescription(description) {
    this.description = description;
  }
  setStart(start) {
    this.start = start;
  }
  setDestination(destination) {
    this.destination = destination;
  }
  setPiKey(key) {
    this.piKey = key;
  }
  setCampusKey(key) {
    this.campusKey = key;
  }
  setSystem(system) {
    this.system = system;
  }
  setDeviceInformation(deviceInformation) {
    this.deviceInformation = JSON.stringify(deviceInformation);
  }
  /**
   * @param {"bluetooth"|"gps" |"heading"} type
   */
  getByType(type) {
    if (type === "gps") {
      return this.data.filter((d) => {
        return d[0] && d[0].lat != null;
      });
    }
    if (type === "bluetooth") {
      return this.data.filter((d) => {
        return d[0] && d[0].rssi != null;
      });
    }
  }
}
