import Highlight from "../../elements/text/highlight.text.jsx";
import StandardCard from "../../elements/cards/StandardCard.card";
import PropTypes from "prop-types";
import RoundedIcon from "../../elements/buttons/roundedIcon.component";

function QRCode(props) {
  return (
    <StandardCard
      role={"button"}
      onClick={() => props.setQRCode(props)}
      className={"mb-3 standardCard__search"}
    >
      <h3 className="font-weight-bold d-flex align-items-center">
        <RoundedIcon
          icon={"qrcode"}
          divClass={"btn ca-btn-head-primary mr-1"}
        />
        <Highlight search={props.searchText}>{props.name}</Highlight>
      </h3>
    </StandardCard>
  );
}

QRCode.propTypes = {
  name: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  startNodeId: PropTypes.any,
  globalPOIId: PropTypes.any,
  setQRCode: PropTypes.func.isRequired,
};

export default QRCode;
