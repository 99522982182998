import * as types from "../types.variables.js";

/**
 * Setzt die Bounds der Map im momentanen Zoom level!
 * @param {{nw: float, se: float}}bounds
 * @return {{payload: *, type: string}}
 */
export function setBounds(bounds) {
  return {
    type: types.MAP_SET_BOUNDS,
    payload: bounds,
  };
}

/**
 * Wird verwendet um automatisch den Etagenwechsler einzublenden ohne dass der User drauf drücken muss
 * @param value
 * @return {{payload: *, type: string}}
 */
export function setViewIsIndoors(value) {
  return {
    type: types.MAP_SET_VIEW_IS_INDOORS,
    payload: value,
  };
}

/**
 * Für Terminal, damit es immer in das Urzustandsbearing kommt
 * @param bearing
 * @return {{type: *}}
 */
export function setTerminalBearing(bearing) {
  return {
    type: types.MAP_SET_TERMINAL_BEARING,
    payload: bearing,
  };
}

/**
 *
 * @param {{}} object
 * @return {{payload: *, type: string}}
 */
export function setMapPadding(padding, tellMap = false) {
  return {
    type: types.MAP_SET_PADDING,
    payload: { padding: padding, tellMap: tellMap },
  };
}

/**
 * Gibt an ob bei Handys, die ein Heading ausgebene können die Kamera sich dazu bewegen soll
 * @param {bool} shouldMove
 */
export function setCameraMoveToHeading(shouldMove) {
  return {
    type: types.MAP_SET_CAMERA_MOVE_TO_HEADING,
    payload: shouldMove,
  };
}

export function forceFeatureFilterUpdate() {
  return {
    type: types.MAP_FORCE_FEATUREFILTER_UPDATE,
  };
}

export function setMapLoaded(value) {
  return {
    type: types.MAP_SET_MAPLOADED,
    payload: value,
  };
}
export function setMapboxServerdata(payload) {
  return {
    type: types.MAP_SET_MAPBOX_SERVERDATA,
    payload: payload,
  };
}

/**
 * Ersetzt die Top Root States von map.js
 * @param {{...states}} payload
 */
export function setMapboxStates(states) {
  return {
    type: types.MAP_SET_MAPBOX_STATES,
    payload: states,
  };
}
export function setChildrenFeatures(features) {
  return {
    type: types.MAP_SET_CHILDRENFEATURES,
    payload: features,
  };
}
export function toggleMapStyle() {
  return {
    type: types.MAP_TOGGLE_MAPSTYLE,
  };
}

export function incrementMapClicks() {
  return {
    type: types.MAP_INCREMENT_MAPCLICKS,
  };
}

/**
 * @param {"route", "navigation", "info"} page
 * @return {{payload: *, type: string}}
 */
export function setPage(page) {
  return {
    type: types.MAP_SET_PAGE,
    payload: page,
  };
}

export function setMap(map) {
  return {
    type: types.MAP_SET_MAP,
    payload: map,
  };
}
export function setAllowBuildingMarkers(value) {
  return {
    type: types.MAP_SET_ALLOWBUILDINGMARKERS,
    payload: value,
  };
}
export function toggle3DBuildings(show) {
  return {
    type: types.MAP_TOGGLE_3D_BUILDINGS,
    payload: show,
  };
}
export function toggleMarkers(payload) {
  return {};
}
