import React from "react";
import PropTypes from "prop-types";

const StandardInstruction = (props) => {
  if (props.type === "span") {
    return (
      <span
        onClick={props.onClick}
        className={`standardInstruction ${props.className}`}
      >
        {props.children}
      </span>
    );
  } else {
    return (
      <p
        onClick={props.onClick}
        className={`standardInstruction ${props.className}`}
      >
        {props.children}
      </p>
    );
  }
};

StandardInstruction.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  noMargin: PropTypes.bool,
  children: PropTypes.any,
  type: PropTypes.string,
};
StandardInstruction.defaultProps = {
  type: "span",
  className: "",
  onClick: () => {},
  noMargin: true,
  children: "",
};

export default StandardInstruction;
