import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { a, config, useSpring } from "react-spring";
import { connect } from "react-redux";
import classes from "./actionBubbles.module.scss";
import * as action from "../../../../reduxStore/actions/map.js";
import * as actionToast from "../../../../reduxStore/actions/toasts.js";
import {
  easeToFeature,
  changeLevel,
} from "../../mapbox/functions/interactivity.map.js";
import { useIntl } from "react-intl";
import Toast, {
  toastTypes,
} from "../../../../functions/classes/toast.class.js";
import RoundedIcon, {
  FilledRoundedIcon,
} from "../../../elements/buttons/roundedIcon.component.jsx";
import * as mainAction from "../../../../reduxStore/actions/main.js";
import germany_SVG from "../../../../assets/images/flags/germany.svg";
import uk_SVG from "../../../../assets/images/flags/uk.png";
import france_PNG from "../../../../assets/images/flags/france.png";
import { CUSTOMIZABLE_VALUES } from "../../mapbox/variables/CUSTOMIZABLE_VALUES.js";

/**
 * Das sind die Bubbles, die bei der Kartenansicht oben rechts auftauchen und Stockwerke wechseln etc.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function ActionBubbles(props) {
  const intl = useIntl();
  // dieser hier triggert die rein und rausfahr animationen!
  const [bubbleStatus, setBubbleStatus] = useState("closed");

  const subBubblesContainer = useRef(null);
  const mainBubbleContainer = useRef(null);

  const [springProps, springAPI] = useSpring(() => ({}));
  const [dimensions, setDimensions] = useState({});

  const handleOpen = () => {
    // stoppt vorherige schließanimation (falls vorhanden)
    springAPI.stop();
    setBubbleStatus("opening");
    springAPI.start((props) => ({
      config: config.stiff,
      to: {
        [dimensions.unit]: "auto",
        [dimensions.direction]: dimensions.offset,
        opacity: 1,
      },
      onRest: () => {
        setBubbleStatus("open");
      },
    }));
  };
  const handleClose = () => {
    springAPI.stop();
    setBubbleStatus("closing");
    springAPI.start((props) => ({
      config: config.stiff,
      to: {
        [dimensions.unit]: "auto",
        [dimensions.direction]: 0,
        opacity: 0,
      },
      onRest: () => {
        setBubbleStatus("closed");
      },
    }));
  };

  //
  useEffect(() => {
    let offset, unit, direction;
    offset = mainBubbleContainer.current.clientWidth;
    switch (props.openingDirection) {
      case "left":
        unit = "width";
        direction = "right";
        break;
      case "right":
        unit = "width";
        direction = "left";
        break;
      case "up":
        unit = "height";
        direction = "bottom";
        break;
      default:
        unit = "height";
        direction = "top";
    }

    setDimensions({
      ...dimensions,
      offset,
      unit,
      direction,
    });
  }, [subBubblesContainer.current]);

  // kümmert sich ums schließen und öffnen
  // wird im Effect gesteuert, damit components von außen auch Einfluss nehmen können
  useEffect(() => {
    switch (bubbleStatus) {
      // closed und open müssen nicht behandelt werden,
      // sondern werden nur als condition beim rendern verwendet
      case "closing":
        handleClose();
        break;
      case "opening":
        handleOpen();
        break;
    }
  }, [bubbleStatus]);

  // von außen wird der Open Status geändert
  useEffect(() => {
    setBubbleStatus(props.open ? "opening" : "closing");
  }, [props.open]);

  /**
   * Die Items die immer drin sind (können durch props ausgeblendet werden)
   * @returns {[]}
   */
  const getStandardItems = () => {
    let returnItems = [];
    let marginclass;
    switch (props.openingDirection) {
      case "up":
        marginclass = "mb-2";
        break;
      case "left":
        marginclass = "mr-2";
        break;
      case "right":
        marginclass = "ml-2";
        break;
      default:
        marginclass = "mt-2";
    }

    if (props.showStandard_items) {
      if (props.show3DBuildings_item) {
        returnItems.push(
          <ActionBubble
            key={"3dBuildings"}
            active={props.show3DBuildings}
            className={`${marginclass} roundedIcon--actionBubble--sub`}
            iconClassName={`roundedIcon--actionBubble--sub__icon`}
            iconname={["fal", "cube"]}
            action={props.toggle3DBuildings}
          />
        );
      }

      if (
        (props.showPosition_item && props.currentLocationObject.isSet) ||
        true
      ) {
        returnItems.push(
          <ActionBubble
            ariaLabel={props.ariaLabel}
            key={"showposition"}
            className={`${marginclass} roundedIcon--actionBubble--sub`}
            iconClassName={`roundedIcon--actionBubble--sub__icon`}
            color={
              props.hasLatestPosition ? CUSTOMIZABLE_VALUES.gpsAvailable : null
            }
            iconname={["fal", "location"]}
            action={() => {
              easeToFeature(
                [
                  props.currentLocationObject.lng,
                  props.currentLocationObject.lat,
                ],
                { zoom: 18 }
              );
              changeLevel(props.currentLocationObject.zIndex);
              if (!props.hasLatestPosition) {
                props.addToast(
                  new Toast(
                    "resortToLastKnownPosition",
                    intl.formatMessage({
                      id: "actionBubbles.lastKnownPosition",
                    }),
                    toastTypes.warning
                  )
                );
              }
            }}
          />
        );
      }
    }
    return returnItems;
  };

  const toggleBubbleStatus = () => {
    if (bubbleStatus === "open" || bubbleStatus === "opening") {
      setBubbleStatus("closing");
    } else {
      setBubbleStatus("opening");
    }
  };

  return (
    <div
      className={`${classes.menu} ${
        bubbleStatus === "open" || bubbleStatus === "opening"
          ? classes.menuOpen
          : ""
      } pointer-event-on ml-auto ${props.className}`}
    >
      <div className={"position-relative"}>
        <ActionBubble
          open={bubbleStatus === "open" || bubbleStatus === "opening"}
          divRef={mainBubbleContainer}
          action={toggleBubbleStatus}
          iconname={props.iconName ? props.iconName : ["fal", "compass"]}
          iconClassName={props.iconClassName}
          className={props.iconDivClassname}
          ariaLabel={props.ariaLabel}
        />
        <a.div
          ref={subBubblesContainer}
          style={springProps}
          className={`${
            props.openingDirection === "left" ||
            props.openingDirection === "right"
              ? "top-0 d-flex"
              : ""
          } ${
            bubbleStatus !== "closed" ? "d-block" : "d-none"
          } position-absolute`}
        >
          {getStandardItems()}
          {props.children}
        </a.div>
      </div>
    </div>
  );
}

export function Languages({ language, setLanguage }) {
  return (
    <div className={"d-flex flex-column"}>
      <FilledRoundedIcon
        icon={germany_SVG}
        onClick={() => setLanguage("de")}
        className={"mb-3"}
        active={language === "de"}
      />
      <FilledRoundedIcon
        icon={uk_SVG}
        onClick={() => setLanguage("en")}
        active={language === "en"}
        className={"mb-3"}
      />
      <FilledRoundedIcon
        icon={france_PNG}
        onClick={() => setLanguage("fr")}
        active={language === "fr"}
        className={"mb-3"}
      />
    </div>
  );
}

export function ActionBubble({
  action,
  divRef,
  iconname,
  iconClassName,
  color,
  // wenn die Super Bubbles geöffnet sind
  open = false,
  active = true,
  className = "",
  ariaLabel,
}) {
  return (
    <RoundedIcon
      divRef={divRef}
      icon={iconname}
      style={{ color: color ? color : undefined }}
      onClick={action ? action : null}
      active={true}
      divClass={`roundedIcon--actionBubble ${
        open ? "roundedIcon--actionBubble--open" : ""
      } ${className}`}
      iconClass={`roundedIcon--actionBubble__icon ${
        open ? "roundedIcon--actionBubble--open__icon" : ""
      } ${iconClassName}`}
      ariaLabel={ariaLabel}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    currentRoute: state.route.currentRoute,
    currentLocationObject: state.route.locationObject,
    hasLatestPosition: state.route.hasLatestPosition,
    show3DBuildings: state.map.show3DBuildings,
    cameraMoveToHeading: state.map.cameraMoveToHeading,
    isTerminal: state.main.isTerminal,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addToast: (object) => dispatch(actionToast.addToast(object)),
    toggle3DBuildings: () => dispatch(action.toggle3DBuildings()),
    setCameraToHeading: (shouldCameraMove) =>
      dispatch(action.setCameraMoveToHeading(shouldCameraMove)),
    setLanguage: (key) => dispatch(mainAction.setLanguage(key)),
  };
};
ActionBubbles.propTypes = {
  iconName: PropTypes.array,
  iconClassName: PropTypes.string,
  open: PropTypes.bool,
  openingDirection: PropTypes.oneOf(["left", "right", "up", "down"]),
  className: PropTypes.string,
  //Alle Standard Items anzeigen
  showStandard_items: PropTypes.bool,
  show3DBuildings_item: PropTypes.bool,
  showPosition_item: PropTypes.bool,
  cameraMoveToHeading_item: PropTypes.bool,
  iconDivClassname: PropTypes.string,
  ariaLabel: PropTypes.string,
};
ActionBubbles.defaultProps = {
  open: false,
  iconDivClassname: "",
  iconClassName: "",
  show3DBuildings_item: true,
  showStandard_items: true,
  showPosition_item: true,
  cameraMoveToHeading_item: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionBubbles);
