import { map } from "../../map.sideEffects/useMap.sideEffects.js";
import { layerIds } from "../../variables/layerIds.js";
import { sourceIds } from "../../variables/sourceIds.js";
import { EXPRESSIONS } from "../../variables/expressions.js";
import {
  iconSize,
  zoomMacro,
  zoomMacroFactor,
  zoomMid,
  zoomMidFactor,
  zoomWide,
} from "../drawer.map.js";
import { CUSTOMIZABLE_VALUES } from "../../variables/CUSTOMIZABLE_VALUES.js";

/**
 * Die kleinen Minimarker für POIs, die keinen Standort aber Kinder haben
 */
// Entweder hier die Opacity runter setzen, damit diese Marker nicht mehr sichtbar sind, oder eben verhindern, dass die Features gesetzt werden.
export function createChildrenSymbols() {
  if (!map.getLayer(layerIds.nodes.poiChildren)) {
    let minimarkerIcon = iconSize * 2;
    map.addLayer({
      id: layerIds.nodes.poiChildren,
      type: "symbol",
      source: sourceIds.poiChildren,
      layout: {
        "icon-image": "location",
        "icon-anchor": "bottom",
        "icon-allow-overlap": true,
        "icon-size": [
          "interpolate",
          ["linear"],
          ["zoom"],
          zoomWide,
          minimarkerIcon,
          zoomMid,
          minimarkerIcon * zoomMidFactor,
          zoomMacro,
          minimarkerIcon * zoomMacroFactor,
        ],
      },
      paint: {
        "icon-opacity": [
          "case",
          EXPRESSIONS.state.selected,
          0,
          EXPRESSIONS.state.differentLevel,
          0.3,
          1,
        ],
        "icon-halo-width": 3,
        "icon-halo-color": [
          "case",
          EXPRESSIONS.state.differentLevel,
          CUSTOMIZABLE_VALUES.childMarker_ghosted,
          CUSTOMIZABLE_VALUES.childMarker_darker,
        ],
        "icon-color": CUSTOMIZABLE_VALUES.childMarker,
      },
    });
  }
}
