import React from "react";

/**
 * Bildet einen Container für ein horizontales Scrollerlebnis
 * @param children
 * @param className
 * @param {boolean} scrollbar - Soll Scrollbar angezeigt werden oder nicht?
 * @return {JSX.Element}
 * @constructor
 */
export default function HorizontalScroller({
  children,
  className = "p-3 mb-3",
  scrollbar = false,
  id
}) {

  const handleScroll = (e, id) => {
    setTimeout(() => {
      const container = document.getElementById(id);
      if(container.hasAttribute("data-wheel-listener") !== true) {
        container.addEventListener("wheel", (evt) => {
          if(evt.deltaY < 0 || evt.deltaY > 0) {
            container.scrollLeft += evt.wheelDelta;
          }
        });
        container.setAttribute("data-wheel-listener", true);
      }
    },500)
  }
  return (
    <div
      id={id}
      onMouseOver={(e) => handleScroll(e, id)}
      onScroll={(e) => handleScroll(e, id)}
      className={`d-flex overflow-auto ${
        scrollbar ? "" : "no-scrollbar"
      } ${className}`}
    >
      {children}
    </div>
  );
}
