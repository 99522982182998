import {
  polygon,
  center,
  lineString,
  length,
  point,
  booleanContains,
} from "@turf/turf";

export default class CampusCollection {
  currentCampus;
  campuses = [];
  allCampusNodes = [];

  /**
   * Diese Funktion sorgt dafür, dass wenn ein zIndex reinkommt der einem Stockwerk entspricht,
   * dann wird er umgewandelt in den eigentlichen Array Index von diesem Stockwer. Nur so kann usePosition.sideEffects.js
   * den Punkt richtig anzeigen (ghosting)
   * @param stockwerk
   */
  getIndexFromLevel(level) {
    try {
      let index = -1;
      this.getCurrentCampus().floors.forEach((item, i) => {
        if (item.stockwerk == level || item.level == level) index = i;
      });
      return index;
    } catch (e) {
      window.onerror("Fehler bei campus.getIndexFromLevel -> 0 returned " + e);
      return 0;
    }
  }
  static getCampusKeys(start, destination) {
    const campusKeys = [];

    if (start) {
      campusKeys.push(start.getCampusKey());
    }

    if (destination && destination.campusId !== start?.campusId) {
      campusKeys.push(destination.getCampusKey());
    }

    return campusKeys;
  }

  addCampus(campus) {
    // duplikate vermeiden
    if (!this.campuses.find((c) => c.id === campus.id)) {
      this.campuses.push(campus);
    }
  }

  /**
   * @param {number} campusId
   */
  setCurrentCampus(campusId) {
    this.campuses.forEach((c) => {
      if (+c.id === +campusId) {
        this.currentCampus = c;
      }
    });
  }
  setCampusPolygon(campusId, polygonArray) {
    this.campuses.forEach((c) => {
      if (c.id === campusId) {
        let polygonFeature = polygon(polygonArray);

        c.polygon = polygonFeature;
        if (c.center && c.center.lng != null && c.center.lat != null) {
          c.center = point([c.center.lng, c.center.lat]);
        } else {
          c.center = center(polygonFeature);
        }
      }
    });
  }

  /**
   * Setzt den actual CampusPOI in das Campus Object
   * @param campusId
   * @param poi
   */
  setCampusPOI(campusId, poi) {
    this.campuses.forEach((c) => {
      if (c.id === campusId) {
        c.poi = poi;
      }
    });
  }

  /**
   *
   * @return {Campus}
   */
  getCurrentCampus() {
    if (this.currentCampus != null) {
      return this.currentCampus;
    } else {
      return this.campuses[0];
    }
  }

  /**
   * @param {number} floorIndex
   * @param {Campus} campus
   * @return {number}
   */
  getLevelByIndex(
    floorIndex,
    getDisplayLevel = false,
    campus = this.getCurrentCampus()
  ) {
    const floors = campus.floors;

    if (floorIndex == null || floorIndex < 0) {
      console.log("floorindex invalide", floorIndex);
      return floors[0]?.stockwerk;
    }

    if (floorIndex > floors.length - 1) {
      console.log("floorindex overflowed -> fallback letzter");
      return floors[floors.length - 1];
    }

    if (!getDisplayLevel || floors[floorIndex].kundeStockwerkAnzeige == null) {
      return floors[floorIndex].stockwerk;
    } else {
      return floors[floorIndex].kundeStockwerkAnzeige;
    }
  }
  getLevelDisplayType(
    level,
    displayType = "kundeStockwerkAnzeige",
    campus = this.getCurrentCampus()
  ) {
    const levelIndex = this.getIndexFromLevel(level);
    const floors = campus.floors;
    console.log(floors, levelIndex);
    if (floors && floors[levelIndex] != null) {
      if (floors[levelIndex][displayType]) {
        return floors[levelIndex][displayType];
      } else {
        return floors[levelIndex].stockwerk;
      }
    } else {
      console.warn("Achtung keine Floors vorhanden");
    }
  }

  /**
   * Gibt den am naheliegstensten Campus zu gegebenen Koordinaten
   * @param {[lng, lat]} lngLat
   * @return {*}
   */
  getClosestCampusTo(lngLat) {
    let shortestDistanceCampus = null;

    this.campuses.forEach((c) => {
      let centerCampus = c.center.geometry.coordinates;
      let lineCampusToCoordinates = lineString([
        centerCampus,
        [lngLat[0], lngLat[1]],
      ]);
      let distance = length(lineCampusToCoordinates);

      if (
        shortestDistanceCampus == null ||
        shortestDistanceCampus.distance >= distance
      ) {
        shortestDistanceCampus = { campus: c, distance: distance };
      }
    });
    return shortestDistanceCampus.campus;
  }

  /**
   * @param lat
   * @param lng
   * @returns {boolean}
   */
  isCorrdOnCurrentCampus(lat, lng) {
    if (!this.currentCampus) return false;
    if (!lat || !lng) return false;
    const coord = point([lng, lat]);
    let line = lineString([
      ...this.currentCampus.polygon.geometry.coordinates[0],
    ]);

    return booleanContains(line, coord);
  }

  getCampusByPosition(lat, lng) {
    return this.campuses.find((c) =>
      booleanContains(c.polygon, point([lng, lat]))
    );
  }

  getCampusById(campusId) {
    return this.campuses.find((c) => +c.id === +campusId);
  }
  getCampusByKey(key) {
    return this.campuses.find((c) => c.campusKey === key);
  }

  /**
   *
   * @return {Campus[]}
   */
  getAllCampuses() {
    return this.campuses;
  }
  getAllCampusNodes() {
    if (!this.allCampusNodes.length) {
      return this.campuses
        .map((campus) =>
          campus.nodes.map((nodeArray) => ({
            ...nodeArray,
            campusId: campus.id,
          }))
        )
        .flat();
    } else {
      return this.allCampusNodes;
    }
  }
}

export class Campus {
  constructor(
    id,
    campusKey,
    name,
    nodes,
    beacons,
    floors,
    center,
    polygon,
    showRoomnames,
    showOutdoorEinrichtungenOnMap
  ) {
    this.id = id;
    this.campusKey = campusKey;
    this.name = name;
    this.nodes = nodes;
    this.beacons = beacons;
    this.floors = floors;
    this.center = center;
    this.polygon = polygon;
    this.poi = null;
    this.showRoomnames = showRoomnames;
    this.showOutdoorEinrichtungenOnMap = showOutdoorEinrichtungenOnMap;
  }

  /**
   * @return {Polygon}
   */
  getPolygon() {
    return this.polygon;
  }
  getPOI() {
    return this.poi;
  }
  getNode(id) {
    return this.nodes.find((n) => n.id === id);
  }

  /**
   * @return {[lng, lat]}
   */
  getCenter() {
    return this.center;
  }

  /**
   * Check ob Beacons in diesem Level verfügbar sind für bsp. Entscheidungen
   * @param {number} levelIndex - Nicht das Stockwerk, sondern really der Index
   */
  hasBeaconsInLevel(levelIndex) {}
}
