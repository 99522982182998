import Highlight from "../../elements/text/highlight.text.jsx";
import StandardCard from "../../elements/cards/StandardCard.card";
import PropTypes from "prop-types";
import { getSearchFilterText } from "../list";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import StandardHeadline from "../../elements/text/standardHeadline.text.jsx";
import StandardInformation from "../../elements/text/standardInformation.text.jsx";

function POI(props) {
  /**
   * @type POI
   */
  const poi = props.poi;
  const handlePoiClick = () => {
    props.searchItemClicked(props.id);
  };
  const handleParentClick = (e) => {
    // der parent button ist innerhalb der card
    // -> bubbling stoppen, damit nicht handlePoiClick ausgeführt wird
    e.stopPropagation();
    props.searchItemClicked(poi.getParent().id);
  };

  return (
    <StandardCard
      role={"button"}
      onClick={handlePoiClick}
      className={"mb-3 standardCard__search"}
      ariaLabel={props.ariaLabel}
    >
      {props.name.toLowerCase().includes(props.searchText.toLowerCase()) ? (
        <StandardHeadline type={"h4"} className="font-weight-bold">
          <Highlight search={props.searchText}>{props.name}</Highlight>
        </StandardHeadline>
      ) : (
        <StandardHeadline type={"h4"} className="font-weight-bold">
          <Highlight search={props.searchText}>
            {props.name +
              getSearchFilterText(props.searchText, props.searchstrings)}
          </Highlight>
        </StandardHeadline>
      )}
      <span className="mb-3 overflow-auto text-muted">
        <Highlight search={props.searchText}>{props.address}</Highlight>
      </span>
      {poi && poi.getParent() && (
        <StandardInformation
          onClick={handleParentClick}
          className={"mt-3 mb-3 d-flex align-items-center"}
          type={"p"}
        >
          <FontAwesomeIcon className={"mr-2"} icon={["fal", "house"]} />
          <span>
            <FormattedMessage id={"infoCard.belongsTo"} />{" "}
            <b>{poi.getParent().name}</b>
          </span>
        </StandardInformation>
      )}
    </StandardCard>
  );
}

POI.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  searchstrings: PropTypes.array,
  address: PropTypes.string,
  searchItemClicked: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string,
};

export default POI;
