import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RoundedIcon from "../buttons/roundedIcon.component.jsx";
import StandardCard from "./StandardCard.card.jsx";
import StandardHeadline from "../text/standardHeadline.text.jsx";
import { CUSTOMIZABLE_VALUES } from "../../mapUi/mapbox/variables/CUSTOMIZABLE_VALUES.js";

export default function EinrichtungLinkCard({
  name,
  address,
  onClick,
  shownOnMap,
  hasLocation,
  customIcon,
  showArrow = true,
  fullWidth = false,
}) {
  return (
    <StandardCard
      className={`standardCard--info mr-3 ${fullWidth ? "col-12" : "col-10"}`}
      onClick={onClick}
    >
      <div className={"d-flex align-items-center "}>
        {shownOnMap && hasLocation ? (
          <FontAwesomeIcon
            color={CUSTOMIZABLE_VALUES.childMarker}
            icon={"map-marker-alt"}
            className={"mr-3 mb-2"}
          />
        ) : null}
        <StandardHeadline type={"h3"} text={name} className={"mr-auto"} />
        {hasLocation && showArrow && (
          <RoundedIcon
            icon={["fal", "long-arrow-right"]}
            divClass={"ml-3 roundedIcon--primary align-self-start"}
            iconClass={"roundedIcon--primary__icon"}
          />
        )}
        {customIcon && customIcon}
      </div>
      <span>{address}</span>
    </StandardCard>
  );
}
