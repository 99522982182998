import { CAMPUS_COLLECTION } from "../../../reduxStore/actions/main";
import { FormattedMessage, useIntl } from "react-intl";
import { useState, useRef } from "react";
import classes from "../search.module.scss";

import StandardButton from "../../elements/buttons/standard.button.jsx";
import FilterDetail from "./FilterDetail";
import CampusButtons from "./CampusButtons";
import CategoryButtons from "./CategoryButtons";
import PropTypes from "prop-types";
import { statisticLogger } from "../../statisticLogger/StatisticLogger.container";

export default function Filter(props) {
  const intl = useIntl();
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [showAbsoluteFilterButton, setShowAbsoluteFilterButton] =
    useState(false);
  const buttonContainer = useRef(null);

  const filterText = filterIsOpen
    ? intl.formatMessage({ id: "search.closeFilter" })
    : `${intl.formatMessage({ id: "search.openFilter" })} ${
        props.selectedCategories.length > 0 || props.selectedCampus.length
          ? `(${
              props.selectedCategories.length +
              (props.selectedCampus.length ===
              CAMPUS_COLLECTION.getAllCampuses().length
                ? 0
                : 1)
            })`
          : ""
      }`;

  const handleScroll = (e) => {
    const { target } = e;
    setShowAbsoluteFilterButton(
      target.scrollLeft > target.firstChild.offsetWidth
    );
  };

  const handleOpenFilter = () => {
    statisticLogger.addLog({
      action: {
        group: "Search",
        id: filterIsOpen ? "searchCloseFilter" : "searchOpenFilter",
        movement: "stay",
        name: filterIsOpen ? "Search close filters" : "Search open filters",
        type: "click",
        interaction: filterIsOpen ? "close" : "open",
      },
    });
    setFilterIsOpen((prevState) => !prevState);
  };
  return (
    <>
      <div className={`${classes.FilterContainer} mt-3 text-nowrap w-100`}>
        <div
          onScroll={handleScroll}
          ref={buttonContainer}
          className={
            "pl-3 pr-5 d-flex align-items-center no-scrollbar overflow-auto"
          }
        >
          <StandardButton
            icon={["fal", "filter"]}
            ariaLabel={
              filterIsOpen
                ? intl.formatMessage({ id: "search.filter.screenreader.close" })
                : intl.formatMessage({ id: "search.filter.screenreader.open" })
            }
            buttonClasses={`normalButton--primary normalButton--filter ${
              filterIsOpen
                ? "normalButton--itemSelection--selected"
                : "normalButton--itemSelection--unselected"
            } mr-3 mb-3`}
            handleClick={handleOpenFilter}
          >
            {filterText}
          </StandardButton>
          {!filterIsOpen ? (
            <div
              className={"d-flex"}
              role={"listbox"}
              aria-orientation={"horizontal"}
            >
              {CAMPUS_COLLECTION.getAllCampuses().length > 1 ? (
                <CampusButtons {...props} />
              ) : null}
              <CategoryButtons {...props} />
            </div>
          ) : (
            <span className={`ml-auto`}>
              {props.matchesCount}{" "}
              <FormattedMessage id="search.filterDetails.matches" />
            </span>
          )}
        </div>
        <StandardButton
          title={filterText}
          icon={["fal", "filter"]}
          iconClasses="d-inline-block"
          buttonClasses={`normalButton--primary ${classes.filterButton} 
          ${classes.AbsoluteFilterButton} ${
            showAbsoluteFilterButton ? classes.ShowAbsoluteFilterButton : ""
          }`}
          noFlex
          handleClick={() => setFilterIsOpen((prevState) => !prevState)}
        >
          {props.selectedCategories.length +
            (props.selectedCampus.length ===
            CAMPUS_COLLECTION.getAllCampuses().length
              ? 0
              : 1)}
        </StandardButton>
      </div>
      {filterIsOpen ? <FilterDetail {...props} /> : null}
    </>
  );
}

Filter.propTypes = {
  selectedCampus: PropTypes.arrayOf(PropTypes.number),
};
