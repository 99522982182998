import { FormattedMessage, useIntl } from "react-intl";
import classes from "../navigation.module.scss";
import { getNodeById } from "../../../../functions/classes/node.class";
import {
  MISC_INFO,
  MODULES,
  POI_NODES,
} from "../../../../reduxStore/actions/main";
import React, { useEffect, useState } from "react";
import StandardButton from "../../../elements/buttons/standard.button.jsx";
import { isAndroid, isChrome } from "react-device-detect";
import { openNativeShareDialog } from "../../../nativeAppInterface/NativeAppInterface.jsx";
import { GeneratedQRCode } from "../../../elements/cards/GeneratedQRCode";
import RouteSettings from "../../../settings/pages/RouteSettings.jsx";
import { statisticLogger } from "../../../statisticLogger/StatisticLogger.container";
import { createShareURL } from "../../info/bottom/interactionButtons.infoCard";

export default function NavigationInfo({
  start,
  target,
  downloadPDF,
  isGeneratingPDF,
  isApp,
  isTerminal,
  settings,
  setProperties,
}) {
  const [targetFeature, setTargetFeature] = useState(null);

  const shareData = {
    text: target.name ? target.name : target.address,
    url: createShareURL(),
  };

  const cb = navigator.clipboard;
  const urlParams = new URLSearchParams(window.location.search);
  const isFramed = !!urlParams.get("isFramed");
  const [copyToClipboardIsAllowed, setCopyToClipboardIsAllowed] =
    useState(true);

  useEffect(() => {
    (async () => {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: "clipboard-write",
        });

        if (
          !(
            permissionStatus.state === "granted" ||
            permissionStatus.state === "prompt"
          )
        ) {
          setCopyToClipboardIsAllowed(false);
        }
      } catch (error) {
        setCopyToClipboardIsAllowed(false);
      }
    })();
  }, []);

  const handleShare = () => {
    if (navigator.share) {
      navigator.share(shareData);
    } else {
      if (isApp && isAndroid) {
        openNativeShareDialog(shareData.url);
      } else {
        cb.writeText(shareData.url).then(() =>
          window.addLog({
            show: true,
            upload: false,
            userMessage: "share.copy",
          })
        );
      }
    }
  };
  const intl = useIntl();

  useEffect(() => {
    setTargetFeature(getNodeById(target.id, POI_NODES));
  }, [target, POI_NODES]);

  return (
    <div className={`${classes.openContent} mr-3 ml-3 mt-3`}>
      {targetFeature ? (
        <div
          className={`${classes.targetInformation} general-margin-bottom-large`}
        >
          <b className="general-margin-bottom">
            <FormattedMessage id="navigation.targetInformation" />
          </b>
          <div className="ca-bg-light general-padding-top general-margin-large-horizontally general-padding-large-bottom rounded-1">
            <h4>{targetFeature.name}</h4>
            <p>{targetFeature.address}</p>
          </div>
        </div>
      ) : null}
      {!isTerminal ? (
        <div className="general-padding-large-bottom">
          <b className="general-margin-bottom">
            <FormattedMessage id="navigation.shareTitle" />
          </b>
          {window.navigator.onLine &&
          start.isSet &&
          target.isSet &&
          MODULES.hatDruckversion ? (
            <StandardButton
              icon={
                isGeneratingPDF ? ["fal", "circle-notch"] : ["fal", "download"]
              }
              iconSpinning={isGeneratingPDF}
              disabled={isGeneratingPDF}
              buttonClasses={"normalButton--primary mr-2 mb-2"}
              handleClick={() => {
                statisticLogger.addLog({
                  action: {
                    group: "Route",
                    id: "routePrintClick",
                    movement: "stay",
                    name: "Click Print Route",
                    type: "click",
                    interaction: "execute",
                  },
                });
                downloadPDF();
              }}
            >
              {isGeneratingPDF ? (
                <FormattedMessage id="navigation.pdfIsGenerating" />
              ) : (
                <FormattedMessage id="navigation.saveRoute" />
              )}
            </StandardButton>
          ) : null}
          {!(isFramed && isChrome) && copyToClipboardIsAllowed ? (
            <StandardButton
              handleClick={handleShare}
              buttonClasses={"normalButton--primary"}
              icon={["fal", "share"]}
            >
              <FormattedMessage id="navigation.share" />
            </StandardButton>
          ) : null}
        </div>
      ) : (
        <GeneratedQRCode
          text={<FormattedMessage id={"qrCode.scanToGetWayLink"} />}
          className={"mb-3"}
          isTerminal={isTerminal}
        />
      )}
      {MISC_INFO.disabledRouteSettings ? null : (
        <div>
          <b className="general-margin-bottom">
            <FormattedMessage id="navigation.routeSettingsTitle" />
          </b>
          <RouteSettings
            isTerminal={isTerminal}
            settings={settings}
            text={intl.formatMessage({ id: "settings.routeSettingsText" })}
            setProperties={setProperties}
          />
        </div>
      )}
    </div>
  );
}
