import StandardToggleButton from "./toggle.button.jsx";
import StandardInstruction from "../text/standardInstruction.component.jsx";

export default function ToggleSetting({
  settingTitle,
  settingText,
  onChange,
  toggleValue,
  errorText,

  outerClassName = "mb-4",
}) {
  return (
    <div className={"w-100 " + outerClassName}>
      <div className="d-flex align-items-center w-100">
        <div className="mr-auto">
          {settingTitle && <h3>{settingTitle}</h3>}
          <StandardInstruction>{settingText}</StandardInstruction>
        </div>
        <StandardToggleButton
          className={"ml-3"}
          value={toggleValue}
          onChange={onChange}
        />
      </div>
      {errorText && <p className={"text-danger"}>{errorText}</p>}
    </div>
  );
}
