import React, { useEffect, useReducer, useRef, useState } from "react";
import { connect } from "react-redux";
import { setFeatures } from "../mapbox/functions/drawer.map.js";
import * as mapAction from "../../../reduxStore/actions/map";
import * as routeAction from "../../../reduxStore/actions/route";
import * as actionRoute from "../../../reduxStore/actions/route";
import {
  setGoToParkingSpot,
  setRoadUserType,
  setSimulatedLocation,
} from "../../../reduxStore/actions/route";
import * as floorAction from "../../../reduxStore/actions/floors";
import { FormattedMessage, useIntl } from "react-intl";
import { ActionBubble } from "../actions/actionBubbles/actionBubbles.component";
import LegChangeInfo from "./legChange/LegChange.component";
import SplashScreen from "../../elements/splashScreen/splashScreen.render";
import ToastsContainer from "../../elements/toast/toasts.container";
import ElevatorSVG from "../../../assets/images/navigation/elevatorSVG.js";
import StairsSVG from "../../../assets/images/navigation/stairsSVG.js";
import EntranceSVG from "../../../assets/images/navigation/entranceSVG.js";
import ExitSVG from "../../../assets/images/navigation/exitSVG.js";
import { withRouter } from "react-router-dom";
import ParkingSVG from "../../../assets/images/navigation/parkingSVG.js";
import { pageNames } from "../../../reduxStore/reducers/main.js";
import SaveZoneWrapper from "../../elements/wrapper/saveZone.wrapper.jsx";
import * as actionSettings from "../../../reduxStore/actions/settings.js";
import { map } from "../mapbox/map.sideEffects/useMap.sideEffects.js";
import { isMobile } from "react-device-detect";
import { sourceIds } from "../mapbox/variables/sourceIds.js";
import {
  setBLEData,
  setGPSData,
  setHeading,
  setNavigationMode,
} from "../../../reduxStore/actions/navigation.js";
import {
  NavigationControls,
  scanner,
} from "./subComponents/navigationControls.component.jsx";
import { NavigationInstructions } from "./subComponents/navigationInstructions.component.jsx";
import { useLiveNavigation } from "./sideEffects/useLiveNavigation.sideEffect.js";
import { useNavigationMode } from "./sideEffects/useNavigationMode.sideEffect.js";
import { useArrowAndCam } from "./sideEffects/useArrowAndCam.sideEffect.js";
import { useLegChange } from "./sideEffects/miscelleanous.sideEffects.js";
import { useSpeech } from "./sideEffects/useSpeech.sideEffects.js";
import { addToast } from "../../../reduxStore/actions/toasts.js";
import { forceFeatureFilterUpdate } from "../../../reduxStore/actions/map";
import { Modal } from "react-bootstrap";
import StandardButton from "../../elements/buttons/standard.button.jsx";
import StandardHeadline from "../../elements/text/standardHeadline.text.jsx";
import StandardCard from "../../elements/cards/StandardCard.card.jsx";
import useSBSNavigation from "./sideEffects/useSBSNavigation.sideEffects.js";
import DragContainer from "../../elements/dragContainer/dragContainer.container.jsx";
import StandardText from "../../elements/text/standardText.text.jsx";
import StandardSubline from "../../elements/text/standardSubline.text.jsx";
import { statisticLogger } from "../../statisticLogger/StatisticLogger.container.js";
import {
  APP_SETTINGS,
  CAMPUS_COLLECTION,
  MISC_INFO,
  POIS,
} from "../../../reduxStore/actions/main.js";
import TopRightBubbles from "../actions/MapControls.component.jsx";
import {
  piKey,
  terminalNavigationLock,
} from "../../../app/app.sideEffects/useURLParams.sideEffects.js";
import {
  cancelDataScanner,
  startDataScanner,
  vibrateDevice,
} from "../../nativeAppInterface/NativeAppInterface";
import { center, point, points } from "@turf/turf";
import StartDestination from "../../../functions/classes/startDestination.class";
import { isInDevelopmentMode } from "../../../functions/helper/helpers.functions";
import SlopSVG from "../../../assets/images/navigation/slopSVG";
import * as actionMap from "../../../reduxStore/actions/map";

const legChangeTypes = {
  enterElevator: "enterElevator",
  enterParkingSpot: "enterParkingSpot",
  enterBuilding: "enterBuilding",
  leaveBuilding: "leaveBuilding",
  enterStairs: "enterStairs",
  enterSlope: "enterSlope",
};

let scanListNavigation = [];

/**
 *
 * @type {NavigationController}
 */
export let navigationController = null;
export let isInParkingModeGlobal = false;
export function setNavigationController(nC) {
  navigationController = nC;
}

export let PROHIBIT_SPEAK = false;
export let PROHIBIT_NEW_SPEAK = false;

let lastPOIID = null;
let firstPOIID = null;
/**
 * Handled die Logik und das Rendering der Navigation mithilfe der Routeklasse.
 * Anders als normale Components verlässt sich diese Component nicht wirklich auf States, sondern
 * settet und gettet Infos aus der Route.class.js und macht dann mit diesen neuen Daten ein forceUpdate()
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
function Navigation(props) {
  // Wenn in einem Terminal, dann muss nicht zwingend jeder Step angezeigt werden!
  const oneStepPerLeg = !!props.isTerminal;

  // Ein komplettes Leg kann der Teil der Route sein vom Eingang des Gebäudes
  // bis zum Lift und es besteht aus
  const currentLeg = props.currentRoute.getCurrentLeg();
  const nextLeg = props.currentRoute.getNextLeg();
  // mehreren Steps
  let currentStep = currentLeg.getCurrentStep();

  // Da die Route von einer Klasse gesteuert wird, muss dieser Componentn separat upgedatet werden
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const intl = useIntl();

  // --- States ---
  // Legt fest, ob ein Splash Screen gezeigt werden soll
  // ( wenn z.B. ein Gebäude betreten wird oder Etagenwechsel stattfindet)
  const [legComplete, setLegComplete] = useState({});
  // Diese Distance ist für die headsup Anzeige in Intervallen (z.B. 20m ... 10m ... 5m )
  const [bannerStepDistance, setBannerStepDistance] = useState(null);
  const [showLegChangeScreen, setShowLegChangeScreen] = useState(false);
  const [isInParkingMode, setIsInParkingMode] = useState(false);
  const [userHasParked, setUserHasParked] = useState(false);
  const [userMovesMap, setUserMovesMap] = useState(false);
  const [userHasReachedDestination, setUserHasReachedDestination] =
    useState(false);
  const [showCloseNavigationModal, setShowCloseNavigationModal] =
    useState(false);

  // --- Refs ---
  const keepRoute = useRef(false);

  useEffect(() => {
    isInParkingModeGlobal = isInParkingMode;
  }, [isInParkingMode]);

  useEffect(() => {
    return () => {
      isInParkingModeGlobal = false;
    };
  }, []);

  // --- Functions ---
  const handleNextStepClick = () => {
    if (props.currentRoute.isNextStepOtherLeg()) {
      // Beim Legwechsel gibts einen Splash Screen
      if (currentLeg?.legComplete?.legChangeType?.type === "enterParkingSpot") {
        // oder einen auffahrenden dragcontainer
        setIsInParkingMode(true);
      }
      console.log(currentLeg.legComplete);
      setLegComplete(currentLeg.legComplete);
      setShowLegChangeScreen(true);
    } else {
      _continueRoute();
    }
  };
  const handlePrevStepClick = () => {
    if (oneStepPerLeg) {
      props.currentRoute.setPrevLeg();
      currentLeg.setLastStep();
    } else {
      props.currentRoute.setPrevStep();
    }

    forceUpdate();
  };

  useEffect(() => {
    PROHIBIT_SPEAK = false;
    PROHIBIT_NEW_SPEAK = false;
  }, []);

  const _continueRoute = () => {
    props.currentRoute.nextStep();
    setShowLegChangeScreen(false);
    forceUpdate();
  };
  const handleLegChangeNextClick = () => {
    const _setUserHasParked = () => {
      setUserHasParked(true);
      // roadusertype ab hier auf fußgänger stellen nach dem parken
      props.setRoadUserType(8);
      props.setGoToParkingSpot(false);
      setIsInParkingMode(false);
    };

    if (isInParkingMode) {
      _setUserHasParked();
    }
    _continueRoute();
  };
  const handleCloseNavigation = () => {
    try {
      statisticLogger.addLog({
        action: {
          group: "Navigation",
          id: "cancelNavigation",
          movement: "leave",
          name: "Cancel Navigation",
          type: "click",
          interaction: "cancel",
          content: {
            globalPoiIds: [props.start.globalPoiId, props.target.globalPoiId],
            campusId: CAMPUS_COLLECTION.getCurrentCampus().id,
            startGlobalPoiId: props.start.globalPoiId,
            destinationGlobalPoiId: props.target.globalPoiId,
            startZIndex: props.start.level,
            startLatitude: props.start.lat,
            startLongitude: props.start.lng,
            destinationLatitude: props.target.lat,
            destinationLongitude: props.target.lng,
          },
        },
      });
    } catch (e) {
      console.warn(e);
    }
    setShowCloseNavigationModal(true);
  };
  const _closeNavigation = () => {
    keepRoute.current = true;
    props.history.goBack();
  };
  const getLegCompleteImage = () => {
    if (!legComplete || !legComplete.legChangeType) return null;

    switch (legComplete.legChangeType.type) {
      case legChangeTypes.enterBuilding:
        return <EntranceSVG />;
      case legChangeTypes.leaveBuilding:
        return <ExitSVG />;
      case legChangeTypes.enterElevator:
        return (
          <ElevatorSVG
            text={intl.formatMessage(
              {
                id: "navigation.level",
              },
              { level: legComplete.legChangeType.value }
            )}
          />
        );
      case legChangeTypes.enterParkingSpot:
        return <ParkingSVG />;
      case legChangeTypes.enterSlope:
        return <SlopSVG />;
      case legChangeTypes.enterStairs:
        return (
          <StairsSVG
            direction={currentLeg.level < nextLeg.level ? "up" : "down"}
          />
        );
      default:
        return null;
    }
  };
  const mountComponent = () => {
    props.setPage(pageNames.navigation);
    // Map setzen für gescheite Zoom einstellungen
    statisticLogger.currentPage = pageNames.navigation;
    statisticLogger.addLog({
      action: {
        id: "enterNavigationPage",
        name: "Enter Navigation Page",
        group: "Page",
        movement: "enter",
        type: "click",
      },
    });
    // unmount -> wichtig, dass alles hier "beendet" wird, weil
    // die navigation vielleicht mal nicht durch "handleClose" beendet werden könnte
    return () => {
      // Route nicht entfernen, wenn auf "X" gedrückt wird -> Weil "Route" diese noch braucht zum anzeigen
      // Route wird NUR entfernt, wenn man von hier aus irgendwie anders zu einer anderen Page gelangt!
      if (!keepRoute.current && !terminalNavigationLock) {
        setFeatures({
          sourceId: sourceIds.routeSymbole,
          features: [],
        });
        setFeatures({
          sourceId: sourceIds.kanten,
          features: [],
        });
        props.resetRoute();
        props.resetStart();
        props.resetTarget();
      }

      // Arrows nur in Navi zu sehen - müssen immer entfernt werden
      setFeatures({ sourceId: sourceIds.arrowKanten, features: [] });
      setFeatures({ sourceId: sourceIds.arrowHead, features: [] });
    };
  };

  // --- Effects ---
  // Kamera und Arrow setzen nach jedem Schritt
  useArrowAndCam(
    currentLeg,
    currentStep,
    nextLeg,
    oneStepPerLeg,
    userMovesMap,
    props.currentRoute,
    props.locationObject,
    props.mapPadding,
    props.setCurrentFloorNumber,
    props.liveNavigationInformation,
    props.navigationMode,
    props.setMapPadding,
    props.isTerminal,
    props.terminalBearing
  );

  // Etage anpassen
  useLegChange(
    props.setCurrentFloorNumber,
    props.currentRoute,
    currentLeg,
    props.forceFeatureFilterUpdate
  );

  useNavigationMode(
    props.isApp,
    props.locationSettings,
    props.locationObject,
    props.navigationMode,
    props.currentRoute,
    currentLeg,
    props.setNavigationMode,
    props.addToast
  );

  const routingObject = {
    target: props.target,
    settings: props.routingSettings,
    roadUserType: props.roadUserType,
    goToParkingSpot: props.goToParkingSpot,
    language: props.language,
    deviceInformation: props.deviceInformation,
    isApp: props.isApp,
    isTerminal: props.isTerminal,
  };

  const { isRerouting, stepLineProgress, userWarning, canEndLiveNavigation } =
    useLiveNavigation(
      props.liveNavigationInformation,
      props.navigationMode,
      forceUpdate,
      props.currentRoute,
      handleNextStepClick,
      handlePrevStepClick,
      setShowLegChangeScreen,
      setLegComplete,
      props.locationObject,
      routingObject,
      props.setStart,
      props.setRoute,
      setUserMovesMap,
      props.setSimulatedLocation,
      currentStep,
      props.addToast,
      currentLeg,
      nextLeg,
      setBannerStepDistance,
      props.routeErrorCounter,
      props.history,
      props.isApp,
      setIsInParkingMode,
      isInParkingMode,
      userHasParked,
      showLegChangeScreen,
      props.start,
      props.target
    );

  useSBSNavigation(
    currentStep,
    setBannerStepDistance,
    oneStepPerLeg,
    currentLeg,
    props.isTerminal
  );

  useSpeech(
    currentStep,
    props.isApp,
    props.needAudio,
    props.locationObject,
    null,
    props.navigationMode,
    props.language,
    stepLineProgress,
    showLegChangeScreen,
    currentLeg
  );

  useEffect(mountComponent, []);

  useEffect(() => {
    if (props.isTerminal) {
      props.setViewIsIndoors(false);

      setTimeout(() => {
        props.setViewIsIndoors(true);
      }, 100);

      map.setFilter("cu_buildings_geojson", [">", ["zoom"], 22]);
    }

    return () => {
      if (props.isTerminal) {
        map.setFilter("cu_buildings_geojson", ["<", ["zoom"], 17]);
      }
    };
  }, [props.isTerminal]);

  const scanDoorSign =
    props.dataScannerAvailable &&
    MISC_INFO.dataScanner.isAllowed &&
    //currentLeg.isIndoor &&
    (isInDevelopmentMode() ||
      piKey === "UKT-7wbrGmoTcGMsoSHnYvS10mCRTb76Ws1uolN3s5kdLJUj9M");

  window.didScanText = (text) => {
    if (piKey === "UKT-7wbrGmoTcGMsoSHnYvS10mCRTb76Ws1uolN3s5kdLJUj9M") {
      scanListNavigation.push(text);
      if (scanner) {
        scanner.addText(text);
      }
      return;
    }

    if (!firstPOIID) {
      firstPOIID = props.start.id;
    }
    let feature;
    feature = Object.values(POIS.all_objects).find(
      (p) =>
        p.type === "Raum" &&
        p.name.replaceAll(".", "").replaceAll(" ", "") ===
          text.replaceAll("-", "")
    );

    if (!feature) {
      feature = Object.values(POIS.all_objects).find(
        (p) => p.type === "Raum" && p.name === text
      );

      if (!feature) {
        return;
      }
    }

    cancelDataScanner();

    if (feature.globalPoiId === props.target.globalPoiId) {
      setShowLegChangeScreen(true);
      setUserHasReachedDestination(true);
      return;
    }

    const vertices = CAMPUS_COLLECTION.getAllCampusNodes();

    const entrances = feature.entrances.reduce((result, entry) => {
      const vertex = vertices.find((v) => v.id === entry.nodeId);
      if (!vertex) return result;

      result.push([vertex.coord[1], vertex.coord[0]]);
      return result;
    }, []);

    const c = center(points(entrances));

    props.setRoute(
      new StartDestination({
        isSet: true,
        lat: c.geometry.coordinates[1],
        lng: c.geometry.coordinates[0],
        level: feature.level,
        positionType: "user",
        name: intl.formatMessage({ id: "gps.myLocation" }),
      }),
      props.target,
      props.routingSettings,
      props.roadUserType,
      props.goToParkingSpot,
      props.language,
      props.isApp,
      props.isTerminal
    );
    if (lastPOIID !== feature.id) {
      vibrateDevice();
    }
    lastPOIID = feature.id;
  };

  const setProhibitSpeak = (value) => {
    PROHIBIT_SPEAK = value;
  };

  const setProhibitNewSpeak = (value) => {
    PROHIBIT_NEW_SPEAK = value;
  };

  useEffect(() => {
    return () => {
      if (scanListNavigation.length) {
        statisticLogger.addLog({
          action: {
            id: "keinBockaufnamenesfindung",
            group: "navigation",
            name: "whats scanned",
            movement: "stay",
            type: "click",
            interaction: "select",
            content: scanListNavigation,
          },
        });

        scanListNavigation = [];
      }

      window.foundedBuilding = null;
      window.foundedFloor = null;
    };
  }, []);

  useEffect(() => {
    setProhibitSpeak(false);
  }, [props.currentRoute]);

  return (
    <div className={"d-flex flex-column h-100"}>
      {isMobile && (
        <NavigationInstructions
          setMapPadding={props.setMapPadding}
          handleClose={handleCloseNavigation}
          locationObject={props.locationObject}
          currentStep={currentStep}
          bannerStepDistance={bannerStepDistance}
        />
      )}
      <ToastsContainer />
      <TopRightBubbles>
        {props.isApp && (
          <ActionBubble
            className={"mb-2 p-0 pointer-event-on ml-auto"}
            action={() => props.setUseSound(!props.needAudio.value)}
            iconname={[
              "fal",
              props.needAudio.value ? "volume-up" : "volume-slash",
            ]}
            active={props.needAudio.value}
            ariaLabel={intl.formatMessage({
              id: `navigation.screenreader.speakInstructions.${
                props.needAudio.value ? "on" : "off"
              }`,
            })}
          />
        )}
      </TopRightBubbles>
      {!showLegChangeScreen ? (
        <NavigationControls
          setProhibitSpeak={setProhibitSpeak}
          isTerminal={props.isTerminal}
          language={props.language}
          goToParkingSpot={props.goToParkingSpot}
          roadUserType={props.roadUserType}
          routingSettings={props.routingSettings}
          target={props.target}
          isApp={props.isApp}
          setRoute={props.setRoute}
          bannerStepDistance={bannerStepDistance}
          navigationMode={props.navigationMode}
          setMapPadding={props.setMapPadding}
          handleClose={handleCloseNavigation}
          handlePrevStep={handlePrevStepClick}
          currentStep={currentStep}
          handleNextStep={handleNextStepClick}
          currentRoute={props.currentRoute}
          currentLeg={currentLeg}
          locationObject={props.locationObject}
          isRerouting={isRerouting}
          canEndLiveNavigation={canEndLiveNavigation}
          onEndNavigation={() => {
            setLegComplete(currentLeg.legComplete);
            setShowLegChangeScreen(true);
          }}
          showCenterPositionButton={userMovesMap}
          setUserMovesMap={setUserMovesMap}
          scanDoorSign={scanDoorSign}
        />
      ) : !isInParkingMode ? (
        <SplashScreen
          onClosingFinished={handleLegChangeNextClick}
          render={(startClosing) => (
            <SaveZoneWrapper>
              <LegChangeInfo
                setProhibitSpeak={setProhibitNewSpeak}
                type={legComplete.type}
                userWarning={userWarning.value}
                onlyUserWarning={userWarning.onlyUserWarning}
                title={legComplete.instruction}
                image={getLegCompleteImage()}
                isComplete={
                  userHasReachedDestination || !props.currentRoute.getNextStep()
                }
                onNextClick={startClosing}
                closeNavigation={handleCloseNavigation}
                showContinueButton={
                  props.navigationMode === "sbs" || nextLeg?.isLive === false
                }
                liveNavigationInformation={props.liveNavigationInformation}
              />
            </SaveZoneWrapper>
          )}
        />
      ) : (
        <DragContainer
          status={"preOpen"}
          interactionHandles={false}
          draggable={false}
        >
          <div className={"p-3 d-flex flex-column align-items-center"}>
            <div
              className={
                "d-flex justify-content-center align-items-center mb-3"
              }
              style={{ width: "10rem", height: "10rem" }}
            >
              {getLegCompleteImage()}
            </div>
            <StandardText>{legComplete.instruction}</StandardText>
            <StandardSubline className={"muted mb-3"}>
              <FormattedMessage id="navigation.legChange.parking" />
            </StandardSubline>
            <StandardButton
              buttonClasses={"normalButton--primary"}
              handleClick={handleLegChangeNextClick}
            >
              <FormattedMessage id="navigation.legChange.next" />
            </StandardButton>
          </div>
        </DragContainer>
      )}
      <CloseNavigationModal
        show={showCloseNavigationModal}
        setShow={setShowCloseNavigationModal}
        closeNavigation={_closeNavigation}
      />
    </div>
  );
}

const CloseNavigationModal = ({ show, closeNavigation, setShow }) => {
  const intl = useIntl();
  if (show) {
    return (
      <Modal centered={true} show={true} backdrop="static" keyboard={false}>
        <StandardCard className="d-flex flex-column align-items-center">
          <StandardHeadline className={"mb-3"}>
            {intl.formatMessage({ id: "navigation.askSureToFinish" })}
          </StandardHeadline>
          <div className={"d-flex justify-items-center"}>
            <StandardButton
              handleClick={closeNavigation}
              buttonClasses={"normalButton--primary mr-3"}
            >
              {intl.formatMessage({ id: "search.dialogDeleteYes" })}
            </StandardButton>
            <StandardButton handleClick={() => setShow(false)}>
              {intl.formatMessage({ id: "search.dialogDeleteNo" })}
            </StandardButton>
          </div>
        </StandardCard>
      </Modal>
    );
  } else return null;
};

const mapStateToProps = (state) => {
  return {
    dataScannerAvailable: state.main.dataScannerAvailable,
    isApp: state.main.isApp,
    isTerminal: state.main.isTerminal,
    terminalBearing: state.map.terminalBearing,
    needAudio: state.settings.useSound,
    currentFloorIndex: state.floors.currentFloorIndex,
    currentRoute: state.route.currentRoute,
    navigationMode: state.route.navigationMode,
    liveNavigationInformation: state.route.liveNavigationInformation,
    mapPadding: state.map.mapPadding,
    locationSettings: state.settings.location,
    locationObject: state.route.locationObject,
    centerLat: state.map.lat,
    centerLng: state.map.lng,
    target: state.route.target,
    start: state.route.start,
    routeErrorCounter: state.route.routeErrorCounter,
    routingSettings: state.settings.routing,
    roadUserType: state.route.roadUserType,
    goToParkingSpot: state.route.goToParkingSpot,
    language: state.main.language,
    deviceInformation: state.main.deviceInformation,
    viewIsIndoors: state.map.viewIsIndoors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setViewIsIndoors: (value) => dispatch(actionMap.setViewIsIndoors(value)),
    forceFeatureFilterUpdate: () => dispatch(forceFeatureFilterUpdate()),
    toggle3DBuildings: (value) => dispatch(mapAction.toggle3DBuildings(value)),
    resetRoute: () => dispatch(routeAction.resetRoute()),
    setPage: (page) => dispatch(mapAction.setPage(page)),
    setCurrentFloorNumber: (number, bounce) =>
      dispatch(floorAction.setCurrentFloorNumber(number, bounce)),
    resetStart: () => dispatch(actionRoute.resetStart()),
    resetTarget: () => dispatch(actionRoute.resetTarget()),
    setStart: (feature) => dispatch(actionRoute.setStart(feature)),
    setTarget: (feature) => dispatch(actionRoute.setTarget(feature)),
    setRoute: (
      start,
      target,
      settings,
      type,
      goToParkingSpot,
      language,
      deviceInformation,
      isApp,
      isTerminal
    ) =>
      dispatch(
        actionRoute.setRoute(
          start,
          target,
          settings,
          type,
          goToParkingSpot,
          language,
          deviceInformation,
          isApp,
          isTerminal
        )
      ),
    setBLEData: (data) => dispatch(setBLEData(data)),
    setGPSData: (data) => dispatch(setGPSData(data)),
    setHeading: (heading) => dispatch(setHeading(heading)),
    setMapPadding: (mapPadding, tellMap) =>
      dispatch(mapAction.setMapPadding(mapPadding, tellMap)),
    setUseSound: (value) =>
      dispatch(actionSettings.setProperties({ useSound: { value: value } })),
    setNavigationMode: (mode) => dispatch(setNavigationMode(mode)),
    setSimulatedLocation: (locationObject) =>
      dispatch(setSimulatedLocation(locationObject)),
    addToast: (toast) => dispatch(addToast(toast)),
    setRoadUserType: (type) => dispatch(setRoadUserType(type)),
    setGoToParkingSpot: (value) => dispatch(setGoToParkingSpot(value)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Navigation)
);
