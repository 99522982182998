import { map } from "../../map.sideEffects/useMap.sideEffects.js";
import { STYLINGVALUES } from "../../variables/layerStylingValues.js";
import { EXPRESSIONS } from "../../variables/expressions.js";
import { CUSTOMIZABLE_VALUES } from "../../variables/CUSTOMIZABLE_VALUES.js";

/**
 * Die Symbole, die aussehen, wie ein umgedrehter Tropfen
 * Routensymbole und Campussymbol
 */
export function createPinSymbols(layers, sourceId, filter = true) {
  // Symbols
  const iconColor = [
    "case",
    ["==", ["get", "iconColor"], "start"],
    CUSTOMIZABLE_VALUES.start_node,
    ["==", ["get", "iconColor"], "transit"],
    CUSTOMIZABLE_VALUES.transit_node,
    CUSTOMIZABLE_VALUES.route_node_symbol,
  ];
  const circleIconOffset = [0, -55];
  const symbolIconOffset = [0, -48];


  // [
  //   layerIds.nodes.route.coloredBg,
  //   layerIds.nodes.route.whiteBg,
  //   layerIds.nodes.route.symbol,
  // ],

  if (!map.getLayer(layers[0])) {
    map.addLayer({
      id: layers[0],
      type: "symbol",
      source: sourceId,
      filter: filter,
      layout: {
        "icon-image": "pinSymbol",
        "icon-size": 1.3,
        "icon-allow-overlap": true,
        "icon-anchor": "bottom",
        "text-allow-overlap": true,
        "text-font": CUSTOMIZABLE_VALUES.defaultMapFont,
        "text-field": EXPRESSIONS.misc.name,
      },
      paint: {
        "text-translate": [0, 10],
        "text-halo-width": 2,
        "text-halo-color": "#fff",
        "icon-color": [
          "case",
          EXPRESSIONS.state.marking,
          CUSTOMIZABLE_VALUES.route_node_marking,
          CUSTOMIZABLE_VALUES.route_node_bg,
        ],
        "icon-opacity": [
          "case",
          ["==", ["get", "showOnAllLevels"], true],
          1,
          [
            "case",
            EXPRESSIONS.state.differentLevel,
            STYLINGVALUES.symbols.opacity.navigation.ghosted,
            1,
          ],
        ],
      },
    });
  }
  if (!map.getLayer(layers[1])) {
    map.addLayer({
      id: layers[1],
      type: "symbol",
      source: sourceId,
      filter: filter,
      layout: {
        "icon-image": "circle",
        "icon-offset": circleIconOffset,
        "icon-allow-overlap": true,
        "icon-size": 0.65,
      },
      paint: {
        "icon-color": CUSTOMIZABLE_VALUES.white,
        "icon-opacity": [
          "case",
          ["==", ["get", "showOnAllLevels"], true],
          1,
          [
            "case",
            EXPRESSIONS.state.differentLevel,
            STYLINGVALUES.symbols.opacity.navigation.ghosted,
            1,
          ],
        ],
      },
    });
  }
  if (!map.getLayer(layers[2])) {
    map.addLayer({
      id: layers[2],
      type: "symbol",
      source: sourceId,
      filter: filter,
      layout: {
        "icon-allow-overlap": true,
        "icon-image": [
          "case",
          EXPRESSIONS.type.start,
          "start",
          EXPRESSIONS.type.destination,
          "target",
          EXPRESSIONS.type.switchFloor,
          "switchFloor",
          EXPRESSIONS.type.parkingSpot,
          "parking",
          EXPRESSIONS.type.markingSpot,
          "target",
          EXPRESSIONS.type.buildingEntry,
          "switchFloor",
          EXPRESSIONS.type.campus,
          "campus",
          "",
        ],
        "icon-offset": symbolIconOffset,
        "icon-size": 0.75,
      },
      paint: {
        "icon-color": iconColor,
        "icon-opacity": [
          "case",
          ["==", ["get", "showOnAllLevels"], true],
          1,
          [
            "case",
            EXPRESSIONS.state.differentLevel,
            STYLINGVALUES.symbols.opacity.navigation.ghosted,
            1,
          ],
        ],
      },
    });
  }
}
export let customLayer;
