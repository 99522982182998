import { point } from "@turf/turf";
import { setFeatures } from "../../mapbox/functions/drawer.map.js";
import { useEffect, useRef, useState } from "react";
import { easeToRoute } from "../route.component.jsx";
import StartDestination from "../../../../functions/classes/startDestination.class.js";
import { sourceIds } from "../../mapbox/variables/sourceIds.js";
import { piKey } from "../../../../app/app.sideEffects/useURLParams.sideEffects";

/**
 * Start und Target werden im RouteHeader gesetzt, daraufhin in der SearchCard ein Effekt aufgerufen.
 * Dieser fetcht dann die Route und setzt das State "fetchNewRoute" auf true und setzt andere Daten relevant zur Route.
 * Dieser Effect hier sorgt dann dafür dass die neue Route auf der Karte gerendert wird
 */
export function useRoutesAndViews(props) {
  const initCall = useRef(true);

  const [forceFetchRoute, setForceFetchRoute] = useState(0);
  const resetRoute = () => {
    props.resetRoute();

    // resetten der Features
    renderWayPointsToMap();
    renderRoute();
  };
  const setNewRoute = () => {
    // Setzt die Route, wenn der obere Effekt einen Fetch hat
    // Startet sie aber nicht, der Startbefehl ist im <StartRouteCard />
    if (!props.mapLoaded) return;

    if (!props.currentRoute) {
      setStartAndTargetFeatures();
    } else {
      // falls man von navigation kommt -> Steps resetten
      props.currentRoute.resetPosition();
      // Rendern der Route mit Ihre Start und Ziel Icons

      // Haben wir einen Punkt? Kann auch sein, dass es keiner ist.
      const myPoint = props.target.getPoint();

      let targetLevel = null;
      let startLevel = null;

      if (
        myPoint != null &&
        myPoint.properties != null &&
        myPoint.properties.level != null
      ) {
        targetLevel = props.target.getPoint().properties.level;
        startLevel = props.currentRoute.legs[0].level;
        // console.log("Target level", targetLevel);
      }

      renderWayPointsToMap(props.currentRoute.legs, targetLevel);
      renderRoute(props.currentRoute, targetLevel);
      // nach dem Rendern wird useFilter nicht mehr aufgerufen, daher muss das manuell nochmal geschehen
      props.forceFeatureFilterUpdate();

      // console.log("Target level", props.target.getPoint().properties.level);

      // Etage auf den Startpunkt wechseln!
      if (piKey === "UKT-7wbrGmoTcGMsoSHnYvS10mCRTb76Ws1uolN3s5kdLJUj9M") {
        if (targetLevel !== null) {
          props.setFloorNumber(targetLevel, false);
        } else {
          props.setFloorNumber(props.currentRoute.legs[0].level, false);
          // nach dem setzen der neuen Route soll die ganze Route angezeigt werden
        }
      } else {
        if (startLevel !== null) {
          setTimeout(() => {
            props.setFloorNumber(props.currentRoute.legs[0].level, false);
          }, 800);
        } else {
          props.setFloorNumber(
            props.currentRoute.legs[props.currentRoute.legs.length - 1].level,
            false
          );
          // nach dem setzen der neuen Route soll die ganze Route angezeigt werden
        }
      }
    }
  };
  const setFetchedRoute = () => {
    if (!props.fetchNewRoute) return;

    props.setFetchNewRoute(false);
    // Neue Route wurde gefetcht und existiert!

    if (props.start?.isSet && props.target?.isSet) {
      resetRoute();
      // Neue Route setzen und rendern
      props.setRoute(
        props.start,
        props.target,
        props.settings,
        props.roadUserType,
        props.goToParkingSpot,
        props.language,
        props.deviceInformation,
        props.isApp,
        props.isTerminal
      );
    }
  };
  const setStartAndTargetFeatures = () => {
    // setzt entweder Start, Target oder beides
    const features = [];

    if (props.target?.isSet) {
      let targetPoint = props.target.getPoint();
      if (targetPoint) {
        let endMarker = point(targetPoint.geometry.coordinates, {
          type: StartDestination.types.destination,
          // level: props.target.level,
          level: targetPoint.properties.level,
        });
        endMarker.id = 1;
        features.push(endMarker);
        // console.log(
        //   "Ich hab einen End-Marker erstellt und er ist wie folgt: ",
        //   endMarker,
        //   "original targetPoint:",
        //   targetPoint,
        //   "props.target",
        //   props.target
        // );
        props.setFloorNumber(targetPoint.properties.level, false);
      }
    }

    if (props.start?.isSet) {
      let startPoint = props.start.getPoint();
      if (startPoint) {
        let startMarker = point(startPoint.geometry.coordinates, {
          type: StartDestination.types.start,
          level: props.start.level,
        });
        startMarker.id = 2;
        features.push(startMarker);
      }
    }

    // Das hier hat keine Wirkung???
    setFeatures({ sourceId: sourceIds.routeSymbole, features });
  };

  useEffect(() => {
    if (!props.start?.isSet || !props.target?.isSet) resetRoute();
  }, [props.start?.isSet, props.target?.isSet]);

  // Fetch new Route
  useEffect(setFetchedRoute, [
    props.fetchNewRoute,
    forceFetchRoute,
    props.roadUserType,
  ]);

  useEffect(setNewRoute, [
    props.currentRoute,
    props.start,
    props.target,
    props.mapLoaded,
  ]);

  // Camera only
  useEffect(() => {
    // wenn man setting position machen möchte, soll er nichts machen
    if (
      !props.mapLoaded ||
      props.start.setPositionOnMap ||
      props.target.setPositionOnMap ||
      !props.start.isSet ||
      !props.target.isSet ||
      !props.currentRoute
    )
      return;

    /** @type LineString */
    let routeLineString;
    if (props.currentRoute)
      routeLineString = props.currentRoute.getLineString();
    easeToRoute(props.start, props.target, props.mapPadding, routeLineString);
  }, [
    props.currentRoute,
    props.start,
    props.target,
    props.mapPadding,
    props.mapLoaded,
  ]);

  useEffect(() => {
    if (initCall.current) return;

    setForceFetchRoute((prevState) => ++prevState);
    console.log("setFetchNewRoute ->useRoutesAndViews");
    props.setFetchNewRoute(true);
  }, [props.settings, props.settings.movementRestricted]);

  useEffect(() => {
    initCall.current = false;
  }, []);
}

/**
 * Rendert Start-, End- und Zwischenkpunkte auf der Map. ‚
 * @param {Leg[]}legs
 * @return {Route}
 */
export const renderWayPointsToMap = (legs = [], targetLevel) => {
  // console.log("RenderWaypointstomap tL", targetLevel);

  let features = legs
    .map((leg, index) => leg.getWayPointsAsFeatures(index))
    .flat();

  // sollten bereits type destination sachen drin sein müssen diese raus
  // aus sicherheitsgründen setzen wir diese hier selbst
  features.forEach((f) => {
    if (
      f.properties.type === StartDestination.types.start ||
      f.properties.type === StartDestination.types.destination
    ) {
      f.properties.type = null;
    }
  });

  // console.log("ALLE MEINE ROUTE SYMBOLE. NFANG UND ENDE INTERESSANT", features);

  try {
    features[0].properties = {
      ...features[0].properties,
      type: StartDestination.types.start,
    };
    features[features.length - 1].properties = {
      ...features[features.length - 1].properties,
      type: StartDestination.types.destination,
      level:
        targetLevel != null
          ? targetLevel
          : features[features.length - 1].properties.level,
      showOnAllLevels:
        piKey === "zzUKT-7wbrGmoTcGMsoSHnYvS10mCRTb76Ws1uolN3s5kdLJUj9M",
      // piKey === "UKT-7wbrGmoTcGMsoSHnYvS10mCRTb76Ws1uolN3s5kdLJUj9M",
    };
  } catch (e) {}

  // zusätzlich zu jedem Leg einen Legchange Button!
  legs.forEach((leg, index) => {
    const firstStep = leg.steps[0];
    if (!firstStep) return;

    let firstFeatureOnLeg = firstStep.stepLine[0];
    // start und ziel symbol indikatoren müssen gesetzt werden, da diese nicht mehr mitgegeben werden
    // die mittleren Symbole kommen automatisch mit der route mit

    if (firstFeatureOnLeg) {
      firstFeatureOnLeg = point(firstFeatureOnLeg);
      firstFeatureOnLeg.id = 1000 + index;
      firstFeatureOnLeg.properties = {
        type: index === 0 ? null : StartDestination.types.legChange,
        level: leg.level,
      };
      features.push(firstFeatureOnLeg);
    }
  });
  // filtert die features raus, welche leer sind
  features = features.filter((f) => f.properties?.type != null);

  // console.log(
  //   "Fertige Feautres von routensymbolen",
  //   features,
  //   "tL",
  //   targetLevel
  // );

  setFeatures({
    sourceId: sourceIds.routeSymbole,
    features,
  });
};

/** Setzt die LineFeatures auf das mapbox objekt.
 *  Aber nur die Lines, die im momentanen oder zukünftigen Leg sind. Vergangene Legs werde nicht angezeigt!
 *  @param {Route | null} currentRoute
 */
export const renderRoute = (currentRoute, targetLevel) => {
  // für autos, fahrrad
  const features = [];
  // für fußgänger, behinderte

  if (currentRoute) {
    currentRoute.legs.forEach((leg, index) => {
      if (index >= currentRoute.legPos) {
        features.push({
          id: index,
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: leg.steps.map((s) => s.stepLine).flat(1),
          },
          properties: {
            order: index,
            isIndoor: leg.isIndoor,
            level: leg.level,
            mode: leg.mode,
          },
        });
      }
    });
    renderWayPointsToMap(currentRoute.legs, targetLevel);
  }
  setFeatures({
    sourceId: sourceIds.kanten,
    features: features,
  });
};
