import {
  zoomBuildingsEnd,
  zoomBuildings_normal_max,
  zoomImportant,
  zoomSymbolsEnd,
  zoomLowerCampus,
  zoomRooms,
  zoomSymbolsStart,
} from "./filter.map.js";
import { bbox, polygon, multiPolygon } from "@turf/turf";
import { getDeepestObjectValues } from "../../../../functions/helper/helpers.functions.js";
import { LayerEventHandler } from "../classes/layerEventHandler.class.js";
import { map } from "../map.sideEffects/useMap.sideEffects.js";
import { point } from "@turf/helpers";
import getAngle from "@turf/rhumb-bearing";
import { CAMPUS_COLLECTION } from "../../../../reduxStore/actions/main.js";
import { isMobile } from "react-device-detect";
import { sourceIds } from "../variables/sourceIds.js";
import { getLayerIds, layerIds } from "../variables/layerIds.js";
import { statisticLogger } from "../../../statisticLogger/StatisticLogger.container.js";
import {
  defaultStartNode,
  terminalBearing,
} from "../../../../app/app.sideEffects/useURLParams.sideEffects.js";

// Funktionen und Variablen, die nach der Initialisierung für die ganze Map verwendet werden können
let _toggleFullMap = () => {};
let _setCurrentFeature = () => {};
let _setBounds = () => {};
let _incrementMapClicks = () => {};
let _setMainMenuClose = () => {};
let _toggleMarkers = () => {};
let _setViewIsIndoors = () => {};
let _setCurrentFloorNumber = () => {};
let layerEventHandler;
let _canvas;
// wird benutzt um zu verhindern, das mapClick ausgeführt wird, obwohl ein poi geklickt wurde
let _poiClickTimestamp;

/**
 * Initialisierung der Map
 * Laden der Features
 * Hooken der Standard Listener
 * @param {mapboxgl} map
 * @param {statefunction} toggleIsFullMap
 * @param {statefunction} setCurrentFeature
 * @param {statefunction} setBounds
 * @param {[Node]}features
 * @param {statefunction} incrementMapClicks
 * @param setMainMenuClose
 * @param {statefunction} setIndoor
 */
export default function mapinteractivity({
  toggleIsFullMap,
  setCurrentFeature,
  setBounds,
  incrementMapClicks,
  setMainMenuClose,
  toggleMarkers,
  setViewIsIndoors,
  setCurrentFloorNumber,
}) {
  // Funktionen oben setzen, damit diese auch in anderen Funktionen außerhalb
  // der mapinteractivity Funktion verwendet werden können!
  _toggleFullMap = toggleIsFullMap ? toggleIsFullMap : _toggleFullMap;
  _toggleMarkers = toggleMarkers ? toggleMarkers : _toggleMarkers;
  _canvas = map ? map.getCanvas() : _canvas;
  _setMainMenuClose = setMainMenuClose;
  _incrementMapClicks = incrementMapClicks
    ? incrementMapClicks
    : _incrementMapClicks;
  _setCurrentFeature = setCurrentFeature
    ? setCurrentFeature
    : _setCurrentFeature;
  _setViewIsIndoors = setViewIsIndoors ? setViewIsIndoors : _setViewIsIndoors;
  // todo: diese Teile braucht man glaub ich überhaupt nicht!
  _setBounds = setBounds ? setBounds : _setBounds;
  _setCurrentFloorNumber = setCurrentFloorNumber
    ? setCurrentFloorNumber
    : _setCurrentFloorNumber;
  layerEventHandler = new LayerEventHandler(map);

  // Starten der ganzen Activities!
  onFeatureListener();
  onMapListener();
}
/** Funktion zum aktivieren des Mapclick listeners */
export function onMapListener() {
  map.on("moveend", mapMoveend);
  map.on("zoomend", mapMoveend);
  map.on("zoom", mapZoom);
}
/** callback für click auf map */
function mapClick(e) {
  const timestamp = +new Date();
  if (timestamp - _poiClickTimestamp < 50) return;

  // alle unsere layers auf features durchgehen um versehentlichen Mapclick zu verhindern!
  let queriedFeatures = map.queryRenderedFeatures(e.point, {
    layers: getLayerIds(layerIds),
  });
  // wenn die beim Click features von einem anderen Layer aufgetaucht sind
  // return !
  if (queriedFeatures.length) return;
  // Menü schließen, falls es geöffnet ist!
  _setMainMenuClose();
  _toggleFullMap();
  _incrementMapClicks();
  _setCurrentFeature(null);
}

/**
 * callback für zoom (feuert kontinuirlich während zoom)
 */
function mapZoom() {
  // Gebäude raus und reinfaden

  // polygonFade();

  /**
   * Filtert die Marker auf den Gebäuden (Namen) nach ZoomLevel ein oder aus.
   * Da diese nicht im normalen Mapbox Canvas sind
   * haben sie eine komische Verhakelung in jeweils mapDrawer und mapInteractivity
   * mapInteractivity ruft diese Funktion bei jeder ZoomÄnderung auf
   * mapDrawer macht das buildingMarkers Array
   */
  let zoom = map.getZoom();

  let toggleObject = {};
  // zoom innerhalb des HTML Markers sichtbarkeitsbereichs
  if (zoom < zoomBuildingsEnd && zoom > zoomImportant) {
    toggleObject = {
      // zoom innerhalb des bereichs für die html marker für "class.normal" gebäude
      normal: zoom > zoomBuildings_normal_max,
      // Hauptgebäude dürfen nur so weit zu sehen sein wie important sachen
      "main-building": zoom > zoomLowerCampus,
      // important wie z.b. parkplatz
      "parking-lot": true,
      "parking-garage": true,
    };
  } else {
    toggleObject = {
      normal: false,
      "main-building": false,
      "parking-lot": false,
      "parking-garage": false,
    };
  }
  _toggleMarkers(toggleObject);

  // Indoor und Outdoor States

  // PH: Das war auskommentiert, allerdings blendet dann der Ebenenwechsler nicht mehr ein. Verursacher war RL, damals auf der Bug-Suche bei der Navigation, wenn ich Recht erinnere. Das sollte hier aber eigentlich keinen Bug verursachen. Meine Meinung. #YOLO
  if (zoom >= zoomBuildingsEnd) {
    _setViewIsIndoors(true);
  } else {
    _setViewIsIndoors(false);
  }
}

/**
 *
 * @param {Object3D}obj
 * @param {number}opacity
 */
export function set3DMaterialOpacity(obj, opacity) {
  if (!obj) {
    console.warn("Kein objekt");
    return;
  }
  obj.children.forEach((child) => {
    set3DMaterialOpacity(child, opacity);
  });
  if (obj.material) {
    obj.material.opacity = opacity;
    obj.material.depthTest = true;
    obj.material.transparent = true;
  }
}

/**
 * Wird aufgerufen, wenn die Map bewegt oder gezoomt wird!
 * Überprüft Bounds
 * @param e
 */
let mapMoveeend_timeout;
function mapMoveend(e) {
  clearTimeout(mapMoveeend_timeout);
  mapMoveeend_timeout = setTimeout(() => {
    let mapBounds = map.getBounds();
    if (mapBounds._ne && mapBounds._sw)
      _setBounds({
        ne: { ...mapBounds._ne },
        sw: { ...mapBounds._sw },
        center: map.getCenter(),
      });
  }, 1000);
}
/**
 * Aktivieren der Listener für:
 * click, mouseenter, mouseleave auf Node/Polygon Features
 */
export function onFeatureListener() {
  offFeatureListener();
  map.on("click", mapClick);

  layerEventHandler.on(
    "click",
    [
      map.cuLayerIds.buildingGeoJSONs.common,
      map.cuLayerIds.buildingGeoJSONs.persistent,
      layerIds.texts.einrichtungen,
      layerIds.texts.rooms,
      layerIds.nodes.outerCircles,
      layerIds.nodes.poiChildren,
      layerIds.nodes.campus.symbol,
      //hier
      // layerIds.nodes.address.symbol,
    ],
    poiClick
  );

  // Navigations und Routeitems sollen auf die richtige Etage gehen, wenn man drauf drückt
  let navigationlayers = getDeepestObjectValues(layerIds.nodes.route).concat(
    getDeepestObjectValues(layerIds.kanten),
    getDeepestObjectValues(layerIds.arrow)
  );
  layerEventHandler.on("click", navigationlayers, navigationClick);
}
export function offFeatureListener() {
  map.off("click", mapClick);

  layerEventHandler.off("click", [
    // map.cuLayerIds.buildingGeoJSONs.common,
    map.cuLayerIds.buildingGeoJSONs.persistent,

    layerIds.texts.einrichtungen,
    layerIds.texts.rooms,
    layerIds.nodes.outerCircles,
    layerIds.nodes.poiChildren,
    layerIds.nodes.campus.symbol,
    //layerIds.nodes.address.symbol,
  ]);
}
function poiClick(e) {
  // console.log("Its a poiClick!!!!!!!!!!!!", e);
  const feature = e.eventFeatures[0];
  if (feature) {
    _poiClickTimestamp = +new Date();
    _setCurrentFeature(feature);
    statisticLogger.addLog({
      action: {
        id: "poiClickOnMap",
        name: "Poi on map clicked",
        group: "Map",
        movement: "stay",
        type: "click",
        interaction: "select",
        content: {
          globalPoiIds: [feature.properties.globalPoiId],
          globalPoiId: feature.properties.globalPoiId,
          globalName: feature.properties?.name,
        },
      },
    });
  }
}

function navigationClick(e) {
  if (e.eventFeatures[0] == null) return;
  _setCurrentFloorNumber(e.eventFeatures[0]?.properties?.level);
}

/**
 * Diese Funktion muss aufgerufen werden wenn sich das currentFeature ändert
 * um es anzuwählen oder abzuwählen (falls currentFeature === null ist)
 * @param {mapboxFeature, [mapboxFeature]}feature
 * @param mustFlyToFeature
 * @param flyOptions
 */
export function selectFeature(
  feature,
  mustFlyToFeature = true,
  flyOptions = {},
  selectOnlyOneSource = false
) {
  // Achtung: Der Mapmarker wird in mapFilter selected, da er je nach Stockwerk geändert werden muss
  // wozu der mapFilter zuständig ist!
  unselectAll(feature, selectOnlyOneSource);

  if (Array.isArray(feature)) {
    feature.forEach((f) => {
      setFeatureStateSelected(f);
    });
    // hier noch bounds machen
  } else {
    setFeatureStateSelected(feature);
    if (mustFlyToFeature && feature) {
      if (defaultStartNode != null) {
        terminalEaseToFeature(feature, flyOptions);
      } else {
        easeToFeature(feature, flyOptions);
      }
    }
  }
}
/**
 * @param {Node} selectedFeature
 */
function terminalEaseToFeature(selectedFeature, flyOptions) {
  try {
    let node = CAMPUS_COLLECTION.getCurrentCampus().getNode(defaultStartNode);
    let standPointFeature = point([node.coord[1], node.coord[0]]);
    let newCameraTransform = map.cameraForBounds(
      [
        selectedFeature.geometry.coordinates,
        standPointFeature.geometry.coordinates,
      ],
      {
        terminalBearing,
      }
    );
    map.stableEaseTo({
      ...newCameraTransform,
      bearing: terminalBearing,
      // 18.5 ist minimum zoom und 0.25 puffer
      zoom: Math.min(18.5, newCameraTransform.zoom - 0.25),
    });
  } catch (e) {
    console.log("error", e);
    easeToFeature(selectedFeature, flyOptions);
  }
}
/**
 * Jetzt den State im Feature auf selected
 * @param {feature} feature
 * @param {String} sourceId - SourceId
 */
function setFeatureStateSelected(feature) {
  const appSourceIds = getDeepestObjectValues(sourceIds);
  const mapSDKSourceIds = getDeepestObjectValues(map.cuSourceIds);

  const allSourceIds = appSourceIds.concat(mapSDKSourceIds);
  if (feature) {
    if (feature.source) {
      map.setFeatureState(
        { id: feature.id, source: feature.source },
        { selected: true }
      );
    } else {
      allSourceIds.forEach((source) => {
        // Da jedes Feature nur einmal vorhanden ist
        map.setFeatureState(
          { id: feature.id, source: source },
          { selected: true }
        );
      });
    }
  }
}
/**
 * Setzt alle Node Features wieder auf unselected.
 * iteriert dazu alle Sources durch, sodass wirklich nichts mehr ausgewählt ist!
 */
function unselectAll(feature, selectOnlyOneSource) {
  let sourceFeatures = [];
  if (!selectOnlyOneSource) {
    let realSourceIds = getDeepestObjectValues(sourceIds);
    realSourceIds = realSourceIds.concat(
      getDeepestObjectValues(map.cuSourceIds)
    );

    realSourceIds.forEach((sourceId) => {
      sourceFeatures = map.getStyle().sources[sourceId].data.features;
      if (Array.isArray(sourceFeatures)) {
        sourceFeatures.forEach((feature) => {
          if (
            feature &&
            feature.id != null &&
            map.getFeatureState({ id: feature.id, source: sourceId })?.selected
          ) {
            map.setFeatureState(
              {
                source: sourceId,
                id: feature.id,
              },
              {
                selected: false,
              }
            );
          }
        });
      } else {
        console.warn(`Source ${sourceId} hat keine Features`);
      }
    });
  } else {
    sourceFeatures = map.getStyle().sources[feature.source].data.features;
    sourceFeatures.forEach((feature) => {
      if (feature.id) {
        map.setFeatureState(
          {
            source: feature.source,
            id: feature.id,
          },
          {
            selected: false,
          }
        );
      }
    });
  }
}

/**
 * Flyto Funktion für normale Nodes und Polygone
 * @param {Polygon, Node, Kante , [ Node], BBox} feature - Können auch mehrere sein
 * @param {Object} flyOptions - Mapbox FlyTo Optionen
 */
export function easeToFeature(feature, flyOptions = {}) {
  if (!feature) return;
  // Unterscheiden nach featuretype
  let currentZoom = map.getZoom();
  // WTF? -> weil sich im DesktopModus nichts ändern darf! Das Padding darf nicht manipuliert werden!
  if (!isMobile) {
    flyOptions.padding = {};
  }

  let object = {
    zoom:
      currentZoom < zoomSymbolsEnd - 1.5
        ? zoomSymbolsEnd - 1.5
        : currentZoom > zoomRooms
        ? zoomSymbolsEnd - 1.5
        : currentZoom,
  };

  // ist es Ein echtes Feature?
  if (feature.geometry) {
    const polyCamera = (poly) => {
      let bounding = bbox(poly);
      let boundCameraOptions = map.cameraForBounds(bounding, {
        bearing: map.getBearing(),
      });
      // zoom gebäudekonform anpassen -> darf nicht zu hoch sein, damit man eben noch marker sehen kann
      if (boundCameraOptions.zoom > zoomBuildings_normal_max) {
        boundCameraOptions.zoom = zoomBuildings_normal_max + 0.1;
      } else if (boundCameraOptions.zoom < zoomLowerCampus) {
        boundCameraOptions.zoom = zoomLowerCampus;
      }

      object = {
        ...object,
        ...boundCameraOptions,
        zoom: boundCameraOptions.zoom,
      };
    };
    switch (feature.geometry.type) {
      case "MultiPolygon":
        try {
          let poly = polygon(feature.geometry.coordinates[0]);
          polyCamera(poly);
        } catch (e) {}
      case "Polygon":
        try {
          let poly = polygon(feature.geometry.coordinates);
          polyCamera(poly);
        } catch (e) {
          console.log("polygon camera oopsie", e);
        }
        break;
      case "Point":
        object = {
          zoom: zoomSymbolsStart > currentZoom ? zoomSymbolsStart : currentZoom,
          center: [
            feature.geometry.coordinates[0],
            feature.geometry.coordinates[1],
          ],
        };
        break;
    }
  } else {
    // offenbar kein Feature sondern nur coordinates
    if (feature.length === 4) {
      object = map.cameraForBounds(
        [
          [feature[0], feature[1]],
          [feature[2], feature[3]],
        ],
        flyOptions
      );
    } else {
      object = {
        ...object,
        zoom: 18,
        center: feature,
      };
    }
  }
  object = {
    ...object,
    ...flyOptions,
  };
  stableEaseTo(object);
}

/**
 * Changes to the level by zIndex.
 */
export const changeLevel = (zIndex) => {
  _setCurrentFloorNumber(CAMPUS_COLLECTION.getLevelByIndex(zIndex));
};

/**
 * Setzt die Kamera so, dass es von einem Punkt auf den anderen zeigt
 * @param {[lng, lat]} pos
 * @param {[lng, lat]} lookAt
 * @param showArrow
 */
export const setCameraPosition = (
  pos,
  lookAt,
  mapPadding = { top: 20, bottom: 20, left: 20, right: 20 },
  lessZoom = 0.5,
  duration = 2000,
  { customBearing } = {}
) => {
  if (!(pos && lookAt)) return;

  const point1 = point(pos);
  const point2 = point(lookAt);
  const bearing =
    customBearing != null ? customBearing : getAngle(point1, point2);

  let newCameraTransform = map.cameraForBounds([pos, lookAt], {
    bearing,
    // padding: mapPadding,
  });

  if (!newCameraTransform) {
    newCameraTransform = map.cameraForBounds([pos, lookAt], {
      bearing,
    });
  }
  // das muss gemacht, falls mehrere anfragen kommen
  // werden nicht alle erfüllt sondern nur das aktuellste!
  if (newCameraTransform) {
    stableEaseTo({
      center: newCameraTransform.center,
      zoom: newCameraTransform.zoom - lessZoom,
      padding: mapPadding,
      duration: duration,
      bearing,
    });
  }
};

export function getCurrentCampusCamera() {
  const currentCampus = CAMPUS_COLLECTION.getCurrentCampus();
  if (currentCampus.getPolygon().geometry.coordinates) {
    let campusBounding = bbox(currentCampus.getPolygon());
    return map.cameraForBounds([
      [campusBounding[0], campusBounding[1]],
      [campusBounding[2], campusBounding[3]],
    ]);
  }
}

let easeToTimer;
/** Diese Funktion soll dafür sorgen, dass EaseTo das mapobject nicht überlastet sondern immer nur das aktuellste genommen wird
 * (Falls es zu oft hintereinander aufgerufen wird!)
 * @param object - Kamera Object
 */
export function stableEaseTo(object = {}, forceFly = false) {
  clearTimeout(easeToTimer);

  const urlParams = new URLSearchParams(window.location.search);
  const bearing = +urlParams.get("bearing");
  const terminalZoom = +urlParams.get("terminalZoom");

  if (bearing) object.bearing = bearing;
  if (terminalZoom) object.zoom = terminalZoom;

  easeToTimer = setTimeout(() => {
    try {
      map.stop();
      if (object?.bearing != null || forceFly) {
        map.flyTo(object);
      } else {
        map.easeTo(object);
      }
    } catch (e) {
      console.error("map.easeTo hat schlechte parameter bekommen", object);
    }
  }, 120);
}
