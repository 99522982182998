import { useEffect, useRef, useState } from "react";
import { EVENTS_UNSORTED, NEWS } from "../../../reduxStore/actions/main.js";
import {
  addRelationToPOI,
  poitypes,
} from "../../../functions/classes/poi.class.js";
import { pageNames } from "../../../reduxStore/reducers/main.js";
import { piKey } from "../../../app/app.sideEffects/useURLParams.sideEffects.js";
import { getQueryParams } from "../../../app/RouteWithSubRoutes";

export const urlPostId = "postId";
/**
 * Verlinkt auf einen Beitrag, wenn man die App wegen einer Notification öffnet
 * @param props
 * @return {null}
 */
export default function usePushNotificationActions(
  serverDataLoaded,
  history,
  setNotificationPostId,
  introComplete
) {
  const [postId, setPostId] = useState(null);
  const [openReceivedPost, setOpenReceivedPost] = useState(false);
  const [postObject, setPostObject] = useState();

  const notificationSetTimeout = useRef();
  const notificationResetTimeout = useRef();

  const stateReset = () => {
    setPostId(null);
    setPostObject(null);
    setOpenReceivedPost(false);
  };

  const hookNativeNotificationReceiver = () => {
    window.didReceivePushNotification = (type, payload, openAutomatically) => {
      let poiId;

      if (type === "iOS") {
        poiId =
          payload?.aps?.CUMessage?.id != null
            ? payload?.aps?.CUMessage?.id
            : payload?.aps?.CUMessage?.globalPoiId;
        // poiId = payload?.aps?.CUMessage?.id;
      } else {
        poiId = payload?.id;
      }

      if (poiId) {
        setPostId(poiId);
        setOpenReceivedPost(openAutomatically);
      } else {
        if (payload?.CUMessage?.typ === "NEUIGKEIT_HINWEIS_PUSH") {
          history.push(`/${pageNames.news}/${getQueryParams()}`);
        } else if (payload?.CUMessage?.typ === "VERANSTALTUNG_HINWEIS_PUSH") {
          history.push(`/${pageNames.events}/${getQueryParams()}`);
        } else {
          console.warn("Received Message but didnt find PoiId", payload);
        }
      }
    };
  };
  const retreivePost = () => {
    if (!postId) return;

    const getPostLocally = () => {
      let post;
      post = NEWS.find((n) => n.id === postId);
      if (!post) {
        post = EVENTS_UNSORTED.find((e) => e.id === postId);
      }
      return post;
    };
    const fetchPost = () => {
      console.log(
        "fetch post",
        `${process.env.REACT_APP_GET_GLOBALPOI}?globalPoiId=${postId}&piKey=${piKey}`
      );
      fetch(
        `${process.env.REACT_APP_GET_GLOBALPOI}?globalPoiId=${postId}&piKey=${piKey}`
      )
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          console.log(json);
          if (!json) {
            console.log("Kein JSON?", json);
            return;
          }

          switch (json.type) {
            case poitypes.event:
              EVENTS_UNSORTED.push(json);
              if (json.relations) {
                addRelationToPOI(json, "events");
              }
              break;
            case poitypes.news:
              NEWS.push(json);
              if (json.relations) {
                addRelationToPOI(json, "news");
              }
              break;
          }

          setPostObject(json);
        })
        .catch((e) => {
          console.error(
            "Beitrag konnte weder lokal noch online gefunden werden",
            e.error
          );
        });
    };

    let post;
    post = getPostLocally();
    if (post) {
      console.log("post erhalten", post);
      setPostObject(post);
    } else {
      console.log("kein post erhalten");
      fetchPost();
    }
  };
  const handleNotification = () => {
    if (
      !serverDataLoaded ||
      !introComplete ||
      postId == null ||
      !postObject ||
      // öffne nur wenn native app es so wollte
      !openReceivedPost
    ) {
      clearTimeout(notificationResetTimeout.current);
      clearTimeout(notificationSetTimeout.current);
      return;
    }

    // um inkonsistenzen zu vermeiden bei folgendem Szenario zu vermeiden:
    // App ist aus, User kommt Benachrichtigung -> App startet, wartet 2 Sekunden Puffer, macht Notification move
    notificationSetTimeout.current = setTimeout(() => {
      if (postObject.type === poitypes.news) {
        setNotificationPostId(postObject.id);
        history.push(`/${pageNames.news}${window.location.search}`);
      }

      if (postObject.type === poitypes.event) {
        setNotificationPostId(postObject.id);
        history.push(`/${pageNames.events}${window.location.search}`);
      }

      notificationResetTimeout.current = setTimeout(() => {
        // reset
        setNotificationPostId(null);
      }, 5000);

      // Alles erledigt, jetzt sollte wieder resettet werden
      stateReset();
    }, 1500);
  };

  useEffect(retreivePost, [postId]);
  useEffect(handleNotification, [
    serverDataLoaded,
    introComplete,
    postObject,
    openReceivedPost,
  ]);
  useEffect(hookNativeNotificationReceiver, []);
  return null;
}
