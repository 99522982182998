import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import StandardButton from "../../elements/buttons/standard.button.jsx";
import React from "react";
import StandardCard from "../../elements/cards/StandardCard.card.jsx";
import { piKey } from "../../../app/app.sideEffects/useURLParams.sideEffects";

export default function AskForParkingSpot(props) {
  const closeModal = (needParkingSpot) => {
    props.closeModal(needParkingSpot);
  };
  return (
    <Modal centered={true} show={true} backdrop="static" keyboard={false}>
      <StandardCard>
        <h2>
          <FormattedMessage id="route.carTargetTitle" />
        </h2>
        <p>
          <FormattedMessage id="route.carTargetText" />
        </p>
        <div className={"d-flex justify-content-center flex-wrap"}>
          <StandardButton
            buttonClasses={
              "normalButton--primary normalButton--primary--parking m-3"
            }
            handleClick={() => closeModal(false)}
          >
            <FormattedMessage id="route.carTargetButton1Title" />
            {piKey === "UKT-7wbrGmoTcGMsoSHnYvS10mCRTb76Ws1uolN3s5kdLJUj9M" && (
              <FormattedMessage id="route.carTargetButton1TitleAdditional" />
            )}
          </StandardButton>
          <StandardButton
            buttonClasses={
              "normalButton--primary normalButton--primary--parking m-3"
            }
            icon={"parking"}
            handleClick={() => closeModal(true)}
          >
            <FormattedMessage id="route.carTargetButton2Title" />
          </StandardButton>
        </div>
      </StandardCard>
    </Modal>
  );
}
