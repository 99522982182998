import { Modal } from "react-bootstrap";
import StandardCard from "../../elements/cards/StandardCard.card";
import StandardHeadline from "../../elements/text/standardHeadline.text";
import StandardButton from "../../elements/buttons/standard.button";
import introVideo from "./intro.gif";
import React from "react";
import { useIntl } from "react-intl";

export default function ScannerInfo(props) {
  const intl = useIntl();

  if (!props.show) return null;

  return (
    <Modal centered={true} show={true} backdrop="static" keyboard={false}>
      <StandardCard className="d-flex flex-column align-items-center">
        <StandardHeadline className={"mb-3"}>
          {intl.formatMessage({ id: "scanner.dialog.title" })}
        </StandardHeadline>
        <div>
          <img
            src={introVideo}
            width={100}
            height={100}
            style={{
              width: "100%",
              height: "auto",
              marginBottom: "25px",
            }}
            alt=""
          />
        </div>
        <div className={"d-flex justify-items-center"}>
          <StandardButton
            handleClick={props.onClose}
            buttonClasses={"normalButton--primary w-100"}
          >
            {intl.formatMessage({ id: "scanner.dialog.continue" })}
          </StandardButton>
        </div>
      </StandardCard>
    </Modal>
  );
}
