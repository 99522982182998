import React from "react";

export default function StairsSVG({ direction = "up" }) {
  if (direction === "down") {
    return (
      <svg
        width="390px"
        height="390px"
        viewBox="0 0 390 390"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Screen / illustration / Treppe-runter</title>
        <g
          id="Screen-/-illustration-/-Treppe-runter"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <path
            d="M55,69 L335,69 C346.045695,69 355,77.954305 355,89 L355,369 C355,380.045695 346.045695,389 335,389 L55,389 C43.954305,389 35,380.045695 35,369 L35,89 C35,77.954305 43.954305,69 55,69 Z"
            id="Rectangle"
            fill="#F0F3F5"
          ></path>
          <g
            id="undraw_stepping_up_g6oo"
            transform="translate(267.104822, 241.752546)"
            fillRule="nonzero"
          >
            <g id="Group">
              <path
                d="M6.51909147,66.1449403 C2.09450768,62.4061981 -0.593057853,56.500091 0.111756298,50.7504485 C0.81657045,45.000806 5.18195845,39.7151206 10.8445023,38.4941601 C16.5070461,37.2731996 22.9619554,40.6530178 24.681856,46.1844807 C25.6283982,35.5200379 26.7191191,24.4087656 32.3890138,15.3269971 C37.5229679,7.10367824 46.4150698,1.21959427 56.0521008,0.16691691 C65.6891318,-0.885760446 75.8356864,3.09005029 81.8459508,10.6964311 C87.8562151,18.3028118 89.3278044,29.3609305 85.1183742,38.0936893 C82.0174321,44.5268166 76.3044013,49.3144229 70.318576,53.2093936 C50.9762127,65.689395 27.7775695,70.7644971 4.99102731,67.5009179"
                id="Path"
                fill="#BDC6D7"
              ></path>
              <path
                d="M72.3160587,3.31042926 C64.3643182,9.78722934 57.0694315,17.0310335 50.5369142,24.937065 C40.3135483,37.2676119 32.0406902,51.0924055 26.0065506,65.9298162 C25.5648381,67.0122316 27.3224969,67.4834018 27.759294,66.4130262 C37.6321937,42.2991345 53.3933866,21.0454063 73.6013468,4.59571738 C74.5087925,3.85679098 73.2160206,2.57759908 72.3160587,3.31042926 L72.3160587,3.31042926 Z"
                id="Path"
                fill="#6D7299"
              ></path>
            </g>
          </g>
          <polyline
            id="Path-2"
            stroke="#394854"
            points="25.0168443 280.85478 77.6375088 280.85478 77.6375088 254.756428 130.258173 254.756428 130.258173 228.658075 182.878838 228.658075 182.878838 202.559723 235.499502 202.559723 235.499502 176.461371 288.120166 176.461371 288.120166 150.363018 370.338048 150.363018"
          ></polyline>
          <path
            d="M246.35931,309.788537 L151.212121,309.788537 C151.212121,309.788537 149.283463,281.501538 160.694697,281.180094 C172.105931,280.85865 170.820158,293.716378 185.124378,276.037002 C199.428598,258.357626 216.786534,259.321966 219.036635,269.76886 C221.286737,280.215754 214.697154,288.573287 226.751273,286.001741 C238.805391,283.430195 256.163326,290.180504 246.35931,309.788537 Z"
            id="Path"
            fill="#FFF9EE"
            fillRule="nonzero"
          ></path>
          <path
            d="M197.179598,309.794987 L196.858468,309.782116 C197.607379,291.158196 201.507859,279.19212 204.648052,272.433493 C208.057589,265.094843 211.343112,262.124004 211.375913,262.094811 L211.589531,262.335029 C211.557197,262.363908 208.309962,265.305241 204.927746,272.594215 C201.804895,279.324262 197.92576,291.241204 197.179598,309.794987 Z"
            id="Path"
            fill="#DCD5C9"
            fillRule="nonzero"
          ></path>
          <path
            d="M231.615772,309.833429 L231.307199,309.74365 C235.022945,296.989826 243.773011,288.937422 243.86106,288.85752 L244.077031,289.09546 C243.989766,289.174739 235.305148,297.170168 231.615772,309.833429 Z"
            id="Path"
            fill="#DCD5C9"
            fillRule="nonzero"
          ></path>
          <path
            d="M166.198234,309.810675 L165.879774,309.766411 C167.140433,300.674339 164.863256,293.620168 162.730864,289.303601 C160.421984,284.629794 157.787578,282.077095 157.761209,282.051823 L157.983615,281.819844 C158.010376,281.845429 160.685509,284.43738 163.019033,289.16124 C165.171359,293.518302 167.470116,300.637609 166.198234,309.810675 Z"
            id="Path"
            fill="#DCD5C9"
            fillRule="nonzero"
          ></path>
          <path
            d="M259.272586,310.21855 L140.949601,310.21855 L140.922305,309.817738 C140.854278,308.82064 139.344876,285.301166 146.96556,276.909274 C148.669173,275.033284 150.72132,274.048572 153.064887,273.982645 C158.032324,273.841343 161.154006,275.702006 163.67184,277.19545 C168.41756,280.010797 171.552672,281.870829 182.951598,267.782116 C195.30016,252.519518 208.623786,249.235959 216.283118,251.061767 C221.231866,252.241742 224.642662,255.576531 225.641021,260.21118 C226.586258,264.599759 226.285175,268.723366 226.043303,272.036738 C225.784423,275.581908 225.579922,278.382142 227.035994,279.651136 C228.251447,280.709964 230.677534,280.806335 234.670546,279.954108 C242.792417,278.221523 254.124988,279.704046 259.813217,287.053474 C262.873589,291.007439 265.284345,298.194776 259.391409,309.980863 L259.272586,310.21855 Z M141.757105,309.358557 L258.740127,309.358557 C263.131435,300.449338 263.268536,292.922911 259.133171,287.579858 C253.915043,280.837842 242.967553,279.063265 234.849865,280.795222 C230.511678,281.720935 227.926655,281.568081 226.471005,280.299508 C224.690758,278.748324 224.909114,275.75892 225.185421,271.974185 C225.423514,268.715612 225.719559,264.660242 224.800364,260.392171 C223.874651,256.094294 220.697539,252.998439 216.083676,251.898457 C208.668742,250.129334 195.717373,253.371535 183.620132,268.322971 C171.75405,282.989077 168.179488,280.869324 163.233256,277.935133 C160.684347,276.423428 157.790263,274.708891 153.089266,274.842217 C150.98841,274.901427 149.142433,275.791443 147.60218,277.487498 C140.753076,285.029465 141.589345,306.17891 141.757105,309.358557 L141.757105,309.358557 Z"
            id="Shape"
            fill="#DCD5C9"
            fillRule="nonzero"
          ></path>
          <g
            id="undraw_random_thoughts_xejj"
            transform="translate(180.775494, 21.363293)"
            fillRule="nonzero"
          >
            <path
              d="M27.1483084,168.91594 L32.7090351,159.581423 L24.851177,156.43828 L24.1312129,157.358234 C22.3316075,159.657751 20.7249836,164.516176 18.5394728,166.405044"
              id="Path"
              fill="#FFB8B8"
            ></path>
            <path
              d="M29.8130264,170.389619 L29.5417405,178.496164 C29.4884858,180.087658 28.1746764,181.345695 26.5823707,181.330053 L20.4507778,181.269117 L13.5358634,181.269117 C13.5358634,181.269117 0.648974536,180.954805 0.0203501871,175.925774 C-0.189192458,174.249433 1.24268288,173.13187 3.24498325,172.386825 C8.05572962,170.611703 12.5584417,168.093353 16.5891196,164.923499 C17.5622439,164.145418 18.8782727,163.949913 20.0355593,164.411507 L28.0174995,167.630928 C29.1360364,168.082078 29.8533659,169.1842 29.8130264,170.389619 L29.8130264,170.389619 Z"
              id="Path"
              fill="#394854"
            ></path>
            <circle
              id="Oval"
              fill="#FFFFFF"
              cx="15.4157676"
              cy="168.169389"
              r="1"
            ></circle>
            <circle
              id="Oval"
              fill="#FFFFFF"
              cx="17.4350597"
              cy="165.977375"
              r="1"
            ></circle>
            <path
              d="M91.6999084,138.700226 L89.0314348,128.1677 L81.252552,131.501507 L81.3939664,132.661103 C81.7474452,135.559633 84.0468293,140.131097 83.8370571,143.012112"
              id="Path"
              fill="#FFB8B8"
              transform="translate(86.476230, 135.589906) rotate(-47.000000) translate(-86.476230, -135.589906) "
            ></path>
            <g
              id="Group-2"
              transform="translate(96.866097, 141.201106) rotate(-49.000000) translate(-96.866097, -141.201106) translate(86.116097, 129.951106)"
            >
              <path
                d="M15.9666162,0.764491647 L20.757048,5.67995213 C21.7792277,6.7288123 21.7421646,8.36887776 20.6735629,9.37422573 L16.9241047,12.9017329 L12.6072314,17.0444043 C12.6072314,17.0444043 4.36592698,24.5765358 0.833938012,21.9402901 C-0.343391643,21.0615405 -0.147159477,19.5341938 0.637715348,17.8882866 C2.54507854,13.9175942 3.78743618,9.68288932 4.31893374,5.34047185 C4.43360116,4.34179809 5.09936361,3.47958811 6.06158836,3.08360951 L13.1476711,0.192372937 C14.1192683,-0.204048463 15.2454606,0.0245177747 15.9666162,0.764491647 Z"
                id="Path"
                fill="#394854"
              ></path>
              <ellipse
                id="Oval"
                fill="#FFFFFF"
                cx="5.60287303"
                cy="8.07022395"
                rx="1"
                ry="1"
              ></ellipse>
              <ellipse
                id="Oval"
                fill="#FFFFFF"
                cx="5.49504731"
                cy="5.54726292"
                rx="1"
                ry="1"
              ></ellipse>
            </g>
            <path
              d="M42.1384434,24.4262767 L45.5959024,34.1700187 L54.7110164,28.8266759 C54.7110164,28.8266759 49.9963015,23.1690173 50.3106173,20.0258776 L42.1384434,24.4262767 Z"
              id="Path"
              fill="#FFB8B8"
            ></path>
            <circle
              id="Oval"
              fill="#FFB8B8"
              cx="43.2385629"
              cy="17.0398886"
              r="8.80080186"
            ></circle>
            <path
              d="M55.9682938,27.56942 L54.1246687,28.0848489 C54.1246687,28.0848489 50.3106352,27.8837322 48.4247479,30.7125633 C46.5388605,33.5413944 45.1436301,32.8953766 45.1436301,32.8953766 L37.42375,44.5423922 C37.42375,44.5423922 34.9092526,49.2571071 35.8521784,54.6004499 C36.7951041,59.9437928 39.9382618,67.4873352 39.9382618,67.4873352 C39.9382618,67.4873352 60.0543772,69.373219 60.6830051,68.4302789 C61.3116331,67.4873388 60.6830051,61.2010486 60.6830051,61.2010486 L63.1975025,33.227075 C63.1975025,33.227075 60.368675,28.5123637 58.7971034,28.5123637 C57.7828298,28.4781711 56.8002256,28.1506339 55.9682938,27.56942 Z"
              id="Path"
              fill="#005192"
            ></path>
            <path
              d="M62.2545768,66.5443915 C62.2545768,66.5443915 56.9112339,66.2300793 54.7110344,66.8587072 C52.5108348,67.4873352 39.3096338,66.8587072 39.3096338,66.8587072 L35.5378626,90.4322781 L23.2795981,157.066911 L36.4808063,162.095949 L52.1965191,124.692549 L54.0824064,130.035892 L82.2245056,138.51356 L86.3293278,128.999726 L69.7981228,115.891747 L63.1975205,91.6895483 C63.1975205,91.6895483 67.2836074,77.8597158 61.6259488,69.3732297 L62.2545768,66.5443915 Z"
              id="Path"
              fill="#394854"
            ></path>
            <polygon
              id="Rectangle"
              fill="#000000"
              opacity="0.2"
              transform="translate(46.381708, 108.033867) rotate(-19.241880) translate(-46.381708, -108.033867) "
              points="46.067381 90.3895203 46.696034 90.3895203 46.696034 125.678213 46.067381 125.678213"
            ></polygon>
            <path
              d="M59.5442176,3.14870023 C59.0434488,1.16801163 57.3698804,-0.117187091 55.8061895,0.278170096 C55.7884732,0.282643104 55.7720949,0.289928331 55.7545365,0.294810258 C55.9314327,0.196351837 56.1203609,0.121271327 56.3165858,0.0714504317 C57.8802839,-0.323910343 59.5538667,0.961288374 60.054614,2.94198057 C60.5496937,4.90021088 59.7117922,6.80253039 58.1816255,7.22749838 C59.3805063,6.56298595 59.9811268,4.87690246 59.5442176,3.14870023 Z"
              id="Path"
              fill="#2F2E41"
            ></path>
            <path
              d="M59.175329,6.23096827 C60.7530631,4.93302489 61.2014185,2.87109757 60.1767124,1.62552819 C60.1651084,1.61141329 60.1515315,1.59970169 60.1396334,1.58591679 C60.3041498,1.70390105 60.4526565,1.84274005 60.5814316,1.99895437 C61.6061378,3.24452375 61.157786,5.30645107 59.5800483,6.60439445 C58.0202026,7.88761313 55.9422198,7.94140042 54.9050417,6.73878913 C56.0175507,7.53955791 57.7987188,7.36343548 59.175329,6.23096827 Z"
              id="Path"
              fill="#2F2E41"
            ></path>
            <ellipse
              id="Oval"
              fill="#394854"
              transform="translate(56.961191, 4.874395) rotate(-57.875540) translate(-56.961191, -4.874395) "
              cx="56.9611913"
              cy="4.87439498"
              rx="2.92041475"
              ry="3.69919154"
            ></ellipse>
            <path
              d="M53.921866,5.82545266 C54.7503235,5.82563129 55.5612908,6.0638032 56.2582789,6.51163066 C56.8706832,6.88995013 57.3810281,7.41260366 57.7446481,8.03384855 C56.9161889,8.03366457 56.1052214,7.7954864 55.4082353,7.34765262 C54.7958361,6.9693344 54.2854922,6.44668806 53.921866,5.82545266 L53.921866,5.82545266 Z"
              id="Path"
              fill="#C2F1EA"
            ></path>
            <path
              d="M41.9822253,4.46731917 L41.9822253,4.46731917 C40.6472352,4.46731917 40.2275581,5.65225723 40.4814183,6.89515428 C41.930505,13.8536434 41.3603594,21.0813563 38.8381674,27.7265779 L46.912961,27.7265779 L48.424755,24.1489682 L49.0104105,27.7265779 L59.5829143,27.7265779 L59.5829143,24.118371 C63.1410429,9.50300015 55.5953519,2.37048302 41.9822253,4.46731917 Z"
              id="Path"
              fill="#394854"
            ></path>
            <path
              d="M40.6582079,4.78163134 L39.8469471,4.78163134 C38.2456232,4.78163134 36.709882,5.41773836 35.5775713,6.55004167 C34.4452606,7.68234497 33.8091331,9.21808198 33.8091331,10.8194059 L33.8091331,12.9538053 L39.0773531,12.3113422 L39.4667917,10.7536057 L39.8332089,12.2191631 L46.6960219,11.3822336 L46.6960219,10.8194059 C46.6960219,9.21808198 46.0598944,7.68234497 44.9275837,6.55004167 C43.795273,5.41773836 42.2595318,4.78163134 40.6582079,4.78163134 L40.6582079,4.78163134 Z"
              id="Path"
              fill="#394854"
            ></path>
            <polygon
              id="Path"
              fill="#000000"
              opacity="0.2"
              points="53.9622988 43.6885248 50.8193564 66.9478051 47.5225311 67.1196231"
            ></polygon>
            <path
              d="M50.7124849,71.6924536 L44.4674491,90.4275576 C44.4674491,90.4275576 31.4569589,104.999307 38.7428353,104.999307 C46.0287116,104.999307 49.6716301,91.9888202 49.6716301,91.9888202 L58.5187654,73.2537162 L50.7124849,71.6924536 Z"
              id="Path"
              fill="#FFB8B8"
            ></path>
            <path
              d="M57.2177157,29.2782559 C57.2177157,29.2782559 61.450673,27.4459406 64.0205323,32.0589995 C66.5903917,36.6720584 65.544429,54.2583958 65.0240105,56.8604953 C64.503592,59.4625947 60.8606521,75.5955992 60.8606521,75.5955992 L48.3705803,72.9935034 C48.3705803,72.9935034 47.3297434,65.707627 50.4522614,66.2280347 L57.2177157,29.2782559 Z"
              id="Path"
              fill="#005192"
            ></path>
          </g>
        </g>
      </svg>
    );
  } else {
    return (
      <svg
        width="390px"
        height="390px"
        viewBox="0 0 390 390"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Screen / illustration / Treppe-hoch</title>
        <g
          id="Screen-/-illustration-/-Treppe-hoch"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <path
            d="M55,69 L335,69 C346.045695,69 355,77.954305 355,89 L355,369 C355,380.045695 346.045695,389 335,389 L55,389 C43.954305,389 35,380.045695 35,369 L35,89 C35,77.954305 43.954305,69 55,69 Z"
            id="Rectangle"
            fill="#F0F3F5"
          ></path>
          <g
            id="undraw_stepping_up_g6oo"
            transform="translate(64.683383, 56.948527)"
            fillRule="nonzero"
          >
            <g id="Group" transform="translate(55.864641, 87.797593)">
              <path
                d="M153.07589,163.151366 C148.651306,159.412624 145.96374,153.506517 146.668554,147.756874 C147.373369,142.007232 151.738757,136.721546 157.4013,135.500586 C163.063844,134.279625 169.518753,137.659444 171.238654,143.190906 C172.185196,132.526464 173.275917,121.415191 178.945812,112.333423 C184.079766,104.110104 192.971868,98.22602 202.608899,97.1733426 C212.24593,96.1206653 222.392485,100.096476 228.402749,107.702857 C234.413013,115.309238 235.884602,126.367356 231.675172,135.100115 C228.57423,141.533242 222.861199,146.320849 216.875374,150.215819 C197.533011,162.695821 174.334368,167.770923 151.547825,164.507344"
                id="Path"
                fill="#BDC6D7"
              ></path>
              <path
                d="M218.872857,100.316855 C210.921116,106.793655 203.62623,114.037459 197.093712,121.943491 C186.870346,134.274038 178.597488,148.098831 172.563349,162.936242 C172.121636,164.018657 173.879295,164.489827 174.316092,163.419452 C184.188992,139.30556 199.950185,118.051832 220.158145,101.602143 C221.065591,100.863217 219.772819,99.5840248 218.872857,100.316855 L218.872857,100.316855 Z"
                id="Path"
                fill="#6D7299"
              ></path>
              <path
                d="M15.8720533,21.6087407 C16.05634,20.1561368 15.5877509,18.6965128 14.592373,17.6226215 C13.5969951,16.5487302 12.1770756,15.9708837 10.7146837,16.0445679 L1.96810866,0 L0,6.88838029 L5.99234471,21.5929762 C6.28541203,24.0964624 8.40462725,25.9850935 10.9252056,25.9891168 C13.445784,25.9931274 15.5710081,24.1112604 15.8720533,21.6087211 L15.8720533,21.6087407 Z"
                id="Path"
                fill="#A0616A"
              ></path>
            </g>
            <polygon
              id="Path"
              fill="#A0616A"
              points="4.3469913 172.757154 9.21855225 176.314018 25.2561777 159.214598 18.0656927 153.96516"
            ></polygon>
            <path
              d="M0.336333718,175.487758 L19.2944996,175.487758 L19.2944996,182.812503 L7.66107323,182.812503 C3.6157313,182.812503 0.336333718,179.533105 0.336333718,175.487758 L0.336333718,175.487758 Z"
              id="Path"
              fill="#394854"
              transform="translate(9.815417, 179.150131) rotate(-143.868550) translate(-9.815417, -179.150131) "
            ></path>
            <polygon
              id="Path"
              fill="#A0616A"
              points="30.1218343 191.584081 36.1540873 191.583589 39.0235897 168.316609 30.1208502 168.317101"
            ></polygon>
            <path
              d="M28.8294653,189.860218 L47.7876311,189.860218 L47.7876311,197.184962 L36.1542048,197.184962 C32.1088628,197.184962 28.8294653,193.905565 28.8294653,189.860218 L28.8294653,189.860218 Z"
              id="Path"
              fill="#394854"
              transform="translate(38.308548, 193.522590) rotate(179.997380) translate(-38.308548, -193.522590) "
            ></path>
            <path
              d="M48.2382203,100.836313 C48.2382203,100.836313 51.1903833,129.373889 43.8099758,149.054975 L38.397677,186.44904 L29.0491609,186.44904 L30.0332153,142.166595 L26.5890251,102.804422 C26.5890251,102.804422 33.4774054,85.0914439 48.2382203,100.836313 L48.2382203,100.836313 Z"
              id="Path"
              fill="#394854"
            ></path>
            <polygon
              id="Path"
              fill="#394854"
              points="16.2565088 102.804176 26.0970521 136.75405 6.90799268 167.259734 16.5025224 175.378182 38.3977312 141.182295 32.001378 99.3599856"
            ></polygon>
            <circle
              id="Oval"
              fill="#A0616A"
              cx="41.3517786"
              cy="14.5901508"
              r="12.0846939"
            ></circle>
            <path
              d="M31.2632832,31.2144694 C31.2632832,31.2144694 38.6436906,26.2941978 47.0081524,37.1187954 L49.2222747,105.264558 C49.2222747,105.264558 42.8259215,96.4080686 29.5411881,104.77253 C16.2564547,113.136992 13.3042917,103.788476 13.3042917,103.788476 C13.3042917,103.788476 3.95577558,33.4285916 31.2632832,31.2144694 Z"
              id="Path"
              fill="#F0C8AC"
            ></path>
            <path
              d="M46.412834,36.9469844 C50.8087557,37.1845995 54.3180035,40.6988278 54.5493839,45.0950822 L56.1106353,74.7588685 L66.4432057,102.804417 L60.5388797,107.724688 L41.3498203,74.2668413 L37.4211163,46.7659031 C37.0564973,44.2135663 37.8544052,41.6321102 39.595649,39.7306792 C41.3368928,37.8292481 43.8383432,36.8078185 46.412834,36.9469844 Z"
              id="Path"
              fill="#F0C8AC"
            ></path>
            <path
              d="M32.4721398,23.6548952 C34.4477568,25.7593544 38.1167492,24.6296305 38.3741778,21.7546469 C38.3943647,21.5314392 38.3928998,21.3068016 38.3698037,21.0838761 C38.2368924,19.810377 37.5011642,18.6541968 37.6773985,17.3095653 C37.7172872,16.9749122 37.8419691,16.6559875 38.0396289,16.3830142 C39.6139436,14.2748696 43.3095301,17.325935 44.7953193,15.4174945 C45.7063715,14.2472817 44.63544,12.4048417 45.3345811,11.0969302 C46.257314,9.37070206 48.9904069,10.2222535 50.7043441,9.27688724 C52.6113135,8.22504633 52.4972517,5.29921664 51.2419576,3.51957899 C49.7110692,1.34924717 47.026938,0.191138232 44.3762499,0.0242180166 C41.7255618,-0.142702199 39.0931328,0.573856641 36.6184576,1.5382594 C33.8067487,2.6340039 31.0185341,4.1483651 29.2882369,6.62070812 C27.1840237,9.62734835 26.9815299,13.6694893 28.0338826,17.1851808 C28.6740542,19.3238261 30.8588368,21.9363821 32.4721398,23.6548952 L32.4721398,23.6548952 Z"
              id="Path"
              fill="#2F2E41"
            ></path>
          </g>
          <polyline
            id="Path-2"
            stroke="#394854"
            points="25.0168443 280.85478 77.6375088 280.85478 77.6375088 254.756428 130.258173 254.756428 130.258173 228.658075 182.878838 228.658075 182.878838 202.559723 235.499502 202.559723 235.499502 176.461371 288.120166 176.461371 288.120166 150.363018 370.338048 150.363018"
          ></polyline>
          <path
            d="M246.35931,309.788537 L151.212121,309.788537 C151.212121,309.788537 149.283463,281.501538 160.694697,281.180094 C172.105931,280.85865 170.820158,293.716378 185.124378,276.037002 C199.428598,258.357626 216.786534,259.321966 219.036635,269.76886 C221.286737,280.215754 214.697154,288.573287 226.751273,286.001741 C238.805391,283.430195 256.163326,290.180504 246.35931,309.788537 Z"
            id="Path"
            fill="#FFF9EE"
            fillRule="nonzero"
          ></path>
          <path
            d="M197.179598,309.794987 L196.858468,309.782116 C197.607379,291.158196 201.507859,279.19212 204.648052,272.433493 C208.057589,265.094843 211.343112,262.124004 211.375913,262.094811 L211.589531,262.335029 C211.557197,262.363908 208.309962,265.305241 204.927746,272.594215 C201.804895,279.324262 197.92576,291.241204 197.179598,309.794987 Z"
            id="Path"
            fill="#DCD5C9"
            fillRule="nonzero"
          ></path>
          <path
            d="M231.615772,309.833429 L231.307199,309.74365 C235.022945,296.989826 243.773011,288.937422 243.86106,288.85752 L244.077031,289.09546 C243.989766,289.174739 235.305148,297.170168 231.615772,309.833429 Z"
            id="Path"
            fill="#DCD5C9"
            fillRule="nonzero"
          ></path>
          <path
            d="M166.198234,309.810675 L165.879774,309.766411 C167.140433,300.674339 164.863256,293.620168 162.730864,289.303601 C160.421984,284.629794 157.787578,282.077095 157.761209,282.051823 L157.983615,281.819844 C158.010376,281.845429 160.685509,284.43738 163.019033,289.16124 C165.171359,293.518302 167.470116,300.637609 166.198234,309.810675 Z"
            id="Path"
            fill="#DCD5C9"
            fillRule="nonzero"
          ></path>
          <path
            d="M259.272586,310.21855 L140.949601,310.21855 L140.922305,309.817738 C140.854278,308.82064 139.344876,285.301166 146.96556,276.909274 C148.669173,275.033284 150.72132,274.048572 153.064887,273.982645 C158.032324,273.841343 161.154006,275.702006 163.67184,277.19545 C168.41756,280.010797 171.552672,281.870829 182.951598,267.782116 C195.30016,252.519518 208.623786,249.235959 216.283118,251.061767 C221.231866,252.241742 224.642662,255.576531 225.641021,260.21118 C226.586258,264.599759 226.285175,268.723366 226.043303,272.036738 C225.784423,275.581908 225.579922,278.382142 227.035994,279.651136 C228.251447,280.709964 230.677534,280.806335 234.670546,279.954108 C242.792417,278.221523 254.124988,279.704046 259.813217,287.053474 C262.873589,291.007439 265.284345,298.194776 259.391409,309.980863 L259.272586,310.21855 Z M141.757105,309.358557 L258.740127,309.358557 C263.131435,300.449338 263.268536,292.922911 259.133171,287.579858 C253.915043,280.837842 242.967553,279.063265 234.849865,280.795222 C230.511678,281.720935 227.926655,281.568081 226.471005,280.299508 C224.690758,278.748324 224.909114,275.75892 225.185421,271.974185 C225.423514,268.715612 225.719559,264.660242 224.800364,260.392171 C223.874651,256.094294 220.697539,252.998439 216.083676,251.898457 C208.668742,250.129334 195.717373,253.371535 183.620132,268.322971 C171.75405,282.989077 168.179488,280.869324 163.233256,277.935133 C160.684347,276.423428 157.790263,274.708891 153.089266,274.842217 C150.98841,274.901427 149.142433,275.791443 147.60218,277.487498 C140.753076,285.029465 141.589345,306.17891 141.757105,309.358557 L141.757105,309.358557 Z"
            id="Shape"
            fill="#DCD5C9"
            fillRule="nonzero"
          ></path>
        </g>
      </svg>
    );
  }
}
