import * as types from "../types.variables.js";

const initState = {
  selectedPOI: null,
  showCreateAppointment: false,
  hideCreateAppointment: false,
};

export default function appointment(state = initState, action) {
  switch (action.type) {
    case types.APPOINTMENT_SET_POI:
      return {
        ...state,
        selectedPOI: action.payload,
      };
    case types.APPOINTMENT_SET_SHOW_CREATEAPPOINTMENT:
      return {
        ...state,
        showCreateAppointment: action.payload,
        hideCreateAppointment: !action.payload,
      };
    case types.APPOINTMENT_SET_HIDE_CREATEAPPOINTMENT:
      return {
        ...state,
        hideCreateAppointment: action.payload,
      };
    default:
      return state;
  }
}
