import { map } from "../../map.sideEffects/useMap.sideEffects.js";
import { layerIds } from "../../variables/layerIds.js";
import { sourceIds } from "../../variables/sourceIds.js";
import { isAndroid, isIOS } from "react-device-detect";
import { CUSTOMIZABLE_VALUES } from "../../variables/CUSTOMIZABLE_VALUES.js";
import { isApp } from "../../../../../reduxStore/reducers/main";

const userPuckRadius_inner = 12;
/**
 * Layer für UserPosition (GPS/Bluetooth Positionspunkt mit Richtungsanzeige)
 */
export function createUserPosition() {
  // Radar das die Accuracy des Signals anzeigt
  if (!map.getLayer(layerIds.nodes.position.radar)) {
    map.addLayer({
      id: layerIds.nodes.position.radar,
      type: "circle",
      source: sourceIds.position,
      paint: {
        "circle-pitch-alignment": "map",
        "circle-color": CUSTOMIZABLE_VALUES.userPuck_radar,
        "circle-opacity": 0.15,
        "circle-blur": 0.25,
        "circle-radius": {
          stops: [
            [0, 0],
            [22, 400],
          ],
          base: 2,
        },
      },
    });
  }
  // Richtungsanzeige (Heading oder Bearing) -> Nur in App
  // android ist viel zu ungenau daher darf ned!
  if (isIOS && isApp && !map.getLayer(layerIds.nodes.position.arc)) {
    map.addLayer({
      id: layerIds.nodes.position.arc,
      type: "symbol",
      source: sourceIds.position,
      layout: {
        "icon-size": 1.5,
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
        "icon-rotation-alignment": "map",
        "icon-image": "arc",
      },
      paint: {},
    });
  }
  // Der Actual Punkt
  if (!map.getLayer(layerIds.nodes.position.dot)) {
    map.addLayer({
      id: layerIds.nodes.position.dot,
      type: "circle",
      source: sourceIds.position,
      paint: {
        "circle-pitch-alignment": "map",
        "circle-radius": userPuckRadius_inner,
        "circle-color": CUSTOMIZABLE_VALUES.userPuck,
        "circle-stroke-width": 3,
        "circle-stroke-color": "#ffffff",
      },
    });
  }
}
