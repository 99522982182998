import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import RouteHeader from "./RouteHeader/routeHeader.component.jsx";
import RouteCard from "./RouteCard/routeCard.component.jsx";
import * as routeAction from "../../../reduxStore/actions/route";
import * as actionRoute from "../../../reduxStore/actions/route";
import {
  cancelRequest,
  setGoToParkingSpot,
} from "../../../reduxStore/actions/route";
import * as floorAction from "../../../reduxStore/actions/floors.js";
import * as mainAction from "../../../reduxStore/actions/main.js";
import { CAMPUS_COLLECTION, POIS } from "../../../reduxStore/actions/main.js";
import * as mapAction from "../../../reduxStore/actions/map";
import * as debugAction from "../../../reduxStore/actions/debug.js";
import {
  setCameraPosition,
  stableEaseTo,
} from "../mapbox/functions/interactivity.map.js";
import ToastsContainer from "../../elements/toast/toasts.container";
import { map } from "../mapbox/map.sideEffects/useMap.sideEffects.js";
import * as turf from "@turf/turf";
import { point } from "@turf/turf";
import { zoomSymbolsStart } from "../mapbox/functions/filter.map.js";
import { withRouter } from "react-router-dom";
import StartDestination from "../../../functions/classes/startDestination.class";
import { setFeatures } from "../mapbox/functions/drawer.map.js";
import { FormattedMessage, useIntl } from "react-intl";
import { pageNames } from "../../../reduxStore/reducers/main.js";
import DragContainer from "../../elements/dragContainer/dragContainer.container.jsx";
import StandardButton from "../../elements/buttons/standard.button.jsx";
import {
  checkBoundingBoxZoom,
  getItemByRootId,
} from "../../../functions/helper/helpers.functions.js";
import { useStartDestination } from "./route.sideEffects/useStartDestination.sideEffect.js";
import { useRoutesAndViews } from "./route.sideEffects/useRoutesAndViews.sideEffects.js";
import { useHandleURLParams } from "./route.sideEffects/useHandleURLParams.sideEffects.js";
import { isMobile } from "react-device-detect";
import styles from "../ui.module.scss";
import { easeInOutQuint } from "../../../functions/helper/animationEasing.functions.js";
import { sourceIds } from "../mapbox/variables/sourceIds.js";
import StandardInstruction from "../../elements/text/standardInstruction.component.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addToast } from "../../../reduxStore/actions/toasts.js";
import {
  setAllowBuildingMarkers,
  setMapPadding,
} from "../../../reduxStore/actions/map";
import { statisticLogger } from "../../statisticLogger/StatisticLogger.container.js";
import TopRightBubbles from "../actions/MapControls.component.jsx";
import { terminalBearing } from "../../../app/app.sideEffects/useURLParams.sideEffects.js";

/**
 * Er zeigt die Route an, bevor die Navigation gestartet wird
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function RouteComponent(props) {
  const [markingPositionMode, setMarkingPositionMode] = useState({
    active: false,
    type: StartDestination.types.start,
  });
  const [isMapFullPage, setIsMapFullPage] = useState(false);
  const [routeCardOpeningStatus, setRouteCardOpeningStatus] =
    useState("preOpen");

  // gibt dem return dieses components bescheid, ob dieser die route resetten soll beim unmounten
  const resetRouteOnClose = useRef(true);

  const fetchingRoute =
    props.target?.isSet &&
    props.start?.isSet &&
    !props.routeHasError &&
    !props.currentRoute;

  /**
   * Beim Click auf die Map werden RouteHeader und Footer ausgeblendet
   */
  const toggleFullMap = () => {
    // Desktop muss nicht toggeln
    if (isMobile) setIsMapFullPage((prevState) => !prevState);
  };

  const handleClose = () => {
    // Target umwandeln in currentFeature, zum Anzeigen in MapInfo
    props.setCurrentFeature(getItemByRootId(props.target.poiId, POIS.all));

    const globalParams = new URLSearchParams(props.location.search);
    globalParams.delete("start");
    const params = "?" + globalParams.toString();

    props.history.push(`/${pageNames.map}/${pageNames.info}/${params}`);
    statisticLogger.addLog({
      action: {
        group: "Route",
        movement: "leave",
        name: "User leaves route screen intentionally",
        interaction: "exit",
        type: "click",
        id: "userLeavesRouteIntentionally",
        content: {
          globalPoiIds: [
            props.start ? props.start.globalPoiId : undefined,
            props.target ? props.target.globalPoiId : undefined,
          ],
          campusId: CAMPUS_COLLECTION.getCurrentCampus().id,
          startGlobalPoiId: props.start ? props.start.globalPoiId : undefined,
          destinationGlobalPoiId: props.target
            ? props.target.globalPoiId
            : undefined,
          startZIndex: props.start.level,
          startLatitude: props.start.lat,
          startLongitude: props.start.lng,
          destinationLatitude: props.target.lat,
          destinationLongitude: props.target.lng,
        },
      },
    });
  };

  const handleDownloadPDF = () => {
    props.setIsGeneratingPDF(true);
    props.downloadRoute(
      props.start,
      props.target,
      props.settings,
      props.roadUserType,
      props.goToParkingSpot,
      props.language,
      props.deviceInformation,
      props.isApp
    );
  };

  useStartDestination(props);
  useHandleURLParams(props);
  useRoutesAndViews(props);

  useEffect(() => {
    if (!props.mapLoaded) return;

    map.on("click", toggleFullMap);

    // pagename setzen für zoom einstellungen
    props.setMapPage(pageNames.route);
    statisticLogger.currentPage = pageNames.route;
    statisticLogger.addLog({
      action: {
        id: "enterRoutePage",
        name: "Enter Route Page",
        group: "Page",
        movement: "enter",
        type: "click",
      },
    });
    props.setCurrentFeature(null);
    // building marker stören bei der route
    props.setAllowBuildingMarkers(false);

    return () => {
      props.setAllowBuildingMarkers(true);
      cancelRequest();
      if (resetRouteOnClose.current) {
        props.resetTarget();
        props.resetStart();
        props.resetRoute();
      }

      map.off("click", toggleFullMap);
    };
  }, [props.mapLoaded]);
  useEffect(() => {
    console.log("route ", props.currentRoute);
  }, [props.currentRoute]);
  return (
    <>
      <div className={`${styles.upperBlock}`}>
        <RouteHeader
          setRouteCardOpeningStatus={setRouteCardOpeningStatus}
          fetchingRoute={fetchingRoute}
          isVisible={!isMapFullPage}
          handleClose={handleClose}
          setMarkingPositionMode={setMarkingPositionMode}
          routeCard={
            <RouteCard
              resetRouteOnClose={resetRouteOnClose}
              isTerminal={props.isTerminal}
              isVisible={!isMapFullPage}
              start={props.start}
              target={props.target}
              currentRoute={props.currentRoute}
              currentRouteCreationTimestamp={
                props.currentRouteCreationTimestamp
              }
              roadUserType={props.roadUserType}
              routeHasError={props.routeHasError}
              settings={props.settings}
              handleDownloadPDF={handleDownloadPDF}
              isGeneratingPDF={props.isGeneratingPDF}
              isApp={props.isApp}
              renderInDragContainer={false}
            />
          }
        />
        <ToastsContainer />
        <TopRightBubbles />
      </div>
      {markingPositionMode.active ? (
        <SetMarkingPositionButtons
          setMapPadding={props.setMapPadding}
          setMarkingPositionMode={setMarkingPositionMode}
          type={markingPositionMode.type}
          start={props.start}
          setStart={props.setStart}
          target={props.target}
          setTarget={props.setTarget}
          currentFloorIndex={props.currentFloorIndex}
          mapPadding={props.mapPadding}
        />
      ) : isMobile ? (
        <RouteCard
          isVisible={!isMapFullPage}
          resetRouteOnClose={resetRouteOnClose}
          routeCardOpeningStatus={routeCardOpeningStatus}
          currentRoute={props.currentRoute}
          start={props.start}
          target={props.target}
          currentRouteCreationTimestamp={props.currentRouteCreationTimestamp}
          roadUserType={props.roadUserType}
          routeHasError={props.routeHasError}
          settings={props.settings}
          handleDownloadPDF={handleDownloadPDF}
          isGeneratingPDF={props.isGeneratingPDF}
          isApp={props.isApp}
          isTerminal={props.isTerminal}
        />
      ) : null}
    </>
  );
}

function SetMarkingPositionButtons({
  setMarkingPositionMode,
  type,
  start,
  target,
  setStart,
  setTarget,
  currentFloorIndex,
  mapPadding,
  setMapPadding,
}) {
  const intl = useIntl();
  const updateSkipper = useRef(0);
  const middleMarker_id = useRef(220022022);
  const timer = useRef();

  const getFixedPoint = () => {
    let otherPoint =
      type === StartDestination.types.start
        ? target?.getPoint() ?? [0, 0]
        : start?.getPoint();
    if (otherPoint) {
      otherPoint.properties = {
        ...otherPoint.properties,
        type:
          type === StartDestination.types.start
            ? StartDestination.types.destination
            : StartDestination.types.start,
      };
    }
    return otherPoint;
  };
  const cancelMarking = () => {
    if (type === StartDestination.types.start) {
      setStart(new StartDestination());
    } else {
      setTarget(new StartDestination());
    }
    setMarkingPositionMode(false);
  };
  const confirmMarking = () => {
    let currentMarker = new StartDestination({
      lat: map.getCenter().lat,
      lng: map.getCenter().lng,
      isSet: true,
      name: intl.formatMessage({ id: "route.setMarker" }),
      campusId: CAMPUS_COLLECTION.getCurrentCampus().campusId,
      level: CAMPUS_COLLECTION.getLevelByIndex(currentFloorIndex),
    });

    if (type === StartDestination.types.start) {
      setStart(currentMarker);
    } else {
      setTarget(currentMarker);
    }
    setMarkingPositionMode(false);
  };

  const updateCenterMarker = () => {
    if (updateSkipper.current % 2 !== 0) return;

    const center = map.getCenter();

    const middleMarker = point(
      // dieser kleine Abzug vom Lat ist damit der middlemarker im z-index als oberster angezeigt wird!
      [center.lng, center.lat - 0.0000002],
      {
        neverGhost: true,
        type: type,
      },
      { id: middleMarker_id.current }
    );

    const otherPoint = getFixedPoint();
    setFeatures({
      sourceId: sourceIds.routeSymbole,
      features: [otherPoint, middleMarker],
    });

    map.setFeatureState(
      {
        id: middleMarker_id.current,
        source: sourceIds.routeSymbole,
      },
      {
        marking: true,
      }
    );
  };

  // drückt den marker runter, damit er nicht direkt auf dem anderen Marker liegt
  const initialMoveDown = () => {
    const center = map.getCenter();

    // offset ist die distanz des movedown,
    // berechnet durch den momentanen zoom (je näher user ist dran, desto weniger distanz
    let offset = 0.00007;
    let factor = 1 / (map.getZoom() / map.getMaxZoom());

    factor = Math.pow(factor, 5);
    offset = offset * factor;

    stableEaseTo({ center: [center.lng, center.lat - offset], duration: 1500 });
  };

  useEffect(() => {
    // Hier wird jeweils das MapCenter dem Start (oder Destination) Feature gesetzt, immer wenn die map moved
    map.on("move", updateCenterMarker);
    // wenn setMapPadding hier aufgerufen wird dann wird das hier ein ewiger loop
    // setMapPadding(mapPadding);

    return () => {
      map.off("move", updateCenterMarker);
      // setMapPadding(STANDARD_MAP_PADDING);
    };
  }, [mapPadding]);

  useEffect(() => {
    timer.current = setTimeout(initialMoveDown, 500);

    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  return (
    <DragContainer
      status={"preOpen"}
      interactionHandles={false}
      noClose
      draggable={false}
      preOpenIsFullScreen
    >
      <div className={"d-flex flex-column align-items-center p-3"}>
        <StandardInstruction className={"mb-3 text-center"}>
          <FontAwesomeIcon icon={["fal", "arrows"]} className={"mr-2"} />
          <FontAwesomeIcon icon={["fal", "hand-peace"]} className={"mr-3"} />
          <br />
          {type === StartDestination.types.start ? (
            <FormattedMessage id={"route.markStart"} />
          ) : (
            <FormattedMessage id={"route.markTarget"} />
          )}
        </StandardInstruction>
        <div className="d-flex align-items-center ">
          <StandardButton
            icon={["fal", "times"]}
            title={"Cancel"}
            textClasses={"normalButton--cancel__text"}
            handleClick={cancelMarking}
            buttonClasses={"mr-3 normalButton--cancel"}
          >
            <FormattedMessage id={"words.cancel"} />
          </StandardButton>
          <StandardButton
            icon={["fal", "map-pin"]}
            title={"Confirm"}
            textClasses={"text-white"}
            buttonClasses={"normalButton--primary"}
            handleClick={confirmMarking}
          >
            <FormattedMessage id={"words.confirm"} />
          </StandardButton>
        </div>
      </div>
    </DragContainer>
  );
}

/**
 * Kamera auf eines der Icons setten -> Kann entweder poiId, nodeId oder nur lngLat haben
 * @param {StartDestination} start
 * @param {StartDestination} target
 * @param mapPadding
 */
export function easeToRoute(start, target, mapPadding, routeLineString) {
  const setCameraWithBoundingBox = () => {
    let boundingBox = turf.bbox(routeLineString);

    boundingBox = [
      [boundingBox[0], boundingBox[1]],
      [boundingBox[2], boundingBox[3]],
    ];

    let cameraObject = map.cameraForBounds(boundingBox);

    // manchmal kann mapbox wegen unpassender mappaddings keine gescheite
    if (cameraObject) {
      // zoom adjustment, if mapbox fails!
      const startTargetDistance = turf.distance(
        turf.point([start.lng, start.lat]),
        turf.point([target.lng, target.lat]),
        { units: "kilometers" }
      );
      const correctedZoom = checkBoundingBoxZoom(
        cameraObject.zoom,
        startTargetDistance
      );
      if (correctedZoom.corrected) {
        cameraObject.zoom = correctedZoom.zoom;
      }
      if (terminalBearing != null) {
        cameraObject.bearing = terminalBearing;
      }
      cameraObject.duration = 2000;
      cameraObject.zoom = cameraObject.zoom - 0.5;
      cameraObject.easing = easeInOutQuint;

      map.stableEaseTo({ ...cameraObject, padding: mapPadding });

      return true;
    } else {
      return false;
    }
  };
  const setCameraWithStartDestination = () => {
    const lineArray = [];
    if (start != null) {
      let startPoint = start.getPoint();
      if (startPoint)
        // Wichtig als String, weil mapbox sonst die berechnung daneben macht
        lineArray.push([
          startPoint.geometry.coordinates[0],
          startPoint.geometry.coordinates[1],
        ]);
    }
    if (target != null) {
      let targetPoint = target.getPoint();
      if (targetPoint)
        lineArray.push([
          targetPoint.geometry.coordinates[0],
          targetPoint.geometry.coordinates[1],
        ]);
    }

    if (lineArray.length > 1) {
      setCameraPosition(lineArray[0], lineArray[1], { ...mapPadding });
    } else {
      stableEaseTo({
        center: lineArray[0],
        zoom: zoomSymbolsStart,
        duration: 2000,
      });
    }
  };

  try {
    let boundingWorked;

    // Erstellen eines LineString
    if (routeLineString) {
      boundingWorked = setCameraWithBoundingBox();
    }

    if (!boundingWorked) {
      setCameraWithStartDestination();
    }
  } catch (e) {
    console.error("Fehler bei ShowRoute", e);
  }
}

const mapStateToProps = (state) => {
  return {
    liveNavigationInformation: state.route.liveNavigationInformation,
    currentFeature: state.main.currentFeature,
    currentFloorIndex: state.floors.currentFloorIndex,
    currentRoute: state.route.currentRoute,
    currentRouteCreationTimestamp: state.route.currentRouteCreationTimestamp,
    currentCampusId: state.main.currentCampusId,
    routeHasError: state.route.routeHasError,
    locationObject: state.route.locationObject,
    isGeneratingPDF: state.route.isGeneratingPDF,
    mapPage: state.map.currentPage,
    viewIsIndoors: state.map.viewIsIndoors,
    mapLoaded: state.map.mapLoaded,
    mapPadding: state.map.mapPadding,
    globalPage: state.main.currentPage,
    settings: state.settings.routing,
    userAllowedGPS: state.settings.location.userAllowed,
    fetchNewRoute: state.route.fetchNewRoute,
    start: state.route.start,
    target: state.route.target,
    roadUserType: state.route.roadUserType,
    goToParkingSpot: state.route.goToParkingSpot,
    language: state.main.language,
    isApp: state.main.isApp,
    defaultStartNode: state.route.defaultStartNode,
    deviceInformation: state.main.deviceInformation,
    isTerminal: state.main.isTerminal,
    urlParamsHandled: state.route.urlParamsHandled,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRoute: (
      start,
      target,
      settings,
      type,
      goToParkingSpot,
      language,
      deviceInformation,
      isApp,
      isTerminal
    ) =>
      dispatch(
        routeAction.setRoute(
          start,
          target,
          settings,
          type,
          goToParkingSpot,
          language,
          deviceInformation,
          isApp,
          isTerminal
        )
      ),
    setDebuggingValues: (object) =>
      dispatch(debugAction.setDebuggingValues(object)),
    forceFeatureFilterUpdate: () =>
      dispatch(mapAction.forceFeatureFilterUpdate()),
    setCurrentFeature: (feature) =>
      dispatch(mainAction.setCurrentFeature(feature)),
    resetStart: () => dispatch(routeAction.resetStart()),
    resetTarget: () => dispatch(routeAction.resetTarget()),
    resetRoute: () => dispatch(routeAction.resetRoute()),
    addToast: (toast) => dispatch(addToast(toast)),
    setMapPadding: (object, tellMap) =>
      dispatch(setMapPadding(object, tellMap)),
    setStart: (feature) => dispatch(actionRoute.setStart(feature)),
    setTarget: (feature) => dispatch(actionRoute.setTarget(feature)),
    setRoadUserType: (type) => dispatch(routeAction.setRoadUserType(type)),
    setGoToParkingSpot: (value) => dispatch(setGoToParkingSpot(value)),
    setFloorNumber: (value, bounce) =>
      dispatch(floorAction.setCurrentFloorNumber(value, bounce)),
    setFetchNewRoute: (status) =>
      dispatch(routeAction.setFetchNewRoute(status)),
    setMapPage: (page) => dispatch(mapAction.setPage(page)),
    setIsGeneratingPDF: (isGenerating) =>
      dispatch(routeAction.setIsGeneratingPDF(isGenerating)),
    setMapURLParamsHandled: () => dispatch(actionRoute.mapURLParamsHandled()),
    setAllowBuildingMarkers: (value) =>
      dispatch(setAllowBuildingMarkers(value)),
    downloadRoute: (
      start,
      target,
      settings,
      type,
      goToParkingSpot,
      language,
      deviceInformation,
      isApp
    ) =>
      dispatch(
        routeAction.downloadRoute(
          start,
          target,
          settings,
          type,
          goToParkingSpot,
          language,
          deviceInformation,
          isApp
        )
      ),
    setLanguage: (key) => dispatch(mainAction.setLanguage(key)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RouteComponent)
);
export const synth = window.speechSynthesis;
