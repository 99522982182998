import moment from "moment";

export default function DateTimePicker({ onChange, value }) {
  const setDate = (e) => {
    value = moment(value ? value : null);
    let settedDate = moment(e.target.value);
    value.date(settedDate.date());
    value.month(settedDate.month());
    value.year(settedDate.year());
    value = value.toDate();
    onChange(value);
  };
  const setTime = (e) => {
    value = moment(value);
    let settedTime = moment(e.target.value, "HH:mm");
    value.hour(settedTime.hour());
    value.minute(settedTime.minute());
    value = value.toDate();
    onChange(value);
  };

  return (
    <div className={"d-flex align-items-center"}>
      <input
        onChange={setDate}
        type={"date"}
        value={value ? moment(value).format("YYYY-MM-DD") : ""}
        className={
          "unset-safari ca-btn-semi mr-3 p-2 pl-3 pr-3 border-0 outline-0"
        }
      />
      <input
        onChange={setTime}
        type={"time"}
        value={value ? moment(value).format("HH:mm") : ""}
        className={"unset-safari ca-btn-semi p-2 pl-3 pr-3 border-0 outline-0"}
      />
    </div>
  );
}
