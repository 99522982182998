import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import { useSpring, a } from "react-spring";
import { useDrag } from "react-use-gesture";

import { fade } from "../../../configuration/animations";
import classes from "./dragContainer.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import RoundedIcon from "../buttons/roundedIcon.component.jsx";
import SaveZoneWrapper from "../wrapper/saveZone.wrapper.jsx";
import * as actionMap from "../../../reduxStore/actions/map.js";
import { deviceSafePadding } from "../../../app/app.sideEffects/useURLParams.sideEffects";

/**
 * Übergebener Status entscheidet,
 * @param {"open", "preOpen", "close"} status - Entscheidet wie der DragContainer initial geöffnet (oder von außen geändert) wird
 */
function DragContainer(props) {
  const initMount = useRef(true);
  const saveZoneRef = useRef(null);
  const childRef = useRef(null);
  // HTML Reference des kompletten Containers -> wird für Größen Rechnung benötigt
  const containerRef = useRef(null);
  // Die Höhe des schmalen " - " divs, der "Drag"-Eingaben entgegen nimmt
  const dragHeaderContainerRef = useRef(null);

  // Deklariert "open", "close", "preOpen", "moving" damit der useEffect die richtige Funktion aufruft!
  const [innerStatus, setInnerStatus] = useState(props.status);
  // Wird genutzt für den State wenn der Container komplett aufgezogen wird
  const pageHeight = props.pageHeight;
  // initiale Setzung der Height, solange keine andere vom Effect errechnet wurde
  // wichtig, dass es null bleibt, jede andere Zahl ergibt einen Bug!
  const [containerHeight, setContainerHeight] = useState(null);
  // Options die Beispielsweise das draggen deaktivieren können (fürs scrolling wichtig)
  const [dragOptions, setDragOptions] = useState({
    enabled: false,
    canScroll: true,
    // das ist der Offset ab dem gemoved wird
    // (weil man anfängt zu "draggen", aber eig nur scrollt und erst bei scrollTop == 0 der Drag beginnt)
    startMovingOffset: 0,
  });

  // setzen der Animation, die nur die y-Bewegung steuert
  const [{ y }, springAPI] = useSpring(() => ({
    y: pageHeight,
  }));
  // Diese Funktion wird an ein Div gebunden, {...dragElement()}, das dann interaktiv gedragged werden kann
  const dragElement = useDrag(
    ({
      movement: [, movementY],
      offset,
      lastOffset,
      tap,
      last,
      vxvy,
      first,
      direction,
    }) => {
      if (!props.draggable) return;

      // Loslassen des Drags
      if (last && innerStatus === "moving") {
        // Position in Prozent von unten
        const position = +(100 - (movementY / pageHeight) * 100).toFixed();
        if (position >= 60) {
          // Position niedriger als Close Threshold
          if (innerStatus !== "open") {
            setInnerStatus("open");
          } else {
            handleOpen();
          }
        } else if (position <= 20) {
          // Position niedriger als Threshold
          if (innerStatus !== "close") {
            setInnerStatus("close");
          } else {
            handleClose();
          }
        } else {
          // Position zwischen oberen Thresholds
          if (innerStatus !== "preOpen") {
            setInnerStatus("preOpen");
          } else {
            handlePreOpen();
          }
        }
        setDragOptions((prevState) => {
          return {
            ...prevState,
            startMovingOffset: 0,
          };
        });

        return;
      }
      // Actions, die während des Drags gesetzt werden
      containerRef.current.style.height = `${
        pageHeight - movementY - props.fullscreenPadding
      }px`;

      const makePositive = (value) => {
        return value < 0 ? value * -1 : value;
      };

      // Fenster mitziehen lassen indem man den movementY wert vom Draggen dem Animationseffekt zum setzen übergibt
      // aber das nur wenn die draggeschwindigkeit nach unten/ oben höher ist als die nach links und rechts (velocity vxvy)
      if (makePositive(vxvy[0]) < makePositive(vxvy[1])) {
        if (innerStatus === "preOpen" && !first && !tap) {
          // wenn content sowieso nicht scrollbar -> einfach draggen!
          // erstes feuern, wenn man auf den drag geht
          setInnerStatus("moving");
        } else if (
          // Wenn aber das Element Scrollbar ist!
          // soll erst on scrollTop und nur mit drag nach unten gedragged werden
          childRef.current.scrollTop <= 0 &&
          direction[1] > 0 &&
          // schaut quasi, dass es das erste mal beim moven setzt und ned die ganze zeit
          innerStatus !== "moving" &&
          !first &&
          !tap
        ) {
          // erstes feuern, wenn man auf den drag geht
          setDragOptions((prevState) => {
            return {
              ...prevState,
              // wichtig, damit drag nicht anfängt zu draggen ab der position wo angefangen wurde zu scrollen
              startMovingOffset: offset[1] - lastOffset[1],
            };
          });
          setInnerStatus("moving");
        }
      }

      // nur wenn der status moving erteilt wurde, darf der drag den container bewegen
      if (innerStatus === "moving") {
        springAPI.start({
          y:
            // diese condition verhindert, dass man den container höher zieht als er ist
            movementY - dragOptions.startMovingOffset > 0
              ? movementY - dragOptions.startMovingOffset
              : 0,
          immediate: true,
        });
      }
    },
    // Start des Containers, damit Animationen den korrekten Weg haben
    {
      initial: () => [0, y.get()],
      useTouch: true,
      eventOptions: {
        capture: false,
      },
    }
  );
  const showChildren = innerStatus !== "preOpen" || !props.preOpenContent;
  const showPreOpenContent =
    (innerStatus === "preOpen" || props.keepPreOpenContent) &&
    props.preOpenContent;
  const showMoreButton = innerStatus === "preOpen" && props.showReadMoreButton;

  const handleClose = () => {
    if (props.noClose) {
      setInnerStatus("preOpen");
      return;
    }

    if (props.handleCloseStart) {
      props.handleCloseStart();
    }
    springAPI.start({
      y: pageHeight + containerHeight,
      ...fade,
      onRest: () => {
        if (props.handleCloseEnd) {
          props.setMapPadding({ bottom: 0 });
          props.handleCloseEnd();
        }
      },
    });
  };

  const handleOpen = () => {
    if (props.handleOpenStart) {
      props.handleOpenStart();
    }
    springAPI.start({
      y: 0,
      ...fade,
      onRest: () => {
        if (props.handleOpenEnd) {
          props.handleOpenEnd();
        }
      },
    });
    containerRef.current.style.height = `${pageHeight}px`;
  };

  const handlePreOpen = () => {
    if (initMount.current) return;
    //Dieser Teil settet das mapbox map padding nach der Größe des DragContainers
    // map.component passt dann die mitte des ausgewählten features auf dieses Padding an,

    // Animation des Hochfahrens
    springAPI.start({
      y: pageHeight - containerHeight,
      ...fade,
      onStart: () => {
        props.setMapPadding({ bottom: containerHeight - 50 });
      },
      onRest: () => {
        if (containerHeight && containerRef.current) {
          containerRef.current.style.height = `${
            containerHeight - props.fullscreenPadding
          }px`;
        }
      },
    });

    // Dragging aktivieren
    setDragOptions((prevState) => ({
      ...prevState,
      enabled: true,
    }));
  };
  const handleMinimized = () => {
    if (initMount.current) return;
    //Dieser Teil settet das mapbox map padding nach der Größe des DragContainers
    // map.component passt dann die mitte des ausgewählten features auf dieses Padding an,
    const dragHeaderHeight = dragHeaderContainerRef.current.clientHeight;
    // Animation des Hochfahrens
    springAPI.start({
      y: pageHeight - dragHeaderHeight,
      ...fade,
      onStart: () => {
        props.setMapPadding({ bottom: dragHeaderHeight - 50 });
      },
      onRest: () => {
        if (dragHeaderHeight != null) {
          containerRef.current.style.height = `${
            dragHeaderHeight - props.fullscreenPadding
          }px`;
        }
      },
    });
  };

  useResize(
    innerStatus,
    props.maxHeight,
    childRef,
    saveZoneRef,
    setContainerHeight,
    dragHeaderContainerRef,
    props.preOpenContent,
    props.children,
    setInnerStatus
  );

  useStatus(
    innerStatus,
    props.status,
    setInnerStatus,
    containerHeight,
    handleClose,
    handlePreOpen,
    handleOpen,
    handleMinimized
  );

  useEffect(() => {
    initMount.current = false;
  }, []);

  return (
    <a.div
      className={`${classes.container} ${
        "open" === innerStatus ? classes.open : ""
      } ${
        innerStatus !== "open" && !props.preOpenIsFullScreen
          ? classes.sideMargins_activated
          : classes.sideMargins_standard
      } ${
        innerStatus === "moving" ? "pointer-event-off" : "pointer-event-on"
      } position-absolute shadow-1 h-100 overflow-hidden bg-white no-scrollbar`}
      style={{
        y,
        transition: "margin 0.25s",
      }}
      ref={containerRef}
      {...dragElement()}
    >
      <SaveZoneWrapper
        activeTop={innerStatus === "open"}
        className={"d-flex flex-column transition-1000"}
        fullpage={innerStatus === "open"}
        givenRef={saveZoneRef}
      >
        <DragHeader
          dragElement={dragElement}
          setStatus={setInnerStatus}
          noClose={props.noClose}
          status={innerStatus}
          ref={dragHeaderContainerRef}
          interactionHandles={props.interactionHandles}
          showShadowOnScrolling={props.showShadowOnScrolling}
        />
        <div
          ref={childRef}
          className={`${
            innerStatus === "moving" || innerStatus === "preOpen"
              ? "overflow-hidden"
              : "overflow-auto"
          } no-scrollbar`}
        >
          {showPreOpenContent && props.preOpenContent}
          {showMoreButton && <ShowMoreButton setStatus={setInnerStatus} />}
          {showChildren && props.children}
        </div>
      </SaveZoneWrapper>
    </a.div>
  );
}

/**
 * Beim Wechsel (oder erstmaligem Aurufen)
 * der Features sorgen die Effects, dass die Karte sich jeweils der Größe anpasst.
 */
function useResize(
  status,
  maxHeight,
  childRef,
  saveZoneRef,
  setContainerHeight,
  dragHeaderContainer,
  preOpenContent,
  children
) {
  // Bei jedem wechsel des preOpenContents berechnet der Dragcontainer die Höhe
  // seiner kinder neu, damit der Effekt drunter seinen Job efüllen kann!
  useEffect(() => {
    if (status === "preOpen") {
      if (maxHeight > childRef.current.clientHeight) {
        setContainerHeight(saveZoneRef.current.clientHeight);
      } else {
        setContainerHeight(maxHeight);
      }
    }
  }, [preOpenContent, children]);
}
function useStatus(
  innerStatus,
  outerStatus,
  setInnerStatus,
  containerHeight,
  handleClose,
  handlePreOpen,
  handleOpen,
  handleMinimized
) {
  // führt je nach gesetztem Status die notwendige animation aus
  useEffect(() => {
    switch (innerStatus) {
      case "close": {
        handleClose();
        break;
      }
      case "minimized":
        handleMinimized();
        break;
      case "preOpen": {
        handlePreOpen();
        break;
      }
      case "open": {
        handleOpen();
        break;
      }
      case "moving": {
        // ändert nichts, aber wichtig für ein update hinterher
        break;
      }
      default: {
        throw Error(`navigationState "${innerStatus}" is un unknown!`);
      }
    }
  }, [innerStatus, containerHeight]);

  // Falls der Status von außerhalb bedient wird!
  // der innerstatus ist der wichtigere, er wechselt öfter als der outerstatus, deshalb wird der outerstatus
  // immer erst in den innerstatus umgewandelt und mit diesem wird dann gearbeitet
  useEffect(() => {
    setInnerStatus(outerStatus);
  }, [outerStatus]);
}
function ShowMoreButton({ setStatus }) {
  return (
    <button
      className={`btn  ${classes.showAll} ${classes.noShadow} mb-4 align-items-center mr-3 ml-3`}
      onClick={() => setStatus("open")}
    >
      <FormattedMessage id="menu.showAll" />
    </button>
  );
}
const DragHeader = React.forwardRef(
  (
    {
      setStatus,
      dragElement,
      status,
      noClose,
      interactionHandles,
      showShadowOnScrolling,
    },
    ref
  ) => {
    const intl = useIntl();

    if (interactionHandles || status === "minimized") {
      return (
        <div
          ref={ref}
          className={`transition-500 ${
            status === "open" ? `pt-3 pb-3` : "pt-1 pb-1"
          } ${
            classes.header
          } position-relative d-flex justify-content-center p-3 ${
            showShadowOnScrolling ? classes.shadow + "pt-3 pb-3" : ""
          }`}
          {...dragElement()}
        >
          {status !== "minimized" && (
            <RoundedIcon
              divClass={`roundedIcon__dragCard--sizeChanger ${classes.hidden}`}
              icon={["fal", "long-arrow-up"]}
            />
          )}
          {status !== "open" && (
            <div
              className={`shadow-none  position-relative `}
              style={
                status === "minimized"
                  ? { marginBottom: deviceSafePadding.bottom }
                  : {}
              }
              onClick={
                status === "minimized" ? () => setStatus("preOpen") : null
              }
              aria-label={intl.formatMessage({
                id: "menu.screenreader.upButton",
              })}
            >
              <FontAwesomeIcon
                icon={[
                  "fal",
                  status === "minimized" ? "chevron-up" : "horizontal-rule",
                ]}
                size="lg"
              />
            </div>
          )}
          {status !== "minimized" &&
            (status === "preOpen" ? (
              <RoundedIcon
                onClick={(e) => {
                  setStatus("open");
                  e.stopPropagation();
                }}
                divClass={`roundedIcon__dragCard--sizeChanger `}
                icon={["fal", "long-arrow-up"]}
                ariaLabel={intl.formatMessage({
                  id: "menu.screenreader.upButton",
                })}
              />
            ) : (
              <RoundedIcon
                divClass={`roundedIcon__dragCard--sizeChanger `}
                onClick={(e) => {
                  setStatus("close");
                  e.stopPropagation();
                }}
                icon={["fal", noClose ? "long-arrow-down" : "times"]}
                ariaLabel={intl.formatMessage({
                  id: "menu.screenreader.closeButton",
                })}
              />
            ))}
        </div>
      );
    } else return null;
  }
);

DragContainer.propTypes = {
  status: PropTypes.oneOf(["open", "preOpen", "close", "minimized", "moving"])
    .isRequired,
  maxHeight: PropTypes.number.isRequired, // if 0, it will use the contentHeight
  pageHeight: PropTypes.number,
  fullscreenPadding: PropTypes.number.isRequired,
  children: PropTypes.any.isRequired,
  // openHeader: PropTypes.object,
  // preOpenHeader: PropTypes.object,
  preOpenContent: PropTypes.object,
  preOpenIsFullScreen: PropTypes.bool,
  showReadMoreButton: PropTypes.bool,
  showShadowOnScrolling: PropTypes.bool,
  // callbacks für die verhaltensweisen
  handleOpenStart: PropTypes.func,
  handleOpenEnd: PropTypes.func,
  handleCloseStart: PropTypes.func,
  handleCloseEnd: PropTypes.func,
  // darf das teil gedragged oder allgemein bedient werden (nicht der content)
  interactionHandles: PropTypes.bool,
  draggable: PropTypes.bool,
  noClose: PropTypes.bool,
  // behält das preopen content zusätzlich zu den children
  keepPreOpenContent: PropTypes.bool,
};
DragContainer.defaultProps = {
  padding: 10,
  maxHeight: window.innerHeight / 2, // if 0, it will use the contentHeight
  interactionHandles: true,
  pageHeight: window.innerHeight,
  draggable: true,
  fullscreenPadding: 0,
  keepPreOpenContent: false,
};

// Dieses hier ist das einzige ReduxState, das verwendet wird!
// Ansonsten bleibt dieses ding hier WIEDERVERWENDBAR1!
const mapDispatchToProps = (dispatch) => {
  return {
    // Der Container muss immer bescheid geben, wie groß er gerade ist, damit das mapPadding angepasst werden kann!
    setMapPadding: (object, tellMap) =>
      dispatch(actionMap.setMapPadding(object, tellMap)),
  };
};
export default connect(null, mapDispatchToProps)(DragContainer);
