import React from "react";
import RoundedIcon from "../../elements/buttons/roundedIcon.component.jsx";
import { FormattedMessage, useIntl } from "react-intl";

export default function EtagenWechsler({
  increaseEtage,
  decreaseEtage,
  etagentext,
}) {
  const intl = useIntl();
  const fullEtagentext =
    intl.formatMessage({ id: "etagenwechsler.level" }) + " " + etagentext;
  return (
    <div
      className="d-flex pointer-event-on
    align-items-center mr-3 levelChanger"
    >
      <RoundedIcon
        onClick={decreaseEtage}
        icon={["fal", "chevron-down"]}
        divClass={"roundedIcon--actionBubble__levelChanger"}
        iconClass={"roundedIcon--actionBubble__levelChanger__icon"}
        ariaLabel={intl.formatMessage({
          id: "etagenwechsler.screenreader.downButton",
        })}
      />
      <div
        aria-label={etagentext}
        style={{ whiteSpace: "nowrap" }}
        className={"d-flex mr-3 ml-3 flex-nowrap"}
      >
        <span className={"pl-1"}>{fullEtagentext}</span>
      </div>
      <RoundedIcon
        onClick={increaseEtage}
        icon={["fal", "chevron-up"]}
        divClass={"roundedIcon--actionBubble__levelChanger"}
        iconClass={"roundedIcon--actionBubble__levelChanger__icon"}
        ariaLabel={intl.formatMessage({
          id: "etagenwechsler.screenreader.upButton",
        })}
      />
    </div>
  );
}
