import axios from "axios";
import { osName } from "react-device-detect";
import * as types from "../types.variables";
import Route from "../../functions/classes/Route.class";
import { polygon } from "@turf/helpers";
import { centroid } from "@turf/turf";
import { setFeatures } from "../../components/mapUi/mapbox/functions/drawer.map.js";
import { getRoute, onSameCampus } from "@catchupapplications/route-calculation";
import { piKey } from "../../app/app.sideEffects/useURLParams.sideEffects.js";
import { CAMPUSES, CONTENT, CAMPUS_COLLECTION, MODULES } from "./main";
import { openURL } from "../../components/nativeAppInterface/NativeAppInterface.jsx";
import { sourceIds } from "../../components/mapUi/mapbox/variables/sourceIds.js";
import { statisticLogger } from "../../components/statisticLogger/StatisticLogger.container";

const CancelToken = axios.CancelToken;
let cancel;

const dataForCampuses = {
  isFuerApp: 0,
  versionName: process.env.REACT_APP_APP_VERSION_NAME,
  version: process.env.REACT_APP_VERSION,
};
/**
 *
 * @param {StartDestination} start
 * @param {StartDestinatino} target
 * @param {{}} settings
 * @param {*} trafficType
 * @param {boolean} goToParkingSpot
 * @param {string} language
 * @param {*} deviceInformation
 * @param {boolean} isApp
 * @param {boolean} isTerminal
 * @returns
 */
export function setRoute(
  start,
  target,
  settings,
  trafficType,
  goToParkingSpot,
  language,
  deviceInformation,
  isApp,
  isTerminal
) {
  return function (dispatch) {
    if (process.env.REACT_APP_NEW_ROUTING) {
      start.updateZIndexWhenNecessary();
      if (
        onSameCampus(
          start.getDataForRouteCalculation(target.campusId),
          target.getDataForRouteCalculation(),
          CAMPUSES,
          CONTENT
        ) &&
        campusIdsCanGoOffline(
          start.getDataForRouteCalculation(target.campusId),
          target.getDataForRouteCalculation()
        )
      ) {
        statisticLogger.addLog({
          action: {
            id: "calculatedRouteOnCampus",
            name: "Calculated Route on campus",
            type: "input",
            group: "Route",
            content: {
              start: start.getDataForRouteCalculation(target.campusId),
              destination: target.getDataForRouteCalculation(),
              trafficType,
              isEmployee: settings.employee,
              disabledParkingPermit: settings.disabledParkingPermit,
            },
          },
        });
        getRoute(
          language.split("_")[0],
          target.campusId,
          trafficType,
          trafficType && settings.movementRestricted ? 10 : 8,
          start.getDataForRouteCalculation(target.campusId),
          target.getDataForRouteCalculation(),
          settings.supplier,
          goToParkingSpot && trafficType !== 8,
          settings.disabledParkingPermit,
          settings.visualImpaired,
          settings.employee,
          CAMPUSES,
          CONTENT,
          false,
          false,
          // todo: das ist eigentlich nicht mehr notwendig, weil es direkt von der datenbank abgefragt werden soll
          MODULES.hasLiveNavi,
          deviceInformation,
          isApp ? "App" : "Browser",
          window.navigator.userAgent,
          null,
          piKey,
          isTerminal,
          true
        )
          .then((res) => {
            dispatch({
              type: types.ROUTE_SET_ROUTE,
              payload: new Route({
                ...res.routes[0],
                ...{ waypoints: res.waypoints },
              }),
            });
          })
          .catch((e) => {
            dispatch({
              type: types.ROUTE_SET_ERROR,
              payload: e,
            });
            window.addError(e);
            throw e;
          });

        return;
      }

      if (!navigator.onLine) {
        window.addWarning({
          show: true,
          upload: false,
          userMessage: "route.notOnline",
        });

        return;
      }

      statisticLogger.addLog({
        action: {
          id: "calculatedRouteOutsideCampus",
          name: "Calculated Route outside campus",
          type: "input",
          group: "Route",
          content: {
            campusID: CAMPUS_COLLECTION.getCurrentCampus().id,
            start: start.getDataForRouteCalculation(target.campusId),
            destination: target.getDataForRouteCalculation(),
            trafficType,
            isEmployee: settings.employee,
            disabledParkingPermit: settings.disabledParkingPermit,
          },
        },
      });

      axios
        .post(
          process.env.REACT_APP_NEW_ROUTING,
          {
            campusID: CAMPUS_COLLECTION.getCurrentCampus().id,
            piKey,
            mode: trafficType,
            start: start.getDataForRouteCalculation(target.campusId),
            destination: target.getDataForRouteCalculation(),
            isSupplier: settings.supplier,
            needParkingSpot: goToParkingSpot && trafficType !== 8,
            disabledParking: settings.disabledParkingPermit,
            isVisualImpact: settings.visualImpaired,
            isEmployee: settings.employee,
            language,
            dataForCampuses,
            isSbS: true,
            deviceInformation,
            type: isApp ? "App" : "Browser",
            userAgent: window.navigator.userAgent,
            isTerminal,
          },
          {
            cancelToken: new CancelToken((c) => {
              cancel = c;
            }),
          }
        )
        .then((result) => {
          const data = result.data.result;
          dispatch({
            type: types.ROUTE_SET_ROUTE,
            payload: new Route({
              ...data.routes[0],
              ...{ waypoints: data.waypoints },
            }),
          });
        })
        .catch((e) => {
          dispatch({
            type: types.ROUTE_SET_ERROR,
            payload: e,
          });
          window.addError(e);
          throw e;
        });
    }
  };
}

export function resetRoute() {
  setFeatures({ sourceId: sourceIds.routeSymbole });
  setFeatures({ sourceId: sourceIds.kanten });
  setFeatures({ sourceId: sourceIds.arrowHead });
  setFeatures({ sourceId: sourceIds.arrowKanten });

  return {
    type: types.ROUTE_RESET_ROUTE,
  };
}

export function downloadRoute(
  start,
  target,
  settings,
  trafficType,
  goToParkingSpot,
  language,
  deviceInformation,
  isApp
) {
  return function (dispatch) {
    axios({
      url: process.env.REACT_APP_GET_PDF,
      method: "POST",
      responseType: "blob",
      data: {
        campusID: start.campusId ? start.campusId : target.campusId,
        piKey,
        mode: trafficType,
        start: start.getDataForRouteCalculation(target.campusId),
        destination: target.getDataForRouteCalculation(),
        isSupplier: settings.supplier,
        needParkingSpot: goToParkingSpot && trafficType !== 8,
        disabledParking: settings.disabledParkingPermit,
        isVisualImpact: settings.visualImpaired,
        isEmployee: settings.employee,
        language,
        dataForCampuses,
        isSbS: true,
        deviceInformation,
        type: isApp ? "App" : "Browser",
        userAgent: window.navigator.userAgent,
        getLink: !!isApp,
        url: window.location.href,
      },
    }).then((response) => {
      if (!isApp) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "route.pdf");
        document.body.appendChild(link);
        link.click();
      } else {
        response.data.text().then((link) => {
          openURL(link);
        });
      }

      dispatch({
        type: types.ROUTE_SET_IS_GENERATING_PDF,
        payload: false,
      });
    });
  };
}

export function setStart(startDestination) {
  return {
    type: types.ROUTE_SET_START,
    payload: startDestination,
  };
}

export function mapURLParamsHandled() {
  return {
    type: types.ROUTE_URL_PARAMS_HANDLED,
  };
}

export function setDefaultStartNode(nodeId) {
  return {
    type: types.ROUTE_SET_DEFAULT_STARTNODE,
    payload: +nodeId,
  };
}

export function setTarget(startDestination) {
  return {
    type: types.ROUTE_SET_TARGET,
    payload: startDestination,
  };
}

export function resetStart() {
  return {
    type: types.ROUTE_SET_START,
    payload: null,
  };
}

export function resetTarget() {
  return {
    type: types.ROUTE_SET_TARGET,
    payload: null,
  };
}

export function setRoadUserType(type) {
  return {
    type: types.ROUTE_SET_ROAD_USER_TYPE,
    payload: type,
  };
}
export function setFetchNewRoute(status) {
  return {
    type: types.ROUTE_SET_FETCH_NEW_ROUTE,
    payload: status,
  };
}

export function setGoToParkingSpot(status) {
  return {
    type: types.ROUTE_SET_GO_TO_PARKING_SPOT,
    payload: status,
  };
}

export function setIsGeneratingPDF(isGeneration) {
  return {
    type: types.ROUTE_SET_IS_GENERATING_PDF,
    payload: isGeneration,
  };
}

/**
 * @param {UserLocation} locationObject
 */
export function setSimulatedLocation(locationObject) {
  return {
    type: types.NAVIGATION_SET_SIMULATED_LOCATION_DATA,
    payload: locationObject,
  };
}

// no redux - just canceling request...
export function cancelRequest() {
  // todo: @Raimund die routefetch canceln funktioniert nicht!
  if (cancel !== undefined) {
    cancel();
  }
}

function getCords(lat, lng) {
  if (!lng && lat.length > 1) {
    const polygons = polygon([lat]);
    const [return1, return2] = centroid(polygons).geometry.coordinates;
    return [return1, return2];
  }
  return [lat, lng];
}

function campusIdsCanGoOffline(start, destination) {
  const ignoreCampuses = [
    34, 85, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125,
    126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138,
  ];

  return !(
    ignoreCampuses.includes(start.campusId ?? -1) ||
    ignoreCampuses.includes(destination.campusId ?? -1)
  );
}
