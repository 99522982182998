import { sourceIds } from "../../components/mapUi/mapbox/variables/sourceIds.js";
export default class Polygon {
  constructor(allProps) {
    // Das hier müsste irgendwann noch getrennt werden, aber momentan ist es das noch nicht!
    this.id = allProps.id;
    this.globalPoiId = allProps.globalPoiId
      ? allProps.globalPoiId
      : allProps.id;

    if (
      allProps.additionalData?.visualPolygon &&
      allProps.additionalData?.visualPolygon.geometry
    ) {
      this.geometry = allProps.additionalData?.visualPolygon.geometry;
      this.properties = allProps.additionalData?.visualPolygon.properties;
      this.type = "Feature";
    } else {
      if (Array.isArray(allProps.polygon[0][0])) {
        this.geometry = {
          type: "Polygon",
          coordinates: allProps.polygon,
        };
      } else {
        this.geometry = {
          type: "Polygon",
          coordinates: [allProps.polygon],
        };
      }
      this.type = "Feature";
    }

    this.properties = { ...this.properties, ...allProps, poiId: this.id };

    // TODO: Das ist nur ein temporärer Abfang.
    // Am Ende müssen die Gebäude sowieso von MapSDK gerendert werden!
    if (!this.properties.class) {
      this.properties.class = "normal";
    }
    if (this.properties.height == null) {
      this.properties.height = 10;
    }

    // Additionaldata aufspalten, weil mapbox sonst buganfällig ist
    if (allProps.additionalData) {
      for (let key in allProps.additionalData) {
        this.properties[key] = allProps.additionalData[key];
      }
    }

    // Unnötige Sachen ausfiltern sonst, leidet mapbox performance
    this.properties.polygon = undefined;
    this.properties.nodeId = undefined;
    this.properties.lat = undefined;
    this.properties.lng = undefined;
    this.properties.id = undefined;
    this.properties.additionalData = undefined;
    this.properties.visualPolygon = undefined;
  }
}
