import React, { useEffect, useState } from "react";
import * as actionInfoCard from "../../../../reduxStore/actions/infoCard.js";
import * as actionAppointment from "../../../../reduxStore/actions/appointment.js";
import { connect } from "react-redux";
import StandardButton from "../../../elements/buttons/standard.button.jsx";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import {
  MODULES,
  setCurrentAppOverlay,
} from "../../../../reduxStore/actions/main";
import { useIntl } from "react-intl";
import { openNativeShareDialog } from "../../../nativeAppInterface/NativeAppInterface.jsx";
import { isAndroid, isChrome } from "react-device-detect";
import { AppOverlayIFrame } from "../../../../functions/classes/miscelleanous.classes.js";
import { getQueryParams } from "../../../../app/RouteWithSubRoutes";
import { pageNames } from "../../../../reduxStore/reducers/main.js";
import StandardText from "../../../elements/text/standardText.text.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MISC_INFO } from "../../../../reduxStore/actions/main";
import { statisticLogger } from "../../../statisticLogger/StatisticLogger.container.js";
import {
  piKey,
  referrerTypes,
} from "../../../../app/app.sideEffects/useURLParams.sideEffects.js";

function InteractionButtons({
  setStatus,
  currentFeature,
  setShowCreateAppointment,
  setAppointmentPOI,
  history,
  isApp,
  isTerminal,
  setCurrentAppOverlay,
}) {
  const shareData = {
    text: currentFeature.name ? currentFeature.name : currentFeature.address,
    url: createShareURL(),
  };
  const cb = navigator.clipboard;
  const urlParams = new URLSearchParams(window.location.search);
  const isFramed = !!urlParams.get("isFramed");
  const intl = useIntl();
  const [copyToClipboardIsAllowed, setCopyToClipboardIsAllowed] =
    useState(true);

  useEffect(() => {
    (async () => {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: "clipboard-write",
        });

        if (
          !(
            permissionStatus.state === "granted" ||
            permissionStatus.state === "prompt"
          )
        ) {
          setCopyToClipboardIsAllowed(false);
        }
      } catch (error) {
        setCopyToClipboardIsAllowed(false);
      }
    })();
  }, []);

  const handlePhone = () => {
    statisticLogger.addLog({
      action: {
        group: "POICard",
        movement: "stay",
        type: "click",
        interaction: "execute",
        id: "callSelectedFacility",
        name: "Call selected facility",
        content: {
          globalPoiIds: [currentFeature.globalPoiId],
          globalPoiId: currentFeature.globalPoiId,
          globalName: currentFeature.name,
        },
      },
    });
  };
  const openRoute = () => {
    history.push(`/${pageNames.map}/${pageNames.route}/${getQueryParams()}`);
    statisticLogger.addLog({
      action: {
        group: "POICard",
        movement: "leave",
        type: "click",
        interaction: "execute",
        id: "routeToSelectedPOI",
        name: "Route to selected POI",
        content: {
          globalPoiIds: [currentFeature.globalPoiId],
          globalPoiId: currentFeature.globalPoiId,
          globalName: currentFeature.name,
        },
      },
    });
    setStatus("close");
  };
  const showWebsite = () => {
    if (currentFeature?.website == null) return;

    statisticLogger.addLog({
      action: {
        group: "POICard",
        movement: "stay",
        type: "click",
        interaction: "open",
        id: "websiteOfSelectedPoi",
        name: "Open website of selected poi",
        content: {
          globalPoiIds: [currentFeature.globalPoiId],
          globalPoiId: currentFeature.globalPoiId,
          globalName: currentFeature.name,
        },
      },
    });
    setCurrentAppOverlay(
      new AppOverlayIFrame("", currentFeature.website, {
        contentType: "commonUrl",
      })
    );
  };

  const handleShowShare = () => {
    statisticLogger.addLog({
      action: {
        group: "POICard",
        movement: "stay",
        type: "click",
        interaction: "execute",
        id: "shareSelectedPoi",
        name: "Share selected POI",
        content: {
          globalPoiIds: [currentFeature.globalPoiId],
          globalPoiId: currentFeature.globalPoiId,
          globalName: currentFeature.name,
        },
      },
    });
    if (navigator.share) {
      // für iOS und Browser
      navigator.share(shareData);
    } else {
      if (isApp && isAndroid) {
        // android logischerweise
        openNativeShareDialog(`${shareData.text} - ${shareData.url}`);
      } else {
        cb.writeText(shareData.url).then(() => {
          window.addLog({
            show: true,
            upload: false,
            userMessage: "share.copy",
          });
        });
      }
    }
  };

  return (
    <>
      {isTerminal && currentFeature.phone != null && (
        <div className={"d-flex mb-3"}>
          <FontAwesomeIcon icon={["fal", "phone"]} className={"mr-2"} />
          <StandardText>{currentFeature.phone}</StandardText>
        </div>
      )}
      <div className="d-flex flex-wrap mb-3">
        {currentFeature.hasLocation() && !MODULES.isOnlyMapVersion ? (
          <StandardButton
            ariaLabel={intl.formatMessage(
              { id: "einrichtungscard.screenreader.route" },
              { name: currentFeature.name }
            )}
            icon={["fal", "route"]}
            handleClick={openRoute}
            buttonClasses={"normalButton--primary mr-2 mb-2"}
            textClasses={"normalButton--primary__text"}
          >
            <FormattedMessage id={"einrichtungscard.route"} />
          </StandardButton>
        ) : null}
        {!isTerminal && currentFeature.phone ? (
          <a
            className={"text-decoration-none"}
            href={`tel://${currentFeature.phone}`}
          >
            <StandardButton
              ariaLabel={intl.formatMessage({
                id: "einrichtungscard.phoneCall",
              })}
              icon={["fal", "phone"]}
              buttonClasses={"normalButton--secondary mr-2 mb-2"}
              textClasses={"normalButton--secondary__text"}
              handleClick={handlePhone}
            >
              <FormattedMessage id="einrichtungscard.phoneCall" />
            </StandardButton>
          </a>
        ) : null}
        {currentFeature.website && (
          <StandardButton
            ariaLabel={intl.formatMessage({ id: "einrichtungscard.website" })}
            icon={["fal", "globe"]}
            buttonClasses={"normalButton--secondary mr-2 mb-2"}
            textClasses={"normalButton--secondary__text"}
            handleClick={showWebsite}
          >
            <FormattedMessage id={"einrichtungscard.website"} />
          </StandardButton>
        )}
        {!isApp && !isTerminal ? (
          <StandardButton
            handleClick={() => {
              setShowCreateAppointment(true);
              setAppointmentPOI(currentFeature);
            }}
            ariaLabel={intl.formatMessage({ id: "appointment.saveToCalendar" })}
            buttonClasses={"normalButton--secondary mr-2 mb-2"}
            textClasses={"normalButton--secondary__text"}
            icon={["fal", "calendar-alt"]}
          >
            <FormattedMessage id={"appointment.saveToCalendar"} />
          </StandardButton>
        ) : null}

        {!isTerminal && !(isFramed && isChrome) && copyToClipboardIsAllowed && (
          <StandardButton
            ariaLabel={intl.formatMessage({ id: "iframe.share" })}
            handleClick={handleShowShare}
            buttonClasses={"normalButton--secondary mr-2 mb-2"}
            textClasses={"normalButton--secondary__text"}
            icon={["fal", "share"]}
          >
            <FormattedMessage id={"iframe.share"} />
          </StandardButton>
        )}
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  isTerminal: state.main.isTerminal,
});
const mapDispatchToProps = (dispatch) => {
  return {
    setStatus: (status) => dispatch(actionInfoCard.setStatus(status)),
    setShowCreateAppointment: (value) =>
      dispatch(actionAppointment.setShowCreateAppointment(value)),
    setHideCreateAppointment: (value) =>
      dispatch(actionAppointment.setHideCreateAppointment),
    setAppointmentPOI: (value) =>
      dispatch(actionAppointment.setAppointmentPOI(value)),
    setCurrentAppOverlay: (appOverlay) =>
      dispatch(setCurrentAppOverlay(appOverlay)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InteractionButtons)
);

export function createShareURL() {
  const allParams = new URLSearchParams(window.location.search);
  const start = allParams.get("start");
  const destination = allParams.get("destination");
  const campusKeys = allParams.get("campusKeys");
  const params = new URLSearchParams();
  params.append("piKey", piKey);
  params.append("referrer", referrerTypes.shareUrl);

  let isRoute = false;
  if (start && start.length) {
    isRoute = true;
    params.append("start", start);
  }

  const url = `${MISC_INFO.shareUrl}/map/${isRoute ? "route" : "info"}`.replace(
    "//map/",
    "/map/"
  );

  if (destination && destination.length) {
    params.append("destination", destination);
  }
  if (campusKeys && campusKeys.length) {
    params.append("campusKeys", campusKeys);
  }

  return `${url}?${params.toString()}`;
}
