import circle from "./staticIcons/circle.svg";
import triangle from "./staticIcons/triangle.svg";
import normal_iconBackground from "./staticIcons/icon-background.svg";
import busstop from "./staticIcons/busstop.svg";
import start from "./staticIcons/start.svg";
import target from "./staticIcons/target.svg";
import taxi from "./staticIcons/taxi.svg";
import mapmarker from "./staticIcons/mapmarker-static.svg";
import parkingSpot_marker from "./staticIcons/parkingSpot_marker.svg";
import image from "./defaultIcons/image.svg";
import switchFloor from "./defaultIcons/switchFloor.svg";
import parkingBike from "./defaultIcons/parkingBike.svg";
import parkingSpot from "./defaultIcons/parkingGarage.svg";
import parkingGarage from "./defaultIcons/parkingGarage.svg";
import entrance from "./defaultIcons/entrance.svg";
import star from "./defaultIcons/star.svg";
import parkingDisabled from "./defaultIcons/parkingDisabled.svg";
import emergency from "./defaultIcons/emergency.svg";
import pharmacy from "./defaultIcons/pharmacy.svg";
import pointMarker from "./defaultIcons/pointMarker.svg";
import pointMarker_ghosted from "./defaultIcons/pointMarker_ghosted.svg";
import wcmen from "./defaultIcons/wcmen.svg";
import wcwomen from "./defaultIcons/wcwomen.svg";
import wcwomenandmen from "./defaultIcons/wcwomenandmen.svg";
import wcdisabled from "./defaultIcons/wcdisabled.svg";
import cashier from "./defaultIcons/cashier.svg";
import atm from "./defaultIcons/atm.svg";
import info from "./defaultIcons/info.svg";
import food from "./defaultIcons/food.svg";
import campus from "./defaultIcons/building.svg";
import virus from "./defaultIcons/virus.svg";
import icecream from "./defaultIcons/ice-cream.svg";
import elevator from "./defaultIcons/elevator.svg";
import stairs from "./defaultIcons/stairs.svg";
import location from "./defaultIcons/mapmarker.svg";
import pretzel from "./defaultIcons/pretzel.svg";
import basketShopping from "./defaultIcons/basket-shopping.svg";
import jelbi_heart from "./defaultIcons/jelbi_heart.svg";

export const defaultSettings = {
  width: 50,
  height: 50,
  pixelRatio: 0.9,
  sdf: true, // colors des svg werden überschrieben wenn true
};
/**
 * "name" ist der Name des SVG Files selbst, das entweder hier in der App ist oder gefetcht wird aus einem anderen Ordner
 * "dbName" ist der Name der Icons bei den POIs aus der Datenbank im Field "iconname" ist
 */
export let mapIcons = {
  ice: {
    icon: icecream,
    name: "ice-cream",
    dbName: "ice-cream",
    settings: defaultSettings,
  },
  jelbi_heart: {
    icon: jelbi_heart,
    name: "jelbi",
    dbName: "jelbi",
    settings: defaultSettings,
  },
  basketShopping: {
    icon: basketShopping,
    name: "basket-shopping",
    dbName: "basket-shopping",
    settings: defaultSettings,
  },
  pretzel: {
    name: "pretzel",
    dbName: "pretzel",
    icon: pretzel,
    settings: defaultSettings,
  },
  city: {
    icon: campus,
    name: "campus",
    dbName: "campus",
    settings: defaultSettings,
  },
  virus: {
    icon: virus,
    name: "virus",
    dbName: "virus",
    settings: defaultSettings,
  },
  image: {
    icon: image,
    name: "image",
    dbName: "attraction",
    settings: defaultSettings,
  },
  elevator: {
    name: "elevator",
    icon: elevator,
    dbName: "elevator",
    settings: defaultSettings,
  },
  stairs: {
    name: "stairs",
    icon: stairs,
    dbName: "stairs",
    settings: defaultSettings,
  },
  elevatorRestricted: {
    name: "elevator-restricted",
    icon: elevator,
    dbName: "elevator-restricted",
    settings: defaultSettings,
  },
  stairsRestricted: {
    name: "stairs-restricted",
    icon: stairs,
    dbName: "stairs-restricted",
    settings: defaultSettings,
  },
  cashier: {
    icon: cashier,
    name: "cashier",
    dbName: "kasse",
    settings: defaultSettings,
  },
  parkingBike: {
    icon: parkingBike,
    name: "parkingBike",
    dbName: "parkingBike",
    settings: defaultSettings,
  },
  parkingSpot_marker: {
    icon: parkingSpot_marker,
    name: "parking",
    dbName: "parking",
    settings: defaultSettings,
  },
  campus: {
    icon: campus,
    name: "campus",
    dbName: "campus",
    settings: defaultSettings,
  },
  atm: {
    icon: atm,
    name: "atm",
    dbName: "geldautomat",
    settings: defaultSettings,
  },
  mapmarker: {
    icon: mapmarker,
    name: "pinSymbol",
    dbName: "pinSymbol",
    settings: defaultSettings,
  },
  info: {
    icon: info,
    name: "info",
    dbName: "info",
    settings: defaultSettings,
  },
  food: {
    icon: food,
    name: "food",
    dbName: "bistro",
    settings: defaultSettings,
  },
  wcwomen: {
    icon: wcwomen,
    name: "wcwomen",
    dbName: "wcwomen",
    settings: defaultSettings,
  },
  wcmen: {
    icon: wcmen,
    name: "wcmen",
    dbName: "wcmen",
    settings: defaultSettings,
  },
  wcwomenandmen: {
    icon: wcwomenandmen,
    name: "wcwomenandmen",
    dbName: "wcwomenandmen",
    settings: defaultSettings,
  },
  wcdisabled: {
    icon: wcdisabled,
    name: "wcdisabled",
    dbName: "wcdisabled",
    settings: defaultSettings,
  },
  normal_iconBackground: {
    icon: normal_iconBackground,
    name: "poi_background",
    dbName: "poi_background",
    settings: defaultSettings,
  },
  triangle: {
    icon: triangle,
    name: "triangle",
    dbName: "triangle",
    settings: defaultSettings,
  },
  circle: {
    icon: circle,
    name: "circle",
    dbName: "circle",
    settings: defaultSettings,
  },
  start: {
    icon: start,
    name: "start",
    dbName: "start",
    settings: defaultSettings,
  },
  target: {
    icon: target,
    name: "target",
    dbName: "target",
    settings: defaultSettings,
  },
  location: {
    icon: location,
    name: "location",
    dbName: "location",
    settings: defaultSettings,
  },
  switchFloor: {
    icon: switchFloor,
    name: "switchFloor",
    dbName: "switchFloor",
    settings: defaultSettings,
  },
  parkingSpot: {
    icon: parkingSpot,
    name: "parkingSpot",
    dbName: "parkingSpot",
    settings: defaultSettings,
  },
  parkingGarage: {
    icon: parkingGarage,
    name: "parkingGarage",
    dbName: "parkingGarage",
    settings: defaultSettings,
  },
  entrance: {
    icon: entrance,
    name: "entrance",
    dbName: "entrance",
    settings: defaultSettings,
  },
  star: {
    icon: star,
    name: "star",
    dbName: "star",
    settings: defaultSettings,
  },
  busstop: {
    icon: busstop,
    name: "busstop",
    dbName: "bushaltestelle",
    settings: defaultSettings,
  },
  taxi: {
    icon: taxi,
    name: "taxi",
    dbName: "taxi",
    settings: defaultSettings,
  },
  parkingDisabled: {
    icon: parkingDisabled,
    name: "parkingDisabled",
    dbName: "parkingDisabled",
    settings: defaultSettings,
  },
  emergency: {
    icon: emergency,
    name: "emergency",
    dbName: "notaufnahme",
    settings: defaultSettings,
  },
  pharmacy: {
    icon: pharmacy,
    name: "pharmacy",
    dbName: "apotheke",
    settings: defaultSettings,
  },
  pointMarker: {
    icon: pointMarker,
    name: "pointMarker",
    dbName: "pointMarker",
    settings: defaultSettings,
  },
  pointMarker_ghosted: {
    icon: pointMarker_ghosted,
    name: "pointMarker_ghosted",
    dbName: "pointMarker_ghosted",
    settings: defaultSettings,
  },
};
