/**
 * Verwendet für MapOverlays in App.js
 */
export class AppOverlay {
  static names = {
    iFrame: "iFrame",
    search: "search",
    customContent: "customContent",
  };

  /**
   *
   * @param name - Einer der names, damit der Component weiß, was er rendern soll
   * @param value - true, wenn gerendert werden soll
   * @param show - Ist für Mobile/Desktop Unterscheidung. Bei Search z.B. ruft mobile ein AppOverlay auf, aber Desktop nicht, stattdessen liest er aus dem gleichen
   * state und ruft es in einer Card auf!
   */
  constructor(name = null, value = null, show = true) {
    this.name = name;
    this.value = value;
    this.show = show;
  }
}

/**
 * @param title =""
 * @param url=""
 * @param {Object} options
 * @param {CalendarEvent} options.event
 * @param {"commonUrl" | "event" | "news"} options.contentType
 */
export class AppOverlayIFrame extends AppOverlay {
  /** @type {"commonUrl" | "event" | "news"} */
  contentType = "commonUrl";

  constructor(title, url, { event, contentType = "commonUrl" } = {}) {
    if (url.includes("http") && !url.includes("https")) {
      url = url.replace("http", "https");
    }
    if (!url.includes("http")) {
      url = "https://" + url;
    }
    super(AppOverlay.names.iFrame, { title: title, url: url });

    this.event = event;
    this.contentType = contentType;
  }
}
export class AppOverlaySearch extends AppOverlay {
  /**
   *
   * @param {boolean} show
   * @param {func} onClose
   * @param {func} onPOIClicked
   * @param {func} onSpecialLocationClicked
   * @param {boolean} mustHaveLocation
   * @param {boolean} canSetPositionOnMap
   * @param {boolean} canSetCurrentPosition
   */
  constructor(
    show,
    type,
    {
      onClose,
      onPOIClicked,
      onSpecialLocationClicked,
      mustHaveLocation = true,
      canSetPositionOnMap = false,
      canSetCurrentPosition = false,
    } = {}
  ) {
    super(AppOverlay.names.search, true, show);
    this.type = type;
    this.onClose = onClose;
    this.onPOIClicked = onPOIClicked;
    this.onSpecialLocationClicked = onSpecialLocationClicked;
    this.mustHaveLocation = mustHaveLocation;
    this.canSetPositionOnMap = canSetPositionOnMap;
    this.canSetCurrentPosition = canSetCurrentPosition;
  }
}
