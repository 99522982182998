import { useEffect } from "react";
import mapinteractivity from "../functions/interactivity.map.js";
import { addSourcesAndLayers, addSVGsToMap } from "../functions/drawer.map.js";
import {
  initCampus,
  piKey,
} from "../../../../app/app.sideEffects/useURLParams.sideEffects.js";
import {
  STANDARD_MAP_PADDING,
  STANDARD_MAP_PADDING_DESKTOP,
} from "../../../../reduxStore/reducers/map.js";
import { setOpenMapsFilters } from "../functions/filter.map.js";
import { CUMap } from "@catchupapplications/map-libre-sdk";
import { isMobile } from "react-device-detect";
import { statisticLogger } from "../../../statisticLogger/StatisticLogger.container.js";

/** @type CUMap */
export let map;
export const setMap = (value) => {
  map = value;
};

/**
 * Initialisierung der Map beim ersten Laden der MapUI Page
 */
export default function useMap(returnedMap, props, springAPI, setMapPadding) {
  const initMap = () => {
    // Diese Condition überprüft, ob die Map geladen ist
    // wird nicht aufgerufen, wenn ein Seitenwechsel stattfindet!
    if (!returnedMap) return;
    map = returnedMap;

    // nur zum Testen
    window.map = returnedMap;
    // Wenn Map geladen ist andere useEffects benachrichtigen!
    // Grund dafür ist, dass für mehrere map.on("load") nicht die Reihenfolge festgelegt werden kann
    // für die jeweiligen useEffects aber schon!
    // nachdem die Map geladen ist Interaktivitätsfunktionen und Source/layers anbinden
    setMapPadding(
      isMobile ? STANDARD_MAP_PADDING : STANDARD_MAP_PADDING_DESKTOP,
      true
    );

    const currentCampus = initCampus
      ? map.campusCollection.getCampusByKey(initCampus)
      : map.campusCollection.getCurrentCampus();
    props.setCurrentCampusId(currentCampus.id);
    props.setCurrentCampusKey(currentCampus.campusKey);

    map.subscribe("campusChange", "campusChangerUseMap", (k) => {
      if (k && k.id && k.campusKey) {
        if (
          window.location.href.includes("/map/route/") ||
          window.location.href.includes("/map/navigation/")
        )
          return;
        props.setCurrentCampusId(k.id);
        props.setCurrentCampusKey(k.campusKey);
      }
    });

    map.subscribe("poiClick", "poiClickKKKK", (feature) => {
      if (feature) {
        props.setCurrentFeature(feature);
        statisticLogger.addLog({
          action: {
            id: "poiClickOnMap",
            name: "Poi on map clicked",
            group: "Map",
            movement: "stay",
            type: "click",
            interaction: "select",
            content: {
              globalPoiIds: [feature.properties.globalPoiId],
              globalPoiId: feature.properties.globalPoiId,
              globalName: feature.properties?.name,
            },
          },
        });
      }
    });

    // Dies wird durch die URL aktiviert
    if (props.mapAngleValue != null) {
      map.setBearing(props.mapAngleValue);
    }
    // Einblendung, dass map geladen ist!
    springAPI.start({
      to: {
        opacity: 1,
        filter: "none",
      },
    });

    // Initialisierung der Interactivity Funktionen auf der Map
    mapinteractivity({
      map: map,
      toggleIsFullMap: props.toggleIsFullMap,
      setCurrentFeature: props.setCurrentFeature,
      setBounds: props.setBounds,
      incrementMapClicks: props.incrementMapClicks,
      setMainMenuClose: props.setMainMenuClose,
      toggleMarkers: () => {},
      setViewIsIndoors: props.setViewIsIndoors,
      setCurrentFloorNumber: props.setCurrentFloorNumber,
    });

    // Richtiges Anbinden (Reihenfolge) der Source- und LayerIds
    // (ohne Features, da diese noch laden)
    addSourcesAndLayers({
      isTerminal: props.isTerminal,
      defaultStartNode: props.defaultStartNode,
    });
    setOpenMapsFilters();
    // Im Mai ist meine Bewährung vorbei, sagt allen Bescheid wir sind hungrig und high
    setTimeout(() => {
      // Ein Puffer damit, die Map wirklich hübsch geladen wird!
      props.setMapLoaded(true);
    }, 500);
  };

  useEffect(() => {
    if (props.serverDataLoaded && props.mapLoaded && piKey != null) {
      addSVGsToMap();
    }
  }, [props.mapLoaded, props.serverDataLoaded, piKey]);

  // Dieser Effekt wird ausgeführt, wenn die App initial geladen wurde
  useEffect(initMap, [returnedMap, props.urlParametersReady]);

  //resize map after pagechange
  // ansonsten ist die map verbuggt klein
  useEffect(() => {
    if (!props.mapLoaded || props.currentMainPage !== "map") return;
    map.resize();
  }, [props.currentMainPage, props.mapLoaded]);
}
