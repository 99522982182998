import * as types from "../types.variables.js";

/**
 * User type definition
 * @params {Toast} toast
 */
export function addToast(toast) {
  return {
    type: types.TOAST_ADD_TOAST,
    payload: toast,
  };
}
export function deleteToast(id) {
  return {
    type: types.TOAST_DELETE_TOAST,
    payload: id,
  };
}
export function setToastAdded(toast) {
  return {
    type: types.TOAST_SET_TOAST_ADDED,
    payload: toast,
  };
}
export function setToastDeleted(id) {
  return {
    type: types.TOAST_SET_TOAST_DELETED,
    payload: id,
  };
}
