import { connect } from "react-redux";
import { useIntl } from "react-intl";
import * as action from "../../../../reduxStore/actions/settings";
import FullPage from "../../../elements/fullPage/fullPage";
import RouteSettings from "../../../settings/pages/RouteSettings";
import SplashScreen from "../../../elements/splashScreen/splashScreen.render.jsx";
function RoutSettings(props) {
  const intl = useIntl();

  if (!props.isOpen) return null;

  return (
    <SplashScreen
      onClosingFinished={props.close}
      render={(handleClose) => {
        return (
          <FullPage
            title={intl.formatMessage({ id: "route.settings" })}
            needClose
            needSearch={false}
            bgClass="ca-bg-white"
            positionAbsolute={false}
            onClose={handleClose}
          >
            <RouteSettings
              close={handleClose}
              settings={props.settings.routing}
              text={intl.formatMessage({ id: "settings.routeSettingsText" })}
              setProperties={props.setProperties}
            />
          </FullPage>
        );
      }}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProperties: (properties) => dispatch(action.setProperties(properties)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoutSettings);
