export default function StandardHeadline({
  type = "h3",
  text,
  className = "",
  onClick = () => {},
  noMargin = true,
  children,
  ariaLabel,
  ariaHidden,
}) {
  let returnClass = `text-wrap text-break standardHeadline ${
    noMargin ? "m-0" : ""
  } ${className}`;
  switch (type) {
    case "h2":
      return (
        <h2
          aria-hidden={ariaHidden}
          aria-label={ariaLabel}
          className={returnClass}
          onClick={onClick}
        >
          {text || children}
        </h2>
      );
    case "h3":
      return (
        <h3
          aria-hidden={ariaHidden}
          aria-label={ariaLabel}
          className={returnClass}
          onClick={onClick}
        >
          {text || children}
        </h3>
      );
    case "h4":
      return (
        <h4
          aria-hidden={ariaHidden}
          aria-label={ariaLabel}
          className={returnClass}
          onClick={onClick}
        >
          {text || children}
        </h4>
      );
    case "h5":
      return (
        <h5
          aria-hidden={ariaHidden}
          aria-label={ariaLabel}
          className={returnClass}
          onClick={onClick}
        >
          {text || children}
        </h5>
      );
    default:
      return (
        <h1
          aria-hidden={ariaHidden}
          aria-label={ariaLabel}
          className={returnClass}
          onClick={onClick}
        >
          {text || children}
        </h1>
      );
  }
}
