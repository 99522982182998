import React from "react";
import { sourceIds } from "../../components/mapUi/mapbox/variables/sourceIds.js";
import { CAMPUS_NODES } from "../../reduxStore/actions/main.js";

export default class Node {
  static moeglicheAttribute = [];

  /**
   * Der Node ist so aufgebaut, dass er als ein Geojson Feature verwendet werden kann!
   * Wenn aber vom User etwas erstellt wird, muss es auf true um in die Datenkollektion aufgenommen zu werden
   * und recorded zu werden
   * @param obj
   */
  constructor({ id, lat, lng, level, attributIds, allProps, indoors, poiId }) {
    this.id = parseInt(id);
    this.geometry = {
      type: "Point",
      coordinates: [parseFloat(lng), parseFloat(lat)],
    };
    // diese Source wird benötigt, damit mapInteractivity
    // durch mapbox APIs richtig selecten und unselecten kann
    this.source = sourceIds.nodes;
    this.relations = {};
    this.relations.events = [];
    this.relations.news = [];
    this.relations.phonebookEntries = [];
    this.type = "Feature";
    this.properties = {
      // ...allProps,
      // poiId: poiId,
      additionalData: allProps.additionalData,
      class: allProps.additionalData?.class,
      short: allProps.short,
      globalPoiId: poiId,
      // bezeichner: "" + id,
      // anweisung: "",
      indoors: indoors,
      attributIds: Array.isArray(attributIds) ? attributIds : [],
      // zielId: parseInt(zielId),
      level: parseInt(level),
      campusId: allProps.campusId,
      // adresseId: parseInt(adresseId),

      type: allProps.type,
      name: allProps.name,
      iconname: allProps.iconname,
      priority: allProps.priority,
      hasPoiSymbol:
        (allProps.type === "Einrichtung" || allProps.type === "POI") &&
        allProps.styleClasses != null
          ? true
          : false,
      // unnötiges ausfiltern
    };
    // Additionaldata aufspalten, weil mapbox sonst buganfällig ist
    if (allProps?.additionalData) {
      for (let key in allProps.additionalData) {
        this.properties[key] = allProps.additionalData[key];
      }
    }
  }
}

/**
 * Suche Node anhand seiner ID aus den globalen Node
 * @param id
 * @param [] zugreifbare Nodes
 * @returns {null|any} : Node
 */
export function getNodeById(id, alleKnoten = []) {
  let passende = alleKnoten.filter(function (p) {
    return p.id == id;
  });
  if (passende.length > 0) {
    return passende[0];
  }

  // such noch die Nodes durch, die an keinem POI hängen
  let rawNode = CAMPUS_NODES[id];
  let node;
  try {
    if (rawNode) {
      node = new Node({
        allProps: {},
        id: rawNode.id,
        lat: rawNode.coord[0],
        lng: rawNode.coord[1],
        level: rawNode.coord[2],
      });
      return node;
    }
  } catch (e) {
    console.error("Error bei Erstellung von Terminal Node Marker", e);
    return null;
  }
  return null;
}
