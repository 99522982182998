export default class Toast {
  constructor(id, text, type, actions = [], lifeTime = 3000) {
    let currentTime = new Date().getTime();

    this.id = id + "_" + currentTime;
    this.id_type = id;
    this.text = text;
    this.type = type;
    this.actions = actions;
    this.spawnTime = currentTime;
    this.lifeTime = lifeTime;
  }
  static toastTypes = {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  };
}
export const toastTypes = {
  success: "success",
  error: "error",
  warning: "warning",
  info: "info",
};
