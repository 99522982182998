import React from "react";

export default function EntranceSVG() {
  return (
    <svg
      width="390px"
      height="390px"
      viewBox="0 0 390 390"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Screen / illustration / Gebaeude-rein</title>
      <g
        id="Screen-/-illustration-/-Gebaeude-rein"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M55,69 L335,69 C346.045695,69 355,77.954305 355,89 L355,369 C355,380.045695 346.045695,389 335,389 L55,389 C43.954305,389 35,380.045695 35,369 L35,89 C35,77.954305 43.954305,69 55,69 Z"
          id="Rectangle"
          fill="#F0F3F5"
        ></path>
        <g
          id="undraw_Login_re_4vu2"
          transform="translate(28.274503, 51.574063)"
          fillRule="nonzero"
        >
          <path
            d="M241.417711,245.213873 C236.106602,240.726028 232.88055,233.636556 233.726583,226.734899 C234.572616,219.833242 239.812662,213.488514 246.60977,212.022905 C253.406877,210.557296 261.155116,214.614318 263.219621,221.254081 C264.355815,208.45288 265.665071,195.115325 272.471009,184.213908 C278.633616,174.342943 289.307365,167.279878 300.875302,166.016308 C312.443239,164.752737 324.622785,169.525117 331.837282,178.655548 C339.051779,187.785978 340.818221,201.059719 335.765374,211.542206 C332.043116,219.264286 325.185432,225.011157 318.000246,229.68653 C294.78239,244.667061 266.935599,250.759026 239.583478,246.841547 L241.417711,245.213873 Z"
            id="Path"
            fill="#FFF9EE"
          ></path>
          <path
            d="M320.397951,169.789663 C310.852974,177.564172 302.096462,186.259365 294.255071,195.749473 C281.983328,210.550602 272.052905,227.145369 264.809759,244.955645 C264.279522,246.254935 266.389369,246.820533 266.913684,245.535672 C278.764745,216.590257 297.683889,191.078072 321.940764,171.332471 C323.030024,170.445493 321.478237,168.909999 320.397951,169.789663 L320.397951,169.789663 Z"
            id="Path"
            fill="#DCD5C9"
          ></path>
          <path
            d="M221.585127,247.475657 L47.5415526,247.475657 L47.5415526,68.9021821 C47.5415526,30.9093138 78.4508664,0 116.44362,0 L152.683064,0 C190.675818,0 221.585127,30.9093138 221.585127,68.9021821 L221.585127,247.475657 Z"
            id="Path"
            fill="#F2F2F2"
          ></path>
          <path
            d="M185.34615,247.475657 L47.541319,247.475657 L47.541319,68.9021821 C47.5035138,54.3441948 52.1134143,40.1541285 60.7000622,28.3980272 C61.0165878,27.9665994 61.3323029,27.5407553 61.6579122,27.1195698 C65.8112087,21.6722711 70.7537711,16.8749232 76.3224661,12.8858667 C76.6370177,12.6569875 76.9528376,12.4320851 77.27334,12.2071731 C81.9330395,8.9825588 86.973591,6.34650084 92.2802639,4.35899999 C92.5953972,4.23955841 92.9162334,4.11988796 93.2370696,4.00510026 C98.0364622,2.29235662 103.011582,1.11913241 108.070541,0.507096218 C108.384163,0.464252889 108.706158,0.430726852 109.028162,0.397200814 C113.958137,-0.129922331 118.930262,-0.129922331 123.860237,0.397200814 C124.181307,0.430726852 124.503311,0.464252889 124.820652,0.507558745 C129.87831,1.11963504 134.852136,2.29270936 139.65028,4.00511934 C139.971236,4.11990703 140.292096,4.23957749 140.608135,4.35925271 C145.849341,6.32168218 150.830576,8.91840453 155.440677,12.0914699 C155.760931,12.3114943 156.081539,12.5364063 156.397368,12.7613135 C159.518763,14.9894271 162.450692,17.471677 165.163223,20.1827223 C167.338482,22.3572742 169.365489,24.6752308 171.230606,27.1209956 C171.555166,27.5407839 171.870881,27.966628 172.18683,28.3973596 C180.773804,40.153582 185.383914,54.3439048 185.34615,68.9021821 L185.34615,247.475657 Z"
            id="Path"
            fill="#BDC6D7"
          ></path>
          <circle
            id="Oval"
            fill="#919EB5"
            cx="174.140357"
            cy="159.977039"
            r="7.62930736"
          ></circle>
          <polygon
            id="Path"
            fill="#FFB8B8"
            points="207.327396 242.127036 201.481439 242.127036 198.70008 219.578618 207.32835 219.579095"
          ></polygon>
          <path
            d="M197.305657,240.456604 L208.57979,240.456604 L208.57979,247.55514 L190.207125,247.55514 L190.207125,247.55514 C190.207125,245.672492 190.955005,243.866951 192.286239,242.535717 C193.617472,241.204483 195.423013,240.456604 197.305662,240.456604 L197.305657,240.456604 Z"
            id="Path"
            fill="#394854"
          ></path>
          <polygon
            id="Path"
            fill="#FFB8B8"
            points="236.41413 242.127036 230.568174 242.127036 227.786814 219.578618 236.415084 219.579095"
          ></polygon>
          <path
            d="M226.392391,240.456604 L237.666524,240.456604 L237.666524,247.55514 L219.29386,247.55514 L219.29386,247.55514 C219.29386,245.672492 220.041739,243.866951 221.372973,242.535717 C222.704207,241.204483 224.509747,240.456604 226.392396,240.456604 L226.392391,240.456604 Z"
            id="Path"
            fill="#394854"
          ></path>
          <path
            d="M178.001554,161.488414 C177.150207,160.234083 176.898175,158.667084 177.313274,157.209061 C177.728373,155.751038 178.767963,154.551764 180.152313,153.933936 L200.024642,99.1618651 L209.803092,104.498275 L187.113169,156.84043 C188.016768,159.288599 186.946469,162.025694 184.621853,163.21152 C182.297236,164.397345 179.453078,163.657078 178.001554,161.488414 L178.001554,161.488414 Z"
            id="Path"
            fill="#FFB8B8"
          ></path>
          <path
            d="M233.427929,171.780326 C232.112787,171.026307 231.199177,169.72849 230.932932,168.236091 C230.666687,166.743692 231.075157,165.210015 232.048431,164.047741 L225.892171,106.108202 L237.020355,106.616609 L239.578877,163.607764 C241.464442,165.411829 241.702673,168.341068 240.133404,170.42611 C238.564134,172.511152 235.683385,173.092947 233.427929,171.780345 L233.427929,171.780326 Z"
            id="Path"
            fill="#FFB8B8"
          ></path>
          <circle
            id="Oval"
            fill="#FFB8B8"
            cx="215.675193"
            cy="77.3051679"
            r="11.7114779"
          ></circle>
          <path
            d="M233.353133,158.603115 L198.045237,158.603115 L198.087728,158.328379 C198.151175,157.917669 204.380371,117.111523 199.786093,103.761665 C198.831319,101.044711 200.079677,98.0451327 202.680142,96.8077899 L202.680261,96.8077899 C209.247058,93.7153694 221.852481,89.9074676 232.491779,99.1486426 C235.66598,101.951074 237.336819,106.083543 237.002355,110.304597 L233.353133,158.603115 Z"
            id="Path"
            fill="#005192"
          ></path>
          <path
            d="M204.826585,123.142013 L188.930604,119.917105 L196.381681,102.260132 C197.310104,98.6993437 200.945396,96.5617932 204.508415,97.4816145 C208.071435,98.4014357 210.217758,102.031555 209.306543,105.596785 L204.826585,123.142013 Z"
            id="Path"
            fill="#005192"
          ></path>
          <path
            d="M225.185411,129.129885 L224.229769,107.223563 C223.505094,103.105535 225.862479,99.2126525 229.487959,98.5763349 C233.114488,97.9423442 236.655569,100.798447 237.383505,104.94674 L240.975479,125.416047 L225.185411,129.129885 Z"
            id="Path"
            fill="#005192"
          ></path>
          <path
            d="M232.178448,155.980521 C237.859684,177.617576 238.479474,205.12728 236.946766,235.134585 L229.317458,234.180922 L215.489339,176.961117 L207.860031,235.134585 L199.277061,234.657753 C196.712927,203.172689 194.216312,176.143221 198.323397,158.36468 L232.178448,155.980521 Z"
            id="Path"
            fill="#394854"
          ></path>
          <path
            d="M223.39189,86.3561347 C221.207047,88.6834502 217.149538,87.4340939 216.86485,84.2546563 C216.842523,84.0078121 216.844142,83.7593862 216.869685,83.512854 C217.016674,82.1044982 217.830315,80.8258787 217.635415,79.3388599 C217.591301,78.9687672 217.453415,78.6160685 217.234824,78.3141867 C215.493797,75.9827992 211.406858,79.3569557 209.763729,77.2464223 C208.756184,75.952301 209.940539,73.9147371 209.16738,72.4683254 C208.146931,70.5592915 205.124414,71.5010245 203.228975,70.4555424 C201.120068,69.292321 201.246204,66.0566554 202.634433,64.0885564 C204.327433,61.6883811 207.295806,60.4076493 210.227196,60.2230677 C213.158585,60.0384861 216.069776,60.8309089 218.80649,61.8974384 C221.915952,63.1092299 224.999428,64.7839439 226.912949,67.5181065 C229.239987,70.8431351 229.463931,75.3133179 228.300133,79.2013131 C227.592181,81.5664222 225.176037,84.4556361 223.39189,86.3561347 Z"
            id="Path"
            fill="#394854"
          ></path>
          <path
            d="M324.378923,247.78247 L0.520791341,247.78247 C0.23292241,247.781563 0,247.527636 0,247.214714 C0,246.901791 0.23292241,246.647864 0.520791341,246.646957 L324.378923,246.646957 C324.666792,246.647864 324.899714,246.901791 324.899714,247.214714 C324.899714,247.527636 324.666792,247.781563 324.378923,247.78247 L324.378923,247.78247 Z"
            id="Path"
            fill="#394854"
          ></path>
          <path
            d="M147.437796,195.739462 L85.4496735,195.739462 C83.7498845,195.748567 82.3634093,194.380156 82.3502674,192.680394 L82.3502674,54.318413 C82.3634093,52.6186502 83.7498845,51.2502396 85.4496735,51.2593441 L147.437796,51.2593441 C149.137585,51.2502396 150.52406,52.6186502 150.537202,54.318413 L150.537202,192.680394 C150.52406,194.380156 149.137585,195.748567 147.437796,195.739462 Z"
            id="Path"
            fill="#FFFFFF"
          ></path>
          <rect
            id="Rectangle"
            fill="#CCCCCC"
            x="82.3502531"
            y="96.0289711"
            width="68.1869345"
            height="1"
          ></rect>
          <rect
            id="Rectangle"
            fill="#CCCCCC"
            x="82.5886689"
            y="150.440404"
            width="68.1869345"
            height="1"
          ></rect>
          <rect
            id="Rectangle"
            fill="#CCCCCC"
            x="103.80768"
            y="51.2594088"
            width="1"
            height="144.95684"
          ></rect>
          <rect
            id="Rectangle"
            fill="#CCCCCC"
            x="127.649266"
            y="51.2594088"
            width="1"
            height="144.95684"
          ></rect>
        </g>
      </g>
    </svg>
  );
}
