import SplashScreen from "../elements/splashScreen/splashScreen.render.jsx";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import RoundedIcon from "../elements/buttons/roundedIcon.component.jsx";
import EinrichtungLinkCard from "../elements/cards/einrichtung.card.jsx";
import { connect } from "react-redux";
import * as actionMain from "../../reduxStore/actions/main.js";
import * as actionAppointment from "../../reduxStore/actions/appointment.js";
import DateTimePicker from "../elements/forms/dateTimePicker.component.jsx";
import StandardTextInput from "../elements/forms/textInput.component.jsx";
import StandardButton from "../elements/buttons/standard.button.jsx";
import classes from "../mapUi/info/bottom/intersection.button.module.scss";
import {
  CalendarEvent,
  downloadCalendarEvent,
} from "../../functions/classes/CalendarEvent.class.js";
import SaveZoneWrapper from "../elements/wrapper/saveZone.wrapper.jsx";
import { nativeSaveToCalendar } from "../nativeAppInterface/NativeAppInterface.jsx";
import StandardHeadline from "../elements/text/standardHeadline.text.jsx";
import {
  AppOverlay,
  AppOverlaySearch,
} from "../../functions/classes/miscelleanous.classes.js";
import StandardText from "../elements/text/standardText.text.jsx";

function CreateAppointment({
  setCurrentFeature,
  setShowCreateAppointment,
  setCurrentAppOverlay,
  currentPOI,
}) {
  const intl = useIntl();
  const [appointmentValues, setAppointmentValues] = useState({
    title: currentPOI && currentPOI.name ? currentPOI.name : "",
    startDate: new Date(),
    endDate: (() => {
      let endDate = new Date();
      endDate.setHours(endDate.getHours() + 1);
      return endDate;
    })(),
    notes: "",
    calendarSelection: [],
    selectedCalendar: null,
  });
  const handleValueChange = (value, key) => {
    setAppointmentValues((prevState) => ({ ...prevState, [key]: value }));
  };
  const handleConfirm = () => {
    let notes =
      appointmentValues.notes +
      "\n\n" +
      intl.formatMessage({ id: "appointment.linkInstruction" });
    let calendarEvent = new CalendarEvent(
      appointmentValues.startDate,
      "local",
      appointmentValues.endDate,
      "local",
      undefined,
      unescape(encodeURIComponent(appointmentValues.title)),
      unescape(encodeURIComponent(notes)),
      currentPOI && currentPOI.address
        ? unescape(encodeURIComponent(currentPOI.address))
        : undefined,
      window.location.href,
      undefined,
      [],
      "CONFIRMED",
      "BUSY",
      {},
      []
    );

    let downloadName = `${intl.formatMessage({
      id: "iframe.appointment",
    })}.ics`;

    downloadCalendarEvent(calendarEvent, downloadName);

    // falls man in der app ist
    nativeSaveToCalendar(calendarEvent);
  };

  useCorrectTime(appointmentValues, setAppointmentValues);

  useEffect(() => {
    setAppointmentValues((prevState) => ({
      ...prevState,
      title: currentPOI && currentPOI.name ? currentPOI.name : "",
    }));
  }, [currentPOI]);

  return (
    <SplashScreen
      onClosingFinished={() => setShowCreateAppointment(false)}
      render={(handleClose) => (
        <SaveZoneWrapper>
          <div className="d-flex flex-column h-100 pl-3 pr-3 pt-3">
            <AppointmentHeader
              title={intl.formatMessage({
                id: "appointment.createAppointment",
              })}
              onClick={handleClose}
            />
            <div className="overflow-auto no-scrollbar">
              <AppointmentDescription
                description={intl.formatMessage({
                  id: "appointment.description",
                })}
              />
              <BasicContentBlock
                title={intl.formatMessage({ id: "appointment.title" })}
              >
                <StandardTextInput
                  onChange={(e) => handleValueChange(e, "title")}
                  value={appointmentValues.title}
                />
              </BasicContentBlock>
              <AppointmentLocation
                intl={intl}
                poi={currentPOI}
                setCurrentAppOverlay={setCurrentAppOverlay}
                setCurrentFeature={setCurrentFeature}
              />
              <BasicContentBlock
                bold
                title={intl.formatMessage({ id: "words.start" })}
              >
                <DateTimePicker
                  onChange={(e) => handleValueChange(e, "startDate")}
                  value={appointmentValues.startDate}
                />
              </BasicContentBlock>
              <BasicContentBlock
                bold
                title={intl.formatMessage({ id: "words.end" })}
              >
                <DateTimePicker
                  onChange={(e) => handleValueChange(e, "endDate")}
                  value={appointmentValues.endDate}
                />
              </BasicContentBlock>
              <BasicContentBlock
                title={intl.formatMessage({ id: "words.notes" })}
              >
                <StandardTextInput
                  onChange={(e) => handleValueChange(e, "notes")}
                  value={appointmentValues.notes}
                  height={7 + "rem"}
                  type={"textarea"}
                />
              </BasicContentBlock>
              <div className="savetext">
                <AppointmentDescription
                  description={intl.formatMessage({
                    id: "appointment.saveDescription",
                  })}
                />
              </div>
              <Confirmation
                handleClose={handleClose}
                handleConfirm={handleConfirm}
              />
            </div>
          </div>
        </SaveZoneWrapper>
      )}
    />
  );
}

function AppointmentHeader({ title, onClick }) {
  return (
    <div className={"d-flex align-items-center mb-3"}>
      <RoundedIcon
        icon={["fal", "long-arrow-left"]}
        onClick={onClick}
        divClass={"roundedIcon--back mr-3"}
      />
      <StandardHeadline text={title} type={"h3"} className={"m-0"} />
    </div>
  );
}

function AppointmentDescription({ description }) {
  return <StandardText text={description} className={"mb-3 appointmenttext"} />;
}

function AppointmentLocation({ poi, setCurrentAppOverlay, setCurrentFeature }) {
  const intl = useIntl();
  const handleClick = () => {
    setCurrentAppOverlay(
      new AppOverlaySearch(true, false, {
        mustHaveLocation: true,
        onClose: () => setCurrentAppOverlay(new AppOverlay()),
        onPOIClicked: (feature) => {
          setCurrentFeature(feature);
          setCurrentAppOverlay(new AppOverlay());
        },
        canSetCurrentPosition: false,
        canSetPositionOnMap: false,
      })
    );
  };

  return (
    <div className={"mb-3"}>
      <h4>{intl.formatMessage({ id: "pois.location" })}</h4>
      <StandardButton
        buttonClasses={`normalButton--itemSelection w-100 mb-3 ${
          poi
            ? "normalButton--itemSelection--selected"
            : "normalButton--itemSelection--unselected"
        }`}
        icon={["fal", "search"]}
        handleClick={handleClick}
      >
        {intl.formatMessage({ id: "appointment.searchLocation" })}
      </StandardButton>

      {poi ? (
        <EinrichtungLinkCard
          name={poi.name}
          hasLocation={poi.hasLocation()}
          address={poi.address}
          fullWidth={true}
          showArrow={false}
          customIcon={
            <RoundedIcon
              onClick={() => setCurrentFeature(null)}
              icon={["fal", "times"]}
              divClass={"roundedIcon--delete"}
              iconClass={"roundedIcon--delete__icon"}
            />
          }
        />
      ) : null}
    </div>
  );
}

function BasicContentBlock({ title, children }) {
  return (
    <div className="d-flex flex-column mb-3">
      <StandardHeadline text={title} type={"h4"} />
      {children}
    </div>
  );
}

function Confirmation({ handleClose, handleConfirm }) {
  return (
    <div className={"d-flex flex-column w-100 align-items-center"}>
      <StandardButton
        handleClick={() => {
          handleConfirm();
          handleClose();
        }}
        title={"Confirm"}
        buttonClasses={`${classes.button} normalButton--primary mb-3`}
      >
        <FormattedMessage id={"appointment.confirmForm"} />
      </StandardButton>
      <StandardButton
        handleClick={handleClose}
        title={"Cancel"}
        buttonClasses={`${classes.button} normalButton--cancel normalButton--cancel--naked mb-3`}
      >
        <FormattedMessage id={"appointment.deleteForm"} />
      </StandardButton>
    </div>
  );
}

/**
 * korrigiert EndTime, falls diese kleiner ist als die StartTime
 */
function useCorrectTime(appointmentValues, setAppointmentValues) {
  useEffect(() => {
    if (
      appointmentValues.startDate.getTime() >=
      appointmentValues.endDate.getTime()
    ) {
      setAppointmentValues((prevState) => ({
        ...prevState,
        endDate: (() => {
          let endDate = new Date();
          // eine stunde hinzurechnen (respektiert auch tageswechsel bei 0 uhr)
          endDate.setTime(
            appointmentValues.startDate.getTime() + 1000 * 60 * 60
          );
          return endDate;
        })(),
      }));
    }
  }, [appointmentValues]);
}
const mapStateToProps = (state) => {
  return {
    appointmentValues: state.appointment.appointmentValues,
    currentPOI: state.main.currentFeature,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentFeature: (poi) => dispatch(actionMain.setCurrentFeature(poi)),
    setShowCreateAppointment: (value) =>
      dispatch(actionAppointment.setShowCreateAppointment(value)),
    setCurrentAppOverlay: (appOverlay) =>
      dispatch(actionMain.setCurrentAppOverlay(appOverlay)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAppointment);
