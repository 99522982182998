export const INFO_CARD_SET_OPEN = "INFO_CARD_SET_OPEN";
export const INFO_CARD_SET_PRE_OPEN = "INFO_CARD_SET_PRE_OPEN";
export const INFO_CARD_SET_CLOSE = "INFO_CARD_SET_CLOSE";

export function setOpen() {
  return {
    type: INFO_CARD_SET_OPEN,
  };
}

export function setPreOpen() {
  return {
    type: INFO_CARD_SET_PRE_OPEN,
  };
}

export function setClose() {
  return {
    type: INFO_CARD_SET_CLOSE,
  };
}

export function setStatus(status) {
  switch (status) {
    case "SET_OPEN":
    case "OPEN_FINISH":
      return {
        type: INFO_CARD_SET_OPEN,
      };
    case "SET_PRE_OPEN":
    case "PRE_OPEN_FINISH":
      return {
        type: INFO_CARD_SET_PRE_OPEN,
      };
    case "SET_CLOSE":
    case "CLOSE_FINISH":
      return {
        type: INFO_CARD_SET_CLOSE,
      };
  }

  return {
    type: INFO_CARD_SET_CLOSE,
  };
}
