import styles from "./textInput.module.scss";

export default function StandardTextInput({
  onChange,
  className = "",
  value,
  type = "text",
  height = "auto",
}) {
  const handleChange = (e) => {
    let value = e.target.value;
    onChange(value);
  };

  return type === "text" ? (
    <input
      value={value}
      onChange={handleChange}
      style={{ height: height }}
      className={`unset-safari ${styles.input} p-2 pl-3 pr-3 col-md-6 col-12 standardTextInput ${className}`}
    />
  ) : (
    <textarea
      value={value}
      onChange={handleChange}
      style={{ height: height }}
      className={`unset-safari p-2 pl-3 pr-3 col-md-6 col-12 ${styles.area} standardTextInput ${className}`}
    />
  );
}
