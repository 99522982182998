import { isTablet } from "react-device-detect";
import {
  mapIcons,
  defaultSettings,
} from "../../../../assets/images/map/icons.images.js";
import {
  arc,
  pulsingDot,
} from "../../../../assets/images/map/staticIcons/pulsingDot.js";

import {
  getDeepestObjectValues,
  getFeatureCollection,
} from "../../../../functions/helper/helpers.functions.js";
import { map } from "../map.sideEffects/useMap.sideEffects.js";
import { piKey } from "../../../../app/app.sideEffects/useURLParams.sideEffects.js";
import { createRoomPolygons } from "./layerDeclarations/createRoomPolygons.js";
import { sourceIds } from "../variables/sourceIds.js";
import { layerIds } from "../variables/layerIds.js";
import { createAddressSymbols } from "./layerDeclarations/createAddressSymbols.js";
import { createBuildingPolygons } from "./layerDeclarations/createBuildingPolygons.js";
import { createRouteLines } from "./layerDeclarations/createNavigationLines.js";
import { createDebuggedUserPosition } from "./layerDeclarations/createDebuggedUserPosition.js";
import { createPinSymbols } from "./layerDeclarations/createPinSymbols.js";
import { createUserPosition } from "./layerDeclarations/createUserPosition.js";
import { createPOISymbols } from "./layerDeclarations/createPOISymbols.js";
import { createChildrenSymbols } from "./layerDeclarations/createChildrenSymbols.js";
import { createCampusSymbols } from "./layerDeclarations/createCampusSymbols.js";
import StartDestination from "../../../../functions/classes/startDestination.class.js";
import { createRouteLegStartCircle } from "./layerDeclarations/createRouteLegStartCircle.js";
import createDebugBeaconSymbols from "./layerDeclarations/createDebugBeaconSymbols";
import { createTerminalPinSymbol } from "./layerDeclarations/createTerminalPinSymbol.js";

const customIconPath = (iconName) =>
  `${process.env.REACT_APP_GET_CUSTOM_STYLE_PATH}${piKey}/design/mapIcons/${iconName}.svg`;

// Zoom Abstufungen mit ihren jeweiligen Multiplikationsfaktoren zur Bestimmung
// der Sizes der Icons, Lines und Points
export const zoomWide = 3;
export const zoomMid = 19;
export const zoomMidFactor = isTablet ? 3 : 2;
export const zoomMacro = 21;
export const zoomMacroFactor = isTablet ? 11 : 3;
export const iconSize = 0.18;
export const iconSize_noCircle = 0.2;

/**
 * Diese Funktion bindet Sources und Layers zur Initialisiierung an!
 * Hier werden noch keine Features mitgegeben.
 * In mapFilter.functions.js werden die Features (nachdem Sie von map.component.jsx mit setFeatures gesetzt werden)
 * hinterher gefilert nach Zoom, iconname etc.
 */
export function addSourcesAndLayers({ isTerminal, defaultStartNode }) {
  // Hooken aller Sources ( ohne Features )
  let allSources = getDeepestObjectValues(sourceIds);
  allSources.forEach((sourceId) => addSource(sourceId));

  // Hinzufügen und stylen der Layers
  // reihenfolge ist crucial zur richtigen darstellung
  // createRoomPolygons();
  createRouteLines();
  // Buildings werden aus dem MapSDK übernommen
  // createBuildingPolygons();
  // Addresses werdem aus dem MapSDK übernommen
  //hier
  // createAddressSymbols();
  createRouteLegStartCircle();
  createPOISymbols();
  createTerminalPinSymbol(isTerminal, defaultStartNode);
  createUserPosition();
  createDebuggedUserPosition();
  createChildrenSymbols();
  createPinSymbols(
    [
      layerIds.nodes.route.coloredBg,
      layerIds.nodes.route.whiteBg,
      layerIds.nodes.route.symbol,
    ],
    sourceIds.routeSymbole,
    // start Punkt darf hier nicht rein!
    [
      "all",
      ["!=", ["get", "type"], StartDestination.types.start],
      ["!=", ["get", "type"], StartDestination.types.legChange],
    ]
  );
  createCampusSymbols();
  createDebugBeaconSymbols();
}

/**
 * Diese Funktion bringt die Features an die jeweiligen Sources
 * und triggert damit ein reRendering dieser auf der Map
 * @param {mapboxgl}map
 * @param {String}sourceId
 * @param {[], FeatureCollection}features - Entweder Features Array oder eine FeatureCollection
 */
export function setFeatures({ sourceId, features }) {
  features = features == null ? [] : features;
  if (!map) return;
  let source = map.getSource(sourceId);
  if (source) {
    if (features && features.type === "FeatureCollection") {
      source.setData(features);
    } else {
      source.setData(getFeatureCollection(features));
    }
  }
}

/**
 * Bei der Initialisierung werden hier die lediglich die Sources deklariert.
 * @param {mapboxgl} map
 * @param {String}sourceId - Die SourceId aus mapdata.globaldata.js
 * @param {[Node, Kante]} features - Features im Array vom Typ Node oder Kante
 */
export function addSource(sourceId, features = []) {
  try {
    if (!map.getSource(sourceId)) {
      map.addSource(sourceId, {
        type: "geojson",
        tolerance: 1,
        data: {
          type: "FeatureCollection",
          features: features,
        },
      });
    }
  } catch (err) {
    console.log("addSource Error: ", err);
  }
}

/**
 * Diese Funktion hilft dabei SVGs in das map Objekt einzutragen, die dann
 * im Layertype "symbol" verwendet werden können mit den im nameArray übergebenen
 * Namen
 * @param images
 * @param map
 */
export function addSVGsToMap() {
  mapIcons.pulsingDot = {
    icon: pulsingDot,
    name: "pulsing-dot",
    dbName: "pulsing-dot",
    settings: { pixelRatio: 2, sdf: false },
    convertToImage: false,
  };
  mapIcons.arc = {
    icon: arc,
    name: "arc",
    dbName: "arc",
    settings: { pixelRatio: 2, sdf: false },
    convertToImage: false,
  };

  for (let key in mapIcons) {
    const image = mapIcons[key];
    if (image.convertToImage === false) {
      if (!map.hasImage(image.dbName))
        map.addImage(image.dbName, image.icon, image.settings);
    } else {
      let svgImage = new Image(image.settings.width, image.settings.height);
      svgImage.onload = () => {
        if (map.hasImage(image.dbName)) {
          map.removeImage(image.dbName);
          map.addImage(image.dbName, svgImage, image.settings);
        } else {
          map.addImage(image.dbName, svgImage, image.settings);
        }
      };
      svgImage.onerror = (e) => {
        console.error("Fehler bei bild: ", image.dbName, e);
      };
      svgImage.src = image.icon;
    }
  }
  setTimeout(() => {
    for (let key in mapIcons) {
      const image = mapIcons[key];

      if (image.convertToImage === false) {
        if (!map.hasImage(image.dbName))
          map.addImage(image.dbName, image.icon, image.settings);
      } else {
        let svgImage = new Image(image.settings.width, image.settings.height);

        svgImage.onload = () => {
          if (map.hasImage(image.dbName)) {
            map.removeImage(image.dbName);
          }
          map.addImage(image.dbName, svgImage, image.settings);
        };
        svgImage.src = customIconPath(image.name);
      }
    }
  }, 1000);

  const startIconURL = map.mapStyleJSON?.routes?.startIcon?.url;
  const destinationIconURL = map.mapStyleJSON?.routes?.destinationIcon?.url;

  if (destinationIconURL !== undefined) {
    map.loadAndAddImage(destinationIconURL, "target", defaultSettings);
  }
  if (startIconURL !== undefined) {
    map.loadAndAddImage(startIconURL, "start", defaultSettings);
  }
}
