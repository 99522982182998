import { Route, useHistory } from "react-router-dom";

const RouteWithSubRoutes = (route) => {
  const history = useHistory();

  const globalParams = new URLSearchParams(window.location.search);
  // todo: glaube das kann in einen Effekt und muss nicht jedes mal neu iteriert werden
  if (!compareParams(globalParams.toString(), history.location.search)) {
    const params = new URLSearchParams();

    globalParams.forEach((value, key) => {
      params.set(key, value);
    });
    history.push({ search: params.toString() });
  }
  return (
    <Route
      path={route.path}
      render={(routeProps) => {
        const props = {
          ...route.props,
          ...routeProps,
        };

        return <route.component {...props} routes={route.routes} />;
      }}
    />
  );
};

function compareParams(param1, param2) {
  param1 = param1.replace("?", "").replace("/", "").replace("%2F", "");
  param2 = param2.replace("?", "").replace("/", "").replace("%2F", "");

  return param1 === param2;
}

export function getQueryParams() {
  const globalParams = new URL(window.location);
  return globalParams.search.toString();
}
export function setQueryParams(key, value) {
  const url = new URL(window.location);
  url.searchParams.set(key, value);

  return url;
}

export default RouteWithSubRoutes;
