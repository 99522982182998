import RouteItem from "./RouteItem.class";
import Leg from "./Leg.class";
import { along, bbox, length, lineString } from "@turf/turf";
import StartDestination from "./startDestination.class.js";
import Coordinate from "@catchupapplications/indoor-navigation/dist/models/Coordinate";

export default class Route extends RouteItem {
  constructor(props) {
    super(props.distance, props.duration);
    this.startTime = new Date();
    this.geometry = props.geometry;
    this.endTime = new Date(
      this.startTime.getTime() + super.getDurationInMinutes() * 60000
    );
    this.legs = props.legs.map((item, index) => {
      const waypoints = [];
      if (props.legs.length === props.waypoints.length) {
        waypoints.push({
          ...props.waypoints[index],
          name: replaceName(props.waypoints[index].name),
        });
      } else {
        if (0 === index) {
          waypoints.push({
            ...props.waypoints[0],
            name: replaceName(props.waypoints[0].name),
          });
          waypoints.push({
            ...props.waypoints[1],
            name: replaceName(props.waypoints[1].name),
          });
        } else {
          if (props.waypoints[index + 1]) {
            waypoints.push({
              ...props.waypoints[index + 1],
              name: replaceName(props.waypoints[index + 1].name),
            });
          }
        }
      }

      return new Leg({
        ...item,
        ...{ waypoints: waypoints },
        ...{ pos: index },
      });
    });

    this.legPos = 0;
    this.locations = this._getLocations();
  }

  /**
   *
   * @return {Coordinate[]}
   */
  _getLocations() {
    let coordinates = [];
    this.legs.forEach((leg) => {
      if (Array.isArray(leg.steps)) {
        leg.steps.forEach((step) => {
          if (Array.isArray(step.stepLine)) {
            step.stepLine.forEach((coord) => {
              if (Array.isArray(coord)) {
                coordinates.push(new Coordinate(coord[1], coord[0]));
              } else console.warn(coord, "ist kein Koordinaten Array");
            });
          } else console.warn(step.stepLine, "ist kein StepLine Array");
        });
      } else console.warn(leg.steps, "ist kein Array an Steps des Legs");
    });
    return coordinates;
  }

  resetPosition() {
    this.legPos = 0;
    this.legs[0].stepPos = 0;
  }
  getLineString(fromLegIndex = 0) {
    let coordinates = [];
    this.legs.forEach((leg, index) => {
      if (index >= fromLegIndex) {
        coordinates = coordinates.concat(
          leg.steps.map((s) => s.stepLine).flat(1)
        );
      }
    });
    return lineString(coordinates);
  }

  hasPrevStep() {
    if (0 !== this.legPos) return true;
    const leg = this.legs[this.legPos];
    return leg.hasPrevStep();
  }

  setPrevStep() {
    const leg = this.legs[this.legPos];
    if (leg.hasPrevStep()) {
      leg.prevStep();
    } else {
      this.legPos = this.legPos - 1;
    }
    return this;
  }

  getPrevStep() {
    const leg = this.legs[this.legPos];
    if (leg.hasPrevStep()) {
      return leg.steps[leg.stepPos - 1];
    } else {
      if (this.legs[this.legPos - 1]) {
        const leg = this.legs[this.legPos - 1];
        return leg.steps[leg.steps.length - 1];
      }
      return false;
    }
  }
  isOnLastLeg() {
    return this.legPos === this.legs.length - 1;
  }

  /**
   * Letztes Step vom currentLeg
   */
  isOnLastStep() {
    const currentLeg = this.getCurrentLeg();
    return currentLeg.stepPos === currentLeg.steps.length - 1;
  }

  /**
   *
   * @return {Step}
   */
  getCurrentStep() {
    const leg = this.legs[this.legPos];
    return leg.steps[leg.stepPos];
  }

  /**
   * @return {Leg}
   */
  getCurrentLeg() {
    return this.legs[this.legPos];
  }
  /** @return {Leg} */
  getNextLeg() {
    return this.legs[this.legPos + 1];
  }
  setPrevLeg() {
    // nur ändern, wenn die vorherige Position wirklich verfügbar ist
    if (this.legPos > 0) {
      this.legPos = this.legPos - 1;
    }
  }
  /**
   * Unterteilt die Distance der Route in alle Legs und gibt den noch voranliegenden Bruchteil der
   * Distance der Route wieder
   * @return {number} Die noch voranliegende Distanz in Metern
   */
  getRemainingDistance() {
    let dividedDistance = super.getDistanceInMeters() / this.legs.length;
    let remainingDistance =
      dividedDistance * (this.legs.length - this.legPos - 1) +
      this.legs[this.legPos].getRemainingDistance();
    return remainingDistance;
  }
  /**
   * Unterteilt die Duration des Legs in alle Steps und gibt den noch voranliegenden Bruchteil der
   * Minuten des Legs wieder
   * @return {number|object} Voranliegende Duration des Legs in Minuten
   */
  getRemainingDuration(inMinutes = true) {
    let remainingDuration = 0;
    if (this.legPos < this.legs.length - 1) {
      for (let i = this.legPos + 1; i < this.legs.length; i++) {
        remainingDuration += this.legs[i].getDurationInMinutes();
      }
      remainingDuration += this.legs[this.legPos].getRemainingDuration();
    } else {
      remainingDuration = this.legs[this.legPos].getRemainingDuration();
    }

    if (inMinutes) {
      return remainingDuration;
    } else
      return {
        days: Math.floor(remainingDuration / 1440),
        hours: Math.floor((remainingDuration % 1440) / 60),
        minutes: Math.floor((remainingDuration % 1440) % 60),
      };
  }
  hasLeg(legIndex) {
    return this.legs.length > legIndex;
  }
  hasNextLeg() {
    return this.legs.length > this.legPos + 1;
  }

  hasNextStep() {
    if (this.legs.length > this.legPos + 1) {
      return true;
    }
    const leg = this.legs[this.legPos];
    return leg.hasNextStep();
  }

  isNextStepOtherLeg() {
    const leg = this.legs[this.legPos];
    return !leg.hasNextStep();
  }

  isNextStepOtherFloor() {
    const leg = this.legs[this.legPos];
    if (leg.hasNextStep()) return false;

    if (this.legs[this.legPos + 1]) {
      return leg.level !== this.legs[this.legPos + 1].level;
    }

    throw Error("there is no next floor. Something went wrong");
  }
  setLeg(legIndex) {
    const leg = this.legs[legIndex];
    if (!leg || legIndex == undefined) {
      console.warn("Es gibt kein Leg mit index: " + legIndex, this.legs);
    } else {
      this.legPos = legIndex;
    }
  }
  setStep(stepIndex) {
    const currentLeg = this.legs[this.legPos];

    currentLeg.setStep(stepIndex);
  }
  nextStep() {
    const leg = this.legs[this.legPos];
    if (leg.hasNextStep()) {
      leg.nextStep();
    } else {
      if (this.hasNextLeg()) {
        this.legPos = this.legPos + 1;
      }
    }
    return this;
  }
  setNextLeg() {
    if (this.hasNextLeg()) {
      this.legPos = this.legPos + 1;
    }
  }

  getNextStep(sameLeg = false) {
    const leg = this.legs[this.legPos];
    if (leg.hasNextStep()) {
      return leg.steps[leg.stepPos + 1];
    } else if (!sameLeg) {
      if (this.legs[this.legPos + 1]) {
        const leg = this.legs[this.legPos + 1];
        return leg.steps[0];
      }
      return false;
    } else {
      return null;
    }
  }

  /**
   * Wird eigentlich nur für Terminal gebraucht, wo der komplette Leg, statt nur dem Step
   * angezeigt werden soll.
   * @return [[lng, lat]] Hälfte der Stepnodes des Legs
   */
  getCurrentLegArrowNodes() {
    let currentLegLine = this.getCurrentLeg().polylines;
    let nextLegLine = [];

    if (currentLegLine.length < 2 && this.getNextLeg()) {
      nextLegLine = this.getNextLeg().polylines[0];
    }

    let returnArray = currentLegLine.concat(nextLegLine);
    let shortenFactor = 0.6;
    returnArray = returnArray.slice(returnArray.length * shortenFactor);

    return returnArray;
  }

  /**
   * Gibt die Nodes um den aktuellen Step zurück,
   * die für die Mapbox camera gebraucht werdne um sich ausrichten zu können.
   * @return {{ prevNodes, currentNode, nextNodes, allArrowNodes }}
   */
  getCurrentStepArrowNodes() {
    const pushPrevNodes = () => {
      // Prevnodes müssen rückwärts iteriert werden
      let accumulatedDistance = 0;
      for (let i = currentStepLine.length - 2; i >= 0; i--) {
        let prevLoopNode = currentStepLine[i + 1];
        let currentLoopNode = currentStepLine[i];

        let nodeToNodeLength = length(
          lineString([currentLoopNode, prevLoopNode], { units: "kilometers" })
        );
        // nodeToNode ist kleiner als erlaubte übrige länge -> nodes übernehmen wie sie sind
        if (nodeToNodeLength <= distanceEachSide - accumulatedDistance) {
          accumulatedDistance = accumulatedDistance + nodeToNodeLength;
          prevNodes.unshift(currentLoopNode);
        } else if (distanceEachSide - accumulatedDistance > 0) {
          // nodeToNode ist größer als erlaubte übrige länge -> kürzen auf restlänge
          let finalNode = along(
            lineString([prevLoopNode, currentLoopNode]),
            distanceEachSide - accumulatedDistance,
            { units: "kilometers" }
          );
          prevNodes.unshift(finalNode.geometry.coordinates);
          break;
        }
      }
    };
    const pushCurrentNode = () => {
      // --- currentNode
      currentNode = currentStepLine[currentStepLine.length - 1]
        ? currentStepLine[currentStepLine.length - 1]
        : null;
    };
    const pushNextNodes = () => {
      // wenn keine nächste stepline, dann endet hier das Leg
      if (nextStepLine != null) {
        let accumulatedDistance = 0;
        // nextNodes vorwärts
        for (let i = 0; i < nextStepLine.length - 1; i++) {
          let currentLoopNode = nextStepLine[i];
          let nextLoopNode = nextStepLine[i + 1];
          let nodeToNodeLength = length(
            lineString([currentLoopNode, nextLoopNode], { units: "kilometers" })
          );

          // nodeToNode ist kleiner als erlaubte übrige länge -> nodes übernehmen wie sie sind
          // durch 2, weil der vordere pfeil nicht zu lang sein darf
          if (nodeToNodeLength <= distanceEachSide - accumulatedDistance) {
            accumulatedDistance += nodeToNodeLength;
            nextNodes.push(nextLoopNode);
          } else if (distanceEachSide - accumulatedDistance > 0) {
            // nodeToNode ist größer als erlaubte übrige länge -> kürzen auf restlänge
            let finalNode = along(
              lineString([currentLoopNode, nextLoopNode]),
              distanceEachSide - accumulatedDistance,
              { units: "kilometers" }
            );
            nextNodes.push(finalNode.geometry.coordinates);
            break;
          }
        }
      } else if (
        !this.legs[this.legPos].isIndoor &&
        this.legs[this.legPos + 1]?.isIndoor &&
        this.getNextStep()
      ) {
        nextNodes.push(this.getNextStep().stepLine[1]);
      }
    };

    // Momentane und nächste Stepline aus entweder einem Leg oder falls
    // der currentLeg zu ende ist -> aus dem nextLeg die erste stepLine holen
    let currentStepLine = this.getCurrentStep().stepLine;
    let nextStepLine = null;
    if (this.getNextStep(true)) {
      nextStepLine = this.getNextStep(true).stepLine;
    }

    // --- NODES --- aus den StepLines : [lng, lat]
    let currentNode = null,
      prevNodes = [],
      nextNodes = [];
    // Länge der Arrows abhängig vom Zoomlevel (Zoom der größer -> Pfeil länger)
    const bounds = bbox(
      lineString([
        currentStepLine[0],
        currentStepLine[currentStepLine.length - 1],
      ])
    );

    // Länge der Arrows vom currentNode aus berechnen
    // /1000 um auf kilometer maß zu kommen
    const meterPerSide = 400;
    const kilometerFactor = 1000;
    // anpassen auf bounds
    const boundLength = length(
      lineString([
        [bounds[0], bounds[1]],
        [bounds[2], bounds[3]],
      ]),
      { units: "kilometers" }
    );

    let distanceEachSide = (boundLength * meterPerSide) / kilometerFactor;

    pushPrevNodes();
    pushCurrentNode();
    pushNextNodes();

    // flaches Node Array zur convenience
    let allArrowNodes;
    if (nextNodes.length) {
      allArrowNodes = prevNodes.concat([currentNode], nextNodes);
    } else {
      allArrowNodes = prevNodes.concat([currentNode]);
    }
    // sicherheitsmaßnahme, damit IMMER MIN. zwei nodes verfügbar sind
    if (allArrowNodes.length < 2) {
      if (nextStepLine && nextStepLine.length) {
        nextNodes.push(nextStepLine[0]);
        allArrowNodes.push(nextStepLine[0]);
      } else if (currentStepLine[currentStepLine.length - 2]) {
        prevNodes.push(currentStepLine[currentStepLine.length - 2]);
      }
    }
    return { prevNodes, currentNode, nextNodes, allArrowNodes };
  }

  /**
   * Gibt route, die nur aus den relevantesten daten besteht
   */
  getStatisticRoute() {
    const statisticRoute = [];
    try {
      this.legs.forEach((l) => {
        l.steps.forEach((s) => {
          if (s.isOnCampus) {
            s.stepLine.forEach((stepLine) => {
              statisticRoute.push({
                level: l.level,
                isIndoor: l.isIndoor,
                isLive: l.isLive,
                latitude: stepLine[1],
                longitude: stepLine[0],
              });
            });
          }
        });
      });
    } catch (e) {
      console.warn("statisticroute konnte nicht erstellt werden", e);
    }

    return statisticRoute;
  }

  getFirstStep() {
    const leg = this.legs[0];
    return leg.steps[0];
  }

  getLastStep() {
    const leg = this.legs[this.legs.length - 1];
    return leg.steps[leg.steps.length - 1];
  }
}

function replaceName(name) {
  name = name.replace(/\s/g, "");

  switch (name) {
    case "Start": {
      return StartDestination.types.start;
    }
    case "Campus": {
      return "campusEntry";
    }
    case "Gebäude": {
      return "buildingEntry";
    }
    case "Ziel": {
      return StartDestination.types.destination;
    }
    case "Parkplatz": {
      return "parkingSpot";
    }
    default: {
      if (
        !isNaN(name) ||
        name.toLowerCase().includes("ebene") ||
        name.toLowerCase().includes("stockwerk")
      )
        return "switchFloor";

      // throw Error(`"Unknown name: "${name}"!`);
    }
  }
}
