import { connect } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import FullPage from "../elements/fullPage/fullPage.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  EVENTS,
  setCurrentAppOverlay,
  setCurrentFeature,
  setPage,
} from "../../reduxStore/actions/main";
import React, { useState, useEffect } from "react";
import { CampusSelection } from "../elements/campusSelection/campusSelection.component";
import Highlight from "../elements/text/highlight.text.jsx";
import { includeText } from "../search/search.jsx";
import {
  CalendarEvent,
  generateCalendarEventB64,
} from "../../functions/classes/CalendarEvent.class.js";
import * as settingsAction from "../../reduxStore/actions/settings";
import StandardList from "../elements/wrapper/standardList.wrapper.jsx";
import StandardHeadline from "../elements/text/standardHeadline.text.jsx";
import NewsEventCard, {
  getInfoPills,
} from "../elements/cards/newsEventCard.jsx";
import { AppOverlayIFrame } from "../../functions/classes/miscelleanous.classes.js";
import { SkeletonPost } from "../elements/loading/NewsSkeleton.jsx";
import { statisticLogger } from "../statisticLogger/StatisticLogger.container.js";
import { pageNames } from "../../reduxStore/reducers/main.js";
import { POIS } from "../../reduxStore/actions/main.js";
import { downloadCalendarEvent } from "../../functions/classes/CalendarEvent.class.js";

let isFuzzy = false;

function EventList(props) {
  const [filterCampusIds, setFilterCampusIds] = useState(
    !props.campusId ? [] : [props.campusId]
  );
  const [searchText, setSearchText] = useState("");
  const [relevantEvents, setRelevantEvents] = useState([]);
  const intl = useIntl();

  const handleReadMore = (item) => {
    if (item) {
      statisticLogger.addLog({
        action: {
          id: "clickOnEventListItem",
          group: "EventList",
          name: "Click on item from the eventlist page",
          movement: "stay",
          type: "click",
          interaction: "select",
          content: {
            globalPoiIds: [item.globalPoiId],
            globalPoiId: item.globalPoiId,
            campusId: item.campusId,
            category: item.category,
            globalName: item.name,
          },
        },
      });
    }
    props.setCurrentAppOverlay(
      new AppOverlayIFrame("Event", item.website, {
        event: getEvent(item),
        contentType: "event",
      })
    );
  };

  const callbackDownloadCalendar = (event, downloadString) => {
    let b64coded = btoa(downloadString);
    props.setCurrentAppOverlay(
      new AppOverlayIFrame(
        "Event",
        process.env.REACT_APP_CALENDAR_DOWNLOAD + b64coded,
        {
          event: getEvent(event),
          contentType: "event",
        }
      )
    );
  };

  const handleCalendarSaveToClick = (item) => {
    if (props.isApp) {
      let calendarEvent = getEvent(item);
      generateCalendarEventB64(calendarEvent, callbackDownloadCalendar);
    } else {
      if (item === undefined) return;

      let calendarEvent = getEvent(item);
      downloadCalendarEvent(
        calendarEvent,
        `${intl.formatMessage({
          id: "iframe.eventName",
        })}.ics`
      );
    }
  };

  const resetFilterCampus = () => {
    setFilterCampusIds([]);
  };

  const toggleFilterCampus = (id) => {
    setFilterCampusIds(
      filterCampusIds.includes(id)
        ? filterCampusIds.filter((i) => i !== id)
        : [id]
    );
  };

  const isCorrectAudience = (event) => {
    if (!event.additionalData.audience) return true;

    if (event.additionalData.audience === "Fachpublikum") {
      return !!props.audience.professionalAudience;
    }

    if (event.additionalData.audience === "Laien") {
      return !!props.audience.patientsInterested;
    }

    return false;
  };

  const getRelevantEvents = (fuzzy) => {
    isFuzzy = fuzzy;
    if (searchText === "") return EVENTS;
    return EVENTS.filter((month) => {
      return month.items.filter(
        (event) =>
          isCorrectAudience(event) &&
          (!filterCampusIds.length ||
            filterCampusIds.includes(event.campusId)) &&
          !("" !== searchText && !includeText(event, fuzzy, searchText))
      ).length;
    }).map((month) => ({
      ...month,
      items: month.items
        .filter(
          (event) =>
            isCorrectAudience(event) &&
            (!filterCampusIds.length ||
              filterCampusIds.includes(event.campusId)) &&
            !("" !== searchText && !includeText(event, fuzzy, searchText))
        )
        .map((item) => ({
          ...item,
          name: item.name.toLowerCase().includes(searchText.toLowerCase())
            ? item.name
            : `${item.name} (${item.includesSearchString(searchText)})`.replace(
                "(undefined)",
                ""
              ), //wenn die fuzzysearch anschlägt. anonsten hätte hier noch eine prüfung hingemusst und das wollte ich dem code nicht antun.
        })),
    }));
  };

  useEffect(() => {
    // filter cats & searchText
    let relevantEvents = getRelevantEvents(false);
    if (!relevantEvents.length) {
      relevantEvents = getRelevantEvents(true);
    }
    setRelevantEvents(relevantEvents);
    statisticLogger.currentPage = pageNames.events;
    statisticLogger.addLog({
      action: {
        id: "enterEventPage",
        name: "Enter Event Page",
        group: "Page",
        movement: "enter",
        type: "click",
      },
    });
    props.setPage(pageNames.events);
  }, [searchText, filterCampusIds, props.serverDataLoaded]);

  useEffect(() => {
    const setting = {
      filter: {
        ...props.settings,
        event: {
          ...props.settings.event,
          campusId: !filterCampusIds.length ? 0 : filterCampusIds[0],
        },
      },
    };

    // todo: könnte das nicht auch in die Settings direkt?
    props.setProperties(setting);
  }, [filterCampusIds]);

  return (
    <FullPage
      title={intl.formatMessage({ id: "event.title" })}
      searchText={searchText}
      setSearchText={setSearchText}
      headlineClassName={"standardHeadline__events"}
      searchCategory="event"
      outerClassName={"fullPage--events "}
      headerClassName={"fullPage__header--events"}
      innerContainerClassName={"fullPage__content--events"}
    >
      {!props.serverDataLoaded ? (
        <SkeletonPost linesLength={3} length={3} outerClassName={"w-100"} />
      ) : (
        <EventsRender
          props={props}
          relevantEvents={relevantEvents}
          searchText={searchText}
          notificationPostId={props.notificationPostId}
          handleReadMore={handleReadMore}
          filterCampusIds={filterCampusIds}
          isCorrectAudience={isCorrectAudience}
          resetFilterCampus={resetFilterCampus}
          toggleFilterCampus={toggleFilterCampus}
          handleCalendarSaveToClick={handleCalendarSaveToClick}
        />
      )}
    </FullPage>
  );
}

function itemJSX(
  items,
  props,
  notificationPostId,
  handleReadMore,
  searchText,
  handleCalendarSaveToClick
) {
  return items.map((item, index) => {
    const infoPills = getInfoPills(
      item,
      props.setCurrentFeature,
      props.history
    );

    return (
      <NewsEventCard
        scrollTo={notificationPostId === item.id}
        key={index + "card"}
        onClick={item.website ? () => handleReadMore(item) : null}
        datetime={item.datetime}
        textClass={"standardCard__events__text"}
        imageClass={"standardCard__events__text"}
        dateClass={"standardCard__events__time"}
        backgroundUrl={item.titleImageUrl}
        standardCardClassName={
          "mr-3 ml-3 mb-3 col-sm-12 col-md-4 standardCard__events"
        }
        infoPills={infoPills}
        headline={
          <>
            {item.additionalData.cancelled ? (
              <>
                <span className={`standardCard__events__text--cancelled`}>
                  <FormattedMessage id="event.cancelled" />
                </span>
              </>
            ) : null}
            <Highlight search={searchText}>{item.name}</Highlight>
          </>
        }
        itemElement={item}
        handleCalendarSaveToClick={handleCalendarSaveToClick}
      />
    );
  });
}

function EventsRender({
  toggleFilterCampus,
  resetFilterCampus,
  filterCampusIds,
  relevantEvents,
  searchText,
  isCorrectAudience,
  props,
  handleReadMore,
  notificationPostId,
  handleCalendarSaveToClick,
}) {
  return !EVENTS.length ? (
    <div className={"d-flex flex-column align-items-center"}>
      <StandardHeadline
        type={"h1"}
        className={"text-center m-5 mb-4"}
        text={<FormattedMessage id="event.empty" />}
      />
      <FontAwesomeIcon
        style={{ fontSize: "4rem" }}
        icon={["fal", "calendar"]}
      />
    </div>
  ) : (
    <div style={{ paddingBottom: window.innerHeight / 3 }}>
      <CampusSelection
        toggle={toggleFilterCampus}
        reset={resetFilterCampus}
        selectedCampuses={filterCampusIds}
      />
      <StandardHeadline
        type={"h3"}
        text={
          !relevantEvents.length ? (
            <FormattedMessage
              id="search.fuzzySearchCategories"
              values={{ searchText }}
            />
          ) : isFuzzy ? (
            <FormattedMessage
              id="search.onlyFuzzySearch"
              values={{ searchText }}
            />
          ) : null
        }
      />
      {relevantEvents.map((month, index) => {
        const catDate = new Date(month.monthDate);
        if (!month.items?.length) return null;

        const items = filterCampusIds.length
          ? month.items.filter(
              (item) =>
                isCorrectAudience(item) &&
                filterCampusIds.includes(item.campusId)
            )
          : month.items;

        const itemsResult = itemJSX(
          items,
          props,
          notificationPostId,
          handleReadMore,
          searchText,
          handleCalendarSaveToClick
        );
        if (itemsResult.length === 0) return null;

        return (
          <StandardList
            key={index + "list"}
            className={"standardList__events mt-3"}
            headingClassName={"ml-5"}
            heading={
              "later" === month.monthDate ? (
                <FormattedMessage id="event.later" />
              ) : (
                catDate.toLocaleString(props.language.replace("_", "-"), {
                  month: "long",
                  year: "numeric",
                })
              )
            }
          >
            <div
              className={"d-flex flex-wrap mt-3 w-100 justify-content-center"}
            >
              {itemsResult}
            </div>
          </StandardList>
        );
      })}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    notificationPostId: state.main.notificationPostId,
    isApp: state.main.isApp,
    isTerminal: state.main.isTerminal,
    language: state.main.language,
    campusId: state.settings.filter.event.campusId,
    audience: state.settings.event,
    settings: state.settings.filter,
    serverDataLoaded: state.main.serverDataLoaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentFeature: (feature) => dispatch(setCurrentFeature(feature)),
    setPage: (page) => dispatch(setPage(page)),
    setProperties: (properties) =>
      dispatch(settingsAction.setProperties(properties)),
    setCurrentAppOverlay: (overlay) => dispatch(setCurrentAppOverlay(overlay)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventList);

export function getEvent(item) {
  if (!item.datetime) return false;

  let address = "";
  if (item.address === undefined && item?.additionalData?.location) {
    const globalPOI = POIS.all_objects[item?.additionalData?.location];
    address = globalPOI
      ? unescape(encodeURIComponent(globalPOI.address))
      : undefined;
  } else if (item.address !== undefined) {
    address = unescape(encodeURIComponent(item.address));
  }

  const diffMin = item?.additionalData?.endDateTime
    ? Math.abs(
        (new Date(item.additionalData.endDateTime).getTime() -
          new Date(item.datetime).getTime()) /
          60000
      )
    : 120;

  const event = new CalendarEvent(
    new Date(item.datetime),
    "local",
    null,
    "local",
    { minutes: diffMin },
    unescape(encodeURIComponent(item.name)),
    unescape(encodeURIComponent(item.name)),
    address.length === 0 ? undefined : address,
    item.website,
    undefined,
    [],
    "CONFIRMED",
    "BUSY",
    {},
    []
  );

  const build = item.relations
    ? item.relations.find((i) => "Einrichtung" === i.type)
    : false;
  if (build) {
    event.location = build.name;
  }

  return event;
}
