export const sourceIds = {
  nodes: "knotenFeatures",
  roomNodes: "roomKnotenFeatures",
  addressNodes: "adressenKnotenFeatures",
  iconNodes: "iconKnotenfeatures",
  polygons: "polygonFeatures",
  polygonLayout: {
    layout: "polygonLayoutFeatures",
    rooms: "polygonRoomsFeatures",
  },
  routeSymbole: "zwischenSymbole",
  arrowHead: "arrowSymbols",
  arrowKanten: "arrowKanten",
  kanten: "kantenFeatures_line",
  threeBuilding: "threeMainBuildingSource",
  position: "positionPoint",
  debugPosition: "debugPositionPoint",
  debug_real_position: "debugPositionPoint2",
  debug_smooth_managerPoint: "debugPositionPoint3",
  debug_beacon_positions: "debugBeaconPositions",
  debug_beacon_positions_raw: "debugBeaconPositions_raw",

  exteriorGeoJSONs: {
    // wichtig diese Namen müssen exakt sein -> die Dateien sind zu finden in navigationProductData
    streets: "streets.geojson.json",
  },
  poiChildren: "poiChildren",
};
// Funktion zum Checken (damit man nicht immer alle Sources ausschreiben muss, falls noch welche dazukommen
export const hasSources = (element) => {
  return (
    element.source === sourceIds.polygons || element.source === sourceIds.nodes
  );
};
