import {appVersion, piKey} from "../../../app/app.sideEffects/useURLParams.sideEffects.js";
import { postData } from "../../../functions/helper/helpers.functions.js";
import { useEffect } from "react";
import {
  callAndroidFunction,
  callIPhoneFunctions,
} from "../NativeAppInterface.jsx";

export function useNativePushMessages(props) {
  const sendTokenToDatabase = (token, value) => {
    let json = {
      token: token,
      piKey: piKey,
      os: props.deviceInformation?.os,
      osVersion: props.deviceInformation?.osVersion,
      appversion: props.deviceInformation?.appVersion,
      delete: !value,
      deviceDetails: props.deviceInformation ?? null
    };
    postData(process.env.REACT_APP_UPLOAD_PRODUKTINSTANZ_TOKEN, json);
  };

  const askForPermission = () => {
    if (!props.isApp || !props.deviceInformation) return;

    if (props.userAllowedPushMessages) {
      callIPhoneFunctions(["enablePush"]);
      callAndroidFunction("enablePush");
    } else {
      callIPhoneFunctions(["disablePush"]);
      callAndroidFunction("disablePush");
    }
  };

  useEffect(askForPermission, [
    props.userAllowedPushMessages,
    props.pushPermissionAllowed,
    props.isApp,
    props.deviceInformation,
  ]);

  window.pushPermissionGranted = (value, token) => {
    // TODO: wenn useSettings fertig ist state setzen, dass es fertig ist,
    // erst dann darf das hier ausgeführt werden!
    sendTokenToDatabase(token, value);
    props.setSettingProperties({
      pushMessages: { permissionAllowed: value },
    });
  };
}
