import { POIS } from "../reduxStore/actions/main";
//@ts-ignore
import { v4 as uuidv4 } from "uuid";

export default class Scanner {
  id = uuidv4();
  scannedText: string[] = [];
  scannedHeading: number[] = [];

  public addText(text: string) {
    this.scannedText.push(text);
  }

  public clear() {
    this.scannedText = [];
    this.scannedHeading = [];
  }

  public addHeading(heading: number) {
    this.scannedHeading.push(heading);
  }

  public getRoom(): any | null {
    const building = this.findBuilding();
    if (!building) return null;
    const floor = this.findFloor();
    if (!floor) return null;

    const room = this.findRoom(building, floor);
    return room;
  }

  private findBuilding() {
    const regexBuilding = /^\d+/;
    const buildings = POIS.buildings as any[];
    const possibleBuildingShorts = this.scannedText.reduce(
      (result: string[], text) => {
        const short = text.match(regexBuilding)?.shift();
        if (!short) return result;
        const building = buildings.find((b: any) => b.short === short);
        if (building) {
          result.push(building.short);
        }
        return result;
      },
      []
    );

    const mostFrequentShort = this.getMostFrequentWord(
      possibleBuildingShorts.filter((n) => n)
    );
    if (!mostFrequentShort.length) return null;

    return buildings.find((b: any) => b.short === mostFrequentShort)!;
  }

  private findFloor() {
    const regexFloor = /Ebene.\d+/;
    const floors = POIS.floors as any[];
    const possibleFloorNames = this.scannedText.reduce(
      (result: string[], text) => {
        const name = text.match(regexFloor)?.shift();
        const floor = floors.find((b: any) => b.name === name);
        if (floor) {
          result.push(floor.name);
        }
        return result;
      },
      []
    );
    const mostFrequentName = this.getMostFrequentWord(
      possibleFloorNames.filter((n) => n)
    );
    if (!mostFrequentName.length) return null;

    return floors.find((f: any) => f.name === mostFrequentName)!;
  }

  private findRoom(building: any, floor: any) {
    const regexRoom = /Raum\s(\d+([.]\d+)?[A-Z]?|[Tt]\d+|\d+\/\d+[a-z]?)/;
    const rooms = POIS.rooms as any[];

    const possibleRoomNames = this.scannedText.reduce(
      (result: string[], text) => {
        const rawRoomName = text.match(regexRoom)?.shift();
        if (!rawRoomName) return result;
        const tempName = rawRoomName.split(".");
        const nameWithoutDot = tempName[tempName.length - 1];

        const name = `${building.short} ${
          floor.level
        }.${nameWithoutDot?.replace("Raum ", "")}`;

        const room = rooms.find((b: any) => b.name === name);
        if (room) {
          result.push(room.name);
        }
        return result;
      },
      []
    );
    const mostFrequentName = this.getMostFrequentWord(
      possibleRoomNames.filter((n) => n)
    );
    if (!mostFrequentName.length) return null;

    return rooms.find((r: any) => r.name === mostFrequentName)!;
  }

  private getMostFrequentWord(wordArray: string[]) {
    let wordFrequency: { [key: string]: number } = {};
    let maxCount = 0;
    let mostFrequentWord = "";

    wordArray.forEach((word) => {
      wordFrequency[word] = (wordFrequency[word] || 0) + 1;
      if (wordFrequency[word] > maxCount) {
        maxCount = wordFrequency[word];
        mostFrequentWord = word;
      }
    });

    return mostFrequentWord;
  }
}
