import { map } from "../../map.sideEffects/useMap.sideEffects.js";
import { layerIds } from "../../variables/layerIds.js";
import { sourceIds } from "../../variables/sourceIds.js";
import { isInDevelopmentMode } from "../../../../../functions/helper/helpers.functions.js";

export function createDebuggedUserPosition() {
  if (!map.getLayer(layerIds.nodes.position.dotDebug)) {
    map.addLayer({
      id: layerIds.nodes.position.dotDebug,
      type: "circle",
      source: sourceIds.debugPosition,
      layout: { visibility: "none" },
      paint: {
        "circle-radius": 10,
        "circle-color": "#a41cbb",
        "circle-opacity": 0.1,
      },
    });
  }
  if (!map.getLayer(layerIds.nodes.position.debug_real_position)) {
    map.addLayer({
      id: layerIds.nodes.position.debug_real_position,
      type: "circle",
      source: sourceIds.debug_real_position,
      layout: {
        visibility: isInDevelopmentMode() ? "visible" : "none",
      },
      paint: {
        "circle-radius": ["case", ["has", "radius"], ["get", "radius"], 10],
        "circle-color": ["case", ["has", "color"], ["get", "color"], "#18cfe0"],
        "circle-opacity": 1,
      },
    });
    map.addLayer({
      id: layerIds.nodes.position.debug_real_position_symbol,
      type: "symbol",
      source: sourceIds.debug_real_position,
      layout: {
        "text-field": ["get", "text"],
        visibility: isInDevelopmentMode() ? "visible" : "none",
      },
    });
  }
  if (!map.getLayer(layerIds.nodes.position.dotDebug3)) {
    map.addLayer({
      id: layerIds.nodes.position.dotDebug3,
      type: "circle",
      source: sourceIds.debug_smooth_managerPoint,
      layout: {
        visibility: "none",
      },
      paint: {
        "circle-radius": ["case", ["has", "radius"], ["get", "radius"], 8],
        "circle-color": ["case", ["has", "color"], ["get", "color"], "#73b1e5"],
        "circle-opacity": 0.5,
      },
    });
    map.addLayer({
      id: layerIds.nodes.position.dotDebug3_symbol,
      type: "symbol",
      source: sourceIds.debug_smooth_managerPoint,
      layout: {
        "text-field": ["get", "text"],
        visibility: isInDevelopmentMode() ? "visible" : "none",
      },
    });
  }
}
