import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import StandardCard from "../../../elements/cards/StandardCard.card.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RouteItem from "../../../../functions/classes/RouteItem.class.js";
import classes from "../navigation.module.scss";
import RoundedIcon from "../../../elements/buttons/roundedIcon.component.jsx";
import useIntl from "react-intl/lib/src/components/useIntl.js";
import { lineSlice, lineStrinmg, length, lineString } from "@turf/turf";

export function NavigationInstructions({
  currentStep,
  handleClose,
  setMapPadding,
  locationObject,
  margin = true,
  bannerStepDistance = null,
  isTerminal = false,
}) {
  const containerRef = useRef();
  const [lengthRemaining, setLengthRemaining] = useState(Infinity);
  useEffect(() => {
    if (!locationObject) return;
    const tempLengthRemaining = Math.floor(
      length(
        lineSlice(
          [locationObject.lng, locationObject.lat],
          currentStep.stepLine[currentStep.stepLine.length - 1],
          lineString(currentStep.stepLine)
        ),
        { units: "meters" }
      )
    );

    if (lengthRemaining > tempLengthRemaining) {
      setLengthRemaining(tempLengthRemaining);
    }
  }, [locationObject]);

  useEffect(() => {
    setLengthRemaining(Infinity);
  }, [currentStep]);
  const intl = useIntl();
  const [distanceToNextStep, setDistanceToNextStep] = useState(0);
  useLayoutEffect(() => {
    setMapPadding({ top: containerRef.current.clientHeight });
  }, []);

  useEffect(() => {
    setDistanceToNextStep(
      RouteItem.getDistanceFormatted(
        bannerStepDistance != null ? bannerStepDistance : currentStep.distance
      )
    );
  }, [bannerStepDistance, currentStep.distance]);

  return (
    <StandardCard
      ref={containerRef}
      className={`d-flex ${
        margin ? "general-margin-horizontally" : ""
      } standardCard__navigation--instructions pointer-event-on w-100-dynamic`}
    >
      <div
        className={`mr-4 ml-4 d-flex flex-column align-items-center justify-content-center`}
      >
        <FontAwesomeIcon
          className={"mb-2"}
          aria-label={currentStep.getIconName()}
          icon={isTerminal ? "arrow-up" : currentStep.getIconName()}
          style={{ fontSize: "30px" }}
        />
        <span className={"speakOut"}>
          {lengthRemaining !== Infinity
            ? lengthRemaining > 1000
              ? `${(lengthRemaining / 1000).toFixed(2).replace(".", ",")} km`
              : `${lengthRemaining} m`
            : ""}
        </span>
      </div>
      <div className={`${classes.stepInfo} d-flex align-items-center mr-3`}>
        <span className={"speakOut"}>{currentStep.instructionsText}</span>
      </div>
      <RoundedIcon
        icon={["fal", "times"]}
        onClick={handleClose}
        divClass={
          "roundedIcon--quit pointer-event-on ml-auto align-self-center"
        }
        iconClass={"roundedIcon--quit__icon"}
        ariaLabel={intl.formatMessage({ id: "navigation.screenreader.quit" })}
      />
    </StandardCard>
  );
}
