import PropTypes from "prop-types";

import classes from "./toogle.module.scss";

function StandardToggleButton({ className, onChange, value }) {
  return (
    <label className={classes.button + " " + className}>
      <input
        onChange={onChange}
        type="checkbox"
        checked={value}
        className={`standardToggleButton w-0 h-0`}
      />
      <span className={classes.slide + " standardToggleButton__slide"} />
    </label>
  );
}

StandardToggleButton.propTypes = {
  value: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
};
StandardToggleButton.defaultProps = {
  className: "",
};

export default StandardToggleButton;
