const initState = {
  status: "close",
};

export default function mainNavigation(state = initState, action) {
  switch (action.type) {
    case "MAIN_NAVIGATION_SET_OPEN":
      return { ...state, status: "open" };
    case "MAIN_NAVIGATION_SET_PRE_OPEN":
      return { ...state, status: "preOpen" };
    case "MAIN_NAVIGATION_SET_CLOSE":
      return { ...state, status: "close" };
    default:
      return state;
  }
}
