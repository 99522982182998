export function fastSlowFast(t) {
  return t < 0.5
    ? (1 - Math.sqrt(1 - Math.pow(2 * t, 2))) / 2
    : (Math.sqrt(1 - Math.pow(-2 * t + 2, 2)) + 1) / 2;
}

export function easeOutQuint(t) {
  return 1 - Math.pow(1 - t, 5);
}

export function easeInCubic(t) {
  return t * t * t;
}

export function easeInOutQuint(x) {
  return x < 0.5 ? 16 * x * x * x * x * x : 1 - Math.pow(-2 * x + 2, 5) / 2;
}
