import {
  setLayerFilters,
  zoomCampusEnd,
  zoomCampusStart,
} from "../filter.map.js";
import { layerIds } from "../../variables/layerIds.js";
import { EXPRESSIONS } from "../../variables/expressions.js";

/**
 * Diese Funktion sieht aus als wäre sie unnötig, aber sie updated die Zoomlevels (je nach Page werden die Variablen global neu besetzt).
 */
export default function filterCampusSymbols() {
  const filter = [
    "case",
    EXPRESSIONS.type.campus,
    ["all", [">", ["zoom"], zoomCampusStart], ["<", ["zoom"], zoomCampusEnd]],
    false,
  ];

  setLayerFilters([layerIds.nodes.campus.symbol], filter);
}
