import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import SplashScreen from "../../elements/splashScreen/splashScreen.render.jsx";
import QrReader from "react-qr-reader";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { QR_CODES } from "../../../reduxStore/actions/main";
import axios from "axios";
import RoundedIcon from "../../elements/buttons/roundedIcon.component.jsx";
import { connect } from "react-redux";
import SaveZoneWrapper from "../../elements/wrapper/saveZone.wrapper.jsx";
import * as mainAction from "../../../reduxStore/actions/main.js";
import StandardCard from "../../elements/cards/StandardCard.card.jsx";
import StandardButton from "../../elements/buttons/standard.button.jsx";
import { setDebuggingValues } from "../../../reduxStore/actions/debug.js";
import { useNativeCamera } from "../../nativeAppInterface/sideEffects/useNativeCamera.js";
import { statisticLogger } from "../../statisticLogger/StatisticLogger.container.js";

function QrCodeReader(props) {
  const [showQRCodeInput, setShowQRCodeInput] = useState(false);
  const [facingType, setFacingType] = useState("environment");
  const [showQRCamera, setShowQRCamera] = useState(true);

  const toggleFacingType = () => {
    if (facingType === "environment") setFacingType("user");
    if (facingType === "user") setFacingType("environment");
  };

  const getQRCode = async (snipped) => {
    const id = snipped.split("/")[snipped.split("/").length - 1].toUpperCase();
    const qrCode = QR_CODES.find((qr) => qr.name === id);
    if (qrCode) return qrCode;

    if (snipped?.includes("?") && window.remoteURLCalled) {
      window.remoteURLCalled(snipped);
      return "link";
    } else {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_GET_QR_CODE}?qrKey=${id}&version=${process.env.REACT_APP_VERSION}`
        );
        if (result.data) {
          return {
            type: "QR-Code",
            name: result.data.qrKey,
            startNodeId: result.data.startNodeId,
            destinationId: result.data.destinationId,
            isSearchable: 1,
            category: "QR-Code",
          };
        } else {
          setShowQRCodeInput(true);
        }
      } catch (e) {
        setShowQRCodeInput(true);
        window.addWarning({
          show: true,
          upload: true,
          message: "QR-Code not found:  " + id,
          userMessage: "qrCode.notFound",
          needAdditionInformation: false,
        });
      }
    }
  };
  const [showErrorMessage] = useState(false);
  const handleClose = () => {
    props.canceled();
  };

  // Upload QR-Code
  const handleScan = async (data) => {
    if (null === data) return;
    // zur sicherheit, wenn man den QR Code aus der Suche aufgerufen hat!
    props.setCurrentAppOverlay();

    const qrCode = await getQRCode(data);
    if (qrCode === "link") {
      props.canceled();
    } else if (qrCode != null) {
      statisticLogger.addLog({
        action: {
          group: "QRCode",
          id: "scanQRCode",
          movement: "stay",
          name: "Scan QRCode",
          type: "input",
          interaction: "scan",
          content: {
            code: qrCode,
            startLatitude: props.locationObject?.lat,
            startLongitude: props.locationObject?.lng,
          },
        },
      });
      props.readId(qrCode);
      props.canceled();
    } else {
      //TODO: SEND WHAT THE USER SCANNED
      setShowQRCodeInput(true);
    }
    setShowQRCamera(false);
  };
  const handleError = (data) => {
    new Error(`qr code can't read QR-Code. given Data: ${JSON.parse(data)}`);
  };

  // Manuel input
  const [code, setCode] = useState("");
  const codeSubmit = async (e) => {
    e.preventDefault();
    const qrCode = await getQRCode(code);

    if (qrCode) {
      props.readId(qrCode);
      statisticLogger.addLog({
        action: {
          group: "QRCode",
          id: "manualQRCodeInput",
          movement: "stay",
          name: "Manually input QRCode",
          type: "input",
          interaction: "submit",
          content: { code: qrCode },
        },
      });
      setShowQRCodeInput(false);
    } else {
      //TODO: zum server schicken...
    }
  };
  useNativeCamera(
    props.cameraPermissionAllowed,
    props.setCameraPermissionAllowed,
    handleScan,
    handleClose
  );
  useBrowserCamera();

  return (
    <div>
      {showQRCamera && !props.isApp && (
        <SplashScreen
          onClosingFinished={props.canceled}
          render={(handleClose) => (
            // Nur den schwarzen hintergrund zeigen, wenn es kein iPhone ist, da dieses natively arbeitet
            <div className={"d-flex flex-column h-100"}>
              <SaveZoneWrapper>
                <div
                  className={
                    "d-flex position-relative rounded-1 overflow-hidden m-3"
                  }
                >
                  <QrReader
                    delay={500}
                    onError={handleError}
                    onScan={handleScan}
                    facingMode={facingType}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
                <div className="d-flex justify-content-center w-100 p-5">
                  <RoundedIcon
                    icon={["fal", "repeat-alt"]}
                    onClick={toggleFacingType}
                    divClass={"roundedIcon--actionBubble mr-3"}
                    size={5 + "rem"}
                    fontSize={2.5 + "rem"}
                    iconRotation={90}
                  />
                  <RoundedIcon
                    icon={["fal", "times"]}
                    divClass={"roundedIcon--actionBubble"}
                    onClick={handleClose}
                    size={5 + "rem"}
                    fontSize={2.5 + "rem"}
                  />
                </div>
                <p className="text-white p-3 text-center">
                  <FormattedMessage id={"qrCode.scanACode"} />
                </p>
              </SaveZoneWrapper>
            </div>
          )}
        />
      )}
      <QRCodeInput
        code={code}
        handleClose={handleClose}
        codeSubmit={codeSubmit}
        setCode={setCode}
        show={showQRCodeInput}
        showErrorMessage={showErrorMessage}
      />
    </div>
  );
}

function QRCodeInput({
  show,
  handleClose,
  showErrorMessage,
  code,
  setCode,
  codeSubmit,
}) {
  const intl = useIntl();
  return (
    <Modal centered show={show} onHide={handleClose}>
      <StandardCard>
        <h2>
          <FormattedMessage id="search.dialogTitle" />
        </h2>
        <div>
          <FormattedMessage id="search.dialogCantReadQRCode" />
          <form className="form-inline pt-4 d-flex justify-content-between">
            {showErrorMessage ? (
              <span className="text-danger">
                <FormattedMessage id="search.dialogNoLocationFound" />
              </span>
            ) : null}
            <input
              type="text"
              name="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              className="form-control input w-100 general-margin-bottom-large"
              placeholder={intl.formatMessage({
                id: "search.dialogPlaceholder",
              })}
            />
            <div className={"d-flex justify-content-center w-100"}>
              <StandardButton
                buttonClasses={
                  "normalButton--cancel normalButton--cancel--naked mr-3"
                }
                handleClick={handleClose}
              >
                <FormattedMessage id="search.dialogCancel" />
              </StandardButton>
              <StandardButton
                buttonClasses={"normalButton--primary"}
                handleClick={codeSubmit}
              >
                <FormattedMessage id="search.dialogSubmit" />
              </StandardButton>
            </div>
          </form>
        </div>
      </StandardCard>
    </Modal>
  );
}

QrCodeReader.propTypes = {
  readId: PropTypes.func.isRequired,
  canceled: PropTypes.func.isRequired,
  cameraPermissionAllowed: PropTypes.bool,
};

function useBrowserCamera(isApp, cameraPermissionAllowed) {
  useEffect(() => {
    if (isApp || cameraPermissionAllowed != null) return;
  });
}

const mapStateToProps = (state) => {
  return {
    cameraPermissionAllowed: state.main.cameraPermissionAllowed,
    isApp: state.main.isApp,
    locationObject: state.route.locationObject,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setCurrentAppOverlay: (object) =>
    dispatch(mainAction.setCurrentAppOverlay(object)),
  setDebug: (object) => dispatch(setDebuggingValues(object)),
  setCameraPermissionAllowed: (value) =>
    dispatch(mainAction.setCameraPermissionAllowed(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QrCodeReader);
