import { EXPRESSION_MIX, EXPRESSIONS } from "./expressions.js";
import { STYLINGVALUES } from "./layerStylingValues.js";
import { CUSTOMIZABLE_VALUES } from "./CUSTOMIZABLE_VALUES.js";

export let BUNDLED_PROPERTIES = {};
/**
 * Deklarieren der Properties für die MapLayers.
 * Diese Funktion ist nur deshalb notwendig, weil die Farben innerhalb des Objekts nicht referenziert werden.
 * Soll heisen wenn colors.variables.js sich ändert, ändern sich nicht die (eigentlich referenzierten) Farben hier! Außer man bespielt es erst wennd die Farben da sind!
 */
export const setBundledProperties = () => {
  BUNDLED_PROPERTIES = {
    rooms: {
      height: [
        "case",
        // wenn explizit eine height angegeben ist, dann muss diese genommen werden
        EXPRESSIONS.roomType.hasHeight,
        EXPRESSIONS.roomType.getHeight,
        EXPRESSIONS.roomType.entranceRoom,
        STYLINGVALUES.rooms.height.entranceRoom,
        // wenn ein Raum glass ist, dann hat er immer einen niedrigen Boden
        // (außer height wurde explizit mitgegeben)
        EXPRESSIONS.roomType.glass,
        STYLINGVALUES.rooms.height.glassLow,
        // rooms ohne glass
        // -- areas ---
        EXPRESSIONS.roomType.waitingArea,
        STYLINGVALUES.rooms.height.waitingArea,
        EXPRESSIONS.roomType.emergencyArea,
        STYLINGVALUES.rooms.height.emergencyArea,
        EXPRESSIONS.roomType.cafeteriaArea,
        STYLINGVALUES.rooms.height.cafeteriaArea,
        EXPRESSIONS.roomType.officeArea,
        STYLINGVALUES.rooms.height.officeArea,

        // --rooms ---
        EXPRESSIONS.roomType.emergencyRoom,
        STYLINGVALUES.rooms.height.emergencyRoom,
        EXPRESSIONS.roomType.cafeteriaRoom,
        STYLINGVALUES.rooms.height.cafeteriaRoom,
        EXPRESSIONS.roomType.waitingRoom,
        STYLINGVALUES.rooms.height.waitingRoom,
        EXPRESSIONS.roomType.switchFloorRoom,
        STYLINGVALUES.rooms.height.switchFloorRoom,
        EXPRESSIONS.roomType.stairsRoom,
        STYLINGVALUES.rooms.height.stairsRoom,
        EXPRESSIONS.roomType.elevatorRoom,
        STYLINGVALUES.rooms.height.elevatorRoom,
        // -- floors ---
        EXPRESSIONS.roomType.mainFloor,
        STYLINGVALUES.rooms.height.mainFloor,
        EXPRESSIONS.roomType.sideFloor,
        STYLINGVALUES.rooms.height.sideFloor,
        STYLINGVALUES.rooms.height.standard,
      ],
      base: [
        "case",
        EXPRESSIONS.roomType.entranceRoom,
        STYLINGVALUES.rooms.base.entranceRoom,
        0,
      ],
      opacity: [
        "case",
        EXPRESSIONS.roomType.switchFloorRoom,
        STYLINGVALUES.rooms.opacity.switchFloorRoom,
        EXPRESSIONS.roomType.mainFloor,
        STYLINGVALUES.rooms.opacity.mainFloor,
        EXPRESSIONS.roomType.stairsRoom,
        STYLINGVALUES.rooms.opacity.stairsRoom,
        EXPRESSIONS.roomType.waitingRoom,
        STYLINGVALUES.rooms.opacity.waitingRoom,
        STYLINGVALUES.rooms.opacity.standardRoom,
      ],
      color: [
        "case",
        // floors
        EXPRESSIONS.roomType.mainFloor,
        CUSTOMIZABLE_VALUES.mainFloor,
        EXPRESSIONS.roomType.sideFloor,
        CUSTOMIZABLE_VALUES.sideFloor,
        EXPRESSIONS.roomType.restrictedFloor,
        CUSTOMIZABLE_VALUES.restrictedFloor,
        // rooms
        EXPRESSIONS.roomType.emergencyRoom,
        CUSTOMIZABLE_VALUES.emergencyRoom,
        EXPRESSIONS.roomType.cafeteriaRoom,
        CUSTOMIZABLE_VALUES.cafeteriaRoom,
        EXPRESSIONS.roomType.exit,
        CUSTOMIZABLE_VALUES.exit,
        EXPRESSIONS.roomType.switchFloorRoom,
        CUSTOMIZABLE_VALUES.levelRoom,
        EXPRESSIONS.roomType.elevatorRoom,
        CUSTOMIZABLE_VALUES.elevatorRoom,
        EXPRESSIONS.roomType.stairsRoom,
        CUSTOMIZABLE_VALUES.stairsRoom,
        EXPRESSIONS.roomType.waitingRoom,
        CUSTOMIZABLE_VALUES.waitingRoom,
        EXPRESSIONS.roomType.wcRoom,
        CUSTOMIZABLE_VALUES.wcRoom,
        EXPRESSIONS.roomType.entranceRoom,
        CUSTOMIZABLE_VALUES.entranceRoom,
        EXPRESSIONS.roomType.officeArea,
        CUSTOMIZABLE_VALUES.officeArea,
        // areas
        EXPRESSIONS.roomType.emergencyArea,
        CUSTOMIZABLE_VALUES.emergencyArea,
        EXPRESSIONS.roomType.cafeteriaArea,
        CUSTOMIZABLE_VALUES.cafeteriaArea,
        EXPRESSIONS.roomType.waitingArea,
        CUSTOMIZABLE_VALUES.waitingArea,

        CUSTOMIZABLE_VALUES.rooms,
      ],
    },
    symbols: {
      indoor: {
        color: [
          "case",
          // icons bevorzugen!
          EXPRESSIONS.state.selected,
          CUSTOMIZABLE_VALUES.white,

          EXPRESSIONS.iconname.notaufnahme,
          CUSTOMIZABLE_VALUES.notaufnahme,

          [
            "any",
            EXPRESSIONS.iconname.wcmen,
            EXPRESSIONS.iconname.wcwomenandmen,
            EXPRESSIONS.iconname.wcdisabled,
            EXPRESSIONS.iconname.wcwomen,
          ],
          CUSTOMIZABLE_VALUES.toilette,

          EXPRESSIONS.iconname.virus,
          CUSTOMIZABLE_VALUES.virus,

          EXPRESSIONS.iconname.jelbi,
          CUSTOMIZABLE_VALUES.jelbi,

          EXPRESSIONS.iconname.apotheke,
          CUSTOMIZABLE_VALUES.apotheke,

          EXPRESSIONS.iconname.taxi,
          CUSTOMIZABLE_VALUES.taxi,

          EXPRESSIONS.iconname.bushaltestelle,
          CUSTOMIZABLE_VALUES.bushaltestelle,

          EXPRESSIONS.iconname.bistro,
          CUSTOMIZABLE_VALUES.bistro,

          EXPRESSIONS.iconname.info,
          CUSTOMIZABLE_VALUES.information,

          EXPRESSIONS.iconname.kasse,
          CUSTOMIZABLE_VALUES.cashier,

          EXPRESSIONS.iconname.entrance,
          CUSTOMIZABLE_VALUES.entrance,

          EXPRESSIONS.iconname.geldautomat,
          CUSTOMIZABLE_VALUES.atm,

          EXPRESSION_MIX.symbols.restricted,
          CUSTOMIZABLE_VALUES.restricted,

          EXPRESSION_MIX.symbols.unimportant,
          CUSTOMIZABLE_VALUES.unimportantSymbol,

          // types
          EXPRESSIONS.type.poi,
          CUSTOMIZABLE_VALUES.poi,

          EXPRESSIONS.type.parkplatz,
          CUSTOMIZABLE_VALUES.parking,

          // standard Farbe
          CUSTOMIZABLE_VALUES.standardSymbol,
        ],
      },
      outdoor: {
        facility: {
          color: ["case"],
        },
      },
    },
  };
};
// safety measure, damit bundled Properties nicht leer bleibt!
setBundledProperties();
