import * as types from "../types.variables.js";
import axios from "axios";
import { sessionKey } from "../reducers/main";
import UserLocation, {
  RawLocation,
} from "../../functions/classes/location.class.js";
import { NAVIGATION_SET_LOCATION } from "../types.variables.js";

export function setType(type) {
  return {
    type: types.NAVIGATION_SET_TYPE,
    payload: type,
  };
}
export function setPositionRecording(value) {
  return {
    type: types.NAVIGATION_SET_POSITION_RECORDING,
    payload: value,
  };
}

/**
 * Action für den Empfang von BLE Data Packages
 * @param {[{rssi, uuid, major, minor, timestamp}]} data
 * @returns {{payload: {lng: number, accuracy: *, lat: number}, type: string}}
 */
export function setBLEData(data) {
  const rawBLEData = new RawLocation(data, RawLocation.types.bluetooth);

  return {
    type: types.NAVIGATION_SET_LOCATION_DATA,
    payload: rawBLEData,
  };
}

export function setLocation(position, rawLocation) {
  return {
    type: types.NAVIGATION_SET_LOCATION,
    payload: {
      position,
      rawLocation,
    },
  };
}

/**
 * Action für den Empfang von GPS Data Packages
 * @param {{lat, lng, accuracy}} data
 * @returns {{payload, type: string}}
 */
export function setGPSData(data) {
  const rawGPSData = new RawLocation(data, RawLocation.types.gps);

  return {
    type: types.NAVIGATION_SET_LOCATION_DATA,
    payload: rawGPSData,
  };
}

export function setHasLatestLocation(value) {
  return {
    type: types.NAVIGATION_SET_LATEST_POSITION,
    payload: value,
  };
}

export function resetLocationObject() {
  return {
    type: types.NAVIGATION_SET_LOCATION_DATA,
    payload: new UserLocation(UserLocation.location_types.gps, null, null),
  };
}

/**
 * Setzt das Heading, also die Blickrichtung des users
 * @param {number} heading
 * @returns {{payload, type: string}}
 */
export function setHeading(heading) {
  return {
    type: types.NAVIGATION_SET_HEADING,
    payload: heading,
  };
}

export function setMotionActivityStatus(status) {
  return {
    type: types.NAVIGATION_SET_MOTION_ACTIVITY_STATUS,
    payload: status,
  };
}

/**
 * @param {"live"|"sbs"} mode
 */
export function setNavigationMode(mode) {
  return {
    type: types.NAVIGATION_SET_MODE,
    payload: mode,
  };
}

export function setLocationObjectZIndex(zIndex) {
  return {
    type: types.NAVIGATION_SET_ZINDEX,
    payload: zIndex,
  };
}

// no redux - just uploading comment
export async function sendRating(
  sessionId,
  target,
  start,
  comment,
  stars,
  duration
) {
  axios.post(process.env.REACT_APP_STATSTIK_URL, {
    sessionKey,
    globalPOIId: target.globalPoiId,
    startKnotenId: start.nodeId,
    campusId: target.campusId ?? start.campusId ?? null,
    sterne: stars,
    kommentar: comment,
  });
}
