import { useEffect, useState } from "react";
import {
  BEACONS,
  CONTENT,
  LOCATION_MANAGER,
} from "../../reduxStore/actions/main";
import { isAndroid } from "react-device-detect";
import { Remote, wrap } from "comlink";
import { connect } from "react-redux";
import {
  LocationManagerWorker,
  LocationManagerWorkerType,
} from "../../workers/locationManager.worker";
import { setLocation } from "../../reduxStore/actions/navigation";
import { Position } from "@turf/turf";
import { RawLocation } from "../../functions/classes/location.class";
import { setDebugBeaconPulses } from "../../reduxStore/functions/setLocationData.functions";
import {
  isInParkingModeGlobal,
  navigationController,
} from "./navigation/navigation.component";
import { piKey } from "../../app/app.sideEffects/useURLParams.sideEffects";

let isCalculation = false;

function LocationManagerContainer(props: any) {
  const [queue, setQueue] = useState<Array<any>>([]); // Zustand für die Warteschlange
  const [worker, setWorker] = useState<null | Promise<
    Remote<LocationManagerWorker>
  >>(null);

  useEffect(() => {
    (async () => {
      const worker = new Worker(
        new URL("../../workers/locationManager.worker", import.meta.url),
        {
          name: "runBigTaskWorker",
          type: "module",
        }
      );

      const LocationManager = wrap<LocationManagerWorkerType>(worker);
      const locationManagerInstance = new LocationManager(
        CONTENT,
        BEACONS,
        isAndroid
      );
      setWorker(locationManagerInstance);
    })();
  }, []);

  useEffect(() => {
    setQueue((oldQueue) => [...oldQueue, props.rawLocationData]);
  }, [props.rawLocationData]);

  useEffect(() => {
    if (queue.length > 0) {
      const rawLocationData = queue[0];

      (async () => {
        if (!worker) return;
        if (isCalculation) return;
        const locationManager = await worker;

        const routeProgress = navigationController?.getRouteProgress();

        let position = null;

        if (navigationController && props.currentRoute) {
          const rP = navigationController.getRouteProgress();
          rP.legIndex = props.currentRoute.legPos;
          navigationController.routeProgress = rP;
        }

        await locationManager.updateLocationData(
          rawLocationData.data,
          props.activeStatus
        );

        position = await locationManager.getCurrentLocation(
          props.activeStatus,
          routeProgress
        );

        LOCATION_MANAGER.setLastPosition(position);

        if (!isInParkingModeGlobal) {
          props.setLocation(position, rawLocationData);
        }

        setDebugBeaconPulses(rawLocationData);
      })();

      setQueue((oldQueue) => oldQueue.slice(1));
    }
  }, [queue]);

  return null;
}

const mapDispatchToProps = (dispatch: any) => ({
  setLocation: (position: Position | null, rawLocation: RawLocation) =>
    dispatch(setLocation(position, rawLocation)),
});

const mapPropsToState = (state: any) => ({
  rawLocationData: state.route.rawLocationData,
  activeStatus: state.route.activeStatus,
  currentRoute: state.route.currentRoute,
  positionRecording: state.route.positionRecording,
});
export default connect(
  mapPropsToState,
  mapDispatchToProps
)(LocationManagerContainer);
