import Highlight from "../../elements/text/highlight.text.jsx";
import StandardCard from "../../elements/cards/StandardCard.card";
import PropTypes from "prop-types";
import { getSearchFilterText } from "../list";
import React from "react";

function News(props) {
  return (
    <StandardCard
      role={"button"}
      onClick={() => props.showNews(props.id)}
      className={"mb-3 standardCard__search"}
    >
      {props.name.toLowerCase().includes(props.searchText.toLowerCase()) ? (
        <h4 className="font-weight-bold">
          <Highlight search={props.searchText}>{props.name}</Highlight>
        </h4>
      ) : (
        <h4 className="font-weight-bold">
          <Highlight search={props.searchText}>
            {props.name +
              getSearchFilterText(props.searchText, props.searchstrings)}
          </Highlight>
        </h4>
      )}
      <span className="mb-3 overflow-auto">{props.description}</span>
    </StandardCard>
  );
}

News.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  searchstrings: PropTypes.array,
  description: PropTypes.string.isRequired,
  showNews: PropTypes.func.isRequired,
};

export default News;
