import React, { useEffect, useRef, useState } from "react";
import ActionBubbles, {
  ActionBubble,
} from "../../mapUi/actions/actionBubbles/actionBubbles.component.jsx";
import {
  setBLEData,
  setGPSData,
  setHeading,
  setPositionRecording,
} from "../../../reduxStore/actions/navigation.js";
import { connect } from "react-redux";
import { recordedPositions_collection } from "../../../reduxStore/reducers/route.js";
import { RawLocation } from "../../../functions/classes/location.class.js";
import {
  CAMPUS_COLLECTION,
  setCurrentAppOverlay,
} from "../../../reduxStore/actions/main.js";
import { AppOverlay } from "../../../functions/classes/miscelleanous.classes.js";
import StandardHeadline from "../text/standardHeadline.text.jsx";
import StandardButton from "../buttons/standard.button.jsx";
import StandardTextInput from "../forms/textInput.component.jsx";
import PropTypes from "prop-types";
import UploadItem from "../../../functions/classes/uploadItem.class.js";
import { setNewUploadItem } from "../../../reduxStore/actions/debug.js";
import { isAndroid } from "react-device-detect";
import StartDestination from "../../../functions/classes/startDestination.class.js";
import { setProperties } from "../../../reduxStore/actions/settings.js";
import { layerIds } from "../../mapUi/mapbox/variables/layerIds.js";
import { map } from "../../mapUi/mapbox/map.sideEffects/useMap.sideEffects.js";
import SaveZoneWrapper from "../wrapper/saveZone.wrapper.jsx";
import {
  isInCustomerTestingMode,
  isInDevelopmentMode,
} from "../../../functions/helper/helpers.functions.js";
import { useAutomaticNavigationRecord } from "./positionRecorder.effects.js/useAutomaticNavigationRecord.js";
import axios from "axios";
import { RECORD_ID, START_INDEX } from "./recordID";
import { piKey } from "../../../app/app.sideEffects/useURLParams.sideEffects";

const PositionRecorder = (props) => {
  const [showDebugPosition, setShowDebugPosition] = useState(false);
  const [currentDate] = useState(+new Date());
  const showDebug = isInDevelopmentMode();
  const showPositionRecorderSavingMenu =
    isInCustomerTestingMode() || isInDevelopmentMode();
  const onlyRecordingMode = !isInDevelopmentMode();

  const playerTimeout = useRef([]);
  const speed = 1;
  const [isPlaying, setIsPlaying] = useState(false);

  const handleStartRecording = () => {
    let currentRecord = recordedPositions_collection.getCurrent();

    if (currentRecord == null || currentRecord.destinationTimestamp != null) {
      recordedPositions_collection.startNewRecord(
        props.start,
        props.destination
      );
      currentRecord = recordedPositions_collection.getCurrent();
    }

    currentRecord.startCoordinates = [
      props.locationObject?.lng,
      props.locationObject?.lat,
    ];

    currentRecord.setNavigationType(props.navigationMode);
    currentRecord.setPiKey(piKey);
    currentRecord.setCampusKey(CAMPUS_COLLECTION.getCurrentCampus().campusKey);

    // meldet dem location reducer, dass er anfangen soll die daten zu saven
    props.setPositionRecording(true);
  };
  const handleStopRecording = () => {
    const currentRecord = recordedPositions_collection.getCurrent();
    if (currentRecord == null || currentRecord.submittedToUpload) {
      console.log("wurde bereits hochgeladen");
      return;
    }

    const uploadSilently = () => {
      // beim passiven Upload müssen automatisch metainfos zur route hochgeladen werden
      const currentCollection = recordedPositions_collection.getCurrent();
      const start = currentCollection.start;
      const destination = currentCollection.destination;
      const startText = getDescriptionText(
        start,
        "Start",
        currentCollection.startCoordinates,
        props.locationObject
      );
      const destinationText = getDescriptionText(
        destination,
        "Destination",
        currentCollection.destinationCoordinates,
        props.locationObject
      );
      submitToUpload(
        currentCollection,
        startText,
        destinationText,
        props.deviceInformation,
        props.setNewUploadItem
      );
    };
    const uploadWithForm = () => {
      // bei normalem Debug muss es möglich sein selbst etwas einzutippen beim upload
      props.setCurrentAppOverlay(
        new AppOverlay(AppOverlay.names.customContent, (handleClose) => (
          <UploadForm
            locationObject={props.locationObject}
            handleClose={handleClose}
            setUploadItem={props.setNewUploadItem}
            deviceInformation={props.deviceInformation}
          />
        ))
      );
    };

    currentRecord.destinationCoordinates = [
      props.locationObject?.lng,
      props.locationObject?.lat,
    ];
    currentRecord.stopRecording();

    props.setPositionRecording(false);
    showDebugPositions(false);

    if (showPositionRecorderSavingMenu) {
      uploadWithForm();
    } else {
      uploadSilently();
    }
    currentRecord.setSubmittedToUpload();
  };

  const showDebugPositions = (value) => {
    if (!isInDevelopmentMode()) return;

    if (value === undefined) {
      const currentValue = map.getLayoutProperty(
        layerIds.nodes.position.dotDebug,
        "visibility"
      );
      if (currentValue === "visible") {
        value = false;
      } else {
        value = true;
      }
    }

    map.setLayoutProperty(
      layerIds.nodes.position.dotDebug3,
      "visibility",
      value ? "visible" : "none"
    );
    setShowDebugPosition(value);
  };

  const handleStartPlayer = async () => {
    const downloadID = RECORD_ID;
    const downloadURL = `${process.env.REACT_APP_RECORDS_URL}?id=${downloadID}`;

    try {
      const axiosResponse = await axios.get(downloadURL);
      if (!axiosResponse || !axiosResponse.data) {
        console.log("Kein recording konnte geladen werden", axiosResponse);
        return;
      }
      const deviceInformation = JSON.parse(
        axiosResponse.data.deviceInformation
      );
      axiosResponse.data.deviceInformation = deviceInformation;
      let correctedDataJSON = axiosResponse.data;
      if (
        typeof correctedDataJSON === "object" &&
        !Array.isArray(correctedDataJSON) &&
        correctedDataJSON !== null
      ) {
        correctedDataJSON = correctedDataJSON.data;
      }
      correctedDataJSON.splice(0, START_INDEX);
      setIsPlaying(true);

      const firstTimestamp = getCorrectTime(correctedDataJSON[0][0]);

      props.setProperties({
        location: { userAllowed: true, permissionAllowed: true },
      });

      for (const [index, entry] of correctedDataJSON.entries()) {
        if (!entry[0]) {
          return;
        }

        if (entry[0].lat != null) {
          props.setGPSData(entry);
        } else if (entry[0].rssi != null) {
          props.setBLEData(
            entry.map((e) => {
              const currentTimeStamp = +new Date();
              return {
                ...e,
                timestamp: currentTimeStamp,
              };
            })
          );
        } else if (entry[0].heading != null) {
          props.setHeading(entry[0].heading);
        }
        const nextElement = correctedDataJSON[index + 1];

        if (nextElement) {
          await justWait(
            nextElement[0].timestamp - entry[entry.length - 1].timestamp
          );
        }
      }

      showDebugPositions(true);
    } catch (e) {
      console.log("fehler beim download", e);
    }
  };
  const handleStopPlayer = () => {
    setIsPlaying(false);
    playerTimeout.current.forEach((timey) => {
      clearTimeout(timey);
    });
    showDebugPositions(false);
    playerTimeout.current = [];
  };
  const toggleBeaconLayers = () => {
    const layers = [
      layerIds.nodes.debug_beacon_positions.circles,
      layerIds.nodes.debug_beacon_positions.circles_raw,
      layerIds.nodes.debug_beacon_positions.symbols,
      layerIds.nodes.debug_beacon_positions.symbols_raw,
    ];
    let visibility = "visible";
    if (props.debugProperties.showBeacons) {
      visibility = "visible";
    } else {
      visibility = "none";
    }

    layers.forEach((l) => {
      map.setLayoutProperty(l, "visibility", visibility);
    });
  };
  useEffect(toggleBeaconLayers, [props.debugProperties.showBeacons]);

  useAutomaticNavigationRecord(
    showDebug,
    handleStartRecording,
    handleStopRecording,
    props.navigationMode,
    props.currentMapPage,
    props.automaticRecord
  );

  // wenn kein Debug angezeigt werden soll,
  // dann soll der Recorder nur unsichtbar agieren
  if (!showDebug) return null;
  return (
    <ActionBubbles
      className={props.className}
      iconDivClassname={props.positionRecording ? "bg-danger" : ""}
      iconClassName={props.positionRecording ? "text-white" : ""}
      iconName={["fal", "shoe-prints"]}
      openingDirection={"left"}
      showStandard_items={false}
    >
      <ActionBubble
        action={
          props.positionRecording ? handleStopRecording : handleStartRecording
        }
        className={"mr-2"}
        iconClassName={props.positionRecording ? "text-danger" : ""}
        iconname={props.positionRecording ? "square" : "circle"}
      />

      {!onlyRecordingMode && (
        <ActionBubble
          action={isPlaying ? handleStopPlayer : handleStartPlayer}
          className={"mr-2"}
          iconname={isPlaying ? "square" : "play"}
        />
      )}
      <ActionBubble
        action={() =>
          props.setProperties({
            debug: { showBeacons: !props.debugProperties.showBeacons },
          })
        }
        className={`mr-2 ${
          props.debugProperties.showBeacons ? "bg-primary" : ""
        }`}
        iconClassName={props.debugProperties.showBeacons ? "text-white" : ""}
        iconname={["fal", "radar"]}
      />
      {!onlyRecordingMode && (
        <ActionBubble
          action={() => showDebugPositions(!showDebugPosition)}
          className={`mr-2 ${showDebugPosition ? "bg-primary" : ""}`}
          iconClassName={showDebugPosition ? "text-white" : ""}
          iconname={"ball-pile"}
        />
      )}
    </ActionBubbles>
  );
};

const UploadForm = ({
  handleClose,
  setUploadItem,
  deviceInformation,
  locationObject,
}) => {
  // constants
  const currentCollection = recordedPositions_collection.getCurrent();
  const start = currentCollection.start;
  const destination = currentCollection.destination;

  // states
  const [startText, setStartText] = useState(
    getDescriptionText(
      start,
      "Start",
      currentCollection.startCoordinates,
      locationObject
    )
  );
  const [destinationText, setDestinationText] = useState(
    getDescriptionText(
      destination,
      "Destination",
      currentCollection.destinationCoordinates,
      locationObject
    )
  );

  const handleSubmit = () => {
    submitToUpload(
      currentCollection,
      startText,
      destinationText,
      deviceInformation,
      setUploadItem
    );
    handleClose();
  };
  const handleCancel = () => {
    handleClose();
  };

  return (
    <SaveZoneWrapper activeTop activeBottom>
      <div
        className={"w-100 d-flex flex-column  h-100 overflow-auto p-3"}
        style={{ paddingBottom: "30%" }}
      >
        <StandardHeadline type={"h3"} text={"Details"} className={"mb-3"} />
        <ul>
          <li>
            startTime:{" "}
            <b>
              {new Date(currentCollection.startTimestamp).toLocaleTimeString()}
            </b>
          </li>
          <li>
            destinationTime:{" "}
            <b>
              {new Date(
                currentCollection.destinationTimestamp
              ).toLocaleTimeString()}
            </b>
          </li>
          <li>Daten gesammelt: {currentCollection.data.length}</li>
          <li>
            Bluetooth: <b>{currentCollection.getByType("bluetooth").length}</b>
          </li>
          <li>
            GPS: <b>{currentCollection.getByType("gps").length}</b>
          </li>
        </ul>
        <StandardHeadline type={"h3"} text={"Start"} className={"mb-3"} />
        <StandardTextInput
          className={"mb-3"}
          type={"textArea"}
          value={startText}
          height={"4rem"}
          onChange={(value) => setStartText(value)}
        />
        <StandardHeadline type={"h3"} text={"Destination"} className={"mb-3"} />
        <StandardTextInput
          className={"mb-3"}
          type={"textArea"}
          value={destinationText}
          height={"4rem"}
          onChange={(value) => setDestinationText(value)}
        />
        <div className={"d-flex justify-content-around"}>
          <StandardButton buttonClasses={"mr-3"} handleClick={handleCancel}>
            Cancel
          </StandardButton>
          <StandardButton
            buttonClasses={"normalButton--primary"}
            handleClick={handleSubmit}
          >
            Upload
          </StandardButton>
        </div>
      </div>
    </SaveZoneWrapper>
  );
};

const submitToUpload = (
  currentCollection,
  startText,
  destinationText,
  deviceInformation,
  setUploadItem
) => {
  currentCollection.setDescription(startText + " | " + destinationText);
  recordedPositions_collection
    .getCurrent()
    .setSystem(isAndroid ? "Android" : "iOS");
  recordedPositions_collection
    .getCurrent()
    .setDeviceInformation(deviceInformation);
  console.log(currentCollection);
  setUploadItem(
    new UploadItem("Positionsrekorder", process.env.REACT_APP_UPLOAD_URL, {
      ...currentCollection,
      piKey,
    })
  );
};

const getDescriptionText = (
  startDestination,
  name,
  coordinates,
  locationObject
) => {
  try {
    if (startDestination) {
      let first = "";
      if (startDestination.poiId != null) {
        first = `${name}: ${startDestination.name}`;
      } else if (startDestination.nodeId != null) {
        first = `${name} - nodeId: ${startDestination.nodeId}`;
      } else {
        first = `${name}: ${coordinates}`;
      }

      const second = `Level: ${startDestination.level}`;

      return first + " | " + second;
    } else if (locationObject) {
      const first = `${name}: ${coordinates}`;
      const second = `zIndex: ${locationObject.zIndex}`;

      return first + " | " + second;
    } else return "";
  } catch (e) {
    console.log(e);
    return "";
  }
};

/**
 *
 * @param position
 * @param {RawLocation} rawLocation
 */
export function logNavigationData(rawLocation) {
  if (RawLocation.types.gps === rawLocation.type) {
    let gpsObject;
    gpsObject = rawLocation.data;

    recordedPositions_collection.getCurrent().pushEntry(gpsObject);
  } else {
    const values = [];

    rawLocation.data.forEach((d) => {
      let bluetoothObject = {
        uuid: d.uuid ?? d.UUID,
        major: d.major,
        minor: d.minor,
        rssi: d.rssi,
        timestamp: d.timestamp,
        mac: d.mac ?? null,
      };
      // prüfen, dass keine nur timestamp objects reinkommen
      if (d.major != null || d.minor != null) values.push(bluetoothObject);
    });

    recordedPositions_collection.getCurrent().pushEntry(values);
  }
}

export function logHeadingData(heading) {
  let headingObject = [
    {
      timestamp: new Date().getTime(),
      heading: heading,
    },
  ];
  recordedPositions_collection.getCurrent().pushEntry(headingObject);
}

PositionRecorder.propTypes = {
  className: PropTypes.string,
  start: PropTypes.instanceOf(StartDestination),
  destination: PropTypes.instanceOf(StartDestination),
  automaticRecord: PropTypes.bool,
};

PositionRecorder.defaultProps = {
  className: "mt-2",
  automaticRecord: false,
};

const mapStateToProps = (state) => {
  return {
    positionRecording: state.route.positionRecording,
    deviceInformation: state.main.deviceInformation,
    locationObject: state.route.locationObject,
    navigationMode: state.route.navigationMode,
    currentMapPage: state.map.currentPage,
    currentMainPage: state.main.currentPage,
    debugProperties: state.settings.debug,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNewUploadItem: (item) => dispatch(setNewUploadItem(item)),
    setPositionRecording: (value) => dispatch(setPositionRecording(value)),
    setCurrentAppOverlay: (appOverlay) =>
      dispatch(setCurrentAppOverlay(appOverlay)),
    setBLEData: (data) => dispatch(setBLEData(data)),
    setGPSData: (data) => dispatch(setGPSData(data)),
    setHeading: (heading) => dispatch(setHeading(heading)),
    setProperties: (value) => dispatch(setProperties(value)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PositionRecorder);

function getCorrectTime(entry) {
  return +entry.timestamp;
}

function justWait(timeInMilliseconds) {
  return new Promise((resolve) => setTimeout(resolve, timeInMilliseconds));
}
