export let alwaysOnBTRecordings = [];
export const resetAlwaysOnBTRecordings = () => {
  alwaysOnBTRecordings = [];
};

/**
 * Bluetooth recorder, der recorded egal ob die empfangenen bluetooth beacons in den daten sind oder nicht!
 * @param {} data
 */
export default function recordAlwaysOnBTData(data) {
  if (!data || data.length == 0) return;

  alwaysOnBTRecordings.push(data);
}
