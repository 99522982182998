import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import StartDestination from "../../../../functions/classes/startDestination.class.js";
import {
  CAMPUS_COLLECTION,
  POIS,
} from "../../../../reduxStore/actions/main.js";
import { distance, point } from "@turf/turf";
import Toast from "../../../../functions/classes/toast.class.js";
import { statisticLogger } from "../../../statisticLogger/StatisticLogger.container.js";

/**
 * Setzt initiales Start und Destination
 * @param {Object} props
 * @param {StartDestination} props.start
 * @param {StartDestination} props.target
 */
export function useStartDestination(props) {
  const [startDestinationError, setStartDestinationError] = useState("");
  const intl = useIntl();
  const setInitStart = () => {
    if (!props.start.isSet || props.start.positionType === "user") {
      if (props.userAllowedGPS && props.locationObject.lat != null) {
        const userLocation = new StartDestination({
          isSet: true,
          lat: props.locationObject.lat,
          lng: props.locationObject.lng,
          level: CAMPUS_COLLECTION.getLevelByIndex(props.locationObject.zIndex),
          positionType: "user",
          name: intl.formatMessage({ id: "gps.myLocation" }),
        });

        props.setStart(userLocation);
      } else if (props.defaultStartNode != null) {
        //defaultStartNode als Start setzen
        // wenn es ein Terminal ist, dann ist es nicht änderbar und "die momentane Position",
        // andernfalls "ausgewählter Ort"

        let defaultNodeName = props.isTerminal
          ? intl.formatMessage({ id: "route.defaultStartPosition" })
          : intl.formatMessage({ id: "route.defaultStartPoint" });

        const defaultNode = new StartDestination({
          nodeId: props.defaultStartNode,
          isSet: true,
          positionType: "node",
          changeable: false,
          name: defaultNodeName,
        });

        props.setStart(defaultNode);
      } else {
        props.setStart(
          new StartDestination({
            name: intl.formatMessage({ id: "gps.noGPS" }),
          })
        );
      }
    }
  };
  const setInitDestination = () => {
    // Destination
    if (!props.target.isSet) {
      if (props.currentFeature != null) {
        // console.log("MEIN CURRENT FET", props.currentFeature);

        // ausgewählten POI übernehmen
        const selectedPOI = new StartDestination({
          nodeId: props.currentFeature.getRouteNodeId(),
          poiId: props.currentFeature.id,
          isSet: true,
          positionType: "node",
          lat: props.currentFeature.getFeatureCenter()[1],
          lng: props.currentFeature.getFeatureCenter()[0],
          level: props.currentFeature.level,
          indoors: props.currentFeature.indoors,
          name: props.currentFeature.name,
          campusId: props.currentFeature.campusId,
          address: props.currentFeature.address,
          type: props.currentFeature.type,
        });
        props.setTarget(selectedPOI);
      }
    }
  };
  const setUpRouteFetch = () => {
    const routeIsTooFar = () => {
      // check ob die Ziele zu weit voneinander entfernt sind -> dann nichts berechnen
      props.addToast(
        new Toast(
          "startDestinationTooFarApar",
          intl.formatMessage({
            id: "route.error.startDestinationTooFarApart",
          }),
          Toast.toastTypes.info,
          [],
          7000
        )
      );
      // letzte Auswahl entfernen
      if (props.start.timestamp < props.target.timestamp) {
        props.resetTarget();
      } else {
        props.resetStart();
      }
      props.resetRoute();
      setStartDestinationError("route.error.startDestinationTooFarApart");
    };
    const routeIsTooShort = () => {
      props.addToast(
        new Toast(
          "startAtTargetError",
          intl.formatMessage({ id: "route.error.startAtDestination" }),
          Toast.toastTypes.info,
          [],
          7000
        )
      );
      // letzte Auswahl entfernen
      if (props.start.timestamp < props.target.timestamp) {
        props.resetTarget();
      } else {
        props.resetStart();
      }
      props.resetRoute();
      setStartDestinationError("route.error.startAtDestination");
    };

    if (props.start.isSet) {
      // aufs richtige Stockwerk setzen
      props.setFloorNumber(props.start.level);
    }

    if (props.start.isSet && props.target.isSet) {
      try {
        // console.log(
        //   "Ich bin hier in der Routen scheiße. start",
        //   props.start,
        //   "target",
        //   props.target
        // );

        const pointdistance = distance(
          point([props.start.lng, props.start.lat]),
          point([props.target.lng, props.target.lat]),
          { units: "meters" }
        );

        // console.log("MEin resultat", pointdistance);
        // check ob sie zu nah aneinander sind -> dann meldung geben,
        // dass man sich bereits am Zeil befindet
        if (pointdistance < 5 && props.start.level === props.target.level) {
          routeIsTooShort();
        } else if (pointdistance > 800000) {
          routeIsTooFar();
        } else {
          // checken ob die Ziele zu weit für Fußgänger sind
          // -> mehr als 5km automatisch auf Auto setzen
          if (pointdistance > 3000) {
            props.setRoadUserType(7);

            if (POIS.parkinglots.length) {
              props.setGoToParkingSpot(true);
            }
          }
          statisticLogger.addLog({
            action: {
              group: "Route",
              id: "routeStartDestinationSelected",
              name: "Route with Start and Destination selected",
              movement: "stay",
              type: "click",
              interaction: "select",
              content: {
                globalPoiIds: [
                  props.start.globalPoiId,
                  props.target.globalPoiId,
                ],
                campusId: props.start.campusId,
                startGlobalPoiId: props.start.globalPoiId,
                destinationGlobalPoiId: props.target.globalPoiId,
                startZIndex: props.start.level,
                startLatitude: props.start.lat,
                startLongitude: props.start.lng,
                destinationLatitude: props.target.lat,
                destinationLongitude: props.target.lng,
                routeMode: props.roadUserType,
              },
            },
          });
          console.log("setFetchNewRoute in useSTartDestinoation");
          props.setFetchNewRoute(true);
        }
      } catch (e) {
        console.log(e);
        if (window.error) {
          window.error(
            "Distanz von Start und Target konnte nicht berechnet werden: Start: lng: " +
              props?.start?.lng +
              ", lat: " +
              props?.start?.lat +
              "; Destination: lng: " +
              props?.target?.lng +
              ", lat: " +
              props?.target?.lat
          );
        }
      }
    }
  };

  // Initiales Setzen des Starts und der Destination,
  // bevor Suche oder sonst etwas verwendet wird
  useEffect(() => {
    setInitStart();
    setInitDestination();
  }, []);

  useEffect(setUpRouteFetch, [
    props.start,
    props.target,
    props.currentCampusId,
  ]);

  // todo: diese sollten eigentlich von ray kommen aber
  //  werden erstmal hier gemacht, bis es soweit ist
  return { startDestinationError };
}
