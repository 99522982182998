import RouteItem from "./RouteItem.class";
import Step from "./Step.class";

export default class Leg extends RouteItem {
  static modes = {
    onFoot: "walking",
    mobilityImpaired: "mobilityImpaired",
    bicycle: "cycling",
    car: "driving",
    car_traffic: "driving-traffic",
  };
  constructor(props) {
    super(props.distance, props.duration);
    const [cuSettings, geometry] = props.summary.split("CU_TRENNZEICHEN");
    const info = {};
    cuSettings.split("|").forEach((item) => {
      const spilt = item.split(":");
      info[spilt[0]] = spilt[1];
    });
    if ("CURoute" !== info?.typ)
      throw Error(`Leg can't be processed. Wrong format.`);
    /** @type Step[] */
    this.steps = props.steps.slice(0, -1).map((item) => new Step(item));

    if (!this.steps.length) {
      this.steps.push(new Step(props.steps[0]));
    }

    this.stepPos = 0;
    this.polylines = this.decode(geometry);
    // ist eigentlich garnicht das level sondern nur ein zIndex!
    this.level = +info.zIndex;
    this.isIndoor = "1" === info.isIndoor;
    this.isLive = "1" === info.isLive;
    // mode sagt an, ob ein Leg zum Fahren ist oder nur zum Laufen
    this.mode = info.mode;
    this.waypoints = props.waypoints.filter(
      (w) => w.name !== "buildingEntry" && w.name !== "campusEntry"
    );
    this.pos = props.pos;
    this.legComplete =
      0 === props.steps[props.steps.length - 1].length
        ? ""
        : props.steps[props.steps.length - 1].maneuver;
  }

  /**
   * Unterteilt die Distance des Legs in alle Steps und gibt den noch verfügbaren Bruchteil der
   * Distance des Legs wieder
   * @return {number} Die noch voranliegende Distanz in Metern
   */
  getRemainingDistance() {
    let dividedDistance = super.getDistanceInMeters() / this.steps.length;
    let remainingDistance =
      dividedDistance * (this.steps.length - this.stepPos);
    return remainingDistance;
  }

  /**
   * Unterteilt die Duration des Legs in alle Steps und gibt den noch voranliegenden Bruchteil der
   * Minuten des Legs wieder
   * @return {number} Voranliegende Duration des Legs in Minuten
   */
  getRemainingDuration(trunced = true) {
    let dividedDuration = super.getDurationInMinutes() / this.steps.length;
    let remainingDuration =
      dividedDuration * (this.steps.length - this.stepPos);

    if (trunced) {
      return Math.trunc(remainingDuration);
    } else return remainingDuration;
  }

  getWayPointsAsFeatures(superindex) {
    const features = [];

    this.waypoints.forEach((waypoint, index) => {
      features.push({
        type: "Feature",
        // superindex weil sonst features von verschiedenen legs die gleichen ids hätten
        id: superindex * 10000 + index,
        geometry: {
          type: "Point",
          coordinates: [waypoint.location[0], waypoint.location[1]],
        },
        properties: {
          type: waypoint.name,
          order: this.pos,
          level: this.level,
        },
      });
    });

    return features;
  }

  hasPrevStep() {
    return 0 !== this.stepPos;
  }
  hasStep(stepIndex) {
    return this.steps.length > stepIndex && stepIndex > -1;
  }
  hasNextStep() {
    return this.steps.length > this.stepPos + 1;
  }

  prevStep() {
    this.stepPos = this.stepPos - 1;

    return this;
  }
  setStep(stepIndex) {
    let step = this.steps[stepIndex];
    if (!step) {
      console.warn("Es gibt keinen step mit dem Index: ", stepIndex);
      return;
    }
    this.stepPos = stepIndex;

    return this;
  }
  nextStep() {
    this.stepPos = this.stepPos + 1;

    return this;
  }
  isOnLastStep() {
    return this.stepPos === this.steps.length - 1;
  }

  getLineToStep(stepIndex) {
    let stepLine = [];
    this.steps.forEach((step, i) => {
      if (i <= stepIndex) {
        stepLine = stepLine.concat(step.stepLine);
      }
    });
    return stepLine;
  }
  getLastStep() {
    if (!this.steps.length) return null;

    return this.steps[this.steps.length - 1];
  }
  getCurrentStep() {
    return this.steps[this.stepPos];
  }

  setLastStep() {
    this.stepPos = this.steps.length - 1;
  }
}
