import { connect } from "react-redux";
import CreateAppointment from "../../appointment/appointment.component.jsx";

/**
 * Hier kommen alle main MapUIOverlays rein. Also Screens, die über eine Page eingeblendet werden.
 * Hier kann man zentral auch eine Anzeige Hierarchie (falls mehere angezeigt werden) machen.
 * @param showCreateAppointment
 * @return {JSX.Element|null}
 * @constructor
 */
function MapUIOverlays({ showCreateAppointment, history }) {
  if (showCreateAppointment) {
    return <CreateAppointment history={history} />;
  } else return null;
}

const mapPropsToState = (state) => ({
  showCreateAppointment: state.appointment.showCreateAppointment,
});

export default connect(mapPropsToState, null)(MapUIOverlays);
