import classes from "./checkbox.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function StandardCheckbox({ checked, className, disabled }) {
  return (
    <div
      style={{ flexShrink: 0 }}
      className={`${classes.standardCheckbox} standardCheckbox ${
        checked
          ? classes["standardCheckbox--active"] + " standardCheckbox--active"
          : ""
      }`}
    >
      <FontAwesomeIcon icon={["fal", "check"]} />
    </div>
  );
}
