import { layerIds } from "../../variables/layerIds.js";
import {
  setLayerFilters,
  zoomAddressesEnd,
  zoomAddressesStart,
  zoomSymbolsEnd,
  zoomSymbolsStart,
} from "../filter.map.js";

/**
 * Filtert Einrichtungs- und POISubstitutes (Adressen) Layer
 * @param allFilters
 * @param options
 */
export function filterFacilitySymbols(allFilters = [], options = {}) {
  // const currentCampus = CAMPUS_COLLECTION.getCurrentCampus();
  // const showOutdoor = currentCampus.showOutdoorEinrichtungenOnMap;

  const zoomFacilities = [
    "all",
    ["<=", ["zoom"], zoomSymbolsEnd],
    [">=", ["zoom"], zoomSymbolsStart],
  ];

  const zoomAddresses = [
    "all",
    ["<=", ["zoom"], zoomAddressesEnd],
    [">=", ["zoom"], zoomAddressesStart],
  ];

  // Einrichtungen
  const newFilterAllExpression = [
    ...zoomFacilities,
    ["!=", ["get", "hasPoiSymbol"], true],
  ];
  setLayerFilters(
    [layerIds.texts.einrichtungen],
    [
      ...allFilters,
      newFilterAllExpression,
      // showOutdoor ? true : EXPRESSIONS.misc.isIndoor,
    ]
  );

  // Adressen - Bisher nur im Charité zusehen
  setLayerFilters(
    [layerIds.nodes.address.symbol],
    [
      ...allFilters,
      zoomAddresses,
      // EXPRESSIONS.misc.isOutdoor,
      // EXPRESSIONS.type.adresse,
    ]
  );
}
