export default function poiMarker(color = "black", outlineColor = "white") {
  let container = document.createElement("div");
  container.innerHTML = `<svg  id="efgfi7iwx7re1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
           viewBox="0 0 70 70" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
       <defs>
         <path stroke="white" stroke-width="1" id="efgfi7iwx7re2"
               d="M35,15.300800C45.879568,15.300800,54.699200,24.120432,54.699200,35C54.699200,45.879568,45.879568,54.699200,35,54.699200L17.489600,54.699200C16.280759,54.699200,15.300800,53.719241,15.300800,52.510400L15.300800,35C15.300800,24.120432,24.120432,15.300800,35,15.300800Z"/>
         <rect id="efgfi7iwx7re3" width="36" height="36" rx="14" ry="14"/>
         <circle id="efgfi7iwx7re4" r="15.897637"/>
         <path id="efgfi7iwx7re5"
               d="M14.669721,2.619593L14.669721,2.095674C14.669721,0.938142,13.731580,0,12.574047,0L3.143512,0C1.407376,0,0,1.407376,0,3.143512L0,11.526210C0,13.262345,1.407376,14.669721,3.143512,14.669721L14.669721,14.669721C15.827254,14.669721,16.765396,13.731580,16.765396,12.574047L16.765396,4.715268C16.765396,3.557735,15.827254,2.619593,14.669721,2.619593ZM15.193640,12.574047C15.193640,12.862857,14.958531,13.097965,14.669721,13.097965L3.143512,13.097965C2.276754,13.097965,1.571756,12.392967,1.571756,11.526210L1.571756,3.143512C1.571756,2.276754,2.276754,1.571756,3.143512,1.571756L12.574047,1.571756C12.862857,1.571756,13.097965,1.806864,13.097965,2.095674L13.097965,3.143512L3.667430,3.143512C3.377965,3.143512,3.143512,3.377965,3.143512,3.667430C3.143512,3.956895,3.377965,4.191349,3.667430,4.191349L14.669721,4.191349C14.958531,4.191349,15.193640,4.426457,15.193640,4.715268L15.193640,12.574047ZM12.574047,7.334861C11.995444,7.334861,11.526210,7.804095,11.526210,8.382698C11.526210,8.961300,11.995444,9.430535,12.574047,9.430535C13.152649,9.430535,13.621884,8.961300,13.621884,8.382698C13.621884,7.804095,13.152649,7.334861,12.574047,7.334861Z"/>
         <filter id="efgfi7iwx7re6-filter" x="-400%" width="600%" y="-400%" height="600%">
           <feGaussianBlur id="efgfi7iwx7re6-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="4,4"/>
           <feOffset id="efgfi7iwx7re6-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp"/>
           <feFlood id="efgfi7iwx7re6-filter-drop-shadow-0-flood" flood-color="rgba(0,0,0,0.09)"/>
           <feComposite id="efgfi7iwx7re6-filter-drop-shadow-0-composite" operator="in" in2="tmp"/>
           <feMerge id="efgfi7iwx7re6-filter-drop-shadow-0-merge">
             <feMergeNode id="efgfi7iwx7re6-filter-drop-shadow-0-merge-node-1"/>
             <feMergeNode id="efgfi7iwx7re6-filter-drop-shadow-0-merge-node-2" in="SourceGraphic"/>
           </feMerge>
         </filter>
         <filter id="efgfi7iwx7re9-filter" x="-400%" width="600%" y="-400%" height="600%">
           <feGaussianBlur id="efgfi7iwx7re9-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="0,0"/>
           <feOffset id="efgfi7iwx7re9-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp"/>
           <feFlood id="efgfi7iwx7re9-filter-drop-shadow-0-flood" flood-color="rgb(0,0,0)"/>
           <feComposite id="efgfi7iwx7re9-filter-drop-shadow-0-composite" operator="in" in2="tmp"/>
           <feMerge id="efgfi7iwx7re9-filter-drop-shadow-0-merge">
             <feMergeNode id="efgfi7iwx7re9-filter-drop-shadow-0-merge-node-1"/>
             <feMergeNode id="efgfi7iwx7re9-filter-drop-shadow-0-merge-node-2" in="SourceGraphic"/>
           </feMerge>
         </filter>
       </defs>
       <g id="efgfi7iwx7re6" filter="url(#efgfi7iwx7re6-filter)">
         <g id="efgfi7iwx7re7"
            transform="matrix(0.78306182747238 -0.76615121226941 0.76615121226941 0.78306182747238 -14.49747468305833 35)">
           <g id="efgfi7iwx7re8_ts" transform="translate(32.403658,31.703885) scale(0.500000,0.500000)">
             <use id="efgfi7iwx7re8" width="39.398399" height="39.398399" xlink:href="#efgfi7iwx7re2"
                  transform="translate(-35.217350,-35.212656)" fill="${color}" fill-rule="evenodd"
                  stroke="none" stroke-width="1"/>
           </g>
         </g>
         <g id="efgfi7iwx7re9" mask="url(#efgfi7iwx7re10)" filter="url(#efgfi7iwx7re9-filter)">
           <mask id="efgfi7iwx7re10">
             <use id="efgfi7iwx7re11" width="39.398399" height="39.398399" xlink:href="#efgfi7iwx7re2"
                  fill="rgb(255,255,255)" fill-rule="evenodd" stroke="none" stroke-width="1"/>
           </mask>
           <g id="efgfi7iwx7re12" display="none">
             <g id="efgfi7iwx7re13_ts" transform="translate(35,35) scale(1,1)">
               <use id="efgfi7iwx7re13" width="36" height="36" xlink:href="#efgfi7iwx7re3"
                    transform="translate(-18,-18)" fill="rgb(255,255,255)" fill-rule="evenodd" stroke="none"
                    stroke-width="1"/>
             </g>
           </g>
           <g id="efgfi7iwx7re14">
             <g id="efgfi7iwx7re15" transform="matrix(1 0 0 1 19 19)">
               <g id="efgfi7iwx7re16_ts" transform="translate(15.897637,15.897637) scale(1,1)">
                 <use id="efgfi7iwx7re16" width="31.795275" height="31.795275" xlink:href="#efgfi7iwx7re4"
                      transform="translate(0,0)" fill="rgb(255,255,255)" fill-rule="evenodd" stroke="none"
                      stroke-width="1"/>
               </g>
             </g>
           </g>
         </g>
       </g>
     </svg>`;
  return container;
}
