import { getDeepestObjectValues } from "../../../../functions/helper/helpers.functions.js";
import { map } from "../map.sideEffects/useMap.sideEffects.js";

export const layerIds = {
  nodes: {
    // Point Node
    circleShadows: "knotenCircleShadowLayer",
    circles: "knotenCirclesLayer",
    symbols: "knotenSymbolsLayer",
    outerCircles: "KnotenSymbolCirclesLayer",
    debug_beacon_positions: {
      circles: "debug_beacon_positions_circles",
      circles_raw: "debug_beacon_positions_circles_raw",
      symbols: "debug_beacon_positions_symbols",
      symbols_raw: "debug_beacon_positions_symbols_raw",
    },
    // navigation symbols
    route: {
      whiteBg: "routeSymbolWhiteBackground",
      coloredBg: "routeSymbolColoredBackground",
      symbol: "routeSymbol",
      startCircle: "routeStartCircle",
    },
    // Die Symbole mit shortnames die statt den Einrichtungsnamen erscheinen
    address: {
      symbol: "poisubstitutesinnercircle",
    },
    position: {
      dotDebug: "currentPositionDotDebug",
      debug_real_position: "currentPositionDotDebug2",
      debug_real_position_symbol: "currentPositionDotDebug2_symbol",
      dotDebug3: "currentPositionDotDebug3",
      dotDebug3_symbol: "currentPositionDotDebug3_symbol",
      dot: "currentUserPositionDot",
      radar: "currentUserPositionRadar",
      arc: "currentUserPositionArc",
    },
    poiChildren: "poiChildren",
    campus: {
      symbol: "campusMarkersFromFarAway",
    },
  },
  interiorGeoJSONs: {
    ground: "interiorGround",
    floors: "interiorFloors",
    rooms: "interiorGeorooms",
    glassRooms: "interiorGeorooms_classified",
    outline: "interiorGeooutline",
  },
  exteriorGeoJSONs: {
    streets: "exteriorStreets",
  },
  buildings: {
    text: "buildingTextLayer",
    mapbox: "buildingsLayerMapbox",
    mapboxExtrusion: "building-extrusion",
  },
  texts: {
    einrichtungen: "textEinrichtungsLayer",
    rooms: "textRoomsLayer",
  },
  kanten: {
    navigation_line: "navigationsKantenLayer_line",
    navigation_dash: "navigationsKantenLayer_dash",
    halo: "navigationsKantenHaloLayer",
  },
  arrow: {
    head: "kantenArrowHead",
    head_background: "kantenArrowHead_background",
    kanten: "kantenArrowKanten",
    kanten_background: "kantenArrowKanten_background",
  },
  polygons: {
    simple: "polygonsMain",
  },
};
/**
 * Gibt alle, wirklich alle, LayerIds als Array zurück
 * @param {mapboxgl} map - Zugabe von Map sorgt dafür, dass NUR layer ausgegeben werden, die wirklich in der Map sind!
 * @return {[]}
 */
export const getLayerIds = (subLayers) => {
  let layerIdsArray = getDeepestObjectValues(subLayers);
  if (map) {
    layerIdsArray = layerIdsArray.filter((layerId) => {
      return map.getLayer(layerId);
    });
  }
  return layerIdsArray;
};
