import { CUSTOMIZABLE_VALUES } from "../../../../components/mapUi/mapbox/variables/CUSTOMIZABLE_VALUES.js";

export const pulsingDot = {
  size: 100,
  width: 100,
  height: 100,
  heading: 0,
  accuracy: 0,
  canvas: null,
  opacity: 1,
  color: "rgb(7,225,190)",
  data: new Uint8Array(100 * 100 * 4),
  context: null,

  setSize: function (size) {
    this.size = size;
  },
  setHeading: function (heading) {
    this.heading = heading;
  },
  setAccuracy: function (accuracy) {
    this.accuracy = accuracy;
  },
  setColor: function (color) {
    this.color = color;
  },
  setOpacity: function (opacity) {
    this.opacity = opacity;
  },

  // get rendering context for the map canvas when layer is added to the map
  onAdd: function () {
    this.canvas = document.createElement("canvas");
    this.canvas.width = this.width;
    this.canvas.height = this.height;
    this.context = this.canvas.getContext("2d");
  },

  // called once before every frame where the icon will be used
  render: function () {
    const duration = 2000;
    const t = (performance.now() % duration) / duration;

    const radius = (this.size / 2) * 0.3;

    // draw inner circle
    this.context.clearRect(0, 0, this.width, this.width);
    this.context.beginPath();
    this.context.arc(this.width / 2, this.width / 2, radius, 0, Math.PI * 2);
    this.context.fillStyle = this.color;
    this.context.globalAlpha = this.opacity;
    this.context.strokeStyle = "white";
    this.context.lineWidth = 2 + 4 * (1 - t);
    this.context.fill();
    this.context.stroke();

    // update this image's data with data from the canvas
    this.data = this.context.getImageData(0, 0, this.width, this.height).data;

    // continuously repaint the map, resulting in the smooth animation of the dot
    // map.triggerRepaint();

    // return `true` to let the map know that the image was updated
    return true;
  },
};

export const arc = {
  size: 200,
  width: 200,
  height: 200,
  heading: 0,
  accuracy: 0,
  canvas: null,
  color: "#77aaaa",
  opacity: "99",
  data: new Uint8Array(200 * 200 * 4),
  context: null,

  setColor: function (color) {
    this.color = color;
  },
  setSize: function (size) {
    this.size = size;
    this.width = size;
    this.height = size;
    this.data = new Uint8Array(size * size * 4);
  },
  setHeading: function (heading) {
    this.heading = heading;
    this.update();
  },
  /**
   * @param opacity - Hexzahlen bis 255, 00 -> ff
   */
  setOpacity: function (opacity) {
    this.opacity = opacity;
    this.update();
  },

  // get rendering context for the map canvas when layer is added to the map
  onAdd: function () {
    this.canvas = document.createElement("canvas");
    this.canvas.width = this.width;
    this.canvas.height = this.height;
    this.context = this.canvas.getContext("2d");

    this.update();
  },
  update: function () {
    this.context.clearRect(0, 0, this.width, this.width);

    // gradient opacity update
    let my_gradient = this.context.createLinearGradient(
      this.width / 2,
      this.width / 2,
      this.width / 2,
      0
    );
    my_gradient.addColorStop(0, CUSTOMIZABLE_VALUES.userPuck_arc + "FF");
    my_gradient.addColorStop(0.25, CUSTOMIZABLE_VALUES.userPuck_arc + "BA");
    my_gradient.addColorStop(0.5, CUSTOMIZABLE_VALUES.userPuck_arc + "40");
    my_gradient.addColorStop(0.9, CUSTOMIZABLE_VALUES.userPuck_arc + "00");
    this.color = my_gradient;
    this.context.fillStyle = this.color;

    // direction Dreieck
    this.context.beginPath();
    let difference = 30;
    this.context.moveTo(this.width / 2, this.width / 2);

    for (let i = 0; i <= this.width; i = i + 5) {
      // y ist nur eine Parabelfunktion um die Curve zu bilden
      this.context.lineTo(i, difference - (-(1 / 300) * i * i + (2 / 3) * i));
    }
    this.context.fill();
    // update this image's data with data from the canvas
    this.data = this.context.getImageData(0, 0, this.width, this.height).data;
  },
  render: function () {
    return this.data;
  },
};
