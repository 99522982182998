import { StatisticLogger } from "@catchupapplications/utilities";
import { useEffect, useState } from "react";
import {
  isAndroid,
  isIOS,
  isMacOs,
  isMobile,
  isWindows,
} from "react-device-detect";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { sessionId as clientSessionKey } from "../../reduxStore/reducers/main";
import { setProperties } from "../../reduxStore/actions/settings";
import { piKey } from "../../app/app.sideEffects/useURLParams.sideEffects";

/** @type StatisticLogger */
export let statisticLogger = new StatisticLogger(
  process.env.REACT_APP_STATISTIC_UPLOAD
);

function StatisticLog(props) {
  const [serverSessionKey, setServerSessionKey] = useState(null);
  const [statisticLoggerSet, setStatisticLoggerSet] = useState(false);
  const [initMetaData, setInitMetaData] = useState(false);

  const globalParams = new URLSearchParams(props.location.search);
  const appVersion = globalParams.get("appVersion");

  const getSessionKeyFromServer = () => {
    if (!initMetaData || !piKey) return;

    fetch(process.env.REACT_APP_SESSIONKEY, {
      method: "POST",
      cache: "no-cache",
      body: JSON.stringify({
        piKey: piKey,
        meta: statisticLogger._meta,
        data: [{ url: props.location.search }],
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (json.sessionKey) {
          setServerSessionKey(json.sessionKey);
        } else {
          console.warn("sessionkey for logging could not be fetched");
        }
      })
      .catch((e) => {
        console.warn("sessionkey for logger could not be retrieved", e);
      });
  };

  const assignSessionKeys = () => {
    if (serverSessionKey) {
      statisticLogger.serverSessionKey = serverSessionKey;
    }
    if (clientSessionKey) {
      statisticLogger.clientSessionKey = clientSessionKey;
    }
    // true, because the clientkey is always available
    setStatisticLoggerSet(true);
  };

  const renewSessionKeysForNewTerminalUser = () => {
    if (!serverSessionKey || !props.isTerminal || props.terminalIsIdle) return;
    // if a user stops using the terminal, it will go into idle mode
    // once it leaves idle mode, a new serverSessionKey is created
    // to indicate another user
    getSessionKeyFromServer();
    console.log("renew sessionkeys");
  };

  const addFurtherData = () => {
    if (!statisticLogger || !statisticLoggerSet) return;

    let device = null;
    let operatingSystem = null;

    if (props.isTerminal) {
      device = "Terminal";
    } else if (props.isApp) {
      device = "App";
    } else if (isMobile) {
      device = "Mobile";
    } else {
      device = "Desktop";
    }

    if (isIOS) {
      operatingSystem = "iOS";
    } else if (isMacOs) {
      operatingSystem = "macOs";
    } else if (isAndroid) {
      operatingSystem = "Android";
    } else if (isWindows) {
      operatingSystem = "Windows";
    } else {
      operatingSystem = "Unknown";
    }

    statisticLogger.meta = {
      appVersion: process.env.REACT_APP_APP_VERSION_NAME ?? null,
      device: device ?? null,
      nativeVersion: appVersion ?? null,
      operatingSystem: operatingSystem ?? null,
      isScreenReaderEnabled: false,
      userGroup: props.properties.routing.userGroup,
    };
    statisticLogger.piKey = piKey;

    setInitMetaData(true);
  };

  useEffect(getSessionKeyFromServer, [initMetaData]);
  useEffect(assignSessionKeys, [serverSessionKey, clientSessionKey]);
  useEffect(renewSessionKeysForNewTerminalUser, [
    props.piKeyState,
    props.terminalIsIdle,
    props.isTerminal,
  ]);
  useEffect(addFurtherData, [
    statisticLoggerSet,
    props.properties.routing.userGroup,
    props.isApp,
    props.isTerminal,
  ]);

  return null;
}
const mapStateToProps = (state) => ({
  piKeyState: state.main.piKeyState,
  isTerminal: state.main.isTerminal,
  terminalIsIdle: state.main.terminalIsIdle,
  isApp: state.main.isApp,
  serverDataLoaded: state.main.serverDataLoaded,
  properties: state.settings,
});
const mapDispatchToProps = (dispatch) => ({
  setProperties: (properties) => dispatch(setProperties(properties)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StatisticLog)
);
