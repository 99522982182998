import { bearing, bbox, lineString, nearestPointOnLine } from "@turf/turf";
import { setFeatures } from "../../mapbox/functions/drawer.map.js";
import { map } from "../../mapbox/map.sideEffects/useMap.sideEffects.js";
import { sourceIds } from "../../mapbox/variables/sourceIds.js";
import getAngle from "@turf/rhumb-bearing";
import { useEffect, useRef } from "react";
import {
  setCameraPosition,
  stableEaseTo,
} from "../../mapbox/functions/interactivity.map.js";
import { Result } from "@catchupapplications/indoor-navigation/dist/types.js";
import UserLocation from "../../../../functions/classes/location.class.js";

/**
 * Kümmert sich nur darum den Pfeil auf die Karte zu setzen (rein graphisch)
 * @param {Leg}currentLeg
 * @param {Step}currentStep
 * @param {Leg} nextLeg
 * @param oneStepPerLeg
 * @param userMovesMap
 * @param {Route} currentRoute
 * @param {Result} liveNavigationInformation
 * @param {UserLocation} locationObject
 * @param mapPadding
 * @param setCurrentFloorNumber
 * @param navigationMode
 * @param setMapPadding
 * @param isTerminal
 * @param terminalBearing
 */
export function useArrowAndCam(
  currentLeg,
  currentStep,
  nextLeg,
  oneStepPerLeg,
  userMovesMap,
  currentRoute,
  locationObject,
  mapPadding,
  setCurrentFloorNumber,
  liveNavigationInformation,
  navigationMode,
  setMapPadding,
  isTerminal,
  terminalBearing
) {
  const lastSpeeds = useRef([]);
  const averageUserSpeed = useRef(0);

  const setAverageSpeed = (speed) => {
    const judgementLength = 15;
    let averageSpeed = 0;
    lastSpeeds.current.push(speed);

    if (lastSpeeds.current.length > judgementLength) {
      lastSpeeds.current.shift();
    }
    lastSpeeds.current.forEach((s) => {
      averageSpeed += s;
    });
    averageSpeed = averageSpeed / lastSpeeds.current.length;
    averageUserSpeed.current = averageSpeed;
  };
  /** Setzt die Pfeile auf der Route */
  const setStepArrow = () => {
    // Nodes des Arrows holen
    let allArrowNodes;

    if (oneStepPerLeg) {
      // wenn im Terminal -> Ganze Legs statt steps kontrollieren
      allArrowNodes = currentRoute.getCurrentLegArrowNodes();
    } else {
      allArrowNodes = currentRoute.getCurrentStepArrowNodes().allArrowNodes;
    }

    // Arrow Head Roation Coordinates definieren
    let arrowHead = allArrowNodes[allArrowNodes.length - 1];
    let arrowTail;
    // todo: dieser Abfang hier sollte eigentlich nicht sein! Das ist wenn ein Leg einfach mal nur einen Step mit nur einem Node hat!
    if (allArrowNodes.length < 2) {
      arrowTail = nextLeg.steps[0].node;
    } else {
      arrowTail = allArrowNodes[allArrowNodes.length - 2];
    }

    let iconRotation = bearing(arrowTail, arrowHead);

    let headFeature = {
      type: "Feature",
      id: 0,
      properties: {
        bearing: iconRotation,
        level: currentLeg.level,
      },
      geometry: {
        type: "Point",
        coordinates: allArrowNodes[allArrowNodes.length - 1],
      },
    };

    // Arrow Body Feature Coordinates definieren
    let lineFeature = {
      type: "Feature",
      id: 0,
      geometry: {
        type: "LineString",
        // alles in ein array
        coordinates: allArrowNodes,
      },
      properties: {
        level: currentLeg.level,
      },
    };

    // Arrow Body setzen
    setFeatures({
      map: map,
      sourceId: sourceIds.arrowKanten,
      features: [lineFeature],
    });
    // Arrow Head setzen
    setFeatures({
      map: map,
      sourceId: sourceIds.arrowHead,
      features: [headFeature],
    });
  };

  const setSBSCamera = () => {
    let customBearing;
    if (isTerminal && terminalBearing != null && currentRoute.legPos === 0) {
      customBearing = terminalBearing;
    }

    setCameraToStep(
      currentRoute,
      setCurrentFloorNumber,
      {
        mapPadding,
        top: 150,
      },
      { oneStepPerLeg: oneStepPerLeg, customBearing: customBearing }
    );
  };
  const setLiveCamera = (coordinates) => {
    if (userMovesMap || !locationObject.isSet) return;

    const stepLine = currentRoute.getCurrentStep().stepLine;
    const snapped = liveNavigationInformation.locationIsSnapped;
    let bearing;
    let zoom = 18;
    let maxZoom;
    let minZoom;
    let easing = (x) => x;
    let center;

    const setZoom = (newCameraTransform) => {
      if (locationObject.positionType === UserLocation.position_types.indoor) {
        // indoor darf näher gezoomt werden
        maxZoom = 19.75;
        minZoom = 18.75;
      } else {
        maxZoom = 19;
        minZoom = 16;
      }

      if (snapped && newCameraTransform) {
        const maxConsiderationSpeed = 18; // alles über dieser speed ist wayne
        const availableZoomSpan = maxZoom - minZoom;
        const considerationSpeed = Math.min(
          maxConsiderationSpeed,
          averageUserSpeed.current
        );
        const zoomToReduce =
          availableZoomSpan * (considerationSpeed / maxConsiderationSpeed);
        zoom = maxZoom - zoomToReduce;
        // zoom = Math.max(maxZoom, Math.min(newCameraTransform.zoom, 19));
      } else if (newCameraTransform) {
        // wenn unsnapped
        zoom =
          Math.min(Math.max(minZoom, newCameraTransform.zoom), maxZoom) - 0.5;
      }
    };

    try {
      if (locationObject.cameraBearing != null && snapped) {
        bearing = locationObject.cameraBearing;
      } else if (
        coordinates &&
        coordinates[0] &&
        coordinates[1] &&
        coordinates[0][1] !== coordinates[1][1] &&
        snapped
      ) {
        // wenn die bearing koordinaten gleich sind würde das bearing in den norden zeigen
        bearing = getAngle(
          [coordinates[0][1], coordinates[0][0]],
          [coordinates[1][1], coordinates[1][0]]
        );
      } else {
        // ansonten nimm die nodes von dem ganzen Step (oder drüber hinaus,
        // falls ein step nur einen node hat)
        const arrowNodes = currentRoute.getCurrentStepArrowNodes();
        bearing = getAngle(arrowNodes.prevNodes[0], arrowNodes.currentNode);
      }
      let boundingLine;
      let currentStepLine = lineString(stepLine);
      if (snapped) {
        boundingLine = currentStepLine;
      } else {
        let snappedPoint = nearestPointOnLine(currentStepLine, [
          locationObject.lng,
          locationObject.lat,
        ]);
        boundingLine = lineString([
          snappedPoint.geometry.coordinates,
          [locationObject.lng, locationObject.lat],
        ]);
      }

      let boundingBox = bbox(boundingLine);
      let newCameraTransform = map.cameraForBounds(boundingBox);

      setZoom(newCameraTransform);

      if (!snapped && newCameraTransform) {
        center = newCameraTransform.center;
      } else {
        center = [locationObject.lng, locationObject.lat];
      }

      stableEaseTo({
        center: center,
        bearing: bearing,
        zoom: zoom,
        easing: easing,
        duration:
          locationObject.cameraDuration && snapped
            ? locationObject.cameraDuration
            : 1500,
        padding: mapPadding,
        pitch: snapped ? 40 : 20,
      });
    } catch (e) {
      console.error("Es gab einen Fehler bei der Kamerafahrt", e);
    }
  };

  // Live Camera orientiert sich immer nach der User Position
  useEffect(() => {
    let coordinates = liveNavigationInformation.snapLine?.geometry.coordinates;
    if (navigationMode === "live") {
      setLiveCamera(coordinates);
    }
  }, [locationObject.lat, userMovesMap]);
  useEffect(() => {
    setAverageSpeed(locationObject.speed);
  }, [locationObject.speed]);

  // SBS Camera orientiert sich an steps
  useEffect(() => {
    setStepArrow();

    if (navigationMode === "sbs") {
      setSBSCamera();
    }
  }, [currentLeg, currentStep, navigationMode]);

  useEffect(() => {
    if (navigationMode === "live") {
      // Das wird gemacht, damit der LocationPuck unter der Mitte
      // des Displays angezeigt wird
      setMapPadding(
        {
          top: window.innerHeight * 0.3,
        },
        true
      );
    }
  }, [navigationMode]);
}

/**
 * Setzt die Camera so, dass sie genau auf die momentanen Pfeilnodes zeigt
 * @param props
 * @param currentRoute
 * @param setCurrentFloorNumber
 * @param mapPadding
 */
export function setCameraToStep(
  currentRoute,
  setCurrentFloorNumber,
  mapPadding,
  { oneStepPerLeg = false, customBearing }
) {
  let arrowNodes = [];
  let viewableStepline;
  // Weil die Arrows manchmal über den Step/Leg hinausragen, werden diese als spitze für die kamera verwendet
  if (oneStepPerLeg) {
    arrowNodes = currentRoute.getCurrentLegArrowNodes();
    // es soll der ganze Leg zu sehen sein!
    viewableStepline = currentRoute.getCurrentLeg().polylines;
    if (customBearing == null) {
      // im Terminal modus muss der neue Leg immer genau in die Richtung zeigen,
      // in der man drauf kommt (z.b. Aufzug aussteigen -> kamera muss so ausgerichtet sein, wie man aus dem aufzug guckt)
      if (viewableStepline?.[0] && viewableStepline?.[1]) {
        customBearing = bearing(viewableStepline?.[0], viewableStepline?.[1]);
      }
    }
  } else {
    arrowNodes = currentRoute.getCurrentStepArrowNodes().allArrowNodes;
    viewableStepline = currentRoute.getCurrentStep().stepLine;
  }

  const nextStep = currentRoute.getNextStep();
  // Camera vom ersten Node des currentStep bis zur Pfeilspitze
  if (nextStep && nextStep.node) {
    setCameraPosition(
      viewableStepline[0],
      arrowNodes[arrowNodes.length - 1],
      mapPadding,
      undefined,
      undefined,
      { customBearing: customBearing }
    );
  } else {
    // Beim letzten "halbpfeil" wird ganz nach an die letzten Nodes gezoomt
    setCameraPosition(
      viewableStepline[0],
      arrowNodes[arrowNodes.length - 1],
      mapPadding,
      undefined,
      undefined,
      { customBearing: customBearing }
    );
  }

  // Etage wechseln, falls man in der falschen ist. Das muss beispielsweise gemacht werden
  // wenn von einer Action Bubble auf den momentanen Step gegangen wird!
  setCurrentFloorNumber(currentRoute.legs[currentRoute.legPos].level, true);
}
