import React from "react";
import style from "./roundedIcon.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

export default function StandardButton({
  prefixIcon,
  postfixIcon,
  handleClick,
  children,
  icon,
  pulsing = false,
  title = "",
  buttonClasses = "",
  textClasses = "",
  iconRotation,
  iconColor = "",
  noFlex = false,
  iconClasses = "",
  disabled,
  iconSpinning = false,
  loadingWhenDisabled = false,
  ariaLabel,
  ariaSelected,
  role = "button",
}) {
  const disabledAndLoading = disabled && loadingWhenDisabled;

  return (
    <button
      aria-selected={ariaSelected}
      className={`normalButton ${buttonClasses} ${
        pulsing ? [style.pulsing, style.pulsing11].join(" ") : ""
      }`}
      onClick={handleClick}
      title={title}
      disabled={disabled}
      role={role}
      aria-label={ariaLabel}
    >
      {!disabledAndLoading ? (
        <>
          <div
            className={`${!noFlex ? "d-flex flex-nowrap" : ""} ${iconClasses}`}
          >
            {prefixIcon && (
              <FontAwesomeIcon
                icon={prefixIcon}
                className={`mr-1 ${iconColor}`}
              />
            )}
            {icon && (
              <FontAwesomeIcon
                spin={iconSpinning}
                rotation={iconRotation}
                icon={icon}
                style={{ flexShrink: 0 }}
                className={`mr-3  ${iconColor}`}
              />
            )}
          </div>
          <div className={textClasses}>{children}</div>
          {postfixIcon ? (
            <FontAwesomeIcon
              style={{ flexShrink: 0 }}
              icon={postfixIcon}
              className={`ml-auto ${iconColor}`}
            />
          ) : (
            ""
          )}
        </>
      ) : (
        <div className={"w-100 mr-3 ml-3 d-flex"}>
          <FontAwesomeIcon
            icon={"circle"}
            className={`mr-3 ${style.triplePulse} ${style.one}`}
          />
          <FontAwesomeIcon
            icon={"circle"}
            className={`mr-3 ${style.triplePulse} ${style.two}`}
          />
          <FontAwesomeIcon
            icon={"circle"}
            className={`${style.triplePulse} ${style.three}`}
          />
        </div>
      )}
    </button>
  );
}

StandardButton.propTypes = {
  buttonClasses: PropTypes.string,
  children: PropTypes.any,
  disabled: PropTypes.any,
  handleClick: PropTypes.any,
  icon: PropTypes.any,
  iconClasses: PropTypes.string,
  iconColor: PropTypes.string,
  iconRotation: PropTypes.any,
  iconSpinning: PropTypes.bool,
  noFlex: PropTypes.bool,
  postfixIcon: PropTypes.any,
  prefixIcon: PropTypes.any,
  pulsing: PropTypes.bool,
  textClasses: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};
