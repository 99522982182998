import { connect } from "react-redux";
import * as actionToast from "../../reduxStore/actions/toasts";
import * as actionNavigation from "../../reduxStore/actions/navigation";
import { isAndroid, isIOS } from "react-device-detect";
import React, { useEffect, useRef, useState } from "react";
import { Switch, Redirect } from "react-router-dom";
import RouteWithSubRoutes, {
  getQueryParams,
} from "../../app/RouteWithSubRoutes";
import SetURLParams from "../setURLParams/SetURLParams";
import {
  CAMPUS_COLLECTION,
  LOCATION_MANAGER,
  setPage,
} from "../../reduxStore/actions/main";
import MapOverlays from "../elements/Overlays/mapOverlays.component.jsx";
import { setProperties } from "../../reduxStore/actions/settings.js";
import Toast from "../../functions/classes/toast.class.js";
import UserLocation from "../../functions/classes/location.class.js";
import {isApp, pageNames} from "../../reduxStore/reducers/main.js";
import LocationManagerContainer from "./locationManagerContainer";
import {
  cancelDataScanner,
  checkIsDataScannerAvailable,
} from "../nativeAppInterface/NativeAppInterface";

/**
 * Dieses Component ist lediglich der Overlay über der Map für die
 * einzelnen klickbaren Elemente
 * @returns {JSX.Element}
 * @constructor
 */
export function MapUI(props) {
  useEffect(() => {
    if(isApp) return;
    (async () => {
      const permissionStatus = await navigator.permissions.query({
        name: "geolocation",
      });

      if (permissionStatus.state === "granted")
        navigator.geolocation.watchPosition((position) => {
          window.didUpdateLocation(
            position.coords.latitude,
            position.coords.longitude,
            position.coords.accuracy
          );
        });
    })();
  }, []);

  useEffect(() => {
    checkIsDataScannerAvailable();
    return () => {
      cancelDataScanner();
    };
  }, []);

  const compareURLCampusKeysWithLoadedData = () => {
    if (
      !props.serverDataLoaded ||
      !props.mapLoaded ||
      props.urlCampusKeys == null
    )
      return;
    // todo: ich glaube das muss in ein Effect, weil das muss ja ned jedes mal aufs
    //  neue aufgerufen werden
    const globalParams = new URLSearchParams(window.location.search);
    const neededCampuses = [];

    if (props.urlCampusKeys.length) {
      props.urlCampusKeys.forEach((key) => {
        if (!CAMPUS_COLLECTION.getCampusByKey(key)) {
          // one of the campuses from the URL is not loaded
          neededCampuses.push(key);
        }
      });
      if (neededCampuses.length) {
        // if unloaded campuses exist show the loader
        props.history.push(`/${pageNames.loader}/?${globalParams.toString()}`);
      }
    }
  };

  useBrowserGPS(
    props.setLocationPermissionAllowed,
    props.setGPSData,
    props.setUserAllowedGPS,
    props.addToast,
    props.isApp,
    props.userAllowedGPS,
    props.setProperties,
    props.isTerminal,
    props.locationObject
  );

  useEffect(compareURLCampusKeysWithLoadedData, [
    props.serverDataLoaded,
    props.mapLoaded,
    props.urlCampusKeys,
  ]);

  useEffect(() => {
    props.setPage(pageNames.map);
  }, []);

  if (!props.serverDataLoaded || !props.mapLoaded) {
    return null;
  }
  if (
    !props.currentRoute &&
    // todo: falls irgendwas schief einkommentieren
    // props.navigationMode === "sbs" &&
    props.location.pathname.includes(
      `/${pageNames.map}/${pageNames.navigation}`
    )
  ) {
    return (
      <Redirect
        to={`/${pageNames.map}/${pageNames.route}/${getQueryParams()}`}
      />
    );
  }

  /**
   * Das neue Feature wird von den POICards jeweils nach ihrer Animation gesetzt.
   * Genau dann wird es hier unmounted und die BigCard gemounted
   */

  return (
    <>
      <LocationManagerContainer />
      <Switch>
        {props.routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
        <Redirect
          to={`/${pageNames.map}/${pageNames.info}/${getQueryParams()}`}
        />
      </Switch>
      <MapOverlays history={props.history} />
      <SetURLParams />
    </>
  );
}

/**
 * In Case, dass NativeAppInterface nicht verfügbar ist (keine App), wird hier vom Browser abgeprüft.
 * Checkt ob ein toggle wie "setUserAllowedGPS" berechtigt ist, falls nicht wird es auch hier gleich wieder auf false gestellt (wenn keine permission vom gerät da ist)
 * @param props
 */
export let GPS = 0;
let gpsTimeout;

/**
 *
 * @param setLocationPermissionAllowed
 * @param setGPSData
 * @param setUserAllowedGPS
 * @param addToast
 * @param isApp
 * @param userAllowedGPS
 * @param setProperties
 * @param isTerminal
 * @param {UserLocation} locationObject
 */
export function useBrowserGPS(
  setLocationPermissionAllowed,
  setGPSData,
  setUserAllowedGPS,
  addToast,
  isApp,
  userAllowedGPS,
  setProperties,
  isTerminal,
  locationObject
) {
  const positionAccuracies = useRef([]);
  const [positionInaccurate, setPositionInaccurate] = useState(false);

  const setPosition = (gps) => {
    // setLocationPermissionAllowed(true);
    setProperties({ location: { permissionAllowed: true } });
    if (!gps) return;

    // Timeout so umständlich weil das Timeout in der watchPosition api nicht geht
    clearTimeout(gpsTimeout);
    gpsTimeout = setTimeout(
      () => {
        onGeoError({ message: "gps.noGPS" });
      },
      isAndroid || isIOS ? 25000 : 60000
    );
    const { coords } = gps;

    setGPSData({
      lat: coords.latitude,
      lng: coords.longitude,
      accuracy: coords.accuracy,
      timestamp: +new Date(),
    });
  };
  const onGeoError = (e) => {
    if (e.code && e.code === e.PERMISSION_DENIED) {
      setProperties({
        location: { permissionAllowed: false, userAllowed: false },
      });
      return;
    }
  };
  const cancelBrowserLocation = () => {
    navigator.geolocation.clearWatch(GPS);
    GPS = 0;
  };
  const setUpBrowserLocation = () => {
    if (GPS === 0 && navigator?.geolocation) {
      GPS = navigator.geolocation.watchPosition(setPosition, onGeoError);
    }
  };

  const setPositionAccuracies = () => {
    const judgmentLength = 5;
    const threshold = 3;

    if (
      LOCATION_MANAGER &&
      locationObject?.isSet &&
      locationObject.positionType === UserLocation.position_types.outdoor
    ) {
      positionAccuracies.current.push(LOCATION_MANAGER.positionIsExact());
    }
    if (positionAccuracies.current.length > judgmentLength) {
      positionAccuracies.current.shift();
    }

    const positionUnexacts = positionAccuracies.current.filter((p) => !p);

    const tooManyUnexacts = positionUnexacts.length >= threshold;
    const latestPositionInaccurate =
      positionAccuracies.current[positionAccuracies.current.length - 1] ===
      false;

    if (tooManyUnexacts && latestPositionInaccurate) {
      setPositionInaccurate(true);
    } else {
      setPositionInaccurate(false);
    }
  };

  useEffect(() => {
    if (isApp || isApp == null || !navigator || !navigator.geolocation) return;

    if (userAllowedGPS && navigator?.geolocation && !isTerminal) {
      setUpBrowserLocation();
    } else {
      cancelBrowserLocation();
    }

    return () => {
      cancelBrowserLocation();
    };
  }, [isApp, userAllowedGPS, isTerminal]);

  useEffect(() => {
    if (!positionInaccurate || isApp) return;

    addToast(
      new Toast(
        "inaccuratePosition",
        "Position ist ungenau",
        Toast.toastTypes.warning,
        []
      )
    );
  }, [positionInaccurate]);

  useEffect(() => {
    if (isApp) return;

    setPositionAccuracies();
  }, [locationObject.accuracy]);
}

const mapStateToProps = (state) => {
  return {
    isApp: state.main.isApp,
    isTerminal: state.main.isTerminal,
    userAllowedGPS: state.settings.location.userAllowed,
    introComplete: state.settings.introComplete,
    isFullMap: state.main.isFullMap,
    currentFeature: state.main.currentFeature,
    lastFeature: state.main.lastFeature,
    mapClicks: state.map.mapClicks,
    currentPage: state.map.currentPage,
    serverDataLoaded: state.main.serverDataLoaded,
    mapLoaded: state.map.mapLoaded,
    mapPadding: state.map.mapPadding,
    currentRoute: state.route.currentRoute,
    navigationMode: state.route.navigationMode,
    locationObject: state.route.locationObject,
    urlCampusKeys: state.main.urlCampusKeys,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPage: (page) => dispatch(setPage(page)),
    addToast: (object) => dispatch(actionToast.addToast(object)),
    setGPSData: (object) => dispatch(actionNavigation.setGPSData(object)),
    setUserAllowedGPS: (allowed) =>
      dispatch(setProperties({ location: { userAllowed: allowed } })),
    setHasLatestLocation: (value) =>
      dispatch(actionNavigation.setHasLatestLocation(value)),
    setProperties: (object) => dispatch(setProperties(object)),
    setLocationPermissionAllowed: (value) =>
      dispatch(setProperties({ location: { permissionAllowed: value } })),
    setHeading: (heading) => dispatch(actionNavigation.setHeading(heading)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapUI);
