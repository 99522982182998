import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef } from "react";
import useIntl from "react-intl/lib/src/components/useIntl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toggleDisplayDownMode } from "../../../../reduxStore/actions/main";
import style from "./wheelchair.module.scss";

function WheelchairButton({ displayDownMode, toggleDisplayDownMode }) {
  const displayBackToTopTimer = useRef();

  const { formatMessage } = useIntl();

  const handleWheelchairClick = () => {
    toggleDisplayDownMode();
  };

  const resetDisplayDownMode = () => {
    if (displayDownMode) {
      displayBackToTopTimer.current = setTimeout(() => {
        toggleDisplayDownMode();
      }, 15000);
    }
    return () => {
      clearTimeout(displayBackToTopTimer.current);
    };
  };

  useEffect(() => {
    return () => {
      toggleDisplayDownMode(false);
    };
  }, []);

  useEffect(resetDisplayDownMode, [displayDownMode]);

  return (
    <div
      onClick={handleWheelchairClick}
      role="button"
      className={`ml-auto rounded-1 shadow-1 mb-2 d-inline-flex align-items-center bg-dark p-3 pt-2 pb-2 mb-3 mr-3 ${style.wheelchairButton}`}
    >
      <FontAwesomeIcon
        className={"text-white "}
        icon={["fal", "wheelchair"]}
        style={{ fontSize: "1.5rem" }}
      />
      <FontAwesomeIcon
        className={"text-white mr-3"}
        icon={["fal", displayDownMode ? "arrow-up" : "arrow-down"]}
        style={{ fontSize: "1rem" }}
      />
      <span className={"text-white"}>
        {displayDownMode
          ? formatMessage({ id: "terminal.moveDisplayToTop" })
          : formatMessage({ id: "terminal.moveDisplayToBottom" })}
      </span>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    displayDownMode: state.main.displayDownMode,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    toggleDisplayDownMode: (value) => dispatch(toggleDisplayDownMode(value)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WheelchairButton)
);
