import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import * as mainAction from "./reduxStore/actions/main";
import messages_de from "./translations/de.json";
import messages_en from "./translations/en.json";
import messages_cn from "./translations/cn.json";
import messages_fr from "./translations/fr.json";

export const MESSAGES = {
  de: messages_de,
  en: messages_en,
  cn: messages_cn,
  fr: messages_fr,
};

function IntlContext(props) {
  return (
    <IntlProvider
      locale={props.locale}
      defaultLocale="de"
      messages={MESSAGES[props.locale]}
    >
      {props.children}
    </IntlProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    locale: state.main.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLanguage: (key) => dispatch(mainAction.setLanguage(key)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IntlContext);
