import { useEffect } from "react";
import { map } from "../../mapbox/map.sideEffects/useMap.sideEffects.js";
import { sourceIds } from "../../mapbox/variables/sourceIds.js";
import { renderRoute } from "../../route/route.sideEffects/useRoutesAndViews.sideEffects.js";

/**
 * Kümmert sich darum, dass der Floor geändert wird,
 * wenn ein Leg im neuem Stockwerk beginnt. Dies ist der Fall bei SBS
 */
export function useLegChange(
  setCurrentFloorNumber,
  currentRoute,
  currentLeg,
  forceFeatureFilterUpdate
) {
  const currentFloor = currentLeg.level;
  useEffect(() => {
    // Vergangene Legs unrendern
    renderRoute(currentRoute);
    // neue Features auf ghosting überprüfen,
    // da der Mapfilter vor dem rendern der neuen Arrows und Kanten
    // drüber gegangen ist!
    forceFeatureFilterUpdate();

    // Dieses hier ist für SBS nötig, da die LiveNavigation das Stockwerk bereits
    // nach dem zIndex des locationObjects ändert
    setCurrentFloorNumber(currentFloor, true);
  }, [currentLeg]);
}
