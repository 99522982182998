import { useEffect } from "react";

window.cameraPermissionGranted = () => {};
/**
 * Wird im QRCode Reader verwendet
 */
export function useNativeCamera(
  cameraPermissionAllowed,
  setCameraPermissionAllowed,
  handleScan,
  handleClose
) {
  window.cameraPermissionGranted = (value) => {
    setCameraPermissionAllowed(value);
  };

  if (handleClose) {
    window.closeCameraView = handleClose;
  }
  if (handleScan) {
    // Das ist der Rückruf, der vom iPhone kommt, weil er die native Kamera nutzt
    // Android machts mit der normalen Webcamera
    window.onQRQodeDetected = handleScan;
  }

  useEffect(() => {
    // diese Abfrage bedeutet es wurde bereits erlaubt/nicht erlaubt
    if (cameraPermissionAllowed != null) return;
    // Android Interface
    try {
      if (window.CUAndroid) {
        window.CUAndroid.enableCamera();
      }
    } catch (err) {
      // todo: error noch an error klasse loggen
    }

    // Interface Setup für iOS
    // Trigger Datenübergabe
    try {
      let msges;

      msges = ["enableCamera"];
      msges.forEach((msg) => {
        window.webkit.messageHandlers.callbackHandler.postMessage(msg);
      });
    } catch (e) {}
  }, [cameraPermissionAllowed]);
}
