import * as types from "../types.variables";
import { nativeStorageInterface } from "../..";

const initState = {
  searchedCategory: "address",
  entries: {},
  categoriesEntries: {},
  target: "mapInfo",
  mustHaveLocation: false,
  mostUsed: {
    address: [], // 394, 52, 19, 97, 1009
    news: [],
    phonebook: [],
    event: [],
  },
  lastUsed: {
    address: [],
    news: [],
    phonebook: [],
    event: [],
  },
};

export default function Search(state = initState, action) {
  switch (action.type) {
    case types.SEARCH_SET_MUSTHAVELOCATION:
      return {
        ...state,
        mustHaveLocation: action.payload,
      };
    case types.SEARCH_SET_CATEGORIES: {
      return {
        ...state,
        searchedCategory: action.payload,
      };
    }
    case types.SEARCH_GET_LAST_SEARCH: {
      return {
        ...state,
        lastUsed: action.payload ?? state.lastUsed,
      };
    }
    case types.SEARCH_SET_LAST_SEARCH: {
      let lastUsed = state.lastUsed[action.payload.type];

      const index = lastUsed.indexOf(action.payload.id);
      if (index > -1) lastUsed.splice(index, 1);
      lastUsed = [action.payload.id].concat(lastUsed);
      nativeStorageInterface.set("lastSearched", {
        ...state.lastUsed,
        [action.payload.type]: lastUsed,
      });
      return {
        ...state,
        lastUsed: { ...state.lastUsed, [action.payload.type]: lastUsed },
      };
    }
    case types.SEARCH_RESET_LAST_SEARCH: {
      nativeStorageInterface.set("lastSearched", {
        ...state.lastUsed,
        [action.payload.type]: [],
      });
      return {
        ...state,
        lastUsed: { ...state.lastUsed, [action.payload.type]: [] },
      };
    }
    case types.SEARCH_SET_TARGET: {
      return {
        ...state,
        target: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
