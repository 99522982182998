// Diese Values können von externen Styles überschrieben werden
import { EXPRESSIONS } from "./expressions.js";

export let CUSTOMIZABLE_VALUES = {
  // Colors für Features auf Map
  primary: "#234854",
  secondary: "#61942f",
  background: "#757575",
  selected: "#093d73",
  text_selected: "#093d73",
  hovered: "#342854",
  special: "#342854",
  white: "#ffffff",
  shadowDark: "#404040",

  // marker auf der Map (bei selected Stuff)
  childMarker: "#d44e4e",
  childMarker_darker: "#923939",
  childMarker_ghosted: "#923939",
  poiMarker: "#2a2a2a",
  poiMarker_ghosted: "#99999966",

  // Symbol Node Types
  standardSymbol: "#393939",
  unimportantSymbol: "#6f6f6f",
  textHalo: "rgba(255,255,255,0.6)",
  einrichtung: "#07e1be",
  einrichtung_light: "#c2f1ea",
  roomText: "#44556b",
  roomText_selected: "#255fa5",
  room: "#b71e5b",
  room_light: "#404040",
  poi: "#57a418",
  poi_light: "#e8fada",
  parking: "#124b92",
  parking_light: "#c7dcf5",
  notaufnahme: "#cd0000",
  notaufnahme_light: "#ffd4d4",
  bistro: "#ec442d",
  bistro_light: "#fdd0bd",
  information: "#4336fd",
  information_light: "#bad6ff",
  taxi: "#000",
  taxi_light: "#f6e67d",
  bushaltestelle: "#1c7906",
  apotheke: "#cd0000",
  apotheke_light: "#ffd4d4",
  toilette: "#048dbf",
  toilette_light: "#b9edff",
  atm: "#1f983d",
  atm_light: "#cdeed5",
  cashier: "#146088",
  cashier_light: "#9cc9e2",
  virus: "#000000",
  virus_light: "#f3ea22",
  jelbi: "#f0d722",
  entrance: "#54c47b",
  entrance_light: "#ffffff",
  restricted: "#dea2a2",
  restricted_light: "#ffffff",

  // Farben für Gebäudeklassen
  building_normal: "#80919e",
  building_main: "#5d6b76",
  building_parkingGarage: "#035ca3",
  building_parkingLot: "#516e86",

  // lines der Navigationsrouten
  lineFill: "#07ceae",
  lineFill_inactive: "#b0b0b0",
  userPuck: "#7cbaea",
  userPuck_radar: "#7cbaea",
  userPuck_arc: "#7cbaea",
  userPuck_inactive: "#b0b0b0",
  lineHalo: "#07ceae",
  lineHalo_inactive: "#5d5d5d",
  ghostedHalo: "#999999",
  ghostedArrowHalo: "#7a7a7a",

  // navigation nodes
  start_node: "#333b42",
  transit_node: "#333b42",
  route_node_symbol: "#333b42",
  route_node_bg: "#333b42",
  route_node_marking: "#333b42",

  // polygontypes
  building: "#e76666",
  building_selected: "#07ceae",

  rooms: "#80919e",
  rooms_active: "#07ceae",
  outline: "#ffffff",
  hallways: "#ffffff",
  ground: "#828282",
  levelRoom: "#333b42",
  waitingRoom: "#333b42",
  elevatorRoom: "#333b42",
  stairsRoom: "#333b42",
  wcRoom: "#048dbf",
  cafeteriaRoom: "#e89e77",
  entranceRoom: "#74fca3",
  emergencyRoom: "#e76666",

  emergencyArea: "#e76666",
  cafeteriaArea: "#e89e77",
  waitingArea: "#a5e7ca",
  officeArea: "#636E84",

  mainFloor: "#ffffff",
  sideFloor: "#eedfdf",
  restrictedFloor: "#dea2a2",

  exit: "#74fca3",

  mapboxBuildings: "#999999",

  // Information
  error: "#ff6969",
  warning: "#ffec63",
  info: "#8dfadf",
  success: "#06B195",
  gpsAvailable: "#06B195",

  // ---- Extras ----
  // für Outdoor Address Icons
  // (Start und End spielen auf das zoomlevel-weit-weg und zoomlevel-nah-dran an)
  outdoorAddressSymbols: {
    filter: EXPRESSIONS.misc.isOutdoor,
    icon: {
      size: {
        start: 0.4,
        end: 0.8,
      },
    },
    text: {
      size: { start: 10, end: 20 },
      font: ["Open Sans Regular", "Arial Unicode MS Regular"],
      color: "#000000",
      translate: [0, 0],
    },
  },
  buildingPolygonColors: [],

  // default Font, welches bei allen (nicht explizit hier definierten) layern verwendet wird
  defaultMapFont: ["Open Sans Regular", "Arial Unicode MS Regular"],
  // Einrichtungen Texts
  facilityTextFont: ["Open Sans Semibold", "Arial Unicode MS Regular"],
};

/**
 * Wird benutzt um nachgeladene (kundenspezifische) Farben reinzuschreiben.
 * @param json
 */
export function setJSCustomLayerValues(json) {
  CUSTOMIZABLE_VALUES = mergeDeep(CUSTOMIZABLE_VALUES, json);
}

/**
 * Deep merge two objects.
 * @param target
 * @param sources
 */
function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
function isObject(item) {
  return item && typeof item === "object" && !Array.isArray(item);
}
