import { useEffect, useRef, useState } from "react";
import StartDestination from "../../../../functions/classes/startDestination.class.js";
import { CAMPUS_COLLECTION } from "../../../../reduxStore/actions/main.js";
import { map } from "../../mapbox/map.sideEffects/useMap.sideEffects.js";
import { sourceIds } from "../../mapbox/variables/sourceIds.js";
import { navigationController } from "../navigation.component.jsx";
import SmoothNavigationManager from "@catchupapplications/indoor-navigation/dist/models/SmoothNavigationManager.js";
import { setFeatures } from "../../mapbox/functions/drawer.map.js";
import {
  length,
  lineSlice,
  lineString,
  nearestPointOnLine,
  pointToLineDistance,
} from "@turf/turf";
import Toast from "../../../../functions/classes/toast.class.js";
import { useIntl } from "react-intl";
import {
  callAndroidFunction,
  callIPhoneFunctions,
  IOS_ACTIONS,
  vibrateDevice,
} from "../../../nativeAppInterface/NativeAppInterface.jsx";
import { renderRoute } from "../../route/route.sideEffects/useRoutesAndViews.sideEffects.js";
import UserLocation from "../../../../functions/classes/location.class.js";
import { piKey } from "../../../../app/app.sideEffects/useURLParams.sideEffects";
import { DONT_DO_ANYTHING } from "../subComponents/navigationControls.component";

/** @type SmoothNavigationManager */
export let smoothNavigationManager;
/**
 * Diese Effekte handeln die ganzen Übergänge der Legs und alles
 * @param {Result} liveNavigationInformation
 * @param navigationMode
 * @param {function} forceUpdate
 * @param {Route} currentRoute
 * @param {function} handleNextStepClick
 * @param {function} handlePrevStepClick
 * @param {function} setShowLegChangeScreen
 * @param {function} setLegComplete
 * @param {UserLocation} locationObject
 * @param routingObject
 * @param setStart
 * @param setRoute
 * @param setUserMovesMap
 * @param setSimulatedLocation
 * @param {Step} currentSimulatedStep
 * @param addToast
 * @param {Leg} currentSimulatedLeg
 * @param {Leg} nextLeg
 * @param setBannerStepDistance
 * @param routeErrorCounter
 * @param history
 * @param {boolean} isApp
 * @param {function} setIsInParkingMode
 * @param isInParkingMode
 * @param showLegChangeScreen
 */
export function useLiveNavigation(
  liveNavigationInformation,
  navigationMode,
  forceUpdate,
  currentRoute,
  handleNextStepClick,
  handlePrevStepClick,
  setShowLegChangeScreen,
  setLegComplete,
  locationObject,
  routingObject,
  setStart,
  setRoute,
  setUserMovesMap,
  setSimulatedLocation,
  currentSimulatedStep,
  addToast,
  currentSimulatedLeg,
  nextLeg,
  setBannerStepDistance, // Der currentStep, auf dem gerade der simulierte Punkt ist
  routeErrorCounter,
  history,
  isApp,
  setIsInParkingMode,
  isInParkingMode,
  userHasParked,
  showLegChangeScreen,
  start,
  target
) {
  // --- States ---
  const [stepLine, setStepLine] = useState(null);
  const [isRerouting, setIsRerouting] = useState(false);
  const [canReroute, setCanReroute] = useState(true);
  const [simulatedPointIsOnCurrentStep, setSimulatedPointIsOnCurrentStep] =
    useState(false);
  const [stepLineProgress, setStepLineProgress] = useState({
    walked: null, // m: wie weit ist der user auf der stepline
    remaining: null, // m: wie viel ist noch übrig von der stepline
  });
  const [userWarning, setUserWarning] = useState({
    value: "",
    onlyUserWarning: false,
  });
  const [canEndLiveNavigation, setCanEndLiveNavigation] = useState(false);
  const [confirmShouldReroute, setConfirmShouldReroute] = useState(0);

  // --- constants ---
  // Achtung! currentSimulatedStep ist nicht gleichzusetzen mit stepIndex. StepIndex ist tatsächlich immer der aktuellste, aber currentSimulatedStep wird
  // nach einer Reihe von anderen Bedingungen erst gesetzt!
  const {
    legIndex,
    stepIndex,
    notification,
    shouldReroute,
    locationIsSnapped,
  } = liveNavigationInformation;

  // Das eigentliche currentLeg, laut eingehenden Daten
  // (wenn der simulierte Punkt darauf liegt, ist simulatedLeg das gleiche!)
  let currentRealtimeLeg = currentRoute.legs[legIndex];
  const isNotLive = navigationMode !== "live";
  const rerouteAttemptsExceeded = routeErrorCounter > 4;
  const rerouteConfirmationTime = 3500;

  // --- Refs ---
  const initRender = useRef(true);
  const prevLegIndex = useRef(legIndex);
  const prevStepIndex = useRef(stepIndex);
  const prevRouteErrorCounter = useRef(routeErrorCounter);
  const prevCurrentRoute = useRef(currentRoute);
  const timeUntilKickOff = useRef(4500); // ms bis zum Start des simulierten Punktes
  const vibratingInterval = useRef();
  const confirmShouldReroute_timeout = useRef();

  // --- custom Hooks ---
  const intl = useIntl();

  // --- functions ---
  const setNewStep = () => {
    // der Step darf erst dann wechseln wenn der simulierte Live Punkt
    // den letzten Step ausgelaufen ist!
    // wenn er sich auf dem currentStep noch befindet
    if (
      (isNotLive || simulatedPointIsOnCurrentStep) &&
      legIndex === prevLegIndex.current
    )
      return;

    if (liveNavigationInformation.legIndex === -1) return;

    if (currentRoute.legPos > legIndex) return;

    // change
    currentRoute.setLeg(legIndex);
    currentRoute.setStep(stepIndex);

    if (
      stepIndex !== prevStepIndex.current ||
      legIndex !== prevLegIndex.current
    ) {
      // update, wenn etwas changed!
      forceUpdate();
    }
  };
  const setUserCanFinish = () => {
    const finishThreshold = 5; // m
    // todo: später nur noch durch die routing notification abfragen!
    if (
      (currentRoute.isOnLastLeg() &&
        stepLineProgress.remainingLeg !== null &&
        stepLineProgress.remainingLeg < finishThreshold) ||
      notification?.type === "RouteComplete"
    ) {
      setCanEndLiveNavigation(true);
    } else {
      setCanEndLiveNavigation(false);
    }
  };
  const setDragListener = () => {
    const handleDragStartEvent = () => {
      setUserMovesMap(true);
    };
    if (isNotLive) return;

    if (isApp) {
      //damit das iPhone nicht ausgeht
      callIPhoneFunctions(["setIdleTimer?disabled=1"]);
      callAndroidFunction("setIdleTimer", [false]);
    }

    map.on("drag", handleDragStartEvent);
    return () => {
      if (isApp) {
        callIPhoneFunctions(["setIdleTimer?disabled=0"]);
        callAndroidFunction("setIdleTimer", [true]);
      }
      map.off("drag", handleDragStartEvent);
    };
  };
  const userIsParking = () => {
    return notification?.type === "SwitchMode";
  };
  const setLegChangeScreen = () => {
    if (isNotLive) return;

    const currentLeg = currentRoute.getCurrentLeg();

    if (notification != null && !currentRoute.isOnLastLeg()) {
      // wir haben eine notification auf dem current leg und
      // haben das nächste leg noch nicht erreicht
      setLegComplete(currentLeg.legComplete);
      setShowLegChangeScreen(true);

      // kann hier nur aktiviert werden! Deaktiviert nur durch Knopfdruck!
      if (userIsParking()) {
        setIsInParkingMode(true);
      }
    }

    console.log(prevLegIndex.current);
    console.log(legIndex);
    if (
      prevLegIndex.current !== legIndex ||
      currentRoute !== prevCurrentRoute.current
    ) {
      // wir sind zum nächsten leg gechanged
      // -> splashcreen muss verschwinden, weil der user auf dem nächsten leg ist!
      if (!isInParkingMode) {
        setShowLegChangeScreen(false);
      }
    }
  };
  const rerouteUser = (forceReroute = false) => {
    const previousRouteErrorSolved =
      prevRouteErrorCounter.current > 0 && routeErrorCounter === 0;

    if (
      isNotLive ||
      (!shouldReroute && !forceReroute) ||
      isRerouting ||
      !locationObject.isSet ||
      // soll doppelte Routeberechnung bei RouteFehler vermeiden
      previousRouteErrorSolved ||
      // Wenn nach 3 Rerouteversuchen immernoch keine Route kommt einfach Fehler dem User zeigen
      rerouteAttemptsExceeded ||
      (!canReroute && !forceReroute)
      // isParking()
    )
      return;

    // Definieren des neuen Starts, anhand der Position des Users
    // Die Destination bleibt die gleiche
    const reroutedStart = new StartDestination({
      lat: locationObject.lat,
      lng: locationObject.lng,
      isSet: true,
      indoors: liveNavigationInformation?.isIndoor,
      level: CAMPUS_COLLECTION.getLevelByIndex(locationObject.zIndex),
      campusId: CAMPUS_COLLECTION.getCurrentCampus().id,
    });

    setRoute(
      reroutedStart,
      routingObject.target,
      routingObject.settings,
      routingObject.roadUserType,
      routingObject.goToParkingSpot,
      routingObject.language,
      routingObject.deviceInformation,
      routingObject.isApp,
      routingObject.isTerminal
    );

    // Das verhindert, dass nochmal gecalled wird, obwohl schon auf eine neue Route gewartet wird
    if (!DONT_DO_ANYTHING) return;
    setIsRerouting(true);
    // nach dem Reroute soll der simulierte Punkt NICHT warten bis er wieder los reisen kann!
    timeUntilKickOff.current = 0;
  };
  const mountSmoothNavigationManager = () => {
    let lineToCurrentStep = lineString(
      currentRealtimeLeg.getLineToStep(stepIndex)
    );
    const actualKickoffTime =
      locationObject.positionType === UserLocation.position_types.outdoor
        ? 0
        : timeUntilKickOff.current;

    // smoothe navigation anschmeißen
    smoothNavigationManager = new SmoothNavigationManager(
      lineToCurrentStep,
      setSimulatedLocation,
      (points) =>
        setFeatures({
          sourceId: sourceIds.debug_smooth_managerPoint,
          features: points,
        }),
      actualKickoffTime,
      locationObject,
      () => {},
      undefined,
      locationObject.positionType
    );
  };
  const unmountSmoothNavigationManager = () => {
    setFeatures({
      sourceId: sourceIds.debug_smooth_managerPoint,
      features: [],
    });
    smoothNavigationManager?.stop();
    smoothNavigationManager = null;
  };
  const setWrongFloorWarningInterval = () => {
    const userLevel = CAMPUS_COLLECTION.getLevelByIndex(
      locationObject.zIndex,
      true
    );

    if (
      !nextLeg ||
      !nextLeg.steps[nextLeg.stepPos] ||
      locationObject.zIndex == null ||
      isNotLive ||
      locationObject.zIndex == userLevel ||
      !currentRealtimeLeg.isIndoor ||
      !currentSimulatedLeg.isIndoor
    ) {
      return stopWrongFloorWarningInterval;
    }

    // const nextLegLevel = CAMPUS_COLLECTION.getLevelByIndex(nextLeg.level, true);
    const nextLegLevel = CAMPUS_COLLECTION.getLevelDisplayType(nextLeg.level);
    // User wird gewarnt, wenn er nicht auf seinem seinem Level oder dem
    // Level des nächst vorgesehenen Legs aussteigt
    if (userLevel !== nextLegLevel && currentSimulatedLeg.level !== userLevel) {
      vibrateDevice();
      stopWrongFloorWarningInterval();
      vibratingInterval.current = setInterval(vibrateDevice, 2000);
      setUserWarning({
        value: intl.formatMessage(
          { id: "navigation.wrongFloor" },
          { level: nextLegLevel }
        ),
        onlyUserWarning: true,
      });
    }
    return stopWrongFloorWarningInterval;
  };
  const stopWrongFloorWarningInterval = () => {
    clearInterval(vibratingInterval.current);
  };
  const setUserSteplinePosition = () => {
    if (!stepLine) return;

    const walkedDistanceOnStepLine = nearestPointOnLine(
      stepLine,
      [locationObject.lng, locationObject.lat],
      { units: "meters" }
    ).properties.location;

    const remainingDistanceOnStepLine =
      length(stepLine, { units: "meters" }) - walkedDistanceOnStepLine;

    try {
      const remainingLeg = Math.floor(
        length(
          lineSlice(
            [locationObject.lng, locationObject.lat],
            currentSimulatedLeg.polylines[
              currentSimulatedLeg.polylines.length - 1
            ],
            lineString(currentSimulatedLeg.polylines)
          ),
          { units: "meters" }
        )
      );

      setStepLineProgress({
        walked: walkedDistanceOnStepLine,
        remaining: remainingDistanceOnStepLine - walkedDistanceOnStepLine,
        remainingLeg,
      });
    } catch (e) {}
  };
  const updateSmoothNavigationManagerAvailableRoute = () => {
    if (!smoothNavigationManager || !currentRealtimeLeg) return;

    // Dies ist die Line vom currentLeg zum currentStep (also nur eine Teilmenge des ganzen Legs)
    // der Smoothmanager bekommt nämlich immer nur den momentanen Leg zum momentanen Step, damit
    // er nicht über den aktuellen step simuliert
    const lineToCurrentStep = lineString(
      currentRealtimeLeg.getLineToStep(stepIndex)
    );
    lineToCurrentStep.properties.length = length(lineToCurrentStep, {
      units: "meters",
    });

    // wenn der manager verfügbar ist, müssen wir ihn jedes mal neue (und erweiterte routeLines speisen)
    smoothNavigationManager.routeLine = lineToCurrentStep;
    // beim Legchange bekommt der Manager eine ganz neue route (die vom neuen leg quasi von vorne beginnt)
    // daher muss man ihm auch sagen, dass er seine gelaufene distanz wieder resetten
    if (legIndex !== prevLegIndex.current) {
      smoothNavigationManager.resetCurrentDistance();
    }
  };
  const checkUserOnStepLine = () => {
    if (
      locationObject.lat == null ||
      locationObject.lng == null ||
      !currentSimulatedStep ||
      isNotLive
    )
      return;

    const toleranceZone = 0.5; // m
    const simulatedPointFromStepLine = pointToLineDistance(
      [locationObject.lng, locationObject.lat],
      currentSimulatedStep.stepLine,
      {
        units: "meters",
      }
    );
    setSimulatedPointIsOnCurrentStep(
      simulatedPointFromStepLine < toleranceZone
    );
  };
  const renderReroute = () => {
    if (isNotLive) return;

    if (rerouteAttemptsExceeded) {
      return;
      history.push(`/map/info${window.location.search}`);
      addToast(
        new Toast(
          "navigationRerouteExceededLimit",
          intl.formatMessage({ id: "navigation.reroute.exceedError" }),
          Toast.toastTypes.error,
          [],
          7000
        )
      );
      window.addError(
        JSON.stringify({
          start,
          target,
          IOS_ACTIONS,
        })
      );
    } else if (!initRender.current) {
      addToast(
        new Toast(
          "neueRoute",
          intl.formatMessage({ id: "navigation.reroute.newRoute" }),
          Toast.toastTypes.info
        )
      );
    }

    setIsRerouting(false);
    renderRoute(currentRoute);
  };
  const updateBannerInstructions = () => {
    if (stepLineProgress.remaining == null || isNotLive) return;

    const allBannerInstructions = currentSimulatedStep.bannerInstructions;
    try {
      for (let i = 0; i < allBannerInstructions.length; i++) {
        const currentInstruction = allBannerInstructions[i];
        if (
          stepLineProgress.remaining <
            currentInstruction.distanceAlongGeometry ||
          currentInstruction.distanceAlongGeometry === 0
        ) {
          setBannerStepDistance(currentInstruction.distanceAlongGeometry);
          allBannerInstructions.splice(i, 1);
          break;
        }
      }
    } catch (e) {
      console.error("Fehler beim errechnen der BannerInstructions", e);
    }
  };
  const manageRouteSnap = () => {
    if (!smoothNavigationManager) return;

    if (!locationIsSnapped || isInParkingMode || shouldReroute) {
      smoothNavigationManager.stop();
    } else {
      smoothNavigationManager.continue();
    }
  };
  const manageSmoothNavigationManagerMounting = () => {
    if (
      !isNotLive &&
      currentRealtimeLeg &&
      navigationController != null &&
      !shouldReroute
    ) {
      mountSmoothNavigationManager();
    }

    return unmountSmoothNavigationManager;
  };
  const checkIfRerouteSufficient = () => {
    confirmShouldReroute_timeout.current = setTimeout(() => {
      setConfirmShouldReroute((prevState) => prevState + 1);
    }, rerouteConfirmationTime);
    return () => {
      clearTimeout(confirmShouldReroute_timeout.current);
    };
  };
  const manageCanReroute = () => {
    if (showLegChangeScreen) {
      setCanReroute(false);
    } else {
      setCanReroute(true);
    }
  };

  // --- Effects ---
  useEffect(manageSmoothNavigationManagerMounting, [
    navigationMode,
    shouldReroute,
    currentRoute,
    currentRealtimeLeg,
    isNotLive,
  ]);
  useEffect(setLegChangeScreen, [legIndex, notification?.type]);
  useEffect(setNewStep, [
    legIndex,
    stepIndex,
    navigationMode,
    simulatedPointIsOnCurrentStep,
  ]);
  useEffect(() => {
    setStepLine(lineString(currentSimulatedStep.stepLine));
  }, [currentSimulatedStep]);
  useEffect(updateSmoothNavigationManagerAvailableRoute, [
    stepIndex,
    legIndex,
    currentRoute,
  ]);
  useEffect(manageRouteSnap, [
    locationIsSnapped,
    shouldReroute,
    isInParkingMode,
  ]);
  useEffect(() => {
    checkUserOnStepLine();
    setUserCanFinish();
  }, [locationObject.lat]);
  useEffect(() => {
    rerouteUser();
  }, [
    shouldReroute,
    locationObject.isSet,
    routeErrorCounter,
    confirmShouldReroute,
  ]);
  useEffect(() => {
    setUserCanFinish(false);
  }, [currentRoute]);
  useEffect(() => {
    if (isNotLive) return;
    // manchmal kam noch kein positionsupdate und daher kein
    // neues liveNavigationInformationUpdate nach dem reroute
    currentRealtimeLeg = currentRoute.legs[0];
    // nach dem reroute warten und kontrollieren
    // ob vielleicht nochmal rerouted werden soll!
    return checkIfRerouteSufficient();
  }, [currentRoute]);
  useEffect(() => {
    prevRouteErrorCounter.current = routeErrorCounter;
  }, [routeErrorCounter]);
  useEffect(renderReroute, [currentRoute, routeErrorCounter]);
  useEffect(setWrongFloorWarningInterval, [locationObject.zIndex]);
  useEffect(setUserSteplinePosition, [
    locationObject.lng,
    locationObject,
    stepLine,
  ]);
  useEffect(updateBannerInstructions, [stepLineProgress.remaining]);
  useEffect(() => {
    if (!currentSimulatedStep || isNotLive) return;

    setBannerStepDistance(Math.round(currentSimulatedStep.distance));
  }, [currentSimulatedStep]);
  useEffect(setDragListener, [isNotLive]);
  useEffect(() => {
    if (userHasParked && routingObject.roadUserType === 8) {
      // Nur wenn auf Fußgänger gewechselt wurde darf der user laufen..
      rerouteUser(true);
    }
  }, [userHasParked, routingObject.roadUserType]);
  useEffect(manageCanReroute, [showLegChangeScreen]);

  // --- setPrevIndexes ---
  useEffect(() => {
    initRender.current = false;
  }, []);
  useEffect(() => {
    prevLegIndex.current = legIndex;
  }, [legIndex]);
  useEffect(() => {
    prevCurrentRoute.current = currentRoute;
  }, [currentRoute]);
  useEffect(() => {
    prevStepIndex.current = stepIndex;
  }, [stepIndex]);

  return {
    isRerouting,
    simulatedPointIsOnCurrentStep,
    stepLineProgress,
    userWarning,
    canEndLiveNavigation,
  };
}
