import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage, useIntl } from "react-intl";
import classes from "./routeCard.module.scss";
import * as settingsAction from "../../../../reduxStore/actions/settings";
import * as mapAction from "../../../../reduxStore/actions/map";
import * as routeAction from "../../../../reduxStore/actions/route";
import * as debugAction from "../../../../reduxStore/actions/debug.js";
import DragContainer from "../../../elements/dragContainer/dragContainer.container.jsx";
import AskForParkingSpot from "../AskForParkingSpot.component";
import { withRouter } from "react-router-dom";
import { CAMPUS_COLLECTION, POIS } from "../../../../reduxStore/actions/main";
import NavigationInfo from "../../navigation/info/navigationInfo.component.jsx";
import StandardButton from "../../../elements/buttons/standard.button.jsx";
import StandardCard from "../../../elements/cards/StandardCard.card.jsx";
import { getQueryParams } from "../../../../app/RouteWithSubRoutes";
import StandardHeadline from "../../../elements/text/standardHeadline.text.jsx";
import StandardInstruction from "../../../elements/text/standardInstruction.component.jsx";
import { synth } from "../route.component.jsx";
import { isAndroid } from "react-device-detect";
import { pageNames } from "../../../../reduxStore/reducers/main.js";
import { setProperties } from "../../../../reduxStore/actions/settings.js";
import { statisticLogger } from "../../../statisticLogger/StatisticLogger.container";
import { RouteModes } from "./routeModes";

let TIMEOUT_ID = -1;
/**
 * Sobald Start und Ziel im RouteHeader ausgewählt sind wird ein Fetch initialisiert.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function StartRouteCard(props) {
  const [idForParkingSpot, setIdForParkingSpot] = useState(0);
  const time =
    null === props.currentRoute
      ? { days: 0, hours: 0, minutes: 0 }
      : props.currentRoute.getDuration();

  const setRoadUserType = (type) => {
    props.setRoadUserType(type);
    props.setFetchNewRoute(true);
  };

  const closeModal = (needParkingSpot) => {
    props.setGoToParkingSpot(needParkingSpot);
    setIdForParkingSpot(0);
    setRoadUserType(idForParkingSpot);
  };

  const parkingSpotIsAvailable = () => {
    const allowedIdsFor7 = [11, 16]; // auto
    const allowedIdsFor9 = [31]; // fahrrad
    const allowedIdsFor10 = [11, 16, 30]; // gehbehindert

    const findCondition = (p, campusId) => {
      if (!campusId) return true;
      if (!campusId || p.campusId !== campusId) return false;

      if (idForParkingSpot === 7) {
        return !!p.attributeIds.find((attributeId) =>
          allowedIdsFor7.includes(attributeId)
        );
      }

      if (idForParkingSpot === 9) {
        return !!p.attributeIds.find((attributeId) =>
          allowedIdsFor9.includes(attributeId)
        );
      }
      if (idForParkingSpot === 10) {
        return !!p.attributeIds.find((attributeId) =>
          allowedIdsFor10.includes(attributeId)
        );
      }

      return false;
    };

    const parkingSportStart = POIS.parkinglots.find((p) =>
      findCondition(p, props.target.campusId)
    );
    const parkingSportDestination = POIS.parkinglots.find((p) =>
      findCondition(p, props.target.campusId)
    );

    if (!props.start.campusId && !props.target.campusId) return false;

    if (!(!!parkingSportStart && !!parkingSportDestination)) {
      closeModal(false);
      return false;
    }
    return true;
  };

  /**
   * @param {number} id - AttributeId, ob es nun KFZ, Fahrrad, Fußgänger etc. ist
   */
  const askForParkingSpot = (id) => {
    const parkingSpotIds = [];
    let routeEligibleForParking = true;
    if (
      props.start &&
      props.target &&
      props.start.indoors &&
      !props.target.indoors
    ) {
      routeEligibleForParking = false;
    }

    // wenn radfahrer
    if (9 === id) {
      parkingSpotIds.push(31);
    }

    // wenn KFZ
    if (7 === id) {
      parkingSpotIds.push(11);
      parkingSpotIds.push(16);
    }

    // wenn behindert
    if (id === 10) {
      parkingSpotIds.push(30);
    }

    const isParkingSpotAvailable = !!POIS.parkinglots.filter((content) =>
      parkingSpotIds.includes(
        content.attributeIds.find((value) => parkingSpotIds.includes(value))
      )
    ).length;

    if (isParkingSpotAvailable && routeEligibleForParking) {
      setIdForParkingSpot(id);
    } else {
      props.setGoToParkingSpot(false);
      setRoadUserType(id);
    }
  };
  const preOpenContent = (
    <PreOpenContent
      resetRouteOnClose={props.resetRouteOnClose}
      isTerminal={props.isTerminal}
      settings={props.settings}
      currentRoute={props.currentRoute}
      routeHasError={props.routeHasError}
      roadUserType={props.roadUserType}
      target={props.target}
      start={props.start}
      time={time}
      props={props}
      setRoadUserType={setRoadUserType}
      askForParkingSpot={askForParkingSpot}
    />
  );
  const content = (
    <div
      className={`${classes.container} ca-bg-white pointer-event-on pb-3 pt-3`}
    >
      <NavigationInfo
        isTerminal={props.isTerminal}
        target={props.target}
        start={props.start}
        roadUserType={props.roadUserType}
        settings={props.settings}
        downloadPDF={props.handleDownloadPDF}
        isGeneratingPDF={props.isGeneratingPDF}
        isApp={props.isApp}
        setProperties={props.setProperties}
      />
    </div>
  );

  // Wenn Mobile ist, dann wird ruft route.component diesen Component raw auf, also ohne DragContainer
  if (props.renderInDragContainer) {
    return (
      <>
        <DragContainer
          status={props.isVisible ? props.routeCardOpeningStatus : "minimized"}
          noClose
          preOpenIsFullScreen
          preOpenContent={preOpenContent}
          keepPreOpenContent={true}
        >
          {content}
        </DragContainer>
        {idForParkingSpot && parkingSpotIsAvailable() ? (
          <AskForParkingSpot closeModal={closeModal} />
        ) : null}
      </>
    );
  } else
    return (
      <StandardCard className={"h-100 overflow-auto"}>
        <div
          className={`${classes.container} ca-bg-white pointer-event-on pb-3 pt-3`}
        >
          {preOpenContent}
          {content}
        </div>
        {idForParkingSpot && parkingSpotIsAvailable() ? (
          <AskForParkingSpot closeModal={closeModal} />
        ) : null}
      </StandardCard>
    );
}

function PreOpenContent({
  currentRoute,
  target,
  start,
  routeHasError,
  askForParkingSpot,
  setRoadUserType,
  time,
  isTerminal,
  roadUserType,
  props,
  resetRouteOnClose,
}) {
  return (
    <>
      {!currentRoute && (
        <NeedTargetAndStart
          target={target}
          start={start}
          routeHasError={routeHasError}
        />
      )}
      <RouteOptions
        history={props.history}
        routeHasError={routeHasError}
        currentRoute={currentRoute}
        resetRouteOnClose={resetRouteOnClose}
        isTerminal={isTerminal}
        askForParkingSpot={askForParkingSpot}
        setRoadUserType={setRoadUserType}
        time={time}
        target={target}
        roadUserType={roadUserType}
        start={start}
        props={props}
      />
    </>
  );
}

function NeedTargetAndStart({ start, target, routeHasError }) {
  let text = "";
  if (!start?.isSet && !target?.isSet) {
    text = <FormattedMessage id="route.instructions.noNothing" />;
  }
  if (!start?.isSet && target?.isSet) {
    text = <FormattedMessage id="route.instructions.noStart" />;
  }
  if (start?.isSet && !target?.isSet) {
    text = <FormattedMessage id="route.instructions.noTarget" />;
  }

  return !routeHasError ? (
    <div className={`${classes.loadingIcon} m-3 h-100 text-center`}>
      {text ? (
        <StandardInstruction className={"m-3 standardInstruction__routeCard"}>
          {text}
        </StandardInstruction>
      ) : (
        <div className={"d-flex align-items-center justify-content-around"}>
          <div className={"mr-3"}>
            {/* {isDesktop && isSafari ? null : (
              <FontAwesomeIcon icon="spinner-third" spin />
            )} */}
            <FontAwesomeIcon icon="spinner-third" fixedWidth={true} spin />
          </div>
          <StandardInstruction className={"standardInstruction__routeCard"}>
            <FormattedMessage id="route.routeIsLoading" />
          </StandardInstruction>
        </div>
      )}
    </div>
  ) : (
    <div className={"d-flex flex-column align-items-center m-3"}>
      <FontAwesomeIcon icon={["fal", "dizzy"]} size={"3x"} className={"mb-3"} />
      <StandardInstruction
        className={
          "standardInstruction__routeCard standardInstruction__routeCard--error"
        }
      >
        <FormattedMessage id="route.instructions.error" />
      </StandardInstruction>
    </div>
  );
}

function RouteOptions({
  history,
  routeHasError,
  roadUserType,
  currentRoute,
  target,
  start,
  resetRouteOnClose,
  time,
  askForParkingSpot,
  setRoadUserType,
  isTerminal,
  props,
}) {
  const intl = useIntl();
  const handleStartNavigation = () => {
    resetRouteOnClose.current = false;
    statisticLogger.addLog({
      action: {
        id: "leaveRouteModeForNavigation",
        name: "Leave route page to enter navigation (Start Navigation)",
        group: "Route",
        movement: "leave",
        type: "click",
        interaction: "execute",
        content: {
          globalPoiIds: [start.globalPoiId, target.globalPoiId],
          destinationGlobalPoiId: start.globalPoiId,
          startGlobalPoiId: target.globalPoiId,
          campusId: CAMPUS_COLLECTION?.getCurrentCampus().id,
          startZIndex: start.level,
          startLatitude: start.lat,
          startLongitude: start.lng,
          destinationLatitude: target.lat,
          destinationLongitude: target.lng,
          route: props.currentRoute
            ? props.currentRoute.getStatisticRoute()
            : null,
        },
      },
    });
    history.push(
      `/${pageNames.map}/${pageNames.navigation}/${getQueryParams()}`
    );
    if (!isAndroid && SpeechSynthesisUtterance) {
      const msg = new SpeechSynthesisUtterance("");
      if ("speechSynthesis" in window) {
        synth.speak(msg);
      }
    }
  };

  // useEffect(() => {
  //   if (terminalNavigationLock) {
  //     // will promptly go to navigation instead of staying in route
  //     handleStartNavigation();
  //   }
  // }, []);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (TIMEOUT_ID > -1) clearTimeout(TIMEOUT_ID);
    TIMEOUT_ID = setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [props.currentRouteCreationTimestamp]);

  return (
    <>
      {currentRoute && (
        <div
          className={`d-flex justify-content-between align-items-center routeInfo pr-3 pl-3 mb-3`}
        >
          <TimeToTake
            currentRoute={currentRoute}
            roadUserType={roadUserType}
            time={time}
          />
          <StandardButton
            ariaLabel={intl.formatMessage(
              { id: "route.screenreader.startNavigation" },
              { start: start?.name, destination: target?.name }
            )}
            disabled={loading}
            handleClick={handleStartNavigation}
            buttonClasses={"normalButton--primary"}
            icon={["fal", loading ? "circle-notch" : "directions"]}
            iconSpinning={loading}
          >
            <FormattedMessage id="route.start" />
          </StandardButton>
        </div>
      )}
      {!isTerminal && (
        <RouteModes
          currentRoute={currentRoute}
          roadUserType={roadUserType}
          setRoadUserType={setRoadUserType}
          askForParkingSpot={askForParkingSpot}
          setSettingsProperties={props.setSettingsProperties}
        />
      )}
      <TargetAddress target={target} />
    </>
  );
}

function getIcon(type) {
  switch (type) {
    case 8: {
      return "walking";
    }
    case 7: {
      return "car";
    }
    case 9: {
      return "biking";
    }
    case 10: {
      return "wheelchair";
    }
    default: {
      throw Error(`Unknown type: ${type}`);
    }
  }
}

function TargetAddress({ target }) {
  if (!target || !target?.name || !target?.address) return null;
  return (
    <StandardCard className={"standardCard--info mr-3 ml-3 mb-3"}>
      <StandardHeadline type={"h3"}>
        {target?.name ? target.name : ""}
      </StandardHeadline>
      <p>{target?.address ? target.address : ""}</p>
    </StandardCard>
  );
}

export function TimeToTake({ currentRoute, roadUserType, time }) {
  return (
    <div className={"mr-3 d-flex align-items-center muted" + classes.info}>
      <div className={"mr-2"}>
        {time.days && !time.hours ? (
          <div id={"timetotake_time"}>
            <b>{time.days}</b>
            <span>
              <FormattedMessage id="route.dayShortcut" />
            </span>
          </div>
        ) : time.days && time.hours ? (
          <div id={"timetotake_time}"}>
            <b>{time.days}</b>
            <span>
              <FormattedMessage id="route.dayShortcut" />{" "}
            </span>
            <b>{time.hours}</b>
            <span>
              <FormattedMessage id="route.hourShortcut" />{" "}
            </span>
          </div>
        ) : time.hours && !time.minutes ? (
          <div id={"timetotake_time"}>
            <b>{time.hours}</b>
            <span>
              <FormattedMessage id="route.hourShortcut" />{" "}
            </span>
          </div>
        ) : time.hours && time.minutes ? (
          <div id={"timetotake_time"}>
            <b>{time.hours}</b>
            <span>
              <FormattedMessage id="route.hourShortcut" />{" "}
            </span>
            <b>{time.minutes}</b>
            <span>
              <FormattedMessage id="route.minuteShortcut" />{" "}
            </span>
          </div>
        ) : time.minutes ? (
          <div id={"timetotake_time"}>
            <b>{time.minutes}</b>
            <span>
              <FormattedMessage id="route.minuteShortcut" />{" "}
            </span>
          </div>
        ) : (
          <div id={"timetotake_time"}>
            <b>&#060; 1</b>
            <span>
              <FormattedMessage id="route.minuteShortcut" />{" "}
            </span>
          </div>
        )}
      </div>

      {roadUserType != null && (
        <span id={"timetotake_distance"}>
          {currentRoute.getDistanceFormatted()}
          <FontAwesomeIcon icon={getIcon(roadUserType)} className={"ml-2"} />
        </span>
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPage: (page) => dispatch(mapAction.setPage(page)),
    setRoadUserType: (type) => dispatch(routeAction.setRoadUserType(type)),
    setDebuggingValues: (object) =>
      dispatch(debugAction.setDebuggingValues(object)),
    setFetchNewRoute: (status) =>
      dispatch(routeAction.setFetchNewRoute(status)),
    setGoToParkingSpot: (status) =>
      dispatch(routeAction.setGoToParkingSpot(status)),
    setProperties: (props) => dispatch(setProperties(props)),
    setSettingsProperties: (properties) =>
      dispatch(settingsAction.setProperties(properties)),
  };
};
StartRouteCard.propTypes = {
  target: PropTypes.object,
  start: PropTypes.object,
  currentRoute: PropTypes.object,
  currentRouteCreationTimestamp: PropTypes.number,
  routeHasError: PropTypes.bool,
  roadUserType: PropTypes.number,
  isVisible: PropTypes.bool,
  settings: PropTypes.object,
  handleDownloadPDF: PropTypes.func,
  isGeneratingPDF: PropTypes.bool,
  renderInDragContainer: PropTypes.bool,
  isTerminal: PropTypes.bool,
  routeCardOpeningStatus: PropTypes.string,
};
StartRouteCard.defaultProps = {
  renderInDragContainer: true,
};
export default withRouter(connect(null, mapDispatchToProps)(StartRouteCard));
