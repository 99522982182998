import { map } from "../mapbox/map.sideEffects/useMap.sideEffects";
import { ActionBubble } from "./actionBubbles/actionBubbles.component";

export default function TerminalMapControls() {
  const handleZoom = (type = "plus") => {
    if (!map) return;

    const currentZoom = map.getZoom();
    if (type === "plus") {
      map.zoomTo(currentZoom + 1);
    } else {
      map.zoomTo(currentZoom - 1);
    }
  };
  return (
    <div className="d-flex flex-column align-items-center mb-3">
      <ActionBubble
        iconname={"plus"}
        className="mb-3 pointer-event-on"
        action={() => handleZoom("plus")}
      />
      <ActionBubble
        iconname={"minus"}
        action={() => handleZoom("minus")}
        className="pointer-event-on"
      />
    </div>
  );
}
