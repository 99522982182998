import { CAMPUS_COLLECTION } from "../../../reduxStore/actions/main";
import StandardButton from "../../elements/buttons/standard.button.jsx";

export default function CampusButtons(props) {
  const activeClass = {
    textClasses: "normalButton--filterSelection--selected--icon",
    buttonClasses:
      "normalButton--filterSelection normalButton--filterSelection--selected mr-3 mb-3",
  };

  const inactiveClass = {
    textClasses: "normalButton--filterSelection--unselected--icon",
    buttonClasses:
      "normalButton--filterSelection normalButton--filterSelection--unselected mr-3 mb-3",
  };

  return CAMPUS_COLLECTION.getAllCampuses().map((campus) => {
    const isActive = props.selectedCampus.includes(campus.id);
    return (
      <StandardButton
        key={campus.id + "campus"}
        role={"option"}
        title={campus.name}
        icon={isActive ? ["fal", "check"] : null}
        {...(isActive ? activeClass : inactiveClass)}
        noFlex
        ariaSelected={isActive}
        handleClick={() => props.toggleCampus(campus.id)}
      >
        {campus.name}
      </StandardButton>
    );
  });
}
