import { EXPRESSION_MIX, EXPRESSIONS } from "../../variables/expressions.js";
import { map } from "../../map.sideEffects/useMap.sideEffects.js";
import { sourceIds } from "../../variables/sourceIds.js";
import { layerIds } from "../../variables/layerIds.js";
import {
  setFeaturesGhosted,
  setLayerFilters,
  zoomImportant,
  zoomSymbolsEnd,
  zoomSymbolsStart,
  zoomSymbolsUnimportantEnd,
  zoomSymbolsUnimportantStart,
} from "../filter.map.js";

/**
 * Filter für die Icons mit dem Circle Background
 * @param allFilters
 * @param options
 */
export function filterPOISymbols(allFilters = [], { target, level } = {}) {
  // während navigation target symbol nicht anzeigen, sonst überlappen sie sich!
  if (target) {
    allFilters.push(["!=", ["id"], target.poiId != null ? target.poiId : null]);
  }
  // Symbole nur anzeigen, wenn in den Properties "iconname" vorhanden ist!
  allFilters.push([
    "any",
    [
      "all",
      ["has", "iconname"],
      [
        "case",
        EXPRESSIONS.type.adresse,
        [
          "!",
          [
            "any",
            EXPRESSIONS.iconname.parking,
            EXPRESSIONS.iconname.parkingBike,
            EXPRESSIONS.iconname.parkingDisabled,
          ],
        ],
        true,
      ],
    ],
  ]);

  allFilters.push([
    "case",
    // Prioritätssymbole, die bereits von hohen Zooms sichtbar sind!
    ["any", EXPRESSIONS.class.important],
    [">=", ["zoom"], zoomImportant],
    // symbole wie treppen, lifts...
    EXPRESSION_MIX.symbols.unimportant,
    [
      "all",
      ["<=", ["zoom"], zoomSymbolsUnimportantEnd],
      [">=", ["zoom"], zoomSymbolsUnimportantStart],
    ],
    // der Rest erst im Gebäude!
    [
      "all",
      ["<=", ["zoom"], zoomSymbolsEnd],
      [">=", ["zoom"], zoomSymbolsStart],
    ],
  ]);

  // Important Sources wie icons mit classname "important" werden nicht gefiltert,
  // sie werden stattdessen immer geghosted (wenn beispielsweise auf anderem Stockwerk)
  let sources = [map.getSource(sourceIds.nodes)];
  setFeaturesGhosted(sources, level);

  setLayerFilters(
    [
      layerIds.nodes.circles,
      layerIds.nodes.outerCircles,
      layerIds.nodes.symbols,
      layerIds.nodes.circleShadows,
    ],
    allFilters
  );
}
