import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { MISC_INFO, MODULES } from "../../../reduxStore/actions/main.js";
import ToggleSetting from "../../elements/buttons/toggleSetting.component.jsx";
import StandardInstruction from "../../elements/text/standardInstruction.component.jsx";

export default function RouteSettings(props) {
  const intl = useIntl();
  const init = useRef(true);
  const [movementRestricted, setMovementRestricted] = useState(
    props.settings.movementRestricted
  );
  const [disabledParkingPermit, setDisabledParkingPermit] = useState(
    props.settings.disabledParkingPermit
  );
  const [visualImpaired, setVisualImpaired] = useState(
    props.settings.visualImpaired
  );
  const [supplier, setSupplier] = useState(props.settings.supplier);
  const [employee, setEmployee] = useState(props.settings.employee);

  const settings = [
    {
      name: intl.formatMessage({
        id: "settings.route.movementRestrictedTitle",
      }),
      text: intl.formatMessage({ id: "settings.route.movementRestrictedText" }),
      value: movementRestricted,
      set: setMovementRestricted,
    },

    /*  {
      name: intl.formatMessage({
        id: "settings.route.visuallyImpairedTitle",
      }),
      text: intl.formatMessage({ id: "settings.route.visuallyImpairedText" }),
      value: visualImpaired,
      set: setVisualImpaired,
    }, */
  ];
  if (MODULES.offerEmployeeRouteMode && !props.isTerminal) {
    settings.push({
      name: intl.formatMessage({
        id: "settings.route.employeeTitle",
      }),
      text: intl.formatMessage({ id: "settings.route.employeeText" }),
      value: employee,
      set: setEmployee,
    });
  }
  if (MODULES.offerSupplierMode && !props.isTerminal) {
    settings.push({
      name: intl.formatMessage({
        id: "settings.route.supplierTitle",
      }),
      text: intl.formatMessage({ id: "settings.route.supplierText" }),
      value: supplier,
      set: setSupplier,
    });
  }
  if (MODULES.offerParkingDisabled && !props.isTerminal) {
    settings.push({
      name: intl.formatMessage({
        id: "settings.route.disabledParkingPermitTitle",
      }),
      text: intl.formatMessage({
        id: "settings.route.disabledParkingPermitText",
      }),
      value: disabledParkingPermit,
      set: setDisabledParkingPermit,
    });
  }
  useEffect(() => {
    if (init.current) return;

    const settings = {
      routing: {
        movementRestricted: movementRestricted,
        disabledParkingPermit: disabledParkingPermit,
        visualImpaired: visualImpaired,
        supplier: supplier,
        employee: employee,
      },
    };
    props.setProperties(settings);
  }, [
    visualImpaired,
    disabledParkingPermit,
    movementRestricted,
    supplier,
    employee,
  ]);
  useEffect(() => {
    init.current = false;
  }, []);

  useEffect(() => {
    setMovementRestricted(props.settings.movementRestricted);
  }, [props.settings.movementRestricted]);

  if (MISC_INFO.disabledRouteSettings) return null;

  return (
    <div className="d-flex flex-column">
      <StandardInstruction className={"mb-4"}>{props.text}</StandardInstruction>
      <div>
        {settings.map((s, index) => (
          <ToggleSetting
            key={index}
            settingTitle={s.name}
            settingText={s.text}
            toggleValue={s.value}
            onChange={() => s.set((prevState) => !prevState)}
          />
        ))}
      </div>
    </div>
  );
}
