import React from "react";
import * as debugAction from "../../../reduxStore/actions/debug.js";
import { connect } from "react-redux";

/**
 * Wrapper der für App.js verwendet wird und position-absolute Elemente, der dafür sorgt,
 * dass ein SavePadding von Notches und Navigationsbalken in Geräten eingehalten wird!
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
function SaveZoneWrapper({
  children,
  activeBottom = true,
  activeTop = true,
  className = "",
  fullpage = true,
  saveZonePadding,
  givenRef,
  style = {},
}) {
  return (
    <div
      className={`${fullpage ? "h-100" : ""} ${className}`}
      ref={givenRef}
      style={{
        paddingBottom: activeBottom ? saveZonePadding.bottom + "px" : "0px",
        paddingTop: activeTop ? saveZonePadding.top + "px " : "0px",
        ...style,
      }}
    >
      {children}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    saveZonePadding: state.settings.saveZonePadding,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setDebugValues: (value) => dispatch(debugAction.setDebuggingValues(value)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SaveZoneWrapper);
