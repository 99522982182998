import { map } from "../../map.sideEffects/useMap.sideEffects";
import { layerIds } from "../../variables/layerIds";
import { sourceIds } from "../../variables/sourceIds";
import { isInDevelopmentMode } from "../../../../../functions/helper/helpers.functions.js";

const beaconDistanceRadius = ["get", "distance"];

export default function createDebugBeaconSymbols() {
  // das hier sind die kalman beacon daten, nach denen die echte position berechnet wird
  if (!map.getLayer(layerIds.nodes.debug_beacon_positions.circles)) {
    map.addLayer({
      id: layerIds.nodes.debug_beacon_positions.circles,
      type: "circle",
      source: sourceIds.debug_beacon_positions,
      filter: [
        "all",
        ["!=", ["get", "visibleStatus"], "past"],
        ["!=", ["get", "visibleStatus"], "never"],
      ],
      layout: {
        visibility: isInDevelopmentMode() ? "visible" : "none",
      },
      paint: {
        "circle-radius": beaconDistanceRadius,
        "circle-opacity": [
          "case",
          ["==", ["get", "visibleStatus"], "past"],
          0,
          ["==", ["get", "visibleStatus"], "never"],
          0,
          ["==", ["get", "type"], "here"],
          1,
          ["==", ["get", "type"], "very close"],
          0.8,
          ["==", ["get", "type"], "close"],
          0.5,
          ["==", ["get", "type"], "far"],
          0.3,
          0.1,
        ],
        "circle-color": [
          "case",
          ["==", ["get", "type"], "here"],
          "#77ee00",
          ["==", ["get", "type"], "very close"],
          "#eeea00",
          ["==", ["get", "type"], "close"],
          "#eeb600",
          ["==", ["get", "type"], "far"],
          "#ee5b00",
          "#0034ee",
        ],
      },
    });
  }
  if (!map.getLayer(layerIds.nodes.debug_beacon_positions.symbols)) {
    map.addLayer({
      id: layerIds.nodes.debug_beacon_positions.symbols,
      type: "symbol",
      source: sourceIds.debug_beacon_positions,
      filter: [
        "all",
        ["!=", ["get", "visibleStatus"], "past"],
        ["!=", ["get", "visibleStatus"], "never"],
      ],
      layout: {
        "text-field": [
          "format",
          "",
          { "font-scale": 0.4 },
          ["get", "name"],
          { "font-scale": 0.55 },
          "\n",
          {},
          "d: ",
          {
            "font-scale": 0.8,
            "text-font": [
              "literal",
              ["DIN Offc Pro Italic", "Arial Unicode MS Regular"],
            ],
          },
          ["get", "distance"],
          {
            "font-scale": 0.8,
            "text-font": [
              "literal",
              ["DIN Offc Pro Italic", "Arial Unicode MS Regular"],
            ],
          },
        ],
        "text-offset": [0, 0],
        "text-allow-overlap": true,
      },
      paint: {
        "text-color": [
          "case",
          ["==", ["get", "visibleStatus"], "now"],
          "#0e0c85",
          ["==", ["get", "visibleStatus"], "past"],
          "#000",
          "#ea0000",
        ],
      },
    });
  }

  const rawFilter = ["<", ["get", "distance"], 20];

  // das hier sind die ROHEN beacon daten
  if (!map.getLayer(layerIds.nodes.debug_beacon_positions.circles_raw)) {
    map.addLayer({
      id: layerIds.nodes.debug_beacon_positions.circles_raw,
      type: "circle",
      source: sourceIds.debug_beacon_positions_raw,
      filter: rawFilter,
      layout: {
        visibility: isInDevelopmentMode() ? "visible" : "none",
      },
      paint: {
        "circle-radius": beaconDistanceRadius,
        "circle-opacity": 0.2,
        "circle-color": "#000000",
      },
    });
  }
  if (!map.getLayer(layerIds.nodes.debug_beacon_positions.symbols_raw)) {
    map.addLayer({
      id: layerIds.nodes.debug_beacon_positions.symbols_raw,
      type: "symbol",
      source: sourceIds.debug_beacon_positions_raw,
      filter: rawFilter,
      layout: {
        "text-field": [
          "format",
          "",
          {
            "font-scale": 0.8,
            "text-font": [
              "literal",
              ["DIN Offc Pro Italic", "Arial Unicode MS Regular"],
            ],
          },
          ["get", "distance"],
          {
            "font-scale": 0.8,
            "text-font": [
              "literal",
              ["DIN Offc Pro Italic", "Arial Unicode MS Regular"],
            ],
          },
        ],
        "text-offset": [0, 1],
        "text-allow-overlap": true,
      },
      paint: {
        "text-color": [
          "case",
          ["==", ["get", "visibleStatus"], "now"],
          "#0e0c85",
          ["==", ["get", "visibleStatus"], "past"],
          "#000",
          "#ea0000",
        ],
      },
    });
  }
}
