import { useEffect } from "react";
import { pageNames } from "../../../../reduxStore/reducers/main.js";
import { isInDevelopmentMode } from "../../../../functions/helper/helpers.functions.js";

export function useAutomaticNavigationRecord(
  showDebug,
  handleStartRecording,
  handleStopRecording,
  navigationMode,
  currentMapPage,
  automaticRecord
) {
  useEffect(() => {
    // im Debuggingmodus (während development) kann der user selbst drücken, wann recorded werden soll
    if (isInDevelopmentMode()) return;

    try {
      if (
        // soll auch bei sbs aufnehmen
        // navigationMode === "live" &&
        currentMapPage === pageNames.navigation &&
        automaticRecord
      ) {
        handleStartRecording();
      }

      return () => {
        if (automaticRecord) handleStopRecording();
      };
    } catch (e) {
      window.onerror("Fehler beim automatischen Aufzeichnen, " + e);
    }
  }, [navigationMode, currentMapPage]);
}
