import { decode } from "@mapbox/polyline";
import { truncTo } from "../helper/helpers.functions.js";

export default class RouteItem {
  static getDistanceFormatted(distance) {
    if (50 > distance) return `${Math.trunc(distance)}m`; // show original meters

    if (1000 > distance) return `${Math.round(distance / 50) * 50}m`; // round to 50 meters

    // round to 100 meters and returns only kilometers if necessary
    if (9999 > distance) {
      distance = distance / 1000;
      const formattedDistance = truncTo(Math.round(distance * 10) / 10, 1);
      return `${formattedDistance}km`;
    }

    if (9999 < distance) {
      return truncTo(distance / 1000, 0) + "km";
    }
    return 0;
  }
  static getDurationFormatted(duration) {
    const days = Math.floor(duration / 1440),
      hours = Math.floor((duration % 1440) / 60),
      minutes = Math.floor((duration % 1440) % 60);

    if (days) {
      if (hours) {
        return `${days} d ${hours} h`;
      }
      return `${days} d`;
    }
    if (hours) {
      if (minutes) {
        return `${hours} h ${minutes} min`;
      }
      return `${hours} h`;
    }
    if (minutes) {
      return `${minutes} min`;
    }
    return "> 1 min";
  }

  constructor(distance, duration) {
    this.distance = Math.floor(distance);
    this.duration = Math.floor(duration / 60);
  }

  getDistanceInMeters() {
    return this.distance;
  }

  getDistanceFormatted() {
    return RouteItem.getDistanceFormatted(this.distance);
  }

  getDurationInMinutes() {
    return this.duration;
  }

  getDuration() {
    return {
      days: Math.floor(this.duration / 1440),
      hours: Math.floor((this.duration % 1440) / 60),
      minutes: Math.floor((this.duration % 1440) % 60),
    };
  }

  getDurationFormatted() {
    const days = Math.floor(this.duration / 1440),
      hours = Math.floor((this.duration % 1440) / 60),
      minutes = Math.floor((this.duration % 1440) % 60);

    if (days) {
      if (hours) {
        return `${days} d ${hours} h`;
      }
      return `${days} d`;
    }
    if (hours) {
      if (minutes) {
        return `${hours} h ${minutes} min`;
      }
      return `${hours} h`;
    }
    if (minutes) {
      return `${minutes} min`;
    }
    return "> 1 min";
  }

  /**
   * use polyline decode function and switch lat & lng
   *
   * @param {String} geometry
   * @returns {Array}
   */
  decode(geometry) {
    if (process.env.REACT_APP_NEW_ROUTING) {
      return decode(geometry, 6);
    }
    return decode(geometry, 6).map((item) => {
      const temp = item[0];
      item[0] = item[item.length - 1];
      item[item.length - 1] = temp;

      return item;
    });
  }
}
