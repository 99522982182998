import React from "react";

export default function ElevatorSVG({ text }) {
  return (
    <svg width="390px" height="391px" viewBox="0 0 390 391" version="1.1">
      <title>9769BBBA-A705-4238-99DD-9B381DEFC66B</title>
      <g
        id="Screens"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Screen-/-Fahrstuhl" transform="translate(0.000000, -78.000000)">
          <rect fill="#FFFFFF" x="0" y="0" width="390" height="844"></rect>
          <g
            id="Screen-/-illustration-/-Fahrstuhl"
            transform="translate(0.000000, 78.566274)"
          >
            <path
              d="M55,69 L335,69 C346.045695,69 355,77.954305 355,89 L355,369 C355,380.045695 346.045695,389 335,389 L55,389 C43.954305,389 35,380.045695 35,369 L35,89 C35,77.954305 43.954305,69 55,69 Z"
              id="Rectangle"
              fill="#FFEDCD"
            ></path>
            <path
              d="M166.894344,65.9110057 C151.459219,59.6361093 135.152192,54.3976913 118.505508,55.0559952 C94.0292104,56.0189396 70.5543747,70.7432362 59.0305555,92.3587135 C49.2645477,110.672167 48.0599917,132.137075 43.8545505,152.460456 C38.6651552,177.504015 28.7415746,201.293996 18.1386805,224.565738 C12.4450524,237.066508 6.43627881,249.836903 5.50484888,263.542229 C4.20574923,282.563009 13.2994467,301.52076 27.5650315,314.175601 C41.8306163,326.830442 60.7673572,333.508024 79.7776321,334.964697 C89.8938015,335.738554 100.465181,335.020723 109.45383,330.311049 C118.957217,325.33175 125.890417,316.329095 135.432321,311.419829 C144.277404,306.867728 154.62468,306.226932 164.555264,306.822207 C177.561175,307.602918 190.376828,310.327042 202.575814,314.903937 C216.456221,320.117844 229.363178,327.663827 243.212071,332.951267 C257.060963,338.238708 272.534606,341.183567 286.69164,336.775032 C299.976773,332.63262 310.502631,322.572477 319.956995,312.382773 C335.513857,295.653876 349.702471,277.702944 362.38608,258.702995 C370.933525,245.901086 378.889197,232.391851 382.670943,217.467962 C389.894777,188.936792 379.950187,156.746434 357.893506,137.280949 C339.629075,121.127993 315.103755,114.12476 291.096674,109.859792 C269.204569,105.983503 243.891384,105.741891 223.739583,95.6362264 C204.522712,85.9997783 187.035641,74.1047878 166.894344,65.9110057 Z"
              id="Path"
              fill="#FAE6C1"
              fillRule="nonzero"
            ></path>
            <g
              id="elevator"
              transform="translate(135.921902, 97.985600)"
              fillRule="nonzero"
            >
              <polygon
                id="Path"
                fill="#CCD1D9"
                points="180.2528 0 180.2528 232.96 166.2752 232.96 166.2752 13.9776 96.3872 13.9776 26.4992 13.9776 26.4992 232.96 12.5216 232.96 12.5216 0"
              ></polygon>
              <g
                id="Group"
                transform="translate(26.499200, 13.977600)"
                fill="#656D78"
              >
                <rect
                  id="Rectangle"
                  x="69.888"
                  y="0"
                  width="69.888"
                  height="218.9824"
                ></rect>
                <rect
                  id="Rectangle"
                  x="0"
                  y="0"
                  width="69.888"
                  height="218.9824"
                ></rect>
              </g>
              <rect
                id="Rectangle"
                fill="#434A54"
                x="91.914368"
                y="13.9776"
                width="8.9316864"
                height="218.9824"
              ></rect>
              <path
                d="M191.0272,228.3008 L4.6592,228.3008 C2.0826624,228.3008 0,230.383462 0,232.96 C0,235.536538 2.0826624,237.6192 4.6592,237.6192 L191.0272,237.6192 C193.603738,237.6192 195.6864,235.536538 195.6864,232.96 C195.6864,230.383462 193.603738,228.3008 191.0272,228.3008 Z"
                id="Path"
                fill="#E6E9ED"
              ></path>
            </g>
            <g id="Group-2" transform="translate(181.977694, 63.115200)">
              <rect
                id="Rectangle"
                fill="#D8D8D8"
                x="1.18234311e-13"
                y="0"
                width="93.1791202"
                height="24.96"
                rx="8.96"
              ></rect>
              <text
                id="3.-Stock"
                fontSize="13.44"
                fontWeight="700"
                fill="#394854"
              >
                <tspan x="20.51872" y="16.84">
                  {text}
                </tspan>
              </text>
            </g>
            <g
              id="dieFrau"
              transform="translate(25.466990, 147.887559)"
              fillRule="nonzero"
            >
              <path
                d="M90.2548963,39.0869658 C88.7912913,38.5202415 87.151836,38.630907 85.7776704,39.3891846 C84.4035049,40.1474622 83.4357854,41.4754605 83.1349033,43.0158455 L39.5663396,66.7060203 L47.2267048,75.3276664 L87.6879706,49.3610122 C90.3680618,49.7024153 92.8799372,47.9853154 93.5348314,45.3641404 C94.1897256,42.7429654 92.780638,40.0462224 90.2549161,39.0869658 L90.2548963,39.0869658 Z"
                id="Path"
                fill="#FFB7B7"
              ></path>
              <polygon
                id="Path"
                fill="#FFB7B7"
                points="79.143352 209.536352 84.5722507 206.024871 73.6112761 183.413264 65.5985039 188.595821"
              ></polygon>
              <path
                d="M77.4794516,202.590073 L97.8003128,202.590073 L97.8003128,210.441311 L85.0371234,210.441311 C80.8631393,210.441311 77.4794516,207.057628 77.4794516,202.883644 L77.4794516,202.590073 L77.4794516,202.590073 Z"
                id="Path"
                fill="#394854"
                transform="translate(87.639882, 206.515692) rotate(147.104110) translate(-87.639882, -206.515692) "
              ></path>
              <polygon
                id="Path"
                fill="#FFB7B7"
                points="18.228314 210.353378 24.6939216 210.353378 27.7709723 185.414112 18.228314 185.414112"
              ></polygon>
              <path
                d="M16.842881,208.505785 L37.1637421,208.505785 L37.1637421,216.357033 L24.4005528,216.357033 C22.3961323,216.357033 20.4738074,215.560781 19.0564686,214.143442 C17.6391298,212.726102 16.842881,210.803776 16.842881,208.799356 L16.842881,208.505785 L16.842881,208.505785 Z"
                id="Path"
                fill="#394854"
                transform="translate(27.003312, 212.431409) rotate(-180.000000) translate(-27.003312, -212.431409) "
              ></path>
              <path
                d="M281.10263,75.1338968 L90.5456685,75.1338968 C88.2292761,75.1307728 86.3521347,72.9334758 86.3494659,70.2220115 L86.3494659,9.54578204 C86.3520533,6.83427821 88.2292424,4.63692541 90.5456685,4.63389679 L281.10263,4.63389679 C283.419022,4.63702391 285.296162,6.83431908 285.298833,9.54578204 L285.298833,70.2220115 C285.296077,72.9334336 283.418987,75.1306712 281.10263,75.1338968 L281.10263,75.1338968 Z"
                id="Path"
                fill="#757A83"
              ></path>
              <path
                d="M22.0397445,126.531137 C23.8613901,139.312276 14.215061,195.936327 15.2863268,195.931365 L29.1387319,195.857315 C29.6324028,194.909466 35.9908838,164.356175 37.9853142,159.454023 C40.769618,152.651239 43.5786053,145.76453 44.6745547,138.497694 C45.7063268,131.675163 45.1978458,124.724277 44.5264534,117.857315 C44.5897625,117.442902 44.44986,117.023336 44.1505062,116.729852 C43.8511525,116.436367 43.428898,116.3048 43.0158205,116.376302 L24.4291116,115.41858 C20.5982256,115.221112 19.6898711,114.381871 20.2724028,118.143644 C20.677208,120.952661 21.6349295,123.687587 22.0397445,126.531137 Z"
                id="Path"
                fill="#394854"
              ></path>
              <path
                d="M25.6929042,95.4545643 C21.9163219,100.884944 17.9570814,107.134817 19.2110054,113.636463 C20.1637902,118.573172 23.9255624,122.399121 27.4750561,125.978235 C41.6631573,140.225577 69.6394864,203.089628 69.6394864,203.089628 C83.1405953,194.450357 69.3178105,203.336433 83.0179675,194.203552 C81.8035371,194.109754 54.2122712,94.9263364 51.926575,94.7930453 C42.6801243,94.1808835 34.7023978,93.2774707 25.6929042,95.4545643 Z"
                id="Path"
                fill="#394854"
              ></path>
              <path
                d="M40.6306545,43.7676619 C38.7124173,42.0569589 25.2318995,36.7814289 25.2318995,36.7814289 C24.9840569,36.7485665 24.7325252,36.7561123 24.4870982,36.8037725 C23.7199337,36.9974149 23.3698963,37.9507576 23.5337556,38.7216296 C23.6976148,39.4925016 22.3532453,52.1615578 22.0069154,57.9225934 C21.3999026,67.9774055 27.3210653,90.7534105 27.2689534,91.4497974 C27.0660571,93.9334728 25.9785152,96.2618458 24.2041014,98.0114931 C24.6652542,98.5839243 53.9551773,99.7319905 55.9475047,100.409746 C56.3496934,99.8995621 53.6795806,63.7729982 53.5566911,61.7322516 C53.3518523,58.6338793 43.162969,46.1137932 40.6306545,43.7676619 Z"
                id="Path"
                fill="#C8D7F2"
              ></path>
              <path
                d="M19.7398207,122.156438 C21.1449482,121.457197 22.1681879,120.171489 22.534208,118.645268 C22.9002281,117.119048 22.5714303,115.509092 21.6362871,114.248605 L33.9943504,45.8554158 L22.4621639,45.7034441 L13.8801892,113.335447 C11.8215929,115.085208 11.39706,118.098143 12.8920104,120.34861 C14.3869608,122.599077 17.3288648,123.375741 19.7398356,122.156438 L19.7398207,122.156438 Z"
                id="Path"
                fill="#FFB7B7"
              ></path>
              <path
                d="M19.640504,50.9303673 C19.1595611,50.9653389 18.6805436,50.8403778 18.2779724,50.5749243 C17.5917699,49.9874559 23.1899977,40.9977091 24.2958205,40.8644179 C27.8946812,40.4497344 31.5972129,40.3115065 35.0874661,41.2840382 C38.5777192,42.2565698 41.8606306,44.4682154 43.4206306,47.7412534 C44.0722762,49.103785 39.3478458,55.9608736 37.2003775,54.953785 C34.6283521,53.7492429 23.0270863,51.0143012 19.640504,50.9303673 Z"
                id="Path"
                fill="#C8D7F2"
              ></path>
              <circle
                id="Oval"
                fill="#FFB7B7"
                cx="32.3428583"
                cy="23.0912343"
                r="12.9533316"
              ></circle>
              <path
                d="M19.619913,44.5083854 C23.277518,44.8910968 26.8792132,43.3904145 29.1829714,40.5238416 C31.4867296,37.6572687 32.1773241,33.8170428 31.0166066,30.3274473 C30.5011105,28.7776515 29.6394475,27.3570938 29.1673845,25.7935245 C28.6953216,24.2299553 28.6824467,22.3811874 29.7294831,21.1276632 C31.7780544,18.6751063 35.7265576,20.2177932 38.8847282,19.7301995 C42.2499049,19.2106503 44.8316259,15.9821316 45.1009185,12.5877444 C45.370211,9.19335717 43.6009637,5.83432667 40.9672048,3.67615059 C38.3334459,1.51797451 34.9366644,0.455293628 31.5479347,0.122337299 C22.6971906,-0.747282729 14.0337704,3.06248524 8.6923804,10.1731436 C3.35099041,17.283802 2.10519776,26.6655493 5.40577062,34.9237651 C6.82155454,38.4661351 9.13409657,41.7654463 12.4187901,43.7054495 C15.7034837,45.6454526 20.0417054,46.0181544 23.3154494,44.0597324"
                id="Path"
                fill="#394854"
              ></path>
              <rect
                id="Rectangle"
                fill="#394854"
                x="0"
                y="215.678206"
                width="294.037225"
                height="1"
              ></rect>
            </g>
            <g id="Group-4" transform="translate(120.629902, 167.500000)">
              <g id="Group-3" transform="translate(0.000000, 0.000000)">
                <circle
                  id="Oval"
                  fill="#D8D8D8"
                  cx="19"
                  cy="21.9709494"
                  r="19"
                ></circle>
                <circle
                  id="Oval"
                  fill="#FFFFFF"
                  cx="19"
                  cy="19"
                  r="19"
                ></circle>
                <path
                  d="M12.3338888,25.6665278 C13.1871511,25.6665278 14.0404133,25.3398883 14.6903591,24.6899425 L19,20.3803016 L23.3096409,24.6899425 C23.9595867,25.3398883 24.8128489,25.6665278 25.6661112,25.6665278 C26.5193734,25.6665278 27.3726356,25.3398883 28.0225815,24.6899425 C29.3258062,23.3867178 29.3258062,21.2802266 28.0225815,19.9770019 L21.3564703,13.3108908 C20.0532456,12.007666 17.9467544,12.007666 16.6435297,13.3108908 L9.97741855,19.9770019 C8.67419382,21.2802266 8.67419382,23.3867178 9.97741855,24.6899425 C10.6273644,25.3398883 11.4806266,25.6665278 12.3338888,25.6665278 Z"
                  id="Path"
                  fill="#B89C69"
                  fillRule="nonzero"
                ></path>
              </g>
              <text
                id="3.-Stock"
                fontSize="29.44"
                fontWeight="700"
                fill="#FFFFFF"
              >
                <tspan x="57.8477923" y="30.4854747">
                  {text}
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
