import React from "react";
import PropTypes from "prop-types";

export default function StandardText({
  type = "p",
  className = "",
  onClick,
  text,
  children,
}) {
  let returnClass = `m-0 standardText ${className}`;
  switch (type) {
    case "p":
      return (
        <p className={returnClass} onClick={onClick}>
          {text || children}
        </p>
      );
    case "span":
      return (
        <span className={returnClass} onClick={onClick}>
          {text || children}
        </span>
      );
  }
}

StandardText.propTypes = {
  type: PropTypes.oneOf(["span", "p"]),
};
