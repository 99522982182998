import * as type from "../types.variables";
import * as types from "../types.variables.js";
import StartDestination from "../../functions/classes/startDestination.class.js";
import { CAMPUS_COLLECTION } from "../actions/main";
import axios from "axios";
import { sessionKey } from "./main";
import UserLocation from "../../functions/classes/location.class.js";
import { navigationController } from "../../components/mapUi/navigation/navigation.component.jsx";
import { RecordedPositions_COLLECTION } from "../../functions/classes/recordedPosition.class.js";
import { logHeadingData } from "../../components/elements/positionRecorder/positionRecorder.jsx";
import { LOCATION_MANAGER } from "../actions/main";
import getLocationData from "../functions/setLocationData.functions.js";
import { statisticLogger } from "../../components/statisticLogger/StatisticLogger.container";

export const recordedPositions_collection = new RecordedPositions_COLLECTION();

const initState = {
  /** @type {UserLocation}*/
  locationObject: new UserLocation(UserLocation.location_types.gps, null, null),
  hasLatestPosition: true,
  /** @type Route*/
  currentRoute: null,
  currentRouteCreationTimestamp: +new Date() + 60 * 1000 * 10,
  /** @type "sbs"|"live" | "map" */
  navigationMode: "map",
  /** @type Result */
  liveNavigationInformation: {
    stepIndex: 0,
    legIndex: -1,
    shouldReroute: null,
    lat: null,
    lng: null,
    snapLine: null,
    intersectionIndex: null,
    floorChangeDialog: null,
  },
  activeStatus: "unknown",
  activeStatusPermission: null,
  routeHasError: false,
  routeErrorCounter: 0,
  fetchNewRoute: false,
  roadUserType: 8,
  goToParkingSpot: false,
  /** @type StartDestination*/
  start: new StartDestination(),
  target: new StartDestination(),

  relevantCampusKeys: [],
  feature: null,
  isGeneratingPDF: false,
  defaultStartNode: null,
  urlParamsHandled: false,
  positionRecording: false,
  rawLocationData: null,
};

export default function Route(state = initState, action) {
  switch (action.type) {
    case types.ROUTE_SET_ERROR: {
      return {
        ...state,
        routeHasError: true,
        routeErrorCounter: state.routeErrorCounter + 1,
      };
    }
    case types.NAVIGATION_SET_TYPE:
      return {
        ...state,
        locationType: action.payload,
      };
    case types.NAVIGATION_SET_POSITION_RECORDING:
      return {
        ...state,
        positionRecording: action.payload,
      };
    case types.ROUTE_SET_DEFAULT_STARTNODE:
      return {
        ...state,
        defaultStartNode: action.payload,
      };
    case types.NAVIGATION_SET_LATEST_POSITION:
      return {
        ...state,
        hasLatestPosition: action.payload,
      };
    case types.SETTING_SET_PROPERTIES:
      if (action.payload?.location?.userAllowed === false) {
        return {
          ...state,
          locationObject: new UserLocation(),
        };
      } else return state;
    case types.NAVIGATION_SET_MODE:
      return { ...state, navigationMode: action.payload };
    case types.NAVIGATION_SET_LOCATION_DATA:
      console.log("rawLocationData", action.payload);
      return {
        ...state,
        rawLocationData: action.payload,
      };
    case type.NAVIGATION_SET_LOCATION:
      return getLocationData(action, state);
    case types.NAVIGATION_SET_SIMULATED_LOCATION_DATA:
      return {
        ...state,
        locationObject: {
          ...state.locationObject,
          ...action.payload,
        },
      };
    case types.NAVIGATION_SET_ZINDEX:
      const currentLocationObject = state.locationObject;
      currentLocationObject.zIndex = action.payload;
      return {
        ...state,
        locationObject: currentLocationObject,
      };
    case types.NAVIGATION_SET_HEADING:
      if (!LOCATION_MANAGER) {
        return state;
      }

      let heading = Math.trunc(action.payload);
      /** @type Result
       */
      let liveNavigationInformation_;

      // darf keine LiveNavigation setzen, solange es kein Location Object vorher gab
      if (
        navigationController != null &&
        state.locationObject.isSet &&
        state.liveNavigationInformation.lat !== 0 &&
        state.liveNavigationInformation.lat != null
      ) {
        liveNavigationInformation_ =
          navigationController.getNavigationInformation(
            state.liveNavigationInformation.lat,
            state.liveNavigationInformation.lng,
            heading,
            CAMPUS_COLLECTION.getLevelByIndex(state.locationObject.zIndex),
            !!state.liveNavigationInformation.isIndoor,
            state.locationObject.accuracy,
            state.locationObject.speed,
            LOCATION_MANAGER
          );
      }
      if (state.positionRecording) logHeadingData(heading);
      state.locationObject.heading = heading;

      return {
        ...state,
        locationObject: { ...state.locationObject, heading: heading },
        liveNavigationInformation: {
          ...state.liveNavigationInformation,
          ...liveNavigationInformation_,
        },
      };
    case types.NAVIGATION_SET_MOTION_ACTIVITY_STATUS: {
      return {
        ...state,
        activeStatus: action.payload,
      };
    }
    case type.ROUTE_SET_ROUTE: {
      if (action.payload) {
        statisticLogger.addLog({
          action: {
            group: "Route",
            id: "calculatedRoute",
            movement: "stay",
            content: action.payload.getStatisticRoute(),
            name: "Calculated Route (not started)",
            type: "input",
            interaction: "execute",
          },
        });
      }

      const start = state.start;
      try {
        start.level = action.payload.legs[0].level ?? 0;
      } catch (e) {}
      return {
        ...state,
        start,
        routeHasError: false,
        currentRoute: action.payload,
        routeErrorCounter: 0,
        currentRouteCreationTimestamp: +new Date(),
      };
    }
    case type.ROUTE_RESET_ROUTE: {
      return {
        ...state,
        currentRoute: null,
        routeHasError: false,
        routeErrorCounter: 0,
      };
    }
    case type.ROUTE_SET_START: {
      let start =
        action.payload == null ? new StartDestination() : action.payload;

      try {
        const urlParams = new URLSearchParams(window.location.search);
        const defaultStartNode = urlParams.get("defaultStartNode");

        if (defaultStartNode && defaultStartNode.length) {
          if (start.nodeId != defaultStartNode) {
            window.map.setLayoutProperty("3d-model", "visibility", "none");
          }
        }
      } catch (e) {}

      if (start.nodeId) {
        axios
          .post(process.env.REACT_APP_STATSTIK_URL, {
            sessionKey,
            globalPOIId: state.target.globalPoiId,
            startKnotenId: start.nodeId,
          })
          .then((res) => {
            if (res.data.sessionNavigationId) {
              axios.post(process.env.REACT_APP_STATSTIK_URL, {
                sessionNavigationId: res.data.sessionNavigationId,
                startKnotenId: start.nodeId,
              });
            }
          });
      }
      return {
        ...state,
        start: start,
        relevantCampusKeys: getRelevantCampusKeys(action.payload, state.target),
      };
    }
    case type.ROUTE_SET_TARGET: {
      if (action.payload) {
      }
      let target =
        action.payload == null ? new StartDestination() : action.payload;
      return {
        ...state,
        target: target,
        relevantCampusKeys: getRelevantCampusKeys(action.payload, state.start),
      };
    }
    case type.MAIN_SET_CURRENT_POI: {
      return {
        ...state,
        relevantCampusKeys: getRelevantCampusKeys(action.payload, {}),
      };
    }
    case type.ROUTE_URL_PARAMS_HANDLED: {
      return {
        ...state,
        urlParamsHandled: true,
      };
    }
    case type.ROUTE_SET_ROAD_USER_TYPE: {
      return {
        ...state,
        roadUserType: action.payload,
      };
    }
    case type.ROUTE_SET_FETCH_NEW_ROUTE: {
      return {
        ...state,
        fetchNewRoute: action.payload,
      };
    }
    case type.ROUTE_SET_GO_TO_PARKING_SPOT: {
      return {
        ...state,
        goToParkingSpot: action.payload,
      };
    }
    case type.ROUTE_SET_IS_GENERATING_PDF: {
      return {
        ...state,
        isGeneratingPDF: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

function getRelevantCampusKeys(point1, point2) {
  const campusKeys = [];

  if (point1 && point1.campusId) {
    const campus = CAMPUS_COLLECTION.getAllCampuses().find(
      (c) => c.id === point1.campusId
    );
    campusKeys.push(campus.campusKey);
  }

  if (
    point2 &&
    point1 &&
    point2.campusId &&
    point1.campusId !== point2.campusId
  ) {
    const campus = CAMPUS_COLLECTION.getAllCampuses().find(
      (c) => c.id === point2.campusId
    );
    campusKeys.push(campus.campusKey);
  }

  return campusKeys;
}
