import * as type from "../types.variables";
import { nativeStorageInterface } from "../..";

const initState = {
  favorites: [],
};

export default function Phonebook(state = initState, action) {
  switch (action.type) {
    case type.PHONEBOOK_ADD_TO_FAVORITE: {
      const favorites = state.favorites.concat(action.payload);
      nativeStorageInterface.set("phonebook-favourites", favorites);
      return {
        ...state,
        favorites,
      };
    }
    case type.PHONEBOOK_REMOVE_FROM_FAVORITE: {
      const favorites = state.favorites.filter((id) => id !== action.payload);
      nativeStorageInterface.set("phonebook-favourites", favorites);
      return {
        ...state,
        favorites,
      };
    }
    case type.PHONEBOOK_READ_FAVORITE_FROM_COOKIE: {
      return {
        ...state,
        favorites: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
