import {
  CAMPUS_COLLECTION,
  CONTENT,
  POI_NODES,
  POI_NODES_Object,
  POIS,
} from "../../reduxStore/actions/main.js";
import {
  center,
  point,
  booleanPointInPolygon,
  featureCollection,
  nearestPoint,
} from "@turf/turf";
import { getNodeById } from "./node.class.js";
import { poitypes } from "./poi.class.js";

export default class StartDestination {
  static types = {
    start: "start",
    legChange: "legChange",
    destination: "destination",
  };
  get poiId() {
    return this.globalPoiId;
  }
  _poiId;
  set poiId(value) {
    this._poiId = value;
  }

  /**
   * @param poiId
   * @param nodeId
   * @param lat
   * @param lng
   * @param isSet
   * @param level
   * @param {"user" | "coordinates" | "node"} positionType
   * @param indoors
   * @param name
   * @param campusId
   * @param address
   * @param changeable
   * @param {(poitypes)} poiType
   * @param setPositionOnMap
   */
  constructor({
    poiId = null,
    nodeId = null,
    lat = null,
    lng = null,
    isSet = false,
    level = 0,
    positionType = "location",
    indoors = null,
    name = null,
    campusId = null,
    address = null,
    changeable = true,
    setPositionOnMap = false,
    position = null,
    type: poiType = undefined,
  } = {}) {
    this.id = poiId;
    this.globalPoiId = poiId;
    this.nodeId = nodeId;
    this.lat = +lat;
    this.lng = +lng;
    this.isSet = this.checkIfSet(isSet);
    this.indoors =
      indoors != null ? !!indoors : isSet ? this.isCoordsIndoor(+level) : false;
    this.level = this.indoors ? +level : 0;
    this.positionType = positionType;
    this.name = name;
    this.changeable = changeable;
    this.campusId =
      campusId != null
        ? campusId
        : isSet
        ? this.getCampusIdForCoordinates()
        : null;
    this.address = address;
    this.timestamp = new Date().getTime();
    this.type = poiType;
    // Soll die jeweilige Component auffordern Start/Ziel selbst von der Map aus zu bestimmen
    this.setPositionOnMap = setPositionOnMap;

    if (nodeId != null && (lat == null || lng == null)) {
      this.setLatLngByNodeId();
    }
    this.modifyForCertainTypes();
  }

  modifyForCertainTypes() {
    switch (this.type) {
      case poitypes.campus:
        this.nodeId = null;
        this.poiId = null;
        break;
      default:
        break;
    }
  }

  isNotSet() {
    return this.lng === 0 && !this.id;
  }
  checkIfSet(isSet) {
    if (
      this.poiId == null &&
      this.nodeId == null &&
      (this.lat == null ||
        this.lng == null ||
        (this.lat === 0 && this.lng === 0))
    ) {
      return false;
    } else return isSet;
  }
  static createStartDestination(nodeId, fallbackName = "") {
    const getNodeAndCampusId = (nodeId) => {
      let campusId = null,
        node = null;
      CAMPUS_COLLECTION.getAllCampuses().forEach((campus) => {
        if (null === node) {
          campusId = campus.id;
          node = campus.nodes.find((n) => n.id === +nodeId);
        }
      });
      return { node, campusId };
    };
    let poi;
    let showFallbackName = false;

    let { node, campusId } = getNodeAndCampusId(nodeId);

    if (!node) {
      /** @type POI */
      poi = POIS.all.find((p) => p.id === nodeId && p.category !== 484);
      node = getNodeAndCampusId(poi.nodeId).node;
    } else {
      poi = POIS.all.find(
        (p) => p.id === +node.zielGPOIId && p.category !== 484
      );
    }
    if (!poi && node) {
      showFallbackName = true;
      poi = POIS.all.find(
        (p) => p.id === +node.zielGPOIId && p.category === 484
      );
    } else if (!poi) {
      throw `Vertex with id:"${nodeId}" not found`;
    }

    return new StartDestination({
      poiId: poi ? poi.id : null,
      nodeId: node.id,
      isSet: true,
      lng: node.coord[1],
      lat: node.coord[0],
      level: node.coord[2],
      indoors: poi ? poi.indoors : false,
      name: showFallbackName ? fallbackName : poi.name,
      campusId,
      address: poi ? poi.address : null,
      type: poi ?? poi.type,
    });
  }

  getCampusIdForCoordinates() {
    if (this.lat == null || this.lng == null) return null;
    let node = point([this.lng, this.lat]);
    let campusId = null;

    for (let i = 0; i < CAMPUS_COLLECTION.campuses.length; i++) {
      let campus = CAMPUS_COLLECTION.campuses[i];
      if (campus.poi == null) continue;

      let polygonObject = campus.poi.polygonObject;
      // sind diese Koordinaten in einem dieser gebäude ? indoor : outdoor
      if (polygonObject) {
        if (booleanPointInPolygon(node, polygonObject)) {
          campusId = campus.id;
          break;
        }
      }
    }
    return campusId;
  }

  isCoordsIndoor(level = this.level) {
    if (this.lat == null || this.lng == null) return null;
    let isIndoor = false;
    let node = point([this.lng, this.lat]);
    // floors mit dem richtigen level
    for (let i = 0; i < POIS.floors.length; i++) {
      let floor = POIS.floors[i];
      let polygonObject = floor.polygonObject;

      if (floor && floor.level == level && polygonObject) {
        try {
          isIndoor = booleanPointInPolygon(node, polygonObject);
        } catch (e) {
          console.warn(
            "Fehler bei indoormessung. Indoor wurde auf false gesetzt",
            node,
            polygonObject
          );
        }
        // es gibt mehere gebäude, mit stockwerken, die dem level entsprechen
        // wenn nur eine davon als indoor zählt ist der user indoor
        if (isIndoor) {
          break;
        }
      }
    }

    return !!isIndoor;
  }

  setName(name) {
    this.name = name;
  }

  set(value) {
    this.isSet = value;
  }

  getPoint() {
    if (this.poiId != null) {
      // let searchedPOI = POIS.all.find((poi) => poi.id === this.poiId);
      let searchedPOI = POIS.all_objects[this.poiId];
      if (searchedPOI != null) {
        const optimalNodeId = searchedPOI.getRouteNodeId();
        if (optimalNodeId) {
          const nodeObject = POI_NODES_Object[optimalNodeId];
          if (nodeObject) return nodeObject;
        }
        if (searchedPOI.nodeObject != null) {
          return searchedPOI.nodeObject;
        }
        if (searchedPOI.polygonObject != null) {
          return center(searchedPOI.polygonObject);
        }
      }
    }
    if (this.nodeId != null) {
      let searchedNode = POI_NODES.find((p) => p.id === this.nodeId);
      if (searchedNode != null) {
        return searchedNode;
      }
    }
    if (this.lng && this.lat) {
      return point([this.lng, this.lat], {}, { id: -1 });
    }
  }

  /**
   * Such die richtige Node und lege lng/lat danach fest
   */
  setLatLngByNodeId() {
    if (this.nodeId == null) return;

    let node = getNodeById(this.nodeId, CAMPUS_COLLECTION.getAllCampusNodes());
    if (node != null && node.coord != null) {
      this.lng = node.coord[1];
      this.lat = node.coord[0];
      this.level = node.coord[2];
    }
  }

  getCampusKey() {
    return CAMPUS_COLLECTION.campuses.find(
      (campus) => campus.id === this.campusId
    ).campusKey;
  }

  getDataForRouteCalculation(targetCampusId = null) {
    return {
      ...this,
      isIndoor: this.indoors,
      globalPoiId: this.poiId != null ? this.poiId : -1,
      poiId: this.poiId != null ? this.poiId : -1,
      zIndex: this.level,
      id: this.nodeId ?? -1,
      campusId:
        targetCampusId === null || targetCampusId === this.campusId
          ? this.campusId
          : null,
    };
  }

  updateZIndexWhenNecessary() {
    setTimeout(() => {
      if (this.indoors) return;
      const startCampus = CAMPUS_COLLECTION.getCampusByPosition(
        this.lat,
        this.lng
      );

      if (!startCampus) return;

      const allNodes = featureCollection(
        startCampus.nodes.reduce((result, node) => {
          if (node.indoors) return result;

          result.push(
            point([node.coord[1], node.coord[0]], { zIndex: node.coord[2] })
          );

          return result;
        }, [])
      );

      const nearest = nearestPoint(point([this.lng, this.lat]), allNodes);

      if (!nearest) return;

      this.level = nearest.properties.zIndex;
    }, 1500);
  }
}
