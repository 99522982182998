import { CAMPUS_COLLECTION } from "../../../reduxStore/actions/main";
import { useIntl } from "react-intl";
import StandardButton from "../buttons/standard.button.jsx";

export function CampusSelection(props) {
  const intl = useIntl();

  if (CAMPUS_COLLECTION.campuses.length < 2) return null;

  const filterText = `${intl.formatMessage({ id: "search.filter" })} ${
    props.selectedCampuses.length > 0
      ? `(${props.selectedCampuses.length})`
      : ""
  }`;

  return (
    <div className="d-flex overflow-auto text-nowrap pt-3 pb-3">
      <StandardButton
        title={filterText}
        icon={["fal", "filter"]}
        buttonClasses={`normalButton--primary ${
          props.selectedCampuses.length > 0
            ? "normalButton--itemSelection--selected"
            : "normalButton--itemSelection--unselected"
        } mr-3`}
        handleClick={props.reset}
      >
        {filterText}
      </StandardButton>

      {CAMPUS_COLLECTION.campuses.map((campus) => (
        <StandardButton
          buttonClasses={`mr-3 normalButton--filterSelection ${
            props.selectedCampuses.find((campusId) => campusId === campus.id)
              ? "normalButton--filterSelection--selected"
              : "normalButton--filterSelection--unselected"
          }`}
          title={campus.name}
          handleClick={() => props.toggle(campus.id)}
          key={campus.id}
        >
          {campus.name}
        </StandardButton>
      ))}
    </div>
  );
}
