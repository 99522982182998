import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import * as actionRoute from "../../../../reduxStore/actions/route";
import * as actionSearch from "../../../../reduxStore/actions/search";
import { a, config, useSpring } from "react-spring";
import PropTypes from "prop-types";
import classes from "./routeSearchHeader.module.scss";
import RoutSettings from "../RouteSettings/RouteSettings.component.jsx";
import * as routeAction from "../../../../reduxStore/actions/route";
import * as debugAction from "../../../../reduxStore/actions/debug.js";
import * as mapAction from "../../../../reduxStore/actions/map.js";
import { withRouter } from "react-router-dom";
import RoundedIcon from "../../../elements/buttons/roundedIcon.component.jsx";
import StandardButton from "../../../elements/buttons/standard.button.jsx";
import StandardCard from "../../../elements/cards/StandardCard.card.jsx";
import mapUIStyles from "../../ui.module.scss";
import { isMobile } from "react-device-detect";
import {
  setCurrentAppOverlay,
  setIsTerminal,
} from "../../../../reduxStore/actions/main.js";
import {
  AppOverlay,
  AppOverlaySearch,
} from "../../../../functions/classes/miscelleanous.classes.js";
import StartDestination from "../../../../functions/classes/startDestination.class.js";
import Search from "../../../search/search.jsx";
import {
  STANDARD_MAP_PADDING,
  STANDARD_MAP_PADDING_DESKTOP,
} from "../../../../reduxStore/reducers/map.js";
import { cancelRequest } from "../../../../reduxStore/actions/route";
import { statisticLogger } from "../../../statisticLogger/StatisticLogger.container";
import {
  terminalDualScreenPortion,
  terminalNavigationLock,
} from "../../../../app/app.sideEffects/useURLParams.sideEffects";

/**
 * Befindet sich im Route Component. Lässt Start und Ziel wählen und setzt auch gleichzeitig die States
 * für Start und Ziel.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function RouteHeader(props) {
  const intl = useIntl();
  const [showSettings, setShowSettings] = useState(false);
  const [currentSettings] = useState("");
  const container = useRef(null);
  const [springProps, springAPI] = useSpring(() => ({
    config: config.wobbly,
    from: { transform: "translateY(-100px)", opacity: 0 },
    opacity: 1,
    transform: "translateY(0)",
  }));
  const [type, setType] = useState("");

  const handleOpenSearch = (type) => {
    setType(type);
    // diese beiden hier sollten verhindern, dass
    // er eine route sucht, obwohl er bereits eine neue gefunden hat
    // das canceln geht irgendwie nicht
    cancelRequest();

    let setStartOrTarget;
    if (type === "start") {
      props.setStart(new StartDestination());
      setStartOrTarget = props.setStart;
    } else {
      props.setTarget(new StartDestination());
      setStartOrTarget = props.setTarget;
    }
    props.setCurrentAppOverlay(
      new AppOverlaySearch(isMobile, type, {
        onClose: () => props.setCurrentAppOverlay(new AppOverlay()),
        onPOIClicked: (poi) => {
          if (terminalDualScreenPortion === 0) {
            // beim terminalmode im dualscreen muss der searchscreen immer die route funktionalität behalten!
            props.setCurrentAppOverlay(new AppOverlay());
          }
          setStartOrTarget(
            new StartDestination({
              poiId: poi.id,
              nodeId: poi.nodeId,
              isSet: true,
              lng: poi.getFeatureCenter()[0],
              lat: poi.getFeatureCenter()[1],
              level: poi.level,
              indoors: poi.indoors,
              name: poi.name,
              campusId: poi.campusId,
              address: poi.address,
              type: poi.type,
            })
          );
          let contentType;
          if (type === "start") {
            contentType = {
              startGlobalPoiId: poi.globalPoiId,
            };
          } else {
            contentType = {
              destinationGlobalPoiId: poi.globalPoiId,
            };
          }
          statisticLogger.addLog({
            action: {
              group: "Search",
              movement: "leave",
              interaction: "select",
              id: "searchSelectRoutePOI",
              name: "Search Select Route POI",
              type: "click",
              content: {
                globalPoiIds: [poi.globalPoiId],
                globalPoiId: poi.globalPoiId,
                category: poi.category,
                campusId: poi.campusId,
                globalName: poi.name,
                ...contentType,
              },
            },
          });
        },
        onSpecialLocationClicked: (startDestination) => {
          props.setCurrentAppOverlay(new AppOverlay());
          if (startDestination.setPositionOnMap) {
            props.setMarkingPositionMode({
              active: true,
              type:
                type === "start"
                  ? StartDestination.types.start
                  : StartDestination.types.destination,
            });
          } else {
            setStartOrTarget(startDestination);
          }
        },
        mustHaveLocation: true,
        canSetCurrentPosition: true,
        canSetPositionOnMap: true,
      })
    );
  };
  const handleDualScreenSearchBox = () => {
    if (terminalDualScreenPortion === 0) return;
    // Die Search im dualscreen soll direkt neue onClick funktion erhalten!
    handleOpenSearch();

    return () => {
      props.setCurrentAppOverlay(new AppOverlay());
    };
  };
  const togglePoints = () => {
    const temp = props.start;
    props.setStart(props.target);
    props.setTarget(temp);
    props.setFetchNewRoute(true);
  };
  const resetMapPadding = () => {
    return () => {
      props.setMapPadding(
        isMobile
          ? { top: STANDARD_MAP_PADDING.top }
          : { top: STANDARD_MAP_PADDING_DESKTOP.top },
        true
      );
    };
  };
  const settingsClosed = () => {
    setShowSettings(false);
    if (JSON.stringify(props.settings !== currentSettings)) {
      props.setFetchNewRoute(true);
    }
  };

  useEffect(() => {
    const y = props.isVisible ? 0 : -100;
    const opacity = props.isVisible ? 1 : 0;

    springAPI.start({
      transform: `translateY(${y}px)`,
      opacity: opacity,
      immediate: false,
      config: { ...config.stiff },
    });

    if (props.isVisible) {
      if (isMobile) {
        // bei Mobile ist der header oben
        props.setMapPadding({ top: container.current.clientHeight - 10 }, true);
      } else {
        // bei desktop ist der header an der seite
        props.setMapPadding({ left: container.current.clientWidth - 10 }, true);
      }
    } else {
      props.setMapPadding({ [isMobile ? "top" : "left"]: 0 }, true);
    }
  }, [props.isVisible]);
  useEffect(handleDualScreenSearchBox, []);
  useEffect(resetMapPadding, []);

  if (
    !isMobile &&
    props.currentAppOverlay?.name === AppOverlay.names.search &&
    terminalDualScreenPortion === 0
  ) {
    return (
      <div
        className={`${mapUIStyles.upperBar} general-margin-horizontally d-flex flex-column `}
      >
        <StandardCard className={"h-100 mb-3"}>
          <Search
            type={type}
            onClose={props.currentAppOverlay.onClose}
            mustHaveLocation={props.currentAppOverlay.mustHaveLocation}
            onPOIClicked={props.currentAppOverlay.onPOIClicked}
            canSetCurrentPosition={
              props.currentAppOverlay.canSetCurrentPosition
            }
            canSetPositionOnMap={props.currentAppOverlay.canSetPositionOnMap}
            onSpecialLocationClicked={
              props.currentAppOverlay.onSpecialLocationClicked
            }
          />
        </StandardCard>
      </div>
    );
  } else
    return (
      <>
        <a.div
          className={`${mapUIStyles.upperBar} d-flex general-margin-horizontally`}
          style={springProps}
          ref={container}
        >
          {!terminalNavigationLock && (
            <RoundedIcon
              ariaLabel={intl.formatMessage({
                id: "route.screenreader.leaveRouteMode",
              })}
              icon={["fal", "arrow-left"]}
              divClass={
                "roundedIcon--back roundedIcon--back__routeHeader mt-1 mr-3 pointer-event-on"
              }
              iconClass={
                "roundedIcon--back__icon roundedIcon--back__routeHeader__icon"
              }
              onClick={props.handleClose}
            />
          )}
          {/*min-width-0 ist wichtig, weil ansonsten flexbox probleme mit white-space: no-wrap hat*/}
          <div
            className={`d-flex flex-column ${classes.desktopHeaderContainer} w-100`}
          >
            <StandardCard
              padding={false}
              className={`standardCard__routeHeader p-1 w-100 pointer-event-on`}
            >
              <div
                className={`${classes.buttonContainer} d-flex align-items-center`}
              >
                <LocationInput
                  type={StartDestination.types.start}
                  onClick={() => handleOpenSearch("start")}
                  disabled={props.isTerminal && props.defaultStartNode != null}
                  inputSelection={props.start}
                  inputSelected={props.start?.isSet}
                  unsetMessage={intl.formatMessage({ id: "route.setStart" })}
                />
                {props.isTerminal ? null : (
                  <RoundedIcon
                    ariaLabel={intl.formatMessage({
                      id: "route.screenreader.routenEinstellungen",
                    })}
                    icon={["fal", "sliders-h"]}
                    onClick={() =>
                      props.setRouteCardOpeningStatus((prevState) =>
                        prevState === "open" ? "preOpen" : "open"
                      )
                    }
                    divClass={`roundedIcon__routeHeader ${
                      props.isTerminal ? "hidden" : ""
                    }`}
                    iconClass={"roundedIcon__routeHeader__icon"}
                  />
                )}
              </div>
              <div
                className={`${classes.buttonContainer} d-flex align-items-center`}
              >
                <LocationInput
                  type={StartDestination.types.destination}
                  onClick={() => handleOpenSearch("target")}
                  unsetMessage={intl.formatMessage({ id: "route.setTarget" })}
                  inputSelection={props.target}
                  inputSelected={props.target?.isSet}
                  iconSelected={"map-marker"}
                  iconUnselected={["fal", "map-marker"]}
                />
                {props.isTerminal ? null : (
                  <RoundedIcon
                    ariaLabel={intl.formatMessage({
                      id: "route.screenreader.switchStartTarget",
                    })}
                    divClass={`roundedIcon__routeHeader ${
                      props.isTerminal && props.defaultStartNode != null
                        ? "hidden"
                        : ""
                    }`}
                    iconClass={"roundedIcon__routeHeader__icon"}
                    icon={["fal", "sort"]}
                    onClick={togglePoints}
                  />
                )}
              </div>
            </StandardCard>
            {props.routeCard && !isMobile && (
              <div className={"d-flex mt-3 pointer-event-on overflow-hidden"}>
                {props.routeCard}
              </div>
            )}
          </div>
        </a.div>
        <RoutSettings isOpen={showSettings} close={settingsClosed} />
      </>
    );
}

/**
 *
 * @param onClick
 * @param {StartDestination}inputSelection
 * @param unsetMessage
 * @param iconUnselected
 * @param iconSelected
 * @param inputSelected
 * @return {JSX.Element}
 * @constructor
 */
function LocationInput({
  onClick,
  inputSelection,
  unsetMessage,
  iconUnselected = ["fal", "circle"],
  iconSelected = "circle",
  inputSelected,
  disabled = false,
  type = StartDestination.types.start,
}) {
  const intl = useIntl();
  const getAriaLabel = () => {
    if (inputSelected) {
      if (type === StartDestination.types.start) {
        return intl.formatMessage(
          { id: "route.screenreader.startIsSet" },
          { name: inputSelection.name }
        );
      } else {
        return intl.formatMessage(
          {
            id: "route.screenreader.targetIsSet",
          },
          { name: inputSelection.name }
        );
      }
    } else {
      if (type === StartDestination.types.start) {
        return intl.formatMessage({ id: "route.screenreader.setStart" });
      } else {
        return intl.formatMessage({ id: "route.screenreader.setTarget" });
      }
    }
  };

  return (
    <StandardButton
      ariaLabel={getAriaLabel()}
      icon={inputSelected ? iconSelected : iconUnselected}
      handleClick={disabled || terminalNavigationLock ? null : onClick}
      buttonClasses={`normalButton--itemSelection normalButton__routeHeader w-100 ${
        inputSelected
          ? "normalButton--itemSelection--selected normalButton__routeHeader--selected"
          : "normalButton--itemSelection--unselected normalButton__routeHeader--unselected"
      } ${
        disabled ? "normalButton--itemSelection--selected--disabled" : ""
      } overflow-hidden mr-2`}
      textClasses={"text-overflow-ellipsis"}
    >
      {inputSelected ? inputSelection.name : unsetMessage}
    </StandardButton>
  );
}

const mapStateToProps = (state) => {
  return {
    currentFeature: state.main.currentFeature,
    currentAppOverlay: state.main.currentAppOverlay,
    start: state.route.start,
    target: state.route.target,
    isTerminal: state.main.isTerminal,
    isApp: state.main.isApp,
    settings: state.settings.routing,
    defaultStartNode: state.route.defaultStartNode,
    locationObject: state.route.locationObject,
    userAllowedGPS: state.settings.location.userAllowed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMustHaveLocation: (value) =>
      dispatch(actionSearch.setMustHaveLocation(value)),
    setStart: (gpsObject) => dispatch(actionRoute.setStart(gpsObject)),
    setTarget: (gpsObject) => dispatch(actionRoute.setTarget(gpsObject)),
    resetRoute: () => dispatch(actionRoute.resetRoute()),
    setSearchTarget: (type) => dispatch(actionSearch.setTarget(type)),
    setMapPadding: (object, tellMap) =>
      dispatch(mapAction.setMapPadding(object, tellMap)),
    setDebuggingValues: (object) =>
      dispatch(debugAction.setDebuggingValues(object)),
    setFetchNewRoute: (status) =>
      dispatch(routeAction.setFetchNewRoute(status)),
    setCurrentAppOverlay: (currentAppOverlaySearch) =>
      dispatch(setCurrentAppOverlay(currentAppOverlaySearch)),
  };
};
RouteHeader.propTypes = {
  handleClose: PropTypes.func,
  routeCard: PropTypes.element,
  setMarkingPositionMode: PropTypes.func,
  fetchingRoute: PropTypes.bool,
  setRouteCardOpeningStatus: PropTypes.func,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RouteHeader)
);
