import { createStore, compose, applyMiddleware } from "redux";
import { createBrowserHistory } from "history";
import reducer from "./reducers";
import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/**
 * Hier wird der Store erstellt und holt sich (von ./reducers die index.js)
 * seine reducer
 * @type {Store<unknown, Action>}
 */

export const history = createBrowserHistory({
  basename: window.home.replace("index.html", "").replace("index.php", ""),
});

export default function configureStore(preloadedState) {
  const store = createStore(
    reducer(history), // root reducer with router state
    preloadedState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunk))
  );

  return store;
}
