import { callIPhoneFunctions } from "../NativeAppInterface.jsx";
import { useEffect } from "react";

export function useHealthStatsPermissionCall(properties, setProperties) {
  const tellIphoneToUseStats = (value) => {
    callIPhoneFunctions(["requestMotionActivityManager?allowed=" + value]);
  };

  useEffect(() => {
    if (properties.healthStats.userAllowed) {
      tellIphoneToUseStats(true);
    } else {
      tellIphoneToUseStats(false);
    }
  }, [properties.healthStats.userAllowed]);
}
