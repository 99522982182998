import { map } from "../../map.sideEffects/useMap.sideEffects.js";
import { STYLINGVALUES } from "../../variables/layerStylingValues.js";
import { EXPRESSIONS } from "../../variables/expressions.js";
import { layerIds } from "../../variables/layerIds.js";
import { sourceIds } from "../../variables/sourceIds.js";
import { zoomCampusEnd, zoomCampusStart } from "../filter.map.js";
import { CUSTOMIZABLE_VALUES } from "../../variables/CUSTOMIZABLE_VALUES.js";

/**
 * Marker die auftauchen,wenn man weit genug aus der Map rauszoomt um zu erkennen, wo  die verschiedenen Campuses sind
 */
export function createCampusSymbols() {
  const filter = [
    "case",
    EXPRESSIONS.type.campus,
    ["all", [">", ["zoom"], zoomCampusStart], ["<", ["zoom"], zoomCampusEnd]],
    false,
  ];

  if (!map.getLayer(layerIds.nodes.campus.symbol)) {
    map.addLayer({
      id: layerIds.nodes.campus.symbol,
      type: "symbol",
      source: sourceIds.polygons,
      filter: filter,
      layout: {
        "icon-image": "campus",
        "icon-size": 0.4,
        "icon-anchor": "bottom",
        "text-font": CUSTOMIZABLE_VALUES.facilityTextFont,
        "text-field": EXPRESSIONS.misc.name,
        "text-anchor": "top",
      },
      paint: {
        "text-halo-width": 1.5,
        "text-halo-color": "#ffffff",
        "text-translate": [0, 5],
        "text-color": [
          "case",
          EXPRESSIONS.state.marking,
          CUSTOMIZABLE_VALUES.route_node_marking,
          CUSTOMIZABLE_VALUES.route_node_bg,
        ],
        "icon-color": [
          "case",
          EXPRESSIONS.state.marking,
          CUSTOMIZABLE_VALUES.route_node_marking,
          CUSTOMIZABLE_VALUES.route_node_bg,
        ],
        "icon-opacity": [
          "case",
          EXPRESSIONS.state.differentLevel,
          STYLINGVALUES.symbols.opacity.navigation.ghosted,
          1,
        ],
      },
    });
  }
}
