import StandardButton from "../../elements/buttons/standard.button.jsx";
import { useIntl } from "react-intl";

export default function CategoryButtons(props) {
  const intl = useIntl();
  const activeClass = {
    textClasses: "normalButton--filterSelection--selected__icon",
    buttonClasses:
      "normalButton--filterSelection normalButton--filterSelection--selected mr-3 mb-3",
  };

  const inactiveClass = {
    iconClasses: "normalButton--filterSelection--unselected__icon",
    buttonClasses:
      "normalButton--filterSelection normalButton--filterSelection--unselected mr-3 mb-3",
  };

  const localSting =
    intl.locale === "de"
      ? "de_DE"
      : intl.locale === "en"
      ? "en_EN"
      : intl.locale;

  if (props.alphabetical) {
    return props.categories
      .sort((a, b) => {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      })
      .map((c) => {
        const isActive = props.selectedCategories.find(
          (catId) => catId === c.id
        );
        return (
          <StandardButton
            role={"option"}
            icon={isActive ? ["fal", "check"] : null}
            ariaLabel={
              isActive
                ? intl.formatMessage({
                    id: "search.filter.screenreader.deselect",
                  }) + c.bezeichnungLabel[localSting] ?? c.name
                : intl.formatMessage({
                    id: "search.filter.screenreader.select",
                  }) + c.bezeichnungLabel[localSting] ?? c.name
            }
            {...(isActive ? activeClass : inactiveClass)}
            noFlex
            ariaSelected={isActive}
            handleClick={() => props.toggleCategory(c.id)}
            key={c.id}
          >
            {c.bezeichnungLabel[localSting] ?? c.name}
          </StandardButton>
        );
      });
  } else {
    return props.categories
      .sort((a, b) => b.prio - a.prio)
      .map((c) => {
        const isActive = props.selectedCategories.find(
          (catId) => catId === c.id
        );
        return (
          <StandardButton
            icon={isActive ? ["fal", "check"] : null}
            {...(isActive ? activeClass : inactiveClass)}
            noFlex
            ariaLabel={
              isActive
                ? intl.formatMessage({
                    id: "search.filter.screenreader.deselect",
                  }) + c.bezeichnungLabel[localSting] ?? c.name
                : intl.formatMessage({
                    id: "search.filter.screenreader.select",
                  }) + c.bezeichnungLabel[localSting] ?? c.name
            }
            role={"option"}
            ariaSelected={isActive}
            handleClick={() => props.toggleCategory(c.id)}
            key={c.id}
          >
            {c.bezeichnungLabel[localSting] ?? c.name}
          </StandardButton>
        );
      });
  }
}
