import * as types from "../types.variables";
export const initState = {
  allToasts: [],
  addToasts: [],
  deleteToasts: [],
};
let newAddToasts = [],
  newAllToasts = [],
  newDeletedToasts = [];

export default function main(state = initState, action) {
  switch (action.type) {
    case types.TOAST_ADD_TOAST:
      if (action.payload.id == null) throw "Toast hat keine Id";
      newAddToasts = state.addToasts.concat(action.payload);
      // wenn ein Toast dieses Types bereits angezeigt wird, dann nicht nochmal hinzufügen
      let typeAlreadyShown = false;
      state.allToasts.forEach((t) => {
        if (t.id_type === action.payload.id_type) {
          typeAlreadyShown = true;
        }
      });
      state.addToasts.forEach((t) => {
        if (t.id_type === action.payload.id_type) {
          typeAlreadyShown = true;
        }
      });

      if (!typeAlreadyShown) {
        return {
          ...state,
          addToasts: newAddToasts,
        };
      } else {
        return state;
      }

    case types.TOAST_DELETE_TOAST:
      newDeletedToasts = state.deleteToasts.concat({
        ...action.payload,
        id_type: action.payload.id,
      });

      return {
        ...state,
        deleteToasts: newDeletedToasts,
      };

    case types.TOAST_SET_TOAST_ADDED:
      // aus addToasts den geaddeten Toast raushauen
      newAddToasts = state.addToasts.filter(
        (toast) => toast.id !== action.payload.id
      );
      // und stattdessen in allToasts hauen (wenn nicht bereits drin)
      if (state.allToasts.indexOf(action.payload) === -1) {
        newAllToasts = state.allToasts.concat(action.payload);
      }

      return {
        ...state,
        addToasts: newAddToasts,
        allToasts: newAllToasts,
      };

    case types.TOAST_SET_TOAST_DELETED:
      // todo: muss noch gemacht werden
      newDeletedToasts = state.deleteToasts.filter((toast) => {
        return toast.id !== action.payload.id;
      });
      newAllToasts = state.allToasts.filter((toast) => {
        return toast.id !== action.payload.id;
      });
      return {
        ...state,
        deleteToasts: newDeletedToasts,
        allToasts: newAllToasts,
      };

    default:
      return state;
  }
}
