import React from "react";
import styles from "./toasts.module.scss";
import { connect } from "react-redux";
import * as actionToast from "../../../reduxStore/actions/toasts.js";
import Toast from "./toast.render";

/**
 * Toast wird von der Action setToast(..) gesteuert und kann an beliebigen Stellen Parameterlos eingesetzt werden (er hat weder height, noch width).
 * Dieser Container ist nur fürs Rendering der Toasts zuständig. Die Toasts selbst bestimmen in sich, wann sie wieder verschwinden und feuern
 * auch die Redux Action.
 * @param props
 * @constructor
 */
function ToastsContainer(props) {
  // diese Props sind mit einer state variable versetzt, die
  // dem Toast Component sagt, wie es sich zu verhalten soll
  return (
    <div className={styles.relativeContainer}>
      <div
        className={`${styles.absoluteContainer} d-flex flex-column align-items-center`}
      >
        {props.allToasts.map((toast) => {
          return (
            <Toast
              key={toast.spawnTime + "all"}
              state={toast.tapped ? "tapped" : "stay"}
              setToastDeleted={props.setToastDeleted}
              setToastAdded={props.setToastAdded}
              toast={toast}
            />
          );
        })}
        {props.addToasts.length
          ? props.addToasts.map((toast) => {
              return (
                <Toast
                  key={toast.spawnTime + "new"}
                  state={"add"}
                  setToastDeleted={props.setToastDeleted}
                  setToastAdded={props.setToastAdded}
                  toast={toast}
                />
              );
            })
          : null}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    allToasts: state.toasts.allToasts,
    addToasts: state.toasts.addToasts,
    deleteToasts: state.toasts.deleteToasts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setToastAdded: (toast) => dispatch(actionToast.setToastAdded(toast)),
    setToastDeleted: (toast) => dispatch(actionToast.setToastDeleted(toast)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ToastsContainer);
