import {
  BUILDING_GEOJSONS,
  CAMPUS_COLLECTION,
} from "../../../../reduxStore/actions/main";
import { map } from "../map.sideEffects/useMap.sideEffects.js";
import mapboxgl from "mapbox-gl";
import { MAP_UI_NAMES } from "../../../../reduxStore/reducers/map.js";
import { pageNames } from "../../../../reduxStore/reducers/main.js";
import getPoiMarker from "../../../../assets/images/map/staticIcons/poimarker.js";
import { EXPRESSIONS } from "../variables/expressions.js";
import { sourceIds } from "../variables/sourceIds.js";
import { layerIds } from "../variables/layerIds.js";
import { STYLINGVALUES } from "../variables/layerStylingValues.js";
import { filterFacilitySymbols } from "./filters/filterFacilitySymbols.js";
import { filterPOISymbols } from "./filters/filterPOISymbols.js";
import { filterRoomTexts } from "./filters/filterRoomTexts.js";
import { filterRoomPolygons } from "./filters/filterRoomPolygons.js";
import { filterPOIChildren } from "./filters/filterPOIChildren.js";
import filterCampusSymbols from "./filters/filterCampusSymbols.js";
import { customLayer } from "./layerDeclarations/createTerminalPinSymbol.js";
import { set3DMaterialOpacity } from "./interactivity.map.js";
import { CUSTOMIZABLE_VALUES } from "../variables/CUSTOMIZABLE_VALUES.js";

import { center, multiPolygon, polygon } from "@turf/turf";
import { piKey } from "../../../../app/app.sideEffects/useURLParams.sideEffects";
/**
 * Diese Funktionen sind dafür zuständig bestehende POIs auf der Karte zu filtern
 * Die Filterfunktionsaufrufe kommen meist von map.component.jsx
 * Das filterobjekt "filters" wird meist vom mapdrawer verwendet.
 * Diese Properties wie z.B. "isBeacon" oder "isIndoor" werden den Node und Kanten im Transformator
 * oder Initializer hinzugefügt! Diese befinden sich für mapbox zugänglich in den "properties" der
 * features.
 *
 * Wenn Features nicht auf der Karte angezeigt werden, kann es sein
 * dass sie hier mit dem Etagenfilter ausgefiltert werden!
 */

/** Zoom Konstanten für die verschiedenen Layer Abstufungen */
// Zoom Important für sowas wie Notaufnahmen und alles, sachen die auch ganz oben zu sehen sein müssen
export let initialZoom = 15;
export let zoomImportant = 15;
export let zoomLowerCampus = 15;
export let zoomBuildingsStart = 11;
export let zoomBuildingsEnd = 17;
export let zoomCampusStart = 0;
export let zoomCampusEnd = 14.5;
export let zoomBuildings_normal_max = 16;
export let zoomLevels = 16;
export let zoomSymbolsStart = 17;
export let zoomSymbolsEnd = 20;
export let zoomAddressesStart = 15;
export let zoomAddressesEnd = 20;
export let zoomSymbolsUnimportantStart = 19;
export let zoomSymbolsUnimportantEnd = 22;
export let zoomRooms = 21;

// Einstellungen für bestimmte Zoommodi (z.b.: navigation zeigt die symbole erst viel lower an)
export const navigationZooms = {
  buildings: {
    start: 13,
    end: 15,
  },
  symbols: {
    start: 20,
    end: 24,
  },
  unimportantSymbols: {
    start: 19,
    end: 24,
  },
  campus: {
    start: 0,
    end: 5,
  },
};
export const routeZooms = {
  buildings: {
    start: 14,
    end: 16,
  },
  symbols: {
    start: 18,
    end: 24,
  },
  unimportantSymbols: {
    start: 20,
    end: 24,
  },
  campus: {
    start: 0,
    end: 5,
  },
};
export const defaultZooms = {
  buildings: {
    start: 15,
    end: 17,
  },
  symbols: {
    start: 17,
    end: 20,
  },
  unimportantSymbols: {
    start: 19,
    end: 20,
  },
  campus: {
    start: 0,
    end: zoomCampusEnd,
  },
};

/**
 * Zoomlevels können geändert werden, je nach Art der Mappage.
 * Diese Funktion sorgt dafür, dass z.B. die Route auch bei hohen zooms sichtbar ist und nicht durch lauter symbole
 * verdeckt wird! Während bei normalen MapInfo View die Symbole wichtiger sind und daher bei schon kleineren Zoom
 * Levels zu sehen sind!
 * @param {MAP_UI_NAMES} mapType
 */
export function setMapTypeZooms(mapType) {
  console.log("ICH PIKEY DU NICHTS", piKey);

  // unterscheiden zwischen Pages -> Änderung der Zoomslevels
  // bei Navigation muss die Kamera weiter rausgefahren werden können, damit Gebäude angezeigt werden!
  switch (mapType) {
    case MAP_UI_NAMES.navigation:
      zoomBuildingsEnd = navigationZooms.buildings.end;
      zoomSymbolsStart = navigationZooms.symbols.start;
      zoomSymbolsEnd = navigationZooms.symbols.end;
      zoomSymbolsUnimportantStart = navigationZooms.unimportantSymbols.start;
      zoomSymbolsUnimportantEnd = navigationZooms.unimportantSymbols.end;
      zoomCampusEnd = navigationZooms.campus.end;
      break;
    case MAP_UI_NAMES.route:
      zoomBuildingsEnd = routeZooms.buildings.end;
      zoomSymbolsStart = routeZooms.symbols.start;
      zoomSymbolsEnd = routeZooms.symbols.end;
      zoomSymbolsUnimportantStart = routeZooms.unimportantSymbols.start;
      zoomSymbolsUnimportantEnd = routeZooms.unimportantSymbols.end;
      zoomCampusEnd = routeZooms.campus.end;
      break;
    default: //
      zoomBuildingsEnd = defaultZooms.buildings.end;
      zoomSymbolsStart = defaultZooms.symbols.start;
      zoomSymbolsEnd = defaultZooms.symbols.end;
      zoomSymbolsUnimportantStart =
        piKey === "Askl-PR7GuJIP5SGRHyLqIxc6pI5YnTOdM4MXkcJs13AbNpq72"
          ? 17
          : defaultZooms.unimportantSymbols.start;
      zoomSymbolsUnimportantEnd = defaultZooms.unimportantSymbols.end;
      zoomCampusEnd = defaultZooms.campus.end;
  }
}

/**
 * Setzt einen Filter auf die Layers, der die Etage in den Properties vergleicht,
 * um die richtigen Features auf der richtigen Etage anzuzeigen
 * @param {number} level
 * @param {mapboxgl} map
 * @param {} target - Das Ziel der Navigation
 * @param {POI} currentFeature
 * @param {"route", "info", "navigation"} currentPage - Momentane Page der Map
 */
export function setFeatureFilters({
  level,
  currentPage,
  currentFeature,
  currentBoundBuildingId,
  target,
  setCurrentFloorNumber,
}) {
  level = +level;
  // Common Etagenfilter, der gilt meist für alle
  // dieses Array wird in den Switch Cases
  // mit weiteren mapbox Expressions ("all" ist quasi AND)
  // befüllt
  const filterRasterLayers = () => {
    // herausfinden der mapboxLayerId durch serverdaten
    let mapboxLayers = map.getStyle().layers;
    let mapboxLayerId;
    let geoJSONLevelArray = [];
    // abchecken, welche Stockwerke (levels) die FeatureCollection des gegebenen Geojson abdeckt!
    if (
      BUILDING_GEOJSONS &&
      BUILDING_GEOJSONS[currentBoundBuildingId]?.[
        sourceIds.interiorGeoJSONs.rooms
      ]?.properties.levels
    ) {
      geoJSONLevelArray =
        BUILDING_GEOJSONS[currentBoundBuildingId][
          sourceIds.interiorGeoJSONs.rooms
        ].properties.levels;
    }

    CAMPUS_COLLECTION.getCurrentCampus().floors.forEach((s) => {
      // check, welches stockwerk der floors mit dem momentanen level übereinstimmt
      // UND ob es dafür KEIN geojson gibt!
      if (s.stockwerk === level && geoJSONLevelArray.indexOf(level) === -1) {
        mapboxLayerId = s.mapboxLayerId;
      }
    });

    // // ground.geojson.json ausblenden, wenn maptiles verwendet werden
    // if (mapboxLayerId) {
    //   map.setLayoutProperty(
    //     layerIds.interiorGeoJSONs.ground,
    //     "visibility",
    //     "none"
    //   );
    // } else {
    //   map.setLayoutProperty(
    //     layerIds.interiorGeoJSONs.ground,
    //     "visibility",
    //     "visible"
    //   );
    // }

    // Layout Property von allen Layern außer diesem auf hidden setzen
    // diese Operation gilt nur für den "street" Modus, wo die Catchup eigenen Raster layer vorhanden sind
    mapboxLayers.forEach(({ id, type }) => {
      if (type === "raster") {
        // todo: das ist bei charité nur zum test, der bettenhauslayer muss hier wieder raus später
        if (id === mapboxLayerId && id !== "Indoor-Bettenhaus-01") {
          map.setLayoutProperty(id, "visibility", "visible");
        } else {
          map.setLayoutProperty(id, "visibility", "none");
        }
      }
    });
  };

  /**
   * Ist gedacht für Etagen, Zoom und Options (aus App Einstellunge raus) Filter
   */
  const filterLayers = () => {
    // Levelfilter für alle erstellen -> und gleich "all" für &&-Condition einstellen
    // important Icons werden nicht vom levelfilter beachtet
    const allFilters = [
      "all",
      [
        "any",
        ["==", ["get", "level"], level],
        ["==", ["get", "class"], "important"],
      ],
    ];

    filterPOISymbols([...allFilters], { target: target, level: level });
    filterPOIChildren([...allFilters], level);
    filterNavigationRouteLayers(currentPage, level);
    filterRoomPolygons([...allFilters], level);
    filterFacilitySymbols([...allFilters]);
    filterRoomTexts([...allFilters]);
    ghostTerminalPin(level);
    filterCampusSymbols();
    setSelectedFeatureMarker(currentFeature, level, setCurrentFloorNumber); // Das hier setzt die schwarzen Marker-
  };
  /**
   * ACHTUNG!! Filter der NUR Features komplett ausfiltert
   * Ist gedacht für Modewechsel (zwischen MapInfo, Route und Navigation)
   * Wenn man sicher ist, dass bei diesem Mode dieses eine Feature wirklich
   * komplett nicht benötigt wird, dann kann es hier eingetragen werden!
   */
  const filterCompletelyOut = () => {
    for (let superKey in layerIds) {
      // durch wirklichalle layerIds iterieren
      for (let key in layerIds[superKey]) {
        let layerId = layerIds[superKey][key];
        let allFilters = [];
        // bereits bestehende Filter der Layers holen
        if (map.getLayer(layerId)) {
          allFilters = map.getFilter(layerId);
        }
        // PageType switchen
        switch (currentPage) {
          case MAP_UI_NAMES.route:
            // jeweilige LayerId rausfiltern
            switch (layerId) {
              case layerIds.nodes.circles:
              case layerIds.nodes.outerCircles:
              case layerIds.nodes.symbols:
              case layerIds.nodes.circleShadows:
              case layerIds.texts.rooms:
                allFilters.push(["!", EXPRESSIONS.type.room]);
                break;
            }
            break;
          case MAP_UI_NAMES.navigation:
            switch (layerId) {
              case layerIds.nodes.circles:
              case layerIds.nodes.outerCircles:
              case layerIds.nodes.symbols:
              case layerIds.nodes.circleShadows:
              case layerIds.texts.rooms:
                allFilters.push(["!", EXPRESSIONS.type.room]);
                break;
            }
            break;
          case MAP_UI_NAMES.info:
            break;
        }
        // Filter mapbox mitteilen
        setLayerFilters([layerId], allFilters);
      }
    }
  };

  // Allgemeines Filtern der Mapstyle Layer nach Etage und so
  filterRasterLayers();
  // Aufrufen der FilterFunktionen für jeden Layer!
  filterLayers();
  filterCompletelyOut();
}

export function showRoomnames(value) {
  map.setLayoutProperty(
    layerIds.texts.rooms,
    "visibility",
    value ? "visible" : "none"
    // "visible"
  );
}

export function setMapStyle(mapStyle) {
  map.setStyle(mapStyle);
}

export function setBuildingFilters() {
  map.setBuildingZoomLevel(zoomBuildingsEnd);
}

/**
 * Anhand der Einstellung im Actionbubble (oben rechts in der MapInfo View) werden hier
 * 3D fähige Features getoggelt
 * @param show3DBuildings
 * @param {bool} navigation - Bei Navigation werden kleinere Zoomlevel zum filtern verwendet,
 * damit 3D Gebäude nicht die Sicht bei Höheren Kameras versperren
 */
export function set3DViewOfPolygons(show3DBuildings) {
  // Stockwerke müssen hier nicht mit einbezogen werden!
  let allFilters = ["all"];
  allFilters.push(
    [
      "case",
      EXPRESSIONS.type.room,
      ["all", ["<", ["zoom"], zoomRooms], [">=", ["zoom"], zoomLevels]],
      EXPRESSIONS.type.einrichtung,
      [
        "all",
        ["<=", ["zoom"], zoomSymbolsEnd],
        [">=", ["zoom"], zoomBuildingsEnd],
      ],
      ["any", EXPRESSIONS.type.stockwerk, EXPRESSIONS.type.parkplatz],
      ["all", ["<", ["zoom"], zoomLevels], [">=", ["zoom"], zoomBuildingsEnd]],
      EXPRESSIONS.type.gebaude,
      [
        "all",
        ["<", ["zoom"], zoomBuildingsEnd],
        [">", ["zoom"], zoomBuildingsStart],
        show3DBuildings,
      ],
      // Campus Polygon wird ausgeblendet
      EXPRESSIONS.type.campus,
      false,
      true,
    ],
    EXPRESSIONS.type.gebaude
  );

  // Stockwerke ausblenden
  allFilters.push([
    "all",
    ["!=", ["get", "type"], "Stockwerk"],
    ["!=", ["get", "type"], "Einrichtung"],
  ]);

  //
  setLayerFilters([layerIds.polygons.simple], allFilters);
}

/**
 * Filtert die NavigationsKanten
 */
function filterNavigationRouteLayers(
  currentPage = pageNames.navigation,
  level
) {
  const setLineDashing = () => {
    map.setPaintProperty(
      layerIds.kanten.navigation_dash,
      "line-dasharray",
      STYLINGVALUES.lines.dash
    );
    // switch (currentPage) {
    //   case pageNames.navigation:
    //     break;
    //   default:
    //     map.setPaintProperty(
    //       layerIds.kanten.navigation_dash,
    //       "line-dasharray",
    //       STYLINGVALUES.lines.dash
    //     );
    // }
  };
  // nicht machen, wos ned benötigt wird, damits ned bei info unnötig gefired wird
  if (currentPage === pageNames.info) return;
  let sources = [
    map.getSource(sourceIds.kanten),
    map.getSource(sourceIds.arrowHead),
    map.getSource(sourceIds.arrowKanten),
    map.getSource(sourceIds.routeSymbole),
  ];

  setLineDashing();
  setFeaturesGhosted(sources, level);
}

/**
 * Setzt Features, die auf einem anderen Level sind auf ghost
 * @param sources
 */
export function setFeaturesGhosted(sources, level) {
  sources.forEach((source) => {
    try {
      if (source._data.features.length) {
        source._data.features.forEach((feature, index) => {
          if (feature.properties.level !== level) {
            switch (feature.geometry.type) {
              case "Point":
                if (feature.properties.neverGhost) return;
              case "LineString":
                // Bei outdoor Lines wird nie geghosted!
                if (
                  feature.properties.isIndoor === false ||
                  feature.properties.neverGhost
                )
                  return;
            }
            map.setFeatureState(
              { id: feature.id, source: source.id },
              { differentLevel: true }
            );
          } else {
            map.setFeatureState(
              { id: feature.id, source: source.id },
              { differentLevel: false }
            );
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  });
}

/**
 * Wird benutzt um das Filterarray an die jeweilig mitgegebenen Layers zu setzen
 * @param {[]}layerIds
 * @param {[]}allFilters
 */
export function setLayerFilters(layerIds = [], allFilters) {
  layerIds.forEach((layerId) => {
    if (map.getLayer(layerId)) {
      try {
        map.setFilter(layerId, allFilters);
      } catch (e) {
        console.error("Error beim setzen der Filter", e);
      }
    }
  });
}

// #Markerfix
let pointMarker;
let pointMarkers = [];
/**
 * @param {POI} currentFeature
 * @param {number} level
 * @param {function} setCurrentFloorNumber
 */
function setSelectedFeatureMarker(
  currentFeature,
  level,
  setCurrentFloorNumber
) {
  let myFeatures = [];

  // console.log("Incoming currentFeature", currentFeature);

  if (currentFeature && currentFeature.selectedFeature) {
    myFeatures.push(currentFeature.selectedFeature);
  } else {
    if (currentFeature && currentFeature.children != null) {
      currentFeature.children.forEach((myChild) => {
        myFeatures.push(myChild.nodeObject);
      });
    }
  }

  if (pointMarker) pointMarker.remove();

  pointMarkers.forEach((lePointDuMark) => {
    lePointDuMark.remove();
  });

  myFeatures.forEach((feature) => {
    // console.log("LOOKHERE", feature);

    if (feature && feature.geometry.type === "Point") {
      // level setzen, falls dieses nicht mitgegeben wird
      if (level == null) level = feature.properties.level;

      let poiMarker;
      if (feature.properties.level === level) {
        poiMarker = getPoiMarker(
          CUSTOMIZABLE_VALUES.poiMarker,
          CUSTOMIZABLE_VALUES.poiMarker_outline
        );
      } else {
        // Das ist der Marker für ein ausgewähltes Objet.
        if (piKey === "zzUKT-7wbrGmoTcGMsoSHnYvS10mCRTb76Ws1uolN3s5kdLJUj9M") {
          poiMarker = getPoiMarker(CUSTOMIZABLE_VALUES.poiMarker);
        } else {
          poiMarker = getPoiMarker(CUSTOMIZABLE_VALUES.poiMarker_ghosted);
        }
      }

      // wenn man auf den poimarker klickt, soll die etage auf die des ausgewählten features
      // wechseln
      poiMarker.addEventListener("click", (e) => {
        setCurrentFloorNumber(feature.properties.level);
        e.stopPropagation();
      });
      poiMarker.children[0].style.height = 5 + "rem";
      pointMarker = new mapboxgl.Marker({
        anchor: "bottom",
        element: poiMarker,
      });
      pointMarkers.push(pointMarker);
      try {
        pointMarker.getElement().style.zIndex = 9999;
      } catch {}
      console.log("MEIN FEATURW", feature);
      pointMarker.setLngLat(feature.geometry.coordinates).addTo(map);
    } else if (
      feature &&
      (feature.geometry.type === "Polygon" ||
        feature.geometry.type === "MultiPolygon")
    ) {
      if (level == null) level = feature.properties.level;

      let poiMarker;
      if (feature.properties.level === level) {
        poiMarker = getPoiMarker(
          CUSTOMIZABLE_VALUES.poiMarker,
          CUSTOMIZABLE_VALUES.poiMarker_outline
        );
      } else {
        poiMarker = getPoiMarker(CUSTOMIZABLE_VALUES.poiMarker_ghosted);
      }

      poiMarker.addEventListener("click", (e) => {
        setCurrentFloorNumber(feature.properties.level);
        e.stopPropagation();
      });
      poiMarker.children[0].style.height = 5 + "rem";
      pointMarker = new mapboxgl.Marker({
        anchor: "bottom",
        element: poiMarker,
      });
      pointMarkers.push(pointMarker);
      try {
        pointMarker.getElement().style.zIndex = 9999;
      } catch {}

      const myCenter =
        feature.geometry.type === "Polygon"
          ? center(polygon(feature.geometry.coordinates))
          : center(multiPolygon(feature.geometry.coordinates));

      const pointMakerPosition = currentFeature.polygonCenter
        ? currentFeature.polygonCenter
        : center(myCenter.geometry);
      pointMarker.setLngLat(pointMakerPosition.geometry.coordinates).addTo(map);
    }
  });
}

// todo: wird noch nicht benutzt
export function navigationShowSpecificOrder(map, order) {
  let allFilters = ["all", ["==", ["get", "order"], order]];
  disabledRouteLayers(map, allFilters);
}

function disabledRouteLayers(map, filter) {
  for (let superKey in layerIds.kanten) {
    if (map.getLayer(layerIds.kanten[superKey])) {
      map.setFilter(layerIds.kanten[superKey], filter);
    }
  }
  // todo: das hier noch richtig machen
  //  map.setFilter(layerIds.nodes.route, filter);
}

/**
 * Hier werden Filter für die Layers gesetzt, welche NICHT Bestandteil dieses Codes sind. Dazu gehören die allgemeinen Namen von
 * Straßen, Gebäude etc..
 */
export function setOpenMapsFilters() {
  try {
    // todo: der hier ist sehr allgemein, er schmeißt halt alle hospitals raus
    const maplayers = map.getStyle().layers;
    const poilabellayer = maplayers.find((e) => (e.id = "poi-label"));
    if (poilabellayer?.filter) {
      const currentFilter = map.getFilter("poi-label");
      map.setFilter("poi-label", [
        "all",
        currentFilter,
        ["!=", ["get", "category_en"], "Hospital"],
      ]);
    }
  } catch (e) {
    console.error("Konnte den allgemeinen Filter nicht ausüben", e);
  }
}

function ghostTerminalPin(level) {
  if (!customLayer || customLayer.node == null) return;

  const nodeLevel = customLayer.node.properties?.level;

  if (nodeLevel == null) {
    console.warn("CustomLayer hat kein Nodelevel", customLayer);
    return;
  }

  if (level != nodeLevel) {
    set3DMaterialOpacity(customLayer.model, 0.2);
  } else {
    set3DMaterialOpacity(customLayer.model, 1);
  }
}
