import { useState, useEffect, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import QrCodeReader from "../../mapUi/QrCodeReader/QrReader.component";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";

import * as actionMain from "../../../reduxStore/actions/main";
import * as actionInfoCard from "../../../reduxStore/actions/infoCard";
import { MODULES, QR_CODES } from "../../../reduxStore/actions/main";
import RoundedIcon from "../../elements/buttons/roundedIcon.component.jsx";
import style from "../search.module.scss";
import { isAndroid, isIOS } from "react-device-detect";
import { statisticLogger } from "../../statisticLogger/StatisticLogger.container";
import { piKey } from "../../../app/app.sideEffects/useURLParams.sideEffects";

function Header(props) {
  const typingTimer = useRef();
  const blurringTimer = useRef();
  const intl = useIntl();

  const [qrCodeReaderOpen, setQrCodeReaderOpen] = useState(false);
  const [inputActive, setInputActive] = useState(false);
  const inputContainer = useRef(null);

  const openQRCodeReader = () => {
    setQrCodeReaderOpen(true);
    statisticLogger.addLog({
      action: {
        group: "QRCode",
        id: "clickOnQRCode",
        name: "Click on QRCode Icon",
        movement: "enter",
        type: "click",
        interaction: "open",
        content: {
          campusId: actionMain.CAMPUS_COLLECTION.getCurrentCampus().id,
        },
      },
    });
    window.addLog({
      show: false,
      upload: true,
      message: "QrCodeReader wurde geöffnet",
    });
  };

  const handleCancel = () => {
    setQrCodeReaderOpen(false);
    window.addLog({
      show: false,
      upload: true,
      message: "QrCodeReader wurde geöffnet",
    });
  };

  const handleTyping = (e) => {
    props.setSearchText(e.target.value);
    typingTimer.current = setTimeout(() => {
      window.addLog({
        show: false,
        upload: true,
        message: `Nutzer hat nach: "${e.target.value}" gesucht`,
      });
    }, 2500);
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") inputContainer.current.blur();
  };

  useLayoutEffect(() => {
    // Beim ersten Aufruf direkt die Suche onFocus setzen!
    inputContainer.current.focus();
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(typingTimer.current);
      clearTimeout(blurringTimer.current);
    };
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  const isFramed = !!urlParams.get("isFramed");

  return (
    <div className={"pl-3 pr-3"}>
      <header className="d-flex justify-content-between">
        {props.hasClose && (
          <RoundedIcon
            icon={["fal", "long-arrow-left"]}
            divClass={"roundedIcon--back mr-3"}
            onClick={props.handleClose}
            ariaLabel={intl.formatMessage({
              id: "search.screenreader.closeSearch",
            })}
            title={intl.formatMessage({ id: "search.buttonBack" })}
          />
        )}
        <div
          role={"search"}
          className={`d-flex align-items-center ${style.searchlabel} searchBar searchBar__search pl-3 pb-2 pt-2 `}
        >
          {!(inputActive || props.searchText.length) ? (
            <FontAwesomeIcon icon={["fal", "search"]} className={"mr-2"} />
          ) : null}
          <input
            aria-label={intl.formatMessage({
              id: "search.screenreader.searchInput",
            })}
            id={"search"}
            ref={inputContainer}
            value={props.searchText}
            placeholder={intl.formatMessage({
              id: props.isStart
                ? "search.placeholderStart"
                : "search.placeholderDestination",
            })}
            role={"searchbox"}
            onChange={handleTyping}
            onKeyDown={handleEnter}
            className={"w-100 mr-3 bg-transparent "}
            style={{ border: "none", outline: "none" }}
            onFocus={() => {
              if (props.showKeyboard) {
                props.showKeyboard(true);
              }
              setInputActive(true);
            }}
            onBlur={() => {
              blurringTimer.current = setTimeout(() => {
                setInputActive(false);
              }, 250);
            }}
          />
          {props.searchText.length && !isFramed ? (
            <RoundedIcon
              onClick={() => props.setSearchText("")}
              divClass={"roundedIcon__searchbar--close ml-auto"}
              iconClass={"roundedIcon__searchbar--close__icon"}
              icon={["fal", "times"]}
              title={intl.formatMessage({ id: "search.buttonQRCode" })}
            />
          ) : MODULES.qrScannerAnbieten &&
            !isFramed &&
            piKey === "KHBR-hXf0ATzBt5SCYTdNnY6c6Tm9z5AxhYMrpyKygpaZ3gbLo" &&
            (isAndroid || isIOS) &&
            !props.isTerminal ? (
            <RoundedIcon
              onClick={openQRCodeReader}
              divClass={"roundedIcon__searchbar--qrCode"}
              iconClass={"roundedIcon__searchbar--qrCode__icon"}
              icon={["fal", "qrcode"]}
              title={intl.formatMessage({ id: "search.buttonQRCode" })}
            />
          ) : null}
        </div>
      </header>
      {qrCodeReaderOpen ? (
        <QrCodeReader
          canceled={handleCancel}
          isOpen={qrCodeReaderOpen}
          readId={props.onRead}
        />
      ) : null}
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentFeature: (feature) =>
      dispatch(actionMain.setCurrentFeature(feature)),
    setStatus: (status) => dispatch(actionInfoCard.setStatus(status)),
  };
};
Header.propTypes = {
  handleClose: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(Header);
