export const options = {
  isCaseSensitive: false,
  includeScore: true,
  shouldSort: true,
  includeMatches: true,
  minMatchCharLength: 2,
  location: 0,
  threshold: 0.3,
  distance: 0,
  useExtendedSearch: true,
  ignoreLocation: true,
  ignoreFieldNorm: true,
  keys: ["name", "searchstrings"],
};
