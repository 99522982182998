/**
 * Die types müssen zwingend mit "FLOOR_.." oder "MAIN_.." benannt werden, da
 * Redux alle reducer Funktionen durchspielt.
 * In jedem Reducer sollten daher switch-cases sein die immer mit dem jeweiligen State
 * Namen beginnen (z.B.: "FLOOR_...")
 * Am Ende eines Reducer Switch-Cases muss ein Default Switch-Case sein, das den
 * unveränderten State zurück gibt!
 * @type {string}
 */
// floor
export const FLOOR_SET_OPEN = "FLOOR_SET_OPEN";
export const FLOOR_SET_CLOSE = "FLOOR_SET_CLOSE";
export const FLOOR_TOGGLE_OPEN = "FLOOR_TOGGLE_OPEN";
export const FLOOR_SET_CURRENT_FLOOR = "FLOOR_SET_CURRENT_FLOOR";

// main
export const MAIN_SET_PAGE = "MAIN_SET_PAGE";
export const MAIN_SET_CA_SERVERDATA = "MAIN_SET_CA_SERVERDATA";
export const MAIN_TOGGLE_IS_FULL_MAP = "MAIN_TOGGLE_IS_FULL_MAP";
export const MAIN_TRIGGER_PAGE_LOAD = "MAIN_TRIGGER_PAGE_LOAD";
export const MAIN_SET_IS_APP = "MAIN_SET_IS_APP";
export const MAIN_SET_UPLOAD_DATA = "MAIN_SET_UPLOAD_DATA";
export const MAIN_CREATE_SESSION_ID = "MAIN_CREATE_SESSION_ID";
export const MAIN_SET_CUSTOMER = "MAIN_SET_CUSTOMER";
export const MAIN_SET_SESSION_KEY = "MAIN_SET_SESSION_KEY";
export const MAIN_ASK_FOR_GPS = "MAIN_ASK_FOR_GPS";
export const MAIN_SET_CURRENT_POI = "MAIN_SET_CURRENT_POI";
export const MAIN_SET_CURRENT_CAMPUS = "MAIN_SET_CURRENT_CAMPUS";
export const MAIN_SET_CURRENT_CAMPUS_KEY = "MAIN_SET_CURRENT_CAMPUS_KEY";
export const MAIN_SET_LANGUAGE = "MAIN_SET_LANGUAGE";
export const MAIN_SET_AVAILABLE_CAMPUSES = "MAIN_SET_AVAILABLE_CAMPUSES";
export const MAIN_SET_NEW_AVAILABLE_CAMPUSES =
  "MAIN_SET_NEW_AVAILABLE_CAMPUSES";
export const MAIN_SET_USER_ALLOWED_GPS = "MAIN_SET_USER_ALLOWED_GPS";
export const MAIN_SET_TERMINAL_IDLE = "MAIN_SET_TERMINAL_IDLE";
export const MAIN_SET_LOCATION_PERMISSION_ALLOWED =
  "MAIN_SET_LOCATION_PERMISSION_ALLOWED";
export const MAIN_SET_CAMERA_PERMISSION_ALLOWED =
  "MAIN_SET_CAMERA_PERMISSION_ALLOWED";
export const MAIN_SET_DATA_SCANNER_AVAILABLE =
  "MAIN_SET_DATA_SCANNER_AVAILABLE";
export const MAIN_SET_CURRENTAPPOVERLAY = "MAIN_SET_CURRENTAPPOVERLAY";
export const MAIN_SET_DEVICE_INFORMATION = "MAIN_SET_DEVICE_INFORMATION";
export const SETTINGS_SET_USER_ALLOWED_PUSH = "SETTINGS_SET_USER_ALLOWED_PUSH";
export const SETTINGS_SET_PUSH_PERMISSION_ALLOWED =
  "SETTINGS_SET_PUSH_PERMISSION_ALLOWED";
export const SETTINGS_SET_SET = "SETTINGS_SET_SET";
export const MAIN_SET_IS_TERMINAL = "types.MAIN_SET_IS_TERMINAL";
export const MAIN_DESIGN_DATA_LOADED = "MAIN_DESIGN_DATA_LOADED";
export const MAIN_SET_NOTIFICATION_POST_ID = "MAIN_SET_NOTIFICATION_POST_ID";
export const MAIN_SET_SERVICE_WORKER_ACTIVE = "MAIN_SET_SERVICE_WORKER_ACTIVE";
export const MAIN_TOGGLE_DISPLAY_DOWN_MODE = "MAIN_TOGGLE_DISPLAY_DOWN_MODE";
export const MAIN_LOGIN_PIKEYS = "MAIN_LOGIN_PIKEYS";
export const MAIN_SET_PIKEY_STATE = "MAIN_SET_PIKEY_STATE";
export const MAIN_SET_URL_PARAMETERS_READY = "MAIN_SET_URL_PARAMETERS_READY";

// map
export const MAP_SET_MAPBOX_SERVERDATA = "MAP_SET_MAPBOX_SERVERDATA";
export const MAP_SET_GEOJSON_DATA = "MAIN_SET_GEOJSON_DATA";
export const MAP_SET_BOUNDS = "MAP_SET_BOUNDS";
export const MAP_SET_CHILDREN_PARENT_NULL = "MAP_SET_CHILDREN_PARENT_NULL";
export const MAP_SET_MAPLOADED = "MAP_SET_MAPLOADED";
export const MAP_SET_MAPBOX_STATES = "MAP_SET_MAPBOX_STATES";
export const MAP_INCREMENT_MAPCLICKS = "MAP_INCREMENT_MAPCLICKS";
export const MAP_SET_PAGE = "MAP_SET_PAGE";
export const MAP_SET_MAP = "MAP_SET_MAP";
export const MAP_TOGGLE_3D_BUILDINGS = "MAP_TOGGLE_3D_BUILDINGS";
export const MAP_SET_ALLOWBUILDINGMARKERS = "MAP_SET_ALLOWBUILDINGMARKERS";
export const MAP_TOGGLE_MAPSTYLE = "MAP_TOGGLE_MAPSTYLE";
export const MAP_TOGGLE_BUILDINGMARKERS = "MAP_TOGGLE_BUILDINGMARKERS";
export const MAP_SET_PADDING = "MAP_SET_PADDING";
export const MAP_FORCE_FEATUREFILTER_UPDATE = "MAP_FORCE_FEATUREFILTER_UPDATE";
export const MAP_SET_CHILDRENFEATURES = "MAP_SET_CHILDRENFEATURES";
export const MAP_SET_VIEW_IS_INDOORS = "MAP_SET_VIEW_IS_INDOORS";
export const MAP_SET_CAMERA_MOVE_TO_HEADING = "MAP_SET_CAMERA_MOVE_TO_HEADING";
export const MAP_SET_TERMINAL_BEARING = "MAP_SET_TERMINAL_BEARING";
export const MAIN_SET_INTRO_COMPLETE = "MAIN_SET_INTRO_COMPLETE";
export const MAIN_SET_CAMPUS_KEYS = "MAIN_SET_CAMPUS_KEYS";
export const MAIN_SET_INITIAL_CAMPUS_KEY = "MAIN_SET_INITIAL_CAMPUS_KEY";
export const MAIN_SET_HAS_SEPARATESEARCHSCREEN =
  "MAIN_SET_HAS_SEPARATESEARCHSCREEN";
export const MAIN_SET_STYLE_ID = "MAIN_SET_STYLE_ID";
export const MAIN_SET_PIDATA = "MAIN_SET_PIDATA";

// phonebook
export const PHONEBOOK_ADD_TO_FAVORITE = "PHONEBOOK_ADD_TO_FAVORITE";
export const PHONEBOOK_REMOVE_FROM_FAVORITE = "PHONEBOOK_REMOVE_FROM_FAVORITE";
export const PHONEBOOK_READ_FAVORITE_FROM_COOKIE =
  "PHONEBOOK_READ_FAVORITE_FROM_COOKIE";

// search
export const SEARCH_SET_CATEGORIES = "SEARCH_SET_CATEGORIES";
export const SEARCH_GET_LAST_SEARCH = "SEARCH_GET_LAST_SEARCH";
export const SEARCH_SET_LAST_SEARCH = "SEARCH_SET_LAST_SEARCH";
export const SEARCH_RESET_LAST_SEARCH = "SEARCH_RESET_LAST_SEARCH";
export const SEARCH_SET_TARGET = "SEARCH_SET_TARGET";
export const SEARCH_SET_MUSTHAVELOCATION = "SEARCH_SET_MUSTHAVELOCATION";

// settings
export const SETTING_TOGGLE_ROUTING_SETTING = "SETTING_TOGGLE_ROUTING_SETTING";
export const SETTINGS_SET_SETTINGS_FROM_STORAGE = "SETTING_GET_CURRENT_SETTING";
export const SETTING_SET_SAVE_ZONE_PADDING = "SETTING_SET_SAVE_ZONE_PADDING";
export const SETTING_SET_VALUE = "SETTING_SET_VALUE";
export const SETTING_SET_PROPERTIES = "SETTING_SET_PROPERTIES";
export const SETTING_SET_CALENDARS = "SETTING_SET_CALENDARS";
export const SETTING_SET_PREFERRED_CALENDAR = "SETTING_SET_PREFERRED_CALENDAR";
export const SETTING_SET_CALENDAR_AUTHORIZED =
  "SETTING_SET_CALENDAR_AUTHORIZED";
export const SETTING_SET_CALENDAR_ALLOWED = "SETTING_SET_CALENDAR_ALLOWED";

// route
export const ROUTE_SET_ROUTE = "ROUTE_SET_ROUTE";
export const ROUTE_RESET_ROUTE = "ROUTE_RESET_ROUTE";
export const ROUTE_SET_START = "ROUTE_SET_START";
export const ROUTE_SET_TARGET = "ROUTE_SET_TARGET";
export const ROUTE_SET_ROAD_USER_TYPE = "ROUTE_SET_ROAD_USER_TYPE";
export const ROUTE_SET_FETCH_NEW_ROUTE = "ROUTE_SET_FETCH_NEW_ROUTE";
export const ROUTE_SET_GO_TO_PARKING_SPOT = "ROUTE_SET_GO_TO_PARKING_SPOT";
export const ROUTE_SET_ERROR = "ROUTE_SET_ERROR";
export const ROUTE_SET_IS_GENERATING_PDF = "ROUTE_SET_IS_GENERATING_PDF";
export const ROUTE_SET_DEFAULT_STARTNODE = "ROUTE_SET_DEFAULT_STARTNODE";
export const ROUTE_URL_PARAMS_HANDLED = "ROUTE_URL_PARAMS_HANDLED";
export const ROUTE_SET_TERMINAL_USER_POSITION =
  "ROUTE_SET_TERMINAL_USER_POSITION";

// navigation
export const NAVIGATION_SET_TYPE = "NAVIGATION_SET_TYPE";
export const NAVIGATION_SET_LOCATION_DATA = "NAVIGATION_SET_LOCATION_DATA";
export const NAVIGATION_SET_LOCATION = "NAVIGATION_SET_LOCATION";
export const NAVIGATION_SET_SIMULATED_LOCATION_DATA =
  "NAVIGATION_SET_SIMULATED_LOCATION_DATA";
export const NAVIGATION_SET_ZINDEX = "NAVIGATION_SET_ZINDEX";
export const NAVIGATION_SET_HEADING = "NAVIGATION_SET_HEADING";
export const NAVIGATION_SET_MOTION_ACTIVITY_STATUS =
  "NAVIGATION_SET_MOTION_ACTIVITY_STATUS";
export const NAVIGATION_SET_LATEST_POSITION = "NAVIGATION_SET_LATEST_POSITION";
export const NAVIGATION_SET_POSITION_RECORDING =
  "NAVIGATION_SET_POSITION_RECORDING";
export const NAVIGATION_SET_MODE = "NAVIGATION_SET_MODE";

// toasts
export const TOAST_ADD_TOAST = "TOAST_ADD_TOAST";
export const TOAST_DELETE_TOAST = "TOAST_DELETE_TOAST";
export const TOAST_SET_TOAST_ADDED = "TOAST_SET_TOAST_ADDED";
export const TOAST_SET_TOAST_DELETED = "TOAST_SET_TOAST_DELETED";

// appointment
export const APPOINTMENT_SET_POI = "APPOINTMENT_SET_POI";
export const APPOINTMENT_SET_VALUES = "APPOINTMENT_SET_VALUES";
export const APPOINTMENT_SET_SHOW_CREATEAPPOINTMENT =
  "APPOINTMENT_SET_SHOW_CREATEAPPOINTMENT";
export const APPOINTMENT_SET_HIDE_CREATEAPPOINTMENT =
  "APPOINTMENT_SET_HIDE_CREATEAPPOINTMENT";

//debugger
export const DEBUG_SET_VALUES = "DEBUG_SET_VALUES";
export const DEBUG_SET_UPLOAD_ITEM = "DEBUG_SET_UPLOAD_ITEM";
export const DEBUG_REMOVE_UPLOAD_ITEM = "DEBUG_REMOVE_UPLOAD_ITEM";
