import { v4 as uuidv4 } from "uuid";

import * as types from "../types.variables.js";
import { getNewChildrenFeatures } from "../../functions/classes/poi.class.js";
import { AppOverlay } from "../../functions/classes/miscelleanous.classes.js";
import { sourceIds } from "../../components/mapUi/mapbox/variables/sourceIds.js";
import { MESSAGES } from "../../IntlContext";

export const sessionId = uuidv4();
export let sessionKey = null;
export let isApp = true;
export const pageNames = {
  search: "search",
  route: "route",
  info: "info",
  navigation: "navigation",
  map: "map",
  news: "news",
  events: "events",
  settings: "settings",
  phonebook: "phonebook",
  styling: "styling",
  loader: "loader",
  intro: "intro",
  complete: "complete",
  selectPush: "select-push",
  selectRestrictions: "select-restrictions",
  selectUserGroup: "select-user-group",
  selectHealthstats: "select-healthstats",
  selectLocation: "select-location",
  selectCampus: "select-campus",
  selectLanguage: "select-language",
  login: "login",
  choosePiKey: "choose-piKey",
};

export const initState = {
  pageReload: 0, // need this to trigger the footerMenu -> fix ui bug
  language: getLanguage(),
  isApp: null,
  uploadData: false,
  serverDataLoaded: 0,
  designDataLoaded: null,
  sessionId,
  // Die momentane Seite, auf der sich der User befindet
  currentPage: "map",
  lastPage: null,
  // Wenn aktiv soll die Map möglichst ungestört vom UI angezeigt werden
  // wird in mapinteractivity de-/aktiviert
  isFullMap: false,
  currentFeature: null,
  lastFeature: null,
  // Children von POIs ohne Standort, die mit roten Markern angezeigt werden
  currentChildrenPOIs: null,
  // Der Vater ist nicht immer der currentFeature
  currentChildrenFather: null,
  currentChildrenFeatures: null,
  currentParentPOI: null,
  currentCampusId: null, // dient als updater, wird im CAMPUS_COLLECTION Objekt upgedated durch Effekt
  currentCampusKey: null,
  customer: null,
  notificationPostId: null,
  // hier kommen die campusKeys rein
  availableCampuses: [],
  // hier kommt eines der appOverlays hin. Der Show Parameter, weil manche Overlays nur auf Mobile gezeigt werden
  // Während sie auf Desktop Geräten in normalen Containern rendern
  currentAppOverlay: new AppOverlay(),
  // todo: noch auf null setzen
  campusKeys: [],
  urlCampusKeys: null,
  cameraPermissionAllowed: null, // initial auf null, erst nach usereingabe auf boolean
  dataScannerAvailable: false,
  campusIsSet: false, // kann erst auf true gesetzt werden, wenn userAllowedGPS gesetzt wurde
  introComplete: false,
  isTerminal: false,
  terminalIsIdle: true,
  displayDownMode: false, // wenn im terminalmodus das rollstuhlicon über dem keyboard gedrück wird, rutscht der bildschirm runter
  hasSeparateSearchScreen: false, // beim UKSH hat das Terminal zwei Bildschirmen
  customStyleId: undefined, // manchmal wird ein anderer Style benutzt für die gleiche produktinstanz (dadurch werden andere colors und CSSs geladen)
  deviceInformation: {
    smartphoneSDK: null,
    smartphoneProdukt: null,
    smartphoneBrand: null,
    smartphoneDevice: null,
    smartphoneModel: null,
    versionCode: null,
    appversion: null,
    os: window.navigator.platform,
  },
  serviceWorkerActive: null,
  /** @type {name: string, key: string}[]*/
  loginPiKeys: [],
  /** @type {string | undefined | null} */
  piKeyState: undefined,
  urlParametersReady: false,
  /** Gibt an, ob die Settings initial bereits aus dem localstorage geholt worden sind */
  storageSettingsSet: false,
  piData: null,
};
/**
 * Hier werden types übergeben und im Hauptstate gespeichert (floor, main, infoCard, MainMenu...
 * Diese sind redux intern)
 * Zur Erklärung: Reducer gibt vor "wie und wo speichere ich", Action sagt: "was speichere ich"
 */
export default function main(state = initState, action) {
  switch (action.type) {
    case types.MAIN_SET_PAGE:
      return {
        ...state,
        currentPage: action.payload,
        lastPage: state.currentPage,
      };
    case types.MAIN_TOGGLE_IS_FULL_MAP:
      let newIsFullMapValue = action.payload;
      if (newIsFullMapValue == null) {
        // wenn payload null -> wir müssen selbst toggeln
        if (state.currentFeature) {
          // wenn man neues Feature anwählt, geht fullscreen map auf
          newIsFullMapValue = true;
        } else if (state.lastFeature && !state.currentFeature) {
          // wenn das letzte Feature null ist und man klickt wieder auf die Map
          // dann toggelt sich der Fullscreenview
          newIsFullMapValue = false;
        } else if (!state.lastFeature && !state.currentFeature) {
          newIsFullMapValue = !state.isFullMap;
        }
      }
      return { ...state, isFullMap: newIsFullMapValue };

    case types.SETTINGS_SET_SETTINGS_FROM_STORAGE: {
      return { ...state, storageSettingsSet: true };
    }

    case types.MAIN_SET_IS_TERMINAL:
      return {
        ...state,
        isTerminal: action.payload,
      };

    case types.MAIN_SET_PIKEY_STATE:
      return {
        ...state,
        piKeyState: action.payload,
      };

    case types.MAIN_SET_SERVICE_WORKER_ACTIVE:
      return {
        ...state,
        serviceWorkerActive: action.payload,
      };
    case types.MAIN_SET_STYLE_ID:
      return {
        ...state,
        customStyleId: action.payload,
      };
    case types.MAIN_SET_TERMINAL_IDLE:
      return { ...state, terminalIsIdle: action.payload };

    case types.MAIN_SET_HAS_SEPARATESEARCHSCREEN:
      return {
        ...state,
        hasSeparateSearchScreen: !!action.payload,
      };
    case types.MAIN_TRIGGER_PAGE_LOAD:
      return { ...state, pageReload: state.pageReload + 1 };

    case types.MAIN_SET_CURRENTAPPOVERLAY:
      return { ...state, currentAppOverlay: action.payload };

    case types.MAIN_SET_LOCATION_PERMISSION_ALLOWED:
      return {
        ...state,
        locationPermissionAllowed: action.payload,
      };
    case types.MAIN_SET_CAMERA_PERMISSION_ALLOWED:
      return {
        ...state,
        cameraPermissionAllowed: action.payload,
      };
    case types.MAIN_SET_DATA_SCANNER_AVAILABLE: {
      return {
        ...state,
        dataScannerAvailable: action.payload,
      };
    }
    case types.MAIN_TOGGLE_DISPLAY_DOWN_MODE:
      return {
        ...state,
        displayDownMode:
          action.payload != null ? action.payload : !state.displayDownMode,
      };
    case types.MAIN_LOGIN_PIKEYS:
      return {
        ...state,
        loginPiKeys: action.payload,
      };
    case types.MAIN_SET_CURRENT_POI:
      const resetChildren = () => {
        // kein Feature ausgewählt -> alles auf anfang
        childrenPOIs = null;
        childrenFeatures = null;
        parent = null;
        childrenFather = null;
      };

      const currentPOI = action.payload;
      let childrenPOIs = state.currentChildrenPOIs;
      let childrenFather = state.currentChildrenFather;
      let childrenFeatures = state.currentChildrenFeatures;
      let parent = state.currentParentPOI;
      // in diesem If Statement gehts konkret um die setzung von children und parent
      console.log(currentPOI);
      if (currentPOI) {
        parent = currentPOI.getParent();
        // currentFeature hat keinen Standort (konnte nur von ui, nicht map, ausgewählt werden)
        // -> Children setzen
        if (!currentPOI.hasLocation()) {
          childrenPOIs = currentPOI.getChildren();
          childrenFeatures = getNewChildrenFeatures(
            childrenPOIs,
            sourceIds.poiChildren
          );
          childrenFather = currentPOI;
          if (!childrenPOIs.length || !childrenFeatures.length) {
            resetChildren();
          }
        } else {
          // sollte es ein currentFeature geben, aber es ist eines der Kids, dann werden die Kids NICHT
          // neugesetzt
          if (state.currentChildrenPOIs) {
            let childrenContainCurrentFeature = state.currentChildrenPOIs.find(
              (c) =>
                c.id === currentPOI.id &&
                c.getPreferredFeature &&
                c.getPreferredFeature()
            );

            if (!childrenContainCurrentFeature) {
              resetChildren();
            }
          }
        }
      } else {
        resetChildren();
      }

      return {
        ...state,
        currentChildrenPOIs: childrenPOIs,
        currentChildrenFeatures: childrenFeatures,
        currentChildrenFather: childrenFather,
        currentParentPOI: parent,
        currentFeature: currentPOI,
        lastFeature: state.currentFeature,
        isFullMap: currentPOI != null ? false : state.isFullMap,
      };
    case types.MAIN_SET_CURRENT_CAMPUS:
      return {
        ...state,
        currentCampusId: action.payload,
      };
    case types.MAIN_SET_CURRENT_CAMPUS_KEY:
      return {
        ...state,
        currentCampusKey: action.payload,
      };
    case types.MAP_SET_CHILDREN_PARENT_NULL:
      return {
        ...state,
        currentParentPOI: null,
        currentChildrenPOIs: null,
      };
    case types.MAIN_SET_IS_APP: {
      isApp = action.payload;
      return {
        ...state,
        isApp: action.payload,
      };
    }
    case types.MAIN_SET_STYLE_ID:
      return {
        ...state,
        customStyleId: +action.payload,
      };
    case types.MAIN_SET_UPLOAD_DATA: {
      return {
        ...state,
        uploadData: action.payload,
      };
    }
    case types.MAIN_CREATE_SESSION_ID: {
      return {
        ...state,
        sessionId: uuidv4(),
      };
    }
    case types.MAIN_SET_CA_SERVERDATA: {
      return {
        ...state,
        serverDataLoaded: state.serverDataLoaded + 1,
      };
    }
    case types.MAIN_DESIGN_DATA_LOADED:
      return {
        ...state,
        designDataLoaded: action.payload,
      };
    case types.MAIN_SET_CUSTOMER: {
      return {
        ...state,
        customer: action.payload,
      };
    }
    case types.MAIN_SET_LANGUAGE: {
      return {
        ...state,
        language: action.payload,
      };
    }
    case types.MAIN_SET_AVAILABLE_CAMPUSES: {
      return {
        ...state,
        availableCampuses: action.payload,
      };
    }
    case types.MAIN_SET_NEW_AVAILABLE_CAMPUSES: {
      return {
        ...state,
        newAvailableCampuses: action.payload,
      };
    }
    case types.MAIN_SET_INTRO_COMPLETE: {
      return {
        ...state,
        introComplete: action.payload,
      };
    }
    case types.MAIN_SET_USER_ALLOWED_GPS: {
      return {
        ...state,
        userAllowedGPS: action.payload,
      };
    }
    case types.MAIN_SET_CAMPUS_KEYS: {
      return {
        ...state,
        campusKeys: action.payload,
      };
    }
    case types.MAIN_SET_INITIAL_CAMPUS_KEY:
      return {
        ...state,
        urlCampusKeys: action.payload,
      };
    case types.MAIN_SET_SESSION_KEY: {
      if (action.payload.sessionKey) {
        sessionKey = action.payload.sessionKey;
      }
      return state;
    }
    case types.MAIN_SET_DEVICE_INFORMATION: {
      const data = { ...action.payload };

      if (data.sdkVersionName) {
        data.appVersion = data.sdkVersionName;
      }

      if (data.betriebssystem) {
        data.os = data.betriebssystem;
      }

      if (data.betriebssystemVersion) {
        data.osVersion = data.betriebssystemVersion;
      }
      return {
        ...state,
        deviceInformation: data,
      };
    }
    case types.MAIN_SET_NOTIFICATION_POST_ID: {
      return {
        ...state,
        notificationPostId: action.payload,
      };
    }
    case types.MAIN_SET_URL_PARAMETERS_READY:
      return {
        ...state,
        urlParametersReady: action.payload,
      };
    case types.MAIN_SET_PIDATA:
      return {
        ...state,
        piData: action.payload,
      };
    default:
      return state;
  }
}

function getLanguage() {
  const isoLanguage = window.navigator.language;
  if (!isoLanguage) return "de";
  const lang = isoLanguage.split("-")[0];
  if (!lang) return "de";

  return Object.keys(MESSAGES).includes(lang) ? lang : "de";
}
