import React from "react";
import StandardHeadline from "../text/standardHeadline.text.jsx";

/**
 * Ein Block mit einer Überschrift, der wie sein Name lautet normalerweise für Lists verwendet wird. In der Search wird dieser
 * Block z.B. sehr häufig aufgerufen.
 * @param children
 * @param heading
 * @param headingType
 * @param className
 * @param listButton
 * @param headingClassName
 * @param headingContainerClassName
 * @param childrenClassName
 * @return {JSX.Element}
 * @constructor
 */
export default function StandardList({
  children,
  heading,
  headingType = "h3",
  className = "",
  listButton,
  headingClassName = "",
  headingContainerClassName = "",
  childrenClassName = "",
  ariaLabel,
}) {
  return (
    <div className={"standardList " + className}>
      <div className={"d-flex align-items-center " + headingContainerClassName}>
        <StandardHeadline
          ariaLabel={ariaLabel}
          text={heading}
          type={headingType}
          noMargin={false}
          className={"standardList__heading " + headingClassName}
        />
        <div className="d-flex ml-auto">{listButton && listButton}</div>
      </div>
      <div className={childrenClassName}>{children}</div>
    </div>
  );
}
