import { FormattedMessage } from "react-intl";
import Highlight from "../../elements/text/highlight.text.jsx";
import classes from "../search.module.scss";
import StandardCard from "../../elements/cards/StandardCard.card.jsx";

export default function MostUsed(props) {
  if (
    props.searchText.length > 0 &&
    !props.mostUsed.filter((item) => {
      const poi = props.pois.find((p) => p.id === item);
      return poi.name.includes(props.searchText);
    }).length
  )
    return null;

  return (
    <>
      <header className="d-flex justify-content-between general-padding-large-top general-padding-horizontally mb-2 pr-3 pl-3">
        <h4>
          <FormattedMessage id="search.mostUsed" />
        </h4>
      </header>

      <div className="d-flex overflow-auto">
        {props.mostUsed.map((mostUsedId) => {
          const poi = props.pois.find((p) => p.id === mostUsedId);

          return (
            <StandardCard
              onClick={() => props.searchItemClicked(poi.id)}
              key={mostUsedId}
              role="button"
              className={`col-8 standardCard__search--mostUsed ${
                props.searchText.length > 0 &&
                !poi.name.includes(props.searchText)
                  ? "d-none"
                  : "d-flex"
              } `}
              heading={
                <Highlight search={props.searchText}>{poi.name}</Highlight>
              }
              headingType={"h2"}
            >
              <span className="mb-3 overflow-auto">{poi.address}</span>
            </StandardCard>
          );
        })}
      </div>
    </>
  );
}
