export default class UserLocation {
  static location_types = {
    bluetooth: "bluetooth",
    gps: "gps",
  };
  static position_types = {
    indoor: "indoor",
    outdoor: "outdoor",
  };

  /**
   * @param locationType
   * @param lat
   * @param lng
   * @param {RawLocation}rawData
   * @param accuracy
   * @param zIndex
   * @param heading
   * @param timestamp
   * @param {PositionType} positionType
   */
  constructor(
    locationType = UserLocation.location_types.gps,
    lat = null,
    lng = null,
    {
      accuracy = null,
      zIndex = 0,
      heading = null,
      timestamp = new Date().getTime(),
      // für bluetooth daten und gpsdaten vor der Verarbeitung im LocationManager
      rawLocation,
      speed = 0,
      isSet,
      positionType = UserLocation.position_types.outdoor,
    } = {}
  ) {
    this.locationType = locationType;
    this.lat = lat;
    this.lng = lng;
    this.accuracy = accuracy;
    this.zIndex = zIndex;
    this.heading = heading;
    this.timestamp = timestamp;
    this.lastRawLocation = rawLocation;
    this.speed = speed;
    this.positionType = positionType;

    if (isSet === undefined) {
      this.isSet = !(lat === 0 || lng === 0 || lat == null || lng == null);
    } else {
      this.isSet = isSet;
    }
  }

  toJson() {
    return {
      latitude: this.lat,
      longitude: this.lng,
      zIndex: this.zIndex,
      positionType: this.positionType,
      locationType: this.locationType,
    };
  }
}

export class RawLocation {
  static types = {
    gps: "gps",
    bluetooth: "bluetooth",
  };
  /**
   * @param {[{rssi, uuid, major, minor, timestamp}]|{lat, lng, accuracy,speed, course, timestamp}} data
   */
  constructor(data, type) {
    this._type = type;
    if (Array.isArray(data)) {
      this._data = data;
    } else {
      this._data = [data];
    }
  }
  getHeading() {
    if (this._type === RawLocation.types.gps) {
      return this._data[0].course;
    }
  }

  get zIndex() {
    if (this._type === RawLocation.types.bluetooth) {
      return this._data[this._data.length - 1];
    }
  }
  get data() {
    return this._data;
  }
  get type() {
    return this._type;
  }
}

/**
 * Diese Location wird verwendet vom Simulierten Punkt. Er gibt der eigentlichen
 * Location noch eine weitere abgespeckte Location mit, die bei bestimmten Cases in der
 * Navigation helfen soll, da die simulierte immer wie auf Schienen läuft und die Realität oft nicht
 */
class ActualLocation {
  constructor(zIndex, lat, lng, isSet) {
    this.zIndex = zIndex;
    this.lat = lat;
    this.lng = lng;

    if (isSet === undefined) {
      this.isSet = !(lat === 0 || lng === 0 || lat == null || lng == null);
    } else {
      this.isSet = isSet;
    }
  }
}
