import Highlight from "../../elements/text/highlight.text.jsx";
import classes from "../../phonebook/phonebook.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StandardCard from "../../elements/cards/StandardCard.card";
import PropTypes from "prop-types";
import { getSearchFilterText } from "../list";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCampusNameById } from "../../../functions/helper/helpers.functions.js";

// In case you wonder; yes there are multiple Phonebooks. This one is the one for the search. The other one is the "real" phone book.
function Phonebook(props) {
  return (
    <StandardCard className={"mb-3 standardCard__search"} role={"button"}>
      {props.name.toLowerCase().includes(props.searchText.toLowerCase()) ? (
        <h4 className="font-weight-bold">
          <Highlight search={props.searchText}>{props.name}</Highlight>
        </h4>
      ) : (
        <h4 className="font-weight-bold">
          <Highlight search={props.searchText}>
            {props.name +
              getSearchFilterText(props.searchText, props.searchstrings)}
          </Highlight>
        </h4>
      )}
      <div className="d-flex justify-content-between">
        <span className="overflow-auto">{props.phone}</span>
        {!props.isTerminal && (
          <a
            href={`tel:${props.phone.replace(/[^0-9]/g, "")}`}
            className={`${classes.callNumberButton} btn ca-btn-card-primary`}
          >
            <FontAwesomeIcon icon="arrow-right" />
          </a>
        )}
      </div>
      {props.campusId && (
        <div className="d-flex justify-content-between">
          <span className="overflow-auto">
            {getCampusNameById(props.campusId)}
          </span>
        </div>
      )}
    </StandardCard>
  );
}

Phonebook.propTypes = {
  name: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  searchstrings: PropTypes.array,
  phone: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isTerminal: state.main.isTerminal,
  };
};

export default withRouter(connect(mapStateToProps)(Phonebook));
