import { map } from "../../map.sideEffects/useMap.sideEffects.js";
import { layerIds } from "../../variables/layerIds.js";
import { sourceIds } from "../../variables/sourceIds.js";
import { debuggingValues, EXPRESSIONS } from "../../variables/expressions.js";
import { STYLINGVALUES } from "../../variables/layerStylingValues.js";
import { addSource } from "../drawer.map.js";
import { CUSTOMIZABLE_VALUES } from "../../variables/CUSTOMIZABLE_VALUES.js";

/**
 * Erstellt die Lines/Kanten der Navigation
 * Arrows
 * Symbols
 * @param map
 */
export function createRouteLines() {
  let lineThicknessNormal =
    STYLINGVALUES.lines.thickness.navigation.normal.line;
  let lineThicknessGhosted =
    STYLINGVALUES.lines.thickness.navigation.ghosted.line;
  let lineThicknessNormalHalo =
    STYLINGVALUES.lines.thickness.navigation.normal.halo;
  let lineThicknessGhostedHalo =
    STYLINGVALUES.lines.thickness.navigation.ghosted.halo;

  if (map.mapStyleJSON?.routes?.route?.thickness != null) {
    lineThicknessNormal = map.mapStyleJSON?.routes?.route?.thickness;
    lineThicknessGhosted = map.mapStyleJSON?.routes?.route?.thickness_ghosted
      ? map.mapStyleJSON?.routes?.route?.thickness_ghosted
      : map.mapStyleJSON?.routes?.route?.thickness;
    lineThicknessNormalHalo = map.mapStyleJSON?.routes?.route?.thickness_halo
      ? map.mapStyleJSON?.routes?.route?.thickness_halo
      : lineThicknessNormal * 2 - 0.25 * lineThicknessNormal;
    lineThicknessGhostedHalo = map.mapStyleJSON?.routes?.route
      ?.thickness_ghosted_halo
      ? map.mapStyleJSON?.routes?.route?.thickness_ghosted_halo
      : lineThicknessNormal * 2 - 0.25 * lineThicknessNormal;
  }

  let lineColor = CUSTOMIZABLE_VALUES.lineFill;
  let lineColorGhosted = CUSTOMIZABLE_VALUES.lineHalo;
  let lineColorGhostedHalo = CUSTOMIZABLE_VALUES.ghostedHalo;

  if (map.mapStyleJSON?.routes?.route?.color != null) {
    lineColor =
      map.mapStyleJSON?.routes?.route?.color &&
      map.mapStyleJSON?.routes?.route?.color.length > 0
        ? map.mapStyleJSON?.routes?.route?.color
        : lineColor;
    lineColorGhosted =
      map.mapStyleJSON?.routes?.route?.color_ghosted &&
      map.mapStyleJSON?.routes?.route?.color_ghosted.length
        ? map.mapStyleJSON?.routes?.route?.color_ghosted
        : lineColorGhosted;
    lineColorGhostedHalo =
      map.mapStyleJSON?.routes?.route?.color_ghosted_halo &&
      map.mapStyleJSON?.routes?.route?.color_ghosted_halo.length > 0
        ? map.mapStyleJSON?.routes?.route?.color_ghosted_halo
        : lineColorGhostedHalo;
  }

  // Lines
  if (!map.getLayer(layerIds.kanten.halo)) {
    map.addLayer({
      id: layerIds.kanten.halo,
      type: "line",
      source: sourceIds.kanten,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": [
          "case",
          ["boolean", ["feature-state", "differentLevel"], false],
          lineColorGhostedHalo,
          lineColor,
        ],
        "line-width": [
          "case",
          EXPRESSIONS.state.differentLevel,
          lineThicknessGhostedHalo,
          lineThicknessNormalHalo,
        ],
        "line-blur": [
          "case",
          ["boolean", ["feature-state", "differentLevel"], false],
          STYLINGVALUES.lines.blur.navigation.ghosted,
          20,
        ],
        "line-opacity": [
          "case",
          ["boolean", ["feature-state", "differentLevel"], false],
          STYLINGVALUES.lines.opacity.navigation.ghosted,
          1,
        ],
      },
    });
  }
  const linePaint = {
    "line-color": [
      "case",
      ["boolean", ["feature-state", "differentLevel"], false],
      lineColor,
      lineColor,
    ],
    "line-blur": [
      "case",
      ["boolean", ["feature-state", "differentLevel"], false],
      STYLINGVALUES.lines.blur.navigation.ghosted,
      1,
    ],
    "line-width": [
      "case",
      EXPRESSIONS.state.differentLevel,
      lineThicknessGhosted,
      lineThicknessNormal,
    ],
    "line-opacity": [
      "case",
      ["boolean", ["feature-state", "differentLevel"], false],
      0.4,
      1,
    ],
  };
  const lineLayout = {
    "line-join": "round",
    "line-cap": "round",
  };
  if (!map.getLayer(layerIds.kanten.navigation_dash)) {
    map.addLayer({
      id: layerIds.kanten.navigation_dash,
      type: "line",
      filter: EXPRESSIONS.line.fussgaenger,
      source: sourceIds.kanten,
      layout: lineLayout,
      paint: { ...linePaint, "line-dasharray": STYLINGVALUES.lines.dash },
    });
  }
  if (!map.getLayer(layerIds.kanten.navigation_line)) {
    map.addLayer({
      id: layerIds.kanten.navigation_line,
      type: "line",
      filter: ["!", EXPRESSIONS.line.fussgaenger],
      source: sourceIds.kanten,
      layout: lineLayout,
      paint: linePaint,
    });
  }
  // Arrow
  if (!map.getLayer(layerIds.arrow.head_background)) {
    map.addLayer({
      id: layerIds.arrow.head_background,
      type: "symbol",
      source: sourceIds.arrowHead,
      layout: {
        "icon-image": "triangle",
        "icon-rotate": ["get", "bearing"],
        "icon-rotation-alignment": "map",
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
        "icon-size": ["interpolate", ["linear"], ["zoom"], 0, 0, 22, 1.15],
      },
      paint: {
        "icon-color": [
          "case",
          ["boolean", ["feature-state", "differentLevel"], false],
          CUSTOMIZABLE_VALUES.ghostedArrowHalo,
          CUSTOMIZABLE_VALUES.lineFill,
        ],
        "icon-opacity": [
          "case",
          EXPRESSIONS.state.differentLevel,
          STYLINGVALUES.symbols.opacity.navigation.arrows.ghosted,
          1,
        ],
      },
    });
  }
  if (!map.getLayer(layerIds.arrow.kanten_background)) {
    map.addLayer({
      id: layerIds.arrow.kanten_background,
      type: "line",
      source: sourceIds.arrowKanten,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": [
          "case",
          EXPRESSIONS.state.differentLevel,
          CUSTOMIZABLE_VALUES.ghostedArrowHalo,
          CUSTOMIZABLE_VALUES.lineFill,
        ],
        "line-width": 16,
        "line-opacity": [
          "case",
          EXPRESSIONS.state.differentLevel,
          STYLINGVALUES.lines.opacity.navigation.arrows.ghosted,
          1,
        ],
      },
    });
  }
  if (!map.getLayer(layerIds.arrow.head)) {
    map.addLayer({
      id: layerIds.arrow.head,
      type: "symbol",
      source: sourceIds.arrowHead,
      layout: {
        "icon-image": "triangle",
        "icon-rotate": ["get", "bearing"],
        "icon-rotation-alignment": "map",
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
        "icon-size": ["interpolate", ["linear"], ["zoom"], 0, 0, 22, 1],
      },
      paint: {
        "icon-color": CUSTOMIZABLE_VALUES.white,
        "icon-opacity": ["case", EXPRESSIONS.state.differentLevel, 0, 1],
      },
    });
  }
  if (!map.getLayer(layerIds.arrow.kanten)) {
    map.addLayer({
      id: layerIds.arrow.kanten,
      type: "line",
      source: sourceIds.arrowKanten,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": CUSTOMIZABLE_VALUES.white,
        "line-width": 12,
        "line-opacity": ["case", EXPRESSIONS.state.differentLevel, 0, 1],
      },
    });
  }
  addSource(debuggingValues.debugLineSourceId);
  if (!map.getLayer(debuggingValues.debugLineLayerId)) {
    map.addLayer({
      id: debuggingValues.debugLineLayerId,
      type: "line",
      source: debuggingValues.debugLineSourceId,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": CUSTOMIZABLE_VALUES.restrictedFloor,
        "line-width": 12,
      },
    });
  }
}
