import EtagenWechsler from "./etagenwechsler.render.jsx";
import { connect } from "react-redux";
import { CAMPUS_COLLECTION } from "../../../reduxStore/actions/main";
import * as action from "../../../reduxStore/actions/floors.js";
import { useEffect, useState } from "react";
import ActionBubbles from "./actionBubbles/actionBubbles.component.jsx";
import { useIntl } from "react-intl";

/**
 * Visuelles Interface um commonValues.current.etage zu ändern
 * Dies hier ist nur der Container, der einen extra View aufruft!
 * @return {JSX.Element}
 * @constructor
 */
function EtagenWechslerContainer(props) {
  const [etagentext, setEtagentext] = useState("");
  const [hasLevels, setHasLevels] = useState(true);
  const intl = useIntl();

  const currentCampus = CAMPUS_COLLECTION.getCurrentCampus();
  const floors = currentCampus?.floors;

  const handleUpFloor = () => {
    props.setCurrentFloor(props.currentFloorIndex + 1);
  };
  const handleDownFloor = () => {
    props.setCurrentFloor(props.currentFloorIndex - 1);
  };

  useEffect(() => {
    let etagentext_temp = "";
    if (!floors?.[props.currentFloorIndex]) {
      console.warn(
        "Keine Stockwerke gefunden",
        floors,
        "index: " + props.currentFloorIndex
      );
      setHasLevels(false);
      return;
    } else {
      setHasLevels(true);
    }
    if (floors[props.currentFloorIndex].kundeStockwerkAnzeige != null) {
      etagentext_temp = floors[props.currentFloorIndex].kundeStockwerkAnzeige;
    } else {
      etagentext_temp = floors[props.currentFloorIndex].stockwerk;
    }
    setEtagentext(etagentext_temp);
  }, [props.currentFloorIndex, props.currentCampusId]);

  if (hasLevels && floors?.length > 1) {
    return (
      <ActionBubbles
        open={props.viewIsIndoors}
        iconName={["fal", "layer-group"]}
        openingDirection={"left"}
        showStandard_items={false}
        className={"mb-2"}
        ariaLabel={intl.formatMessage({
          id: "etagenwechsler.screenreader.openButton",
        })}
      >
        <EtagenWechsler
          etagentext={etagentext}
          increaseEtage={handleUpFloor}
          decreaseEtage={handleDownFloor}
        />
      </ActionBubbles>
    );
  } else return null;
}

const mapStateToProps = (state) => {
  return {
    currentFloorIndex: state.floors.currentFloorIndex,
    currentCampusId: state.main.currentCampusId,
    viewIsIndoors: state.map.viewIsIndoors,
    isTerminal: state.main.isTerminal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentFloor: (number) => dispatch(action.setCurrentFloorIndex(number)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EtagenWechslerContainer);
