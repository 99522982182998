import { CAMPUS_COLLECTION } from "../../reduxStore/actions/main";

/**
 * Rechnet Meter in Pixel auf Max Zoomlevel um
 * Der 0.019 Parameter kommt von Mapbox und ist auf Zoom 22 eingestellt, aber in der
 * Standardlatitude 0 (Euquator)
 * @param meters
 * @param latitude
 * @returns {number}
 */
export function getMetersFromPixels(meters, latitude) {
  return meters / 0.019 / Math.cos((latitude * Math.PI) / 180);
}

/**
 * URL mitgeben und JSON zurück erhalten
 * @param {String} url
 * @param {boolean} convertToJson - Soll als JSON (oder normal) zurückgegeben werden!
 * @returns {Promise<any>}
 */
export async function getData(url = "", convertToJson = true, signal) {
  // Default options are marked with *
  const response = await fetch(url, {
    signal: signal,
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manuayl, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  });
  if (convertToJson) {
    return response.json(); // parses JSON response into native JavaScript objects
  } else {
    return response;
  }
}

/**
 * Funktion um Daten von einer URL zu holen. Normale Post Anfrage aber ohne AJAX
 * @param url : String - URL STring
 * @param data : Object - Kein String, nur ein Object
 * @returns {Promise<Object>}
 */
export async function postData(url = "", data = {}, returnAsJSON, requestID) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      //  "Content-Type": "application/json; charset=UTF-8",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  if (returnAsJSON) {
    return response.json().catch(() => {
      console.warn("Reponse konnte nicht in JSON umgewandelt werden", response);
    }); // parses JSON response into native JavaScript objects
  } else {
    return response;
  }
}

/**
 *
 * @param {[Knoten,Kante, PolygonRoom]}features
 * @returns {{features: *[], type: string}}
 */
export function getFeatureCollection(features = []) {
  return {
    type: "FeatureCollection",
    features: features,
  };
}

/**
 * Sucht aus einem gegebenen Array an Objekten das heraus, was in seiner root den übereinstimmenden "id"-Wert besitzt
 * @param id
 * @param array
 * @return {*}
 */
export function getItemByRootId(id, array) {
  for (let i = 0; i < array.length; i++) {
    if (array[i].id == id) {
      return array[i];
    }
  }
}

/**
 * Iteriert ein Objekt durch bis zur tiefsten Value und speist diese dann
 * ins übergebene Array
 * @param {{}} element
 * @return []
 */
export function getDeepestObjectValues(element) {
  let array = [];
  const recurse = (element) => {
    if (typeof element === "object") {
      for (let key in element) {
        recurse(element[key]);
      }
    } else {
      // return Element, falls es quasi keine objekete mehr in sich trägt
      array.push(element);
    }
  };
  recurse(element);
  return array;
}

/**
 * Gibt aus einem CampusArray den richtigen Campus raus
 * @param {[]}campusArray
 * @param {number}campusId
 */
export function getCurrentCampus(campusArray, campusId) {
  for (let i = 0; i < campusArray.length; i++) {
    if (campusArray[i].campusinformationen.id == campusId) {
      return [campusArray[i]];
    }
  }
  throw Error(`campusID: ${campusId} can't find in the given campusArray.`);
}

export function truncTo(number, truncDigits) {
  if (typeof number === "number" && typeof truncDigits === "number") {
    let truncdigits = 10;
    for (let i = 0; i < truncDigits; i++) {
      truncdigits *= 10;
    }
    return Math.trunc(number * truncdigits) / truncdigits;
  } else return number;
}

export function indicatorLog(
  text = "",
  {
    background = "green",
    color = "white",
    padding = "0.5rem",
    borderRadius = "0.5rem",
    error = null,
  } = {}
) {
  console.log(
    `%c${text}`,
    `background: ${background}; padding: ${padding}; border-radius: ${borderRadius}; color: ${color}`,
    error
  );
}

let intentionalDebugMode = false;

export function toggleDebugMode(value) {
  intentionalDebugMode = value;
}

/**
 * Gibt an ob wir uns im "npm run start" modus, also Development oder im Production Modus befinden
 * @return {boolean}
 */
export function isInDevelopmentMode() {
  return (
    intentionalDebugMode ||
    !process.env.NODE_ENV ||
    process.env.NODE_ENV === "development"
  );
}
export function isInCustomerTestingMode() {
  return (
    window.location.href.includes("https://test") ||
    window.location.href.includes("https://dev-ws1") ||
    window.location.href.includes("https://dev-ws2") ||
    window.location.href.includes("https://dev-mk")
  );
}

let currentTime = new Date();
export const getDifferenceTime = () => {
  const difference = +new Date() - +currentTime;
  currentTime = new Date();
  return new Date(difference).getSeconds();
};

/**
 * Checks the zoom level by applying the function zoom = -1 * log2(km) + 15, since mapbox often fucks up
 * the zoom when map paddings are too tight. Mapbox then zooms out to about 8 for no reason at all.
 * This function is a logarithmic curve which is almost exactly the correct correlation between zoom and linelength
 * @param {number} zoom Zoom given by mapbox
 * @param {number} distance distance (km) of either a -> b or two bbox corners (opposites)
 * @returns {{zoom:{number}, corrected: {boolean}}} The corrected zoom level
 */
export const checkBoundingBoxZoom = (zoom, distance) => {
  // the allowance for the zoom to be a little bit off because of mappadding
  const tolerance = 1.5;
  const suggestedZoom = -1 * Math.log2(distance) + 15;
  if (zoom > suggestedZoom + tolerance || zoom < suggestedZoom - tolerance) {
    return { zoom: suggestedZoom, corrected: true };
  } else {
    return { zoom, corrected: false };
  }
};

export const getCampusNameById = (id) => {
  try {
    const resultArray = CAMPUS_COLLECTION.campuses.filter((element) => {
      return element.id === id;
    });

    return resultArray[0].name;
  } catch (e) {
    console.warn("Something went wrong with campus name", e);
    return "";
  }
};
