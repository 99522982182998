import { FormattedMessage, useIntl } from "react-intl";
import React, { useState, useEffect } from "react";
import { useBrowserGPS } from "../../mapUi/mapUi.component.jsx";
import ToggleSetting from "../../elements/buttons/toggleSetting.component.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StandardInstruction from "../../elements/text/standardInstruction.component.jsx";
import { standardIPhoneFunctionCall } from "../../nativeAppInterface/NativeAppInterface.jsx";
import { isAndroid, isIOS } from "react-device-detect";
import { useNativePermissionCheck } from "../../nativeAppInterface/sideEffects/useNativePermissionCheck.js";
import { useHealthStatsPermissionCall } from "../../nativeAppInterface/sideEffects/useHealthStatsPermissionCall.js";

/**
 * LocationAbfrage in den Einstellungen
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
export default function Location(props) {
  const handleLocationToggle = (e) => {
    props.setProperties({
      location: { userAllowed: !props.location.userAllowed },
    });
  };

  const handleAdvancedLocationToggle = () => {
    props.setProperties({
      healthStats: { userAllowed: !props.properties.healthStats.userAllowed },
    });
  };

  const intl = useIntl();

  const showAdvancedLocationSetting =
    props.isApp && isIOS && props.location.userAllowed;

  useBrowserGPS(
    props.setLocationPermissionAllowed,
    props.setGPSData,
    props.setUserAllowedGPS,
    props.addToast,
    props.isApp,
    props.userAllowedGPS,
    props.setProperties,
    props.isTerminal,
    props.locationObject
  );

  const { denial } = useNativePermissionCheck(
    props.userAllowedGPS,
    props.locationPermissionAllowed,
    true
  );

  const healthPermission = useNativePermissionCheck(
    props.properties.healthStats.userAllowed,
    props.properties.healthStats.permissionAllowed,
    props.isApp
  );

  useHealthStatsPermissionCall(props.properties, props.setProperties);

  return (
    <div className="d-flex flex-column">
      <StandardInstruction className={"mb-4"}>{props.text}</StandardInstruction>
      <ToggleSetting
        onChange={handleLocationToggle}
        errorText={
          denial
            ? intl.formatMessage({ id: "welcome.allowedButNoPermission" })
            : ""
        }
        settingTitle={<FormattedMessage id="settings.locationToggleTitle" />}
        settingText={<FormattedMessage id="settings.locationToggleText" />}
        toggleValue={props.location.userAllowed}
      />
      {showAdvancedLocationSetting && (
        <ToggleSetting
          onChange={handleAdvancedLocationToggle}
          errorText={
            healthPermission.error
              ? intl.formatMessage({
                  id: "welcome.healthStats.toggle.noPermissionError",
                })
              : ""
          }
          toggleValue={props.properties.healthStats.userAllowed}
          settingTitle={<FormattedMessage id="welcome.healthStats.headline" />}
          settingText={<FormattedMessage id="welcome.healthStats.text" />}
        />
      )}
      <HowToActivateLocation denial={denial} isApp={props.isApp} />
    </div>
  );
}

export function HowToActivateLocation({ denial, isApp }) {
  const intl = useIntl();
  if (denial && isApp) {
    if (isIOS) {
      // ios instruction
      return (
        <div className="p-2">
          <p>
            {intl.formatMessage({
              id: "settings.location.permissionDeniedInstruction.ios.goToSettings",
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "settings.location.permissionDeniedInstruction.ios.scrollToApps",
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "settings.location.permissionDeniedInstruction.ios.tapOnLocation",
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "settings.location.permissionDeniedInstruction.ios.checkAlways",
            })}
          </p>
        </div>
      );
    } else {
      // android instruction
      return (
        <div className="p-2">
          <p>
            {intl.formatMessage({
              id: "settings.location.permissionDeniedInstruction.android.goToSettings",
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "settings.location.permissionDeniedInstruction.android.scrollToApps",
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "settings.location.permissionDeniedInstruction.android.tapOnLocation",
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: "settings.location.permissionDeniedInstruction.android.checkAlways",
            })}
          </p>
        </div>
      );
    }
  } else if (denial && !isApp && isIOS) {
    return (
      <div className="p-2">
        <p>
          {intl.formatMessage({
            id: "settings.location.permissionDeniedInstruction.ios.goToSettings",
          })}
        </p>
        <p>
          {intl.formatMessage({
            id: "settings.location.permissionDeniedInstruction.safari.scrollToSafari",
          })}
        </p>
        <p>
          {intl.formatMessage({
            id: "settings.location.permissionDeniedInstruction.safari.tabLocation",
          })}
        </p>
        <p>
          {intl.formatMessage({
            id: "settings.location.permissionDeniedInstruction.safari.enable",
          })}
        </p>
        <p>
          {intl.formatMessage({
            id: "settings.location.permissionDeniedInstruction.safari.goToSettingsAgain",
          })}
        </p>
        <p>
          {intl.formatMessage({
            id: "settings.location.permissionDeniedInstruction.safari.scrollToPrivacy",
          })}
        </p>
        <p>
          {intl.formatMessage({
            id: "settings.location.permissionDeniedInstruction.safari.allowLocationPrivacy",
          })}
        </p>
        <p>
          {intl.formatMessage({
            id: "settings.location.permissionDeniedInstruction.safari.reload",
          })}
        </p>
      </div>
    );
  } else if (denial && !isApp && isAndroid) {
    return (
      <p className="p-2">
        {intl.formatMessage({
          id: "settings.location.permissionDeniedInstruction.androidBrowser.browserSettings",
        })}
      </p>
    );
  } else {
    return null;
  }
}

export function useGPSAllowance(
  userAllowedGPS,
  locationPermissionAllowed,
  setButtonDisabled,
  setError
) {
  useEffect(() => {
    if (userAllowedGPS && locationPermissionAllowed === null) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }

    if (locationPermissionAllowed === false) {
      setError(true);
    }
  }, [userAllowedGPS, locationPermissionAllowed]);
}

export function SearchingLocation({ show, className = "mb-3 text-primary" }) {
  if (show) {
    return (
      <FontAwesomeIcon
        icon={["fal", "map-marker-alt"]}
        className={"loadingPulse " + className}
        style={{ fontSize: 5 + "rem" }}
      />
    );
  } else return null;
}

export function useHealthStatsPermissionCheck(properties, setProperties) {
  const [error, setError] = useState(false);

  const tellIphoneToUseStats = (value) => {
    standardIPhoneFunctionCall([
      "requestMotionActivityManager?allowed=" + value,
    ]);
  };
  const checkPermission = () => {
    if (properties.healthStats.permissionAllowed === false) {
      setError(true);
      setProperties({
        healthStats: { userAllowed: false },
      });
    } else {
      setError(false);
    }
  };

  useEffect(() => {
    if (properties.healthStats.userAllowed) {
      tellIphoneToUseStats(true);
    } else {
      tellIphoneToUseStats(false);
    }

    checkPermission();
  }, [
    properties.healthStats.userAllowed,
    properties.healthStats.permissionAllowed,
  ]);

  return { error };
}
