import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./roundedIcon.module.scss";

export default function RoundedIcon({
  icon,
  iconClass,
  pulsing = false,
  divRef,
  divClass,
  disabled,
  onClick,
  title = "",
  iconRotation = 0,
  size = null,
  style = {},
  fontSize = null,
  role = "button",
  active = true,
  spin = false,
  ariaLabel = "",
}) {
  return (
    <button
      ref={divRef}
      className={`d-flex align-items-center justify-content-center roundedIcon ${
        pulsing ? [classes.pulsing, classes.pulsing13].join(" ") : ""
      } ${divClass}`}
      style={{
        width: size,
        height: size,
        ...style,
      }}
      onClick={onClick}
      title={title}
      role={role}
      disabled={disabled}
      aria-label={ariaLabel}
    >
      <div className={`d-flex position-relative`}>
        <FontAwesomeIcon
          rotation={iconRotation}
          icon={icon}
          spin={spin}
          className={`roundedIcon__icon ${iconClass}`}
          style={{ fontSize: fontSize }}
        />
        {active ? null : (
          <FontAwesomeIcon
            spin={spin}
            icon="slash"
            className={`roundedIcon__icon ${iconClass}`}
            style={{
              fontSize: fontSize,
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
        )}
      </div>
    </button>
  );
}

export function FilledRoundedIcon({
  title,
  active = false,
  onClick,
  role = "button",
  disabled = false,
  icon,
  size,
  style = {},
  className = "",
}) {
  return (
    <button
      style={{
        width: size,
        height: size,
        ...style,
      }}
      className={`p-0 d-flex align-items-center overflow-hidden position-relative pointer-event-on roundedIcon roundedIcon--filled ${className} ${
        active ? "roundedIcon--filled--active" : "roundedIcon--filled--inactive"
      }`}
      onClick={onClick}
      title={title}
      role={role}
      disabled={disabled}
    >
      <div
        className="d-flex h-100 w-100 position-absolute "
        style={{
          top: 0,
          backgroundImage: `url(${icon})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      />
    </button>
  );
}
