import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import classes from "./fullPage.module.scss";
import RoundedIcon from "../buttons/roundedIcon.component.jsx";
import SaveZoneWrapper from "../wrapper/saveZone.wrapper.jsx";
import StandardHeadline from "../text/standardHeadline.text.jsx";

export default function FullPage(props) {
  const [isScrolling, setIsScrolling] = useState(false);
  const [isSearching, setSearching] = useState(false);
  const searchInput = useRef(null);

  const trackScrolling = (e) => {
    if (e.target.scrollTop > 0) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }

    if (props.userHasScrolledToBottom)
      props.userHasScrolledToBottom(
        e.target.scrollTop === e.target.scrollHeight - e.target.clientHeight
      );
  };

  const toggleSearch = () => {
    setSearching((prevState) => !prevState);
    props.setSearchText("");
  };

  useEffect(() => {
    if (isSearching) {
      searchInput.current.focus();
    }
  }, [isSearching]);

  return (
    <div
      className={`${classes.container} ${
        props.positionAbsolute ? "position-absolute" : ""
      } d-flex flex-column fullPage align-items-center pointer-event-on fullPage ${
        props.outerClassName
      }`}
    >
      <SaveZoneWrapper
        className={`position-relative w-100 d-flex flex-column align-items-center`}
      >
        <header
          className={`${
            isScrolling ? `${classes.scrollContainer} shadow-1` : ""
          }  w-100 fullPage__header ${props.headerClassName}`}
        >
          <div
            className={`general-padding-horizontally align-items-center ${
              props.needClose || props.needBack ? "d-flex" : ""
            }`}
          >
            {props.needClose && (
              <RoundedIcon
                icon={["fal", "times"]}
                onClick={props.onClose}
                divClass={`roundedIcon--close`}
              />
            )}
            {props.needBack && (
              <RoundedIcon
                icon={["fal", "angle-left"]}
                onClick={props.onBack}
                divClass={`roundedIcon--back`}
              />
            )}
            <div
              className={`${classes.headerContainer} d-flex justify-content-between position-relative`}
            >
              <StandardHeadline
                className={`${props.titleCenter ? "w-100 text-center" : ""} ${
                  !isSearching ? classes.fadeOut : ""
                } ${props.headlineClassName} fullPage__header__headline`}
                text={!isSearching ? props.title : ""}
              />

              {props.showClose ? (
                <RoundedIcon
                  icon={["fal", "times"]}
                  onClick={props.close}
                  divClass={`roundedIcon--close ${classes.right} position-absolute`}
                />
              ) : (
                <>
                  <div
                    className={`${classes.search} ${
                      isSearching ? classes.active : ""
                    }`}
                  >
                    <RoundedIcon
                      icon={["fal", "search"]}
                      divClass={`roundedIcon--search`}
                    />
                    <input
                      value={props.searchText}
                      onChange={(e) => props.setSearchText(e.target.value)}
                      ref={searchInput}
                    />
                  </div>
                  {props.needSearch ? (
                    <RoundedIcon
                      icon={["fal", isSearching ? "times" : "search"]}
                      divClass={`roundedIcon--search ${classes.searchButton}`}
                      onClick={toggleSearch}
                    />
                  ) : null}
                </>
              )}
            </div>
          </div>
        </header>
        <div
          className={`${classes.innerContainer} pr-3 pl-3 w-100 h-100 flex-1 overflow-auto fullPage__content ${props.innerContainerClassName}`}
          onScroll={trackScrolling}
        >
          {props.children}
        </div>
      </SaveZoneWrapper>
    </div>
  );
}

FullPage.defaultProps = {
  needSearch: true,
  needClose: false,
  needBack: false,
  positionAbsolute: true,
  headlineClassName: "",
  outerClassName: "ca-bg-white",
  innerContainerClassName: "",
  headerClassName: "ca-bg-white",
};

FullPage.propTypes = {
  title: PropTypes.string.isRequired,
  titleCenter: PropTypes.bool,
  showClose: PropTypes.bool,
  close: PropTypes.func,
  outerClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  headlineClassName: PropTypes.string,
  innerContainerClassName: PropTypes.string,
  searchCategory: PropTypes.string,
  children: PropTypes.any.isRequired,
  userHasScrolledToBottom: PropTypes.func,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
  needSearch: PropTypes.bool,
  needClose: PropTypes.bool,
  onClose: PropTypes.func,
  needBack: PropTypes.bool,
  onBack: PropTypes.func,
  positionAbsolute: PropTypes.bool,
};
