import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import StandardText from "../text/standardText.text.jsx";
import StandardHeadline from "../text/standardHeadline.text.jsx";
import StandardButton from "../buttons/standard.button.jsx";
import StandardCard from "./StandardCard.card.jsx";
import { POIS } from "../../../reduxStore/actions/main.js";
import { pageNames } from "../../../reduxStore/reducers/main.js";
import { downloadCalendarEvent } from "../../../functions/classes/CalendarEvent.class.js";
import { getEvent } from "../../events/EventList.component.jsx";

/**
 *
 * @param backgroundUrl
 * @param headline
 * @param contentText
 * @param onClick
 * @param {"2021-07-26 09:36:24"} datetime
 * @param showTime
 * @param standardCardClassName
 * @param dateClass
 * @param imageClass
 * @param textClass
 * @param pinned
 * @param {{onClick, iconName, name}[]} infoPills
 * @return {JSX.Element}
 * @constructor
 */
export default function NewsEventCard({
  backgroundUrl,
  headline,
  contentText,
  onClick,
  datetime,
  showTime = false,
  standardCardClassName = "",
  dateClass = "standardCard__news__time",
  imageClass = "standardCard__news__image",
  textClass = "standardCard__news__text",
  pinned = false,
  infoPills = [],
  scrollTo = false,
  itemElement = undefined,
  handleCalendarSaveToClick,
}) {
  const intl = useIntl();
  const [highlighted, setHighlighted] = useState(false);
  const [time, setTime] = useState({
    day: null,
    month: null,
    year: null,
    time: null,
  });
  const ref = useRef();

  const prepareDateTime = () => {
    let day, month, year, time;
    if (datetime && typeof datetime === "string") {
      [, time] = datetime.split(" ");
      const dateString = datetime
        .split(" ")[0]
        .replace(new RegExp("-", "g"), "/");
      datetime = new Date(dateString);
      day = datetime.getDate();
      month = datetime.getMonth();
      year = datetime.getFullYear();
      let options = { month: "long" };
      month = new Intl.DateTimeFormat(navigator.language, options).format(
        new Date(datetime)
      );
      setTime({ time, day, year, month });
    }
  };

  const manageScrollTo = () => {
    if (scrollTo) {
      setTimeout(() => {
        if (ref.current) {
          ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
          setHighlighted(true);
          onClick();
        }
      }, 1000);
    }
  };
  const manageHighlight = () => {
    if (highlighted)
      setTimeout(() => {
        setHighlighted(false);
      }, 3000);
  };

  useEffect(prepareDateTime, []);
  useEffect(manageScrollTo, [scrollTo]);
  useEffect(manageHighlight, [highlighted]);

  return (
    <StandardCard
      ref={ref}
      padding={false}
      className={`standardCard--newsEvent position-relative overflow-hidden ${
        pinned ? "standardCard--newsEvent--pinned" : ""
      } ${highlighted ? "highlighted" : ""} ${standardCardClassName}`}
    >
      {backgroundUrl != null && (
        <div className={`w-100 mb-3 ${imageClass}`}>
          <img style={{ width: "100%" }} src={backgroundUrl} />
        </div>
      )}
      {datetime && (
        <div
          className={`d-flex mb-3 ${
            backgroundUrl == null ? "mt-3" : ""
          } ${dateClass}`}
        >
          <div className={"standardTimeBox"}>
            <small>
              {time.day}. {time.month} {time.year}
            </small>
          </div>
          {showTime && time && "00:00:00" !== time ? (
            <div className="standardTimeBox">
              <small>
                <>
                  {time.time.substring(0, time.time.length - 3)}{" "}
                  <FormattedMessage id="event.oClock" />
                </>
              </small>
            </div>
          ) : null}
        </div>
      )}
      <div className={`pl-3 pr-3 pb-3 ${textClass}`}>
        <StandardHeadline text={headline} className={"mb-3"} type={"h3"} />
        <StandardText text={contentText} className={"mb-3"} />
        {infoPills?.length > 0 && (
          <div className="d-flex flex-wrap">
            {infoPills.map((pill, index) => {
              return (
                <StandardButton
                  key={index}
                  icon={["fal", pill.iconName]}
                  buttonClasses={"link-pill mr-3 mb-3"}
                  handleClick={pill.onClick}
                >
                  {pill.name}
                </StandardButton>
              );
            })}
          </div>
        )}
        {onClick && (
          <StandardButton
            buttonClasses={
              "normalButton--naked normalButton--naked--showMore normalButton--naked__eventCard mb-3"
            }
            handleClick={onClick}
          >
            {intl.formatMessage({ id: "event.readMore" })}
          </StandardButton>
        )}
        {itemElement && (
          <StandardButton
            buttonClasses={
              "normalButton--naked normalButton--naked--showMore normalButton--naked__eventCard"
            }
            handleClick={(event) => handleCalendarSaveToClick(itemElement)}
          >
            {intl.formatMessage({ id: "event.saveToCalendar" })}
          </StandardButton>
        )}
      </div>
    </StandardCard>
  );
}

export const getInfoPills = (
  newsEventItem,
  setCurrentFeature,
  history,
  onClick
) => {
  const pills = [];
  const organisatorIds = newsEventItem.additionalData?.organisators ?? [];
  const locationId = newsEventItem.additionalData?.location;

  organisatorIds.forEach((o) => {
    let organisatorPOI = POIS.all_objects[o];
    if (organisatorPOI)
      pills.push({
        name: organisatorPOI.name,
        iconName: "user",
        onClick: () => {
          if (onClick) {
            onClick();
          }
          if (history) {
            history.push(
              `/${pageNames.map}/${pageNames.info}${window.location.search}`
            );
          }
          setTimeout(() => {
            if (setCurrentFeature) setCurrentFeature(organisatorPOI);
          }, 200);
        },
      });
  });
  let locationPOI = POIS.all_objects[locationId];
  if (locationPOI)
    pills.push({
      name: locationPOI.name,
      iconName: "map-marker",
      onClick: () => {
        if (onClick) {
          onClick();
        }
        setTimeout(() => {
          if (setCurrentFeature) setCurrentFeature(locationPOI);
        }, 200);
        if (history) {
          history.push(
            `/${pageNames.map}/${pageNames.info}${window.location.search}`
          );
        }
      },
    });

  return pills;
};
