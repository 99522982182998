import StartDestination from "../../../../functions/classes/startDestination.class.js";
import Leg from "../../../../functions/classes/Leg.class.js";
import { mapIcons } from "../../../../assets/images/map/icons.images.js";

/**
 * Die Filter bedeuten basically zeige nur Features mit diesem Merkmal an
 * z.B. bei Beacon -> "Zeige nur Features an, isBeacon = true in den properties haben
 * ein "any" davor wie bei commonSymbols -> "zeige mir alle an, die entweder entrance, poi... sind"
 * die properties wie "isTerminal", "isHaupteingang" werden im Initializer -> setFeatureEigenschaften() gesetzt
 */
export const EXPRESSIONS = {
  iconname: {
    notaufnahme: ["==", ["get", "iconname"], mapIcons.emergency.dbName],
    parking: ["==", ["get", "iconname"], mapIcons.parkingSpot_marker.dbName],
    parkingBike: ["==", ["get", "iconname"], mapIcons.parkingBike.dbName],
    parkingDisabled: [
      "==",
      ["get", "iconname"],
      mapIcons.parkingDisabled.dbName,
    ],
    taxi: ["==", ["get", "iconname"], mapIcons.taxi.dbName],
    jelbi: ["==", ["get", "iconname"], mapIcons.jelbi_heart.dbName],
    kasse: ["==", ["get", "iconname"], mapIcons.cashier.dbName],
    apotheke: ["==", ["get", "iconname"], mapIcons.pharmacy.dbName],
    wcmen: ["==", ["get", "iconname"], mapIcons.wcmen.dbName],
    wcwomen: ["==", ["get", "iconname"], mapIcons.wcwomen.dbName],
    wcwomenandmen: ["==", ["get", "iconname"], mapIcons.wcwomenandmen.dbName],
    wcdisabled: ["==", ["get", "iconname"], mapIcons.wcdisabled.dbName],
    bistro: ["==", ["get", "iconname"], mapIcons.food.dbName],
    info: ["==", ["get", "iconname"], mapIcons.info.dbName],
    geldautomat: ["==", ["get", "iconname"], mapIcons.atm.dbName],
    bushaltestelle: ["==", ["get", "iconname"], mapIcons.busstop.dbName],
    virus: ["==", ["get", "iconname"], mapIcons.virus.dbName],
    elevator: ["==", ["get", "iconname"], mapIcons.elevator.dbName],
    stairs: ["==", ["get", "iconname"], mapIcons.stairs.dbName],
    elevatorRestricted: [
      "==",
      ["get", "iconname"],
      mapIcons.elevatorRestricted.dbName,
    ],
    stairsRestricted: [
      "==",
      ["get", "iconname"],
      mapIcons.stairsRestricted.dbName,
    ],
    entrance: ["==", ["get", "iconname"], mapIcons.entrance.dbName],
  },
  class: {
    important: ["==", ["get", "class"], "important"],
  },
  type: {
    start: ["==", ["get", "type"], StartDestination.types.start],
    destination: ["==", ["get", "type"], StartDestination.types.destination],
    legChange: ["==", ["get", "type"], StartDestination.types.legChange],
    switchFloor: ["==", ["get", "type"], "switchFloor"],
    parkingSpot: ["==", ["get", "type"], "parkingSpot"],
    markingSpot: ["==", ["get", "type"], "markingSpot"],
    campusEntry: ["==", ["get", "type"], "campusEntry"],
    buildingEntry: ["==", ["get", "type"], "buildingEntry"],
    einrichtung: ["==", ["get", "type"], "Einrichtung"],
    poi: ["==", ["get", "type"], "POI"],
    ziel: ["==", ["get", "type"], "Ziel"],
    room: ["==", ["get", "type"], "Raum"],
    gebaude: ["==", ["get", "type"], "Gebäude"],
    stockwerk: ["==", ["get", "type"], "Stockwerk"],
    campus: ["==", ["get", "type"], "Campus"],
    parkplatz: ["==", ["get", "type"], "Parkplatz"],
    adresse: ["==", ["get", "type"], "Adresse"],
  },
  roomType: {
    // --- rooms ---
    elevatorRoom: ["==", ["get", "type"], "elevatorRoom"],
    stairsRoom: ["==", ["get", "type"], "stairsRoom"],
    switchFloorRoom: ["==", ["get", "type"], "switchFloor"],
    waitingRoom: ["==", ["get", "type"], "waitingRoom"],
    cafeteriaRoom: ["==", ["get", "type"], "cafeteriaRoom"],
    wcRoom: ["==", ["get", "type"], "wcRoom"],
    entranceRoom: ["==", ["get", "type"], "entranceRoom"],
    emergencyRoom: ["==", ["get", "type"], "emergencyRoom"],
    // wird zum filtern benutzt
    hiddenRoom: [
      "any",
      ["==", ["get", "type"], "hiddenRoom"],
      ["==", ["get", "type"], "hidden"],
    ],

    // --- Areas ---
    waitingArea: ["==", ["get", "type"], "waitingArea"],
    officeArea: ["==", ["get", "type"], "officeArea"],
    cafeteriaArea: ["==", ["get", "type"], "cafeteriaArea"],
    emergencyArea: ["==", ["get", "type"], "emergencyArea"],

    // --- Floors ---
    mainFloor: [
      "any",
      ["==", ["get", "type"], "mainFloor"],
      ["==", ["get", "class"], "floor"],
    ],
    sideFloor: [
      "any",
      ["==", ["get", "type"], "sideFloor"],
      ["==", ["get", "class"], "floor"],
    ],
    restrictedFloor: [
      "any",
      ["==", ["get", "type"], "restrictedFloor"],
      ["==", ["get", "class"], "floor"],
    ],

    // --- misc ---
    exit: ["==", ["get", "type"], "exit"],

    // --- additionals ---
    glass: ["boolean", ["get", "glass"], false],
    hasHeight: ["has", "height"],
    getHeight: ["get", "height"],
  },
  buildingClass: {
    normal: ["==", ["get", "class"], "normal"],
    main: ["==", ["get", "class"], "main-building"],
    parkingGarage: ["==", ["get", "class"], "parking-garage"],
    parkingLot: ["==", ["get", "class"], "parking-lot"],
  },
  state: {
    selected: ["boolean", ["feature-state", "selected"], false],
    leftEdge: ["boolean", ["feature-state", "leftEdge"], false],
    rightEdge: ["boolean", ["feature-state", "rightEdge"], false],
    hover: ["boolean", ["feature-state", "hover"], false],
    differentLevel: ["boolean", ["feature-state", "differentLevel"], false],
    marking: ["boolean", ["feature-state", "marking"], false],
  },

  line: {
    oneWay: ["boolean", ["get", "isOneWay"], false],
    indoor: ["boolean", ["get", "isIndoor"], false],
    gesperrt: ["boolean", ["get", "isGesperrt"], false],
    kfz: [
      "any",
      ["in", 7, ["get", "attributIds"]],
      ["in", 7, ["get", "mode"]],
      ["==", ["get", "mode"], Leg.modes.car],
      ["==", ["get", "mode"], Leg.modes.car_traffic],
    ],
    fahrrad: [
      "any",
      ["in", 9, ["get", "attributIds"]],
      ["in", 9, ["get", "mode"]],
      ["==", Leg.modes.bicycle, ["get", "mode"]],
    ],
    fussgaenger: [
      "any",
      ["in", 8, ["get", "attributIds"]],
      ["in", 8, ["get", "mode"]],
      ["==", Leg.modes.onFoot, ["get", "mode"]],
      ["==", Leg.modes.mobilityImpaired, ["get", "mode"]],
    ],
    mitarbeiter: ["in", 25, ["get", "attributIds"]],
    bewegungseingeschraenkt: ["in", 10, ["get", "attributIds"]],
    bevorzugen: ["in", 36, ["get", "attributIds"]],
    seltenBenutzen: ["in", 29, ["get", "attributIds"]],
  },
  misc: {
    additionalData: ["get", "additionalData"],
    additionalData_string: "additionalData",
    class: ["get", "class", ["get", "additionalData"]],
    onlyClass: ["get", "class"],
    class_string: "class",
    isOutdoor: ["!=", ["get", "indoors"], 1],
    isIndoor: ["==", ["get", "indoors"], 1],
    short: ["get", "short"],
    name: ["get", "name"],
  },
};

export const EXPRESSION_MIX = {
  symbols: {
    unimportant: [
      "any",
      EXPRESSIONS.iconname.elevator,
      EXPRESSIONS.iconname.stairs,
      EXPRESSIONS.iconname.elevatorRestricted,
      EXPRESSIONS.iconname.stairsRestricted,
    ],
    restricted: [
      "any",
      EXPRESSIONS.iconname.elevatorRestricted,
      EXPRESSIONS.iconname.stairsRestricted,
    ],
    noCircle: ["any", EXPRESSIONS.iconname.jelbi],
  },
};
export const debuggingValues = {
  debugLineLayerId: "linelayerdebugggggg",
  debugLineSourceId: "debugsourrceliiiine",
};
