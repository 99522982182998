import { useEffect } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { postData } from "../functions/helper/helpers.functions";
import * as actionToast from "../reduxStore/actions/toasts.js";
import Toast from "../functions/classes/toast.class.js";
import { appVersion } from "../app/app.sideEffects/useURLParams.sideEffects";
import StackTrace from "stacktrace-js";

window.onerror = () => {};
function LogBoundary(props) {
  const intl = useIntl();

  useEffect(() => {
    window.onerror = handleErrorEnter;
    window.addLog = handleLogEnter;
    window.addWarning = handleWarningEnter;
    window.saveLog = handleSaveLog;
    window.addError = handleAddError;
  });

  const handleAddError = (error) => {
    handleErrorEnter(error);
  };

  const handleErrorEnter = (message, source, lineno, colno, error) => {
    StackTrace.fromError(error)
      .then((err) => {
        uploadMessage("error", message, true, true, JSON.stringify(err));
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleLogEnter = (info) => {
    if (info.show) {
      showMessage("info", info.userMessage);
    }
    if (info.upload) {
      uploadMessage("info", info.message, false);
    }

    console.log(info);
  };

  const handleWarningEnter = (warning) => {
    if (warning.show) {
      showMessage("warning", warning.userMessage);
    }
    if (warning.upload) {
      uploadMessage(
        "warning",
        warning.message,
        warning.needAdditionInformation
      );
    }
  };

  const handleSaveLog = (obj) => {
    postData(process.env.REACT_APP_LOG_URL, obj, true);
  };

  const showMessage = (type, message) => {
    message = intl.formatMessage({ id: message });
    props.addToast(new Toast(message, message, type));
  };

  const uploadMessage = (
    type,
    message,
    needAdditionInformation = false,
    sendMail = false,
    stack = undefined
  ) => {
    const url = window ? window.location.href : "App";
    // console.log({ type, message, needAdditionInformation, sendMail });
    if (url.includes("localhost")) return;
    const additionData = needAdditionInformation
      ? {
          url,
          userInformation: getUserInformation(),
          applicationInformation: {
            stack,
            ...props.settings,
            ...props.navigation,
            ...props.route,
            ...props.main,
            ...props.map,
          },
          request: getRequests(),
        }
      : {};
    postData(
      process.env.REACT_APP_ERROR_LOG_URL,
      {
        sessionId: props.sessionId,
        type,
        appVersionName: process.env.REACT_APP_APP_VERSION_NAME,
        message: message,
        nativeAppVersion: appVersion,
        additionData,
        date: new Date(),
        sendMail,
      },
      true
    );
  };

  return props.children;
}

const mapDispatchToProps = (dispatch) => ({
  addToast: (object) => dispatch(actionToast.addToast(object)),
});
const mapStateToProps = (state) => {
  return {
    settings: state.settings,
    navigation: state.route,
    route: {
      start: state.route?.start,
      target: state.route?.target,
    },
    main: {
      currentFeature: state.main?.currentFeature,
      lastFeature: state.main?.lastFeature,
      language: state.main?.language,
      currentPage: state.main?.currentPage,
      inApp: state.main?.inApp,
    },
    map: {
      mapLoaded: state.map?.mapLoaded,
      currentPage: state.map?.currentPage,
      mapClicks: state.map?.mapClicks,
      show3DBuildings: state.map?.show3DBuildings,
    },
    sessionId: state.main.sessionId,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogBoundary);

function getRequests() {
  if (!performance.getEntriesByType("resource")) return null;
  return performance
    .getEntriesByType("resource")
    .filter((item) => "xmlhttprequest" === item.initiatorType)
    .map((item) => ({
      url: item.name,
      size: item.encodedBodySize,
      duration: item.duration,
    }));
}

function getUserInformation() {
  if (!navigator) return null;
  return {
    appVersion: navigator.appVersion,
    bluetooth: navigator.bluetooth,
    connection: navigator.connection,
    cookieEnabled: navigator.cookieEnabled,
    geolocation: navigator?.geolocation,
    language: navigator.language,
    maxTouchPoints: navigator.maxTouchPoints,
    permissions: navigator.permissions,
    platform: navigator.platform,
    userActivation: navigator.userActivation,
    userAgent: navigator.userAgent,
  };
}
