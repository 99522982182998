import * as types from "../types.variables.js";
import { CAMPUS_COLLECTION } from "./main";

export function setOpen() {
  return {
    type: types.FLOOR_SET_OPEN,
  };
}

export function setClose() {
  return {
    type: types.FLOOR_SET_CLOSE,
  };
}

export function toggleOpen() {
  return {
    type: types.FLOOR_TOGGLE_OPEN,
  };
}

/**
 * Ändert das Level/ den Floor
 * @param index - Der Stockwerk index im Floor Array
 * @param {bool} bounce - Soll die Kamera bouncen beim Stockwerkwechsel?
 * Sollte man ausschalten, wenn kurz danach ein flyTo Befehl kommt, da dieser sonst gecancelled wird
 * @returns {{payload: {bounce: boolean, index}, type: string}|{type: string}}
 */
export function setCurrentFloorIndex(index, bounce = true) {
  // verhindert dass out of bounds stockwerke indizes aufgerufen werden

  console.log(
    "soll false sein",
    isNaN(index) ||
      index < 0 ||
      index > CAMPUS_COLLECTION.getCurrentCampus().floors.length - 1
  );
  if (
    isNaN(index) ||
    index < 0 ||
    index > CAMPUS_COLLECTION.getCurrentCampus().floors.length - 1
  )
    return {
      type: "UNHANDELD_FLOOR",
    };

  return {
    type: types.FLOOR_SET_CURRENT_FLOOR,
    payload: { index: index, bounce: bounce },
  };
}
// Stichwort: Level6-Bug bei UKSH-iFrame
// Das ist auch höchst verdächtig!
// 1. setCFN
// 2. Fehlerbehandlung.
// 3.

/**
 * @param number - Actual Nummer des Stockwerks (nicht der Index)
 * @param {bool} bounce - Soll die Kamera bouncen beim Stockwerkwechsel?
 * Sollte man ausschalten, wenn kurz danach ein flyTo Befehl kommt, da dieser sonst gecancelled wird
 * @returns {{payload: {bounce: boolean, index: number}, type: string}|{type: string}}
 */
export function setCurrentFloorNumber(number, bounce = true) {
  let index = -1;
  CAMPUS_COLLECTION.getCurrentCampus().floors.forEach((item, i) => {
    if (item.stockwerk == number || item.level == number) index = i;
  });

  // verhindert dass out of bounds stockwerke indizes aufgerufen werden
  if (
    index < 0 ||
    index > CAMPUS_COLLECTION.getCurrentCampus().floors.length - 1
  )
    return {
      type: "UNHANDELD_FLOOR",
    };
  return {
    type: types.FLOOR_SET_CURRENT_FLOOR,
    payload: { index: index, bounce: bounce },
  };
}
