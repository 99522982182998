import { layerIds } from "../../variables/layerIds.js";
import { setLayerFilters, zoomBuildingsStart } from "../filter.map.js";
import { EXPRESSIONS } from "../../variables/expressions.js";

/**
 * Filtert die GEojson rooms, hallways und outline by level
 */
export function filterRoomPolygons(allFilters) {
  // min zoom zum spawnen
  const zoomFilters = [">", ["zoom"], zoomBuildingsStart];
  // filter für die alle Räume
  const roomFilters = [
    "all",
    ["!", EXPRESSIONS.roomType.hiddenRoom],
    zoomFilters,
  ];
  // filter für die Räume, die aus Glaß sind
  const glassRoomFilters = ["all", EXPRESSIONS.roomType.glass, zoomFilters];

  setLayerFilters(
    [layerIds.interiorGeoJSONs.outline],
    allFilters.concat([zoomFilters])
  );
  setLayerFilters(
    [layerIds.interiorGeoJSONs.rooms],
    allFilters.concat([roomFilters])
  );
  setLayerFilters(
    [layerIds.interiorGeoJSONs.glassRooms],
    allFilters.concat([glassRoomFilters])
  );
}
