import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSpring, a, config } from "react-spring";
import { toastTypes } from "../../../functions/classes/toast.class.js";
import StandardCard from "../cards/StandardCard.card.jsx";
import { CUSTOMIZABLE_VALUES } from "../../mapUi/mapbox/variables/CUSTOMIZABLE_VALUES.js";

/**
 * Der Toast Renderer kümmert sich um die Animations und so, die Logik hat hier ansonsten nix zu suchen
 * @param props
 * @constructor
 */
export default function Toast({
  toast,
  state,
  setToastAdded,
  setToastDeleted,
}) {
  const [spring, springAPI] = useSpring(() => ({}));
  const [innerState, setInnerState] = useState(state);
  const container = useRef();

  // Spawn und Despawn Animationen und ihre Callbacks
  const addToast = () => {
    springAPI.start(() => ({
      from: { opacity: 0, transform: `translateY(-50px)` },
      to: {
        opacity: 1,
        transform: `translateY(0px)`,
      },
      config: { ...config.wobbly },
      onRest: () => {
        setToastAdded(toast);
      },
    }));
  };
  const deleteToast = () => {
    // todo: irgendetwas passiert noch wenn sie verschwunden sind, irgendeine animation, die noch etwas aufblinken lässt
    springAPI.start(() => ({
      from: {
        opacity: 1,
        transform: `translateY(0)`,
        height: container.current.clientHeight,
      },
      to: {
        opacity: 0,
        // height: 0,
        // paddingTop: 0,
        // paddingBottom: 0,
        // marginTop: 0,
        // marginBottom: 0,
        transform: `translateY(-50px)`,
      },
      config: config.wobbly,
      onRest: () => {
        setToastDeleted(toast);
      },
    }));
  };
  const tapToast = () => {
    // Falls gerade die Add Animation noch im Gang ist muss sie für den
    // nächsten render wind davon bkeommen dass der init state "tapped"sein soll!
    if (toast.tapped) {
      springAPI.start(() => ({ scale: 1.1 }));
    } else {
      springAPI.start(() => ({
        from: { scale: 1 },
        scale: 1.1,
      }));
    }
    toast.tapped = true;
  };

  useEffect(() => {
    // was soll ich tun beim ersten call oder wenn mein State sich ändert?
    switch (innerState) {
      case "add":
        addToast();
        break;
      case "delete":
        deleteToast();
        break;
      case "tapped":
        tapToast();
      default:
        // dont act
        break;
    }
  }, [innerState]);

  useEffect(() => {
    // nur bei Stay ist der delete Befehl relevant
    if (innerState !== "stay") return;

    // delete myself timer
    let intervalId = setInterval(() => {
      if (toast.spawnTime + toast.lifeTime < new Date().getTime()) {
        clearInterval(intervalId);
        setInnerState((prevState) => {
          if (prevState === "tapped") {
            return "tapped";
          } else {
            return "delete";
          }
        });
      }
    }, 500);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const getIcon = () => {
    switch (toast.type) {
      case toastTypes.success:
        return {
          icon: ["fal", "check-circle"],
          color: CUSTOMIZABLE_VALUES.success,
        };
      case toastTypes.error:
        return {
          icon: ["fal", "times-circle"],
          color: CUSTOMIZABLE_VALUES.error,
        };
      case toastTypes.warning:
        return {
          icon: ["fal", "exclamation-circle"],
          color: CUSTOMIZABLE_VALUES.warning,
        };
      default:
        return {
          icon: ["fal", "info-circle"],
          color: CUSTOMIZABLE_VALUES.info,
        };
    }
  };

  return toast ? (
    <div className={"col-12 col-md-8"}>
      <a.div
        onClick={() => {
          setInnerState((prevState) => {
            if (prevState !== "tapped") {
              return "tapped";
            } else {
              return "delete";
            }
          });
        }}
        style={spring}
        className={`mt-3 pointer-event-on `}
        ref={container}
      >
        <StandardCard
          className={"standardCard--toast flex-row align-items-center"}
        >
          <FontAwesomeIcon
            icon={getIcon().icon}
            className={"mr-3"}
            color={getIcon().color}
          />
          <span className={`mr-auto`}>{toast.text}</span>
          <div className={"d-flex"}>
            {toast.actions.map((action, index) => {
              return (
                <span
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    action.onClick();
                  }}
                  className={`p-1 ml-auto btn text-white ml-3`}
                >
                  {action.text}
                </span>
              );
            })}
          </div>
        </StandardCard>
      </a.div>
    </div>
  ) : null;
}
