import * as types from "../types.variables";

/**
 *
 * @param object
 * @return {{payload: *, type: string}}
 */
export function setDebuggingValues(object) {
  return {
    type: types.DEBUG_SET_VALUES,
    payload: object,
  };
}

/**
 * @param {UploadItem} uploadItem
 */
export function setNewUploadItem(uploadItem) {
  return {
    type: types.DEBUG_SET_UPLOAD_ITEM,
    payload: uploadItem,
  };
}
