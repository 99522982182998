export default function CurrentRating(props) {
  if (!props.steps) return null;

  const currentStepPos = props.totalProgress | 0;
  const currentStep = props.steps[currentStepPos];
  const nextStep =
    props.steps[4 === currentStepPos ? currentStepPos : currentStepPos + 1];

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="55px"
        height="55px"
        viewBox="0 0 50 50"
      >
        <path
          d="M50,25 C50,38.807 38.807,50 25,50 C11.193,50 0,38.807 0,25 C0,11.193 11.193,0 25,0 C38.807,0 50,11.193 50,25"
          fill={`${interpolateColor(
            currentStep.imageColor,
            nextStep.imageColor,
            props.progress
          )}`}
        />
        <path
          d={getPath(currentStep, nextStep, props.progress, "mouth")}
          fill="#655F52"
        />
        <path
          d={getPath(currentStep, nextStep, props.progress, "rightEye")}
          fill="#655F52"
        />
        <path
          d={getPath(currentStep, nextStep, props.progress, "leftEye")}
          fill="#655F52"
        />
      </svg>
    </>
  );
}
// color set
function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
}

function rgbToHex(r, g, b) {
  return (
    "#" +
    [r, g, b]
      .map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      })
      .join("")
  );
}

function interpolateColor(color1, color2, progress) {
  const rgb = interpolatedArray(
    hexToRgb(color1),
    hexToRgb(color2),
    progress
  ).map(function (channel) {
    return Math.round(channel);
  });

  return rgbToHex(rgb[0], rgb[1], rgb[2]);
}

// path set
function getPath(currentStep, nextStep, progress, elementName = "mouth") {
  const currentPath = splitString(currentStep[elementName]);
  const nextPath = splitString(nextStep[elementName]);

  const interpolatedPoints = interpolatedArray(
    currentPath.digits,
    nextPath.digits,
    progress
  );

  return recomposeString(interpolatedPoints, currentPath.nonDigits);
}

// helper functions
function recomposeString(digits, nonDigits) {
  return nonDigits.reduce((a, b, i) => a + digits[i - 1] + b);
}

function splitString(str) {
  const regEx = /-?\d*\.?\d+/g;

  return {
    digits: str.match(regEx).map(Number),
    nonDigits: str.split(regEx),
  };
}

function interpolatedArray(startArray, endArray, progress) {
  return startArray.map(
    (startValue, index) =>
      (endArray[index] - startValue) * progress + startValue
  );
}
