import React, {useEffect} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import StandardButton from "../../../elements/buttons/standard.button.jsx";
import HorizontalScroller from "../../../elements/cards/horizontalscroller.containter";

/**
 *
 * @param {Route} currentRoute
 * @param roadUserType
 * @param setRoadUserType
 * @param askForParkingSpot
 * @return {JSX.Element}
 * @constructor
 */

export function RouteModes(props) {
  const intl = useIntl();
  useEffect(() => {
    if (!props.currentRoute) return;

    if (props.currentRoute.getRemainingDistance()) {
    }
  }, [props.currentRoute]);

  return (
      <HorizontalScroller id={"hs-route-modes-container"} className={"pl-3 pr-3 pb-3"}>
        <StandardButton
            role={"option"}
            ariaSelected={10 === props.roadUserType}
            ariaLabel={intl.formatMessage(
                { id: "route.screenreader.routeMode" },
                {
                  name: intl.formatMessage({ id: "route.wheelchair" }),
                }
            )}
            buttonClasses={`normalButton--filterSelection ${
                10 === props.roadUserType
                    ? "normalButton--filterSelection--selected"
                    : "normalButton--filterSelection--unselected"
            } mr-2`}
            handleClick={() => {
              props.setRoadUserType(10);
              // Oben wird RoadUserType gesetzt, mutmaßlich für externe Routenberechnungen.
              // Unten wird die Eigenschaft für interne Routenberechnung gesetzt, die sonst über die RouteCard festgeleget werden.
              const settings = {
                routing: {
                  movementRestricted: true,
                },
              };
              props.setSettingsProperties(settings);
            }}
            icon={["fal", "wheelchair"]}
        >
          <FormattedMessage id="route.accessibility" />
        </StandardButton>
        <StandardButton
            role={"option"}
            ariaSelected={8 === props.roadUserType}
            ariaLabel={intl.formatMessage(
                { id: "route.screenreader.routeMode" },
                {
                  name: intl.formatMessage({ id: "route.pedestrian" }),
                }
            )}
            buttonClasses={`normalButton--filterSelection ${
                8 === props.roadUserType
                    ? "normalButton--filterSelection--selected"
                    : "normalButton--filterSelection--unselected"
            } mr-2`}
            handleClick={() => {
              props.setRoadUserType(8);
              // Analog zum Bewegungseingeschränkt-Modus, siehe ein Button weiter oben.
              const settings = {
                routing: {
                  movementRestricted: false,
                },
              };
              props.setSettingsProperties(settings);
            }}
            icon={["fal", "walking"]}
        >
          <FormattedMessage id="route.pedestrian" />
        </StandardButton>
        <StandardButton
            role={"option"}
            ariaSelected={9 === props.roadUserType}
            ariaLabel={intl.formatMessage(
                { id: "route.screenreader.routeMode" },
                {
                  name: intl.formatMessage({ id: "route.cyclist" }),
                }
            )}
            buttonClasses={`normalButton--filterSelection ${
                9 === props.roadUserType
                    ? "normalButton--filterSelection--selected"
                    : "normalButton--filterSelection--unselected"
            } mr-2`}
            handleClick={() => props.askForParkingSpot(9)}
            icon={["fal", "biking"]}
        >
          <FormattedMessage id="route.cyclist" />
        </StandardButton>
        <StandardButton
            role={"option"}
            ariaSelected={7 === props.roadUserType}
            ariaLabel={intl.formatMessage(
                { id: "route.screenreader.routeMode" },
                {
                  name: intl.formatMessage({ id: "route.driver" }),
                }
            )}
            buttonClasses={`normalButton--filterSelection ${
                7 === props.roadUserType
                    ? "normalButton--filterSelection--selected"
                    : "normalButton--filterSelection--unselected"
            } mr-2`}
            handleClick={() => props.askForParkingSpot(7)}
            icon={["fal", "car"]}
        >
          <FormattedMessage id="route.driver" />
        </StandardButton>
      </HorizontalScroller>
  );
}
