import * as actionMain from "../../reduxStore/actions/main.js";
import {
  CONTENT,
  MODULES,
  POIS,
  setNotificationPostId,
} from "../../reduxStore/actions/main.js";
import * as actionFloor from "../../reduxStore/actions/floors.js";
import * as actionNavigation from "../../reduxStore/actions/navigation.js";
import * as actionDebug from "../../reduxStore/actions/debug.js";
import * as actionSetting from "../../reduxStore/actions/settings.js";
import { setProperties } from "../../reduxStore/actions/settings.js";
import { connect } from "react-redux";
import { useEffect } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { indicatorLog } from "../../functions/helper/helpers.functions.js";
import { withRouter } from "react-router-dom";
import { getQueryParams } from "../../app/RouteWithSubRoutes.jsx";
import * as actionRoute from "../../reduxStore/actions/route.js";
import StartDestination from "../../functions/classes/startDestination.class.js";
// import { useNativeCamera } from "./sideEffects/useNativeCamera.js";
import { useNativePushMessages } from "./sideEffects/useNativePushMessages.jsx";
import { useNativeLocation } from "./sideEffects/useNativeLocation.jsx";
import usePushNotificationActions from "./sideEffects/usePushNotificationActions.js";
import { pageNames } from "../../reduxStore/reducers/main.js";
import { useRef } from "react";
// import { isInDevelopmentMode } from "../../../functions/helper/helpers.functions";
import { isInDevelopmentMode } from "../../functions/helper/helpers.functions.js";
import { useIntl } from "react-intl";

export let IOS_ACTIONS = [];
function NativeAppInterface(props) {
  useNativeLocation(props);
  // useNativeCamera(
  //   props.cameraPermissionAllowed,
  //   props.setCameraPermissionAllowed
  // );

  useEffect(() => {
    window.isDataScannerAvailable = (value) => {
      props.setDataScannerAvailable(value);
    };
  }, []);

  useNativePushMessages(props);
  usePushNotificationActions(
    props.serverDataLoaded,
    props.history,
    props.setNotificationPostId,
    props.introComplete
  );

  useEffect(() => {
    // miscelleanous App Funktionen
    setTimeout(() => {
      hideNativeLoadingScreen();
    }, 2000);

    // todo: diese funktion ist obsolete, muss bei iOS noch raus
    window.didUpdateZIndex = (value) => {};
    window.didUpdateDeviceInformation = (data) => {
      props.setDeviceInformation(data);
    };
    // TODO: Sehbehindertenmodus
    window.setScreenReaderEnabled = (value) => {
      //console.log("setScreenReaderEnabled: " + value);
    };
  }, []);
  const introCompleteRef = useRef(false);
  useEffect(() => {
    introCompleteRef.current = props.introComplete;
  }, [props.introComplete]);
  const handleCalledURLInNativeApp = () => {
    if (props.isApp) {
      indicatorLog("Verwendung in Native App Umfeld", {
        background: "cyan",
        color: "black",
      });
    }

    window.remoteURLCalled = (urlString) => {
      const run = () => {
        const urlObject = new URL(urlString);
        const urlParams = new URLSearchParams(urlObject.search);
        const destination = getStartDestinationObject(
          urlParams.get("destination")
        );
        const start = getStartDestinationObject(urlParams.get("start"));

        if (start) {
          if (destination) {
            console.log(typeof destination);
            props.setCurrentFeature(destination);
          }
          props.setStart(start);

          props.history.push(
            `/${pageNames.map}/${pageNames.route}/${getQueryParams()}`
          );
          return;
        }

        if (destination) {
          props.setCurrentFeature(destination);
          props.history.push(
            `/${pageNames.map}/${
              pageNames.info
            }/${getQueryParams()}&destination=${destination.id}`
          );
        }
      };

      if (CONTENT.length) {
        run();
      } else {
        const intervalId = setInterval(() => {
          if (CONTENT.length && introCompleteRef.current) {
            window.clearInterval(intervalId);
            run();
          }
        }, 200);
      }
    };
  };

  useEffect(handleCalledURLInNativeApp, [props.isApp]);
  useEffect(() => {
    console.log("HELLO", props.isApp);

    if (!props.isApp) return;
    standardIPhoneFunctionCallDeleteBadge();
    // standardIPhoneFunctionCall("badges?anzahl=0");
    callAndroidFunction("badges?anzahl=0");
  }, [props.isApp]);

  return null;
}

// das ist nur für iOS, da man da keine Objekte aufrufen kann!
let currentCalendarEvent = null;

window.getAppointment = () => {
  return JSON.stringify(currentCalendarEvent);
};

export function vibrateDevice() {
  if (!isAndroid && !isIOS) return;
  // iOS Interface
  try {
    let msges = ["vibrate"];
    if (window.webkit != null) {
      msges.forEach((msg) => {
        window.webkit.messageHandlers.callbackHandler.postMessage(msg);
      });
    }
  } catch (e) {
    console.error(e);
  }

  if (window.CUAndroid?.vibrate) {
    window.CUAndroid.vibrate();
  }
}
export function nativeSaveToCalendar(calendarEvent) {
  // iOS type beat
  currentCalendarEvent = calendarEvent;
  // Android Interface
  if (window.CUAndroid?.saveToCalendar) {
    window.CUAndroid.saveToCalendar(JSON.stringify(calendarEvent));
  }

  // iOS Interface
  try {
    let msges = ["saveToCalendar"];
    if (window.webkit != null) {
      msges.forEach((msg) => {
        window.webkit.messageHandlers.callbackHandler.postMessage(msg);
      });
    }
  } catch (e) {
    console.error(e);
  }
}

export const checkIsDataScannerAvailable = () => {
  if (isIOS) {
    callIPhoneFunctions(["isDataScannerAvailable"]);
  }

  if (isAndroid) {
    callAndroidFunction("isDataScannerAvailable");
  }
};

export const cancelDataScanner = () => {
  if (isIOS) {
    // const tag = document.createElement("div");
    // tag.className = "debug-ios-modal";
    // tag.style.position = "fixed";
    // tag.style.top = "50px";
    // tag.style.zIndex = 999999;
    // tag.style.left = "50px";
    // tag.style.background = "red";
    // tag.innerText = `run false`;
    // document.body.appendChild(tag);
    // setTimeout(() => {
    //   tag.remove();
    // }, 4000);

    callIPhoneFunctions([`runDataScanner?run=false&regex=""`]);
  }

  if (isAndroid) {
    const regex = "";
    const run = false;
    callAndroidFunction(`runDataScanner`, [run, regex, 0]);
  }
};

export const startDataScanner = (regex, orgHeight) => {
  // isDebugMode muss on sein, dann einblenden.

  if (isIOS) {
    // const tag = document.createElement("div");
    // tag.className = "debug-ios-modal";
    // tag.style.position = "fixed";
    // tag.style.top = "50px";
    // tag.style.zIndex = 999999;
    // tag.style.left = "50px";
    // tag.style.background = "red";
    // tag.innerText = `height: ${window.innerHeight}`;
    // document.body.appendChild(tag);
    // setTimeout(() => {
    //   tag.remove();
    // }, 4000);

    callIPhoneFunctions([
      `runDataScanner?run=true&regex=${regex}&height=${
        orgHeight ?? window.innerHeight - 35
      }`,
    ]);
  }

  if (isAndroid) {
    const height = orgHeight ?? window.innerHeight - 35;
    const run = true;
    callAndroidFunction(`runDataScanner`, [run, regex, height]);
  }
};

/**
 *
 * @param {[String]} msges
 */
export const standardIPhoneFunctionCall = (msges = []) => {
  try {
    if (window.webkit != null) {
      msges.forEach((msg) => {
        window.webkit.messageHandlers.callbackHandler.postMessage(msg);
      });
    }
  } catch (e) {
    console.error(e);
  }
};

export const standardIPhoneFunctionCallDeleteBadge = () => {
  const message = "badges?anzahl=0";

  try {
    // eslint-disable-next-line no-undef
    webkit.messageHandlers.callbackHandler.postMessage(message);
  } catch (e) {
    console.error(e);
  }
};

export function openNativeShareDialog(url) {
  if (isAndroid) {
    try {
      if (window.CUAndroid) {
        window.CUAndroid.openShareDialog(url);
      }
    } catch (err) {
      // todo: error noch an error klasse loggen
    }
  }
}

/**
 * @param{"en"|"de"} lang
 */
export function setAndroidSpeakingLanguage(lang) {
  if (isAndroid) {
    try {
      if (window.CUAndroid) {
        window.CUAndroid.setLanguage(lang);
      }
    } catch (err) {
      console.error(err);
    }
  }
}

export function setNativeSpeechLanguage(language) {
  if (isAndroid) {
    try {
      if (window.CUAndroid) {
        window.CUAndroid.setLanguage(language);
      }
    } catch (err) {
      // todo: error noch an error klasse loggen
    }
  }
}

export function setNativeStartSpeak(text) {
  if (isAndroid) {
    try {
      if (window.CUAndroid) {
        window.CUAndroid.startSpeak(text);
      }
    } catch (err) {
      // todo: error noch an error klasse loggen
    }
  }
  if (isIOS) {
    callIPhoneFunctions([`startSpeak?text=${text}`]);
  }
}

export function setNativeStopSpeak() {
  if (isAndroid) {
    try {
      if (window.CUAndroid) {
        window.CUAndroid.stopSpeak();
      }
    } catch (err) {
      // todo: error noch an error klasse loggen
    }
  }
  if (isIOS) {
    try {
      callIPhoneFunctions(["stopSpeak"]);
    } catch (e) {}
  }
}

export function openURL(url) {
  callAndroidFunction("openInBrowser", [url]);
  callIPhoneFunctions([`openInBrowser?url=${url}`]);
}

/**
 * Die Funktion gibt dem App Interface Bescheid, wenn der Loading Screen (Native seitig) verschwinden soll
 */
export function hideNativeLoadingScreen() {
  // Interface Setup für Android
  if (window.CUAndroid?.hideLoadingScreen) {
    window.CUAndroid.hideLoadingScreen();
  }
  // Interface Setup für iOS
  // Trigger Datenübergabe
  try {
    let msges = ["hideLoadingScreen"];

    msges.forEach((msg) => {
      window.webkit.messageHandlers.callbackHandler.postMessage(msg);
    });
  } catch (e) {}
}

export function setProduktinstanzModuleToNative() {
  if (window.CUAndroid?.setProduktinstanzModule) {
    window.CUAndroid.setProduktinstanzModule(JSON.stringify(MODULES));
  }

  try {
    window.webkit.messageHandlers.callbackHandler.postMessage(`
       setProduktinstanzModule?modules=${JSON.stringify(MODULES)}`);
  } catch (e) {}
}

export function runCode(functionName) {
  // Interface Setup für Android
  if (window.CUAndroid) {
    const text = "window.CUAndroid." + functionName + "()";
    eval(text);
  }
  // Interface Setup für iOS
  // Trigger Datenübergabe
  try {
    let msges = [functionName];

    msges.forEach((msg) => {
      window.webkit.messageHandlers.callbackHandler.postMessage(msg);
    });
  } catch (e) {}
}

/**
 * @param {string[]} msges
 */
export function callIPhoneFunctions(msges = []) {
  // Interface Setup für iOS
  if (!isIOS) return;
  try {
    msges.forEach((msg) => {
      IOS_ACTIONS.push(msg);
      window?.webkit?.messageHandlers?.callbackHandler?.postMessage(msg);
    });
  } catch (e) {
    console.error(e);
  }
}

export function callAndroidFunction(functionName, parameters = []) {
  try {
    if (window.CUAndroid != null && window.CUAndroid[functionName]) {
      window.CUAndroid[functionName](...parameters);
    }
  } catch (err) {
    console.error(err);
  }
}

// Redux zum holen der States
const mapStateToProps = (state) => {
  return {
    cameraPermissionAllowed: state.main.cameraPermissionAllowed,
    isApp: state.main.isApp,
    mapLoaded: state.map.mapLoaded,
    serverDataLoaded: state.main.serverDataLoaded,
    locationType: state.route.locationType,
    userAllowedGPS: state.settings.location.userAllowed,
    locationPermissionAllowed: state.settings.location.permissionAllowed,
    locationSettings: state.settings.location,
    userAllowedPushMessages: state.settings.pushMessages.userAllowed,
    pushPermissionAllowed: state.settings.pushMessages.permissionAllowed,
    deviceInformation: state.main.deviceInformation,
    introComplete: state.main.introComplete,
    activeStatus: state.route.activeStatus,
    developerMode: state.settings.developerMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCameraPermissionAllowed: (value) =>
      dispatch(actionMain.setCameraPermissionAllowed(value)),
    setDataScannerAvailable: (value) =>
      dispatch(actionMain.setDataScannerAvailable(value)),
    setCurrentFloorNumber: (index) =>
      dispatch(actionFloor.setCurrentFloorNumber(index)),
    setBLEData: (data) => dispatch(actionNavigation.setBLEData(data)),
    setGPSData: (data) => dispatch(actionNavigation.setGPSData(data)),
    setHeading: (heading) => dispatch(actionNavigation.setHeading(heading)),
    setMotionActivityStatus: (status) =>
      dispatch(actionNavigation.setMotionActivityStatus(status)),
    setDebug: (object) => dispatch(actionDebug.setDebuggingValues(object)),
    setHasLatestLocation: (value) =>
      dispatch(actionNavigation.setHasLatestLocation(value)),
    setProperties: (object) => dispatch(setProperties(object)),
    setLocationPermissionAllowed: (value) =>
      dispatch(setProperties({ location: { permissionAllowed: value } })),
    setNotificationPostId: (id) => dispatch(setNotificationPostId(id)),
    setSettingProperties: (value) =>
      dispatch(actionSetting.setProperties(value)),
    setDeviceInformation: (data) =>
      dispatch(actionMain.setDeviceInformation(data)),
    setCurrentFeature: (feature) =>
      dispatch(actionMain.setCurrentFeature(feature)),
    setStart: (id) => dispatch(actionRoute.setStart(id)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NativeAppInterface)
);

function getStartDestinationObject(key) {
  if (!key) return null;
  const split = key.split(",");

  if (split.length === 1) {
    let poi = POIS.all.find((p) => p.uuid === key);
    if (!poi) poi = POIS.all_objects[+split[0]];
    if (!poi) return null;

    return new StartDestination({
      nodeId: poi.getRouteNodeId(),
      poiId: poi.id,
      isSet: true,
      lat: poi.getFeatureCenter()[1],
      lng: poi.getFeatureCenter()[0],
      level: poi.level,
      indoors: poi.indoors,
      name: poi.name,
      campusId: poi.campusId,
      address: poi.address,
      type: poi.type,
    });
  } else if (split.length === 3) {
    return new StartDestination({
      nodeId: null,
      poiId: null,
      isSet: true,
      lat: split[0],
      lng: split[1],
      level: split[2],
      name: "Start",
      campusId: null,
      address: null,
    });
  }
}
