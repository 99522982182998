import { FormattedMessage, useIntl } from "react-intl";
import classes from "./legChange.module.scss";
import RateNavigation from "./rateNavigation/RateNavigation.component";
import StandardHeadline from "../../../elements/text/standardHeadline.text.jsx";
import StandardButton from "../../../elements/buttons/standard.button.jsx";
import StandardText from "../../../elements/text/standardText.text.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Result } from "@catchupapplications/indoor-navigation/dist/types.js";
import { useEffect } from "react";

/**
 *
 * @param isComplete
 * @param image
 * @param title
 * @param onNextClick
 * @param closeNavigation
 * @param showContinueButton
 * @param userWarning
 * @param onlyUserWarning
 * @param {Result} liveNavigationInformation
 * @return {JSX.Element}
 * @constructor
 */
export default function LegChangeInfo({
  setProhibitSpeak,
  isComplete,
  image,
  title,
  onNextClick,
  closeNavigation,
  showContinueButton = true,
  userWarning = "",
  onlyUserWarning = false,
}) {
  useEffect(() => {
    setProhibitSpeak(true);
    return () => {
      setProhibitSpeak(false);
    };
  }, []);

  const intl = useIntl();

  if (isComplete) {
    return (
      <div
        className={`position-relative pointer-event-on w-100 h-100 ca-bg-white d-flex flex-column`}
      >
        <RateNavigation />
      </div>
    );
  }

  return (
    <div
      className={`position-relative h-100 w-100 pb-3 overflow-auto ca-bg-white d-flex flex-column align-items-center justify-content-center`}
    >
      {!showContinueButton && (
        <StandardButton
          icon={["fal", "times"]}
          buttonClasses={`ml-auto mt-3 mb-3 mr-3 normalButton--quit ${classes.quitNavigation}`}
          handleClick={closeNavigation}
        >
          {intl.formatMessage({ id: "navigation.finishIt" })}
        </StandardButton>
      )}
      <div className={"w-100 d-flex justify-content-center"}>{image}</div>
      {userWarning && (
        <StandardHeadline
          type={"h4"}
          className={
            "standardHeadline--warning text-center m-3 pulse pulse--delay"
          }
        >
          {userWarning}
        </StandardHeadline>
      )}
      {!onlyUserWarning && (
        <StandardHeadline className={`${classes.title} m-3 text-center`}>
          {title}
        </StandardHeadline>
      )}
      {!showContinueButton && (
        <div className={"d-flex align-items-center pr-3 pl-3"}>
          <FontAwesomeIcon icon={"spinner"} spin className={"mr-3"} />
          <StandardText
            className={"text-center"}
            text={intl.formatMessage({
              id: "navigation.screenDisappearsFloor",
            })}
          />
        </div>
      )}
      {showContinueButton && (
        <StandardButton
          buttonClasses={`normalButton--primary align-self-center pointer-event-on`}
          handleClick={onNextClick}
        >
          <FormattedMessage id="navigation.legChange.next" />
        </StandardButton>
      )}
    </div>
  );
}
