import { FormattedMessage } from "react-intl";
import React, { useEffect, useState } from "react";
import { MISC_INFO } from "../../../reduxStore/actions/main";
import { isMobile } from "react-device-detect";
import StandardCard from "./StandardCard.card.jsx";
import QRCodeGenerator from "qrcode";
import StandardHeadline from "../text/standardHeadline.text.jsx";
import {
  referrerTypes,
  urlParameters,
} from "../../../app/app.sideEffects/useURLParams.sideEffects.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function GeneratedQRCode({
  href,
  headline = <FormattedMessage id={"qrCode.scanToGetLinkHeadline"} />,
  text = <FormattedMessage id={"qrCode.scanToGetLinkText"} />,
  className = "",
  inCard = true,
  isTerminal = false,
}) {
  const [qrCodeDataURL, setQrCodeDataURL] = useState(null);
  const getURLWithCustomerDomain = (url) => {
    // rauslesen von https://subdomain
    const regex = /^[^.]+\./g;
    let newURL = url;
    if (MISC_INFO.subDomain) {
      newURL = url.replace(regex, "https://" + MISC_INFO.subDomain + ".");
    }
    return newURL;
  };
  /**
   * Filtert unnötige parameter aus der URL raus, die sonst zum Terminal führen würden!
   */
  const cleanURL = (url, qrCodeReferrer = true) => {
    const expression = `(&|\\?)(${urlParameters.isTerminal}|${urlParameters.dualScreenPortion}|${urlParameters.terminalBearing}|${urlParameters.defaultStartNode}|${urlParameters.marginTop}|${urlParameters.marginBottom}|${urlParameters.mainScreenBottomMargin}|${urlParameters.dualScreenBottomMargin}|${urlParameters.dualScreenTopMargin})=.*?(?=&|$)`;
    const regex = new RegExp(expression, "g");
    let customerDomainURL = getURLWithCustomerDomain(url.replace(regex, ""));

    if (qrCodeReferrer) {
      customerDomainURL =
        customerDomainURL +
        (isTerminal
          ? `referrer=${referrerTypes.terminaShareUrl}`
          : `referrer=${referrerTypes.shareQR}`);
    }
    return customerDomainURL;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!href) {
        href = window.location.href;
      }

      QRCodeGenerator.toDataURL(
        !isMobile ? cleanURL(window.location.href) : "12",
        {
          errorCorrectionLevel: "L",
        }
      ).then((response) => {
        setQrCodeDataURL(response);
      });
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  }, [href, window.location.href]);

  const qrCodeBox = (
    <div className={"d-flex flex-column align-items-center w-100"}>
      <div>
        <StandardHeadline text={headline} type={"h3"} />
        {/* <StandardText text={text} type={"p"} /> */}
      </div>
      <div className="d-flex align-items-center justify-content-center w-100">
        <FontAwesomeIcon
          icon={["fal", "mobile"]}
          className="mr-3"
          style={{ width: "3rem", height: "3rem" }}
        />
        <img
          style={{ width: "50%", maxWidth: "8rem" }}
          className={"mr-3"}
          src={qrCodeDataURL}
        />
      </div>
    </div>
  );
  if (!isMobile && inCard) {
    return <StandardCard className={className}>{qrCodeBox}</StandardCard>;
  } else if (!isMobile) {
    return qrCodeBox;
  } else return null;
}
