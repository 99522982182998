import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Modal } from "react-bootstrap";
import StandardCard from "../../elements/cards/StandardCard.card.jsx";
import StandardButton from "../../elements/buttons/standard.button.jsx";
import StandardList from "../../elements/wrapper/standardList.wrapper.jsx";
import StandardHeadline from "../../elements/text/standardHeadline.text.jsx";

/**
 *
 * @param lastUsed
 * @param pois
 * @param resetLastUsed
 * @param {String[]} selectedCampuses
 * @param searchItemClicked
 * @return {JSX.Element|null}
 * @constructor
 */
export default function LastUsed({
  lastUsed,
  pois,
  resetLastUsed,
  selectedCampuses,
  searchItemClicked,
}) {
  const [show, setShow] = useState(false);
  const intl = useIntl();
  const handleClose = (shoutDelete = false) => {
    setShow(false);
    if (shoutDelete) resetLastUsed();
  };

  const filteredPOIs = lastUsed.filter((lastUsedId) => {
    if (!selectedCampuses.length) return true;
    const poi = pois.find((p) => p.id === +lastUsedId);
    if (poi) {
      return selectedCampuses.includes(poi.campusId);
    } else return false;
  });

  if (!filteredPOIs.length) return null;

  return (
    <>
      <StandardList
        heading={<FormattedMessage id="search.lastUsed" />}
        headingType={"h4"}
        headingContainerClassName={"pr-3 pl-3"}
        listButton={
          <StandardButton
            buttonClasses={"normalButton--delete normalButton--delete__search"}
            handleClick={() => setShow(true)}
          >
            <FormattedMessage id="search.delete" />
          </StandardButton>
        }
      >
        <div className="d-flex overflow-auto pr-3 pl-3 no-scrollbar">
          {filteredPOIs.map((lastUsedId) => {
            const poi = pois.find((p) => p.id === +lastUsedId);
            if (!poi) return null;

            return (
              <StandardCard
                ariaLabel={intl.formatMessage(
                  {
                    id: "search.screenreader.poiCard",
                  },
                  {
                    poiName: poi.name,
                    poiAddress: poi.address,
                    category: intl.formatMessage({ id: "search.lastUsed" }),
                  }
                )}
                onClick={() => searchItemClicked(poi.id)}
                key={lastUsedId}
                role="button"
                className={"col-auto standardCard__search--lastUsed mr-3"}
                heading={poi.name}
                headingType={"h3"}
              >
                <span className="overflow-auto">{poi.address}</span>
              </StandardCard>
            );
          })}
        </div>
      </StandardList>

      <Modal show={show} backdrop="static" centered onHide={handleClose}>
        <StandardCard>
          <Modal.Header closeButton>
            <StandardHeadline
              text={<FormattedMessage id="search.dialogDeleteTitle" />}
            />
          </Modal.Header>
          <Modal.Body>
            <FormattedMessage id="search.dialogDeleteText" />
          </Modal.Body>
          <Modal.Footer>
            <StandardButton
              buttonClasses={"normalButton--cancel"}
              handleClick={() => handleClose(false)}
            >
              <FormattedMessage id="search.dialogDeleteNo" />
            </StandardButton>
            <StandardButton
              buttonClasses={"normalButton--delete"}
              handleClick={() => handleClose(true)}
            >
              <FormattedMessage id="search.dialogDeleteYes" />
            </StandardButton>
          </Modal.Footer>
        </StandardCard>
      </Modal>
    </>
  );
}
