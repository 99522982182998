import React from "react";
import style from "./skeleton.module.scss";

export function SkeletonPost({ outerClassName, length = 3, linesLength = 3 }) {
  const getLines = () => {
    const output = [];
    for (let i = 0; i < linesLength; i++) {
      output.push(
        <div
          key={i}
          className={`m-2 bg-light ${style.video}`}
          style={{ height: "1.4rem", width: "15rem" }}
        />
      );
    }
    output.push(
      <div
        key={linesLength}
        className={`m-2 bg-light ${style.video}`}
        style={{ height: "1.4rem", width: "10rem" }}
      />
    );

    return output;
  };
  const getSkeletons = () => {
    const output = [];
    for (let i = 0; i < length; i++) {
      output.push(
        <div key={i} className={` ${style.video} `}>
          <div
            className={`m-2 bg-light ${style.video}`}
            style={{ height: "11rem", width: "15rem" }}
          />
          {getLines()}
        </div>
      );
    }
    return output;
  };
  return (
    <div
      className={"d-flex flex-wrap justify-content-center " + outerClassName}
    >
      {getSkeletons()}
    </div>
  );
}
