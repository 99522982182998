import { EXPRESSIONS } from "../../variables/expressions.js";
import { layerIds } from "../../variables/layerIds.js";
import { setLayerFilters, zoomSymbolsEnd } from "../filter.map.js";

export function filterRoomTexts(allFilters = [], options = {}) {
  allFilters.push([
    "all",
    EXPRESSIONS.type.room,
    [">", ["zoom"], zoomSymbolsEnd],
  ]);
  setLayerFilters([layerIds.texts.rooms], allFilters);
}
