import {
  CAMPUS_COLLECTION,
  LOCATION_MANAGER,
  resetLocationManager,
} from "../actions/main.js";
import {
  navigationController,
  setNavigationController,
} from "../../components/mapUi/navigation/navigation.component.jsx";
import { isAndroid } from "react-device-detect";
import { logNavigationData } from "../../components/elements/positionRecorder/positionRecorder.jsx";
import { booleanPointInPolygon, point } from "@turf/turf";
import { setFeatures } from "../../components/mapUi/mapbox/functions/drawer.map.js";
import { sourceIds } from "../../components/mapUi/mapbox/variables/sourceIds.js";
import {
  isInDevelopmentMode,
  truncTo,
} from "../../functions/helper/helpers.functions.js";
import UserLocation from "../../functions/classes/location.class.js";
import { smoothNavigationManager } from "../../components/mapUi/navigation/sideEffects/useLiveNavigation.sideEffect.js";
import NavigationController from "@catchupapplications/indoor-navigation/dist/models/NavigationController";
import { statisticLogger } from "../../components/statisticLogger/StatisticLogger.container.js";

// Wenn der LocationManage Mehrmals keine gescheite
// Position auspuckt wird er resettet
let locationManagerErrors = 0;
let navigationControllerErrors = 0;

export default function getLocationData(action, state) {
  const positionCalculationStartTime = +new Date();
  /** @type RawLocation */
  let { rawLocation, position } = action.payload;
  // Positionsrecorder - Mutiert die Postion nicht, sondern nimmt sie nur auf
  if (state.positionRecording) logNavigationData(rawLocation);

  if (!position) {
    if (
      LOCATION_MANAGER &&
      state.liveNavigationInformation &&
      !state.liveNavigationInformation.shouldReroute &&
      !state.liveNavigationInformation.notification
    ) {
      return {
        ...state,
        liveNavigationInformation: {
          ...state.liveNavigationInformation,
          shouldReroute: LOCATION_MANAGER.transmittedDataNotMatching(
            state.liveNavigationInformation.isIndoor
          ),
        },
      };
    }

    return state;
  }

  // der shit hier muss sein,
  // da ich sonst auf dem position objekt vom Location_manager arbeite und der dann abspackt
  position = { ...position };

  /**
   * Wichtig für die LiveNavigation. Geben Leg/Step Idexe und neu berechnete lng/lat, welche
   * an die Route gesnapped sind!
   */
  const liveNavigationInformation = getLiveNavigationInformation(
    position,
    state
  );
  let updateOnlyLiveInformation = false;

  if (liveNavigationInformation) {
    if (
      !liveNavigationInformation.shouldReroute &&
      !liveNavigationInformation.notification
    ) {
      liveNavigationInformation.shouldReroute =
        LOCATION_MANAGER.transmittedDataNotMatching(
          liveNavigationInformation.isIndoor
        );
    }

    // Während die LiveNavigation richtige Informationen liefert darf hier
    // das state "locationObject" nicht upgedated werden, weil dieses Location Update
    // sonst mit dem Location Update des SmoothNavigation Managers konkurriert
    // und dadurch hin- und her springen würde (real location <-> snapped location)
    updateOnlyLiveInformation =
      state.navigationMode === "live" &&
      liveNavigationInformation &&
      !liveNavigationInformation.shouldReroute &&
      liveNavigationInformation.locationIsSnapped;
  }

  position = relevantPositionAdjustments(
    position,
    state,
    liveNavigationInformation,
    updateOnlyLiveInformation
  );
  const newLocationObject = new UserLocation(
    rawLocation.type,
    position.lat,
    position.lng,
    {
      accuracy: position.accuracy,
      heading: state.locationObject.heading,
      timestamp: position.timestamp,
      rawLocation: rawLocation,
      zIndex: position.zIndex,
      speed: position.speed,
      positionType: position.type,
      // während SBS darf der Punkt nicht angezeigt werden, da er nur störend ist
      isSet: state.navigationMode !== "sbs",
    }
  );
  // log berechnete position
  if (statisticLogger && CAMPUS_COLLECTION) {
    const positionCalculationEndTime = +new Date();

    const currentCampus = CAMPUS_COLLECTION.getCurrentCampus();

    if (currentCampus) {
      const campusPolygon = currentCampus.polygon;

      if (campusPolygon) {
        const locationInCampus = booleanPointInPolygon(
          [newLocationObject.lng, newLocationObject.lat],
          campusPolygon
        );

        // Wegen Datenschutz NUR die daten loggen, die auf dem Campus liegen
        if (locationInCampus) {
          statisticLogger.addLog({
            action: {
              id: "calculatedUserPosition",
              name: "Calculated User Position",
              type: "input",
              group: "Map",
              movement: "stay",
              content: {
                location: newLocationObject.toJson(),
                duration:
                  positionCalculationEndTime - positionCalculationStartTime,
              },
            },
          });
        }
      }
    }
  }
  updateSmoothNavigationManager(liveNavigationInformation, newLocationObject);

  if (updateOnlyLiveInformation) {
    // das locationObject wird im LiveMode von einer anderen Action/Reducer erstellt!
    return {
      ...state,
      liveNavigationInformation: {
        ...state.liveNavigationInformation,
        ...liveNavigationInformation,
      },
    };
  } else {
    return {
      ...state,
      locationObject: newLocationObject,
      liveNavigationInformation: {
        ...state.liveNavigationInformation,
        ...liveNavigationInformation,
      },
    };
  }
}
/**
 *
 * @param {Position} position
 * @param state
 * @return {Result | null}
 */
const getLiveNavigationInformation = (position, state) => {
  const realPoint = point([position.lng, position.lat], {
    color: "#bd1515",
    text: "R",
  });
  /**
   * @type Result
   */
  let liveNavigationInformation;
  if (
    navigationController == null
    // wenn reroutet werden muss, dann sollte solange nicht zur map gesnapped werden und keine neuen Steps oder so
    // gesettet werden
  )
    return null;

  const mode = navigationController?.getRouteProgress()
    ? navigationController.getRouteProgress().currentLegProgress.leg.steps[0]
        .mode
    : "walking";
  let params;

  try {
    params = LOCATION_MANAGER.getParamsForRouteCalculation(mode);
  } catch (e) {
    window.onerror(
      "fehler in file 'setLocationData.functions.js' bei Funktion LOCATION_MANAGER.getParamsForRouteCalculation. " +
        JSON.stringify(e)
    );
  }
  try {
    navigationController.setParamsForRouteCalculation(params);
  } catch (e) {
    window.onerror(
      "Fehler in file 'setLocationData.functions.js' bei Funktion navigationController.setParamsForRouteCalculation. " +
        JSON.stringify(e)
    );
  }

  try {
    liveNavigationInformation = navigationController.getNavigationInformation(
      position.lat,
      position.lng,
      state.locationObject.heading,
      position.zIndex,
      position.isIndoor,
      position.accuracy,
      position.speed,
      LOCATION_MANAGER
    );
    navigationControllerErrors = 0;
  } catch (e) {
    navigationControllerErrors += 1;
    window.onerror(
      "Fehler in file 'setLocationData.functions.js' bei Funktion navigationController.getNavigationInformation. Position: " +
        JSON.stringify(position) +
        "Error: " +
        JSON.stringify(e)
    );
    if (navigationControllerErrors > 2) {
      window.onerror("NavigationController wurde neugesetzt");
      setNavigationController(
        //todo: das hier noch dynamic machen
        new NavigationController(state.currentRoute, "walking", "bluetooth")
      );
    }
  }
  if (!liveNavigationInformation) {
    console.warn(
      "es konnte keine liveNavigationInformation ausgerechnet werden"
    );
    return null;
  }
  //liveNavigationInformation.isIndoor = position.isIndoor;
  let snapCoodrinates = [0, 0];
  if (liveNavigationInformation.lng && liveNavigationInformation.lat) {
    snapCoodrinates = [
      liveNavigationInformation.lng,
      liveNavigationInformation.lat,
    ];
  }
  // --- debugging---
  setFeatures({
    sourceId: sourceIds.debug_real_position,
    features: [
      {
        type: "Feature",
        properties: { text: "R", color: "#f65e5e", radius: 13 },
        geometry: {
          coordinates: snapCoodrinates,
          type: "Point",
        },
      },
      realPoint,
    ],
  });
  return liveNavigationInformation;
};

/**
 * Setzt die Daten für mapbox um leuchtende Beacons anzuzeigen
 * @param {*} rawData
 */
export const setDebugBeaconPulses = (rawLocation) => {
  if (!isInDevelopmentMode) return;

  let beacons;
  if (CAMPUS_COLLECTION) {
    beacons = CAMPUS_COLLECTION.getCurrentCampus().beacons;
  } else return null;

  // TODO: muss NUR bei campuswechsel neu erstellt werden, NICHT bei jeder neuen position
  const beaconKeys = beacons.map((b) => `${b.uuid}-${b.major}-${b.minor}`);

  const calculateDistance = (
    rssi,
    calibratedRssi = -59,
    pathLossParameter = 1.7
  ) => {
    if (rssi >= 0) {
      console.error("rssi ungültig");
      return 100000;
    }

    return Math.pow(10, (calibratedRssi - rssi) / (10 * pathLossParameter));
  };
  const rawData = rawLocation.data
    .filter((t) => {
      // TODO: WICHTIG GPS WIRD RAUSGEFILTERT, sollte aber eigentlich drin bleiben!!
      if (t.lng) return false;

      if (t.uuid == null || t.uuid === "") {
        // TODO: das ist nur für UMG, aber das
        t.uuid = "4152554E-F99B-4A3B-86D0-947070693A78";
      }

      const key = `${t.uuid}-${t.major}-${t.minor}`;

      return beaconKeys.includes(key);
    })
    .map((t) => {
      if (t.lng) return t;

      const key = `${t.uuid}-${t.major}-${t.minor}`;
      const currentBeacon = beacons.find(
        (b) => `${b.uuid}-${b.major}-${b.minor}` === key
      );
      if (!currentBeacon) return t;

      return {
        ...t,
        dist: truncTo(
          calculateDistance(t.rssi, -59, currentBeacon.suppressed ? 2.4 : 1.7),
          0
        ),
        lat: currentBeacon.latitude,
        lng: currentBeacon.longitude,
        identifier: `${currentBeacon.uuid}-${currentBeacon.major}-${currentBeacon.minor}`,
        name: currentBeacon.minor,
        suppressed: currentBeacon.suppressed,
      };
    });

  const kalmanFeatures = LOCATION_MANAGER.getCurrentBeacon();
  kalmanFeatures.forEach((f) => {
    const distance = f.properties?.distance;
    if (distance != null) {
      f.properties.distance = truncTo(distance, 0);
    }
  });

  setFeatures({
    sourceId: sourceIds.debug_beacon_positions_raw,
    features: rawData.map((t) => {
      const p = point([t.lng, t.lat]);

      return {
        ...p,
        properties: {
          ...p.properties,
          distance: t.dist,
          identifier: t.identifier,
          name: t.name,
          isVisible: true,
          visibleStatus: "now",
          type: "here",
        },
      };
    }),
  });

  setFeatures({
    sourceId: sourceIds.debug_beacon_positions,
    features: kalmanFeatures,
  });
};

/**
 *
 * @param {RawLocation} rawLocation
 * @param state
 * @return Position
 */
const getPosition = (rawLocation, state) => {
  let position;

  setDebugBeaconPulses(rawLocation);

  try {
    if (LOCATION_MANAGER != null) {
      if (isInDevelopmentMode()) {
        // das wird nur verwendet, weil die beacons sonst immer veraltet sind
        // aber beim abspielen, sollen sie immer den aktuellen timestamp haben
        rawLocation.data.forEach((d) => {
          if (d.major && d.minor) {
            d.timestamp = +new Date();
          }
        });
      }
      // auch hier kann position leer bleiben
      position = LOCATION_MANAGER.getCurrentLocation(
        rawLocation.data,
        state.activeStatus,
        navigationController?.getRouteProgress(),
        isAndroid ? 12000 : 0
      );

      if (position) {
        position.type = LOCATION_MANAGER.positionType;
      }
    }
    locationManagerErrors = 0;
  } catch (e) {
    console.error(e.message);
    locationManagerErrors += 1;
    window.onerror(
      "position konnte nicht berechnet werden. rawLocation: " +
        "Error: " +
        e?.message +
        "Rawloction"
    );
    if (locationManagerErrors > 1) {
      // wenn Fehler geworfen werden soll der LocationManager einfach neu erstellt werden
      // dadurch wird er vorerst vielleicht kurz mal falsch orten, aber kriegt sich dann wieder ein und die
      // App muss nicht neugestartet werden!

      resetLocationManager();
      locationManagerErrors = 0;
    }
  }

  return position;
};
/**
 *
 * @param {Position} position
 * @param {{}} state
 * @param {Result} liveNavigationInformation
 * @param {boolean} setOnlyLiveNavigation
 * @return Position
 */
const relevantPositionAdjustments = (
  position,
  state,
  liveNavigationInformation,
  setOnlyLiveNavigation
) => {
  // für die Anzeige in der App wird nicht Level
  // sondern der tatsächliche Array Index des levels verwendet
  position.zIndex =
    position.zIndex != null
      ? CAMPUS_COLLECTION.getIndexFromLevel(position.zIndex)
      : state.locationObject.zIndex;

  if (position.lat != null && position.lng != null) {
    position.lat = truncTo(position.lat, 6);
    position.lng = truncTo(position.lng, 6);
  }
  if (position.accuracy != null) {
    position.accuracy = Math.round(position.accuracy);
  }

  // wenn nur die LiveNavigation wichtig ist, muss die echte Position auf diese
  // (gesnappte) Position angepasst werden
  if (setOnlyLiveNavigation) {
    position.lng = liveNavigationInformation.lng;
    position.lat = liveNavigationInformation.lat;
  }

  return position;
};
/**
 * gib die informationen an den simulierten State weiter!
 * es passiert hier folgendes:
 * an dieser Stelle bekommt der Manager, der für den simulierten Punkt zuständig ist, alle Daten zur User Location.
 * das State bekommt hier noch kein Update! Stattdessen errechnet der Manager eine geführte Location für den User Punkt
 * und updated sie selbstständig in einem anderen reducer (aber das gleiche locationObject)
 * @param {Result} liveNavigationInformation
 * @param {UserLocation} newLocationObject
 */
const updateSmoothNavigationManager = (
  liveNavigationInformation,
  newLocationObject
) => {
  if (
    smoothNavigationManager &&
    liveNavigationInformation &&
    !liveNavigationInformation.shouldReroute
  ) {
    smoothNavigationManager.setActualLocation(newLocationObject);
  }
};
