import StandardSubline from "../../../elements/text/standardSubline.text.jsx";
import React from "react";

/**
 *
 * @param {Date} arrivalDate
 * @return {JSX.Element}
 * @constructor
 */
export function ArrivalTime({ arrivalDate, id }) {
  let hours =
    arrivalDate.getHours() < 10
      ? "0" + arrivalDate.getHours()
      : arrivalDate.getHours();
  let minutes =
    arrivalDate.getMinutes() < 10
      ? "0" + arrivalDate.getMinutes()
      : arrivalDate.getMinutes();

  return (
    <StandardSubline id={id} className={"text-muted flex-shrink-0"}>
      {hours}:{minutes}
    </StandardSubline>
  );
}
