import { useEffect, useState } from "react";

/**
 * Wird dazu benutzt um während der Kommunikation zwischen Javascript und Native bei einem Wartevorgang
 * zu melden, wann etwas disabled ist oder wann verweigert wurde. Wichtig zu wissen: die Permission muss bevor
 * Sie genehmigt oder abgelehnt wird immer null sein! Bei true/false weiß man, dass der User die Entscheidung gegeben hat.
 * @param userAllowed
 * @param permissionGranted
 * @return {{waiting: boolean, denial: boolean}}
 */
export function useNativePermissionCheck(
  userAllowed,
  permissionGranted,
  isApp
) {
  const [waiting, setWaiting] = useState(false);
  const [denial, setDenial] = useState(false);

  useEffect(() => {
    if(isApp) return;
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setWaiting(false);
        setDenial(false);
      },
      () => {
        setDenial(true);
        setWaiting(false);
      }
    );
  }, []);

  useEffect(() => {
    if (!isApp) return;

    setWaiting(userAllowed && permissionGranted === null);
    setDenial(permissionGranted === false);
  }, [userAllowed, permissionGranted, isApp]);

  return { waiting, denial };
}
