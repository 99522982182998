import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as mainAction from "../../../reduxStore/actions/main.js";
import { AppOverlayIFrame } from "../../../functions/classes/miscelleanous.classes.js";

/**
 * Dieser Wrapper Component wird für alle Links verwendet. Er ist auch in der Lage einen iFrame zu spawnen (und sollte dafür auch verwendet werden)!
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
function CustomHref(props) {
  const openIFrame = () => {
    props.setCurrentAppOverlay(
      new AppOverlayIFrame(props.websiteTitle, props.href)
    );
  };
  const isForMail = () => {
    return props.href && props.href.includes("mailto");
  };

  if ((props.isApp || props.openInIFrame) && !isForMail()) {
    return (
      <div
        onClick={openIFrame}
        className={`${props.styleAsLink ? "standardLink" : ""} ${
          props.className
        }`}
      >
        {props.children}
      </div>
    );
  } else {
    return (
      <a
        href={props.href}
        target={props.target}
        className={`standardLink ${props.className}`}
      >
        {props.children}
      </a>
    );
  }
}

CustomHref.propTypes = {
  href: PropTypes.string.isRequired,
  websiteTitle: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  openInIFrame: PropTypes.bool, // wichtig: wenn in Native App wird IMMER iFrame verwendet
  styleAsLink: PropTypes.bool,
};
CustomHref.defaultProps = {
  target: "_blank",
  websiteTitle: "",
  className: "",
  openInIFrame: true,
  styleAsLink: true,
};
const mapStateToProps = (state) => ({
  isApp: state.main.isApp,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentAppOverlay: (appOverlay) =>
    dispatch(mainAction.setCurrentAppOverlay(appOverlay)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomHref);
