import { useEffect } from "react";
import { POIS } from "../../../../reduxStore/actions/main.js";
import StartDestination from "../../../../functions/classes/startDestination.class.js";
import { useIntl } from "react-intl";

export function useHandleURLParams(props) {
  const intl = useIntl();

  useEffect(() => {
    // darf nur einmal abspielen, danach muss es sich nach den anderen components richten
    if (!props.mapLoaded || props.urlParamsHandled) return;

    const globalParams = new URLSearchParams(window.location.search);
    const destination = globalParams.get("destination")
      ? globalParams.get("destination").split(",")
      : null;

    const start = globalParams.get("start")
      ? globalParams.get("start").split(",")
      : null;

    const setURLStart = () => {
      if (start) {
        if (1 === start.length) {
          let poi = POIS.all.find((p) => p.uuid === start[0]);
          if (!poi) poi = POIS.all_objects[+start[0]];

          if (poi) {
            props.setStart(
              new StartDestination({
                nodeId: poi.getRouteNodeId(),
                poiId: poi.id,
                isSet: true,
                lat: poi.getFeatureCenter()[1],
                lng: poi.getFeatureCenter()[0],
                level: poi.level,
                indoors: poi.indoors,
                name: poi.name,
                campusId: poi.campusId,
                address: poi.address,
                type: poi.type,
              })
            );
          }
        } else if (3 === start.length) {
          if ("0" === start[0] || "0" === start[1]) return;
          props.setStart(
            new StartDestination({
              nodeId: null,
              poiId: null,
              isSet: true,
              lat: start[0],
              lng: start[1],
              level: start[2],
              name: intl.formatMessage({
                id: "gps.selectedPlace",
              }),
              campusId: null,
              address: null,
            })
          );
        }
      }
    };
    const setURLDestination = () => {
      if (destination) {
        if (1 === destination.length) {
          let poi = POIS.all.find((p) => p.uuid === destination[0]);
          if (!poi) poi = POIS.all_objects[+destination[0]];
          if (poi) {
            props.setTarget(
              new StartDestination({
                nodeId: poi.getRouteNodeId(),
                poiId: poi.id,
                isSet: true,
                lat: poi.getFeatureCenter()[1],
                lng: poi.getFeatureCenter()[0],
                level: poi.level,
                indoors: poi.indoors,
                name: poi.name,
                campusId: poi.campusId,
                address: poi.address,
                type: poi.type,
              })
            );
          }
        } else if (3 === destination.length) {
          //if (start == null || "0" === start[0] || "0" === start[1]) return;
          props.setTarget(
            new StartDestination({
              nodeId: null,
              poiId: null,
              isSet: true,
              lat: destination[0],
              lng: destination[1],
              level: destination[2],
              name: intl.formatMessage({
                id: "gps.selectedPlace",
              }),
              campusId: null,
              address: null,
            })
          );
        }
      }
    };

    setURLDestination();
    setURLStart();

    props.setMapURLParamsHandled();
  }, [props.mapLoaded]);
}
