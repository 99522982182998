import { useEffect } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { statisticLogger } from "../../components/statisticLogger/StatisticLogger.container";
import buildingLabelExceptions from "./buildingLabelExceptions.json";
import { setStart } from "../../reduxStore/actions/route";
import StartDestination from "../../functions/classes/startDestination.class";
import {
  easeToFeature,
  stableEaseTo,
} from "../../components/mapUi/mapbox/functions/interactivity.map";
import { CAMPUS_COLLECTION } from "../../reduxStore/actions/main";
import { map } from "../../components/mapUi/mapbox/map.sideEffects/useMap.sideEffects";

export let piKey;
export const setPiKey = (value) => {
  piKey = value;
};
export let useSavedData;
export let terminalPitch = 40;
export let terminalZoom = 19;
export let terminalBearing = 0;
export let terminalIdleTime = 90;
export let terminalIdleRotation = false;
export let terminalHandAnimation = true;
export let terminalNavigationLock = false;
export let defaultStartNode = null;
export let terminalDualScreenPortion = 0;
export let terminalDualScreenBottomMargin = 0;
export let terminalDualScreenTopMargin = 0;
export let terminalMainScreenBottomMargin = 0;
export let terminalDisplayDownPixels = 300;
export let terminalStandardLanguage = "de";
export let autoReset = true;
export let appVersion;
// TODO: Das muss irgendwann entfernt werden, weil es nur zum übergang dient!
export let useNewBuildingLabels = true;

// Falls es sich um eine App handelt wird ein Safezone Padding ( eigentlich Margin ) aus der URL rausgelesen
// dieses wird dann überall wo notwendig eingefügt
export let deviceSafePadding = { top: 0, bottom: 0 };
export let noIntro;
export let initCampus;
export let urlCampusKeys;

export const referrerTypes = {
  shareQR: "share-qr",
  terminaShareUrl: "terminal-share-url",
  shareUrl: "share-url",
};
// Verwendung der Parameter:
// Bitte jeweils die Variablen in den Anführungszeichen verwenden!
// Richtig: ....com/?piKey=.......&bearing=33&pitch=40
// Falsch:  ....com/?piKey=.......&terminalBearing=33&terminalPitch=40
export let urlParameters = {
  // --- Terminal ---
  // {number} Node für das Terminal, welches sich die App als Start-/Konzentrationspunkt nimmt
  defaultStartNode: "defaultStartNode",
  // {number} Wenn angegeben richtet sich die Map im Terminal in diese Richtung standardmäßig aus
  terminalBearing: "bearing",
  // {number} Wenn angegeben neigt sich die Map im Terminal standardmäßig niedriger (oder höher)
  terminalPitch: "pitch",
  // {number} Wie weit soll die kamera im Terminal modus hineingezoomt sein
  terminalZoom: "zoom",
  // {number} wie viel Zeit (in sekunden) muss vergehen, bis das Terminal in den Vorschau Zustand (Idle Mode)
  // geht und alles resettet. Default: 90 sek
  terminalIdleTimer: "idleTime",
  // {true/false} Gibt an, ob die Karte sich im Idle mode um einen Punkt drehen soll
  terminalIdleRotation: "idleRotation",
  // {true/false} Wenn true, dann sieht man im Terminal idle Zustand eine Handanimation
  terminalHandAnimation: "handAnimation",
  // {number (pixel)} Wie weit soll das Interface runterfahren, wenn auf das Rollstuhl Icon gedrückt wird
  terminalDisplayDownSize: "displayDownSize",
  // {"true"/"false"} Intro wird geskipped -> scheint aber nicht mehr zu funktionieren
  noIntro: "noIntro",
  initCampus: "initCampus",
  // {"true"/"false"}
  isTerminal: "isTerminal",
  uploadData: "uploadData",
  // Sollte ein Terimal über ein DualScreen verfügen kann der Anteil in px dessen hier angegeben werden
  // {number}
  dualScreenPortion: "dualScreenPortion",
  // Dualscreeneinstellungen
  // {number}
  dualScreenBottomMargin: "dualScreenBottomMargin",
  dualScreenTopMargin: "dualScreenTopMargin",
  mainScreenBottomMargin: "mainScreenBottomMargin",
  // Standardsprache beim Zurücksetzen des Terminals
  // {"de"|"en"|"fr"}
  standardLanguage: "standardLanguage",
  //{true/false} Bestimmt ob User Eingaben resettet werden bei Rückkehr zum Idle Zustand
  autoReset: "autoReset",

  // --- SelfCheck-In Terminal ---
  // Empfehlung für den Selfcheck-In: alle folgenden parameter aktivieren
  // {true/false} Sorgt dafür, dass man nicht mehr aus dem Route zurück in die Mapview kommt
  navigationLock: "navigationLock",

  // --- App ----
  // {"true"/"false"} Wenn die Webapp in einem Native App Environment abgespielt wird muss dieser Parameter gesetzt
  // werden, auch auf native Funktionen zugegriffen wird
  isApp: "isApp",
  // {number} Margin, damit nichts vom Content oben und unten durch Notches etc abgeschnitten wird in der App
  marginBottom: "marginBottom",
  marginTop: "marginTop",
  // {string} Wer hat die Seite referred?
  referrer: "referrer",

  // --- Data ---
  useSavedData: "useSavedData",
  // {string[]} CampusKeys
  campusKeys: "campusKeys",
  // {string} StyleId, wenn man für eine Instanz verschiedene Styles hat (Ordner des Styles hat styleId als Namen)
  customStyleId: "customStyleId",
  // {string}
  piKey: "piKey",
  // wird automatisch gesetzt, kann aber auch manuell gesetzt werden um beim Aufruf der Map sofort den
  // gewünschten POI anzuzeigen
  // {number}
  destination: "destination",
  appVersion: "appVersion",

  // --- Global Settings ---
  // Gibt an, wie hoch der SeitenZoom sein soll
  // {percentage}
  pageZoom: "pageZoom",
};

/**
 * Liest die Parameter aus der URL ein
 * @param props
 * @param intl
 */
export function useURLParams(props) {
  const queryString = props.location.search;
  const fetchedParams = new URLSearchParams(queryString);
  const androidMarginFactor_top = 1 / 3;
  const androidMarginFactor_bottom = 1 / 1.5;
  const iPhoneMarginFactor = 1 / 1.25;

  useEffect(() => {
    props.setIsApp(
      fetchedParams.get(urlParameters.isApp) === "1" ||
        fetchedParams.get(urlParameters.isApp) === "true"
    );

    props.setUploadData(
      fetchedParams.get(urlParameters.uploadData) === "1" ||
        fetchedParams.get(urlParameters.uploadData) === "true"
    );

    statisticLogger.addLog({
      action: {
        id: "initialPageCall",
        name: "Inital Page Call",
        group: "Page",
        movement: "enter",
        type: "click",
        interaction: "open",
        content: { referrer: fetchedParams.get(urlParameters.referrer) },
      },
    });

    if (fetchedParams.get(urlParameters.terminalZoom)) {
      terminalZoom = +fetchedParams.get(urlParameters.terminalZoom);
    }

    if (fetchedParams.get(urlParameters.appVersion)) {
      appVersion = fetchedParams.get(urlParameters.appVersion);
    } else if (fetchedParams.get(urlParameters.appversion)) {
      appVersion = fetchedParams.get(urlParameters.appversion);
    }

    if (fetchedParams.get(urlParameters.terminalPitch)) {
      terminalPitch = +fetchedParams.get(urlParameters.terminalPitch);
    }

    if (fetchedParams.get(urlParameters.terminalIdleTimer)) {
      terminalIdleTime = +fetchedParams.get(urlParameters.terminalIdleTimer);
    }

    if (
      fetchedParams.get(urlParameters.terminalIdleRotation) === "true" ||
      fetchedParams.get(urlParameters.terminalIdleRotation) === "1"
    ) {
      terminalIdleRotation = true;
    } else if (
      fetchedParams.get(urlParameters.terminalIdleRotation) === "false" ||
      fetchedParams.get(urlParameters.terminalIdleRotation) === "0" ||
      fetchedParams.get(urlParameters.terminalIdleRotation) === null
    ) {
      terminalIdleRotation = false;
    }

    if (
      fetchedParams.get(urlParameters.terminalHandAnimation) === "true" ||
      fetchedParams.get(urlParameters.terminalHandAnimation) === "1" ||
      fetchedParams.get(urlParameters.terminalHandAnimation) === null
    ) {
      terminalHandAnimation = true;
    } else if (
      fetchedParams.get(urlParameters.terminalHandAnimation) === "false" ||
      fetchedParams.get(urlParameters.terminalHandAnimation) === "0" ||
      !fetchedParams.get(urlParameters.terminalHandAnimation)
    ) {
      terminalHandAnimation = false;
    }

    if (fetchedParams.get(urlParameters.defaultStartNode)) {
      defaultStartNode = +fetchedParams.get(urlParameters.defaultStartNode);
      props.setDefaultStartNode(defaultStartNode);

      const doStuff = () => {
        const start = StartDestination.createStartDestination(
          defaultStartNode,
          "Hallo"
        );

        start.properties = {
          globalPoiId: start.globalPoiId,
        };

        stableEaseTo({ center: [start.lng, start.lat], duration: 1500 });
        map.zoomTo(19, { duration: 1000 });
        setTimeout(() => {
          props.setCurrentFloor(
            CAMPUS_COLLECTION.currentCampus.floors.findIndex(
              (f) => f.stockwerk === start.level
            )
          );
        }, 100);
        setTimeout(() => {
          props.setCurrentFloor(
            CAMPUS_COLLECTION.currentCampus.floors.findIndex(
              (f) => f.stockwerk === start.level
            )
          );
        }, 1000);

        setTimeout(() => {
          props.setCurrentFloor(
            CAMPUS_COLLECTION.currentCampus.floors.findIndex(
              (f) => f.stockwerk === start.level
            )
          );
        }, 2000);
      };

      setTimeout(() => {
        if (CAMPUS_COLLECTION && CAMPUS_COLLECTION.currentCampus) {
          doStuff();
        } else {
          setTimeout(() => {
            if (CAMPUS_COLLECTION && CAMPUS_COLLECTION.currentCampus) {
              doStuff();
            } else {
              setTimeout(() => {
                if (CAMPUS_COLLECTION && CAMPUS_COLLECTION.currentCampus) {
                  doStuff();
                } else {
                  setTimeout(() => {
                    try {
                      doStuff();
                    } catch (e) {}
                  });
                }
              }, 2000);
            }
          }, 1000);
        }
      }, 4000);
    }

    /*
    if (!CAMPUS_COLLECTION) {
        setTimeout(() => {
          if (!CAMPUS_COLLECTION) {
            setTimeout(() => {
              if (!CAMPUS_COLLECTION) {
                setTimeout(() => {}, 2000);
              } else {
                const start =
                  StartDestination.createStartDestination(defaultStartNode);
                props.setCurrentFeature(start);
              }
            }, 2000);
          } else {
            try {
              const start =
                StartDestination.createStartDestination(defaultStartNode);
              props.setCurrentFeature(start);
            } catch (e) {}
          }
        }, 1000);
      } else {
        const start = StartDestination.createStartDestination(defaultStartNode);
        props.setCurrentFeature(start);
      }
     */

    if (fetchedParams.get(urlParameters.useSavedData)) {
      useSavedData = fetchedParams.get(urlParameters.useSavedData);
    }

    if (fetchedParams.get(urlParameters.standardLanguage)) {
      terminalStandardLanguage = fetchedParams.get(
        urlParameters.standardLanguage
      );
    }

    if (
      fetchedParams.get(urlParameters.autoReset) === "true" ||
      fetchedParams.get(urlParameters.autoReset) === "1" ||
      fetchedParams.get(urlParameters.autoReset) === null
    ) {
      autoReset = true;
    } else if (
      fetchedParams.get(urlParameters.autoReset) === "false" ||
      fetchedParams.get(urlParameters.autoReset) === "0"
    ) {
      autoReset = false;
    }

    if (fetchedParams.get(urlParameters.terminalBearing)) {
      terminalBearing = +fetchedParams.get(urlParameters.terminalBearing);
      props.setTerminalBearing(
        +fetchedParams.get(urlParameters.terminalBearing)
      );
    }

    if (fetchedParams.get(urlParameters.campusKeys)) {
      const stringedArray = fetchedParams.get(urlParameters.campusKeys);
      // Initialen CampusKey speichern
      try {
        urlCampusKeys = JSON.parse(stringedArray);
        props.setURLCampusKeys(urlCampusKeys);
      } catch (e) {
        console.log("No valid CampusKey Array found in URL at 'campusKeys'");
      }
    }

    if (fetchedParams.get(urlParameters.customStyleId)) {
      // Initialen CampusKey speichern
      props.setCustomStyleId(fetchedParams.get(urlParameters.customStyleId));
    } else {
      // muss null gesetzt werden, weil sonst der styyyyyyler nix machen kann
      props.setCustomStyleId(null);
    }

    if (
      fetchedParams.get(urlParameters.navigationLock) === "true" ||
      fetchedParams.get(urlParameters.navigationLock) === "1"
    ) {
      terminalNavigationLock = true;
    } else if (
      fetchedParams.get(urlParameters.navigationLock) === "false" ||
      fetchedParams.get(urlParameters.navigationLock) === "0" ||
      fetchedParams.get(urlParameters.navigationLock) === null
    ) {
      terminalNavigationLock = false;
    }

    if (fetchedParams.get(urlParameters.dualScreenPortion)) {
      terminalDualScreenPortion = +fetchedParams.get(
        urlParameters.dualScreenPortion
      );
    } else if (
      fetchedParams.get(urlParameters.isTerminal) === "true" ||
      fetchedParams.get(urlParameters.isTerminal) === "1"
    ) {
      terminalDualScreenPortion = 540;
    }

    if (fetchedParams.get(urlParameters.dualScreenBottomMargin)) {
      terminalDualScreenBottomMargin = +fetchedParams.get(
        urlParameters.dualScreenBottomMargin
      );
    }
    if (fetchedParams.get(urlParameters.dualScreenTopMargin)) {
      terminalDualScreenTopMargin = +fetchedParams.get(
        urlParameters.dualScreenTopMargin
      );
    }

    if (fetchedParams.get(urlParameters.terminalDisplayDownSize)) {
      terminalDisplayDownPixels =
        +fetchedParams.get(urlParameters.terminalDisplayDownSize) + "px";
    }

    if (fetchedParams.get(urlParameters.mainScreenBottomMargin)) {
      terminalMainScreenBottomMargin =
        fetchedParams.get(urlParameters.mainScreenBottomMargin) + "px";
    }

    if (fetchedParams.get(urlParameters.pageZoom)) {
      document.body.style.zoom = fetchedParams.get(urlParameters.pageZoom);
    }

    piKey = fetchedParams.get(urlParameters.piKey);
    props.setPiKeyState(piKey);

    buildingLabelExceptions.forEach((label) => {
      if (label === piKey) {
        useNewBuildingLabels = false;
      }
    });

    // setDefaultAngle(props.setProperties, urlParams);
    if (
      fetchedParams.get(urlParameters.noIntro) === "true" ||
      fetchedParams.get(urlParameters.noIntro) === "1" ||
      piKey === "LHH-44sbHZhwah0o5cs7Go5QQSn2c89mR6VGImb9wT7N2smGUS"
    ) {
      noIntro = true;
    } else if (
      fetchedParams.get(urlParameters.noIntro) === "false" ||
      fetchedParams.get(urlParameters.noIntro) === "0" ||
      fetchedParams.get(urlParameters.noIntro) === null
    ) {
      noIntro = false;
    }

    if (fetchedParams.get(urlParameters.initCampus)) {
      initCampus = fetchedParams.get(urlParameters.initCampus);
    }

    console.log("noIntro: " + noIntro);

    // Style Properties um Unterschiede für Handys, Tablets etc. zu machen
    if (fetchedParams.get(urlParameters.marginTop)) {
      deviceSafePadding.top = fetchedParams.get(urlParameters.marginTop);
      if (isAndroid) {
        deviceSafePadding.top = deviceSafePadding.top * androidMarginFactor_top;
      }
      if (isIOS) {
        // iPhone
        deviceSafePadding.top = deviceSafePadding.top * iPhoneMarginFactor;
      }
    }

    if (fetchedParams.get(urlParameters.marginBottom)) {
      deviceSafePadding.bottom = fetchedParams.get(urlParameters.marginBottom);
      if (isAndroid) {
        deviceSafePadding.bottom =
          deviceSafePadding.bottom * androidMarginFactor_bottom;
      }
      if (isIOS) {
        deviceSafePadding.bottom =
          deviceSafePadding.bottom * iPhoneMarginFactor;
      }
    }

    // State setzen für den SaveZoneWrapper
    props.setProperties({
      saveZonePadding: {
        top: deviceSafePadding.top,
        bottom: deviceSafePadding.bottom,
      },
    });

    props.setURLParametersReady(true);
  }, []);

  const setSettingsSpecificParams = () => {
    if (!props.cookieSettingsReceived) return;

    if (
      fetchedParams.get(urlParameters.isTerminal) === "true" ||
      fetchedParams.get(urlParameters.isTerminal) === "1"
    ) {
      props.setIsTerminal();
      props.setProperties({
        routing: {
          movementRestricted: true,
        },
      });
    }
  };

  useEffect(setSettingsSpecificParams, [props.cookieSettingsReceived]);
}
