import { useEffect, useRef, useState } from "react";
import { synth } from "../../route/route.component.jsx";
import {
  setNativeSpeechLanguage,
  setNativeStartSpeak,
  setNativeStopSpeak,
} from "../../../nativeAppInterface/NativeAppInterface.jsx";
import { isIOS, isMobile } from "react-device-detect";
import { PROHIBIT_NEW_SPEAK, PROHIBIT_SPEAK } from "../navigation.component";
import { lineSlice, lineString, length } from "@turf/turf";

/**
 *
 * @param currentStep
 * @param isApp
 * @param needAudio
 * @param locationObject
 * @param setVoiceStepDistance
 * @param navigationMode
 * @param language
 * @param stepLineProgress
 * @param showLegChangeScreen
 * @param {Leg} currentLeg
 */
export function useSpeech(
  currentStep,
  isApp,
  needAudio,
  locationObject,
  setVoiceStepDistance,
  navigationMode,
  language,
  stepLineProgress,
  showLegChangeScreen,
  currentLeg
) {
  // --- States ---
  const [lineToSpeak, setLineToSpeak] = useState("");
  const [voice, setVoice] = useState(null);

  // --- Refs ---
  const prevStep = useRef(null);

  // --- funtions ---
  const speak = () => {
    if (PROHIBIT_SPEAK) return;
    // wenn der legchange screen da ist muss nicht gesprochen werden!
    if (!lineToSpeak || !needAudio.value || !isApp) return;

    if ("speechSynthesis" in window && !isIOS) {
      const msg = new SpeechSynthesisUtterance(lineToSpeak);
      msg.voice = voice;

      if (!synth.speaking || navigationMode === "sbs") {
        synth.cancel();
        synth.speak(msg);
      }
    } else {
      setNativeStopSpeak();
      setNativeStartSpeak(lineToSpeak);
    }
    setLineToSpeak("");
  };
  const setLanguage = () => {
    if (synth) {
      let voices = synth.getVoices();
      voices = voices.filter((voice) => voice.lang.includes(language));

      const voice = voices.find((v) => v.name === "Anna") ?? voices[0];
      setVoice(voice);
    }
    // setNativeSpeechLanguage(voices[0].lang);
    setNativeSpeechLanguage(language);
  };
  const setLiveSpeakLines = () => {
    if (
      navigationMode !== "live" ||
      !currentStep.voiceInstructions.length ||
      stepLineProgress.remaining == null ||
      !needAudio.value
    )
      return;

    const lengthRemaining = Math.floor(
      length(
        lineSlice(
          [locationObject.lng, locationObject.lat],
          currentStep.stepLine[currentStep.stepLine.length - 1],
          lineString(currentStep.stepLine)
        ),
        { units: "meters" }
      )
    );

    const allStepInstructions = currentStep.voiceInstructions;

    for (let i = 0; i < allStepInstructions.length; i++) {
      const currentInstruction = allStepInstructions[i];

      if (
        lengthRemaining <= currentInstruction.distanceAlongGeometry ||
        currentInstruction.distanceAlongGeometry === 0
      ) {
        // Diese Abfrage muss sein, weil der Step könnte vorangeschritten sein, aber
        // die simulierte Location könnte noch hinterher hinken auf dem prevStep,
        // Erst wenn die user distance auf dem currentStep etwas weiter als 0 ist sollte gesprochen werden
        // setVoiceStepDistance(currentInstruction.distanceAlongGeometry); --> wurde durch setBannerinstruction ersetzt
        if (!PROHIBIT_NEW_SPEAK) {
          console.warn("speak", currentInstruction.announcement);
          setLineToSpeak(currentInstruction.announcement);
        }

        // Die genannte Stepinstruction raushauen, damit sie nicht nochmal gesprochen wird
        allStepInstructions.splice(i, 1);
        break;
      }
    }
  };
  const setSBSSpeakLines = () => {
    if (!currentStep) return;

    // wenns keine LiveNavigation ist, dann muss nur einmal eine Ansage am
    // Anfang eines Steps gemacht werden
    if (
      currentStep.voiceInstruction_sbs?.announcement &&
      navigationMode !== "live"
    ) {
      setLineToSpeak(currentStep.voiceInstruction_sbs.announcement);
    }
  };
  const speakLastStep = () => {
    if (PROHIBIT_NEW_SPEAK) return;
    if (!showLegChangeScreen || navigationMode !== "live" || !needAudio.value)
      return;
    // wenn das Leg sein Ende erreicht muss auf jeden
    // Fall die letzte Instruction gesprochen werden!
    const lastStep = currentLeg.getLastStep();

    try {
      if (lastStep) {
        const legChangeInstruction =
          lastStep.voiceInstructions[lastStep.voiceInstructions.length - 1]
            .announcement;

        if (legChangeInstruction) {
          if (synth) {
            synth.cancel();
          }

          // sprich nur noch die letzte und dann hau ab
          setLineToSpeak(legChangeInstruction);
        }
      }
    } catch (e) {}
  };

  useEffect(() => {
    currentLeg.polylines = currentLeg.steps.map((s) => s.stepLine).flat(1);
  }, [currentLeg]);

  // --- Effects ---
  useEffect(speakLastStep, [showLegChangeScreen]);
  useEffect(() => {
    setSBSSpeakLines();
    return () => {
      prevStep.current = currentStep;
    };
  }, [currentStep, needAudio]);

  useEffect(setLiveSpeakLines, [
    currentStep,
    stepLineProgress,
    needAudio,
    locationObject?.lat,
    showLegChangeScreen,
  ]);

  useEffect(setLanguage, [language]);

  useEffect(speak, [lineToSpeak, needAudio]);

  useEffect(() => {
    if (needAudio && needAudio.value === false) {
      if ("speechSynthesis" in window && !isIOS) {
        synth.cancel();
      } else {
        setNativeStopSpeak();
      }
    }
  }, [needAudio]);
}
