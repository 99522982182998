import * as types from "../types.variables.js";
import { setCurrentFloorIndex } from "../actions/floors.js";
import { CAMPUS_COLLECTION } from "../actions/main.js";

const iniState = {
  isOpen: false,
  currentFloorIndex: 1,
  useFloorBounce: true,
};

export default function floors(state = iniState, action) {
  switch (action.type) {
    case types.MAIN_SET_CURRENT_POI: {
      let level = action.payload?.level;
      if (level != null) {
        return {
          ...state,
          currentFloorIndex: CAMPUS_COLLECTION.getIndexFromLevel(level),
          useFloorBounce: false,
        };
      } else {
        return state;
      }
    }

    case types.NAVIGATION_SET_ZINDEX:
      let index;
      try {
        index = setCurrentFloorIndex(action.payload).payload.index;
      } catch (e) {
        window.onerror("Fehler bei case types.NAVIGATION_SET_INDEX " + e);
      }
      // bei bluetooth stockwerkwechsel soll upgedatet werden. NUR BEI WECHSEL
      if (index != null && index !== state.currentFloorIndex) {
        return {
          ...state,
          currentFloorIndex: index,
        };
      } else {
        return state;
      }
    case types.FLOOR_SET_OPEN: {
      return {
        ...state,
        isOpen: true,
      };
    }
    case types.FLOOR_SET_CLOSE: {
      return {
        ...state,
        isOpen: false,
      };
    }
    case types.FLOOR_TOGGLE_OPEN: {
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    }
    // gleich beim serverload soll das StandardLevel gesetzt werden
    case types.MAIN_SET_CA_SERVERDATA:
      return {
        ...state,
        currentFloorIndex: CAMPUS_COLLECTION.getIndexFromLevel(0, false),
      };
    case types.FLOOR_SET_CURRENT_FLOOR: {
      console.log("reducer", action.payload.index);
      return {
        ...state,
        currentFloorIndex: action.payload.index,
        useFloorBounce: action.payload.bounce,
      };
    }

    default: {
      return state;
    }
  }
}
