import * as types from "../types.variables";

const ALLOWED_CATEGORIES = ["address", "news", "phonebook", "event"];

export function setSearchCategory(category) {
  if (!ALLOWED_CATEGORIES.includes(category)) {
    throw Error(`"${category}" can't use search`);
  }

  return {
    type: types.SEARCH_SET_CATEGORIES,
    payload: category,
  };
}

/**
 * Soll die Suche nur Ergebnisse mit Location anzeigen?
 * @param {bool} value
 * @return {{payload: *, type: string}}
 */
export function setMustHaveLocation(value) {
  return {
    type: types.SEARCH_SET_MUSTHAVELOCATION,
    payload: value,
  };
}

export function setSearchesFromStorage(lastSearch) {
  return {
    payload: lastSearch,
    type: types.SEARCH_GET_LAST_SEARCH,
  };
}

export function setLastSearch(type, id) {
  return {
    type: types.SEARCH_SET_LAST_SEARCH,
    payload: { type, id },
  };
}

export function resetLastUsed(type) {
  return {
    type: types.SEARCH_RESET_LAST_SEARCH,
    payload: { type },
  };
}

export function setTarget(type) {
  return {
    type: types.SEARCH_SET_TARGET,
    payload: type,
  };
}
