import latinize from "latinize";
import { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";

export default function Highlight({ search = "", children = "" }) {
  const [searchWords, setSearchWords] = useState([]);
  search = search.split("+").join("");
  useEffect(() => {
    let words = search.split(" ");
    setSearchWords(words);
  }, [search]);

  return (
    <Highlighter
      highlightClassName={"searchHighlighter"}
      searchWords={searchWords}
      autoEscape={true}
      caseSensitive={false}
      activeIndex={-1}
      sanitize={latinize}
      textToHighlight={children}
    />
  );
}
