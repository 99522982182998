import { isIOS13, isMobile } from "react-device-detect";

export class CalendarEvent {
  constructor(
    start,
    startInputType = null,
    end,
    endInputType = null,
    duration,
    title,
    description,
    location,
    url,
    geo,
    categories,
    status,
    busyStatus,
    organizer,
    attendees
  ) {
    if (start instanceof Date) {
      this.start = [
        start.getFullYear(),
        start.getMonth() + 1,
        start.getDate(),
        start.getHours(),
        start.getMinutes(),
      ];
    }
    if (end instanceof Date) {
      this.end = [
        end.getFullYear(),
        end.getMonth() + 1,
        end.getDate(),
        end.getHours(),
        end.getMinutes(),
      ];
    }
    this.startInputType = startInputType;
    this.endInputType = endInputType;
    this.duration = duration;
    this.title = title;
    this.description = description;
    this.location = location;
    this.url = url;
    if (isMobile && !isIOS13) {
      this.description = this.description + "\n" + this.url;
    }
    this.geo = geo;
    this.categories = categories;
    this.status = status;
    this.busyStatus = busyStatus;
    this.organizer = organizer;
    this.attendees = attendees;
  }
}

/**
 *
 * @param {CalendarEvent} event
 * @param {String} downloadText
 */
export const downloadCalendarEvent = (event, downloadText) => {
  const ics = require("ics");

  ics.createEvent(event, (e, value) => {
    if (e) {
      console.error(e);
      throw { userMessage: "error.failedCreateICS" };
    }
    const downloadLink = document.createElement("a");
    downloadLink.href = "data:text/calendar;charset=utf8," + escape(value);
    downloadLink.download = downloadText;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  });
};

/**
 *
 * @param {CalendarEvent} event
 * @param {String} downloadText
 */
export const generateCalendarEventB64 = (event, callback) => {
  const ics = require("ics");

  const cb = (e, value) => {
    if (e) {
      console.error(e);
      throw { userMessage: "error.failedCreateICS" };
    }
    return callback(event, escape(value));
  };

  ics.createEvent(event, cb);
};
