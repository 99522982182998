import { map } from "../../map.sideEffects/useMap.sideEffects.js";
import { layerIds } from "../../variables/layerIds.js";
import { sourceIds } from "../../variables/sourceIds.js";
import { EXPRESSIONS } from "../../variables/expressions.js";

const userPuckRadius_inner = 12;
const userPuckRadius_smaller = 6;
/**
 * Layer für UserPosition (GPS/Bluetooth Positionspunkt mit Richtungsanzeige)
 */
export function createRouteLegStartCircle() {
  // Der Actual Punkt
  if (!map.getLayer(layerIds.nodes.route.startCircle)) {
    map.addLayer({
      id: layerIds.nodes.route.startCircle,
      type: "circle",
      source: sourceIds.routeSymbole,
      filter: ["any", EXPRESSIONS.type.start, EXPRESSIONS.type.legChange],
      paint: {
        "circle-pitch-alignment": "map",
        "circle-radius": [
          "case",
          EXPRESSIONS.type.start,
          userPuckRadius_inner,
          userPuckRadius_smaller,
        ],
        "circle-color": "#ffffff",
        "circle-opacity": ["case", EXPRESSIONS.state.differentLevel, 0.2, 1],
        "circle-stroke-opacity": [
          "case",
          EXPRESSIONS.state.differentLevel,
          0.1,
          1,
        ],
        "circle-stroke-width": ["case", EXPRESSIONS.type.start, 3, 2],
        "circle-stroke-color": [
          "case",
          EXPRESSIONS.type.start,
          "#000000",
          "#575757",
        ],
      },
    });
  }
}
