import * as type from "../types.variables";
const initState = {
  debuggingView: {},
  uploadItem: null,
};

export default function debug(state = initState, action) {
  switch (action.type) {
    case type.DEBUG_SET_VALUES:
      return {
        ...state,
        debuggingView: {
          ...state.debuggingView,
          ...action.payload,
        },
      };
    case type.DEBUG_SET_UPLOAD_ITEM:
      return {
        ...state,
        // uploadItems: [...state.uploadItems, action.payload],
        uploadItem: action.payload,
      };
    // case type.DEBUG_REMOVE_UPLOAD_ITEM:
    //   let id = action.payload;
    //   let items = state.uploadItems.filter((item) => item.id !== id);
    //
    //   return {
    //     ...state,
    //     uploadItems: items,
    //   };
    default:
      return state;
  }
}
