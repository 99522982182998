import { isMobile } from "react-device-detect";

const initState = {
  status: "close",
};

export default function infoCard(state = initState, action) {
  switch (action.type) {
    case "INFO_CARD_SET_OPEN":
      return { ...state, status: "open" };
    case "INFO_CARD_SET_PRE_OPEN":
      return { ...state, status: "preOpen" };
    case "INFO_CARD_SET_CLOSE":
      return { ...state, status: "close" };
    case "MAIN_SET_CURRENT_POI":
      // bei desktop soll die karte sofort hochfahren
      if (!isMobile) {
        return { ...state, status: "preOpen" };
      } else {
        return { ...state };
      }
    default:
      return state;
  }
}
