import React from "react";
import PropTypes from "prop-types";

StandardInformation.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  noMargin: PropTypes.bool,
  children: PropTypes.any,
  type: PropTypes.string,
};
StandardInformation.defaultProps = {
  className: "mb-3",
  onClick: () => {},
  noMargin: true,
  children: "",
};

function StandardInformation(props) {
  return (
    <div
      onClick={props.onClick}
      className={`standardInformation d-flex align-items-center ${props.className}`}
    >
      {props.children}
    </div>
  );
}

export default StandardInformation;
