import { combineReducers } from "redux";
import mainMenu from "./mainMenu";
import main from "./main";
import map from "./map.js";
import floors from "./floors.js";
import phonebook from "./phonebook.js";
import search from "./search.js";
import infoCard from "./infoCards";
import settings from "./settings";
import route from "./route";
import debug from "./debug";
import toasts from "./toasts";
import appointment from "./appointment.js";
import { connectRouter } from "connected-react-router";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    mainMenu,
    main,
    appointment,
    infoCard,
    map,
    floors,
    phonebook,
    search,
    settings,
    debug,
    route,
    toasts,
  });
export default createRootReducer;
