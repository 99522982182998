import * as types from "../types.variables.js";
import { mapStyle } from "../../app/app.sideEffects/useServerData.sideEffects.js";
import { isMobile } from "react-device-detect";
import { map as cumap } from "../../components/mapUi/mapbox/map.sideEffects/useMap.sideEffects.js";

export const MAP_UI_NAMES = {
  info: "info",
  route: "route",
  navigation: "navigation",
};
export const STANDARD_MAP_PADDING = {
  top: 30,
  bottom: 30,
  left: 20,
  right: 20,
};
export const STANDARD_MAP_PADDING_DESKTOP = {
  top: 100,
  bottom: 100,
  left: 100,
  right: 100,
};

/**
 * Veränderungen der Mapansicht, die Einfluss auf einige
 * Components haben
 */
const initState = {
  currentPage: MAP_UI_NAMES.info,
  lastPage: null,
  // bounds hat Einfluss auf die angezeigten POI Cards
  bounds: { ne: null, sw: null, center: null },
  // Es gibt POI children und es gibt explizit die Features (die haben einen Standort und sind tatsächliche Features)
  // Sie werden als rote kleine Marker auf der map angezeigt
  childrenFeatures: null,

  show3DBuildings: true,
  // Das Gebäude das der User gerade am ehesten ansieht zum laden der korrekten GeoJSONS
  // todo: !!!!
  currentBoundBuildingId: "952",
  // Diese States sind für die HTML Marker
  // show ist normal zeigen, aber show geht nur wenn es allowed ist (nicht immun)
  showBuildingMarkers: {
    main: true,
    normal: true,
    "parking-lot": true,
    "parking-garage": true,
  },
  allowBuildingMarkers: true,
  // meta
  mapClicks: 0,
  mapLoaded: false,
  geoJSONsLoaded: false,
  geoJSONsUpdate: 0,
  accessToken: null,
  mapStyle: null,
  lng: null,
  lat: null,
  map: null,
  // State der sagt, ob der zoom sich gerade im indoorbereich(oder nicht) befindet
  viewIsIndoors: false,
  // State der erlaubt/verbietet, dass sich die Kamera mit dem Heading mitdreht
  cameraMoveToHeading: false,
  // wichtig für map.flyto oder map.panTo
  mapPadding: isMobile ? STANDARD_MAP_PADDING : STANDARD_MAP_PADDING_DESKTOP,
  // inkrement, dass manchmal verwendet werden muss um gerenderte Features upzudaten
  forceFeatureFilterUpdate: 0,
  terminalBearing: null,
};

export default function map(state = initState, action) {
  switch (action.type) {
    case types.MAP_SET_TERMINAL_BEARING:
      return {
        ...state,
        terminalBearing: action.payload,
      };
    case types.MAP_SET_GEOJSON_DATA:
      return {
        ...state,
        // da es öfter geladen werden kann
        // (je nach gebäudewechsel) inkrement als update menchanismus
        geoJSONsLoaded: action.payload,
        geoJSONsUpdate: state.geoJSONsUpdate + 1,
      };
    case types.MAP_SET_CAMERA_MOVE_TO_HEADING:
      return {
        ...state,
        cameraMoveToHeading: action.payload,
      };
    case types.MAP_SET_CHILDRENFEATURES:
      return {
        ...state,
        childrenFeatures: action.payload,
      };
    case types.MAP_SET_VIEW_IS_INDOORS:
      return {
        ...state,
        viewIsIndoors: action.payload,
      };
    case types.MAP_FORCE_FEATUREFILTER_UPDATE:
      return {
        ...state,
        forceFeatureFilterUpdate: state.forceFeatureFilterUpdate + 1,
      };
    case types.MAP_TOGGLE_BUILDINGMARKERS:
      if (
        action.payload["main-building"] ===
          state.showBuildingMarkers["main-building"] &&
        action.payload.normal === state.showBuildingMarkers.normal &&
        action.payload["parking-lot"] ===
          state.showBuildingMarkers["parking-lot"] &&
        action.payload["parking-garage"] ===
          state.showBuildingMarkers["parking-garage"]
      ) {
        return { ...state };
      } else
        return {
          ...state,
          showBuildingMarkers: {
            "main-building":
              action.payload["main-building"] != null
                ? action.payload["main-building"]
                : state.showBuildingMarkers["main-building"],
            normal:
              action.payload.normal != null
                ? action.payload.normal
                : state.showBuildingMarkers.normal,
            "parking-lot":
              action.payload["parking-lot"] != null
                ? action.payload["parking-lot"]
                : state.showBuildingMarkers["parking-lot"],
            "parking-garage":
              action.payload["parking-garage"] != null
                ? action.payload["parking-garage"]
                : state.showBuildingMarkers["parking-garage"],
          },
        };
    case types.MAP_SET_PADDING:
      const validatePadding = () => {
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;

        if (windowHeight - (newPadding.top + newPadding.bottom) < 0) {
          newPadding.top = isMobile
            ? STANDARD_MAP_PADDING.top
            : STANDARD_MAP_PADDING_DESKTOP.top;
          newPadding.bottom = isMobile
            ? STANDARD_MAP_PADDING.bottom
            : STANDARD_MAP_PADDING_DESKTOP.bottom;
        }
        if (windowWidth - (newPadding.left + newPadding.right) < 0) {
          newPadding.left = isMobile
            ? STANDARD_MAP_PADDING.left
            : STANDARD_MAP_PADDING_DESKTOP.left;
          newPadding.right = isMobile
            ? STANDARD_MAP_PADDING.right
            : STANDARD_MAP_PADDING_DESKTOP.right;
        }

        return newPadding;
      };
      let newPadding = { ...state.mapPadding, ...action.payload.padding };
      for (const key in newPadding) {
        // Paddings dürfen nie kleiner als 0 sein
        newPadding[key] = newPadding[key] > 0 ? newPadding[key] : 0;
      }
      newPadding = validatePadding();

      if (cumap && action.payload.tellMap) {
        // let the map know the padding
        cumap.setPadding(newPadding);
      }

      return {
        ...state,
        mapPadding: newPadding,
      };
    case types.MAP_SET_BOUNDS:
      return {
        ...state,
        bounds: action.payload,
      };
    case types.MAP_INCREMENT_MAPCLICKS:
      return {
        ...state,
        mapClicks: state.mapClicks + 1,
      };
    case types.MAP_SET_MAPLOADED:
      return {
        ...state,
        mapLoaded: action.payload,
      };
    case types.MAP_SET_MAPBOX_SERVERDATA:
      return {
        ...state,
        accessToken: action.payload.accessToken,
      };
    // für lng, lat, mapstyle
    case types.MAP_SET_MAPBOX_STATES:
      return {
        ...state,
        ...action.payload,
      };
    case types.MAP_TOGGLE_MAPSTYLE:
      if (state.mapStyle.includes("satellite")) {
        return {
          ...state,
          mapStyle: mapStyle,
        };
      } else {
        return {
          ...state,
          mapStyle: "mapbox://styles/mapbox/satellite-v9",
        };
      }

    case types.MAP_SET_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
        lastPage: state.currentPage,
      };
    }
    case types.MAIN_SET_CA_SERVERDATA:
      const campusInfo = action.payload.campus[0].campusinformationen;
      let style;
      if (campusInfo.mapstyleV5) {
        style = campusInfo.mapstyleV5;
      } else {
        style = campusInfo.stockwerke[0].mapstyle;
      }
      return {
        ...state,
        mapStyle: style,
      };
    case types.MAP_SET_MAP: {
      return {
        ...state,
        map: action.payload,
      };
    }
    case types.MAP_TOGGLE_3D_BUILDINGS: {
      return {
        ...state,
        show3DBuildings:
          action.payload != null ? action.payload : !state.show3DBuildings,
        // der 3D Modus toggelt automatisch auch die Building Marker
        showBuildingMarkers:
          action.payload != null ? action.payload : !state.show3DBuildings,
        allowBuildingMarkers:
          action.payload != null ? action.payload : !state.show3DBuildings,
      };
    }
    case types.MAP_SET_ALLOWBUILDINGMARKERS:
      return {
        ...state,
        allowBuildingMarkers: action.payload,
      };
    case types.MAIN_SET_TERMINAL_IDLE:
      // wenn das terminal in den Idle Zustand geht dürfen die marker nicht angezeigt werden!
      return {
        ...state,
        allowBuildingMarkers: !action.payload,
      };
    default:
      return state;
  }
}
