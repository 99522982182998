import { map } from "../../map.sideEffects/useMap.sideEffects.js";
import { layerIds } from "../../variables/layerIds.js";
import { sourceIds } from "../../variables/sourceIds.js";
import { EXPRESSION_MIX, EXPRESSIONS } from "../../variables/expressions.js";
import { BUNDLED_PROPERTIES } from "../../variables/layerProperties.js";
import {
  iconSize,
  zoomMacro,
  zoomMacroFactor,
  zoomMid,
  zoomWide,
  zoomMidFactor,
} from "../drawer.map.js";
import { CUSTOMIZABLE_VALUES } from "../../variables/CUSTOMIZABLE_VALUES.js";

let knotenSize = 13;
const haloWidth = 1;
const textSize = 14;
const iconBackgroundSize = 0.35;

/**
 * Erstellt den Layer für die Symbole (mit den Kreisen) auf der Map.
 * Dazu gehören z.B. Parkplätze, POIs etc.
 * @param map
 */
export function createPOISymbols() {
  // Die Layer sind für POIs, Raum, Gebäude, Stockwerke, Campusse
  if (!map.getLayer(layerIds.texts.einrichtungen)) {
    map.addLayer({
      id: layerIds.texts.einrichtungen,
      type: "symbol",
      source: sourceIds.nodes,
      layout: {
        visibility: "visible",
        "symbol-sort-key": ["case", ["get", "sortKey"], ["get", "sortKey"], 1],
        "text-size": textSize,
        "text-field": [
          "case",
          ["has", "short"],
          ["get", "short"],
          ["get", "name"],
        ],
        "text-font": CUSTOMIZABLE_VALUES.facilityTextFont,
      },
      paint: {
        "text-translate": [0, 5],
        "text-halo-color": CUSTOMIZABLE_VALUES.textHalo,
        "text-halo-width": haloWidth,
        "text-color": [
          "case",
          EXPRESSIONS.state.selected,
          CUSTOMIZABLE_VALUES.roomText_selected,
          CUSTOMIZABLE_VALUES.roomText,
        ],
      },
    });
  }
  if (!map.getLayer(layerIds.nodes.circleShadows)) {
    map.addLayer({
      id: layerIds.nodes.circleShadows,
      type: "circle",
      source: sourceIds.iconNodes,
      filter: EXPRESSIONS.type.poi,
      paint: {
        "circle-color": CUSTOMIZABLE_VALUES.shadowDark,
        "circle-blur": 1,
        "circle-opacity": [
          "case",
          ["any", EXPRESSION_MIX.symbols.noCircle, EXPRESSIONS.state.selected],
          0,
          EXPRESSIONS.state.differentLevel,
          0,
          1,
        ],
        "circle-radius": [
          "interpolate",
          ["linear"],
          ["zoom"],
          zoomWide,
          knotenSize,
          zoomMid,
          knotenSize * zoomMidFactor,
          zoomMacro,
          knotenSize * zoomMacroFactor,
        ],
      },
    });
  }
  knotenSize = 10;
  if (!map.getLayer(layerIds.nodes.outerCircles)) {
    map.addLayer({
      id: layerIds.nodes.outerCircles,
      type: "symbol",
      source: sourceIds.iconNodes,
      filter: EXPRESSIONS.type.poi,
      paint: {
        "icon-opacity": [
          "case",
          ["any", EXPRESSIONS.state.selected, EXPRESSION_MIX.symbols.noCircle],
          0,
          EXPRESSIONS.state.differentLevel,
          0.5,
          1,
        ],
        "icon-color": [
          "case",
          EXPRESSIONS.state.differentLevel,
          CUSTOMIZABLE_VALUES.ghostedHalo,
          CUSTOMIZABLE_VALUES.white,
        ],
        // "icon-halo-color": CUSTOMIZABLE_VALUES.white,
      },
      layout: {
        "icon-image": "poi_background",
        "icon-allow-overlap": true,
        "icon-size": [
          "interpolate",
          ["linear"],
          ["zoom"],
          zoomWide,
          iconBackgroundSize,
          zoomMid,
          iconBackgroundSize * zoomMidFactor,
          zoomMacro,
          iconBackgroundSize * zoomMacroFactor,
        ],
      },
    });
  }
  knotenSize = 8;
  if (!map.getLayer(layerIds.nodes.circles)) {
    map.addLayer({
      id: layerIds.nodes.circles,
      type: "circle",
      source: sourceIds.iconNodes,
      filter: EXPRESSIONS.type.poi,
      paint: {
        "circle-opacity": [
          "case",
          [
            "any",
            EXPRESSIONS.iconname.stairs,
            EXPRESSIONS.iconname.elevator,
            EXPRESSIONS.state.selected,
            EXPRESSION_MIX.symbols.noCircle,
          ],
          0,
          EXPRESSIONS.state.differentLevel,
          0,
          1,
        ],
        "circle-color": [
          "case",
          ["all", EXPRESSIONS.iconname.notaufnahme, EXPRESSIONS.state.selected],
          CUSTOMIZABLE_VALUES.notaufnahme,
          EXPRESSIONS.iconname.notaufnahme,
          CUSTOMIZABLE_VALUES.notaufnahme_light,
          EXPRESSIONS.iconname.geldautomat,
          CUSTOMIZABLE_VALUES.atm_light,
          EXPRESSIONS.iconname.kasse,
          CUSTOMIZABLE_VALUES.cashier_light,

          EXPRESSIONS.iconname.virus,
          CUSTOMIZABLE_VALUES.virus_light,

          ["all", EXPRESSIONS.iconname.apotheke, EXPRESSIONS.state.selected],
          CUSTOMIZABLE_VALUES.apotheke,

          EXPRESSIONS.iconname.apotheke,
          CUSTOMIZABLE_VALUES.apotheke_light,

          // Abdeckung aller Toiletten
          [
            "all",
            [
              "any",
              EXPRESSIONS.iconname.wcmen,
              EXPRESSIONS.iconname.wcwomenandmen,
              EXPRESSIONS.iconname.wcdisabled,
              EXPRESSIONS.iconname.wcwomen,
            ],
            EXPRESSIONS.state.selected,
          ],
          CUSTOMIZABLE_VALUES.toilette,
          [
            "any",
            EXPRESSIONS.iconname.wcmen,
            EXPRESSIONS.iconname.wcwomenandmen,
            EXPRESSIONS.iconname.wcdisabled,
            EXPRESSIONS.iconname.wcwomen,
          ],
          CUSTOMIZABLE_VALUES.toilette_light,

          ["all", EXPRESSIONS.iconname.taxi, EXPRESSIONS.state.selected],
          CUSTOMIZABLE_VALUES.taxi,
          [
            "any",
            EXPRESSIONS.iconname.taxi,
            EXPRESSIONS.iconname.bushaltestelle,
          ],
          CUSTOMIZABLE_VALUES.taxi_light,

          EXPRESSIONS.iconname.bistro,
          CUSTOMIZABLE_VALUES.bistro_light,

          EXPRESSIONS.iconname.entrance,
          CUSTOMIZABLE_VALUES.entrance_light,

          EXPRESSIONS.iconname.info,
          CUSTOMIZABLE_VALUES.information_light,

          ["all", EXPRESSIONS.type.poi, EXPRESSIONS.state.selected],
          CUSTOMIZABLE_VALUES.poi,
          EXPRESSIONS.type.poi,
          CUSTOMIZABLE_VALUES.poi_light,

          ["all", EXPRESSIONS.type.parkplatz, EXPRESSIONS.state.selected],
          CUSTOMIZABLE_VALUES.parking,
          EXPRESSIONS.type.parkplatz,
          CUSTOMIZABLE_VALUES.parking_light,

          CUSTOMIZABLE_VALUES.white,
        ],
        "circle-radius": [
          "interpolate",
          ["linear"],
          ["zoom"],
          zoomWide,
          knotenSize,
          zoomMid,
          knotenSize * zoomMidFactor,
          zoomMacro,
          knotenSize * zoomMacroFactor,
        ],
      },
    });
  }
  if (!map.getLayer(layerIds.nodes.symbols)) {
    map.addLayer({
      id: layerIds.nodes.symbols,
      type: "symbol",
      source: sourceIds.iconNodes,
      layout: {
        "icon-image": [
          "case",
          ["has", "iconname"],
          ["get", "iconname"],
          EXPRESSIONS.type.room,
          "room",
          "star",
        ],
        "icon-size": [
          "interpolate",
          ["linear"],
          ["zoom"],
          zoomWide,
          iconSize,
          zoomMid,
          iconSize * zoomMidFactor,
          zoomMacro,
          iconSize * zoomMacroFactor,
        ],
        "icon-allow-overlap": true,
      },
      paint: {
        "icon-opacity": [
          "case",
          EXPRESSIONS.state.selected,
          0,
          EXPRESSIONS.state.differentLevel,
          0.5,
          1,
        ],
        "icon-color": BUNDLED_PROPERTIES.symbols?.indoor.color ?? "#2388ad",
      },
    });
  }
  if (!map.getLayer(layerIds.texts.rooms)) {
    map.addLayer({
      id: layerIds.texts.rooms,
      type: "symbol",
      source: sourceIds.roomNodes,
      layout: {
        visibility: "visible",
        "text-size": textSize,
        "text-field": [
          "case",
          ["has", "short"],
          ["get", "short"],
          ["get", "name"],
        ],
        "text-font": CUSTOMIZABLE_VALUES.facilityTextFont,
      },
      paint: {
        "text-halo-color": CUSTOMIZABLE_VALUES.textHalo,
        "text-halo-width": haloWidth,
        "text-color": [
          "case",
          EXPRESSIONS.state.selected,
          CUSTOMIZABLE_VALUES.roomText_selected,
          CUSTOMIZABLE_VALUES.roomText,
        ],
      },
    });
  }
}
