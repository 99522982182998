import { useEffect, useRef } from "react";
import {
  BEACONS,
  CAMPUS_COLLECTION,
} from "../../../reduxStore/actions/main.js";
import { isAndroid } from "react-device-detect";
import recordAlwaysOnBTData, {
  alwaysOnBTRecordings,
  resetAlwaysOnBTRecordings,
} from "../../elements/positionRecorder/rawBluetoothRecorder.js";
import { piKey } from "../../../app/app.sideEffects/useURLParams.sideEffects.js";
import {
  callAndroidFunction,
  callIPhoneFunctions,
} from "../NativeAppInterface.jsx";
import { statisticLogger } from "../../statisticLogger/StatisticLogger.container.js";

/**
 * Hier findet die Kommunikation zwischen App und Webimplementierung statt
 * @param props
 * @returns {null}
 * @constructor
 */
export let gpsTimeout;
export let androidHeadingModulo = 0;
export let appIsForeground = true;
export const workedLocation = {
  pastHeadings: [],
  averageHeading: null,
  maxHeading: null,
  minHeading: null,
  sumCos: 0,
  sumSin: 0,
  aveCos: 0,
  aveSin: 0,
};
window.didUpdateBLELocationData = () => {};
window.didReceiveAppEvent = () => {};

export function useNativeLocation(props) {
  const lastInstanceGeoErrorTimer = useRef();
  const prevAndroidTimestamp = useRef(new Date().getTime());
  const bluetoothDataUploadTimer = useRef();
  const healthStatusUnknownCount = useRef(0);

  const uploadRawBluetoothData = () => {
    if (!props.serverDataLoaded) return;

    const uploadInterval = 60000;
    const uploadURL =
      process.env.REACT_APP_ALWAYS_RECORDING_BLE_UPLOAD + "?piKey=" + piKey;

    bluetoothDataUploadTimer.current = setInterval(() => {
      if (!alwaysOnBTRecordings || !alwaysOnBTRecordings.length) return;

      fetch(uploadURL, {
        method: "POST",
        body: JSON.stringify({
          data: alwaysOnBTRecordings,
          sessionKey: statisticLogger._serverSessionKey,
          piKey: piKey,
          campusKey: CAMPUS_COLLECTION?.getCurrentCampus().campusKey,
        }),
      }).catch((e) => console.error("Konnte nicht hochgeladen werden", e));

      resetAlwaysOnBTRecordings();

      return () => {
        clearInterval(bluetoothDataUploadTimer.current);
      };
    }, uploadInterval);
  };

  const onGeoError = (e) => {
    console.log("wow geoerror", e);
    if (e.code && e.code === e.PERMISSION_DENIED) {
      props.setProperties({
        location: { permissionAllowed: false, userAllowed: false },
      });

      return;
    }

    // Warnung soll nur kommen, wenn User auch Standort in den Appeinstellungen
    // eingeschaltet hat.
    if (props.userAllowedGPS) {
      // Sicherheitstimer für schließen und öffnen der App!
      lastInstanceGeoErrorTimer.current = setTimeout(() => {
        if (appIsForeground) {
          window.addWarning({
            show: true,
            upload: false,
            message: e.message,
            userMessage: "app.noLocation",
            needAdditionInformation: false,
          });
        }
      }, 10000);
    }
  };

  // Global verfügbare Funktionen zum Aufrufen durch die Native App Hülle
  useEffect(() => {
    if (!props.serverDataLoaded) return;
    window.getBeacons = () => {
      return JSON.stringify(BEACONS);
    };
  }, [props.serverDataLoaded]);
  useEffect(uploadRawBluetoothData, [props.serverDataLoaded]);

  const lowerSpecificKeys = (obj) => {
    if (obj.UUID) {
      obj.uuid = obj.UUID;
    }
    if (obj.MAC) {
      obj.mac = obj.MAC;
    }
    return obj;
    // return Object.keys(obj).reduce((accumulator, key) => {
    //   accumulator[key.toLowerCase()] = obj[key];
    //   return accumulator;
    // }, {});
  };
  useEffect(() => {
    window.didUpdateBLELocationData = (result) => {
      recordAlwaysOnBTData(result);
      // bei android müssen die bluetoothdaten so gefiltert werden
      // dass nur jede sekunde was reinkommt
      let currentTimestamp = new Date().getTime();
      if (isAndroid && currentTimestamp - prevAndroidTimestamp.current < 500) {
        console.warn("BLE Data skipped");
        return;
      } else {
        prevAndroidTimestamp.current = currentTimestamp;
      }
      result.forEach((r, index) => {
        result[index] = lowerSpecificKeys(r);
      });
      clearTimeout(lastInstanceGeoErrorTimer.current);
      clearTimeout(gpsTimeout);
      gpsTimeout = setTimeout(() => {
        onGeoError({ message: "Keine Lokalisierung" });
      }, 20000);

      // update
      if (props.userAllowedGPS) props.setBLEData(result);
    };
    // wenns ne neue gps location gibt (hier gibts auch ein heading("course"))
    window.didUpdateLocation = (lat, lng, accuracy, speed = 0, course = 0) => {
      props.setProperties({ location: { permissionAllowed: true } });

      clearTimeout(lastInstanceGeoErrorTimer.current);
      clearTimeout(gpsTimeout);
      gpsTimeout = setTimeout(() => {
        onGeoError({ message: "Keine Lokalisierung" });
      }, 20000);
      const timestamp = new Date().getTime();
      // update - Aber eben nur wenn GPS vom User erlaubt wurde
      if (props.userAllowedGPS)
        props.setGPSData({ lng, lat, accuracy, speed, course, timestamp });
    };
    window.didUpdateHeading = (heading) => {
      if (isAndroid) {
        // weil das heading von android so beschiss ist, muss es erstmal komplett ignoriert werden
        return;
        // Android hat sehr schelchte Heading Werte, daher müssen diese verarbeitet werden

        // 2d-Einheitsvektoren der Gradzahl (heading) ins 7ner-array hinzufügen
        let radians = heading * (Math.PI / 180);
        let cosValue = Math.cos(radians);
        let sinValue = Math.sin(radians);
        workedLocation.pastHeadings.push([cosValue, sinValue]);

        // auf die Summe draufzählen
        workedLocation.sumCos += cosValue;
        workedLocation.sumSin += sinValue;

        if (workedLocation.pastHeadings.length > 9) {
          // falls bereits mehr als 5 drin sind das erste rausshiften
          // aber vorher von der summe abziehen
          workedLocation.sumCos -= workedLocation.pastHeadings[0][0];
          workedLocation.sumSin -= workedLocation.pastHeadings[0][1];
          // ersten Eintrag entfernen
          workedLocation.pastHeadings.shift();
        }

        // durch anzahl teilen um von jeweils sin und cos den durchschnitt der letzten x werte zu bekommen
        workedLocation.aveCos =
          workedLocation.sumCos / workedLocation.pastHeadings.length;
        workedLocation.aveSin =
          workedLocation.sumSin / workedLocation.pastHeadings.length;

        // vektor mit atan ausrechnen in radians -> umwandlung in degree
        let averageAngle =
          Math.atan2(workedLocation.aveSin, workedLocation.aveCos) *
          (180 / Math.PI);
        // umwandeln der negativ werte in normale 360 Grad
        if (averageAngle < 0) averageAngle = 360 + averageAngle;

        // State setzen (aber alle 4 infos erst)
        if (androidHeadingModulo % 4 === 0 && props.userAllowedGPS)
          props.setHeading(averageAngle);
        androidHeadingModulo++;
      } else {
        if (props.userAllowedGPS) props.setHeading(heading);
      }
    };
    window.onMotionActivityStatusUpdate = (status, confidence) => {
      if (confidence !== "high") return;

      props.setMotionActivityStatus(status);

      if (status !== "unknown") {
        props.setProperties({
          healthStats: { permissionAllowed: true },
        });
      } else {
        if (healthStatusUnknownCount.current < 6) {
          // nicht beim ersten mal verzagen
          healthStatusUnknownCount.current++;
        } else {
          props.setProperties({
            healthStats: { permissionAllowed: false, userAllowed: false },
          });
          healthStatusUnknownCount.current = 0;
        }
      }
    };
    /**
     * Event, das verschiedene Anweisungen enthalten kann
     * @param {{event:CUWebViewEvent, data:json}} event
     */
    window.didReceiveAppEvent = (event) => {
      switch (event) {
        case "didStopRangingBeacons":
          break;
        case "applicationWillEnterForeground":
          appIsForeground = true;
          break;
        case "applicationDidEnterBackground":
          appIsForeground = false;
          break;
        default:
          break;
      }
    };
  }, [props.userAllowedGPS]);

  const handleBeaconRanging = () => {
    if (!props.serverDataLoaded || !props.isApp || !props.userAllowedGPS)
      return;

    const beaconMsges = ["startRangingBeacons"];
    const locationMsges = ["startUpdatingLocation", "startUpdatingHeading"];

    if (BEACONS.length) {
      // Android Interface
      beaconMsges.forEach((msg) => {
        callAndroidFunction(msg);
      });
      // Interface Setup für iOS
      // Trigger Datenübergabe
      callIPhoneFunctions(beaconMsges);
    }
    // Android Interface
    locationMsges.forEach((msg) => {
      callAndroidFunction(msg);
    });
    // Interface Setup für iOS
    // Trigger Datenübergabe
    callIPhoneFunctions(locationMsges);
    if (props.userAllowed === false) {
      props.setGPSData({
        locationType: null, // GPS or bluetooth
        lat: null,
        lng: null,
        accuracy: null,
        zIndex: { value: 0, isFromBeacon: null },
        heading: null,
        timestamp: 0,
      });
    }

    return () => {
      const msges = [
        "stopRangingBeacons",
        "stopUpdatingLocation",
        "stopUpdatingHeading",
      ];
      msges.forEach((msg) => {
        callAndroidFunction(msg);
      });

      callIPhoneFunctions(msges);
    };
  };
  // Interface Setup für Android & iOS
  // starten, wenn alle Daten da sind
  useEffect(handleBeaconRanging, [
    props.serverDataLoaded,
    props.mapLoaded,
    props.isApp,
    props.userAllowedGPS,
  ]);
}
