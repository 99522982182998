import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "react-simple-keyboard/build/css/index.css";
import { FormattedMessage, useIntl } from "react-intl";
import {
  POIS,
  SEARCH_CATEGORIES,
  PHONEBOOK_ENTRIES,
  NEWS,
  EVENTS_UNSORTED,
  QR_CODES,
  CAMPUS_COLLECTION,
  MISC_INFO,
  MODULES,
} from "../../reduxStore/actions/main";
import Header from "./header";
import Filter from "./filter";
import LastUsed from "./lastUsed";
import MostUsed from "./mostUsed";
import List from "./list";

import classes from "./search.module.scss";
import fullpageStyle from "../elements/fullPage/fullPage.module.scss";

import * as action from "../../reduxStore/actions/search";
import * as actionMain from "../../reduxStore/actions/main";
import * as actionInfoCard from "../../reduxStore/actions/infoCard";
import * as actionRoute from "../../reduxStore/actions/route";
import * as routeAction from "../../reduxStore/actions/route";
import * as settingsAction from "../../reduxStore/actions/settings";
import * as appointmentAction from "../../reduxStore/actions/appointment.js";
import ToastsContainer from "../elements/toast/toasts.container";
import Fuse from "fuse.js";
import { options } from "../../configuration/fuzzy";
import StartDestination from "../../functions/classes/startDestination.class.js";
import { withRouter } from "react-router-dom";
import SaveZoneWrapper from "../elements/wrapper/saveZone.wrapper.jsx";
import RoundedIcon from "../elements/buttons/roundedIcon.component.jsx";
import StandardCard from "../elements/cards/StandardCard.card.jsx";
import { isIOS, isMobile } from "react-device-detect";
import PropTypes from "prop-types";
import StandardHeadline from "../elements/text/standardHeadline.text.jsx";
import * as actionInfo from "../../reduxStore/actions/infoCard.js";
import { Modal } from "react-bootstrap";
import StandardButton from "../elements/buttons/standard.button.jsx";
import StandardInstruction from "../elements/text/standardInstruction.component.jsx";
import ToggleSetting from "../elements/buttons/toggleSetting.component.jsx";
import { SearchingLocation } from "../settings/pages/Location.jsx";
import { distance } from "@turf/turf";
import { map } from "../mapUi/mapbox/map.sideEffects/useMap.sideEffects";
import { statisticLogger } from "../statisticLogger/StatisticLogger.container";
import WheelchairButton from "../elements/buttons/wheelchair/Wheelchair.button";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { nativeStorageInterface } from "../../index";
import {
  cancelDataScanner,
  startDataScanner,
} from "../nativeAppInterface/NativeAppInterface";
import { isInDevelopmentMode } from "../../functions/helper/helpers.functions";
import {
  piKey,
  terminalNavigationLock,
} from "../../app/app.sideEffects/useURLParams.sideEffects";
import ScannerInfo from "../mapUi/navigation/scannerInfo";
import Scanner from "../../classes/Scanner";
import QrCodeReader from "../mapUi/QrCodeReader/QrReader.component";
import { history } from "../../reduxStore/store";
import { pageNames } from "../../reduxStore/reducers/main";
import { getQueryParams } from "../../app/RouteWithSubRoutes";

let scanListSearch = [];
let scanner = null;

function Search(props) {
  const intl = useIntl();
  const [searchText, setSearchText] = useState("");
  const [qrOpen, setQrOpen] = useState(false);
  const [matches, setMatches] = useState(999);
  const [categories, setCategories] = useState([]);
  const [searchCategories, setSearchCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCampuses, setSelectedCampuses] = useState([]);

  const [showKeyboard, setShowKeyboard] = useState(true);

  // gefilterte POIs nach angegebenen (globalen, nicht usergesteuerten) Filtern für Search
  const filteredPOIs = useGlobalFilter(props);

  // Modal, falls GPS für die currentPosition noch nicht erlaubt
  const [GPSModal, setGPSModal] = useState(false);

  useEffect(() => {
    return () => {
      if (scanListSearch.length) {
        statisticLogger.addLog({
          action: {
            id: "keinBockaufnamenesfindung",
            group: "search",
            name: "whats scanned",
            movement: "stay",
            type: "click",
            interaction: "select",
            content: scanListSearch,
          },
        });

        scanListSearch = [];
      }

      window.foundedBuilding = null;
      window.foundedFloor = null;
    };
  }, []);
  const createCategories = () => {
    const temp_searchCategories = SEARCH_CATEGORIES.map((sC) => ({
      ...sC,
      nodes: sC.nodes.filter((n) => {
        return n.id !== props.start.poiId && n.id !== props.destination.poiId;
      }),
    }));
    // Kategorien für den Filter
    const temp_categories = temp_searchCategories.filter((category) => {
      return (
        filteredPOIs.find((node) => +node.category === +category.id) ||
        category.id === "QR-Code"
      );
    });
    setSearchCategories(temp_searchCategories);
    setCategories(temp_categories);
  };
  const setSettings = () => {
    const setting = {
      filter: {
        ...props.settings,
        search: {
          ...props.settings.search,
          campusId: selectedCampuses,
          categories: selectedCategories,
        },
      },
    };

    props.setProperties(setting);
  };
  const setInitialCampusFilter = async (reset = false) => {
    const allCampusIds = CAMPUS_COLLECTION.getAllCampuses().map((c) => c.id);
    setSelectedCampuses(allCampusIds);
  };
  const getCurrentCampusId = (id) => {
    const campusNodes = CAMPUS_COLLECTION.getAllCampusNodes();
    const node = campusNodes.find((cN) => cN.id === id);
    if (!node) throw "No item found in Campuses";
    return node.campusId;
  };
  const resetLastUsed = () => {
    window.addLog({
      show: false,
      upload: true,
      message: `Zuletzt benutzt wurde gelöscht`,
    });
    props.resetLastUsed(props.searchedCategory);
  };
  const toggleCampus = (id) => {
    let tempSelected = selectedCampuses;

    if (tempSelected.includes(id)) {
      // wenn nur noch 1 ausgewählt ist, darf dieser nicht abgewählt werden!
      if (tempSelected.length === 1) return;

      tempSelected = tempSelected.filter((c) => c !== id);
    } else {
      tempSelected.push(id);
    }

    nativeStorageInterface.set("selectedCampusIds", {
      payload: tempSelected,
    });

    setSelectedCampuses([...tempSelected]);
  };
  const setQRCode = (qrCode) => {
    if (qrCode.startNodeId || qrCode.destinationId) {
      props.history.push(
        `/${pageNames.map}/${pageNames.route}/${getQueryParams()}`
      );
    }

    setTimeout(() => {
      if (qrCode.startNodeId) {
        const currentCampusId = getCurrentCampusId(qrCode.startNodeId);
        setStart(qrCode.startNodeId, currentCampusId);
      }
      if (qrCode.destinationId) {
        setDestination(qrCode.destinationId);
      }
    }, 100);

    const { piKey } = props.match.params;

    window.saveLog({
      piKey,
      qrKey: qrCode.name,
    });
  };
  const setStart = (nodeId) => {
    props.setStart(
      StartDestination.createStartDestination(
        nodeId,
        intl.formatMessage({ id: "gps.selectedPlace" })
      )
    );
  };
  const setDestination = (poiId) => {
    // const feature = POIS.all.find((p) => p.id === +poiId);
    const feature = POIS.all_objects[+poiId];
    if (!feature) throw `POI with id:"${+poiId}" not found`;

    props.setTarget(
      new StartDestination({
        poiId: feature.id,
        nodeId: feature.nodeId,
        isSet: true,
        lng: feature.getFeatureCenter()[0],
        lat: feature.getFeatureCenter()[1],
        level: feature.level,
        indoors: feature.indoors,
        name: feature.name,
        campusId: feature.campusId,
        address: feature.address,
        type: feature.type,
      })
    );
  };

  const onReadId = async (result) => {
    const qrCode = await result;
    let start = false;

    const hasStart = !!qrCode.startNodeId;
    const hasDestination = !!qrCode.destinationId;

    if (hasStart) {
      history.push(`/${pageNames.map}/${pageNames.route}/${getQueryParams()}`);
      setTimeout(() => {
        start = StartDestination.createStartDestination(
          qrCode.startNodeId,
          intl.formatMessage({ id: "gps.selectedPlace" })
        );

        start.id = start.nodeId;
        start.globalPoiId = null;
        props.setStart(start);

        if (hasDestination) {
          const test = StartDestination.createStartDestination(
            qrCode.destinationId,
            intl.formatMessage({ id: "gps.selectedPlace" })
          );
          props.setTarget(test);
        }
      }, 100);
    } else if (hasDestination) {
      setTimeout(() => {
        const test = StartDestination.createStartDestination(
          qrCode.destinationId,
          intl.formatMessage({ id: "gps.selectedPlace" })
        );

        if ((props.start && props.start.lat) || props.start.id) {
          props.setTarget(test);
        } else {
          props.setCurrentFeature(test);
        }
      }, 100);
    }
  };
  const curateCurrentLocation = () => {
    const zIndex = CAMPUS_COLLECTION.getLevelByIndex(
      props.locationObject.zIndex
    );
    const startDestination = new StartDestination({
      nodeId: null,
      poiId: null,
      isSet: true,
      lat: props.locationObject.lat,
      lng: props.locationObject.lng,
      level: zIndex,
      isIndoors: false,
      positionType: "user",
      name: intl.formatMessage({ id: "gps.currentLocation" }),
      campusId: null,
      address: null,
    });
    props.onSpecialLocationClicked(startDestination);
  };
  const handleCurrentLocationClick = () => {
    window.addLog({
      show: false,
      upload: true,
      message: "GPS Location wurde als Startpunkt gewählt",
    });
    statisticLogger.addLog({
      action: {
        group: "Search",
        movement: "leave",
        interaction: "select",
        id: "searchSelectCurrentPosition",
        name: "Search select current position",
        type: "click",
        content: {
          search: searchText,
          startLatitude: props.locationObject.lat,
          startLongitude: props.locationObject.lng,
          startZIndex: CAMPUS_COLLECTION.getLevelByIndex(
            props.locationObject.zIndex
          ),
        },
      },
    });
    if (props.userAllowedGPS) {
      curateCurrentLocation();
    } else {
      setGPSModal(true);
    }
  };
  const handlePositionOnMapClick = () => {
    statisticLogger.addLog({
      action: {
        group: "Search",
        movement: "leave",
        interaction: "select",
        id: "searchSelectMarkOnMap",
        name: "Search select mark position on map",
        type: "click",
        content: {
          search: searchText,
        },
      },
    });
    props.onSpecialLocationClicked(
      new StartDestination({
        setPositionOnMap: true,
        isSet: false,
        positionType: "coordinates",
      })
    );
  };
  const [showScannerInfo, setShowScannerInfo] = useState(false);
  const handleScanDoorSign = async () => {
    const seenScannerInfo = await nativeStorageInterface.get(
      "seen-scanner-info"
    );

    if (!seenScannerInfo) {
      setShowScannerInfo(true);
      nativeStorageInterface.set("seen-scanner-info", true);
    } else {
      setShowScannerInfo(false);
      if (piKey === "UKT-7wbrGmoTcGMsoSHnYvS10mCRTb76Ws1uolN3s5kdLJUj9M") {
        scanner = new Scanner();
        // console.log("ALlles richtig");
        setTimeout(() => {
          // console.log("Timeout stopped");

          //TODO abprüfen, ob kamera noch aktiv
          const room = scanner.getRoom();
          const allHeadings = [...scanner.scannedHeading];

          // room.lat, room.lng ??

          // props.locationObject

          statisticLogger.addLog({
            action: {
              group: "Search",
              movement: "",
              interaction: "",
              id: "scannerend",
              name: "Was der scanner bekommen hat",
              type: "click",
              content: scanner.scannedText,
            },
          });

          cancelDataScanner();
          if (room) {
            props.onPOIClicked(room, room?.name);
            props.setPreOpen();
          } else {
            window.addLog({
              show: true,
              upload: false,
              userMessage: "scanner.roomNotFound",
            });
          }
        }, 5000);
      }
      startDataScanner(MISC_INFO.dataScanner.regEx);
    }
  };

  const handleScanQrCode = async () => {
    setQrOpen(true);
  };

  useEffect(() => {
    if (scanner) {
      scanner.addHeading(props.locationObject.heading);
    }
  }, [props.locationObject]);

  window.didScanText = (text) => {
    if (piKey === "UKT-7wbrGmoTcGMsoSHnYvS10mCRTb76Ws1uolN3s5kdLJUj9M") {
      scanListSearch.push(text);
      if (scanner) {
        scanner.addText(text);
      }

      return;
    }

    /*
    const tag = document.createElement("div");
    tag.className = "debug-ios-modal";
    tag.style.position = "fixed";
    tag.style.top = "50px";
    tag.style.zIndex = 999999;
    tag.style.left = "50px";
    tag.style.background = "red";
    tag.innerText = `result: ${text}`;
    document.body.appendChild(tag);
    setTimeout(() => {
      tag.remove();
    }, 4000); */

    let feature;
    feature = Object.values(POIS.all_objects).find(
      (p) =>
        p.type === "Raum" &&
        p.name.replaceAll(".", "").replaceAll(" ", "") ===
          text.replaceAll("-", "")
    );

    if (!feature) {
      feature = Object.values(POIS.all_objects).find(
        (p) => p.type === "Raum" && p.name === text
      );

      if (!feature) {
        //TODO: LOG
        return;
      }
    }

    cancelDataScanner();
    props.onPOIClicked(feature, text);
    props.setPreOpen();
  };

  const handlePOIClick = (id) => {
    window.addLog({
      show: false,
      upload: true,
      message: `Auf das Element mit der Id: "${id}" wurde geklickt`,
    });
    props.setLastSearch(props.searchedCategory, id);

    // TODO show selected entry (currently only addresses ar supported)
    // const feature = POIS.all.find((poi) => poi.id === id);
    const feature = POIS.all_objects[id];
    if (feature) {
      props.onPOIClicked(feature, searchText);
      props.setPreOpen();
    }
  };
  const handleClose = () => {
    statisticLogger.addLog({
      action: {
        group: "Search",
        movement: "leave",
        type: "click",
        interaction: "exit",
        id: "exitsearch",
        name: "Exit Search",
      },
    });
    props.onClose();
  };
  const toggleCategory = (id) =>
    setSelectedCategories(
      selectedCategories.find((catId) => catId === id)
        ? selectedCategories.filter((catId) => catId !== id)
        : selectedCategories.concat(id)
    );

  useEffect(setSettings, [selectedCategories, selectedCampuses]);
  useEffect(createCategories, [filteredPOIs]);
  useEffect(() => setSearchText(""), [props.terminalIsIdle]);
  useEffect(() => {
    setInitialCampusFilter();
  }, []);
  useEffect(() => {}, [props]);
  useEffect(() => {
    if (props.isTerminal) setSelectedCategories([]);
  }, [props.terminalIsIdle]);

  const ScanQRButton = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const isFramed = !!urlParams.get("isFramed");

    return (
      <SpecialLocation
        handleClick={handleScanQrCode}
        show={
          isMobile &&
          !isFramed &&
          MODULES.qrScannerAnbieten &&
          (piKey === "KHBR-hXf0ATzBt5SCYTdNnY6c6Tm9z5AxhYMrpyKygpaZ3gbLo" ||
            piKey === "LHH-44sbHZhwah0o5cs7Go5QQSn2c89mR6VGImb9wT7N2smGUS") &&
          !searchText.length
        }
        title={<FormattedMessage id="search.scanQR" />}
        icon={["fal", "scanner-keyboard"]}
        className={"p-3"}
      />
    );
  };

  const ScanTextButton = () => {
    if (props.type !== "start" || !props.isApp) return null;
    if (piKey !== "UKT-7wbrGmoTcGMsoSHnYvS10mCRTb76Ws1uolN3s5kdLJUj9M")
      return null;

    return (
      <SpecialLocation
        handleClick={handleScanDoorSign}
        show={
          props.dataScannerAvailable ||
          (piKey === "UKT-7wbrGmoTcGMsoSHnYvS10mCRTb76Ws1uolN3s5kdLJUj9M" &&
            !searchText.length)
        }
        title={<FormattedMessage id="search.scanDoorSign" />}
        icon={["fal", "scanner-keyboard"]}
        className={"p-3"}
      />
    );
  };

  return (
    <div
      className={`${classes.container} h-100 w-100 d-flex flex-column pointer-event-on align-items-center`}
    >
      <SaveZoneWrapper
        activeBottom={false}
        activeTop={true}
        className={`d-flex flex-column ${fullpageStyle.innerContainer} ${
          !isMobile ? "overflow-hidden" : ""
        }`}
      >
        <div className="mt-2">
          <Header
            hasClose={props.onClose}
            searchText={searchText}
            setSearchText={setSearchText}
            isStart={props.searchAim === "start"}
            onRead={setQRCode}
            handleClose={handleClose}
            showKeyboard={setShowKeyboard}
          />
          <VirtualKeyboard
            searchText={searchText}
            isTerminal={props.isTerminal}
            showKeyboard={showKeyboard}
            setSearchText={setSearchText}
            setShowKeyboard={setShowKeyboard}
            language={props.language}
            toggleDisplayDownMode={props.toggleDisplayDownMode}
            displayDownMode={props.displayDownMode}
          />
          <ToastsContainer />
          {categories.length > 1 ? (
            <Filter
              categories={categories}
              selectedCategories={selectedCategories}
              resetCategories={() => setSelectedCategories([])}
              resetCampus={() => setInitialCampusFilter(true)}
              toggleCategory={toggleCategory}
              toggleCampus={toggleCampus}
              matchesCount={matches}
              selectedCampus={selectedCampuses}
            />
          ) : null}
        </div>
        <SaveZoneWrapper
          activeBottom={true}
          activeTop={false}
          className={`overflow-auto  ${
            props.isTerminal ? classes.keyBoardPadding : ""
          }`}
        >
          {
            /*FIXME: Ausnahme aus dem Ticket: https://app.clickup.com/t/86bxhnvrc -> sollte über Einträge der DB gelöst werden, wenn korrekt gepflegt */
            piKey !== "LHH-44sbHZhwah0o5cs7Go5QQSn2c89mR6VGImb9wT7N2smGUS" && (
              <SpecialLocation
                handleClick={handleCurrentLocationClick}
                show={
                  props.locationObject.locationType !== null &&
                  props.canSetCurrentPosition &&
                  !searchText.length
                }
                ariaLabel={intl.formatMessage({
                  id: "search.screenreader.currentLocation",
                })}
                icon={["fal", "location"]}
                title={<FormattedMessage id="gps.currentLocation" />}
                className={"mb-2 p-3"}
              />
            )
          }
          {!terminalNavigationLock ? (
            <SpecialLocation
              handleClick={handlePositionOnMapClick}
              show={props.canSetPositionOnMap && !searchText.length}
              title={<FormattedMessage id="search.setPositionOnMap" />}
              icon={["fal", "map-pin"]}
              className={"p-3"}
            />
          ) : null}
          <ScanQRButton />
          <ScanTextButton />
          {props.lastUsed[props.searchedCategory] &&
          props.lastUsed[props.searchedCategory].length !== 0 &&
          searchText.length === 0 &&
          !props.isTerminal ? (
            <LastUsed
              lastUsed={props.lastUsed[props.searchedCategory]}
              pois={filteredPOIs}
              resetLastUsed={resetLastUsed}
              selectedCampuses={selectedCampuses}
              searchItemClicked={handlePOIClick}
            />
          ) : null}
          {props.mostUsed[props.searchedCategory].length ? (
            <MostUsed
              mostUsed={props.mostUsed[props.searchedCategory]}
              pois={filteredPOIs}
              searchText={searchText}
              searchItemClicked={handlePOIClick}
            />
          ) : null}
          <List
            pois={filteredPOIs}
            categories={searchCategories}
            searchText={searchText}
            selectedCategories={selectedCategories}
            selectedCampuses={selectedCampuses}
            searchItemClicked={handlePOIClick}
            setQRCode={setQRCode}
            setMatches={setMatches}
            setCurrentAppOverlay={props.setCurrentAppOverlay}
            isTerminal={props.isTerminal}
            resetCategories={() => setSelectedCategories([])}
            resetCampus={() =>
              setSelectedCampuses(
                CAMPUS_COLLECTION.getAllCampuses().map((c) => c.id)
              )
            }
          />
        </SaveZoneWrapper>
        {qrOpen ? (
          <QrCodeReader
            history={props.history}
            readId={onReadId}
            canceled={() => setQrOpen(false)}
          />
        ) : null}
      </SaveZoneWrapper>
      <AskForAllowLocation
        userLocation={props.locationObject}
        curateCurrentLocation={curateCurrentLocation}
        setShow={setGPSModal}
        show={GPSModal}
        userAllowedGPS={props.userAllowedGPS}
        setProperties={props.setProperties}
      />
      {props.isTerminal && <WheelchairButton />}
      <ScannerInfo show={showScannerInfo} onClose={handleScanDoorSign} />
    </div>
  );
}

/**
 *
 * @param show
 * @param {UserLocation} userLocation
 * @param userAllowedGPS
 * @param setProperties
 * @param setShow
 * @param curateCurrentLocation
 * @return {JSX.Element|null}
 * @constructor
 */
const AskForAllowLocation = ({
  show,
  userLocation,
  userAllowedGPS,
  setProperties,
  setShow,
  curateCurrentLocation,
}) => {
  const intl = useIntl();
  const [showLoading, setShowLoading] = useState(false);
  useEffect(() => {
    if (userAllowedGPS && show) {
      if (userLocation.isSet) {
        setShowLoading(false);
        curateCurrentLocation();
        setShow(false);
      } else {
        setShowLoading(true);
      }
    }
    return () => {
      setShowLoading(false);
    };
  }, [userLocation, userAllowedGPS, show]);

  if (show) {
    return (
      <Modal centered={true} show={true} backdrop="static" keyboard={false}>
        <StandardCard>
          <StandardHeadline>Standort aktivieren</StandardHeadline>
          <StandardInstruction className={"mb-3"}>
            {intl.formatMessage({ id: "settings.locationText" })}
          </StandardInstruction>
          <ToggleSetting
            settingText={intl.formatMessage({
              id: "settings.locationToggleTitle",
            })}
            toggleValue={userAllowedGPS}
            outerClassName={"mt-3 mb-3"}
            onChange={(e) => {
              setProperties({
                location: { userAllowed: e.target.checked },
              });
            }}
          />
          <div className="d-flex flex-column align-items-center">
            <SearchingLocation show={showLoading} />
            <StandardButton handleClick={() => setShow(false)}>
              {intl.formatMessage({ id: "words.cancel" })}
            </StandardButton>
          </div>
        </StandardCard>
      </Modal>
    );
  } else return null;
};

const SpecialLocation = ({
  handleClick,
  icon,
  title,
  show,
  className = "",
  ariaLabel,
}) => {
  if (show) {
    return (
      <StandardCard
        className={"m-3 standardCard__search--specialLocation " + className}
        onClick={handleClick}
        ariaLabel={ariaLabel}
        role={"button"}
      >
        <div className="d-flex align-items-center">
          <RoundedIcon
            icon={icon}
            divClass={"roundedIcon__searchCard mr-3"}
            iconClass={"roundedIcon__searchCard__icon"}
          />
          <StandardHeadline className={"m-0"} text={title} type={"h3"} />
        </div>
      </StandardCard>
    );
  } else return null;
};

/**
 * Filtert beim ersten Aufruf alle POIs nach globalen Vorgaben.
 * Zum Beispiel, wenn man von der Routenansicht einen POI aussucht, muss er ja nen Standort haben.
 */
const useGlobalFilter = (props) => {
  const [filteredPOIs, setFilteredPOIs] = useState([]);

  useEffect(() => {
    const allPois = POIS.all.filter((p) => {
      if (p.id === props.start.poiId) return false;

      if (p.id === props.destination.poiId) return false;

      if (props.destination.lat && props.destination.lng) {
        return !(
          props.destination.lat === p.lat && props.destination.lng === p.lng
        );
      }

      if (props.start.lat && props.start.lng) {
        return !(props.start.lat === p.lat && props.start.lng === p.lng);
      }

      if (p.nodeId === props.start.nodeId) return false;
      if (p.nodeId === props.destination.nodeId) return false;

      return true;
    });
    const allPOIs = allPois.concat(
      NEWS,
      EVENTS_UNSORTED,
      PHONEBOOK_ENTRIES,
      QR_CODES
    );

    const filteredPOIs = allPOIs.filter((poi) => {
      if (props.mustHaveLocation) {
        return poi.hasLocation && poi.hasLocation();
      } else {
        return true;
      }
    });

    setFilteredPOIs(filteredPOIs);
  }, []);

  return filteredPOIs;
};

/**
 * Importet das Virtual Keyboard erst dann wenn es benötigt wird (im Terminal), ansonsten wird es nicht geladen
 * @param isTerminal
 * @return {*}
 */
const useVirtualKeyboard = (isTerminal, language) => {
  const [keyboard, setKeyboard] = useState(null);
  const [keyboardLayout, setKeyboardLayout] = useState({});
  useEffect(() => {
    if (!isTerminal) return;

    import("react-simple-keyboard").then((module) => {
      setKeyboard(module);
    });

    // Standardmäßig wird das Keyboard in Englisch geladen
    // andere sprachen können nachgeladen werden
    if (language.includes("de")) {
      import("simple-keyboard-layouts/build/layouts/german.js").then(
        (module) => {
          setKeyboardLayout(module);
        }
      );
    } else {
      import("simple-keyboard-layouts/build/layouts/english.js").then(
        (module) => {
          setKeyboardLayout(module);
        }
      );
    }
  }, [isTerminal, language]);

  if (keyboard) {
    return {
      VirtualKeyboard: keyboard.default,
      VirtualKeyboardLayout: keyboardLayout.default,
    };
  } else
    return {
      VirtualKeyboard: null,
      VirtualKeyboardLayout: null,
    };
};

function VirtualKeyboard({
  searchText,
  isTerminal,
  setSearchText,
  language,
  showKeyboard,
  setShowKeyboard,
}) {
  const [layoutName, setLayoutName] = useState("default");
  const { formatMessage } = useIntl();

  const handleInput = (input) => {
    setSearchText(searchText + input);
  };
  const handleDelete = () => {
    const str = searchText.slice(0, -1);
    setSearchText(str);
  };

  const handleSpace = () => {
    const str = searchText + " ";
    setSearchText(str);
  };

  const handleKeyPress = (button) => {
    // If you want to handle the shift and caps lock buttons
    if (button === "{shift}" || button === "{lock}") handleShift();

    if (button === "{bksp}") handleDelete();

    switch (button) {
      case "{shift}":
      case "{lock}":
        handleShift();
        break;
      case "{space}":
        handleSpace();
        break;
      case "{bksp}":
        handleDelete();
        break;
      default:
        if (button.length === 1) handleInput(button);
    }
  };
  const hideKeyboard = (hide) => {
    setShowKeyboard(!hide);
  };

  const handleShift = () => {
    setLayoutName((prevState) =>
      prevState === "default" ? "shift" : "default"
    );
  };

  const { VirtualKeyboard, VirtualKeyboardLayout } = useVirtualKeyboard(
    isTerminal,
    language
  );

  return VirtualKeyboard != null && showKeyboard ? (
    <div className={`${classes.virtualKeyboard} p-3 w-100 `}>
      <StandardButton
        icon={faTimes}
        handleClick={() => hideKeyboard(true)}
        buttonClasses="ml-auto mb-2"
      >
        {formatMessage({ id: "keyboard.hide" })}
      </StandardButton>
      <VirtualKeyboard
        {...VirtualKeyboardLayout}
        onKeyPress={handleKeyPress}
        layoutName={layoutName}
        theme={
          "hg-theme-default hg-layout-default custom-dark-Keyboard shadow-1 backdrop-gray"
        }
        layout={{
          default: [
            "1 2 3 4 5 6 7 8 9 0 - {bksp}",
            "q w e r t z u i o p",
            "a s d f g h j k l",
            "y x c v b n m , . / {shift}",
            "{space}",
          ],
          // Guess this is not needed anymore, but doesn't hurt anyway...
          shift: [
            "~ ! @ # $ % ^ &amp; * ( ) _ + {bksp}",
            "Q W E R T Z U I O P { } |",
            'A S D F G H J K L : @ " {enter}',
            "Y X C V B N M &lt; &gt; ? {shift}",
            "{space}",
          ],
        }}
        mergeDisplay={true}
        display={{
          "{bksp}": formatMessage({ id: "keyboard.backspace" }),
          "{enter}": formatMessage({ id: "keyboard.enter" }),
        }}
      />
    </div>
  ) : null;
}

const mapStateToProps = (state) => {
  return {
    dataScannerAvailable: state.main.dataScannerAvailable,
    categories: state.search.categoriesEntries,
    searchedCategory: state.search.searchedCategory,
    // mustHaveLocation: state.search.mustHaveLocation,
    entries: state.search.entries,
    mostUsed: state.search.mostUsed,
    lastUsed: state.search.lastUsed,
    // Aim ist hier eine Anweisung für das Search Component, welches Ziel der User hier verfolgt
    searchAim: state.search.target,
    start: state.route.start,
    destination: state.route.target,
    locationObject: state.route.locationObject,
    filterCategories: state.settings.filter.search.categories,
    settings: state.settings.filter,
    lastMainPage: state.main.lastPage,
    currentMapPage: state.map.currentPage,
    userAllowedGPS: state.settings.location.userAllowed,
    permissionAllowedGPS: state.settings.location.permissionAllowed,
    terminalIsIdle: state.main.terminalIsIdle,
    isTerminal: state.main.isTerminal,
    isApp: state.main.isApp,
    language: state.main.language,
    displayDownMode: state.main.displayDownMode,
    developerMode: state.settings.developerMode,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLastSearch: (type, id) => dispatch(action.setLastSearch(type, id)),
    resetLastUsed: (type) => dispatch(action.resetLastUsed(type)),
    setCurrentFeature: (feature) =>
      dispatch(actionMain.setCurrentFeature(feature)),
    setPreOpen: () => dispatch(actionInfo.setPreOpen()),
    setMustHaveLocation: (value) => dispatch(action.setMustHaveLocation(value)),
    setStatus: (status) => dispatch(actionInfoCard.setStatus(status)),
    setStart: (gpsObject) => dispatch(actionRoute.setStart(gpsObject)),
    setTarget: (gpsObject) => dispatch(actionRoute.setTarget(gpsObject)),
    setFetchNewRoute: (status) =>
      dispatch(routeAction.setFetchNewRoute(status)),
    setAppointmentPOI: (poi) =>
      dispatch(appointmentAction.setAppointmentPOI(poi)),
    setHideCreateAppointment: (value) =>
      dispatch(appointmentAction.setHideCreateAppointment(value)),
    setProperties: (properties) =>
      dispatch(settingsAction.setProperties(properties)),
    setCurrentAppOverlay: (appOverlay) =>
      dispatch(actionMain.setCurrentAppOverlay(appOverlay)),
    toggleDisplayDownMode: (value) =>
      dispatch(actionMain.toggleDisplayDownMode(value)),
  };
};

Search.propTypes = {
  // Falls Search im SplashScreen aufgerufen wird, muss der Splashscreen wissen, dass es vorbei ist!
  // das handleClose hier wird also vom SplashScreen übergeben
  onClose: PropTypes.func,
  onPOIClicked: PropTypes.func,
  onSpecialLocationClicked: PropTypes.func,
  mustHaveLocation: PropTypes.bool,
  canSetPositionOnMap: PropTypes.bool,
  canSetCurrentPosition: PropTypes.bool,
  type: PropTypes.any,
};
Search.defaultProps = {
  mustHaveLocation: false,
  canSetPositionOnMap: false,
  canSetCurrentPosition: false,
  type: null,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search));

export function includeText(item, fuzzy, searchText) {
  if (fuzzy) {
    const fuse = new Fuse([item], options);
    return fuse.search(searchText).length;
  }
  const normalizedSearchString = item.name
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

  const words = searchText
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .split(" ");

  const neededMatches = words.length;
  let matches = 0;
  words.forEach((word) => {
    if (normalizedSearchString.includes(word)) {
      matches++;
    }
  });

  return neededMatches === matches;
}
