import RouteItem from "./RouteItem.class";

export default class Step extends RouteItem {
  constructor(props) {
    super(props.distance, props.duration);
    const instructions = props.bannerInstructions.length
      ? props.bannerInstructions[0].primary
      : { text: null, modifier: null, type: null };
    this.bannerInstructions = props.bannerInstructions;
    this.instructionsText = instructions.text;
    this.instructionsModifier = instructions.modifier;
    this.instructionsType = instructions.type;
    this.isOnCampus = props.isOnCampus;
    this.drivingSide = props.driving_side;
    this.mode = props.mode;
    // [lng, lat][]
    this.stepLine = this.decode(props.geometry);
    this.node = this.stepLine[0];
    this.voiceInstruction = props.voiceInstructions[0]?.announcement ?? "";
    this.voiceInstructions = props.voiceInstructions;
    this.voiceInstruction_sbs =
      props.voiceInstruction_sbs ?? props.voiceInstructions[0];
    this.intersections = props.intersections;
    this.geometry = props.geometry;
    this.maneuver = props.maneuver;
  }

  getIconName() {
    switch (this.instructionsModifier) {
      case "slight left": {
        return "navigationArrowSlightlyLeft";
      }
      case "slight right": {
        return "navigationArrowSlightlyRight";
      }
      case "left": {
        return "navigationArrowLeft";
      }
      case "right": {
        return "navigationArrowRight";
      }
      case "sharp left": {
        return "navigationArrowSharplyLeft";
      }
      case "sharp right": {
        return "navigationArrowSharplyRight";
      }
      case "straight": {
        return "navigationArrowStraight";
      }
      case "uturn": {
        return "navigationArrowTurnAround";
      }
    }
  }
}
