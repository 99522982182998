import * as types from "../types.variables.js";

export function setSettingsFromStorage(settings) {
  return {
    payload: settings,
    type: types.SETTINGS_SET_SETTINGS_FROM_STORAGE,
  };
}

export function toggleRoutingSetting(type) {
  return {
    type: types.SETTING_TOGGLE_ROUTING_SETTING,
    payload: type,
  };
}

export function setProperty(name, value) {
  return {
    type: types.SETTING_SET_VALUE,
    payload: {
      name,
      value,
    },
  };
}

export function setCalendarAuthorized(value) {
  return {
    type: types.SETTING_SET_CALENDAR_AUTHORIZED,
    payload: value,
  };
}
export function setCalendarAllowed(value) {
  return {
    type: types.SETTING_SET_CALENDAR_ALLOWED,
    payload: value,
  };
}
export function setCalendars(value = []) {
  return {
    type: types.SETTING_SET_CALENDARS,
    payload: value,
  };
}
export function setPreferredCalendar(value) {
  return {
    type: types.SETTING_SET_PREFERRED_CALENDAR,
    payload: value,
  };
}

/**
 *
 * @param {settingObjekt} properties - Setting Objekt mit dem Key
 * @return {{payload: *, type: string}}
 */
export function setProperties(properties) {
  return {
    type: types.SETTING_SET_PROPERTIES,
    payload: properties,
  };
}

export function updateFilter(filter) {
  return {
    type: types.SETTING_SET_PROPERTIES,
    payload: { filter },
  };
}

export function setSaveZonePadding({ top, bottom }) {
  return {
    type: types.SETTING_SET_SAVE_ZONE_PADDING,
    payload: { top, bottom },
  };
}

export function setUserAllowedPush(value) {
  return {
    type: types.SETTINGS_SET_USER_ALLOWED_PUSH,
    payload: value,
  };
}

export function setPushPermissionAllowed(value) {
  return {
    type: types.SETTINGS_SET_PUSH_PERMISSION_ALLOWED,
    payload: value,
  };
}

export function setSettingsSet(value) {
  return {
    type: types.SETTINGS_SET_SET,
    payload: value,
  };
}
