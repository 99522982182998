import React from "react";

export default function ExitSVG() {
  return (
    <svg
      width="390px"
      height="390px"
      viewBox="0 0 390 390"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Screen / illustration / Gebaeude-raus</title>
      <g
        id="Screen-/-illustration-/-Gebaeude-raus"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M55,69 L335,69 C346.045695,69 355,77.954305 355,89 L355,369 C355,380.045695 346.045695,389 335,389 L55,389 C43.954305,389 35,380.045695 35,369 L35,89 C35,77.954305 43.954305,69 55,69 Z"
          id="Rectangle"
          fill="#F0F3F5"
        ></path>
        <g
          id="undraw_Login_re_4vu2"
          transform="translate(4.774503, 49.574063)"
          fillRule="nonzero"
        >
          <path
            d="M241.417711,245.213873 C236.106602,240.726028 232.88055,233.636556 233.726583,226.734899 C234.572616,219.833242 239.812662,213.488514 246.60977,212.022905 C253.406877,210.557296 261.155116,214.614318 263.219621,221.254081 C264.355815,208.45288 265.665071,195.115325 272.471009,184.213908 C278.633616,174.342943 289.307365,167.279878 300.875302,166.016308 C312.443239,164.752737 324.622785,169.525117 331.837282,178.655548 C339.051779,187.785978 340.818221,201.059719 335.765374,211.542206 C332.043116,219.264286 325.185432,225.011157 318.000246,229.68653 C294.78239,244.667061 266.935599,250.759026 239.583478,246.841547 L241.417711,245.213873 Z"
            id="Path"
            fill="#FFF9EE"
          ></path>
          <path
            d="M320.397951,169.789663 C310.852974,177.564172 302.096462,186.259365 294.255071,195.749473 C281.983328,210.550602 272.052905,227.145369 264.809759,244.955645 C264.279522,246.254935 266.389369,246.820533 266.913684,245.535672 C278.764745,216.590257 297.683889,191.078072 321.940764,171.332471 C323.030024,170.445493 321.478237,168.909999 320.397951,169.789663 L320.397951,169.789663 Z"
            id="Path"
            fill="#DCD5C9"
          ></path>
          <path
            d="M221.585127,247.475657 L47.5415526,247.475657 L47.5415526,68.9021821 C47.5415526,30.9093138 78.4508664,0 116.44362,0 L152.683064,0 C190.675818,0 221.585127,30.9093138 221.585127,68.9021821 L221.585127,247.475657 Z"
            id="Path"
            fill="#F2F2F2"
          ></path>
          <path
            d="M185.34615,247.475657 L47.541319,247.475657 L47.541319,68.9021821 C47.5035138,54.3441948 52.1134143,40.1541285 60.7000622,28.3980272 C61.0165878,27.9665994 61.3323029,27.5407553 61.6579122,27.1195698 C65.8112087,21.6722711 70.7537711,16.8749232 76.3224661,12.8858667 C76.6370177,12.6569875 76.9528376,12.4320851 77.27334,12.2071731 C81.9330395,8.9825588 86.973591,6.34650084 92.2802639,4.35899999 C92.5953972,4.23955841 92.9162334,4.11988796 93.2370696,4.00510026 C98.0364622,2.29235662 103.011582,1.11913241 108.070541,0.507096218 C108.384163,0.464252889 108.706158,0.430726852 109.028162,0.397200814 C113.958137,-0.129922331 118.930262,-0.129922331 123.860237,0.397200814 C124.181307,0.430726852 124.503311,0.464252889 124.820652,0.507558745 C129.87831,1.11963504 134.852136,2.29270936 139.65028,4.00511934 C139.971236,4.11990703 140.292096,4.23957749 140.608135,4.35925271 C145.849341,6.32168218 150.830576,8.91840453 155.440677,12.0914699 C155.760931,12.3114943 156.081539,12.5364063 156.397368,12.7613135 C159.518763,14.9894271 162.450692,17.471677 165.163223,20.1827223 C167.338482,22.3572742 169.365489,24.6752308 171.230606,27.1209956 C171.555166,27.5407839 171.870881,27.966628 172.18683,28.3973596 C180.773804,40.153582 185.383914,54.3439048 185.34615,68.9021821 L185.34615,247.475657 Z"
            id="Path"
            fill="#BDC6D7"
          ></path>
          <circle
            id="Oval"
            fill="#919EB5"
            cx="174.140357"
            cy="159.977039"
            r="7.62930736"
          ></circle>
          <path
            d="M324.378923,247.78247 L0.520791341,247.78247 C0.23292241,247.781563 0,247.527636 0,247.214714 C0,246.901791 0.23292241,246.647864 0.520791341,246.646957 L324.378923,246.646957 C324.666792,246.647864 324.899714,246.901791 324.899714,247.214714 C324.899714,247.527636 324.666792,247.781563 324.378923,247.78247 L324.378923,247.78247 Z"
            id="Path"
            fill="#394854"
          ></path>
          <path
            d="M147.437796,195.739462 L85.4496735,195.739462 C83.7498845,195.748567 82.3634093,194.380156 82.3502674,192.680394 L82.3502674,54.318413 C82.3634093,52.6186502 83.7498845,51.2502396 85.4496735,51.2593441 L147.437796,51.2593441 C149.137585,51.2502396 150.52406,52.6186502 150.537202,54.318413 L150.537202,192.680394 C150.52406,194.380156 149.137585,195.748567 147.437796,195.739462 Z"
            id="Path"
            fill="#FFFFFF"
          ></path>
          <rect
            id="Rectangle"
            fill="#CCCCCC"
            x="82.3502531"
            y="96.0289711"
            width="68.1869345"
            height="1"
          ></rect>
          <rect
            id="Rectangle"
            fill="#CCCCCC"
            x="82.5886689"
            y="150.440404"
            width="68.1869345"
            height="1"
          ></rect>
          <rect
            id="Rectangle"
            fill="#CCCCCC"
            x="103.80768"
            y="51.2594088"
            width="1"
            height="144.95684"
          ></rect>
          <rect
            id="Rectangle"
            fill="#CCCCCC"
            x="127.649266"
            y="51.2594088"
            width="1"
            height="144.95684"
          ></rect>
        </g>
        <g
          id="undraw_sweet_home_dkhr"
          transform="translate(198.920814, 112.574063)"
          fillRule="nonzero"
        >
          <path
            d="M90.2978701,92.9277849 C90.6974456,91.3710633 90.3869705,89.71731 89.4499869,88.4115136 C88.5130032,87.1057172 87.0457597,86.2820114 85.4430591,86.1620375 L54.5474759,47.5349263 L46.9029616,54.6601599 L79.5405711,91.5579604 C79.5169778,94.3244946 81.5663221,96.6714456 84.3107959,97.0209194 C87.0552697,97.3703932 89.6273867,95.6119265 90.2978701,92.9277654 L90.2978701,92.9277849 Z"
            id="Path"
            fill="#FFB8B8"
          ></path>
          <path
            d="M60.4744317,53.6709524 L50.710436,61.1484536 C50.1482763,61.5789561 49.4265719,61.7434464 48.7333844,61.5990615 C48.0401969,61.4546766 47.4441856,61.0157178 47.1006591,60.3965688 L41.1528158,49.6747602 C38.904026,46.7024422 39.4841093,42.4709526 42.449837,40.2134785 C45.4155647,37.9560044 49.6487329,38.5237082 51.9148721,41.4828201 L60.6893977,49.9899516 C61.1976077,50.4829848 61.4660439,51.1728111 61.4247563,51.8796735 C61.3834687,52.5865359 61.0365468,53.2404194 60.4743928,53.670933 L60.4744317,53.6709524 Z"
            id="Path"
            fill="#005192"
          ></path>
          <circle
            id="Oval"
            fill="#FFB8B8"
            cx="44.076795"
            cy="17.9359102"
            r="12.4162224"
          ></circle>
          <polygon
            id="Path"
            fill="#FFB8B8"
            points="70.5805673 178.992043 76.7787406 178.992043 76.1882067 167.392479 67.0412557 167.392479"
          ></polygon>
          <path
            d="M68.9998726,176.968876 L81.2051761,176.96838 L81.2056723,176.96838 C83.2685868,176.96838 85.2470273,177.787782 86.7057409,179.24647 C88.1644545,180.705158 88.9839274,182.683584 88.9839274,184.746499 L88.9839274,184.999261 L69.0002423,185 L68.9998726,176.968876 Z"
            id="Path"
            fill="#394854"
          ></path>
          <polygon
            id="Path"
            fill="#FFB8B8"
            points="4.50206666 170.217702 9.77503781 173.474881 15.369057 163.295711 8.9889565 159.304014"
          ></polygon>
          <path
            d="M4.22055616,167.665175 L14.6045054,174.079463 L14.6049237,174.079725 C16.3599946,175.163868 17.6124908,176.900825 18.0868618,178.908463 C18.5612327,180.916101 18.218618,183.029955 17.1343933,184.784976 L17.0015572,184.999981 L0,174.497818 L4.22055616,167.665175 Z"
            id="Path"
            fill="#394854"
          ></path>
          <path
            d="M70.1327053,175.591346 C69.1893198,175.591346 68.3445626,175.006942 68.010636,174.124633 L45.5925018,120.525592 C45.4941544,120.258672 45.2547233,120.069221 44.9723413,120.034887 C44.6899593,120.000553 44.4121057,120.127108 44.2526613,120.362684 L13.4544821,168.878786 C12.8317969,169.812438 11.619614,170.149822 10.6041104,169.672125 L6.38995572,167.705316 C5.2673198,167.175998 4.77281295,165.847077 5.27634702,164.712642 L30.5083117,108.468486 C29.9574906,107.128149 24.5835644,93.3654123 32.8402881,86.5659938 L32.8980476,86.5299975 L55.2874528,76.5428929 L55.8519817,93.7179328 L78.168042,171.146767 C78.3378048,171.735376 78.2629788,172.367577 77.9604985,172.900293 C77.6580182,173.433009 77.1534558,173.821202 76.5610019,173.977019 L70.7156118,175.515316 C70.5253997,175.565682 70.3294726,175.591346 70.1327053,175.591346 L70.1327053,175.591346 Z"
            id="Path"
            fill="#394854"
          ></path>
          <path
            d="M55.626246,51.4195981 C54.7736591,47.9979554 53.5651369,44.5490918 51.9456343,42.9568588 C51.3501686,42.3762713 50.7905792,41.7599917 50.2699678,41.1114356 C49.180659,39.7392323 47.8101332,38.6162057 46.2505725,37.8178912 C46.8212598,37.5003649 47.1557238,37.1216255 47.1557238,36.7140015 C47.1557238,35.5972115 44.666142,34.6918948 41.5949476,34.6918948 C38.5237532,34.6918948 36.0341617,35.5972115 36.0341617,36.7140015 C36.0493855,36.9759861 36.1683238,37.2211199 36.3646856,37.3952168 C32.2767537,39.0615641 29.4562829,42.8648969 29.0487592,47.2605567 C28.3305746,54.9525666 28.1208914,64.784858 30.6379081,71.0041426 C32.7491121,76.1470313 32.9169947,81.8823859 31.1102282,87.1399597 C30.8600951,87.8875759 31.0679241,88.7124004 31.6424514,89.252229 C32.2169788,89.7920576 33.0531356,89.948167 33.7837413,89.6520051 L55.9999557,80.6994729 C56.7651316,80.3911457 57.2662689,79.6488901 57.2662373,78.8239296 L57.2662373,65.2267368 C57.2840471,60.5747353 56.7333014,55.9379898 55.626246,51.4195981 L55.626246,51.4195981 Z"
            id="Path"
            fill="#005192"
          ></path>
          <path
            d="M13.1190647,107.422112 C14.68459,107.058544 16.0086679,106.020215 16.7350125,104.586522 C17.4613571,103.152829 17.5153554,101.471047 16.8824776,99.9937121 L34.2647978,54.1546357 L25.4499758,51.8787746 L9.37215168,97.245784 C6.90673509,98.5010643 5.76980739,101.401925 6.72572364,103.99812 C7.68163989,106.594316 10.4282603,108.065258 13.1190355,107.422035 L13.1190647,107.422112 Z"
            id="Path"
            fill="#FFB8B8"
          ></path>
          <path
            d="M34.1923382,62.8524697 L23.0539163,57.6386979 C22.4126439,57.3384974 21.9337939,56.7740431 21.7421389,56.0924135 C21.550484,55.4107838 21.6650065,54.67949 22.0558655,54.0890835 L28.8251151,43.8659891 C30.4274474,40.5041164 34.4463311,39.0704839 37.8146584,40.6592026 C41.1829858,42.2479214 42.6328649,46.2609725 41.0577856,49.6356994 L37.5574569,61.3451977 C37.3544503,62.0235398 36.8662337,62.5799239 36.2200273,62.8693665 C35.5738209,63.1588091 34.8336313,63.1526411 34.1923382,62.8524697 L34.1923382,62.8524697 Z"
            id="Path"
            fill="#005192"
          ></path>
          <path
            d="M55.481609,14.8798829 C56.0417547,14.9462067 56.6089435,14.8436903 57.1104317,14.5854819 C58.026304,13.9861046 57.9360701,12.6236934 57.6262198,11.5738954 C56.0567266,6.45976972 51.9870331,2.49947411 46.8320489,1.06988392 C45.0643389,0.600103054 42.8277916,0.618256863 41.8165875,2.14231091 C36.5653085,-1.35585773 29.5462328,-0.493717311 25.2976239,4.17130546 C23.2884496,6.5286682 21.9490755,9.38225098 21.4193828,12.4340318 C20.3584065,17.6319653 15.8148079,28.3542505 18.331708,33.0243129 C20.006567,36.1319705 27.7144251,33.7106358 30.9748627,35.0642133 C34.2727672,36.4988958 38.0692134,36.1924307 41.0942878,34.2473308 C44.0872629,32.181997 45.3768176,28.4018071 44.2700562,24.93791 C43.6993609,23.6978246 43.0412436,22.4998717 42.3006793,21.3531068 C41.557242,20.1925101 41.2539309,18.8040802 41.4456603,17.4391905 C41.9020836,15.3627879 51.7020756,14.8020531 55.481609,14.8798829 L55.481609,14.8798829 Z"
            id="Path"
            fill="#394854"
          ></path>
        </g>
      </g>
    </svg>
  );
}
