import * as types from "../types.variables.js";
import { nativeStorageInterface } from "../..";

const initState = {
  routing: {
    userGroup: "",
    disabledParkingPermit: false,
    movementRestricted: false,
    visualImpaired: false,
    supplier: false,
    employee: false,
  },
  useSound: { value: true },
  meta: {
    loggingServerSessionId: null,
  },
  dataProtection: {
    dp1: false,
    dp2: false,
    dp3: false,
    dp4: false,
  },
  // wird durch die URL bedient, ist für Terminal, weil dort nicht rotiert werden darf!
  mapAngle: { isFrozen: false, angle: null },
  filter: {
    phonebook: {
      campusId: 0,
    },
    event: {
      campusId: 0,
    },
    search: {
      campusId: 0,
      categories: [],
    },
  },
  calendar: {
    syncWithCalendarAuthorized: null,
    syncWithCalendarAllowed: false,
    calendars: [],
    preferredCalendar: null,
  },
  pushMessages: {
    userAllowed: null,
    permissionAllowed: null,
  },
  location: {
    userAllowed: null,
    permissionAllowed: null,
  },
  event: {
    patientsInterested: true,
    professionalAudience: true,
  },
  healthStats: {
    userAllowed: null,
    permissionAllowed: null,
  },
  saveZonePadding: {
    top: 0,
    bottom: 0,
  },
  campus: {
    lastCampusId: null,
  },
  debug: {
    developerMode: false,
    showBeacons: false,
  },
  cookieSettingsReceived: false,
  loggedInUser: {
    accountName: null,
    chosenPiKey: null,
  },
  settingsSet: null,
};

export default function settings(state = initState, action) {
  switch (action.type) {
    case types.SETTING_SET_SAVE_ZONE_PADDING: {
      return {
        ...initState,
        saveZonePadding: {
          top: action.payload.top,
          bottom: action.payload.bottom,
        },
      };
    }
    case types.SETTINGS_SET_SETTINGS_FROM_STORAGE: {
      const settings = {
        ...state,
        ...action.payload,
        filter: action.payload.filter ?? state.filter,
        // savezonepaddingdarf nicht übernommen werden aus storage!
        saveZonePadding: state.saveZonePadding,
        cookieSettingsReceived: true,
      };
      return settings;
    }
    case types.SETTINGS_SET_PUSH_PERMISSION_ALLOWED:
      return {
        ...state,
        pushMessages: {
          ...state.pushMessages,
          permissionAllowed: action.payload,
        },
      };
    case types.SETTINGS_SET_USER_ALLOWED_PUSH:
      return {
        ...state,
        pushMessages: { ...state.pushMessages, userAllowed: action.payload },
      };
    case types.SETTING_SET_VALUE: {
      const settings = {
        ...state,
        routing: {
          ...state.routing,
          [action.payload.name]: action.payload.value,
        },
      };

      if (state.cookieSettingsReceived) {
        nativeStorageInterface.set("settings", settings);
      }
      return settings;
    }

    case types.SETTING_SET_PROPERTIES: {
      let settingKey = null;
      let settings = {};

      for (let key in state) {
        // schau ob dieses Setting Objekt bereits vorhanden ist
        if (action.payload[key] != null) {
          settingKey = key;
        }
      }

      if (settingKey) {
        // wichtig, wenn zum beispiel ein Setting mehrere key/value pairs hat (wie z.B. Location)
        // man aber nur eines davon übergibt
        // -> objekt merge
        settings = {
          ...state,
          [settingKey]: {
            ...state[settingKey],
            ...action.payload[settingKey],
          },
        };
      } else {
        // wenn nicht
        // -> füge übergebenes Setting Objekt einfach zu den anderen Settings hinzu
        settings = {
          ...state,
          ...action.payload,
        };
      }

      if (state.cookieSettingsReceived) {
        nativeStorageInterface.set("settings", settings);
      }

      return settings;
    }

    default: {
      return state;
    }
  }
}
