/**
 * Der Grund wieso dieser EventHandler für die Clicks auf der Map verwendet wird, ist weil
 * der die Normale Mapbox API jeden Layer klickt und nicht nur den obersten!
 * Liegen also zwei Layer übereinander (Point über Polygon) und man klickt auf den Point, so
 * feuert mapbox einen klick auf dem Point und dann sofort auf dem Polygon. Bescheuert und man kanns nicht aufhalten.
 */
export class LayerEventHandler {
  constructor(map) {
    this.map = map;

    this.handlers = {};
    this.defaultHandlers = {};

    this._onMapEvent = this._onMapEvent.bind(this);
  }

  getHandleLayers(eventName) {
    if (!this.handlers[eventName]) {
      return [];
    }
    return Object.keys(this.handlers[eventName]);
  }

  on(eventName, layerId, callback) {
    const letsGo = (lId) => {
      if (!this.defaultHandlers[eventName] && !this.handlers[eventName]) {
        // Create new event name keys in our storage maps
        this.defaultHandlers[eventName] = [];
        this.handlers[eventName] = {};

        // Register a map event for the given event name
        this.map.on(eventName, this._onMapEvent);
      }

      if (!lId) {
        // lId is not specified, so this is a 'default handler' that will be called if no other events have cancelled the event
        this.defaultHandlers[eventName].push(callback);
      } else {
        // lId is specified, so this is a specific handler for that layer
        this.handlers[eventName][lId] = callback;
      }
    };

    if (typeof layerId === "object") {
      layerId.forEach((l) => {
        letsGo(l);
      });
    } else letsGo(layerId);
  }

  off(eventName, layerId) {
    const letsGo = (lId) => {
      if (this.handlers[eventName] && this.handlers[eventName][lId]) {
        this.handlers[eventName][lId] = null;
        delete this.handlers[eventName][lId];
      }
    };
    if (typeof layerId === "object") {
      layerId.forEach((l) => {
        letsGo(l);
      });
    } else letsGo(layerId);
  }

  _onMapEvent(event) {
    let layers = this.getHandleLayers(event.type); //unordered list of layers to be checked

    let eventName = event.type;

    // This gets the features that was clicked in the correct layer order
    let eventFeatures = this.map.queryRenderedFeatures(event.point, {
      layers: layers,
    });

    // This makes a sorted array of the layers that are clicked
    let sortedLayers = eventFeatures.reduce((sorted, next) => {
      let nextLayerId = next.layer.id;
      if (sorted.indexOf(nextLayerId) === -1) {
        return sorted.concat([nextLayerId]);
      }
      return sorted;
    }, []);

    // Add the layers and features info to the event
    event.eventLayers = sortedLayers;
    event.eventFeatures = eventFeatures;

    let bubbleEvent = true;

    // Loop through each of the sorted layers starting with the first (top-most clicked layer)
    // Call the handler for each layer in order, and potentially stop propagating the event
    for (let i = 0; i < sortedLayers.length; i++) {
      if (bubbleEvent !== true) {
        break;
      }
      let layerId = sortedLayers[i];
      // Filter out only the features for this layer
      let layerFeatures = eventFeatures.filter((feature) => {
        return feature.layer.id === layerId;
      });

      // Call the layer handler for this layer, giving the clicked features
      bubbleEvent = this.handlers[eventName][layerId](event, layerFeatures);
    }

    if (bubbleEvent === true) {
      // No events has cancelled the bubbling
      if (!this.defaultHandlers[eventName]) {
        return;
      }

      this.defaultHandlers[eventName].forEach((handler) => {
        handler(event);
      });
    }
  }
}
