import { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

function SetURLParams(props) {
  useEffect(() => {
    const start = getDataForURL(props.start);
    const destination = getDataForURL(props.target);

    const pathname = props.location.pathname;
    if (
      (null === start && null === destination) ||
      pathname.includes("map/info") ||
      !props.introComplete
    )
      return;

    const globalParams = new URLSearchParams(props.location.search);

    if (start) {
      globalParams.set("start", start);
    } else {
      globalParams.delete("start");
    }

    if (destination) {
      globalParams.set("destination", destination);
    } else {
      globalParams.delete("destination");
    }
    const params = ("?" + globalParams.toString()).replace("??", "?");
    props.history.push(pathname + params);
  }, [props.target, props.start]);

  useEffect(() => {
    const globalParams = new URLSearchParams(props.location.search);

    const pathname = props.location.pathname;

    if (
      !props.introComplete ||
      !pathname.includes("/map/info") ||
      !props.urlParamsHandled
    )
      return;

    if (!props.currentFeature) {
      globalParams.delete("campusKeys");
      globalParams.delete("destination");
    } else {
      globalParams.set("destination", props.currentFeature.id);
      globalParams.set(
        "campusKeys",
        props.campusKeys.length
          ? props.campusKeys
          : globalParams.get("campusKeys").split(",")
      );
    }

    const params = ("?" + globalParams.toString()).replace("??", "?");
    props.history.push(`/map/info/${params}`);
  }, [props.currentFeature]);

  return null;
}

const mapStateToProps = (state) => {
  return {
    currentFeature: state.main.currentFeature,
    start: state.route.start,
    target: state.route.target,
    campusKeys: state.route.relevantCampusKeys,
    introComplete: state.main.introComplete,
    urlParamsHandled: state.route.urlParamsHandled,
  };
};

export default withRouter(connect(mapStateToProps)(SetURLParams));

function getDataForURL(point) {
  if (point.poiId) {
    return point.poiId;
  }
  if (!point.lat) return null;
  return [point.lat, point.lng, point.level];
}
