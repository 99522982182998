export default class UploadItem {
  constructor(name, url, post) {
    this.name = name;
    this.timestamp = new Date().getTime();
    this.url = url;
    this.post = post;
    this.timesFailed = 0;
    this.shouldRetry = true;
  }
  incrementTimesFailed() {
    this.timesFailed++;
  }
}
