import * as types from "../types.variables.js";
import Polygon from "../../functions/classes/polygon.class";
import GlobalPOI, {
  addRelationToPOI,
  poitypes,
} from "../../functions/classes/poi.class";
import { indicatorLog } from "../../functions/helper/helpers.functions";
import Node from "../../functions/classes/node.class";
import CampusCollection, {
  Campus,
} from "../../functions/classes/campus.class.js";
import axios from "axios";
import { AppOverlay } from "../../functions/classes/miscelleanous.classes.js";
import {
  piKey,
  setPiKey,
  useSavedData,
} from "../../app/app.sideEffects/useURLParams.sideEffects";
import { isApp } from "../reducers/main";
import { isAndroid, isIOS } from "react-device-detect";
import LocationManager from "@catchupapplications/indoor-navigation/dist/models/LocationManager";
import { setProduktinstanzModuleToNative } from "../../components/nativeAppInterface/NativeAppInterface.jsx";
import { setQueryParams } from "../../app/RouteWithSubRoutes.jsx";
import { nativeStorageInterface } from "../../index";
import { map } from "../../components/mapUi/mapbox/map.sideEffects/useMap.sideEffects.js";

/**
 * Nodes, die explizit für Mapbox verwendet werden
 */
export let POI_NODES = [];
// todo: irgendwann alle POI_Nodes durch das object ersetzen (was das selbe enthält aber schneller zu iterieren ist)
export let POI_NODES_Object = {};
/** Diese Nodes sind absolut RAW! */
export let CAMPUS_NODES = {};

export let CONTENT = [];

export let CAMPUSES = [];

export let NAVI_PRIORITY_LIST = [];

export let MODULES = {};

export let APP_SETTINGS = {};

export let BEACONS = [];

export let SORT_ORDER = [];
/** @type {LocationManager} */
export let LOCATION_MANAGER = null;
export const resetLocationManager = () => {
  LOCATION_MANAGER = new LocationManager(CONTENT, BEACONS, isAndroid);
};

/**
 * Polygons, die explizit für Mapbox verwendet werden
 */
export const POI_POLYGONS = [];

/**
 * Einzelne POIS ohne Duplikate
 * Wenn ein POI eine Entity als Node und als Polygon hat, dann wird nur das Node für diese Collection genommen!
 */
export const POIS = {
  // hier sind wirklich alle POIS aus dem Content drin (außer Telefonbuch, news, events)
  /**@type [GlobalPOI]*/
  all: [],
  all_objects: {},
  // hier
  facilities: [],
  buildings: [],
  addresses: [],
  floors: [],
  rooms: [],
  pois: [],
  parkinglots: [],
  /**@type GlobalPOI[]*/
  campuses: [],
  miscelleanous: [],
};
export let MISC_INFO = {};
export let MAPBOX_ACCESSTOKEN;

/**
 * GEOJSONS werden in App.js in einem sideeffect aufgerufen
 * @type {{[buildingId]: {buildingModel.glb: data, hallways.geojson.json: json, outline.geojson.json: json, rooms.geojson.json: json}}}
 */
export let BUILDING_GEOJSONS = null;
export const PHONEBOOK_ENTRIES = [];
export const NEWS = [];
export const QR_CODES = [];
export let EVENTS = [];
export let EVENTS_UNSORTED = [];
export let SEARCH_CATEGORIES = [];

/**
 * @type {CampusCollection}
 */
export let CAMPUS_COLLECTION;

export function setPage(page) {
  return {
    type: types.MAIN_SET_PAGE,
    payload: page,
  };
}
/**
 * Setze das neue ausgewählte Feature in den State. Wichtig hier zu wissen, dass der ausgewählte POI und
 * das ausgewählte Feature auf der Map distinkt voneinander sein können, da das Feature im POI referenziert ist.
 * @param {Node, Polygon, POI} feature
 */
export function setCurrentFeature(feature) {
  /**
   * @type GlobalPOI
   */
  let poi;
  // wenns bereits ein POI ist der Ausgewählt wird, dann abchecken was auf der Map ausgewählt werden soll
  if (feature instanceof GlobalPOI) {
    poi = feature;
  } else if (feature != null) {
    // wenns direkt ein feature von der map ausgewählt ist, diese Art "Node" oder "Polygon" als selected hinterlegen
    poi =
      POIS.all_objects[feature.properties?.globalPoiId ?? feature.globalPoiId];

    if (poi) {
      // source für das Feature bearbeiten
      // id für das feature neu vergeben!
      poi.setPreferredFeatureAsSelected();
      const poiFeature = poi.selectedFeature;

      poiFeature.id = feature.id;
      poiFeature.source = feature.source;
    }
  }

  if (poi) {
    poi.setPreferredFeatureAsSelected();
    poi.getRouteNodeId();

    if (map) {
      const poiCampusKey = map.campusCollection.getCampusById(
        poi.campusId
      )?.campusKey;

      if (!poiCampusKey)
        return {
          type: types.MAIN_SET_CURRENT_POI,
          payload: poi,
        };

      // gib poi das polygon von der map wenn verfügbar!
      const buildingFeatures =
        map.getGeoJSONCollection().fetched[poiCampusKey]?.buildings;

      if (buildingFeatures?.length) {
        const buildingFeature = buildingFeatures.find(
          (b) => b.properties.globalPoiId === poi.globalPoiId
        );
        if (buildingFeature) {
          poi.setPolygonObject(buildingFeature);
        }
      }
    }
  } else if (feature) {
    console.error(`Für Feature kann kein POI gefunden werden`, feature);
  }

  return {
    type: types.MAIN_SET_CURRENT_POI,
    payload: poi,
  };
}

export function setCurrentCampusId(campusId) {
  // aktuellen Campus auf [0] setzen
  CAMPUS_COLLECTION.setCurrentCampus(campusId);

  return {
    type: types.MAIN_SET_CURRENT_CAMPUS,
    payload: campusId,
  };
}

export function setCurrentCampusKey(campusKey) {
  return {
    type: types.MAIN_SET_CURRENT_CAMPUS_KEY,
    payload: campusKey,
  };
}

/**
 * Holt die vefügbaren GEOJSONS zu jedem Gebäude!
 * Befüllt lediglich die BUILDING_GEOJSONS und setzt ein bool state.
 */
export function setGEOJSONS(geoData) {
  if (geoData) {
    BUILDING_GEOJSONS = { ...BUILDING_GEOJSONS, ...geoData };

    // components nur bescheid sagen dass die daten da sind
    return {
      type: types.MAP_SET_GEOJSON_DATA,
      payload: true,
    };
  } else {
    return {
      type: types.MAP_SET_GEOJSON_DATA,
      payload: false,
    };
  }
}

export function setIsApp(isApp) {
  return {
    type: types.MAIN_SET_IS_APP,
    payload: isApp,
  };
}

export function setCustomStyleId(id) {
  return {
    type: types.MAIN_SET_STYLE_ID,
    payload: id,
  };
}

export function setHasSeparateSearchScreen(value) {
  return {
    type: types.MAIN_SET_HAS_SEPARATESEARCHSCREEN,
    payload: value,
  };
}

export function setUploadData(shoutUploadData) {
  return {
    type: types.MAIN_SET_UPLOAD_DATA,
    payload: shoutUploadData,
  };
}

export function generateSessionId() {
  return {
    type: types.MAIN_CREATE_SESSION_ID,
  };
}

export function setCustomerInformation(customer) {
  return {
    type: types.MAIN_SET_CUSTOMER,
    payload: customer,
  };
}

export function getSessionKey() {
  const globalParams = new URLSearchParams(window.location.search);
  return function (dispatch) {
    axios
      .get(
        `${process.env.REACT_APP_GET_SESSION_KEY}?piKey=${globalParams.get(
          "piKey"
        )}`
      )
      .then((res) => {
        // indicatorLog("Session Key erhalten 🔑");
        dispatch({
          type: types.MAIN_SET_SESSION_KEY,
          payload: res.data,
        });
      })
      .catch((e) => {
        // indicatorLog("Keinen SessionKey erhalten", { background: "red" });
        throw e;
      });
  };
}

/**
 * wenn value nicht ausgefüllt wird getoggelt, ansonsten normales set
 * @param value
 * @return {{payload: *, type: string}}
 */
export function toggleIsFullMap(value = null) {
  return {
    type: types.MAIN_TOGGLE_IS_FULL_MAP,
    payload: value,
  };
}

export function triggerPageLoad() {
  return {
    type: types.MAIN_TRIGGER_PAGE_LOAD,
  };
}

export function setLanguage(key) {
  nativeStorageInterface.set("language", key);

  GlobalPOI.language = key;
  return {
    type: types.MAIN_SET_LANGUAGE,
    payload: key,
  };
}

export function setAvailableCampuses(piKey) {
  return function (dispatch) {
    axios
      .get(`${process.env.REACT_APP_GET_CAMPUSES_URL}?piKey=${piKey}`)
      .then(async (res) => {
        const campuses = res.data.campuses.filter(
          (c) => c.published == null || c.published === true
        );

        const availableCampusKeys = await nativeStorageInterface.get(
          "availableCampusKeys"
        );

        const beforeCampusCount = availableCampusKeys
          ? availableCampusKeys
          : undefined;

        if (beforeCampusCount != null) {
          if (beforeCampusCount < campuses.length) {
            dispatch({
              type: types.MAIN_SET_NEW_AVAILABLE_CAMPUSES,
              payload: true,
            });
          }
        }
        nativeStorageInterface.set("availableCampusKeys", campuses.length);
        dispatch({
          type: types.MAIN_SET_AVAILABLE_CAMPUSES,
          payload: campuses,
        });
      })
      .catch((e) => {
        throw e;
      });
  };
}

/**
 * Diese Funktion lädt die Campusse und alle ihre Daten
 * @param campusKeys
 * @param locale
 * @return {(function(*): void)|*}
 */
export function loadCampuses(campusKeys, locale, userGroup) {
  return function (dispatch) {
    nativeStorageInterface.set("campusKeys", campusKeys);
    let os = "";
    if (isIOS) os = "iOS";
    else if (isAndroid) os = "Android";
    else {
      os = navigator?.userAgentData?.platform;
    }
    const data = {
      isFuerApp: isApp,
      versionName: process.env.REACT_APP_APP_VERSION_NAME,
      locale: locale,
      betriebssystem: os,
      nutzergruppe: userGroup,
      version: process.env.REACT_APP_VERSION,
      campusKeys,
    };
    axios
      .get(
        `${process.env.REACT_APP_GET_PRODUCT_INSTANCE_URL}?locale=${
          data.locale
        }&piKey=${piKey}&version=${
          data.version
        }&isFuerApp=${+data.isFuerApp}&loadUnpublishedData=${+!!useSavedData}&campusKeys=${JSON.stringify(
          campusKeys
        )}`
      )
      .then((res) => {
        indicatorLog("Produktinstanzdaten erhalten 🗂", { error: res.data });
        try {
          nativeStorageInterface.set("piData", res.data);
        } catch (e) {
          console.warn("Konnte nicht in native Storage speichern", e);
        }
        setProductInstanceData(res.data);
        dispatch({
          type: types.MAIN_SET_CA_SERVERDATA,
          payload: res.data,
        });
        dispatch({
          type: types.MAIN_SET_PIDATA,
          payload: res.data,
        });
      })
      .catch((e) => {
        indicatorLog("Serverdaten für die App konnten nicht geladen werden", {
          background: "red",
          error: e,
        });
        nativeStorageInterface.get("piData").then((storagePiData) => {
          if (storagePiData) {
            indicatorLog("Serverdaten konnten aus dem Storage geholt werden", {
              background: "yellow",
              error: e,
            });
            setProductInstanceData(storagePiData);
            dispatch({
              type: types.MAIN_SET_CA_SERVERDATA,
              payload: storagePiData,
            });
          } else {
            throw e;
          }
        });
      });
  };
}

export function setCampusKeys(key) {
  return {
    type: types.MAIN_SET_CAMPUS_KEYS,
    payload: key,
  };
}

export function setURLCampusKeys(key) {
  return {
    type: types.MAIN_SET_INITIAL_CAMPUS_KEY,
    payload: key,
  };
}

export function completeIntro(value = true) {
  nativeStorageInterface.set("introComplete", value);
  return {
    type: types.MAIN_SET_INTRO_COMPLETE,
    payload: value,
  };
}

export function setIsTerminal() {
  return {
    type: types.MAIN_SET_IS_TERMINAL,
    payload: true,
  };
}

export function setTerminalIsIdle(value) {
  return {
    type: types.MAIN_SET_TERMINAL_IDLE,
    payload: value,
  };
}

export function setCameraPermissionAllowed(value) {
  return {
    type: types.MAIN_SET_CAMERA_PERMISSION_ALLOWED,
    payload: value,
  };
}

export function setDataScannerAvailable(value) {
  return {
    type: types.MAIN_SET_DATA_SCANNER_AVAILABLE,
    payload: value,
  };
}

/**
 *  WICHTIGSTE FUNKTION: Sie ordnet alle gezogenen Content Daten vom Server
 * @param piData
 */
function setProductInstanceData(piData) {
  const urlParams = new URL(window.location).searchParams;
  const value = urlParams.get("disabledRouteSettings");

  const disabledRouteSettings =
    value === "1" || value === "true" || value === "True";

  try {
    //reset data
    POI_NODES.length = 0;
    POI_POLYGONS.length = 0;
    POIS.all.length = 0;
    POIS.facilities.length = 0;
    POIS.buildings.length = 0;
    POIS.floors.length = 0;
    POIS.rooms.length = 0;
    POIS.pois.length = 0;
    POIS.parkinglots.length = 0;
    POIS.campuses.length = 0;
    POIS.miscelleanous.length = 0;
    PHONEBOOK_ENTRIES.length = 0;
    NEWS.length = 0;
    QR_CODES.length = 0;
    EVENTS.length = 0;
    EVENTS_UNSORTED.length = 0;
    SEARCH_CATEGORIES.length = 0;
    BUILDING_GEOJSONS = null;
    CONTENT = null;
    CAMPUSES = [];

    nativeStorageInterface.set("DataTimestamp", piData.revision);

    if (!CAMPUS_COLLECTION) {
      // muss nicht resettet werden
      CAMPUS_COLLECTION = new CampusCollection();
    }

    if (piData.produktinstanz.mapboxAccessToken) {
      MAPBOX_ACCESSTOKEN = piData.produktinstanz.mapboxAccessToken;
    } else {
      indicatorLog(
        "Es wurde kein Mapbox AccessToken mitgeliefert! Map kann nicht geladen werden!",
        { background: "red" }
      );
    }
    if (piData.produktinstanz.appSettings) {
      APP_SETTINGS = piData.produktinstanz.appSettings;
    }

    MISC_INFO = {
      revision: piData.revision,
      imprintURL:
        piData?.produktinstanz?.impressumURL ??
        `https://www.catchup-apps.com/impressum/?piKey=${piKey}`,
      dataProtectionURL:
        piData?.produktinstanz?.datenschutzURL ??
        `https://www.catchup-apps.com/datenschutz-khapp/?piKey=${piKey}`,
      contactURL: piData?.produktinstanz?.kontaktURL,
      partners: piData?.partners ?? [],
      campusAutoSelectDistance: piData.campusAutoSelectDistance,
      tabBarOrder: APP_SETTINGS.tabBarOrder ?? [],
      dataScanner: {
        isAllowed: !!APP_SETTINGS?.dataScanner,
        regEx: APP_SETTINGS?.dataScanner?.regEx ?? null,
      },
      introText: APP_SETTINGS?.introText,
      shareUrl:
        "https://" +
        (piData.produktinstanz.catchupSubdomain ?? "www") +
        ".catchup-apps.com/app/",
      subDomain: piData?.produktinstanz.catchupSubdomain,
      openNewsInNewTab: APP_SETTINGS.openNewsInNewTab ?? true,
      openEventsInNewTab: APP_SETTINGS.openEventsInNewTab ?? true,
      openCommonUrlsInNewTab: APP_SETTINGS.openCommonUrlsInNewTab ?? true,
      disabledRouteSettings:
        APP_SETTINGS.disabledRouteSettings ?? disabledRouteSettings ?? false,
      startZoomLevel:
        APP_SETTINGS.startZoomLevel == null
          ? null
          : APP_SETTINGS.startZoomLevel,
      accessibilityURL: APP_SETTINGS?.accessibilityLink,
    };

    CAMPUSES = piData.campus;
    CONTENT = piData.content;

    if (Array.isArray(piData.naviPriorityList)) {
      NAVI_PRIORITY_LIST = piData.naviPriorityList;
    }
    // Module richtig extrahieren
    for (let key in piData.module) {
      MODULES = { ...MODULES, [key]: !!piData.module[key] };
    }
    // --- Abfang, wenn in der taborder menu items sind, die  garnicht verfügbar sind!
    if (!MODULES.hatNeuigkeiten) {
      MISC_INFO.tabBarOrder = MISC_INFO.tabBarOrder.filter((t) => t !== "news");
    }
    if (!MODULES.hatVeranstaltungen) {
      MISC_INFO.tabBarOrder = MISC_INFO.tabBarOrder.filter(
        (t) => t !== "events"
      );
    }

    setProduktinstanzModuleToNative(MODULES);
    try {
      CAMPUSES.forEach((campus) => {
        campus.qrCodes.forEach((qr) => {
          QR_CODES.push({
            type: "QR-Code",
            name: qr.qrKey,
            startNodeId: qr.startNodeId,
            destinationId: qr.destinationId,
            isSearchable: 1,
            category: "QR-Code",
          });
        });
        CAMPUS_COLLECTION.addCampus(
          new Campus(
            campus.campusinformationen.campusId,
            campus.campusinformationen.campusKey,
            campus.campusinformationen.campusName,
            campus.graph.knoten,
            campus.beacons,
            campus.campusinformationen.stockwerke,
            {
              lat: +campus.campusinformationen.centerLat,
              lng: +campus.campusinformationen.centerLng,
            },
            undefined,
            campus.campusinformationen.raumnummernAnzeigen,
            campus.campusinformationen.showOutdoorEinrichtungenOnMap
          )
        );
      });
    } catch (e) {
      indicatorLog("Fehler bei Erstellung der Campusse", {
        background: "red",
        error: {
          campuses: CAMPUSES,
          campusCollection: CAMPUS_COLLECTION,
        },
      });
      throw e;
    }

    BEACONS = CAMPUSES.reduce((beacons, campus) => {
      const b = campus.beacons ?? [];

      beacons.push(b.flat(1));
      return beacons;
    }, []).flat(1);
    try {
      LOCATION_MANAGER = new LocationManager(CONTENT, BEACONS, isAndroid);
    } catch (e) {
      indicatorLog("Es gab ein Problem mit dem LocationManager", {
        background: "red",
        error: { BEACONS, CONTENT, e },
      });
    }
    CAMPUS_COLLECTION.getAllCampusNodes().forEach((node) => {
      CAMPUS_NODES[node.id] = node;
    });

    CONTENT.forEach((item) => {
      switch (item.type) {
        case poitypes.address:
          pushCorrectTypeObject(item, POIS.addresses);
          break;
        case poitypes.room:
          pushCorrectTypeObject(item, POIS.rooms);
          break;
        case poitypes.building:
          pushCorrectTypeObject(item, POIS.buildings);
          break;
        case poitypes.floor:
          if (
            item.attributeIds &&
            item.attributeIds.find((aId) => [11, 16, 30, 31].includes(aId))
          ) {
            pushCorrectTypeObject(item, POIS.parkinglots);
          } else {
            pushCorrectTypeObject(item, POIS.floors);
          }
          break;
        case poitypes.poi:
          pushCorrectTypeObject(item, POIS.pois);
          break;
        case poitypes.campus:
          CAMPUS_COLLECTION.setCampusPolygon(item.campusId, item.polygon);

          // node für den Campus setzen
          let campusFromCollection = CAMPUS_COLLECTION.getCampusById(
            item.campusId
          );

          if (campusFromCollection.nodes) {
            const campusEntry = campusFromCollection.nodes.find(
              (n) => n.attributIds && n.attributIds.includes(47)
            );

            if (campusEntry) {
              item.nodeId = campusEntry.id;
              item.globalPoiId = null;
            }
          }

          pushCorrectTypeObject(item, POIS.campuses);
          CAMPUS_COLLECTION.setCampusPOI(
            item.campusId,
            POIS.campuses[POIS.campuses.length - 1]
          );

          let poiCampus = POIS.campuses[POIS.campuses.length - 1];
          poiCampus.setPolygonCenter(
            campusFromCollection?.center?.geometry?.coordinates
          );
          break;
        case poitypes.parking:
          pushCorrectTypeObject(item, POIS.parkinglots);
          break;
        case poitypes.facility:
          pushCorrectTypeObject(item, POIS.facilities);
          break;
        case poitypes.node:
          pushCorrectTypeObject(item, POIS.miscelleanous);
        default: {
          //throw Error(`content with the type: "${item.type}" is not handled`);
        }
      }
    });

    setPOIPriorities();

    setBuildingPolygonsToFacilities();

    // Das hier muss einzeln gemacht werden, weil vorher alle POI_Nodes bestimmt sein sollen!
    CONTENT.forEach((item) => {
      switch (item.type) {
        case poitypes.phone: {
          const phoneBookEntry = new GlobalPOI(item);
          PHONEBOOK_ENTRIES.push(phoneBookEntry);
          if (phoneBookEntry.relations) {
            addRelationToPOI(phoneBookEntry, "phonebookEntries");
          }
          break;
        }
        case poitypes.news: {
          const newsEntry = new GlobalPOI(item);
          NEWS.push(newsEntry);
          if (newsEntry.relations) {
            addRelationToPOI(newsEntry, "news");
          }
          break;
        }
        case poitypes.event: {
          const eventEntry = new GlobalPOI(item);
          EVENTS_UNSORTED.push(eventEntry);
          EVENTS.push(eventEntry);
          if (eventEntry.relations) {
            addRelationToPOI(eventEntry, "events");
          }
          break;
        }
        default: {
          //   throw Error(`content with the type: "${item.type}" is not handled`);
        }
      }
    });

    setEvents();
    setCategories(piData);
  } catch (e) {
    indicatorLog("Es gab einen Fehler beim verarbeiten der PiDaten", {
      background: "red",
      error: e,
    });

    throw { error: e, preparing: true };
  }
}

function setPOIPriorities() {
  if (!NAVI_PRIORITY_LIST || !NAVI_PRIORITY_LIST.length) return;

  NAVI_PRIORITY_LIST.forEach((poiId, index) => {
    /** @type GlobalPOI */
    // const poi = POIS.all.find((poi) => poi.id === poiId);
    const poi = POIS.all_objects[poiId];
    if (poi) {
      poi.priority = NAVI_PRIORITY_LIST.length - index;
    }
  });
}

export function setDesignDataLoaded(value) {
  return {
    type: types.MAIN_DESIGN_DATA_LOADED,
    payload: value,
  };
}

export function setServiceWorkerActive(value) {
  return {
    type: types.MAIN_SET_SERVICE_WORKER_ACTIVE,
    payload: value,
  };
}

export function setCurrentAppOverlay(appOverlay = new AppOverlay()) {
  return {
    type: types.MAIN_SET_CURRENTAPPOVERLAY,
    payload: appOverlay,
  };
}

export function setNotificationPostId(id) {
  return {
    type: types.MAIN_SET_NOTIFICATION_POST_ID,
    payload: id,
  };
}

export function setDeviceInformation(data) {
  return {
    type: types.MAIN_SET_DEVICE_INFORMATION,
    payload: data,
  };
}
/**
 * Die Funktion wandelt jedes Item in ein POI um und fügt in dieses ein Polygon oder Node ein
 * @param {Object} item - Das zu konvertierende und ins Array einzuspeisende item
 * @param {[Node, GlobalPOI, Polygon]} insertionArray - Das Array in das eingespeist werden soll
 * @param {[Node]} NODES - Das KnotenArray, das für die Suche nach einrichtungen gebraucht wird!
 * @param {[GlobalPOI, Node, Polygon]} nodePOIs
 */
function pushCorrectTypeObject(item, insertionArray) {
  const nodeId = item.nodeId;
  // neuen POI erstellen
  let poi = new GlobalPOI(item);

  if (nodeId) {
    poi.nodeId = nodeId;
  }

  POIS.all.push(poi);
  POIS.all_objects[poi.id] = poi;

  // zusätzlich in ein dediziertes Array pushen (um in den Components nicht immer auf "type" zu checken)
  if (insertionArray) {
    insertionArray.push(poi);
  }

  // if (item.globalPoiId === 71908) {
  //   console.log("FOUND POI IN PUSHCORRECTTYPEOBJECT", item);
  // }

  // wenn nodeId existiert -> node erstellen
  if (poi.nodeId != null) {
    let node;

    // actual node aus der data herausfinden, bevor man es in die Klasse "Node" umwandelt
    // (welches by the way eine POI Klasse ist)
    const k = CAMPUS_NODES[poi.nodeId];

    if (k) {
      node = new Node({
        allProps: item,
        // wichtig ist, dass hier die ID des Items und
        // nicht des zugehörigen Knotens genommen wird,
        // weil sich ansonsten mehrere Layer Features
        // mit der gleichen ID teilen! -> das Führt dazu dass beim
        // Click falsche Features angewählt werden
        id: item.nodeId,
        indoors: poi.indoors,
        lat: k.coord[0],
        lng: k.coord[1],
        zielId: k.zielId,
        level: k.coord[2],
        attributIds: k.attributIds,
        adresseId: k.adresseId,
        poiId: poi.id,
        priority: poi.priority,
      });

      if (poi && poi.position && poi.position.lat) {
        node.geometry.coordinates = [poi.position.lng, poi.position.lat];
        node.properties.level = poi.position.z;
      }

      // zu den Nodes für mapbox hinzufügen
      POI_NODES.push(node);

      // im POI referenzieren
      poi.setNodeObject(node);
    }
  } else if (poi.position != null) {
    let node;

    node = new Node({
      allProps: item,
      // wichtig ist, dass hier die ID des Items und
      // nicht des zugehörigen Knotens genommen wird,
      // weil sich ansonsten mehrere Layer Features
      // mit der gleichen ID teilen! -> das Führt dazu dass beim
      // Click falsche Features angewählt werden
      id: item.nodeId,
      indoors: poi.indoors,
      lat: poi.position.lat,
      lng: poi.position.lng,
      level: poi.position.z,
      // lat: k.coord[0],
      // lng: k.coord[1],
      // zielId: k.zielId,
      // level: k.coord[2],
      // attributIds: k.attributIds,
      // adresseId: k.adresseId,
      poiId: poi.id,
      priority: poi.priority,
    });

    // zu den Nodes für mapbox hinzufügen
    POI_NODES.push(node);

    // im POI referenzieren
    // TODO BRAUCH ICH DAS????
    // poi.setNodeObject(node);
  }

  // wenn polygon existiert -> polygon erstellen
  if (item.polygon || item.additionalData?.visualPolygon) {
    const polygon = new Polygon(item);

    poi.setPolygonObject(polygon);

    switch (poi.type) {
      case poitypes.building:
      case poitypes.campus:
        // für Mapbox zu den Polygons hinzufügen
        POI_POLYGONS.push(polygon);
        break;
      case poitypes.facility:
        // Einrichtungen brauchen keine Polygone -> Sie nehmen nach der ganzen Iteration die Polygone ihrer
        // buildingIds als ihre eigenen an
        delete poi.polygon;
        break;
      default:
        break;
    }
  }
}

/**
 * Fügt jeder Einrichtung mit einer buildingId das Building PolygonObject hinzu, damit
 * man bei der Suche dann auf der Map darauf verweisen kann, denn Einrichtungspolygone werden perse nicht angezeigt
 */
function setBuildingPolygonsToFacilities() {
  let daBuilding;
  POIS.facilities.forEach((l) => {
    if (!l.nodeObject && l.buildingId != null) {
      daBuilding = POIS.buildings.find(
        (element) => element.id === l.buildingId
      );
      if (daBuilding) {
        l.setPolygonObject(daBuilding.polygonObject);
      }
    }
  });
}

function setEvents() {
  const events = [];
  const date = new Date(Date.now());
  const now = Date.now();
  const datestringToDate = (datestring) => {
    const dateString = datestring
      .split(" ")[0]
      .replace(new RegExp("-", "g"), "/");
    return new Date(dateString);
  };

  // events auf monate im jahr verteilen
  for (let i = 0; i < 12; ++i) {
    events.push({
      monthDate: date.toString(),
      id: i,
      items: EVENTS_UNSORTED.filter((event) => {
        const eventDate = datestringToDate(event.datetime);
        return (
          eventDate.getMonth() === date.getMonth() &&
          eventDate.getFullYear() === date.getFullYear() &&
          eventDate > now
        );
      }).sort((a, b) => {
        a = a.datetime.split("-").reverse().join("");
        b = b.datetime.split("-").reverse().join("");
        return a > b ? 1 : a < b ? -1 : 0;
      }),
    });
    date.setMonth(date.getMonth() + 1);
  }

  events.push({
    monthDate: "later",
    id: "later",
    // sortierung nach datum geht nicht auf safari und ios
    items: EVENTS.filter((event) => {
      const eventDate = datestringToDate(event.datetime);

      return date < eventDate;
    }).sort((a, b) => {
      a = a.datetime.split("-").reverse().join("");
      b = b.datetime.split("-").reverse().join("");
      return a > b ? 1 : a < b ? -1 : 0;
    }),
  });
  EVENTS = events.filter((event) => event.items.length);
}
function setCategories(piData) {
  SEARCH_CATEGORIES = piData.categories.map((category) => ({
    ...category,
    id: +category.id,
    nodes: POIS.all
      .filter((node) => +node.category === +category.id && node.isSearchable)
      .sort((a, b) => a.name.localeCompare(b.name)),
    phonebook: PHONEBOOK_ENTRIES.filter(
      (pe) => +pe.category === +category.id && pe.isSearchable
    ),
    events: EVENTS_UNSORTED.filter(
      (e) => +e.category === +category.id && e.isSearchable
    ),
    news: NEWS.filter((n) => +n.category === +category.id && n.isSearchable),
  }));

  SEARCH_CATEGORIES = SEARCH_CATEGORIES.filter(
    (category) =>
      category.nodes.length ||
      category.phonebook.length ||
      category.events.length ||
      category.news.length
  );

  SORT_ORDER = piData.naviPriorityList ?? [];
}

export function toggleDisplayDownMode(value) {
  return {
    type: types.MAIN_TOGGLE_DISPLAY_DOWN_MODE,
    payload: value,
  };
}
/**
 * Die PiKeys, die man nach login zurück erhält
 * @param {{name: string, key: string}[]} loginPiKeys
 */
export function setLoginPiKeys(loginPiKeys) {
  return {
    type: types.MAIN_LOGIN_PIKEYS,
    payload: loginPiKeys,
  };
}
export function setPiKeyState(piKey) {
  if (piKey) {
    const url = setQueryParams("piKey", piKey);
    window.history.replaceState(null, null, url);
  }

  setPiKey(piKey);
  return {
    type: types.MAIN_SET_PIKEY_STATE,
    payload: piKey ?? null,
  };
}
export function setURLParametersReady(value) {
  return {
    type: types.MAIN_SET_URL_PARAMETERS_READY,
    payload: value,
  };
}
