const standard = 2.5;
/**
 * Für die Ordentlichkeit werden möglichst alle StylingValues für jegliche Layers hier gespeichert,
 * damit man möglichst gleiche Values für alles verwendet und keinen Mischmasch hat.
 */
export const STYLINGVALUES = {
  rooms: {
    height: {
      standard: standard,
      glassStandard: standard + 0.05,
      glassLow: 0.2,
      // bei allen anderen außer normalen räumen sind manchmal noch polygone innendrin
      // die krieseln hässlich (wegen der gleichen höhe und farbunterschied),
      // deshalb ist es nur provisorisch, dass diese räume ein kleines bisschen höher sind
      switchFloorRoom: standard + 0.05,
      elevatorRoom: standard + 0.05,
      stairsRoom: standard + 0.05,
      entranceRoom: 3.5,
      cafeteriaRoom: standard + 0.05,
      waitingRoom: 0.2,
      emergencyRoom: standard + 0.05,
      waitingArea: 0.1,
      officeArea: standard / 3,
      cafeteriaArea: 0.2,
      emergencyArea: 0.2,
      mainFloor: 0,
      sideFloor: 0,
      restrictedFloor: 0,
    },
    base: {
      entranceRoom: standard + 0.05,
    },
    opacity: {
      standardRoom: 1,
      switchFloorRoom: 0.5,
      elevatorRoom: 0.5,
      stairsRoom: 0.5,
      waitingRoom: 1,
      mainFloor: 1,
      glass: 0.4,
    },
  },
  lines: {
    opacity: {
      navigation: {
        arrows: {
          ghosted: 0,
        },
        normal: 1,
        ghosted: 0.3,
      },
    },
    blur: {
      navigation: {
        ghosted: 20,
      },
    },
    dash: [0.1, 2],
    thickness: {
      navigation: {
        normal: {
          line: 8,
          halo: 14,
        },
        ghosted: {
          line: 8,
          halo: 14,
        },
      },
    },
  },
  symbols: {
    opacity: {
      navigation: {
        arrows: {
          ghosted: 0,
        },
        normal: 1,
        ghosted: 0.2,
      },
    },
  },
};
