import classes from "./splashScreen.module.scss";
import { useSpring, a, config } from "react-spring";
import { useState } from "react";

/**
 * HOC für Splash Screens mit Open und Close Animation
 * @param {function} render - Funktion MIT dem Component. Die Funktion die übergeben wird, muss dabei
 * im render Component die Schließfunktion sein.
 * @param {function} handleClose - Diese Funktion muss der Render Component selbst beinhalten,
 * damit der User z.B. auf "schließen" drücken kann
 * @param {function} onClosingFinished - Callback, wenn die CloseAnimation vorbei ist (nachdem handleClose getriggert wurde im übergebenen Component).
 * @param {object} openSpringProps - React Spring Props zur Animation.
 * @param {object} closeSpringProps - React Spring Props zur Animation.
 * @returns {JSX.Element}
 * @constructor
 */
export default function SplashScreen({
  render,
  onClosingFinished,
  openSpringProps,
  closeSpringProps,
  className = "mw-35-rem",
  animated = true,
}) {
  // --- States ---
  const [minimized, setMinimized] = useState(false);

  // --- Functions ---
  // Die Animation beim Öffnen
  const [springProps, springAPI] = useSpring(() =>
    openSpringProps
      ? openSpringProps
      : {
          from: {
            opacity: 0,
            transform: "translateY(100px)",
          },
          to: {
            opacity: 1,
            transform: "translateY(0px)",
          },
          config: config.stiff,
        }
  );
  // Animation beim schließen
  const handleClose = () => {
    springAPI(
      closeSpringProps
        ? { ...closeSpringProps, onRest: onClosingFinished }
        : {
            to: {
              opacity: 0,
              transform: "translateY(50px)",
            },
            config: config.stiff,
            onRest: onClosingFinished,
          }
      // onClosingFinished sollte den State im Parent ändern, damit dieser
      // den component unmounten kann
    );
  };
  const handleMinimize = () => {
    setMinimized(!minimized);
  };

  return (
    <a.div
      className={`${classes.container}  pointer-event-on bg-white`}
      style={animated ? springProps : null}
    >
      <div className={`${classes.content} ${className}`}>
        {render(handleClose, handleMinimize)}
      </div>
    </a.div>
  );
}
