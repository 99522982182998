import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import StandardCard from "../../../elements/cards/StandardCard.card.jsx";
import { isMobile } from "react-device-detect";
import { TimeToTake } from "../../route/RouteCard/routeCard.component.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StandardSubline from "../../../elements/text/standardSubline.text.jsx";
import RoundedIcon from "../../../elements/buttons/roundedIcon.component.jsx";
import { NavigationInstructions } from "./navigationInstructions.component.jsx";
import { ArrivalTime } from "./arrivalTime.component.jsx";
import StandardHeadline from "../../../elements/text/standardHeadline.text.jsx";
import StandardButton from "../../../elements/buttons/standard.button.jsx";
import { FormattedMessage, useIntl } from "react-intl";
import { GeneratedQRCode } from "../../../elements/cards/GeneratedQRCode";
import {
  cancelDataScanner,
  startDataScanner,
  vibrateDevice,
} from "../../../nativeAppInterface/NativeAppInterface";
import {
  CAMPUS_COLLECTION,
  MISC_INFO,
  MODULES,
} from "../../../../reduxStore/actions/main";
import { nativeStorageInterface } from "../../../../index";
import ScannerInfo from "../scannerInfo";
import Scanner from "../../../../classes/Scanner";
import { statisticLogger } from "../../../statisticLogger/StatisticLogger.container";
import { center, points } from "@turf/turf";
import StartDestination from "../../../../functions/classes/startDestination.class";
import { piKey } from "../../../../app/app.sideEffects/useURLParams.sideEffects";
import QrCodeReader from "../../QrCodeReader/QrReader.component";
import { useHistory } from "react-router-dom";
import DoorSignScannerInfo from "../../info/doorSignScannerInfo";
import QrScannerInfo from "../../info/qrScannerInfo";

export let DONT_DO_ANYTHING = false;
export let scanner = null;

export function NavigationControls({
  setProhibitSpeak,
  handlePrevStep,
  handleNextStep,
  currentRoute,
  currentLeg,
  setMapPadding,
  handleClose,
  currentStep,
  navigationMode,
  isRerouting,
  canEndLiveNavigation,
  onEndNavigation,
  showCenterPositionButton,
  setUserMovesMap,
  bannerStepDistance,
  isTerminal,
  isApp,
  language,
  goToParkingSpot,
  roadUserType,
  routingSettings,
  target,
  setRoute,
  locationObject,
}) {
  const [showScannerInfo, setShowScannerInfo] = useState(false);
  const [qrOpen, setQrOpen] = useState(false);
  const containerRef = useRef();
  const intl = useIntl();
  const remainingDruation = currentRoute.getRemainingDuration(false);
  const arrivalDate = currentRoute.endTime;
  const remainingDistanceToDestination = currentRoute.getDistanceFormatted();
  const ariaText = "";
  const history = useHistory();

  useLayoutEffect(() => {
    setMapPadding({ bottom: containerRef.current.clientHeight }, true);
  }, []);

  useEffect(() => {
    return () => {
      DONT_DO_ANYTHING = false;
    };
  }, []);

  const onReadId = async (result) => {
    const qrCode = await result;

    if (!!qrCode.startNodeId) {
      setRoute(
        StartDestination.createStartDestination(
          qrCode.startNodeId,
          intl.formatMessage({ id: "gps.selectedPlace" })
        ),
        target,
        routingSettings,
        roadUserType,
        goToParkingSpot,
        language,
        isApp,
        isTerminal
      );
      vibrateDevice();
    }
  };

  const scanDoorSign =
    MISC_INFO.dataScanner.isAllowed &&
    currentLeg.isIndoor &&
    piKey === "UKT-7wbrGmoTcGMsoSHnYvS10mCRTb76Ws1uolN3s5kdLJUj9M";

  const scanQrSign =
    currentLeg.isIndoor &&
    isMobile &&
    MODULES.qrScannerAnbieten &&
    (piKey === "KHBR-hXf0ATzBt5SCYTdNnY6c6Tm9z5AxhYMrpyKygpaZ3gbLo" ||
      piKey === "LHH-44sbHZhwah0o5cs7Go5QQSn2c89mR6VGImb9wT7N2smGUS");

  const handleClickQcSign = () => {
    setQrOpen(true);
  };

  const handleClickScanDoorSign = async () => {
    const seenScannerInfo = await nativeStorageInterface.get(
      "seen-scanner-info"
    );

    if (!seenScannerInfo) {
      setShowScannerInfo(true);
      nativeStorageInterface.set("seen-scanner-info", true);
    } else {
      setShowScannerInfo(false);
      scanner = new Scanner();
      setTimeout(() => {
        if (piKey === "UKT-7wbrGmoTcGMsoSHnYvS10mCRTb76Ws1uolN3s5kdLJUj9M") {
          //TODO abprüfen, ob kamera noch aktiv
          const room = scanner.getRoom();

          cancelDataScanner();
          if (room) {
            const vertices = CAMPUS_COLLECTION.getAllCampusNodes();

            const entrances = room.entrances.reduce((result, entry) => {
              const vertex = vertices.find((v) => v.id === entry.nodeId);
              if (!vertex) return result;

              result.push([vertex.coord[1], vertex.coord[0]]);
              return result;
            }, []);

            const c = center(points(entrances));

            setRoute(
              new StartDestination({
                isSet: true,
                lat: c.geometry.coordinates[1],
                lng: c.geometry.coordinates[0],
                level: room.level, //TODO: ändern auf feature.level
                positionType: "user",
                name: intl.formatMessage({ id: "gps.myLocation" }),
              }),
              target,
              routingSettings,
              roadUserType,
              goToParkingSpot,
              language,
              isApp,
              isTerminal
            );
            vibrateDevice();
          } else {
            window.addLog({
              show: true,
              upload: false,
              userMessage: "scanner.roomNotFound",
            });
          }
        }
      }, 5000);
      startDataScanner(MISC_INFO.dataScanner.regEx);
    }
  };

  return (
    <div
      role={"region"}
      aria-label={"Navigation-Controls"}
      className={
        "pointer-event-on mt-auto d-flex flex-column general-margin-horizontally"
      }
    >
      {showCenterPositionButton && (
        <StandardButton
          icon={["fal", "location-arrow"]}
          buttonClasses={"normalButton--primary align-self-center mb-3"}
          handleClick={() => setUserMovesMap(false)}
        >
          <FormattedMessage id={"navigation.camera.center"} />
        </StandardButton>
      )}
      <div className="d-flex align-items-center justify-content-around position-relative">
        {isTerminal && (
          <GeneratedQRCode
            className="mr-3"
            qrCodeHeight={7}
            isTerminal={isTerminal}
          />
        )}

        <StandardCard
          ref={containerRef}
          padding={false}
          className={"standardCard__navigation mw-100-dynamic"}
          ariaLabel={ariaText}
        >
          {scanDoorSign ? (
            <DoorSignScannerInfo onClick={handleClickScanDoorSign} />
          ) : scanQrSign ? (
            <QrScannerInfo onClick={handleClickQcSign} />
          ) : null}

          {!isMobile && (
            <div className={"p-3"}>
              <NavigationInstructions
                margin={false}
                setMapPadding={setMapPadding}
                handleClose={handleClose}
                locationObject={locationObject}
                currentStep={currentStep}
                isTerminal={isTerminal}
                bannerStepDistance={bannerStepDistance}
              />
            </div>
          )}
          <div className={"d-flex p-3 align-items-center"}>
            {!canEndLiveNavigation && (
              <div>
                <TimeToTake time={remainingDruation} />
                <div
                  role={"heading"}
                  aria-label={intl.formatMessage(
                    { id: "navigation.screenreader.ankunftsInformationen" },
                    {
                      remainingDistance: remainingDistanceToDestination,
                      arrivalDate: arrivalDate.toLocaleTimeString(undefined, {
                        hour: "2-digit",
                        minute: "2-digit",
                      }),
                    }
                  )}
                  // aria-labelledby={"navigation__time navigation__distance"}
                  className={"d-flex align-items-baseline"}
                >
                  <FontAwesomeIcon icon={["fal", "clock"]} className={"mr-2"} />
                  <ArrivalTime
                    id={"navigation__time"}
                    arrivalDate={arrivalDate}
                  />
                  <FontAwesomeIcon
                    icon={["fal", "flag"]}
                    className={"ml-3 mr-2"}
                  />
                  <StandardSubline
                    id={"navigation__distance"}
                    className={"text-muted flex-shrink-0"}
                  >
                    {remainingDistanceToDestination}
                  </StandardSubline>
                </div>
              </div>
            )}
            {navigationMode === "sbs" ? (
              <div className="d-flex align-items-center ml-auto">
                <RoundedIcon
                  onClick={handlePrevStep}
                  fontSize={"1.5rem"}
                  size={"3rem"}
                  disabled={!currentRoute.hasPrevStep()}
                  divClass={`mr-4 ml-4 roundedIcon__navigation`}
                  iconClass={"roundedIcon__navigation__icon"}
                  icon={["fal", "angle-left"]}
                  ariaLabel={intl.formatMessage({
                    id: "navigation.screenreader.stepBack",
                  })}
                />
                <RoundedIcon
                  onClick={handleNextStep}
                  fontSize={"1.5rem"}
                  size={"3rem"}
                  pulsing={!currentRoute.hasPrevStep()}
                  divClass={`roundedIcon__navigation ${
                    dontNeedContinueOnTerminal(isTerminal, currentRoute)
                      ? "button-disabled"
                      : ""
                  } `}
                  iconClass={"roundedIcon__navigation__icon"}
                  icon={["fal", "angle-right"]}
                  ariaLabel={intl.formatMessage({
                    id: "navigation.screenreader.stepForward",
                  })}
                />
              </div>
            ) : (
              <LiveActions
                setProhibitSpeak={setProhibitSpeak}
                canEndLiveNavigation={canEndLiveNavigation}
                navigationMode={navigationMode}
                isRerouting={isRerouting}
                onEndNavigation={onEndNavigation}
              />
            )}
          </div>
        </StandardCard>
      </div>
      <ScannerInfo show={showScannerInfo} onClose={handleClickScanDoorSign} />
      {qrOpen ? (
        <QrCodeReader
          history={history}
          readId={onReadId}
          canceled={() => setQrOpen(false)}
        />
      ) : null}
    </div>
  );
}

function dontNeedContinueOnTerminal(isTerminal, currentRoute) {
  if (!isTerminal) return false;

  return currentRoute.legs.length === currentRoute.legPos + 1;
}

/**
 * Zeigt bei Live Navigation "Rerouting" an oder Button zum Beenden, wenn man vor dem Ziel steht.
 * @param navigationMode
 * @param isRerouting
 * @param canEndLiveNavigation
 * @return {JSX.Element}
 * @constructor
 */
function LiveActions({
  setProhibitSpeak,
  navigationMode,
  isRerouting,
  canEndLiveNavigation,
  onEndNavigation,
}) {
  const getStatus = () => {
    if (canEndLiveNavigation) return "endNavigation";
    if (isRerouting) return "isRerouting";
  };

  const getContent = () => {
    switch (getStatus()) {
      case "endNavigation":
        DONT_DO_ANYTHING = true;
        setTimeout(() => {
          setProhibitSpeak(true);
        }, 2500);

        return (
          <>
            <StandardButton
              icon={["fal", "check-circle"]}
              handleClick={() => {
                setProhibitSpeak(false);
                onEndNavigation();
              }}
              pulsing
              buttonClasses={"normalButton--primary"}
            >
              <FormattedMessage id={"navigation.finishIt"} />
            </StandardButton>
          </>
        );
      case "isRerouting":
        return (
          <>
            <StandardHeadline
              text={"Rerouting"}
              type={"h3"}
              className={"mr-2"}
            />
            <RoundedIcon
              divClass={"roundedIcon--naked"}
              iconClass={"roundedIcon--naked__icon"}
              icon={"spinner"}
              spin
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className={"d-flex align-items-center justify-content-center w-100"}>
      {getContent()}
    </div>
  );
}
