import { CAMPUS_COLLECTION } from "../../../reduxStore/actions/main";
import CampusButtons from "./CampusButtons";
import { FormattedMessage, useIntl } from "react-intl";
import CategoryButtons from "./CategoryButtons";
import StandardButton from "../../elements/buttons/standard.button.jsx";
import StandardList from "../../elements/wrapper/standardList.wrapper.jsx";
import style from "../search.module.scss";

export default function FilterDetail(props) {
  const intl = useIntl();
  return (
    <div className={`d-flex flex-column ${style.FilterDetail}`}>
      {CAMPUS_COLLECTION.getAllCampuses().length > 1 ? (
        <StandardList
          className={"pr-3 pl-3 pb-3"}
          listButton={
            <StandardButton
              handleClick={props.resetCampus}
              buttonClasses={"normalButton--naked normalButton--naked--reset"}
              ariaLabel={intl.formatMessage({
                id: "search.filterDetails.screenreader.reset",
              })}
            >
              {intl.formatMessage({ id: "search.filterDetails.resetCampus" })}
            </StandardButton>
          }
          heading={intl.formatMessage({ id: "search.filterDetails.campuses" })}
        >
          <div className={"d-flex flex-wrap"}>
            <CampusButtons {...props} />
          </div>
        </StandardList>
      ) : null}
      <StandardList
        className={"pr-3 pl-3 pb-3"}
        listButton={
          <StandardButton
            buttonClasses={"normalButton--naked normalButton--naked--reset"}
            handleClick={props.resetCategories}
            ariaLabel={intl.formatMessage({
              id: "search.filterDetails.screenreader.reset",
            })}
          >
            {intl.formatMessage({
              id: "search.filterDetails.reset",
            })}
          </StandardButton>
        }
        heading={<FormattedMessage id="search.filterDetails.categories" />}
      >
        <div className={"d-flex flex-wrap"} role={"listbox"}>
          <CategoryButtons alphabetical {...props} />
        </div>
      </StandardList>
    </div>
  );
}
